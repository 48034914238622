import { ActionStatusTypes } from '../../assets/globalConstants'

import {
    UserCredentialSelection,
    UserSelectedCredentials,
} from '../../interfaces/interfaces'

// LIST
export interface IGetProcessAction {
    type: ScanModalListActionsTypes.GET_PROCCESS
    payload: {
        profile: string
        request: string
    }
}

export interface IGetProcessSuccessAction {
    type: ScanModalListActionsTypes.GET_PROCCESS_SUCCESS
    payload: { data: UserCredentialSelection }
}

export interface IGetProcessFailed {
    type: ScanModalListActionsTypes.GET_PROCCESS_FAILED
    payload: {
        error: string
    }
}

export interface ISetProcessDataAction {
    type: ScanModalListActionsTypes.SET_PROCESS_DATA
    payload: { profile: string; data: UserSelectedCredentials }
}

export interface ISendProcessDataAction {
    type: ScanModalListActionsTypes.SEND_PROCESS_DATA
    payload: { profile: string; data: UserSelectedCredentials }
}

export interface ISendProcessDataSuccessAction {
    type: ScanModalListActionsTypes.SEND_PROCESS_DATA_SUCCESS
}

export interface ISendProcessDataFailed {
    type: ScanModalListActionsTypes.SEND_PROCESS_DATA_FAILED
    payload: {
        error: string
    }
}

export interface IClearStateAction {
    type: ScanModalListActionsTypes.CLEAR_STATE
}

export type scanModalListActions =
    | IGetProcessAction
    | IGetProcessSuccessAction
    | IGetProcessFailed
    | ISetProcessDataAction
    | ISendProcessDataAction
    | ISendProcessDataSuccessAction
    | ISendProcessDataFailed
    | IClearStateAction

export enum ScanModalListActionsTypes {
    GET_PROCCESS = 'scanModal/get_process',
    GET_PROCCESS_SUCCESS = 'scanModal/get_process_success',
    GET_PROCCESS_FAILED = 'scanModal/get_process_failed',
    SET_PROCESS_DATA = 'scanModal/set_process_data',
    SEND_PROCESS_DATA = 'scanModal/send_process_data',
    SEND_PROCESS_DATA_SUCCESS = 'scanModal/send_process_data_success',
    SEND_PROCESS_DATA_FAILED = 'scanModal/send_process_data_failed',
    CLEAR_STATE = 'scanModal/clear_state',
}

export interface IscanModalListState {
    process?: UserCredentialSelection
    statusGettingProccess?: ActionStatusTypes
    processData?: { profile: string; data: UserSelectedCredentials }
    statusSendingData?: ActionStatusTypes
}
