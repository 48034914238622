import React from 'react'
import CredentialPreviewModal from '../../CredentialPreview'
import { t } from 'i18next'
import cx from 'classnames'
import {
    ButtonModel,
    ICredentialRequestedModel,
} from '../../../../../../interfaces/interfaces'
import NoActionPreview from '../NoActionPreview/NoActionPreview'
import { brandImages } from '../../../../../../assets/globalConstants'
import styles from '../../credentialPreviewModal.module.scss'
import CredentialCardPreview from '../../../../../organisms/CredentialCard/CredentialCardPreview/CredentialCardPreview'
import MediumModal from '../../../Medium/MediumModal'

type ICredentialsPreviewProps = {
    issuanceTemplate?: boolean
    saveButton?: ButtonModel
    showSaveButton?: boolean
    data?: ICredentialRequestedModel[]
    smallResolution: boolean
    show: boolean
    hideModal: (x?) => void
}

const CredentialsPreview: React.FC<ICredentialsPreviewProps> = (props) => {
    const {
        issuanceTemplate,
        saveButton,
        showSaveButton,
        data,
        smallResolution,
        show,
        hideModal,
    } = props
    return (
        <>
            {smallResolution ? (
                <>
                    {show ? (
                        <MediumModal
                            title={t(
                                issuanceTemplate
                                    ? 'editIssuance.step3.credPrevTitle'
                                    : 'editVerifier.step2.credPrevTitle'
                            )}
                            subtitle={t(
                                issuanceTemplate
                                    ? 'editIssuance.step3.credPrevDescription'
                                    : 'editVerifier.step2.credPrevDescription'
                            )}
                            titleClassName={cx(
                                'heading6 neutral1000 marginBottom4'
                            )}
                            subtitleClassName={cx('bodyRegularSM neutral700')}
                            headerClose={true}
                            show={show}
                            handleClose={hideModal}
                            className={styles.previewModalResponsive}
                        >
                            <CredentialPreviewModal
                                titleHeader=""
                                descriptionHeader=""
                                modalResponsiveView={true}
                            >
                                {data?.length ? (
                                    <div className={styles.credConstraints}>
                                        {data?.map((el, index) => (
                                            <CredentialCardPreview
                                                key={'credpreview__' + index}
                                                credential={el}
                                                isOnRequest={true}
                                                isOnConstraints={false}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <NoActionPreview
                                        title={t(
                                            issuanceTemplate
                                                ? 'editIssuance.step3.credPrevBodyTitle'
                                                : 'editVerifier.step2.credPrevBodyTitle'
                                        )}
                                        description={t(
                                            issuanceTemplate
                                                ? 'editIssuance.step3.credPrevBodyDescription'
                                                : 'editVerifier.step2.credPrevBodyDescription'
                                        )}
                                        image={brandImages.credExample}
                                    />
                                )}
                            </CredentialPreviewModal>
                        </MediumModal>
                    ) : null}
                </>
            ) : (
                <CredentialPreviewModal
                    titleHeader={t(
                        issuanceTemplate
                            ? 'editIssuance.step3.credPrevTitle'
                            : 'editVerifier.step2.credPrevTitle'
                    )}
                    descriptionHeader={t(
                        issuanceTemplate
                            ? 'editIssuance.step3.credPrevDescription'
                            : 'editVerifier.step2.credPrevDescription'
                    )}
                    saveButton={saveButton}
                    showSaveButton={showSaveButton ? showSaveButton : false}
                >
                    {data?.length ? (
                        <div className={styles.credConstraints}>
                            {data?.map((el, index) => (
                                <CredentialCardPreview
                                    key={'credpreview__' + index}
                                    credential={el}
                                    isOnRequest={true}
                                    isOnConstraints={false}
                                />
                            ))}
                        </div>
                    ) : (
                        <NoActionPreview
                            title={t(
                                issuanceTemplate
                                    ? 'editIssuance.step3.credPrevBodyTitle'
                                    : 'editVerifier.step2.credPrevBodyTitle'
                            )}
                            description={t(
                                issuanceTemplate
                                    ? 'editIssuance.step3.credPrevBodyDescription'
                                    : 'editVerifier.step2.credPrevBodyDescription'
                            )}
                            image={brandImages.credExample}
                        />
                    )}
                </CredentialPreviewModal>
            )}
        </>
    )
}

export default CredentialsPreview
