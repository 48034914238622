import { ActionStatusTypes } from '../../../assets/globalConstants'
import { CertificateModel, StepModel } from '../../../interfaces/interfaces'

// LIST
export interface IGetCertificatesAction {
    type: CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES
}

export interface IGetCertificatesSuccessAction {
    type: CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES_SUCCESS
    payload: {
        data: CertificateModel[]
    }
}

export interface IGetCertificatesFailed {
    type: CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES_FAILED
    payload: {
        error: string
    }
}

export interface IUploadCertificateAction {
    type: CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE
    payload: {
        alias?: string
        password?: string
        file: File
    }
}

export interface IUploadCertificateSuccessAction {
    type: CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE_SUCCESS
}

export interface IUploadCertificateFailedAction {
    type: CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE_FAILED
    payload: {
        error: string
    }
}

export interface ISelectCertificateAction {
    type: CertificatesListActionsTypes.SELECT_QUALIFIED_SIGNATURE
    payload: {
        certificate: CertificateModel
    }
}

export interface IDeleteCertificateAction {
    type: CertificatesListActionsTypes.DELETE_QUALIFIED_SIGNATURE
    payload: {
        id: string
    }
}

export interface IDeleteCertificateSuccessAction {
    type: CertificatesListActionsTypes.DELETE_QUALIFIED_SIGNATURE_SUCCESS
}

export interface IDeleteCertificateFailed {
    type: CertificatesListActionsTypes.DELETE_QUALIFIED_SIGNATURE_FAILED
    payload: {
        error: string
    }
}

export interface ClearCertificatesList {
    type: CertificatesListActionsTypes.CLEAR_QUALIFIED_SIGNATURE_LIST
}

export type CertificatesListActions =
    | IGetCertificatesAction
    | IGetCertificatesSuccessAction
    | IGetCertificatesFailed
    | ISelectCertificateAction
    | IDeleteCertificateAction
    | IDeleteCertificateSuccessAction
    | IDeleteCertificateFailed
    | IUploadCertificateAction
    | IUploadCertificateSuccessAction
    | IUploadCertificateFailedAction
    | ClearCertificatesList

export enum CertificatesListActionsTypes {
    GET_QUALIFIED_SIGNATURES = 'certificates/get',
    GET_QUALIFIED_SIGNATURES_SUCCESS = 'certificates/get_success',
    GET_QUALIFIED_SIGNATURES_FAILED = 'certificates/get_failed',
    SELECT_QUALIFIED_SIGNATURE = 'certificate/selectCertificate',
    DELETE_QUALIFIED_SIGNATURE = 'certificate/deleteCertificate',
    DELETE_QUALIFIED_SIGNATURE_SUCCESS = 'certificates/delete_certificate_success',
    DELETE_QUALIFIED_SIGNATURE_FAILED = 'certificates/delete_certificate_failed',
    UPLOAD_QUALIFIED_SIGNATURE = 'certificates/upload',
    UPLOAD_QUALIFIED_SIGNATURE_SUCCESS = 'certificates/upload_success',
    UPLOAD_QUALIFIED_SIGNATURE_FAILED = 'certificates/upload_failed',
    CLEAR_QUALIFIED_SIGNATURE_LIST = 'certificates/clear_list',
}

export interface ICertificatesListState {
    certificatesResponse?: CertificateModel[]
    selectedCertificate?: CertificateModel
    loadingStatus: boolean
    error: string
}

// FORM
export interface IConfigCertificateFormState {
    steps: StepModel[] | null
    firstStep: StepModel | null
    step: StepModel | null
    finalStep: StepModel | null
}

export interface SetActiveStepAction {
    type: ConfigCertificateFormActionsTypes.SETSTEP
    payload: {
        step: StepModel
    }
}
export interface SetStepsAction {
    type: ConfigCertificateFormActionsTypes.SETSTEPS
    payload: {
        steps: StepModel[]
    }
}

export interface ClearCertificateFormState {
    type: ConfigCertificateFormActionsTypes.CLEAR_FORM_STEP
}

export type ConfigCertificateFormActions =
    | SetActiveStepAction
    | SetStepsAction
    | ClearCertificateFormState

export enum ConfigCertificateFormActionsTypes {
    SETSTEPS = 'certificates/setSteps',
    SETSTEP = 'certificates/setStep',
    CLEAR_FORM_STEP = 'certificates/clearFormSteps',
}

export interface ICertificateState {
    certificate: CertificateModel
    token: string
    baseId: string
    status: ActionStatusTypes
    error: string
}

export interface IUpdateCertificateAction {
    type: ConfigCertificateActionsTypes.UPDATE_QUALIFIED_SIGNATURE
    payload: {
        certificate: CertificateModel
        certificateId: string
    }
}

export interface IUpdateCertificateSuccessAction {
    type: ConfigCertificateActionsTypes.UPDATE_QUALIFIED_SIGNATURE_SUCCESS
}

export interface IUpdateCertificateFailedAction {
    type: ConfigCertificateActionsTypes.UPDATE_QUALIFIED_SIGNATURE_FAILED
    payload: {
        error: string
    }
}

export interface IClearCertificate {
    type: ConfigCertificateActionsTypes.CLEAR_CURRENT_QUALIFIED_SIGNATURE
}

export interface IClearActionStatus {
    type: ConfigCertificateActionsTypes.CLEAR_ACTION_STATUS
}

export type ConfigDidActions =
    | IClearCertificate
    | IUpdateCertificateAction
    | IUpdateCertificateSuccessAction
    | IUpdateCertificateFailedAction
    | IClearActionStatus

export enum ConfigCertificateActionsTypes {
    CLEAR_CURRENT_QUALIFIED_SIGNATURE = 'config/certificate/clearCertificateForm',
    CREATE_QUALIFIED_SIGNATURE = 'config/certificate/create',
    UPDATE_QUALIFIED_SIGNATURE = 'config/certificate/update',
    UPDATE_QUALIFIED_SIGNATURE_SUCCESS = 'config/certificate/update_success',
    UPDATE_QUALIFIED_SIGNATURE_FAILED = 'config/certificate/create_failed',
    CLEAR_ACTION_STATUS = 'config/certificate/clear_action_status',
}
