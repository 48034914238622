import { t } from 'i18next'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { homeSelectors } from '../..'
import { ReactComponent as CloseLightIcon } from '../../../../../assets/images/close-light.svg'
import QrBuilder from '../../../../../Components/qrBuilder/QrBuilder'
import { hideScan } from '../../homeActions'
import style from './sandBoxModal.module.scss'
import { modalConstants } from './sandboxModalConstants'
import cx from 'classnames'
import { brandImages } from '../../../../../assets/globalConstants'

type ISandboxModalProps = {
    show: boolean
    hideModal: () => void
}

const SandboxModal: React.FC<ISandboxModalProps> = (props) => {
    const { hideModal, show } = props
    const dispatch = useDispatch()
    const invitationUrl = useSelector(homeSelectors.invitationUrl) || ''

    const hideAndCleanModal = () => {
        dispatch(hideScan())
        hideModal()
    }

    return (
        <div
            className={`${style.sandBoxModalBackgroundLayer} ${
                show ? style.visibleSandboxModal : style.hiddenSandboxModal
            }`}
        >
            <div className={style.sandBoxModal__qr}>
                <div
                    className={style.sandBoxModal__qr__header}
                    onClick={() => {
                        hideAndCleanModal()
                    }}
                >
                    <p
                        className={cx(
                            'bodyBoldMD neutral1000',
                            style.sandboxPanel__qr__header__title
                        )}
                    >
                        {t('navigation.sandboxPanel.modalTitle')}
                    </p>
                    <div className={style.sandBoxModal__qr__header__close}>
                        <CloseLightIcon />
                    </div>
                </div>
                <QrBuilder
                    size={modalConstants.size}
                    qrData={invitationUrl}
                    logoSrc={brandImages?.brandLogo?.src}
                ></QrBuilder>
            </div>
        </div>
    )
}

export default SandboxModal
