import { Epic, ofType } from 'redux-observable'
import { ignoreElements, tap } from 'rxjs/operators'
import { RootState } from '../../../store/indexReducers'

export const navigateEpic: Epic<any, any, RootState> = (
    action$,
    state$,
    dependencies
) => {
    return action$.pipe(
        ofType('NAVIGATE'),
        tap((action: any): any => {
            dependencies.history.push(action.path)
        }),
        ignoreElements()
    )
}
