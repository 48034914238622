import cx from 'classnames'
import React from 'react'
import GaImage from '../../../../../../Components/atoms/Image/Image'
import { gaImages } from '../../../../../../assets/globalConstants'
import { t } from 'i18next'
import styles from '../tutorial.module.scss'

type ISecondSteprops = {}

const SecondStep: React.FC<ISecondSteprops> = (props) => {
    return (
        <div>
            <div className={cx(styles.image)}>
                <GaImage image={gaImages.scanToVerificate} />
            </div>
            <div>
                <p className={cx('heading5')}>
                    {t('appIntegrations.tutorial.scan')}
                </p>
                <p className={cx('bodyRegularMD neutral700 marginTop4')}>
                    {t('appIntegrations.tutorial.scanDesc')}
                </p>
            </div>
        </div>
    )
}

export default SecondStep
