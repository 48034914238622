import {
    ConnectionListActionsTypes,
    ConnectionListActions,
    IConnectionListState,
} from './connectionsTypes'

// LIST
const initialConnectionsListState: IConnectionListState = {
    connections: undefined,
    selectedConnection: undefined,
    loadingStatus: false,
}

export function connectionsReducer(
    state = initialConnectionsListState,
    action: ConnectionListActions
): IConnectionListState {
    switch (action.type) {
        case ConnectionListActionsTypes.GET_CONNECTIONS:
            return {
                ...state,
                loadingStatus: true,
                connections: undefined,
            }
        case ConnectionListActionsTypes.GET_CONNECTIONS_SUCCESS:
            // TODO: remove this when total is coming always from backend
            return {
                ...state,
                connections: action.payload?.data,
                loadingStatus: false,
            }
        case ConnectionListActionsTypes.GET_CONNECTIONS_FAILED:
            return {
                ...state,
                connections: undefined,
                loadingStatus: false,
            }
        case ConnectionListActionsTypes.SELECT_CONNECTION:
            return {
                ...state,
                selectedConnection: action.payload.dataAgreement,
            }
        case ConnectionListActionsTypes.DELETE_DATA_AGREEMENT:
            return {
                ...state,
                connections: undefined,
                loadingStatus: true,
            }
        case ConnectionListActionsTypes.DELETE_DATA_AGREEMENT_SUCCESS:
            return {
                ...state,
                connections: undefined,
                loadingStatus: false,
            }

        default:
            return state
    }
}
