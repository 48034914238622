import { Epic, ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, delay, mergeMap, switchMap } from 'rxjs/operators'
import dataService from '../../../services/dataService'
import { setReachedLimitsAction } from '../../common/licenses/licensesActions'
import { showNotification } from '../../common/Notification/notificationActions'
import { setSessionSSIOperations } from '../../common/session/sessionActions'
import {
    createVerifierFailed,
    createVerifierSuccess,
    deleteVerifierFailed,
    deleteVerifierSuccess,
    getVerifierConfigs,
    getVerifierConfigsFailed,
    getVerifierConfigsSuccess,
    getVerifierPurposesFailed,
    getVerifierPurposesSuccess,
    updateVerifierFailed,
    updateVerifierSuccess,
} from './verifierActions'
import { VerifierConfigActionsTypes } from './verifierTypes'
import { hideScreenLoaderAction } from '../../common/Loader/loaderActions'

export const getVerifierConfigsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS),
        delay(100),
        switchMap((action: any): any => {
            return from(dataService.getVerifierConfigs()).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(getVerifierConfigsSuccess(response.response)),
                        of(
                            setSessionSSIOperations(
                                'verifier',
                                response.response
                            )
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        getVerifierConfigsFailed(error),
                        showNotification(
                            'editVerifier.getVerifiersError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const getVerifierPurposesEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES),
        delay(100),
        switchMap((action: any): any => {
            return from(dataService.getPurposes()).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(getVerifierPurposesSuccess(response.response))
                    )
                }),
                catchError((error) =>
                    of(
                        getVerifierPurposesFailed(error),
                        showNotification(
                            'editVerifier.getVerifiersError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const updateVerifierEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG),
        switchMap((action: any): any => {
            const verifier = action.payload.verifier

            return from(dataService.updateVerifierConfigs(verifier)).pipe(
                mergeMap(() => {
                    return merge(
                        of(
                            updateVerifierSuccess(),
                            hideScreenLoaderAction(),
                            showNotification(
                                'editVerifier.updateVerifierSuccess',
                                'success',
                                ''
                            )
                        )
                    )
                }),
                catchError((error) => {
                    return of(
                        updateVerifierFailed(error),
                        hideScreenLoaderAction(),
                        showNotification(
                            'editVerifier.updateVerifierError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                })
            )
        })
    )
}

export const createVerifierEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG),
        switchMap((action: any): any => {
            const verifier = action.payload.verifier
            return from(dataService.createVerifierConfigs(verifier)).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(createVerifierSuccess(), hideScreenLoaderAction())
                    )
                }),
                catchError((error) => {
                    const limitReachedError = error?.status === 429
                    return of(
                        createVerifierFailed(error.response?.response?.status),
                        hideScreenLoaderAction(),
                        limitReachedError
                            ? setReachedLimitsAction(
                                  'subscription.limitedFeatures.verificationTemplates'
                              )
                            : showNotification(
                                  'editVerifier.createVerifierError',
                                  'error',
                                  error.response?.message,
                                  error?.status
                              )
                    )
                })
            )
        })
    )
}

export const deleteVerifierEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(VerifierConfigActionsTypes.DELETE_VERIFIER_CONFIG),
        switchMap((action: any): any => {
            const verifierId = action.payload.verifierID
            return from(dataService.deleteVerifierConfigs(verifierId)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        getVerifierConfigs(),
                        deleteVerifierSuccess(verifierId)
                    )
                }),
                catchError((error) =>
                    of(
                        deleteVerifierFailed(error),
                        showNotification(
                            'editVerifier.deleteVerifierError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
