import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import GaSelectCheckbox, {
    Selectable,
} from '../../../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import {
    ApiKeyStatusTypes,
    apiKeysStatusOptions,
} from '../../../../../assets/globalConstants'
import {
    IApiKeyModel,
    IDIDModel,
    IPresentationRequestModel,
} from '../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { didsSelectors } from '../../../Dids'
import { getAliasFromDid, getDidFromAlias } from '../../../Dids/didSelectors'
import { getVerifierConfigs } from '../../../Verifier/verifierActions'
import { sessionSelectors } from '../../../../common/session'
import { getTenantConfig } from '../../apiKeysActions'
import { propertiesToSearchSSIOperations } from '../../views/apiKeysList/apiKeysList.constants'

type IUpdateFormProps = {
    currentApiKey: IApiKeyModel
    buttonFunction: (x?) => void
}

const UpdateForm: React.FC<IUpdateFormProps> = (props) => {
    const { currentApiKey, buttonFunction } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const userTenants = useSelector(sessionSelectors.getTenants)
    const userDids = useSelector(sessionSelectors.getUserDids)
    const allDidsAllowed = useSelector(sessionSelectors.allDidsAllowed)
    const dids = useSelector(didsSelectors.getDids)
    const currentTenant = useSelector(sessionSelectors.getCurrentTenant)
    const didOptions = useSelector(didsSelectors.getdidConfigsVms) || [
        { DidId: '', alias: '' } as IDIDModel,
    ]
    const operations = useSelector(sessionSelectors.getSsiOperations)
    const initialFormState = {
        alias: currentApiKey.alias,
        id: currentApiKey.id,
        did: currentApiKey.did,
        tenant: currentApiKey.tenant || currentTenant,
        status: currentApiKey.status || 'ACTIVE',
        ssi_operations: currentApiKey.ssi_operations || [''],
    }

    const [formData, setFormState] = useState(initialFormState)
    const { id, alias, did, tenant, ssi_operations } = formData
    const validForm =
        !!did?.trim() &&
        !!alias.trim() &&
        !!tenant?.trim &&
        !!ssi_operations?.length

    const filterSsiOperationsByDid = (selectedDid: string): Selectable[] => {
        let formattedSsiConfigs = new Array()
        if (selectedDid && selectedDid?.length) {
            const filteredOptions = operations?.filter(
                (option: IPresentationRequestModel) =>
                    option.vmethodId?.match(selectedDid)
            )
            filteredOptions?.forEach((el) =>
                formattedSsiConfigs.push({ key: el.id, value: el.id })
            )
        }

        return formattedSsiConfigs
    }

    const [options, setOptions] = useState({
        tenants: userTenants || [],
        tenantDids: allDidsAllowed ? didOptions : userDids || {},
        ssi_operations:
            filterSsiOperationsByDid(did) ||
            operations.map((item: IPresentationRequestModel) => {
                return { key: item.id, value: item.id }
            }),
    })

    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canReadDids = !!(
        availableScopes &&
        userHasScopes(['readDids', 'readTenants'], availableScopes, true)
    )
    const canReadSSIConfigs = !!(
        availableScopes &&
        userHasScopes(['readSSIConfigs'], availableScopes, true)
    )

    useEffect(() => {
        if (!userDids?.length && !!userTenants && canReadDids) {
            dispatch(getTenantConfig(currentTenant || ''))
        }
        if (!operations?.length && canReadSSIConfigs) {
            dispatch(dispatch(getVerifierConfigs()))
        }
        const ssiOptionsByDid = filterSsiOperationsByDid(did)
        setOptions({
            ...options,
            tenants: userTenants,
            ssi_operations: ssiOptionsByDid,
        })
    }, [userTenants])

    useEffect(() => {
        setOptions({ ...options, tenantDids: userDids || {} })
        const ssiOptionsByDid = filterSsiOperationsByDid(did)
        setOptions({ ...options, ssi_operations: ssiOptionsByDid || [] })
    }, [userDids])

    useEffect(() => {
        const ssiOptionsByDid = filterSsiOperationsByDid(did)
        setOptions({ ...options, ssi_operations: ssiOptionsByDid || [] })
    }, [userDids, did, operations])

    useEffect(() => {
        setFormState(initialFormState)
        const ssiOptionsByDid = filterSsiOperationsByDid(did)
        setOptions({ ...options, ssi_operations: ssiOptionsByDid || [] })
    }, [currentApiKey])

    const [state, setState] = React.useState<{
        selections: Selectable[]
        searched: string
        searchedResult: Selectable[]
    }>({
        selections: [],
        searched: '',
        searchedResult: options?.ssi_operations || [],
    })

    function handleSearchedChange(e) {
        setState({
            selections: state.selections,
            searched: state.searched,
            searchedResult: e,
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        buttonFunction(formData)
    }

    return (
        <>
            <LightPanel className={cx('marginTop32')}>
                <form onSubmit={onSubmit} className={cx('width100')}>
                    <GaTextInput
                        className={cx('margin-bottom')}
                        id={'id'}
                        invalid={!formData?.alias}
                        labelText={t('createApiKey.idLabel')}
                        required={false}
                        readOnly={true}
                        name={'id'}
                        type="text"
                        value={formData.id}
                    />
                    <GaTextInput
                        className={cx('margin-bottom')}
                        id={'alias'}
                        invalid={!formData?.alias}
                        labelText={t('createApiKey.nameLabel')}
                        required={false}
                        name={'name'}
                        onChange={(e) =>
                            setFormState({ ...formData, alias: e.target.value })
                        }
                        type="text"
                        value={formData.alias}
                    />

                    <GaSelect
                        idSelect={'did'}
                        className={cx('margin-top margin-bottom')}
                        value={did ? getAliasFromDid(didOptions, did) : did}
                        invalid={!did?.trim()?.length}
                        labelText={t('configApiKey.did')}
                        required
                        name={'did'}
                        permissions={{
                            scopes: ['readDids', 'readTenants'],
                            every: true,
                        }}
                        onChange={(e) => {
                            let selected_did = getDidFromAlias(
                                didOptions,
                                e?.target?.value
                            )
                            setFormState({
                                ...formData,
                                did: selected_did?.split('#')[0] || '',
                                ssi_operations: [],
                            }),
                                setOptions({
                                    ...options,
                                    ssi_operations: filterSsiOperationsByDid(
                                        selected_did || ''
                                    ),
                                })
                        }}
                        options={didOptions.map(
                            (did: IDIDModel) => did?.alias || did?.DidId
                        )}
                    />

                    {options.ssi_operations?.length > 0 ? (
                        <GaSelectCheckbox
                            id={'ssiConfigsCheckbox'}
                            className={cx('margin-top margin-bottom')}
                            labelText={t('createApiKey.ssiOperations')}
                            required
                            permissions={{
                                scopes: ['readSSIConfigs'],
                                every: true,
                            }}
                            name={'ssi_operations'}
                            onChange={(e) => {
                                setFormState({
                                    ...formData,
                                    ssi_operations: e,
                                })
                            }}
                            checkedData={ssi_operations}
                            options={options.ssi_operations}
                            handleSearchedChange={handleSearchedChange}
                            propertiesToSearch={propertiesToSearchSSIOperations}
                        />
                    ) : (
                        <GaSelect
                            idSelect={'ssiOperations'}
                            className={cx('margin-top margin-bottom')}
                            value={t('createApiKey.noSsiOperations')}
                            invalid={true}
                            disabled={true}
                            permissions={{
                                scopes: ['readSSIConfigs'],
                                every: true,
                            }}
                            labelText={t('createApiKey.ssiOperations')}
                            required
                            name={'ssi_operations'}
                            options={[t('createApiKey.noSsiOperations')]}
                        />
                    )}

                    <GaSelect
                        idSelect={'selectStatusContainer'}
                        className={cx('margin-bottom')}
                        value={formData?.status}
                        id="select-status"
                        invalid={false}
                        labelText={t('configApiKey.status')}
                        optionTextProperty={'text'}
                        optionValueProperty={'value'}
                        required
                        onChange={(e) => {
                            setFormState({
                                ...formData,
                                status: e.target.value as ApiKeyStatusTypes,
                            })
                        }}
                        options={apiKeysStatusOptions}
                    />
                </form>
            </LightPanel>
            <DarkButton
                className={cx('marginTop32', 'margin-left-auto')}
                text={'public.save'}
                disabled={!validForm}
                functionality={() => buttonFunction(formData)}
            />
        </>
    )
}

export default UpdateForm
