import { combineEpics } from 'redux-observable'
import { apiKeysEpics } from '../features/studio/ApiKeys'
import { didsEpic } from '../features/studio/Dids'
import { homeEpics } from '../features/studio/Home'
import { issuancesEpic } from '../features/studio/Issuance'
import { loginEpics } from '../features/common/Login'
import { statsEpics } from '../features/common/Statistics'
import { userEpics } from '../features/common/Team/tabsContent/users/store'
import { consentEpics, verifierEpics } from '../features/studio/Verifier'
import { catalogEpics } from '../features/common/catalog'
import * as certificatesEpic from '../features/studio/certificates/certificatesEpics'
import { credentialsEpic } from '../features/studio/credentials'
import { navigateEpic } from '../features/common/global/globalEpics'
import { licensesEpics } from '../features/common/licenses'
import { epics as organization } from '../features/common/organization'
import { sessionEpics } from '../features/common/session'
import { rolesEpics } from '../features/common/Team/tabsContent/roles/store'
import { requestCredentialsEpic } from './requestCredentials'
import { connectionsEpic } from './connections'
import { scanModalEpic } from './scanModal'
import { appIntegrationsEpic } from './appIntegrations'
import { appIntegrationModalEpic } from './appIntegrationModal'
import { notificationsEpics } from './notifications'

// import {settingsEpic} from "../features/Settings";

const rootEpic = combineEpics(
    navigateEpic,
    organization.getOrgEpic,
    organization.loadOrgEpic,
    organization.createOrgEpic,
    organization.createStarterOrgEpic,
    organization.updateOrgEpic,
    organization.deleteOrgEpic,
    organization.createOrgToRequestSubEpic,
    organization.updateOrgNoPaymentEpic,
    loginEpics.submitLoginEpic,
    loginEpics.submitEmailloginEpic,
    loginEpics.emailSignUpEpic,
    loginEpics.loginSuccessEpic,
    loginEpics.switchTenantEpic,
    loginEpics.signupSuccessEpic,
    sessionEpics.deleteSessionEpic,
    sessionEpics.getUserEpic,
    sessionEpics.setSessionEpic,
    sessionEpics.getLicensesEpic,
    sessionEpics.loadDataEpic,
    sessionEpics.readSessionEpic,
    apiKeysEpics.getApiKeysEpic,
    apiKeysEpics.createApiKeyEpic,
    apiKeysEpics.deleteApiKeyEpic,
    apiKeysEpics.updateApiKeyEpic,
    apiKeysEpics.getTenantConfigEpic,
    apiKeysEpics.regenerateApiKeyEpic,
    certificatesEpic.getCertificatesEpic,
    certificatesEpic.uploadCertificatesEpic,
    certificatesEpic.deleteCertificatesEpic,
    didsEpic.requestDidsEpic,
    didsEpic.requestKeypairEpic,
    didsEpic.requestSecretEpic,
    issuancesEpic.getIssuancesEpic,
    issuancesEpic.updateIssuanceEpic,
    issuancesEpic.createIssuanceEpic,
    issuancesEpic.deleteIssuanceEpic,
    verifierEpics.getVerifierConfigsEpic,
    verifierEpics.updateVerifierEpic,
    verifierEpics.createVerifierEpic,
    verifierEpics.deleteVerifierEpic,
    verifierEpics.getVerifierPurposesEpic,

    // settingsEpic.autoSettingsEpic,
    // settingsEpic.checkStatusEpic,
    didsEpic.createDidEpic,
    didsEpic.createFirstDidEpic,
    didsEpic.deleteDidEpic,
    didsEpic.addIssuerTypeEpic,
    didsEpic.deleteIssuerTypeEpic,
    didsEpic.addVerifierTypeEpic,
    didsEpic.deleteVerifierTypeEpic,
    // settingsEpic.initSettingsEpic,
    // settingsEpic.initSettingsSuccessEpic,
    // settingsEpic.notInitializedEpic,
    // settingsEpic.submitSettingsEpic,
    // settingsEpic.requestSettingsEpic,
    didsEpic.updateDidEpic,
    userEpics.getUsersEpic,
    userEpics.getRegistrationTokenEpic,
    userEpics.createUserEpic,
    userEpics.deleteUserEpic,
    userEpics.updateUserEpic,
    userEpics.checkUsersEpic,
    userEpics.changePasswordEpic,
    userEpics.getProfileEpic,
    userEpics.updateProfileEpic,
    catalogEpics.getCatalogEpic,
    catalogEpics.reactivateCredentialEpic,
    catalogEpics.revokeCredentialEpic,
    catalogEpics.suspendCredentialEpic,
    catalogEpics.mergePrivateCatalogEpic,
    catalogEpics.addIssuerToGroupEpic,
    catalogEpics.removeIssuerFromGroupEpic,
    catalogEpics.deletePrivateGroupEpic,
    credentialsEpic.getCredentialsEpic,
    credentialsEpic.getPendingCredentialsEpic,
    credentialsEpic.deleteCredentialsEpic,
    credentialsEpic.issueCredentialsEpic,
    credentialsEpic.updateCredentialStatusEpic,
    credentialsEpic.rejectCredentialsEpic,
    rolesEpics.getRolesEpic,
    rolesEpics.getRoleEpic,
    rolesEpics.createRoleEpic,
    rolesEpics.deleteRoleEpic,
    rolesEpics.updateRoleEpic,
    rolesEpics.getScopesEpic,
    consentEpics.getConsentsEpic,
    consentEpics.selectConsentEpic,
    consentEpics.deleteIssuanceEpic,
    homeEpics.displaySandboxEpic,
    homeEpics.displayNavigationSandboxEpic,
    statsEpics.getMetricEpic,
    statsEpics.getStatisticsEpic,
    licensesEpics.getProductsEpic,
    licensesEpics.getClientSecretEpic,
    licensesEpics.sendPaymentMethodEpic,
    licensesEpics.getSubscriptionInfoEpic,
    licensesEpics.updateLicenseEpic,
    licensesEpics.cancelDowngradeEpic,
    licensesEpics.cancelSubscriptionEpic,
    licensesEpics.getCurrentSubscriptionInfoEpic,
    licensesEpics.requestSubscriptionEpic,
    homeEpics.getAUStatisticsEpic,
    homeEpics.getICStatisticsEpic,
    // EW
    requestCredentialsEpic.getCredentialsEpic,
    requestCredentialsEpic.deleteCredentialsEpic,
    requestCredentialsEpic.importCredentialsEpic,
    connectionsEpic.getConnectionsEpic,
    connectionsEpic.deleteDataAgreementEpic,
    scanModalEpic.getProccessDataEpic,
    scanModalEpic.sendProcessDataEpic,
    appIntegrationsEpic.requestAppIntegrationsEpic,
    appIntegrationsEpic.requestScopesEpic,
    appIntegrationsEpic.requestSecretEpic,
    appIntegrationsEpic.deleteAppIntegrationEpic,
    appIntegrationsEpic.updateAppIntegrationEpic,
    appIntegrationsEpic.createAppIntegrationDataEpic,
    appIntegrationModalEpic.sendAppIntegrationDataEpic,
    notificationsEpics.getUnreadNotificationsListEpic,
    notificationsEpics.downloadCredentialEpic,
    notificationsEpics.deleteNotificationEpic
)

export default rootEpic
