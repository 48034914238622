import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    ConfigVerifierFormActions,
    ConfigVerifierFormActionsTypes,
    CreateNewPurposeActions,
    CreateNewPurposeActionsTypes,
    IConfigVerifierFormState,
    ICreateNewPurposeActionsState,
    VerifierConfigActionsTypes,
    VerifierConfigsListState,
    VerifiersListActions,
} from './verifierTypes'

// LIST
const initialVerifierConfigListState: VerifierConfigsListState = {
    verifiers: null,
    loadingStatus: false,
    purposes: null,
    status: ActionStatusTypes.neutral,
    updateStatus: ActionStatusTypes.neutral,
    deletedConfigId: undefined,
}

export function verifiersReducer(
    state = initialVerifierConfigListState,
    action: VerifiersListActions | ConfigVerifierFormActions
): VerifierConfigsListState {
    switch (action.type) {
        case ConfigVerifierFormActionsTypes.SETSTEP:
            return {
                ...state,
                updateStatus: ActionStatusTypes.neutral,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS:
            return {
                ...state,
                verifiers: null,
                status: ActionStatusTypes.loading,
                loadingStatus: true,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS_SUCCESS:
            return {
                ...state,
                verifiers: action?.payload?.verifiers,
                status: ActionStatusTypes.neutral,
                updateStatus: ActionStatusTypes.neutral,
                loadingStatus: false,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS_FAILED:
            return {
                ...state,
                verifiers: null,
                status: ActionStatusTypes.failed,
                loadingStatus: false,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES:
            return {
                ...state,
                purposes: null,
                status: ActionStatusTypes.loading,
                loadingStatus: true,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES_SUCCESS:
            return {
                ...state,
                purposes: action.payload.purposes,
                status: ActionStatusTypes.neutral,
                loadingStatus: false,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES_FAILED:
            return {
                ...state,
                purposes: null,
                status: ActionStatusTypes.failed,
                loadingStatus: false,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG:
            return {
                ...state,
                status: ActionStatusTypes.loading,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG_SUCCESS:
            return {
                ...state,
                status: ActionStatusTypes.success,
            }
        case VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG:
            return {
                ...state,
                updateStatus: ActionStatusTypes.loading,
            } as VerifierConfigsListState
        case VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG_SUCCESS:
            return {
                ...state,
                updateStatus: ActionStatusTypes.success,
            }
        case VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG_FAILED:
            return {
                ...state,
                updateStatus: ActionStatusTypes.failed,
            }
        case VerifierConfigActionsTypes.DELETE_VERIFIER_CONFIG_SUCCESS:
            return {
                ...state,
                deletedConfigId: action?.payload?.id,
            }
        case VerifierConfigActionsTypes.CLEAR_VERIFIERS_GENERAL_STATE:
            return initialVerifierConfigListState as VerifierConfigsListState
        default:
            return state
    }
}

// EDIT / CREATE
// UPDATE AND CREATE FORMS
const initialFormState: IConfigVerifierFormState = {
    steps: [],
    firstStep: null,
    step: null,
    finalStep: null,
    credentialsSelected: null,
    credentialsSelectedInitial: null,
    selectedCredential: null,
    purposesList: [],
    qrVerifier: '',
    selectedVerifier: {
        id: '',
    },
    ignoreDataAgreement: false,
}

export function configVerifierFormReducer(
    state = initialFormState,
    action: ConfigVerifierFormActions
): IConfigVerifierFormState {
    switch (action.type) {
        case ConfigVerifierFormActionsTypes.SETSTEP:
            return {
                ...state,
                step: action.payload.step,
                credentialsSelected: state.credentialsSelectedInitial,
            } as IConfigVerifierFormState
        case ConfigVerifierFormActionsTypes.SETSTEPS:
            const lastPosition = action.payload.steps.length - 1
            return {
                ...state,
                steps: action.payload.steps,
                firstStep: action.payload.steps[0],
                step: action.payload.steps[0],
                finalStep: action.payload.steps[lastPosition],
            } as IConfigVerifierFormState
        case ConfigVerifierFormActionsTypes.SET_SELECTED_CREDENTIALS:
            return {
                ...state,
                credentialsSelected: action.payload.credentials,
            } as IConfigVerifierFormState
        case ConfigVerifierFormActionsTypes.SET_SELECTED_CREDENTIAL:
            return {
                ...state,
                selectedCredential: action.payload,
            } as IConfigVerifierFormState
        case ConfigVerifierFormActionsTypes.CLEAR_SELECTED_CREDENTIAL:
            return {
                ...state,
                selectedCredential: null,
            } as IConfigVerifierFormState
        case ConfigVerifierFormActionsTypes.SET_PURPOSES: //TODO: maybe not required. Possibly deprecated
            return {
                ...state,
                purposesList: action.payload.purposes,
            } as IConfigVerifierFormState
        case ConfigVerifierFormActionsTypes.CLEAR_VERIFIER_FORM:
            return initialFormState as IConfigVerifierFormState
        case ConfigVerifierFormActionsTypes.SET_QR_VERIFIER:
            return {
                ...state,
                selectedVerifier: {
                    id: action.payload,
                    service: action.payload,
                },
            } as IConfigVerifierFormState
        case ConfigVerifierFormActionsTypes.EDIT_VERIFIER:
            const requestedCredentials = action.payload?.credentials
            return {
                ...state,
                selectedVerifier: action.payload || { id: '' },
                credentialsSelected: requestedCredentials,
                credentialsSelectedInitial: requestedCredentials,
            } as IConfigVerifierFormState
        case ConfigVerifierFormActionsTypes.CLEAR_VERIFIER:
            return {
                ...state,
                selectedVerifier: { id: '' },
            }
        default:
            return state
    }
}

const createNewPurposeState: ICreateNewPurposeActionsState = {
    newPurposeFormData: null,
}

export function purposesReducer(
    state = createNewPurposeState,
    action: CreateNewPurposeActions
): ICreateNewPurposeActionsState {
    switch (action.type) {
        case CreateNewPurposeActionsTypes.SET_NEW_PURPOSE:
            return {
                ...state,
                newPurposeFormData: action.payload,
            } as ICreateNewPurposeActionsState
        case CreateNewPurposeActionsTypes.CLEAR_NEW_PURPOSE:
            return {
                ...state,
                newPurposeFormData: null,
            } as ICreateNewPurposeActionsState
        default:
            return state
    }
}
