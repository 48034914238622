import { ActionStatusTypes } from '../../../assets/globalConstants'
import { HomeActions, HomeActionsTypes, IHomeState } from './homeTypes'

const homeState: IHomeState = {
    notifications: [...Array(10).keys()],
    pendingActions: [...Array(6).keys()],
    verifiers: [],
    displayScan: false,
    invitationUrl: undefined,
    generalDataLoaded: false,
    getStaticsStatus: ActionStatusTypes.neutral,
    getAUStaticsStatus: ActionStatusTypes.neutral,
    getICStaticsStatus: ActionStatusTypes.neutral,
    statistics: undefined,
    selectedAUPeriodIsYear: false,
    selectedICPeriodIsYear: false,
    showAgeVerificationModal: false,
}

export function homeReducer(
    state = homeState,
    action: HomeActions
): IHomeState {
    switch (action.type) {
        case HomeActionsTypes.GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: null,
            } as IHomeState
        case HomeActionsTypes.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload?.notifications,
            }
        case HomeActionsTypes.GET_NOTIFICATIONS_FAILED:
            return {
                ...state,
                notifications: [],
            }
        case HomeActionsTypes.GET_PENDING_ACTIONS:
            return {
                ...state,
                pendingActions: null,
            } as IHomeState
        case HomeActionsTypes.GET_PENDING_ACTIONS_SUCCESS:
            return {
                ...state,
                pendingActions: action.payload?.pendingActions,
            }
        case HomeActionsTypes.GET_PENDING_ACTIONS_FAILED:
            return {
                ...state,
                pendingActions: [],
            }
        case HomeActionsTypes.DISPLAY_SCAN:
            return {
                ...state,
                displayScan: true,
                invitationUrl: undefined,
            }
        case HomeActionsTypes.DISPLAY_SCAN_FAILED:
            return {
                ...state,
                displayScan: false,
            }
        case HomeActionsTypes.DISPLAY_SCAN_SUCCESS:
            const params =
                '?' + new URLSearchParams(action.payload.invitation).toString()
            return {
                ...state,
                displayScan: true,
                invitationUrl: process.env.REACT_APP_SANDBOX_URL + params,
            }
        case HomeActionsTypes.HIDE_SCAN:
            return {
                ...state,
                displayScan: false,
                invitationUrl: undefined,
            }
        case HomeActionsTypes.DISPLAY_SCAN_NAVIGATION:
            return {
                ...state,
                displayScanNavigation: true,
                invitationUrl: undefined,
            }
        case HomeActionsTypes.DISPLAY_SCAN_NAVIGATION_FAILED:
            return {
                ...state,
                displayScanNavigation: false,
            }
        case HomeActionsTypes.DISPLAY_SCAN_NAVIGATION_SUCCESS:
            const paramsNav =
                '?' + new URLSearchParams(action.payload.invitation).toString()
            return {
                ...state,
                displayScanNavigation: true,
                invitationUrl: process.env.REACT_APP_SANDBOX_URL + paramsNav,
            }
        case HomeActionsTypes.HIDE_SCAN_NAVIGATION:
            return {
                ...state,
                displayScanNavigation: false,
                invitationUrl: undefined,
            }
        case HomeActionsTypes.SET_GENERAL_DATA_LOADED:
            return {
                ...state,
                generalDataLoaded: action?.payload?.dataLoaded,
            }
        case HomeActionsTypes.GET_IC_STATISTICS:
            return {
                ...state,
                getStaticsStatus: ActionStatusTypes.loading,
                getICStaticsStatus: ActionStatusTypes.loading,
            }
        case HomeActionsTypes.GET_IC_STATISTICS_SUCCESS:
            return {
                ...state,
                getStaticsStatus: ActionStatusTypes.success,
                getICStaticsStatus: ActionStatusTypes.success,
                statisticsIC: action.payload.statistics,
            }
        case HomeActionsTypes.GET_IC_STATISTICS_FAILED:
            return {
                ...state,
                getStaticsStatus: ActionStatusTypes.failed,
                getICStaticsStatus: ActionStatusTypes.failed,
                statistics: undefined,
            }
        //
        case HomeActionsTypes.GET_AU_STATISTICS:
            return {
                ...state,
                getStaticsStatus: ActionStatusTypes.loading,
                getAUStaticsStatus: ActionStatusTypes.loading,
            }
        case HomeActionsTypes.GET_AU_STATISTICS_SUCCESS:
            return {
                ...state,
                getStaticsStatus: ActionStatusTypes.success,
                getAUStaticsStatus: ActionStatusTypes.success,
                statisticsAU: action.payload.statistics,
            }
        case HomeActionsTypes.GET_AU_STATISTICS_FAILED:
            return {
                ...state,
                getStaticsStatus: ActionStatusTypes.failed,
                getAUStaticsStatus: ActionStatusTypes.failed,
                statisticsAU: undefined,
            }
        case HomeActionsTypes.SET_IC_SELECTED_PERIOD_YEAR_STATS:
            return {
                ...state,
                selectedICPeriodIsYear: action?.payload?.isYear,
            }
        case HomeActionsTypes.SET_AU_SELECTED_PERIOD_YEAR_STATS:
            return {
                ...state,
                selectedAUPeriodIsYear: action?.payload?.isYear,
            }
        case HomeActionsTypes.SET_SHOW_AGE_VERIFICATION_MODAL:
            return {
                ...state,
                showAgeVerificationModal: action?.payload?.show,
            }
        default:
            return state
    }
}
