import React from 'react'

type MonitorIconProps = {
    size?: number
    color?: string
    className?: string
}

const MonitorIcon: React.FC<MonitorIconProps> = React.memo((props) => {
    const { size, color, className } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                d="M26.6665 4H5.33317C3.86041 4 2.6665 5.19391 2.6665 6.66667V20C2.6665 21.4728 3.86041 22.6667 5.33317 22.6667H26.6665C28.1393 22.6667 29.3332 21.4728 29.3332 20V6.66667C29.3332 5.19391 28.1393 4 26.6665 4Z"
                stroke="#1E1E20"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6665 28H21.3332"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 22.6667V28.0001"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})

export default MonitorIcon
