import localStorageService from '../../../services/localStorageService'
import sessionStorageService from '../../../services/sessionStorageService'
import {
    ICheckLoginAction,
    ILoginDone,
    ILoginFailedAction,
    ILoginSuccessAction,
    ILogOutAction,
    IQRSignupSuccessAction,
    ISetEmailSigningUp,
    ISignUpEmailAction,
    ISignUpEmailFailedAction,
    ISignUpEmailSuccessAction,
    ISubmitEmailLoginAction,
    ISubmitLoginAction,
    ISwitchTenantAction,
    LoginActionTypes,
} from './loginTypes'

export function setNotSigningUp(): ISetEmailSigningUp {
    return {
        type: LoginActionTypes.SET_EMAIL_SIGNING_UP,
    }
}

export function emailSignUp(
    username: string,
    password: string
): ISignUpEmailAction {
    sessionStorageService.deleteSessionStorageData('token')
    sessionStorageService.deleteSessionStorageData('user')
    localStorageService.deleteLocalStorageData('TMClosed')

    return {
        type: LoginActionTypes.EMAIL_SIGN_UP,
        payload: {
            user: {
                username: username,
                password: password,
            },
        },
    }
}

export const switchTenant = (tenant: string): ISwitchTenantAction => {
    return {
        type: LoginActionTypes.SWITCH_TENANT,
        payload: {
            tenant: tenant,
        },
    }
}

export function emailSignUpSuccess(): ISignUpEmailSuccessAction {
    return {
        type: LoginActionTypes.EMAIL_SIGN_UP_SUCCESS,
    }
}

export function emailSignUpFailed(err: any): ISignUpEmailFailedAction {
    return {
        type: LoginActionTypes.EMAIL_SIGN_UP_FAILED,
        payload: {
            error: err,
        },
    }
}

export function submitEmailLogin(
    username: string,
    password: string
): ISubmitEmailLoginAction {
    return {
        type: LoginActionTypes.SUBMIT_EMAIL_LOGIN,
        payload: {
            user: {
                username: username,
                password: password,
            },
        },
    }
}

export function submitLogin(token: string): ISubmitLoginAction {
    return {
        type: LoginActionTypes.SUBMIT_LOGIN,
        token: token,
    }
}

export function checkLogin(): ICheckLoginAction {
    return {
        type: LoginActionTypes.CHECK_LOGIN,
    }
}

export const loginSuccess = (): ILoginSuccessAction => {
    localStorageService.deleteLocalStorageData('TMClosed')
    return {
        type: LoginActionTypes.LOGIN_SUCCESS,
        payload: {},
    }
}

export const qrSignupSuccess = (): IQRSignupSuccessAction => {
    return {
        type: LoginActionTypes.QR_SIGNUP_SUCCESS,
        payload: {},
    }
}

export const loginDone = (): ILoginDone => {
    return {
        type: LoginActionTypes.LOGIN_DONE,
    }
}

export const loginFailed = (err: any): ILoginFailedAction => {
    return {
        type: LoginActionTypes.LOGIN_FAILED,
    }
}

export const loginOut = (): ILogOutAction => {
    return {
        type: LoginActionTypes.LOGOUT,
    }
}
