/* Return the items for each page of a list */
export function paginate(items: any, pageSize: number, pageNumber: number) {
    return items?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

/*
  	Return an array of certain length and set the elements within it from
    start value to end value.
*/
export function createRange(start: number, end: number): number[] {
    let range: number[] = []
    for (let i = start; i <= end; i++) {
        range.push(i)
    }
    return range
}

/*
  	Return the total of items in a page
*/
export const getTotalPageItems = (dataLength: number, pageSize?: number) =>
    pageSize && Math.ceil(dataLength / pageSize)
