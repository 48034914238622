import React from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './loading.module.scss'
import ScanModal from '../../ScanModal'
import AnimatedLoader from '../../../../../loader/animatedLoader/AnimatedLoader'
import { LoaderSizes } from '../../../../../../data/globalVar'

type IScanModalLoading = {
    title: string
    description: string
}

const ScanModalLoading: React.FC<IScanModalLoading> = (props) => {
    const { title, description } = props

    return (
        <ScanModal hideModal={() => {}}>
            <div className={styles.loading}>
                <AnimatedLoader size={LoaderSizes.MD} />
                <p className={cx('heading6 marginTop30 ')}>{t(title)}</p>
                <p className={cx('bodyRegularMD neutral800 marginTop8')}>
                    {t(description)}
                </p>
            </div>
        </ScanModal>
    )
}

export default ScanModalLoading
