import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { licensesSelectors } from '../../../..'
import { yearMonths } from '../../../../../../../assets/globalConstants'
import StatusModal from '../../../../../../../Components/atoms/formFields/stripeCardInput/statusModal/StatusModal'
import { StripeCardForm } from '../../../../../../../Components/atoms/formFields/stripeCardInput/StripeCardForm'
import GaTextInput from '../../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../../../Components/atoms/panels/LightPanel/LightPanel'
import Description from '../../../../../../../Components/atoms/texts/Description/Description'
import ErrorPanel from '../../../../../../../Components/molecules/notification/panels/error/ErrorPanel'
import { isValidEmail } from '../../../../../../../utils/validations/functions'
import { hideScreenLoaderAction } from '../../../../../Loader/loaderActions'
import { getOrg } from '../../../../../organization/organizationSelectors'
import { setSelectedRoute } from '../../../../../PanelScafolding/scafoldingActions'
import { sessionSelectors } from '../../../../../session'
import { cardStatuses } from '../../../../licenses.constants'
import {
    getSubscriptionInfoAction,
    sendPaymentMethodAction,
    setCurrentStepAction,
    updateLicenseAction,
} from '../../../../licensesActions'
import {
    getClientSecret,
    isSendingPaymentMethod,
} from '../../../../licensesSelectors'
import styles from './changeSubscriptionThirdStep.module.scss'
import { onPremise } from '../../../../../../../data/globalVar'
import cx from 'classnames'
import DarkButton from '../../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import {
    CreateOrgPayloadModel,
    ITierModel,
    StepModel,
} from '../../../../../../../interfaces/interfaces'
import { getLicenses, readSession } from '../../../../../session/sessionActions'
import { setNotSigningUp } from '../../../../../Login/loginAction'
import { orgSelectors } from '../../../../../organization'

type IChangeSubscriptionThirdStepProps = {
    formattedOrgToCreate?: CreateOrgPayloadModel | any
    flowSteps: StepModel[]
    licenseIsIncomplete: boolean
    newPriceId: string
    newLicense: ITierModel
    setPreviousStepsInactive?: () => void
    setIsNextStepActive?: (x?) => void
}

const ChangeSubscriptionThirdStep: React.FC<
    IChangeSubscriptionThirdStepProps
> = (props) => {
    const {
        formattedOrgToCreate,
        flowSteps,
        licenseIsIncomplete,
        newPriceId,
        newLicense,
        setPreviousStepsInactive,
        setIsNextStepActive,
    } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const clientSecret = useSelector(getClientSecret)
    const subscriptionIsUpdating = useSelector(
        licensesSelectors.updatingSubscriptionLoading
    )
    const subscriptionIsUpdatedSuccessfully = useSelector(
        licensesSelectors.subscriptionInfoUpdatedSuccess
    )
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const userLicense = useSelector(sessionSelectors.getLicenses)
    const org = useSelector(orgSelectors?.getOrg)

    const [loadingScreen, setLoadingScreen] = useState(false)

    const strpKey = process.env.REACT_APP_STRP_KEY
    const stripePromise = strpKey && loadStripe(strpKey)
    const userPaymnetByStripe =
        subscriptionInfo?.currentTierId &&
        !subscriptionInfo?.paymentOutsideStripe
    const orgInfo = org?.orgInfo
    const orgBilling = org?.billingInfo
    const orgLegalAddress = orgBilling?.legalAddress
    const subscriptionPaymentMethod = subscriptionInfo?.paymentMethod

    const [cardFormActive, setCardFormActive] = useState(
        !subscriptionPaymentMethod?.id ||
            !subscriptionPaymentMethod?.email?.trim()?.length
    )
    const currentYear = new Date().getFullYear()
    const yearsOptions = () => {
        const start = currentYear
        const stop = currentYear + 20
        const step = +1
        return Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
        )
    }

    useEffect(() => {
        const lastStep = flowSteps?.length - 1
        dispatch(setCurrentStepAction(flowSteps[lastStep]))
    }, [])

    useEffect(() => {}, [org?.id])

    useEffect(() => {
        setStatusModal(false)
        if (!subscriptionIsUpdating) {
        }
    }, [clientSecret])

    useEffect(() => {
        if (!subscriptionInfo?.currentTierId && !subscriptionIsUpdating) {
            // Just in cloud for now
            !onPremise &&
                userPaymnetByStripe &&
                dispatch(getSubscriptionInfoAction())
        }
        if (subscriptionIsUpdatedSuccessfully) {
            dispatch(readSession()), dispatch(setNotSigningUp())
            !onPremise && dispatch(getLicenses())
            dispatch(setCurrentStepAction(flowSteps[0]))
            dispatch(setSelectedRoute('subscription')),
                history.push('subscription')
            dispatch(hideScreenLoaderAction())
            setLoadingScreen(false)
        }
    }, [
        subscriptionInfo,
        subscriptionIsUpdating,
        subscriptionIsUpdatedSuccessfully,
    ])

    const cardDetails = {
        id: subscriptionPaymentMethod?.creditCardNumber || '',
        holderName: subscriptionPaymentMethod?.holderName || '',
        creditCardNumber: subscriptionPaymentMethod?.creditCardNumber || '',
        expirationMonth:
            subscriptionPaymentMethod?.expiration?.split('/')[0] ||
            yearMonths[0]?.value,
        expirationYear:
            subscriptionPaymentMethod?.expiration?.slice(-4) ||
            yearsOptions()[0].toString(),
        ccv: subscriptionPaymentMethod?.ccvCheck || '',
        email: subscriptionPaymentMethod?.email || '',
    }

    const [cardForm, setCardForm] = useState(cardDetails)
    const uRole = useSelector(sessionSelectors.getUserRole_s)
    const userIsOrgOwner = uRole === 'OrganizationOwner'

    useEffect(() => {
        setIsNextStepActive &&
            setIsNextStepActive(
                !!subscriptionInfo?.paymentMethod?.id ? true : false
            )
    }, [cardDetails])

    const [showStatusModal, setStatusModal] = useState(false)
    const hideStatusModal = () => setStatusModal(false)
    function submitData(paymentMethod) {
        !onPremise &&
            org?.id &&
            !licenseIsIncomplete &&
            !onPremise &&
            userIsOrgOwner &&
            dispatch(updateLicenseAction(newLicense, newPriceId, org?.id))
        !onPremise &&
            dispatch(
                sendPaymentMethodAction({
                    id: paymentMethod,
                    email: cardForm?.email,
                })
            )
        setStatusModal(true)
    }

    const resetFormData = () => {
        setCardFormActive(false)
        setCardForm({
            ...cardForm,
            email: cardDetails?.email,
        })
    }

    return (
        <React.Fragment>
            <>
                <div className={styles.details}>
                    <LightPanel className={styles.details__content}>
                        <div className={styles.header}>
                            <p
                                className={cx(
                                    'heading6 neutral1000',
                                    styles.header__title
                                )}
                            >
                                {t('billing.cardDetails')}
                            </p>

                            {!cardFormActive ? (
                                <DarkButton
                                    className={styles.details__button}
                                    text={'public.change'}
                                    disabled={false}
                                    functionality={() => {
                                        setCardFormActive(true)
                                    }}
                                />
                            ) : null}
                        </div>
                        {!cardFormActive ? (
                            <>
                                <p className={cx('bodyRegularMD neutral1000')}>
                                    {t('billing.cardEnding', {
                                        number:
                                            subscriptionPaymentMethod?.creditCardNumber?.slice(
                                                -4
                                            ) || '-',
                                    })}
                                </p>
                                <Description
                                    className={cx('bodyRegularMD neutral1000')}
                                    text={'billing.expires'}
                                    extraTranslationParams={{
                                        date:
                                            subscriptionPaymentMethod?.expiration ||
                                            '-',
                                    }}
                                />
                                {subscriptionPaymentMethod?.ccvCheck &&
                                subscriptionPaymentMethod?.ccvCheck?.toLowerCase() ===
                                    'fail' ? (
                                    <ErrorPanel
                                        hasIcon
                                        hasTitle
                                        label={'subscription.reviewCardData'}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <div className={styles.details__form}>
                                <>
                                    <div className={styles.personalData}>
                                        <GaTextInput
                                            className={styles.paymentEmail}
                                            id="email"
                                            labelText={t('billing.email')}
                                            required
                                            errorTexts={[
                                                t('public.invalidEmail'),
                                            ]}
                                            invalid={
                                                !isValidEmail(cardForm?.email)
                                            }
                                            name="email"
                                            onChange={(e) => {
                                                setCardForm({
                                                    ...cardForm,
                                                    email: e.target.value,
                                                })
                                            }}
                                            placeholder={t('billing.email')}
                                            value={cardForm?.email}
                                        />
                                    </div>
                                    {stripePromise && (
                                        <Elements stripe={stripePromise}>
                                            <StripeCardForm
                                                setPreviousStepsInactive={
                                                    setPreviousStepsInactive
                                                }
                                                loadingScreen={loadingScreen}
                                                setLoader={setLoadingScreen}
                                                formattedOrgToCreate={
                                                    formattedOrgToCreate
                                                }
                                                organizationCreated={!!org?.id}
                                                validForm={isValidEmail(
                                                    cardForm?.email
                                                )}
                                                successAction={submitData}
                                                clientSecret={clientSecret}
                                                hideForm={resetFormData}
                                                statusPanel={
                                                    !!(
                                                        subscriptionPaymentMethod?.ccvCheck &&
                                                        subscriptionPaymentMethod?.ccvCheck?.toLowerCase() !==
                                                            'pass'
                                                    )
                                                }
                                                status={
                                                    cardStatuses[
                                                        subscriptionPaymentMethod?.ccvCheck?.toLowerCase() ||
                                                            'pass'
                                                    ]
                                                }
                                            />
                                        </Elements>
                                    )}
                                </>
                            </div>
                        )}
                        {showStatusModal &&
                        !!subscriptionPaymentMethod?.ccvCheck ? (
                            <StatusModal
                                show={showStatusModal}
                                hideModal={hideStatusModal}
                                status={
                                    cardStatuses[
                                        subscriptionPaymentMethod?.ccvCheck?.toLowerCase() ||
                                            'pass'
                                    ]
                                }
                            />
                        ) : null}
                    </LightPanel>
                </div>
            </>
        </React.Fragment>
    )
}

ChangeSubscriptionThirdStep.defaultProps = {}

export default ChangeSubscriptionThirdStep
