import { createSelector } from 'reselect'
import {
    EndsStepsModel,
    ICredentialTypeModel,
} from '../../../interfaces/interfaces'
import { RootState } from '../../../store/indexReducers'

// LIST
export const getIssuances = (state: RootState) =>
    state.issuancesReducer.issuances
export const getIsLoading = (state: RootState) =>
    state.issuancesReducer.loadingStatus
export const getDeletedConfigId = (state: RootState) =>
    state.issuancesReducer.deletedConfigId

// FORMS
export const getActiveStep = (state: RootState) =>
    state.configIssuanceFormReducer.step
export const getRegisterSteps = (state: RootState) =>
    state.configIssuanceFormReducer.steps
export const getEndsSteps = (state: RootState) => {
    const steps = state.configIssuanceFormReducer.steps
    const activeStep = state.configIssuanceFormReducer.step
    return activeStep && steps?.length
        ? getPreviousAndNextSteps(steps, activeStep)
        : null
}
export const getCatalogTypes = (state: RootState) =>
    buildEditableCredentials(state.catalogReducer?.credentialTypes)
export const getOriginalCatalogTypes = (state: RootState) =>
    state.catalogReducer?.credentialTypes
export const getPurposes = (state: RootState) =>
    state.configIssuanceFormReducer.purposesList
export const getSelectedCredentials = (state: RootState) =>
    state.configIssuanceFormReducer.credentialsSelected
export const getSelectedCredential = (state: RootState) =>
    state.configIssuanceFormReducer.selectedCredential
export const getNewPurposeData = (state: RootState) =>
    state.purposesReducer.newPurposeFormData
export const getSelectedIssuance = (state: RootState) =>
    state.configIssuanceFormReducer?.selectedIssuance
export const getRequestedCredentials = (state: RootState) =>
    formatRequestedCredentials(
        state.catalogReducer?.credentialTypes,
        state.configIssuanceFormReducer?.credentialsSelected
    )
export const getFormattedRequestedCredentials = createSelector(
    [getOriginalCatalogTypes, getRequestedCredentials],
    (types, creds) => {
        return formatRequestedCredentials(types, creds)
    }
)
export const getRequestedUnfCredentials = (state: RootState) =>
    returnOriginalRequestedCredential(
        state.configIssuanceFormReducer?.credentialsSelected
    )
export const getInitialRequestedUnfCredentials = (state: RootState) =>
    JSON.parse(
        JSON.stringify(
            state.configIssuanceFormReducer?.credentialsSelectedInitial
        )
    )
export const getOperationStatus = (state: RootState) =>
    state.issuancesReducer?.status
export const getUpdateStatus = (state: RootState) =>
    state.issuancesReducer?.updateStatus

export const getSelectedIssuanceGroup = (state: RootState) =>
    state.configIssuanceFormReducer.credentialGroupSelected
export const getSelectedIssuanceInitialGroup = (state: RootState) =>
    JSON.parse(JSON.stringify(state.configIssuanceFormReducer))
        .credentialGroupSelectedInitial
export const getSelectedIssuanceTypes = (state: RootState) =>
    state.configIssuanceFormReducer.credentialTypesSelected
export const getSelectedIssuanceInitialTypes = (state: RootState) =>
    JSON.parse(JSON.stringify(state.configIssuanceFormReducer))
        .credentialTypesSelectedInitial

const getPreviousAndNextSteps = (steps, activeStep): EndsStepsModel => {
    let nextStep
    let previousStep

    const lastStep = steps[steps.length]
    const nextIndex = steps.indexOf(activeStep) + 1
    const previousIndex = steps.indexOf(activeStep) - 1
    nextStep = activeStep !== lastStep ? steps[nextIndex] : null
    previousStep = activeStep !== steps[0] ? steps[previousIndex] : null

    return { previousStep, nextStep }
}

function buildEditableCredentials(credentials: ICredentialTypeModel[] | null) {
    let editableCredentials: any = credentials

    editableCredentials?.map(
        (el) => (
            (el.mandatory = el.mandatory ? el.mandatory : false),
            (el.trustLevel = el.trustLevel ? el.trustLevel : 0),
            (el.purpose = el.purpose ? el.purpose : ''),
            (el.formatted = true)
        )
    )

    return editableCredentials
}

export function returnOriginalRequestedCredential(
    requestedCredentials?: any[] | null
) {
    const credentials = requestedCredentials?.map((cred) => {
        return {
            id: cred?.id || cred?.type,
            mandatory: cred?.mandatory,
            trustLevel: cred?.trustLevel,
            type: cred?.type || cred?.id,
        }
    })

    return credentials
}

export function formatRequestedCredentials(
    credentialList: any,
    requestedCredentials?: any[] | null
) {
    let formattedRequestedCredentials = new Array()

    if (requestedCredentials) {
        let found: any
        for (const cred of requestedCredentials) {
            found = undefined
            found = credentialList?.find((e) => {
                return e.id === cred.type || e.id === cred.id
            })
            if (!!found && found.formatted) {
                found.mandatory = found.mandatory || cred.mandatory
                found.trustLevel = found.trustLevel || cred.trustLevel
                formattedRequestedCredentials.push(found)
            } else if (!!found) {
                found.mandatory = cred.mandatory || cred.mandatory
                found.trustLevel = cred.trustLevel || cred.trustLevel
                found.purpose = found.purpose || cred.purpose || ''
                found.type = found.type || cred.type
                formattedRequestedCredentials.push(found)
            }
        }
    }

    return formattedRequestedCredentials
}
