import React, { useEffect, useRef, useState } from 'react'
import styles from './didsSelector.module.scss'
import { IDIDModel, PermissionsDataModel } from '../../../interfaces/interfaces'
import cx from 'classnames'
import InputSkeleton from '../skeletons/inlineSkeleton/InputSkeleton'
import ChevronUpIcon from '../../../assets/iconsLibrary/simple/chevronUp/chevronUpIcon'
import ChevronDownIcon from '../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import { centerEllipsisTrim } from '../../../utils/formatDataUtil'
import { useSelector } from 'react-redux'
import { sessionSelectors } from '../../../features/common/session'
import { userHasScopes } from '../../../utils/permissionsUtil'
import { t } from 'i18next'
import { gaColors } from '../../../assets/globalConstants'
import DidsSelectorDropdown from './components/didsSelectorDropdown/DidsSelectorDropdown'
import ProfilesModal from './components/profilesModal/ProfilesModal'
import AddAccountModal from './components/addAccountModal/AddAccountModal'

export type IDidsSelectorProps = {
    value?: string
    options?: IDIDModel[]
    isLoading?: boolean
    permissions?: PermissionsDataModel
    canCreate?: boolean
    onChange?: (e?) => void
}

const DidsSelector: React.FC<IDidsSelectorProps> = (props) => {
    const { value, options, isLoading, permissions, canCreate, onChange } =
        props

    // Consts
    const completeSelectedDid = options?.find((did) => did?.DidId === value)
    const valueDisplay =
        completeSelectedDid?.alias || completeSelectedDid?.DidId

    const [availableOptions, setOptions] = useState(new Array())
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const [selectedValue, setSelectedValue] = useState(valueDisplay)
    const [showDropdown, setShowDropdown] = useState(false)

    const [showProfilesModal, setShowProfilesModal] = useState(false)
    const [showAddAccountModal, setShowAddAccountModal] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null)

    const hasScopes = !permissions?.scopes
        ? true
        : availableScopes
        ? userHasScopes(
              permissions?.scopes || [],
              availableScopes,
              permissions?.every
          )
        : false

    const hasPermissions = permissions?.scopes || hasScopes

    // Effects
    useEffect(() => {}, [availableScopes, selectedValue])

    useEffect(() => {
        setSelectedValue(valueDisplay)
    }, [value])

    useEffect(() => {
        options && setOptions(options)
    }, [options])

    useEffect(() => {
        isLoading && setShowDropdown(false)
    }, [isLoading])

    useEffect(() => {
        const handler = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setShowDropdown(false)
            }
        }

        window.addEventListener('click', handler)
        return () => {
            window.removeEventListener('click', handler)
        }
    })

    // Functions
    const onItemClick = (itemId) => {
        const changeParam = {
            target: {
                value: itemId,
            },
        }
        const newSelectedDid = availableOptions?.find(
            (el) => el?.DidId === itemId
        )
        const itemValueDisplay =
            newSelectedDid['alias'] ||
            centerEllipsisTrim(newSelectedDid['DidId'], 25)

        setSelectedValue(t(itemValueDisplay))
        onChange && onChange(changeParam)
    }

    return (
        <>
            <div className={`${styles.didSelectorContainer}`}>
                {!isLoading ? (
                    <>
                        <div className={styles.selectCustomContainer}>
                            <input
                                ref={inputRef}
                                className={`${cx('heading4')}`}
                                onClick={() =>
                                    setShowDropdown(
                                        (showDropdown) => !showDropdown
                                    )
                                }
                                aria-autocomplete="list"
                                role="combobox"
                                onChange={(e) => {
                                    onChange ? onChange(e) : () => {}
                                }}
                                placeholder={t('general.chooseAnOption')}
                                value={selectedValue}
                                autoComplete="Off"
                                type="text"
                                readOnly
                                aria-expanded={showDropdown}
                            />
                            {hasPermissions &&
                            showDropdown &&
                            !!availableOptions?.length ? (
                                <DidsSelectorDropdown
                                    options={options}
                                    value={value}
                                    select={(x) => onItemClick(x?.DidId)}
                                    canCreate={canCreate}
                                    setShowProfilesModal={setShowProfilesModal}
                                    setShowAddAccountModal={
                                        setShowAddAccountModal
                                    }
                                />
                            ) : null}
                        </div>
                        {hasPermissions ? (
                            showDropdown ? (
                                <ChevronUpIcon
                                    size={32}
                                    color={gaColors.neutral1000}
                                />
                            ) : (
                                <ChevronDownIcon
                                    size={32}
                                    color={gaColors.neutral1000}
                                />
                            )
                        ) : null}
                    </>
                ) : (
                    <InputSkeleton
                        className={styles.inputSkeleton}
                        required={false}
                    />
                )}
            </div>
            {showProfilesModal ? (
                <ProfilesModal
                    show={showProfilesModal}
                    options={options}
                    selectedDid={value}
                    hideModal={() => setShowProfilesModal(false)}
                    onChange={(x) => onItemClick(x)}
                />
            ) : null}

            {showAddAccountModal ? (
                <AddAccountModal
                    show={showAddAccountModal}
                    hideModal={() => {
                        setShowAddAccountModal(false)
                    }}
                    dids={options}
                />
            ) : null}
        </>
    )
}

export default DidsSelector
