import {
    IApiKeyModel,
    ITenantConfigModel,
    StepModel,
} from '../../../interfaces/interfaces'
import {
    ApiKeysActionTypes,
    ClearApiKeyFormState,
    ConfigApiKeyFormActionsTypes,
    EditApiKey,
    IClearActionStatus,
    IClearApiKeyListAction,
    ICreateApiKeyAction,
    ICreateApiKeyFailedAction,
    ICreateApiKeySuccessAction,
    IDeleteApiKeyAction,
    IDeleteApiKeyFailedAction,
    IDeleteApiKeySuccessAction,
    IGetApiKeysAction,
    IGetApiKeysFailedAction,
    IGetApiKeysSuccessAction,
    IGetTenantConfigAction,
    IGetTenantConfigFailedAction,
    IGetTenantConfigSuccessAction,
    IRegenerateApiKeyAction,
    IRegenerateApiKeyFailedAction,
    IRegenerateApiKeySuccessAction,
    IUpdateApiKeyAction,
    IUpdateApiKeyFailedAction,
    IUpdateApiKeySuccessAction,
    SetActiveStepAction,
    SetStepsAction,
} from './apiKeysTypes'

// FORM
export const setSteps = (steps: StepModel[]): SetStepsAction => {
    return {
        type: ConfigApiKeyFormActionsTypes.SETSTEPS,
        payload: {
            steps: steps,
        },
    }
}

export const setActiveStep = (step: StepModel): SetActiveStepAction => {
    return {
        type: ConfigApiKeyFormActionsTypes.SETSTEP,
        payload: {
            step: step,
        },
    }
}

export const clearFormState = (): ClearApiKeyFormState => {
    return {
        type: ConfigApiKeyFormActionsTypes.CLEAR_API_KEY_FORM,
    }
}

export const editApiKey = (app: IApiKeyModel): EditApiKey => {
    return {
        type: ConfigApiKeyFormActionsTypes.EDIT_API_KEY,
        payload: {
            apiKey: app,
        },
    }
}

// ACTIONS
export const getApiKeys = (): IGetApiKeysAction => {
    return {
        type: ApiKeysActionTypes.GET_API_KEYS,
    }
}

export const getApiKeysSuccess = (
    apiKeys: IApiKeyModel[]
): IGetApiKeysSuccessAction => {
    return {
        type: ApiKeysActionTypes.GET_API_KEYS_SUCCESS,
        payload: apiKeys,
    }
}

export const getApiKeysFailed = (err: any): IGetApiKeysFailedAction => {
    return {
        type: ApiKeysActionTypes.GET_API_KEYS_FAILED,
        payload: { error: err },
    }
}

export const getTenantConfig = (tenant: string): IGetTenantConfigAction => {
    return {
        type: ApiKeysActionTypes.GET_TENANTS_CONFIG,
        payload: { tenant: tenant },
    }
}

export const getTenantConfigSuccess = (
    tenantConfig: ITenantConfigModel
): IGetTenantConfigSuccessAction => {
    return {
        type: ApiKeysActionTypes.GET_TENANTS_CONFIG_SUCCESS,
        payload: { tenantConfig },
    }
}

export const getTenantConfigFailed = (
    err: any
): IGetTenantConfigFailedAction => {
    return {
        type: ApiKeysActionTypes.GET_TENANTS_CONFIG_FAILED,
        payload: err,
    }
}

export const createApiKey = (app: IApiKeyModel): ICreateApiKeyAction => {
    return {
        type: ApiKeysActionTypes.CREATE_API_KEY,
        payload: {
            app: app,
        },
    }
}
export const createApiKeySuccess = (
    created: IApiKeyModel
): ICreateApiKeySuccessAction => {
    return {
        type: ApiKeysActionTypes.CREATE_API_KEY_SUCCESS,
        payload: {
            message: 'App created successfully',
            app: created,
        },
    }
}
export const createApiKeyFailed = (err: any): ICreateApiKeyFailedAction => {
    return {
        type: ApiKeysActionTypes.CREATE_API_KEY_FAILED,
        payload: {
            error: err,
        },
    }
}
export const deleteApiKey = (appID: string): IDeleteApiKeyAction => {
    return {
        type: ApiKeysActionTypes.DELETE_APP,
        payload: {
            appID: appID,
        },
    }
}
export const deleteApiKeySuccess = (id: string): IDeleteApiKeySuccessAction => {
    return {
        type: ApiKeysActionTypes.DELETE_API_KEY_SUCCESS,
        payload: {
            id: id,
        },
    }
}
export const deleteApiKeyFailed = (err: any): IDeleteApiKeyFailedAction => {
    return {
        type: ApiKeysActionTypes.DELETE_API_KEY_FAILED,
    }
}

export const updateApiKey = (
    app: IApiKeyModel,
    id: string
): IUpdateApiKeyAction => {
    return {
        type: ApiKeysActionTypes.UPDATE_API_KEY,
        payload: {
            app: app,
            id: id,
        },
    }
}

export const updateApiKeySuccess = (): IUpdateApiKeySuccessAction => {
    return {
        type: ApiKeysActionTypes.UPDATE_API_KEY_SUCCESS,
    }
}
export const updateApiKeyFailed = (err: any): IUpdateApiKeyFailedAction => {
    return {
        type: ApiKeysActionTypes.UPDATE_API_KEY_FAILED,
        payload: {
            error: err,
        },
    }
}
export const clearApiKeysList = (): IClearApiKeyListAction => {
    return {
        type: ApiKeysActionTypes.CLEAR_API_KEY_LIST,
    }
}
export const clearActionStatus = (): IClearActionStatus => {
    return {
        type: ApiKeysActionTypes.CLEAR_ACTION_STATUS,
    }
}

export const regenerateApiKey = (
    app: IApiKeyModel
): IRegenerateApiKeyAction => {
    return {
        type: ApiKeysActionTypes.REGENERATE_API_KEY,
        payload: {
            app: app,
        },
    }
}

export const regenerateApiKeySuccess = (
    app: IApiKeyModel
): IRegenerateApiKeySuccessAction => {
    return {
        type: ApiKeysActionTypes.REGENERATE_API_KEY_SUCCESS,
        payload: {
            newApiKey: app,
        },
    }
}

export const regenerateApiKeyFailed = (
    err: any
): IRegenerateApiKeyFailedAction => {
    return {
        type: ApiKeysActionTypes.REGENERATE_API_KEY_FAILED,
        payload: {
            error: err,
        },
    }
}
