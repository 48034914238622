import {t} from 'i18next';
import React from 'react';
import {ButtonModel} from '../../../../../interfaces/interfaces';
import LightButton from '../../../buttons/LightButton/LightButton';
import style from './successContent.module.scss';

type SuccessContentProps = {
  className?: string
  successMessage: string,
  subject?: string,
  state: string,
  Example?: any,
  actionsText?: string,
  primaryButton: ButtonModel,
  secondaryButton: ButtonModel
}

const SuccessContent: React.FC<SuccessContentProps> = React.memo((props) => {
  const {successMessage, subject, state, Example, actionsText, primaryButton, secondaryButton, className, children } = props;

  return (
    <div className={style.successEndContainer}>
      <p className={style.message}> {t(successMessage)}</p>
      { subject ? <p className={style.serviceName}> {t(subject)} </p> : null }

      <p className={style.stateText}> {t(state)}</p>
      { Example ? <Example /> : null }
      {actionsText ? <p className={style.actionsText}> {t(actionsText)} </p> : null }

      <div className={style.buttons}>
        <LightButton
          className={style.buttons__did}
          text={primaryButton.label}
          disabled={false}
          functionality={primaryButton.function}
        />
        <div></div>
        <LightButton
          className={style.buttons__try}
          text={secondaryButton.label}
          disabled={false}
          functionality={secondaryButton.function}
        />
      </div>
    </div>
  )
})

export default SuccessContent;
