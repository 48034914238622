import {t} from 'i18next';
import React from 'react';
import {ButtonModel} from '../../../../../interfaces/interfaces';
import LightButton from '../../../buttons/LightButton/LightButton';
import style from './failedContent.module.scss';

type FailedContentProps = {
  className?: string
  failedText?: string,
  error?: string,
  primaryButton: ButtonModel,
  secondaryButton: ButtonModel
  handleClose?: () => void
}

const FailedContent: React.FC<FailedContentProps> = React.memo((props) => {
  const {failedText, error, primaryButton, secondaryButton } = props;

  return (
    <div className={style.failedEndContainer}>
      <p className={style.message}> {t(failedText || 'public.operationFailed')} </p>
      { error ? <p className={style.error}> {t(error)}. </p> : null }

      <div className={style.buttons}>
        <LightButton
          className={style.buttons__did}
          text={primaryButton.label}
          disabled={false}
          functionality={primaryButton.function}
        />
        <div></div>
        <LightButton
          className={style.buttons__try}
          text={secondaryButton.label}
          disabled={false}
          functionality={secondaryButton.function}
        />
      </div>
    </div>
  )
})

export default FailedContent;
