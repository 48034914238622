import { createSelector } from 'reselect'
import { didTypeOptions } from '../../../assets/globalConstants'
import { Selectable } from '../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import { EndsStepsModel, IDIDModel } from '../../../interfaces/interfaces'
import { RootState } from '../../../store/indexReducers'
import { t } from 'i18next'
// List
export const isLoading = (state: RootState) =>
    state.didListReducer.loadingStatus
export const getdidConfigs = (state: RootState) =>
    addTypeToDid(
        state.didListReducer?.didsSetup?.didConfigs,
        state.catalogReducer?.authority,
        state.catalogReducer?.serviceProviders
    )
export const getdidConfigsVms = (state: RootState) =>
    toVmIds(state.didListReducer?.didsSetup?.didConfigs)
export const getDefaultDid = (state: RootState) =>
    state.didListReducer?.didsSetup?.defaultDid
export const getDids = (state: RootState) => state.didListReducer.didsSetup
export const getDidsForSelect = (state: RootState) =>
    getSelectableDIDs(
        state.didListReducer?.didsSetup?.didConfigs,
        state.sessionReducer.allDidsAllowed
    )

export const getDidsError = (state: RootState) => state.didListReducer.error

// Form steps
export const getActiveStep = (state: RootState) =>
    state.configDidFormReducer.step
export const getRegisterSteps = (state: RootState) =>
    state.configDidFormReducer.steps
export const getLastStep = (state: RootState) =>
    state.configDidFormReducer.finalStep
export const getEndsSteps = (state: RootState) => {
    const steps = state.configDidFormReducer.steps
    const activeStep = state.configDidFormReducer.step
    return activeStep && steps?.length
        ? getPreviousAndNextSteps(steps, activeStep)
        : null
}

// Config Did
export const getSelectedDid = (state: RootState) => state.configDidReducer.did
export const getSelectedDidToken = (state: RootState) =>
    state.configDidReducer.token
export const getSelectedDidBaseId = (state: RootState) =>
    state.configDidReducer.baseId
export const getStatus = (state: RootState) => state.configDidReducer.status
export const getIsKeypairLoading = (state: RootState) =>
    state.configDidReducer.isKeypairLoading
export const getIsSecretLoading = (state: RootState) =>
    state.configDidReducer.isSecretLoading
export const getError = (state: RootState) => state.configDidReducer.error

// EW Account Did
export const getEWSelectedDid = (state: RootState) =>
    state.EWDidAccountReducer.selectedDid

export const getEWDid = createSelector(
    [getEWSelectedDid, getdidConfigs],
    (selectedDid, dids) => {
        const selectedDidExists = dids?.find(
            (did) => did?.DidId === selectedDid
        )
        return selectedDid && selectedDidExists
            ? selectedDid
            : getDefaultDidId(dids)
    }
)

export const getEWDids = createSelector([getdidConfigs], (dids) => {
    return dids?.map((did, index) => {
        const alias =
            did?.alias ||
            t('ew.profile', {
                number: index || '0',
            })
        did ? (did.alias = alias) : null
        return did
    })
})

// Functions
const getDefaultDidId = (dids: IDIDModel[]) => {
    return dids?.find((did) => !!did?.default)?.DidId
}

const getPreviousAndNextSteps = (steps, activeStep): EndsStepsModel => {
    let nextStep
    let previousStep

    const lastStep = steps[steps.length]
    const nextIndex = steps.indexOf(activeStep) + 1
    const previousIndex = steps.indexOf(activeStep) - 1
    nextStep = activeStep !== lastStep ? steps[nextIndex] : null
    previousStep = activeStep !== steps[0] ? steps[previousIndex] : null

    return { previousStep, nextStep }
}

const toVmIds = (dids: IDIDModel[] | undefined): IDIDModel[] | undefined => {
    let sortedDids = [...getSortedDids(dids)]
    sortedDids?.forEach((el) => {
        if (el?.DidId && !el?.DidId.includes('#')) {
            el.DidId += '#keys-1'
        }
    })

    return sortedDids
}

const addTypeToDid = (dids, authority, serviceProvider) => {
    dids?.map((did) => {
        const isIssuanceType = authority?.find((auth) => auth.id === did.DidId)
        const isVerifierType = serviceProvider?.find(
            (sp) => sp.id === did.DidId
        )

        if (isIssuanceType && isVerifierType) {
            did.type = [didTypeOptions[0], didTypeOptions[1]]
        } else if (isIssuanceType) {
            did.type = [didTypeOptions[0]]
        } else if (isVerifierType) {
            did.type = [didTypeOptions[1]]
        }
        return did
    })

    return getSortedDids(dids)
}

const getSortedDids = (dids: IDIDModel[] | undefined) => {
    const sortedDids = dids || []
    const isDefault = (element) => element?.default
    const defaultDidIndex = sortedDids?.findIndex(isDefault)
    const defaultDid = sortedDids?.splice(defaultDidIndex, 1)[0]

    sortedDids?.sort(function (a, b) {
        const c = Date.parse(a.updatedAt || '0')
        const d = Date.parse(b.updatedAt || '0')

        return d - c
    })

    sortedDids.unshift(defaultDid)

    return sortedDids
}

export const getAliasFromDid = (
    options: IDIDModel[],
    didId?: string
): string | undefined => {
    const selectedDid = options.find(
        (did: IDIDModel) => did?.DidId?.split('#')[0] === didId?.split('#')[0]
    )
    return selectedDid?.alias || selectedDid?.DidId
}

export const getDidFromAlias = (
    options: IDIDModel[],
    aliasOrDid?: string
): string | undefined => {
    const selectedDid = options.find(
        (did: IDIDModel) =>
            did?.alias === aliasOrDid ||
            did?.DidId?.split('#')[0] === aliasOrDid?.split('#')[0]
    )
    return selectedDid?.DidId || selectedDid?.alias
}

export const ToIDIDModelArray = (
    values: Record<string, string>
): IDIDModel[] => {
    return Object.entries(values).map(
        ([key, value]) =>
            ({
                DidId: key,
                alias: value != key ? value : 'undefined',
            } as IDIDModel)
    )
}

export const getSelectableDIDs = (
    dids: IDIDModel[] | undefined,
    allDidsAllowed: boolean | undefined
): Selectable[] => {
    console.log('getSelectableDIDs dids', dids)
    let selectableDids = dids
        ? Object.keys(dids || {}).map((did) => {
              return {
                  key: dids[did]?.DidId,
                  value: dids
                      ? dids[did]?.alias
                          ? dids[did]?.alias + ' - ' + dids[did]?.DidId
                          : dids[did]?.DidId
                      : did,
              }
          })
        : []
    return selectableDids
}
