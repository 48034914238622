import { t } from 'i18next'
import React from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { configVerifierSelectors } from '../../..'
import { ReactComponent as BinIcon } from '../../../../../../assets/images/bin.svg'
import {
    DataAgreementEvent,
    ICredentialRequestedModel,
    IDataAgreementItem,
    IDIDModel,
    IVerifierConfigModel,
} from '../../../../../../interfaces/interfaces'
import { shortValue } from '../../../../../../utils/formatDataUtil'
import { didsSelectors } from '../../../../Dids'
import styles from './consentsCells.module.scss'
import cx from 'classnames'
import CopyIcon from '../../../../../../assets/iconsLibrary/simple/copy/copyIcon'

// CELLS COMPONENTS

export type TableContentProps = {
    item: IDataAgreementItem
    index?: number
    canReadVerifiers?: boolean
    selectItem?: (x?) => void
    deleteItem?: (x, y?, z?) => void
    issueCredential?: (x?) => void
    rejectCredential?: (x?) => void
    handleDropdownChange?: (x, y, z?) => void
}

const guessTemplateId = (da: IDataAgreementItem): string => {
    const configs = useSelector(configVerifierSelectors.getVerifiers)

    let foundConfig = configs?.find((config: IVerifierConfigModel) => {
        const isMandatoryAndPresent = (
            cred: ICredentialRequestedModel,
            index,
            array
        ) =>
            !cred.mandatory ||
            da.personal_data.find((pda) => pda.attribute_name === cred.type) !==
                undefined
        return config.credentials?.every(isMandatoryAndPresent)
    })
    return foundConfig?.id || t('consents.unknown_config')
}

export const ConsentTemplateCell: React.FC<TableContentProps> = (props) => {
    const { item, canReadVerifiers, index } = props
    const dids = useSelector(didsSelectors.getDids)
    const didAlias = dids?.didConfigs?.find(
        (did: IDIDModel) => did?.DidId === item.data_receiver?.id
    )?.alias
    const templateId = guessTemplateId(item)
    return (
        <td>
            <div className={styles.copyCell}>
                {canReadVerifiers ? (
                    <span>{templateId}</span>
                ) : (
                    <span className={styles.noViewPermissions}>
                        {t('public.viewPermissionBlocked')}
                    </span>
                )}
            </div>
            {/* <div className={styles.copyCell}>
                <span className="description">
                    {didAlias || item.data_receiver?.id}
                </span>
                <div
                    className={styles.copyCell__button}
                    onClick={() => {
                        navigator.clipboard.writeText(item.data_subject)
                    }}
                >
                    <CopyIcon />
                </div>
            </div> */}
        </td>
    )
}

export const ConsentUser: React.FC<TableContentProps> = (props) => {
    const { item, index } = props

    return (
        <td>
            <div className={styles.copyCell}>
                {item?.data_subject && (
                    <span className={cx('buttonSM neutral1000')}>
                        {shortValue(item.data_subject, 15, 5)}
                    </span>
                )}
                {item?.data_subject && (
                    <div
                        className={styles.copyCell__button}
                        onClick={() => {
                            navigator.clipboard.writeText(item.data_subject)
                        }}
                    >
                        <CopyIcon color="#1E1E20" size={16} />
                    </div>
                )}
            </div>
        </td>
    )
}

export const ConsentData: React.FC<TableContentProps> = (props) => {
    const { item, index } = props
    return (
        <td>
            <span className={cx('buttonSM neutral1000')}>
                {item.personal_data.map((pd) => pd.attribute_name).join(', ')}
            </span>
        </td>
    )
}

export const ConsentCreationDate: React.FC<TableContentProps> = (props) => {
    const { item, index } = props
    let timestamp = item.event?.find(
        (ev: DataAgreementEvent) => ev.state === 'Capture'
    )?.timestamp
    return (
        <td>
            <Moment className={cx('buttonSM neutral1000')} format="DD/MM/YYYY">
                {timestamp ? new Date(timestamp) : ''}
            </Moment>
        </td>
    )
}

export const ConsentDetailLink: React.FC<TableContentProps> = (props) => {
    const { item, index, selectItem } = props

    return (
        <td>
            <span
                onClick={() => (selectItem ? selectItem(item) : null)}
                className={cx('buttonSM primary700', styles.detailLink)}
            >
                {t('credentials.detailData')}
            </span>
        </td>
    )
}

export const ConsentDeleteButton: React.FC<TableContentProps> = (props) => {
    const { item, index, deleteItem } = props

    return (
        <td>
            {deleteItem ? (
                <BinIcon
                    className={styles.bin}
                    onClick={() => deleteItem(item)}
                />
            ) : null}
        </td>
    )
}
