import { BitString } from '../../../../../utils/formatDataUtil'
import {
    CredentialBase,
    CredentialStatus,
    CredentialStatusType,
    CredentialStatusWrapper,
    VerifiableCredential,
    credentialStatusTypes,
} from '../../data/requestCredentials.interfaces'
import {
    getBaseCredential,
    getType,
    resolveResource,
} from '../displayDetailsUtil'

export class CredentialStatusList2020 implements CredentialStatusWrapper {
    expectedType = 'RevocationList2020'
    expectedCred = this.expectedType + 'Credential'
    static myInstance: CredentialStatusList2020

    static getInstance() {
        if (!CredentialStatusList2020.myInstance) {
            CredentialStatusList2020.myInstance = new CredentialStatusList2020()
        }
        return this.myInstance
    }

    constructor() {}

    getType(): CredentialStatusType {
        return 'RevocationList2020Status'
    }

    apply(type: CredentialStatusType): boolean {
        return type === 'RevocationList2020Status'
    }

    async resolve(
        status: CredentialStatus,
        credentialId: string
    ): Promise<credentialStatusTypes> {
        if (
            !status.statusListCredential ||
            !status.statusListIndex ||
            !parseInt(status.statusListIndex)
        ) {
            // console.log('[StatusList2021]', 'Unable to solve reference')
            return 'INVALID'
        }

        try {
            const res = (await resolveResource(
                status.statusListCredential
            )) as VerifiableCredential
            return await this.validateCredential(
                getBaseCredential(res),
                parseInt(status.statusListIndex)
            )
        } catch (error) {
            // console.log(
            //     '[StatusList2021]',
            //     'Error retrieving status credential',
            //     error
            // )
        }

        return 'INVALID'
    }

    async validateCredential(
        statusCred: CredentialBase,
        index: number
    ): Promise<credentialStatusTypes> {
        // Validation not implemented in Studio

        if (getType(statusCred) !== this.expectedCred) {
            return 'INVALID'
        }

        if (
            !statusCred?.credentialSubject ||
            statusCred?.credentialSubject?.type !== this.expectedType
        ) {
            return 'INVALID'
        }

        if (!statusCred?.credentialSubject?.encodedList) {
            return 'INVALID'
        }

        const decoded = await BitString.decode(
            statusCred?.credentialSubject?.encodedList
        )
        let valid = BitString.bitAt(decoded, index)

        if (valid) {
            return 'ISSUED'
        }

        return 'REVOKED'
    }
}
