import cx from 'classnames'
import React from 'react'
import GaImage from '../../../../../../Components/atoms/Image/Image'
import { gaImages } from '../../../../../../assets/globalConstants'
import { t } from 'i18next'
import styles from '../tutorial.module.scss'

type IFourthSteprops = {}

const FourthStep: React.FC<IFourthSteprops> = (props) => {
    return (
        <div>
            <div className={cx(styles.image)}>
                <GaImage image={gaImages.getVerified} />
            </div>
            <div>
                <p className={cx('heading5')}>
                    {t('appIntegrations.tutorial.share')}
                </p>
                <p className={cx('bodyRegularMD neutral700 marginTop4')}>
                    {t('appIntegrations.tutorial.shareDesc')}
                </p>
            </div>
        </div>
    )
}

export default FourthStep
