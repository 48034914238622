import cx from 'classnames'
import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import packageJson from '../../../../../package.json'
import GaImage from '../../../../Components/atoms/Image/Image'
import { brandImages, brandLabels } from '../../../../assets/globalConstants'
import Notification from '../../Notification/Notification'
import { LinkModel } from '../../../../interfaces/interfaces'
import Loader from '../../Loader/screenLoader/ScreenLoader'
import { loginLink } from '../../Login/EmailLogin/emailLoginConstants'

// @ts-ignore
import { isDefaultBrandActive } from '../../../../utils/brandThemeUtil'
import panelStyles from './PanelScafoldingPublic.module.scss'
import { useDispatch } from 'react-redux'
import { stopNotificationsPolling } from '../../../../store/notifications/notificationsActions'

type IPanelSProps = {
    link?: LinkModel
    columnDisplay?: boolean
}

const PanelScafoldingPublic: React.FC<IPanelSProps> = (props) => {
    const { link, columnDisplay, children } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(stopNotificationsPolling())
    }, [])

    return (
        <Suspense fallback={Loader}>
            <>
                <header aria-label="Studio" className={panelStyles.topbar}>
                    <Link className={panelStyles.logoContainer} to="/login">
                        <GaImage image={brandImages?.logoPlatform} />
                    </Link>
                </header>

                <section className={panelStyles.panelContainer}>
                    <Notification className={panelStyles.extraPanel} />
                    <div
                        className={`${panelStyles.panelContainerChildren} ${
                            columnDisplay ? panelStyles.columnDisplay : ''
                        }`}
                    >
                        {children}
                    </div>
                </section>
                <footer className={panelStyles.footer}>
                    <ul>
                        <a
                            href={brandLabels?.privacypolicylink}
                            target="_blank"
                        >
                            <li className={cx('ButtonMD neutral700')}>
                                {t('public.privacy')}
                            </li>
                        </a>
                        <a
                            href={brandLabels?.termsOfServiceLink}
                            target="_blank"
                        >
                            <li className={cx('ButtonMD neutral700')}>
                                {t('public.termsAndConditions')}
                            </li>
                        </a>
                        <a
                            href={brandLabels?.documentationLink}
                            target="_blank"
                        >
                            <li className={cx('ButtonMD neutral700')}>
                                {t('public.documentation')}
                            </li>
                        </a>

                        <p
                            className={`${cx('ButtonMD neutral700')} ${
                                panelStyles.version
                            }`}
                        >
                            {isDefaultBrandActive() ? (
                                <Link
                                    className={`${cx('ButtonMD neutral700')} ${
                                        panelStyles.spaceLinks
                                    }`}
                                    to={link?.route || loginLink.route}
                                >
                                    {t(link?.label || loginLink.label)}
                                </Link>
                            ) : null}

                            {packageJson?.version &&
                                'v ' + packageJson?.version}
                        </p>
                    </ul>
                </footer>
            </>
        </Suspense>
    )
}

export default PanelScafoldingPublic
