import React from 'react'
import { StatusPage, StatusPageConfig } from 'react-healthy'

const Status: React.FC = () => {
    const statusPageConfig: StatusPageConfig = {
        apis: [
            {
                name: 'Studio',
                endpoint: 'https://studio.gataca.io/api/v1/health',
            },
            {
                name: 'Studio Connect',
                endpoint: 'https://connect.gataca.io/api/v1/health',
            },
            {
                name: 'Studio Certify',
                endpoint: 'https://certify.gataca.io/api/v1/health',
            },
            {
                name: 'Wallet Accounts',
                endpoint: 'https://accounts.gataca.io/api/v1/health',
            },
            {
                name: 'DID Registry',
                endpoint: 'https://dids.gataca.io/api/v1/health',
            },
            {
                name: 'Governance',
                endpoint: 'https://governance.gataca.io/api/v1/health',
            },
        ],
        interval: 30000,
    }

    return <StatusPage config={statusPageConfig} />
}

export default Status
