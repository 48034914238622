import { ActionStatusTypes } from '../../../../../../assets/globalConstants'
import { IRolesState, RolesAction, RolesActionTypes } from './rolesTypes'

const initialRolesState: IRolesState = {
    roles: [],
    selectedRole: undefined,
    rolesLoadingStatus: false,
    scopesLoadingStatus: false,
    status: ActionStatusTypes.neutral,
    error: '',
}

export function rolesReducer(
    state = initialRolesState,
    action: RolesAction
): IRolesState {
    switch (action.type) {
        case RolesActionTypes.GET_SCOPES:
            return {
                ...state,
                scopesLoadingStatus: true,
                status: ActionStatusTypes.neutral,
            }
        case RolesActionTypes.GET_SCOPES_SUCCESS:
            return {
                ...state,
                scopes: action.payload.scopes,
                scopesLoadingStatus: false,
            }
        case RolesActionTypes.GET_SCOPES_FAILED:
            return {
                ...state,
                scopesLoadingStatus: false,
            }
        case RolesActionTypes.GET_ROLES:
            return {
                ...state,
                rolesLoadingStatus: true,
                selectedRole: undefined,
            }
        case RolesActionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload.roles,
                rolesLoadingStatus: false,
            }
        case RolesActionTypes.GET_ROLES_FAILED:
            return {
                ...state,
                rolesLoadingStatus: false,
            }
        case RolesActionTypes.CREATE_ROLE:
            return {
                ...state,
                selectedRole: action.payload.role,
                rolesLoadingStatus: true,
                status: ActionStatusTypes.loading,
                error: '',
            }
        case RolesActionTypes.CREATE_ROLE_SUCCESS:
            return {
                ...state,
                rolesLoadingStatus: false,
                status: ActionStatusTypes.success,
                error: '',
            }
        case RolesActionTypes.CREATE_ROLE_FAILED:
            return {
                ...state,
                rolesLoadingStatus: false,
                status: ActionStatusTypes.failed,
                error: action.payload?.error,
            }
        case RolesActionTypes.GET_ROLE:
            return {
                ...state,
                rolesLoadingStatus: true,
                selectedRole: undefined,
            }
        case RolesActionTypes.GET_ROLE_SUCCESS:
            return {
                ...state,
                selectedRole: action.payload.role,
                rolesLoadingStatus: false,
            }
        case RolesActionTypes.GET_ROLE_FAILED:
            return {
                ...state,
                rolesLoadingStatus: false,
            }
        case RolesActionTypes.UPDATE_ROLE:
            return {
                ...state,
                selectedRole: action.payload.role,
                rolesLoadingStatus: true,
                status: ActionStatusTypes.loading,
                error: '',
            }
        case RolesActionTypes.UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                rolesLoadingStatus: false,
                status: ActionStatusTypes.success,
                error: '',
            }
        case RolesActionTypes.UPDATE_ROLE_FAILED:
            return {
                ...state,
                rolesLoadingStatus: false,
                status: ActionStatusTypes.failed,
                error: action.payload?.error || '',
            }
        case RolesActionTypes.SELECT_ROLE:
            return {
                ...state,
                rolesLoadingStatus: false,
                selectedRole: action.payload.role,
            }
        case RolesActionTypes.CLEAR_ACTION_STATUS:
            return {
                ...state,
                status: ActionStatusTypes.neutral,
            }
        case RolesActionTypes.CLEAR_ROLE_LIST:
            return initialRolesState as IRolesState
        default:
            return state
    }
}
