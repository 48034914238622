import React, { useState } from 'react'
import styles from './profilesModal.module.scss'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import SearchBar from '../../../searchBar/SearchBar'
import { gaImages } from '../../../../../assets/globalConstants'
import Description from '../../../../atoms/texts/Description/Description'
import DarkButton from '../../../../atoms/buttons/DarkButton/DarkButton'
import CloseIcon from '../../../../../assets/iconsLibrary/simple/close/closeIcon'
import { t } from 'i18next'
import cx from 'classnames'
import { ButtonSizes } from '../../../../../data/globalVar'
import { propertiesToSearchDids } from '../../data/didsSelector.constants'
import SelectableDidCard from '../selectableDidCard/SelectableDidCard'

type IProfilesModalProps = {
    options?: IDIDModel[]
    show: boolean
    selectedDid?: string
    hideModal: () => void
    onChange: (x?) => void
}

const ProfilesModal: React.FC<IProfilesModalProps> = (props) => {
    const { hideModal, onChange, selectedDid, show, options } = props

    const [selectedValue, setSelectedValue] = useState(selectedDid)
    const [state, setState] = React.useState<{
        searched: string
        searchedResult: IDIDModel[]
    }>({
        searched: '',
        searchedResult: options?.slice() || [],
    })

    function confirmSelection() {
        onChange(selectedValue)
        hideModalAndClean()
    }

    function handleSearchedChange(e) {
        setState({
            searched: state.searched,
            searchedResult: e,
        })
    }

    function hideModalAndClean() {
        setSelectedValue(selectedDid)
        setState({
            searched: '',
            searchedResult: options?.slice() || [],
        })
        hideModal()
    }

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            }`}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <p className={cx('bodyRegularMD neutral1000')}>
                        {t('ew.profiles')}
                    </p>

                    <div
                        className={styles.modal__header__close}
                        onClick={() => hideModalAndClean()}
                    >
                        <CloseIcon size={24} color="#1E1E20" />
                    </div>
                    <div className={`${styles.modal__content}`}>
                        <SearchBar
                            className={styles.searchBar}
                            options={options || []}
                            propertiesToSearch={propertiesToSearchDids}
                            icon={gaImages.searchBar}
                            cleanIcon={true}
                            handleSearchedChange={handleSearchedChange}
                            placeholder={'public.search'}
                        />
                        {state.searchedResult?.length ? (
                            state.searchedResult?.map(
                                (item: IDIDModel, index: number) => {
                                    return (
                                        <SelectableDidCard
                                            item={item}
                                            index={index}
                                            selectedDid={selectedValue}
                                            select={(e) =>
                                                setSelectedValue(e?.DidId)
                                            }
                                        />
                                    )
                                }
                            )
                        ) : (
                            <Description text={'public.noResultsForSearch'} />
                        )}
                    </div>
                    <div className={styles.modal__footer}>
                        <DarkButton
                            className={styles.modal__footer__primaryButton}
                            functionality={confirmSelection}
                            text={'ew.confirmSelection'}
                            disabled={false}
                            size={ButtonSizes.MD}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfilesModal
