import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ActionStatusTypes } from '../../../../../../../assets/globalConstants'
import StatusPanel from '../../../../../../../Components/atoms/panels/StatusPanel/StatusPanel'
import { IUserItem } from '../../../../../../../interfaces/interfaces'
import { setSelectedRoute } from '../../../../../PanelScafolding/scafoldingActions'
import UpdateForm from '../forms/updateForm/UpdateForm'
import {
    clearActionStatus,
    clearUser,
    clearUserList,
    updateUser,
} from '../../store/usersActions'
import {
    getError,
    getSelectedUser,
    getStatus,
} from '../../store/usersSelectors'
import styles from './configUser.module.scss'

type IConfigUserProps = {
    hideUserModal: () => void
}

const ConfigUser: React.FC<IConfigUserProps> = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const selectedUser = useSelector(getSelectedUser)
    const configStatus = useSelector(getStatus)
    const configError = useSelector(getError)

    const forwardFunction = (data: IUserItem) => {
        dispatch(updateUser(data, selectedUser?.email || ''))
    }

    const handleCloseModal = () => {
        clearState(), dispatch(clearActionStatus())
    }

    const navigate = (route) => {
        dispatch(setSelectedRoute(route)), history.push(route)
    }
    const goToHome = () => {
        clearState(), navigate('home')
    }
    const goToUsersList = () => {
        clearState(), navigate('team')
    }

    const clearState = () => {
        dispatch(clearUser())
        dispatch(clearActionStatus())
    }

    useEffect(() => {}, [configStatus, selectedUser])

    return (
        <>
            {/* <ScreenHeader
                title={'configUser.title'}
                subText={selectedUser?.email}
            /> */}
            <div className={styles.editUser}>
                <UpdateForm
                    buttonFunction={forwardFunction}
                    currentUser={selectedUser!}
                    closeModal={props?.hideUserModal}
                />
            </div>

            <StatusPanel
                insideModal
                successMessage={'configUser.success'}
                state={'configUser.isReady'}
                operationStatus={configStatus}
                show={
                    configStatus &&
                    (configStatus as ActionStatusTypes) !==
                        ActionStatusTypes.neutral
                }
                failedText={t('users.updateUserError')}
                error={configError || ''}
                primaryButton={{
                    label: 'configUser.backToList',
                    function: goToUsersList,
                }}
                secondaryButton={{
                    label: 'configUser.backToHome',
                    function: goToHome,
                }}
                handleClose={() => {
                    clearState(), handleCloseModal()
                }}
            />
        </>
    )
}

export default ConfigUser
