import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { apiKeysSelectors } from '../..'
import {
    ActionStatusTypes,
    gaImages,
} from '../../../../../assets/globalConstants'
import StatusPanel from '../../../../../Components/atoms/panels/StatusPanel/StatusPanel'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import { IApiKeyModel } from '../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { getDids } from '../../../Dids/didActions'
import { getIssuances } from '../../../Issuance/issuanceActions'
import { setSelectedRoute } from '../../../../common/PanelScafolding/scafoldingActions'
import { sessionSelectors } from '../../../../common/session'
import { getVerifierConfigs } from '../../../Verifier/verifierActions'
import {
    clearActionStatus,
    clearFormState,
    updateApiKey,
} from '../../apiKeysActions'
import { getSelectedApp } from '../../apiKeysSelectors'
import UpdateForm from '../../forms/updateForm/UpdateForm'
import styles from './configApiKey.module.scss'

type IConfigApiKeyProps = {}

const ConfigApiKey: React.FC<IConfigApiKeyProps> = ({}) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }
    scrollToTop()

    const selectedApiKey = useSelector(getSelectedApp)
    const configStatus = useSelector(apiKeysSelectors.getStatus)
    const configError = useSelector(apiKeysSelectors.getError)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const canReadDids = !!(
        availableScopes &&
        userHasScopes(['readDids', 'readTenants'], availableScopes, true)
    )
    const canReadSSIConfigs = !!(
        availableScopes && userHasScopes(['readSSIConfigs'], availableScopes)
    )
    useEffect(() => {
        if (canReadSSIConfigs) {
            dispatch(getVerifierConfigs())
            dispatch(getIssuances())
        }
        canReadDids && dispatch(getDids())
        return () => {
            dispatch(clearFormState())
        }
    }, [])

    const forwardFunction = (data: any) => {
        const simplifiedDid = data as IApiKeyModel
        dispatch(updateApiKey(simplifiedDid, selectedApiKey.id))
    }

    const handleCloseModal = () => dispatch(clearActionStatus())

    const navigate = (route) => {
        setActiveRoute(route), history.push(route)
    }
    const goToHome = () => {
        clearState(), navigate('home')
    }
    const goToList = () => {
        clearState(), navigate('api_keys')
    }

    const setActiveRoute = (route: string) => {
        dispatch(setSelectedRoute(route))
    }

    const clearState = () => {
        dispatch(clearFormState())
        dispatch(clearActionStatus())
    }

    return (
        <>
            <ScreenHeader
                title={'configApiKey.title'}
                titleClassname="heading5"
                subText="configApiKey.description"
            />

            <div className={styles.editApiKey}>
                <UpdateForm
                    buttonFunction={forwardFunction}
                    currentApiKey={selectedApiKey}
                />
            </div>

            <StatusPanel
                successMessage={'configApiKey.success'}
                state={'configApiKey.isReady'}
                operationStatus={configStatus}
                show={
                    configStatus &&
                    (configStatus as ActionStatusTypes) !==
                        ActionStatusTypes.neutral
                }
                failedText={
                    t('configApiKey.failedOperation') +
                    (configError ? ': ' : '.')
                }
                error={configError || ''}
                primaryButton={{
                    label: 'createApiKey.backToList',
                    function: goToList,
                }}
                secondaryButton={{
                    label: 'createApiKey.backToHome',
                    function: goToHome,
                }}
                handleClose={handleCloseModal}
            />
        </>
    )
}

export default ConfigApiKey
