import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { gaImages } from '../../../../../../assets/globalConstants'
import {
    ButtonModel,
    ImageModel,
} from '../../../../../../interfaces/interfaces'
import GaImage from '../../../../../atoms/Image/Image'
import styles from './warningFilledPanel.module.scss'
import { ReactComponent as CloseIcon } from '../../../../../../assets/images/close.svg'
import cx from 'classnames'
import LightButton from '../../../../../atoms/buttons/LightButton/LightButton'

type IWarningFilledPanelProps = {
    title?: string
    label: string
    label2?: string
    label3?: string
    hasIcon?: boolean
    hasTitle?: boolean
    icon?: ImageModel
    rightButton?: ButtonModel
    leftButton?: ButtonModel
    titleExtraParams?: Object
    className?: string
    closeButton?: {
        action: (x?) => void
    }
}

const WarningFilledPanel: React.FC<IWarningFilledPanelProps> = (props) => {
    const {
        title,
        label,
        rightButton,
        leftButton,
        label2,
        label3,
        hasIcon,
        hasTitle,
        icon,
        closeButton,
        className,
        titleExtraParams,
    } = props
    const { t } = useTranslation()

    return (
        <div className={styles.warningFilledPanel}>
            <div className={styles.warningFilledPanel__header}>
                {/* {hasIcon && <GaImage image={icon || gaImages.warningIcon} />} */}
                {hasTitle ? (
                    <p
                        className={`${
                            rightButton || leftButton ? '' : styles.fullWidth
                        } ${styles.title} ${cx('bodyBoldSM neutral1000')}`}
                    >
                        {!title ? (
                            t('public.error')
                        ) : (
                            <Trans i18nKey={title} values={titleExtraParams} />
                        )}
                    </p>
                ) : null}

                {closeButton && (
                    <div onClick={closeButton?.action}>
                        <CloseIcon />
                    </div>
                )}
            </div>

            <div className={styles.warningFilledPanel__content}>
                <div
                    className={`${
                        rightButton || leftButton ? '' : styles.fullWidth
                    } ${styles.warningFilledPanel__content__texts}`}
                >
                    <p className={cx('bodyRegularSM neutral1000')}>
                        <Trans i18nKey={label} />
                    </p>
                    <p className={cx('bodyRegularSM neutral1000')}>
                        {label2 && <Trans i18nKey={label2} />}
                    </p>
                    <p className={cx('bodyRegularSM neutral1000')}>
                        {label3 && <Trans i18nKey={label3} />}
                    </p>
                </div>
                <div className={styles.warningFilledPanel__content__buttons}>
                    {leftButton && (
                        <LightButton
                            className={styles.button}
                            text={leftButton.label}
                            disabled={false}
                            functionality={leftButton.function}
                        />
                    )}
                    {rightButton && (
                        <LightButton
                            className={styles.button}
                            text={rightButton.label}
                            disabled={false}
                            functionality={rightButton.function}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default WarningFilledPanel
