export const propertiesToSearchDids = [
    {
        text: 'alias',
        searchMultipleLanguages: false,
        latinize: true,
    },
    {
        text: 'DidId',
        searchMultipleLanguages: false,
        latinize: true,
    },
]
