import React from 'react'
import { useTranslation } from 'react-i18next'
import { StepModel } from '../../../../interfaces/interfaces'
import cx from 'classnames'
import styles from './lineProgressBar.module.scss'
import DefaultCircleIcon from '../../../../assets/iconsLibrary/stepper-progress-bar/default-circle/defaultCircleIcon'
import ActiveCircleIcon from '../../../../assets/iconsLibrary/stepper-progress-bar/active-circle/activeCircleIcon'
import CompletedCircleIcon from '../../../../assets/iconsLibrary/stepper-progress-bar/completed-circle/completedCircleIcon'
import { brandColors } from '../../../../assets/globalConstants'
import WarningCircleIcon from '../../../../assets/iconsLibrary/stepper-progress-bar/warning-circle/warningCircleIcon'
import {
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../data/globalVar'
import GaHoverTooltip from '../../tooltips/GaHoverTooltip/GaHoverTooltip'

type LineProgressBarProps = {
    activeStep: StepModel
    inactiveSteps?: number[]
    formSteps: StepModel[]
    notClickableSteps?: boolean
    className?: string
    onHandleChange: (step) => void
}

const LineProgressBar: React.FC<LineProgressBarProps> = React.memo((props) => {
    const { t } = useTranslation()
    const {
        activeStep,
        formSteps,
        inactiveSteps,
        notClickableSteps,
        className,
        onHandleChange,
    } = props

    const currentIndex = activeStep?.order

    const stepCompleted = (index: number) => currentIndex > index
    const stepActive = (index: number) => currentIndex === index
    const stepInactive = (index: number) => inactiveSteps?.includes(index)

    return (
        <div
            className={cx(
                styles.lineProgressBar__container,
                className && className
            )}
        >
            <div className={styles.lineProgressBar}>
                <ul className={styles.progressBar}>
                    {formSteps.map((step, index) => {
                        return stepInactive(index) ? (
                            <li
                                className={cx(
                                    stepCompleted(index) && styles.completed,
                                    stepActive(index) && styles.active,
                                    stepActive(index) && styles.uncompleted,
                                    stepInactive(index) && styles.inactive
                                )}
                                key={index}
                            >
                                <GaHoverTooltip
                                    className={`${
                                        styles.lineStepperHoverTooltip
                                    } ${
                                        index === formSteps?.length - 1 &&
                                        styles.lastStepperHoverTooltip
                                    }`}
                                    position={TooltipPosition.bottom}
                                    positionArrowDirection={
                                        TooltipArrowPosition.center
                                    }
                                    label={
                                        <>
                                            <span
                                                className={
                                                    styles.progressCircle
                                                }
                                            >
                                                {stepCompleted(index) ? (
                                                    <CompletedCircleIcon
                                                        color={
                                                            brandColors.primaryColor
                                                        }
                                                    />
                                                ) : stepActive(index) ? (
                                                    <ActiveCircleIcon
                                                        color={
                                                            brandColors.primaryColor
                                                        }
                                                    />
                                                ) : stepInactive(index) ? (
                                                    <WarningCircleIcon />
                                                ) : (
                                                    <DefaultCircleIcon />
                                                )}
                                            </span>
                                            {step?.label && (
                                                <button
                                                    type="button"
                                                    className={`${
                                                        styles.progress_text
                                                    } ${cx(
                                                        stepCompleted(index) ||
                                                            stepActive(index)
                                                            ? 'buttonSM'
                                                            : 'bodyRegularSM neutral700'
                                                    )}`}
                                                >
                                                    {t(step?.label)}
                                                </button>
                                            )}
                                        </>
                                    }
                                    info={step?.disabledInfo || ''}
                                    hideIcon={true}
                                />
                            </li>
                        ) : (
                            <li
                                className={cx(
                                    stepCompleted(index) && styles.completed,
                                    stepActive(index) && styles.active,
                                    stepActive(index) && styles.uncompleted,
                                    stepInactive(index) && styles.inactive
                                )}
                                key={index}
                                onClick={() =>
                                    !notClickableSteps
                                        ? onHandleChange(index)
                                        : {}
                                }
                            >
                                <span className={styles.progressCircle}>
                                    {stepCompleted(index) ? (
                                        <CompletedCircleIcon
                                            color={brandColors.primaryColor}
                                        />
                                    ) : stepActive(index) ? (
                                        <ActiveCircleIcon
                                            color={brandColors.primaryColor}
                                        />
                                    ) : stepInactive(index) ? (
                                        <WarningCircleIcon />
                                    ) : (
                                        <DefaultCircleIcon />
                                    )}
                                </span>
                                {step?.label && (
                                    <button
                                        type="button"
                                        className={`${
                                            styles.progress_text
                                        } ${cx(
                                            stepCompleted(index) ||
                                                stepActive(index)
                                                ? 'buttonSM'
                                                : 'bodyRegularSM neutral700'
                                        )}`}
                                    >
                                        {t(step?.label)}
                                    </button>
                                )}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
})

export default LineProgressBar
