import React from 'react'
import { useTranslation } from 'react-i18next'
import { StepModel } from '../../../interfaces/interfaces'
import styles from './numberStepper.module.scss'

type NumberStepperProps = {
    steps: StepModel[]
    currentStep: StepModel
    nextStepActive?: boolean
    previousStepsAreInactive?: boolean
    onChangeStep: (x) => void
}

const NumberStepper: React.FC<NumberStepperProps> = React.memo((props) => {
    const {
        steps,
        currentStep,
        nextStepActive,
        previousStepsAreInactive,
        onChangeStep,
    } = props

    const { t } = useTranslation()

    const onHandleStepChange = (step) => {
        const stepIsSameAsCurrent = currentStep?.id === step?.id
        const stepIsPriorToTheCurrent = step?.order > currentStep?.order
        const stepIsLowerToTheCurrent = step?.order < currentStep?.order
        const stepIsOneGreaterThanCurrent =
            step?.order - currentStep?.order === 1

        if (
            !stepIsSameAsCurrent &&
            (stepIsPriorToTheCurrent || !previousStepsAreInactive) &&
            (stepIsLowerToTheCurrent ||
                (nextStepActive && stepIsOneGreaterThanCurrent))
        ) {
            onChangeStep(step)
        }
    }

    return (
        <div className={styles.numberStepper}>
            {steps.map((step, index) => (
                <div
                    onClick={() => onHandleStepChange(step)}
                    className={`${styles.step} ${
                        currentStep?.id === step?.id ? styles.activeStep : ''
                    } ${
                        step?.order > currentStep?.order && !nextStepActive
                            ? styles.inactiveStep
                            : ''
                    }`}
                >
                    <div className={styles.step__order} key={index}>
                        <p>{step.order}</p>
                    </div>
                    <span className={styles.step__label}>{t(step.label)}</span>
                </div>
            ))}
        </div>
    )
})

NumberStepper.defaultProps = {}

export default NumberStepper
