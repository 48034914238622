import {
    IIssuanceConfigItemModel,
    CertificateModel,
} from '../../../interfaces/interfaces'
import { RootState } from '../../../store/indexReducers'
// List
export const isLoading = (state: RootState) =>
    state.certificatesReducer.loadingStatus
export const getCertificatesList = (state: RootState) => {
    return state.certificatesReducer.certificatesResponse
}
export const getCertificatesError = (state: RootState) =>
    state.didListReducer.error
export const getSelectedCertificate = (state: RootState) =>
    getQSWithRelatedIssuances(
        state.certificatesReducer.selectedCertificate,
        state.issuancesReducer.issuances
    )

export const getQSFromID = (
    options: CertificateModel[],
    id?: string
): string | undefined => {
    const selectedQS = options.find((qs: CertificateModel) => qs?.id === id)
    return selectedQS?.alias || selectedQS?.id
}

export const getQSFromAlias = (
    options: CertificateModel[],
    alias?: string
): string | undefined => {
    const selectedQS = options.find(
        (qs: CertificateModel) => qs?.alias === alias
    )
    return selectedQS?.id || selectedQS?.alias
}

export const getQSWithRelatedIssuances = (
    qs?: CertificateModel,
    issuanceTemps?: IIssuanceConfigItemModel[] | null
) => {
    if (qs) {
        let qsWithTemplates: any = qs
        const selectedQSIssuances = issuanceTemps?.filter(
            (issuanceTemp: IIssuanceConfigItemModel) =>
                issuanceTemp?.prConfig?.certificateId === qs?.id
        )
        qsWithTemplates.templates = selectedQSIssuances
        return qsWithTemplates
    }
}
