import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setSelectedRoute } from '../../features/common/PanelScafolding/scafoldingActions'
import Description from '../atoms/texts/Description/Description'
import cx from 'classnames'
// @ts-ignore
import styles from './createBlock.module.scss'
import PlusIcon from '../../assets/iconsLibrary/simple/plus/plusIcon'
import { gaColors } from '../../assets/globalConstants'
import InfoCircleFilledIcon from '../../assets/iconsLibrary/simple/info/filled/ga-info-circle-filled'
import ArrowLeftDiagonalCircleIcon from '../../assets/iconsLibrary/arrowLeftDiagonalCircle/arrowLeftDiagonalCircleIcon'

type ICreateBlockProps = {
    title: string | React.ReactNode
    description?: string
    type?: string
    route: string
    className?: string
    status?: 'needUpgrade' | 'noPermissions'
    action?: () => void
}

const CreateBlock: React.FC<ICreateBlockProps> = (props) => {
    const { title, description, route, status, className, action } = props

    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const needUpgrade = status === 'needUpgrade'
    const noPermissions = status === 'noPermissions'
    const disabled = needUpgrade || noPermissions

    const [showInfo, setShowInfo] = useState(false)

    const navigate = (route) => {
        setActiveRoute(route)
        history.push(route)
    }

    const setActiveRoute = (route: string) => {
        const servicesRouteActive =
            route === 'issuance_templates' || 'verification_templates'
        if (servicesRouteActive) {
            dispatch(setSelectedRoute(route))
        }
    }

    const performAction = () => {
        action ? action() : navigate(route)
    }

    return (
        <div
            className={`${styles.homeBlock} ${className}`}
            onClick={() => (!disabled ? performAction() : undefined)}
            onMouseEnter={() => setShowInfo(true)}
            onMouseLeave={() => setShowInfo(false)}
        >
            <div
                className={cx(
                    styles.homeBlock__icon,
                    !!disabled && styles.homeBlock__iconDisabled
                )}
            >
                {!disabled ? (
                    <PlusIcon color="white" size={24} />
                ) : (
                    <>
                        {needUpgrade ? (
                            <ArrowLeftDiagonalCircleIcon
                                style={'filled'}
                                color={gaColors.neutral600}
                                size={40}
                                className={styles.upgradeIcon}
                            />
                        ) : (
                            <InfoCircleFilledIcon
                                colorFill={gaColors.neutral600}
                                colorStroke={gaColors.neutral400}
                                size={40}
                            />
                        )}
                        {!!showInfo ? (
                            <div
                                className={cx(
                                    'bodyRegularXS neutral100',
                                    styles.homeBlock__textTooltipContainer
                                )}
                            >
                                <div
                                    className={`${styles.gaTooltip__info}  ${cx(
                                        'bodyRegularXS neutral100'
                                    )}`}
                                >
                                    {t(
                                        needUpgrade
                                            ? 'public.upgradeSubscriptionToAccessFromTrial'
                                            : 'public.notNecessaryPermission'
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </>
                )}
            </div>

            <div className={styles.homeBlock__info}>
                <h3
                    className={`${styles.title__text} ${cx(
                        'heading6 neutral100'
                    )}`}
                >
                    {title}
                </h3>

                {description ? (
                    <Description
                        className={cx('bodyRegularSM neutral100 marginTop8')}
                        text={description}
                    />
                ) : null}
            </div>
        </div>
    )
}

CreateBlock.defaultProps = {}

export default CreateBlock
