import { brandPaymentMethod } from '../assets/globalConstants'
import { NumberFormatOptions } from '../interfaces/interfaces'

export const onPremise = process.env.REACT_APP_TYPE?.toString() !== 'gaas'
export const noPaymentMethod = brandPaymentMethod?.includes('None')

export const formatNumbersDefaultOptions: NumberFormatOptions = {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
}
export enum ButtonSizes {
    LG = 'LG',
    MD = 'MD',
    SM = 'SM',
}

export enum TextSizes {
    LG = 'LG',
    MD = 'MD',
}

export enum CheckboxSizes {
    MD = 'MD',
    SM = 'SM',
}

export enum LabelPosition {
    right = 'right',
    left = 'left',
}

export enum TooltipPosition {
    right = 'right',
    left = 'left',
    bottom = 'bottom',
    top = '',
}

export enum TooltipArrowPosition {
    right = 'right',
    left = 'left',
    center = '',
}

export enum badgetBGColor {
    primary1000 = 'primary1000_BG',
    primary700 = 'primary700_BG',
    neutral600 = 'neutral600_BG',
}

export enum LoaderSizes {
    XL = 'XL',
    MD = 'MD',
    SM = 'SM',
}
