import cx from 'classnames'
import { t } from 'i18next'
import React, { useState } from 'react'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import MediumModal from '../../../../../Components/molecules/modals/Medium/MediumModal'
import styles from './uploadModal.module.scss'

type ICertificateUploadModalProps = {
    show: boolean
    hideModal: () => void
    uploadCertificate: (file: File, alias?: string, password?: string) => void
}

type InitialState = {
    alias?: string
    password?: string
    file: File | undefined
}

const CertificatesUploadModal: React.FC<ICertificateUploadModalProps> = (
    props
) => {
    const { hideModal, uploadCertificate, show } = props

    const initialFormState: InitialState = {
        alias: '',
        password: '',
        file: undefined,
    }
    function hideModalAndClean() {
        hideModal()
        setFormState(initialFormState)
    }
    const [form, setFormState] = useState(initialFormState)

    const validForm = !!form?.file !== undefined

    const uploadCertificateFunc = () => {
        uploadCertificate(form?.file!, form?.alias, form?.password),
            hideModalAndClean()
    }

    return (
        <MediumModal
            title={'certificates.uploadNewModalTitle'}
            headerClose={true}
            show={show}
            className={styles.uploadModal}
            handleClose={hideModalAndClean}
            primaryButton={{
                label: 'certificates.upload',
            }}
            primaryButtonDisabled={!validForm}
            secondaryButton={{
                label: 'public.cancel',
                function: hideModalAndClean,
            }}
            primaryButtonFunction={uploadCertificateFunc}
        >
            <div className={styles.detailContainer}>
                <GaTextInput
                    id={'alias'}
                    invalid={!form?.alias?.trim()?.length}
                    labelText={t('certificates.alias')}
                    required={false}
                    name={'alias'}
                    onChange={(e) => {
                        setFormState({ ...form, alias: e.target.value })
                    }}
                    placeholder={t('certificates.aliasPlaceholder')}
                    value={form.alias}
                />
                <GaTextInput
                    className={`${cx('marginTop20 marginBottom20')}`}
                    id={'passw'}
                    invalid={!form?.password?.trim()?.length}
                    labelText={t('certificates.password')}
                    required={false}
                    name={'passw'}
                    onChange={(e) => {
                        setFormState({ ...form, password: e.target.value })
                    }}
                    placeholder={t('certificates.passwordPlaceholder')}
                    value={form.password}
                />
                <p className={`${cx('bodyRegularXS')}`}>
                    {t('certificates.selectAfile')}
                </p>
                <input
                    type="file"
                    onChange={(e) => {
                        setFormState({
                            ...form,
                            file: e?.target?.files
                                ? e.target.files[0]
                                : undefined,
                        })
                    }}
                />
                {/* <div>{t('certificates.uploadAreaPlaceholder')}</div> */}
            </div>
        </MediumModal>
    )
}

export default CertificatesUploadModal
