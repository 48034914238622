import { Epic, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'
import dataService from '../../../services/dataService'
import {
    displayScanError,
    displayScanNavigationError,
    displayScanNavigationSucess,
    displayScanSucess,
    getAUStatisticsFailed,
    getAUStatisticsSuccess,
    getICStatisticsFailed,
    getICStatisticsSuccess,
    getNotificationsFailed,
} from './homeActions'
import { HomeActionsTypes } from './homeTypes'

export const requestNotificationsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(HomeActionsTypes.GET_NOTIFICATIONS),
        switchMap((action: any): any => {
            // TODO : Create services methods when backend is ready
            catchError((error) =>
                of(
                    // TODO : Create notification error component to manage failed action
                    getNotificationsFailed()
                )
            )
        })
    )
}

export const displaySandboxEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(HomeActionsTypes.DISPLAY_SCAN),
        switchMap((action: any): any => {
            return from(dataService.requestSbxInvitation()).pipe(
                map((response: any) => displayScanSucess(response?.response)),
                catchError((error) => {
                    return of(displayScanError(error.response?.message))
                })
            )
        })
    )
}

export const displayNavigationSandboxEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(HomeActionsTypes.DISPLAY_SCAN_NAVIGATION),
        switchMap((action: any): any => {
            return from(dataService.requestSbxInvitation()).pipe(
                map((response: any) =>
                    displayScanNavigationSucess(response?.response)
                ),
                catchError((error) => {
                    return of(
                        displayScanNavigationError(error.response?.message)
                    )
                })
            )
        })
    )
}

export const getAUStatisticsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(HomeActionsTypes.GET_AU_STATISTICS),
        delay(100),
        switchMap((action: any): any => {
            const params = action.payload?.params
            const id = action.payload?.id
            return from(dataService.getAdminStatistics(params, id)).pipe(
                map((response: any) =>
                    getAUStatisticsSuccess(response?.response, id)
                ),
                catchError((error) => of(getAUStatisticsFailed(error)))
            )
        })
    )
}

export const getICStatisticsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(HomeActionsTypes.GET_IC_STATISTICS),
        delay(100),
        switchMap((action: any): any => {
            const params = action.payload?.params
            const id = action.payload?.id
            return from(dataService.getAdminStatistics(params, id)).pipe(
                map((response?: any) => {
                    return getICStatisticsSuccess(response?.response, id)
                }),
                catchError((error) => of(getICStatisticsFailed(error)))
            )
        })
    )
}
