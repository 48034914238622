import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { brandColors, gaImages } from '../../../assets/globalConstants'
import { sessionSelectors } from '../../../features/common/session'
import { getLicenseType } from '../../../features/common/session/sessionSelectors'
import { LinkModel } from '../../../interfaces/interfaces'
import GaImage from '../../atoms/Image/Image'
import styles from './expandableMenu.module.scss'
import { licensesSelectors } from '../../../features/common/licenses'
import { getOrg } from '../../../features/common/organization/organizationSelectors'
import GaHoverTooltip from '../tooltips/GaHoverTooltip/GaHoverTooltip'
import { TooltipArrowPosition, TooltipPosition } from '../../../data/globalVar'
import { getResponsive } from '../../../utils/formUtils'

type IExpandableMenuProps = {
    item: LinkModel
    expanded?: string[]
    selected: string
    setExpanded: (x) => void
    setSelected: (x) => void
    setShowSandboxPanel?: (x) => void
}

const ExpandableMenu: React.FC<IExpandableMenuProps> = (props) => {
    const {
        item,
        expanded,
        selected,
        setExpanded,
        setSelected,
        setShowSandboxPanel,
    } = props
    const history = useHistory()
    const { t } = useTranslation()
    const supportLink = process.env.REACT_APP_SUPPORT_URL
    const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const uRole = useSelector(sessionSelectors.getUserRole_s)
    const userLicense = useSelector(sessionSelectors.getLicenses)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const org = useSelector(getOrg)
    const orgId = useSelector(sessionSelectors.getUserOrganization)

    const organizationId = org?.id
    const userPaymnetByStripe =
        subscriptionInfo?.currentTierId &&
        !subscriptionInfo?.paymentOutsideStripe
    const currentLicenseType: string = useSelector(getLicenseType)
    const cantAccessSupport = currentLicenseType === 'Starter'
    const canAccessTeam =
        currentLicenseType !== 'Starter' && currentLicenseType !== 'Essential'
    const userIsOrgOwner = uRole === 'OrganizationOwner'
    const thereIsDue = subscriptionInfo?.subscriptionStatus === 'past_due'
    const canceledLicense =
        subscriptionInfo?.subscriptionStatus === 'unpaid' ||
        subscriptionInfo?.subscriptionStatus === 'canceled'
    const isTrialing =
        subscriptionInfo?.subscriptionStatus === 'trial' ||
        subscriptionInfo?.subscriptionStatus === 'trialing'

    useEffect(() => {}, [allowedScopes])

    useEffect(() => {
        // This will hide the sandbox panel in mobile sizes
        // when settings dropdown is open, to avoid problems with height
        setShowSandboxPanel && setShowSandboxPanel(!getIfIsExpanded())
    }, [expanded])

    const getIfIsExpanded = () =>
        !!expanded && !!expanded?.filter((route) => route === item.route)[0]

    const getIfIsSelected = (item: LinkModel) => {
        return (
            selected === item?.route ||
            (item.subRoutes &&
                item.subRoutes?.some((element) => element?.route === selected))
        )
    }

    const setExpandenToggle = (e, changeRoute?: boolean) => {
        e.stopPropagation()
        const isCurrentlyExpanded = getIfIsExpanded()
        let newExpandedValue = ['']
        if (isCurrentlyExpanded) {
            newExpandedValue = expanded
                ? expanded.filter((e) => e !== item.route)
                : []
        } else {
            newExpandedValue.push(item.route)
        }
        setExpanded(newExpandedValue)
    }

    function navigate(e, url: string) {
        setSelected(url), e.stopPropagation()
        history.push(url)
    }

    const getIsActive = (option: LinkModel) => {
        const routeIsTeamWithAccess = option?.route === 'team' && canAccessTeam
        const hasAccessToBilling =
            userIsOrgOwner &&
            userPaymnetByStripe &&
            !!allowedScopes?.find((el) => {
                return el === 'manageSubscriptions'
            })
        const routeIsBillingWithAccess =
            option?.route === 'billing' && hasAccessToBilling

        let isActive = false

        if (
            !canceledLicense &&
            (option?.route !== 'team' || routeIsTeamWithAccess) &&
            (option?.route !== 'billing' || routeIsBillingWithAccess) &&
            !(option?.route === 'settings' && thereIsDue) &&
            !(option?.route === 'app-integrations' && !isTrialing) &&
            (option?.route !== 'subscription' ||
                ((!!organizationId || !!orgId) &&
                    subscriptionInfo?.subscriptionStatus !== 'incomplete'))
        ) {
            if (option.scopes?.length) {
                if (allowedScopes?.length) {
                    isActive = allowedScopes.some((ai) =>
                        option.scopes?.includes(ai)
                    )
                }
            } else {
                isActive = true
            }
        }
        return isActive
    }

    return (
        <>
            <div
                className={`${
                    getResponsive(775)
                        ? styles.expandableMenu
                        : styles.expandedMenu
                } ${getIfIsSelected(item) ? styles.selected : ''}`}
            >
                {getResponsive(775) ? (
                    <>
                        <div
                            className={styles.expandableMenu__text}
                            onClick={(e) => {
                                setExpandenToggle(e),
                                    !item?.route?.includes('no') &&
                                        setSelected(item.route)
                            }}
                        >
                            {item.iconComponent ? (
                                <item.iconComponent
                                    size="16"
                                    style={getIfIsSelected(item) && 'filled'}
                                    color={
                                        getIfIsSelected(item)
                                            ? brandColors?.primaryColor
                                            : '#1E1E20'
                                    }
                                />
                            ) : null}
                            <span className={cx('buttonSM neutral1000')}>
                                {' '}
                                {t(item.label)}
                            </span>
                        </div>
                        <div
                            onClick={(e) => {
                                setExpandenToggle(e),
                                    !item?.route?.includes('no') &&
                                        setSelected(item.route)
                            }}
                        >
                            <GaImage
                                className={`${
                                    getIfIsExpanded()
                                        ? styles.active
                                        : styles.inactive
                                } ${styles.angle}`}
                                image={gaImages.angleDown}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className={`${styles.expandedMenu__text} ${styles.expandedMenu__noClick}`}
                        >
                            {item.iconComponent ? (
                                <item.iconComponent
                                    size="16"
                                    style={getIfIsSelected(item) && 'filled'}
                                    color={
                                        getIfIsSelected(item)
                                            ? brandColors?.primaryColor
                                            : '#1E1E20'
                                    }
                                />
                            ) : null}
                            <span className={cx('buttonSM neutral1000')}>
                                {t(item.label)}
                            </span>
                        </div>
                    </>
                )}
            </div>
            {!getResponsive(775) ? (
                <ul className={`${styles.expandedMenu__list}`}>
                    {item?.subRoutes?.map((i, index) => {
                        return (
                            <li
                                key={i.label + index + i.route}
                                onClick={(event) => {
                                    getIsActive(i)
                                        ? navigate(event, i.route)
                                        : null
                                }}
                                className={`${cx(
                                    !getIsActive(i)
                                        ? styles.expandedMenu__link__disabled
                                        : null
                                )} ${styles.expandedMenu__link} ${
                                    item.subRoutes ? 'column' : 'row'
                                }
              `}
                            >
                                <div
                                    className={`${
                                        getIfIsSelected(i)
                                            ? styles.selected
                                            : ''
                                    }`}
                                >
                                    {!getIsActive(i) ? (
                                        <>
                                            <GaHoverTooltip
                                                position={
                                                    TooltipPosition.bottom
                                                }
                                                positionArrowDirection={
                                                    TooltipArrowPosition.left
                                                }
                                                warning
                                                label={
                                                    <>
                                                        {i.icon ? (
                                                            <GaImage
                                                                image={i.icon}
                                                                className={
                                                                    styles.icon
                                                                }
                                                            />
                                                        ) : null}
                                                        <span
                                                            className={cx(
                                                                'buttonSM neutral1000'
                                                            )}
                                                        >
                                                            {t(i.label)}
                                                        </span>
                                                    </>
                                                }
                                                info={
                                                    i.route === 'team' &&
                                                    !canAccessTeam
                                                        ? t(
                                                              'public.upgradeSubscriptionToAccess'
                                                          )
                                                        : i.route ===
                                                              'billing' &&
                                                          userIsOrgOwner &&
                                                          !userPaymnetByStripe
                                                        ? t(
                                                              'public.billingSupportNeeded'
                                                          )
                                                        : t(
                                                              'public.notNecessaryPermission'
                                                          )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <span
                                            className={cx(
                                                'buttonSM neutral1000'
                                            )}
                                        >
                                            {t(i.label)}
                                        </span>
                                    )}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            ) : (
                <>
                    {getIfIsExpanded() ? (
                        <ul className={`${styles.expandableMenu__list}`}>
                            {item?.subRoutes?.map((i, index) => {
                                return !(i.id === 'support') ? (
                                    <li
                                        key={i.label + index + i.route}
                                        onClick={(event) => {
                                            getIsActive(i)
                                                ? navigate(event, i.route)
                                                : null
                                        }}
                                        className={`${cx(
                                            !getIsActive(i)
                                                ? styles.expandableMenu__link__disabled
                                                : null
                                        )} ${styles.expandableMenu__link} ${
                                            item.subRoutes ? 'column' : 'row'
                                        }
              `}
                                    >
                                        <div
                                            className={`${
                                                getIfIsSelected(i)
                                                    ? styles.selected
                                                    : ''
                                            }`}
                                        >
                                            {!getIsActive(i) ? (
                                                <>
                                                    <GaHoverTooltip
                                                        position={
                                                            TooltipPosition.bottom
                                                        }
                                                        positionArrowDirection={
                                                            TooltipArrowPosition.left
                                                        }
                                                        warning
                                                        label={
                                                            <>
                                                                {i.icon ? (
                                                                    <GaImage
                                                                        image={
                                                                            i.icon
                                                                        }
                                                                        className={
                                                                            styles.icon
                                                                        }
                                                                    />
                                                                ) : null}
                                                                <span
                                                                    className={cx(
                                                                        'buttonSM neutral1000'
                                                                    )}
                                                                >
                                                                    {t(i.label)}
                                                                </span>
                                                            </>
                                                        }
                                                        info={
                                                            i.route ===
                                                                'team' &&
                                                            !canAccessTeam
                                                                ? t(
                                                                      'public.upgradeSubscriptionToAccess'
                                                                  )
                                                                : i.route ===
                                                                      'billing' &&
                                                                  userIsOrgOwner &&
                                                                  !userPaymnetByStripe
                                                                ? t(
                                                                      'public.billingSupportNeeded'
                                                                  )
                                                                : t(
                                                                      'public.notNecessaryPermission'
                                                                  )
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <span
                                                    className={cx(
                                                        'buttonSM neutral1000'
                                                    )}
                                                >
                                                    {t(i.label)}
                                                </span>
                                            )}
                                        </div>
                                    </li>
                                ) : !cantAccessSupport ? (
                                    <a
                                        className={`${cx(
                                            !getIsActive(i)
                                                ? styles.expandableMenu__link__disabled
                                                : null
                                        )} ${styles.expandableMenu__link} ${
                                            item.subRoutes ? 'column' : 'row'
                                        }`}
                                        href={supportLink}
                                        target="_blank"
                                    >
                                        <div>
                                            <li
                                                className={`${cx(
                                                    !getIsActive(i)
                                                        ? styles.expandableMenu__link__disabled
                                                        : null
                                                )} ${
                                                    styles.expandableMenu__link
                                                } ${
                                                    item.subRoutes
                                                        ? 'column'
                                                        : 'row'
                                                }`}
                                                key={i.route + i.id + index}
                                            >
                                                {i.icon ? (
                                                    <GaImage
                                                        className={
                                                            styles.menuOptions__icon
                                                        }
                                                        image={i.icon}
                                                    />
                                                ) : null}
                                                <span
                                                    className={`${
                                                        selected
                                                            ? 'font-weight-bold'
                                                            : ''
                                                    } ${cx(
                                                        'buttonSM neutral1000'
                                                    )}`}
                                                >
                                                    {t(i.label)}
                                                </span>
                                            </li>
                                        </div>
                                    </a>
                                ) : null
                            })}
                        </ul>
                    ) : null}
                </>
            )}
        </>
    )
}

export default ExpandableMenu
