import {
    IStatisticsActions,
    IStatisticsState,
    StatisticsTypes,
} from './statisticsTypes'

const scopesState: IStatisticsState = {
    isLoading: false,
    statistics: undefined,
    statisticsError: false,
}

export const statisticsReducer = (
    state = scopesState,
    action: IStatisticsActions
): IStatisticsState => {
    switch (action.type) {
        case StatisticsTypes.GET_ALL_STATISTICS:
            return {
                ...state,
                isLoading: true,
                statisticsError: false,
            }
        case StatisticsTypes.GET_ALL_STATISTICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statistics: action.payload.statistics,
                statisticsError: false,
            }
        case StatisticsTypes.GET_ALL_STATISTICS_FAILED:
            return {
                ...state,
                isLoading: false,
                statistics: undefined,
                statisticsError: true,
            }
        case StatisticsTypes.GET_METRIC:
            return {
                ...state,
                isLoading: true,
            }
        case StatisticsTypes.GET_METRIC_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case StatisticsTypes.GET_METRIC_FAILED:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
