import { ActionStatusTypes } from '../../assets/globalConstants'
import {
    ConfigAppIntegrationActions,
    ConfigAppIntegrationActionsTypes,
    AppIntegrationsListActions,
    AppIntegrationsListActionsTypes,
    IAppIntegrationsListState,
    IAppIntegrationState,
} from './appIntegrationsTypes'

// LIST
const initialDidListState: IAppIntegrationsListState = {
    appIntegrations: undefined,
    scopes: undefined,
    loadingStatus: false,
    loadingScopesStatus: false,
    error: '',
}

export function appIntegrationsListReducer(
    state = initialDidListState,
    action: AppIntegrationsListActions
): IAppIntegrationsListState {
    switch (action.type) {
        case AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS:
            return {
                ...state,
                loadingStatus: true,
            }
        case AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS_SUCCESS:
            return {
                ...state,
                appIntegrations: action.payload.appIntegrations,
                loadingStatus: false,
                error: '',
            }
        case AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS_FAILED:
            return {
                ...state,
                appIntegrations: undefined,
                loadingStatus: false,
                error: action.payload.error,
            }
        case AppIntegrationsListActionsTypes.GET_SCOPES:
            return {
                ...state,
                loadingScopesStatus: true,
            }
        case AppIntegrationsListActionsTypes.GET_SCOPES_SUCCESS:
            return {
                ...state,
                scopes: action.payload.scopes,
                loadingScopesStatus: false,
                error: '',
            }
        case AppIntegrationsListActionsTypes.GET_SCOPES_FAILED:
            return {
                ...state,
                scopes: undefined,
                loadingScopesStatus: false,
                error: action.payload.error,
            }
        case AppIntegrationsListActionsTypes.CLEAR_APP_INTEGRATIONS_LIST:
            return initialDidListState as IAppIntegrationsListState
        default:
            return state
    }
}

const initiaAppIntState: IAppIntegrationState = {
    appIntegration: undefined,
    status: ActionStatusTypes.neutral,
    getSecretStatus: ActionStatusTypes.neutral,
    secret: undefined,
    error: '',
}

export function configAppIntegrationsReducer(
    state = initiaAppIntState,
    action: ConfigAppIntegrationActions
): IAppIntegrationState {
    switch (action.type) {
        case ConfigAppIntegrationActionsTypes.SELECT_APP_INTEGRATION:
            return {
                ...state,
                appIntegration: action.payload.appIntegration,
            }
        case ConfigAppIntegrationActionsTypes.GET_SECRET:
            return {
                ...state,
                getSecretStatus: ActionStatusTypes.loading,
                appIntegration: action.payload.appInt,
            }
        case ConfigAppIntegrationActionsTypes.GET_SECRET_SUCCESS:
            return {
                ...state,
                getSecretStatus: ActionStatusTypes.success,
                appIntegration: action.payload.appInt,
            }
        case ConfigAppIntegrationActionsTypes.GET_SECRET_FAILED:
            return {
                ...state,
                getSecretStatus: ActionStatusTypes.failed,
                appIntegration: undefined,
            }
        case ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION:
            return {
                ...state,
                status: ActionStatusTypes.loading,
            }
        case ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION_SUCCESS:
            return {
                ...state,
                status: ActionStatusTypes.success,
            }
        case ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
                error: action.payload?.error,
            }
        case ConfigAppIntegrationActionsTypes.CLEAR_ACTION_STATUS:
            return {
                ...state,
                status: ActionStatusTypes.neutral,
            }
        case ConfigAppIntegrationActionsTypes.CLEAR_CURRENT_APP_INTEGRATION:
            return initiaAppIntState as IAppIntegrationState
        default:
            return state
    }
}
