import cx from 'classnames'
import React from 'react'
import styles from './selectableDidCard.module.scss'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import { centerEllipsisTrim } from '../../../../../utils/formatDataUtil'
import GaRadioButton from '../../../../atoms/formFields/radioButton/GaRadioButton'
import { Trans } from 'react-i18next'

type ISelectableDidCardProps = {
    item?: IDIDModel
    index?: number
    selectedDid?: string
    select: (x?) => void
}

const SelectableDidCard: React.FC<ISelectableDidCardProps> = (props) => {
    const { item, index, selectedDid, select } = props

    return (
        <div
            className={`${styles.menuItem}`}
            role="option"
            key={index}
            id={item?.DidId}
            onClick={() => {
                select(item)
            }}
        >
            <div className={styles.option__leftContent}>
                <p className={cx('buttonMD neutral1000')}>{item?.alias}</p>
                <p className={cx('bodyRegularXS neutral700')}>
                    {centerEllipsisTrim(item?.DidId, 25)}
                </p>
            </div>

            <GaRadioButton
                id={'did--account' + index}
                index={index?.toString() || ''}
                value={item?.DidId}
                checked={item?.DidId === selectedDid}
                onChange={() => {
                    select(item)
                }}
            />
        </div>
    )
}

export default SelectableDidCard
