import cx from 'classnames'
import React from 'react'
import GaImage from '../../../../../../Components/atoms/Image/Image'
import { gaImages } from '../../../../../../assets/globalConstants'
import {
    marketAndroidURI,
    marketIosURI,
} from '../../../../../common/Login/loginConstants'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import styles from '../tutorial.module.scss'

type IFirstSteprops = {}

const FirstStep: React.FC<IFirstSteprops> = (props) => {
    return (
        <div>
            <div className={cx(styles.image)}>
                <GaImage
                    image={gaImages.downloadWallet}
                    className={cx(styles.firstTutorialImage)}
                />
            </div>

            <div>
                <p className={cx('heading5')}>
                    {t('appIntegrations.tutorial.downloadWallet')}
                </p>
                <p className={cx('bodyRegularMD neutral700 marginTop4')}>
                    {t('appIntegrations.tutorial.downloadWalletDesc')}
                </p>
                <div className={styles.stores}>
                    <Link to={marketIosURI} target="_blank">
                        <GaImage image={gaImages.appleStoreBordered} />
                    </Link>
                    <Link to={marketAndroidURI} target="_blank">
                        <GaImage image={gaImages.googleBrandButtonColor} />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default FirstStep
