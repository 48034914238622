import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IApiKeyModel } from '../../../../interfaces/interfaces'
import { addOrRemove } from '../../../../utils/checkboxUtil'
import Description from '../../../atoms/texts/Description/Description'
import style from './apiKeyCard.module.scss'
import GaCheckbox from '../../../atoms/formFields/checkbox/GaCheckbox'

type IApiKeysCardProps = {
    apiKey: IApiKeyModel
    index: number
    id?: string
    selectedApiKeys: IApiKeyModel[]
    handleCheckboxChange: (e) => void
}

const ApiKeyCard: React.FC<IApiKeysCardProps> = (props) => {
    const { apiKey, index, id, selectedApiKeys, handleCheckboxChange } = props
    const { t } = useTranslation()

    function handleChange(apiKey: IApiKeyModel) {
        const selection = addOrRemove(apiKey, selectedApiKeys)
        handleCheckboxChange(selection)
    }

    return (
        <div
            id={id}
            className={`${style.apiKeyCard} ${style.pointer}`}
            key={apiKey.id + Math.random()}
            onClick={() => handleChange(apiKey)}
        >
            <div className={style.apiKeyCard__header}>
                <div className={style.apiKeyCard__header__title}>
                    <p
                        className={cx(
                            'buttonSM neutral1000',
                            style.apiKeyCard__header__title__text
                        )}
                    >
                        {apiKey.alias || apiKey.id}
                    </p>
                    <Description
                        className={cx(
                            'margin-top-micro bodyRegularSM neutral700'
                        )}
                        text={'apiKeys.linkedTemplates'}
                        extraTranslationParams={{
                            quantity: apiKey.ssi_operations?.length || 0,
                        }}
                    />
                </div>
                <GaCheckbox
                    index={'' + index}
                    id={`custom-checkbox-${index}`}
                    name={apiKey.id}
                    value={index}
                    onChange={() => {}}
                    checked={selectedApiKeys?.includes(apiKey)}
                />
            </div>
        </div>
    )
}

export default ApiKeyCard
