import React, { useEffect, useState } from 'react'
import {
    clearModalState,
    sendAppIntegrationData,
} from '../../../../../store/appIntegrationModal/appIntegrationModalActions'
import { useDispatch, useSelector } from 'react-redux'
import { ageVerificationSteps } from '../../home.constants'
import { IAppIntegrationModel } from '../../../../../interfaces/interfaces'
import { getAppIntegrations } from '../../../../../store/appIntegrations/appIntegrationsSelectors'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'
import {
    getAppIntegrationDataSaved,
    sendingAppIntegrationDataStatus,
} from '../../../../../store/appIntegrationModal/appIntegrationModalSelectors'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import { initialLang } from '../../../../../i18n'
import { appIntegrationsSelectors } from '../../../../../store/appIntegrations'

type IAgeVerificationProps = {
    setShowAgeVerificationModal: (x?: any) => void
}

const AgeVerification: React.FC<IAgeVerificationProps> = (props) => {
    const { setShowAgeVerificationModal } = props
    const dispatch = useDispatch()

    // Selectors
    const existingAppIntegrations = useSelector(getAppIntegrations)

    // Constants
    const initialFormState: IAppIntegrationModel = {
        client_name: '',
        redirect_uris: [''],
        grant_types: [
            'client_credentials',
            'authorization_code',
            'refresh_token',
            'implicit',
        ],
        response_types: ['id_token', 'code', 'token'],
        scope: 'openid',
        audience: null,
        owner: '',
        policy_uri: '',
        allowed_cors_origins: [],
        tos_uri: '',
        contacts: [],
        client_secret_expires_at: 0,
        subject_type: 'public',
        token_endpoint_auth_method: 'client_secret_basic',
        request_uris: [],
        userinfo_signed_response_alg: 'none',
        metadata: null,
        display_tutorial: true,
    }

    // States
    const [ageVerificationCurrentStep, setAgeVerificationCurrentStep] =
        React.useState(ageVerificationSteps[0])

    const [formData, setFormState] = useState(initialFormState)

    const getNewAppIntegrationName = () => {
        const scopeDisplay = (formData) => {
            const filteredScoped = formData?.scope
                ?.match(/\b\w+\b/g)
                ?.filter((scope) => scope !== 'openid')

            return filteredScoped
                ?.map((el) => {
                    const fullOption = scopes?.find(
                        (option) => option.scope === el
                    )

                    return fullOption?.description_short &&
                        fullOption?.description_short[initialLang]
                        ? fullOption?.description_short[initialLang]
                        : fullOption?.description_short['en'] || el
                })
                ?.join(', ')
        }

        const appsWithRepeatedName = existingAppIntegrations?.filter((appI) => {
            return appI?.client_name
                ?.toLowerCase()
                ?.includes(`website ` + scopeDisplay(formData)?.toLowerCase())
        })

        const repeatedNames = appsWithRepeatedName?.map((appI) => {
            return appI?.client_name
        })

        const extractNumber = (str) => {
            const match = str?.match(/\((\d+)\)/)
            return match ? parseInt(match[1], 10) : 0
        }

        const numbers = repeatedNames?.map((str) => extractNumber(str))

        const maxNumber = Math.max(...numbers) || 0

        return repeatedNames?.length
            ? `Website ` + scopeDisplay(formData) + ` (${maxNumber + 1})`
            : `Website ` + scopeDisplay(formData)
    }

    const { redirect_uris, policy_uri, tos_uri } = formData

    // Selectors
    const sendingAppStatus = useSelector(sendingAppIntegrationDataStatus)
    const savedAppInt = useSelector(getAppIntegrationDataSaved)
    const scopes = useSelector(appIntegrationsSelectors.scopeList)

    // Effects
    useEffect(() => {
        if (sendingAppStatus === ActionStatusTypes.success) {
            setAgeVerificationCurrentStep(ageVerificationSteps[3])
        }
    }, [sendingAppStatus])

    // Functions
    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formData,
            [name]: value,
        })
    }

    const handleInputRedirectChange = (event, index) => {
        const target = event.target
        const value = target.value

        const formDataURLValue = formData.redirect_uris
        formDataURLValue[index] = value?.trim()

        setFormState({
            ...formData,
            redirect_uris: formDataURLValue,
        })
    }

    const removeLastRedirectUrl = (index) => {
        const modifiedRedirectUris = formData?.redirect_uris
        modifiedRedirectUris?.splice(index, 1)
        const redirectUrisWithoutLastItem = modifiedRedirectUris

        setFormState({
            ...formData,
            redirect_uris: redirectUrisWithoutLastItem,
        })
    }

    const continueButtonFunction = () => {
        const formattedAppInt = formatNewAppIntData()
        if (ageVerificationCurrentStep?.id === 3) {
            dispatch(showScreenLoaderAction())
            dispatch(sendAppIntegrationData(formattedAppInt))
        } else if (ageVerificationCurrentStep?.id === 4) {
            setShowAgeVerificationModal(false)
        } else {
            setAgeVerificationCurrentStep(
                ageVerificationSteps[ageVerificationCurrentStep?.order + 1]
            )
        }
    }

    const formatNewAppIntData = () => {
        return {
            ...formData,
            client_name: getNewAppIntegrationName(),
        }
    }

    return (
        <>
            {ageVerificationCurrentStep && (
                <ageVerificationCurrentStep.content
                    hideModal={() => {
                        setAgeVerificationCurrentStep(ageVerificationSteps[0])
                        setShowAgeVerificationModal(false)
                        dispatch(clearModalState())
                    }}
                    setAgeVerificationCurrentStep={
                        setAgeVerificationCurrentStep
                    }
                    savedAppInt={savedAppInt}
                    ageVerificationCurrentStep={ageVerificationCurrentStep}
                    ageVerificationSteps={ageVerificationSteps}
                    handleInputChange={handleInputChange}
                    continueButtonFunction={continueButtonFunction}
                    formData={formData}
                    setFormState={setFormState}
                    handleInputRedirectChange={handleInputRedirectChange}
                    removeLastRedirectUrl={removeLastRedirectUrl}
                />
            )}
        </>
    )
}

export default AgeVerification
