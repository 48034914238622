import React from 'react'
import { useSelector } from 'react-redux'
import { gaImages } from '../../../../../../assets/globalConstants'
import Description from '../../../../../../Components/atoms/texts/Description/Description'
import SearchBar from '../../../../../../Components/molecules/searchBar/SearchBar'
import CredentialCard from '../../../../../../Components/organisms/CredentialCard/CredentialCard'
import { ICredentialRequestedModel } from '../../../../../../interfaces/interfaces'
import { getFormattedRequestedCredentials } from '../../../issuanceSelectors'
import { propertiesToSearchCredentials } from '../../../issuance.constants'
import style from './credentialSelection.module.scss'
import ScrollableList from '../../../../../../Components/atoms/lists/ScrollableList/scrollableList'
import { getResponsive } from '../../../../../../utils/formUtils'
import { issuanceSelectors } from '../../..'

type ICredentialSelectionProps = {
    setSelectedCredentials: (x) => void
}

const CredentialSelection: React.FC<ICredentialSelectionProps> = (props) => {
    const { setSelectedCredentials } = props

    const credentialList = useSelector(
        issuanceSelectors.getOriginalCatalogTypes
    )
    const selected = useSelector(getFormattedRequestedCredentials)

    const [state, setState] = React.useState<{
        selections: ICredentialRequestedModel[]
        searched: string
        searchedResult: ICredentialRequestedModel[]
    }>({
        selections: selected?.slice() || [],
        searched: '',
        searchedResult: credentialList?.slice() || [],
    })

    function handleCheckboxChange(selected: ICredentialRequestedModel[]) {
        setState({
            selections: selected,
            searched: state.searched,
            searchedResult: state.searchedResult,
        })

        setSelectedCredentials(selected)
    }

    function handleSearchedChange(e) {
        setState({
            selections: state.selections,
            searched: state.searched,
            searchedResult: e,
        })
    }

    return (
        <div className={style.credentialSectionContainer}>
            <SearchBar
                className={style.searchBar}
                options={credentialList || []}
                propertiesToSearch={propertiesToSearchCredentials}
                icon={gaImages.searchBar}
                handleSearchedChange={handleSearchedChange}
                placeholder={'public.search'}
            />
            {getResponsive(1320) ? (
                <ScrollableList
                    className={style.scrollableList}
                    hasSearch={true}
                >
                    {state.searchedResult?.length > 0 ? (
                        state.searchedResult?.map((e, index) => (
                            <CredentialCard
                                key={index}
                                id={index === 0 ? 'topOfTheList' : e.id}
                                credential={e}
                                index={index}
                                selectedCredentials={state.selections.slice()}
                                handleCheckboxChange={handleCheckboxChange}
                                showClaims={true}
                                hasOnlyRemove={false}
                            />
                        ))
                    ) : (
                        <Description text={'public.noResultsForSearch'} />
                    )}
                </ScrollableList>
            ) : (
                <>
                    {state.searchedResult?.length > 0 ? (
                        state.searchedResult?.map((e, index) => (
                            <CredentialCard
                                key={index}
                                id={index === 0 ? 'topOfTheList' : e.id}
                                credential={e}
                                index={index}
                                selectedCredentials={state.selections.slice()}
                                handleCheckboxChange={handleCheckboxChange}
                                showClaims={true}
                                hasOnlyRemove={false}
                            />
                        ))
                    ) : (
                        <Description text={'public.noResultsForSearch'} />
                    )}
                </>
            )}
        </div>
    )
}

export default CredentialSelection
