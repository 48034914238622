import { Epic, ofType } from 'redux-observable'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import dataService from '../../services/dataService'
import { from, of } from 'rxjs'
import { AppIntegrationModalListActionsTypes } from './appIntegrationModalTypes'
import {
    sendAppIntegrationDataFailed,
    sendAppIntegrationDataSuccess,
} from './appIntegrationModalActions'
import { hideScreenLoaderAction } from '../../features/common/Loader/loaderActions'
import { showNotification } from '../../features/common/Notification/notificationActions'
import { history } from '../../services/history'

export const sendAppIntegrationDataEpic: Epic = (action$) => {
    return action$.pipe(
        ofType(AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA),
        switchMap((action): any => {
            const client = action.payload?.client
            return from(dataService.createAppIntegrations(client)).pipe(
                mergeMap((response?: any) => {
                    history?.push('app-integrations')
                    return of(
                        hideScreenLoaderAction(),
                        sendAppIntegrationDataSuccess(response?.response)
                    )
                }),
                catchError((error) =>
                    of(
                        hideScreenLoaderAction(),
                        sendAppIntegrationDataFailed(error),
                        showNotification(
                            'appIntegrations.createAppIntError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
