import { getFormattedJWTCredForEW } from '../../utils/credentialsUtils'

import { RootState } from '../indexReducers'

// List
export const isLoading = (state: RootState) =>
    state.requestCredentialsReducer.loadingStatus

export const getCreds = (state: RootState) =>
    state.requestCredentialsReducer.credentials

export const getFormattedCreds = (state: RootState) => {
    const pagedState = state.requestCredentialsReducer.credentials
    return {
        ...pagedState,
        items: formatCreds(
            //@ts-ignore
            state.requestCredentialsReducer.credentials?.items ||
                state.requestCredentialsReducer.credentials
        ),
    }
}

export const getDeleteCredStatus = (state: RootState) =>
    state.requestCredentialsReducer.deleteCredStatus

export const getImportedCredential = (state: RootState) =>
    state.requestCredentialsReducer.importedCredential

export const getImportCredStatus = (state: RootState) =>
    state.requestCredentialsReducer.deleteCredStatus

const formatCreds = (creds) => getFormattedJWTCredForEW(creds)
