import cx from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import { brandLabels } from '../../../../../assets/globalConstants'

type IRegisterFormProps = {
    currentDid: IDIDModel
    webToken?: string
    buttonFunction: (x?) => void
}

const RegisterForm: React.FC<IRegisterFormProps> = (props) => {
    const { currentDid, webToken, buttonFunction } = props
    const { t } = useTranslation()

    const [formData, setFormState] = useState({
        token: webToken,
        did: { alias: currentDid?.alias || '' },
    })

    const { token, did } = formData
    const validForm = !!token?.trim()

    const onSubmit = (e) => {
        e.preventDefault()
        buttonFunction({ ...currentDid, formData })
    }

    return (
        <>
            <h3>{t('createDid.step1.sectionTitle')}</h3>
            <Description
                className={cx('margin-bottom margin-top')}
                text={t('createDid.step1.sectionDescription')}
                extraTranslationParams={{
                    brand: brandLabels?.brand,
                }}
            />

            <form onSubmit={onSubmit}>
                <GaTextInput
                    className={cx('margin-bottom')}
                    id={'did-token'}
                    invalid={false}
                    labelText={t('createDid.step1.tokenLabel')}
                    required={true}
                    onChange={(e) =>
                        setFormState({ ...formData, token: e.target.value })
                    }
                    placeholder={t('createDid.step1.tokenPlaceholder')}
                    value={token}
                />
            </form>

            <GaTextInput
                className={cx('margin-bottom')}
                id={'alias'}
                invalid={false}
                labelText={t('createDid.step4.aliasLabel')}
                required={false}
                name={'alias'}
                onChange={(e) =>
                    setFormState({
                        ...formData,
                        did: { alias: e.target.value },
                    })
                }
                placeholder={t('createDid.step4.aliasPlaceholder')}
                value={did?.alias}
            />

            <LightButton
                className={cx('margin-top-m', 'margin-left-auto')}
                text={'public.finish'}
                disabled={!validForm}
                functionality={() => {
                    buttonFunction(formData)
                }}
            />
        </>
    )
}

export default RegisterForm
