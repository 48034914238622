import React, { useEffect, useState } from 'react'
import {
    CredContentDetailType,
    IComplexVerifiableCredential,
    VerifiableCredential,
} from '../../../../../../data/requestCredentials.interfaces'
import styles from './complexContent.module.scss'
import cx from 'classnames'
import {
    isArray,
    isObject,
    isStringOrNumber,
    plainValues,
} from '../../../../../../../../../utils/formatDataUtil'
import { t } from 'i18next'
import { gaImages } from '../../../../../../../../../assets/globalConstants'
import ChevronRightIcon from '../../../../../../../../../assets/iconsLibrary/simple/chevronRight/chevronRightIcon'
import LockIcon from '../../../../../../../../../assets/iconsLibrary/simple/lock/lockIcon'
import {
    containerStyle,
    getCredentialDetailStyle,
    getCredentialSubjects,
    getLevelContent,
    removeIdAndTitle,
} from '../../../../../../utils/complexCredUtil'

interface GaComplexDetailProps {
    title?: string
    leftText: string
    rightIcon?: any
    rightText?: string
    style?: string
    action?: any
    isTable?: boolean
    level: number
}

interface GaComplexConfigProps {
    content: any
    index: number
    level: number
    isTable?: boolean
    currentLength?: number
    isDemoContent?: boolean
    action?: (dataType?: any, value?: any) => void
}

// Components
// Return configured complex credential detail according to the type and deep level
export const ComplexCredentialConfig: React.FC<GaComplexConfigProps> = (
    props
) => {
    const {
        content,
        index,
        level,
        isTable,
        currentLength,
        isDemoContent,
        action,
    } = props

    const title = content?.isTitle ? content.value : undefined
    const rightIcon = gaImages.rightArrow
    const rightText = content?.preview ? content?.preview : undefined
    const leftText = content?.dataType

    const classStyles = getCredentialDetailStyle(
        content?.isTitle,
        index,
        currentLength,
        isTable,
        isDemoContent
    )
    const rightBlockedText =
        !isObject(content?.value) && !isArray(content?.value)
            ? content?.value
            : content?.preview
            ? content?.preview
            : undefined
    const rightBlockedIcon =
        isObject(content?.value) || isArray(content?.value)
            ? gaImages.unlockImg
            : undefined

    if (content && level < 4) {
        switch (true) {
            case isObject(content?.value):
                return (
                    <ComplexCredDetail
                        title={title}
                        rightIcon={rightIcon}
                        rightText={rightText}
                        leftText={leftText}
                        style={classStyles}
                        action={action}
                        isTable={isTable}
                        level={level}
                    />
                )
            case isArray(content?.value):
                return content?.value && content?.value[0] ? (
                    <>
                        {content?.value?.map((el: any, index: number) => {
                            return (
                                <ComplexCredDetail
                                    title={title}
                                    key={'complexCredDet__' + index}
                                    rightIcon={rightIcon}
                                    rightText={rightText}
                                    leftText={el?.dataType}
                                    style={classStyles}
                                    action={action}
                                    isTable={isTable}
                                    level={level}
                                />
                            )
                        })}
                    </>
                ) : (
                    <ComplexCredDetail
                        title={title}
                        rightIcon={rightIcon}
                        rightText={rightText}
                        leftText={leftText}
                        style={classStyles}
                        action={action}
                        isTable={isTable}
                        level={level}
                    />
                )
            case isStringOrNumber(content?.value):
                return (
                    <ComplexCredDetail
                        style={classStyles}
                        leftText={content?.dataType}
                        rightText={content?.value}
                        title={title}
                        isTable={isTable}
                        level={level}
                    />
                )
            default:
                return (
                    <ComplexCredDetail
                        style={classStyles}
                        leftText={leftText}
                        rightText={rightText}
                        title={title}
                        isTable={isTable}
                        level={level}
                    />
                )
        }
    } else {
        return (
            <ComplexCredDetail
                style={classStyles}
                leftText={leftText}
                title={title}
                rightText={rightBlockedText}
                rightIcon={rightBlockedIcon}
                action={action}
                isTable={isTable}
                level={level}
            />
        )
    }
}

export const ComplexCredDetail: React.FunctionComponent<
    GaComplexDetailProps
> = (props) => {
    const {
        title,
        leftText,
        rightIcon,
        rightText,
        action,
        style,
        isTable,
        level,
    } = props

    return action && !title && rightIcon ? (
        <div
            onClick={action}
            className={cx(
                styles.itemIconContainer,
                style,
                !rightIcon || title || level >= 5 ? styles.defaultCursor : ''
            )}
        >
            <div className={styles.itemIconHalf}>
                <p className={styles.mainText}>{leftText}</p>
                {rightText && (
                    <p className={cx('bodyRegularCap neutral700')}>
                        {rightText}
                    </p>
                )}
            </div>
            <div className={cx(styles.itemIconHalf)}>
                {rightIcon &&
                    (level < 5 ? (
                        <ChevronRightIcon size={24} />
                    ) : (
                        <LockIcon size={24} />
                    ))}
            </div>
        </div>
    ) : (
        <div
            className={cx(
                !title && styles.itemIconContainer,
                style,
                !rightIcon || title || level >= 5 ? styles.defaultCursor : ''
            )}
        >
            {!title ? (
                <>
                    <div className={styles.itemIconHalf}>
                        <p className={styles.mainText}>{leftText}</p>
                        {rightText && (
                            <p className={cx('bodyRegularCap neutral700')}>
                                {rightText}
                            </p>
                        )}
                    </div>
                    <div className={styles.itemIconHalf}>
                        {rightIcon &&
                            (level < 5 ? (
                                <ChevronRightIcon size={24} />
                            ) : (
                                <LockIcon size={24} />
                            ))}
                    </div>
                </>
            ) : (
                <p
                    className={cx(
                        'heading6 neutral1000',
                        isTable ? 'marginTop10' : 'marginBottom20'
                    )}
                >
                    {title}
                </p>
            )}
        </div>
    )
}

const ComplexContent: React.FC<IComplexVerifiableCredential> = (props) => {
    const {
        vc,
        category,
        group,
        inactive,
        passingOnPress,
        level,
        isDemoContent,
    } = props

    const [vcState, setVcState] = useState<{
        vc: VerifiableCredential
        contents?: CredContentDetailType[]
        credCategory: string
        isTable?: boolean
    }>({
        vc: vc,
        contents: [],
        credCategory: t('ew.requestCredentials.fileDetail', {
            type: category || t('ew.requestCredentials.credential'),
        }),
        isTable: false,
    })

    const loadVCDetailData = async () => {
        const contents = await getLevelContent(
            getCredentialSubjects([vc], vcState.credCategory),
            level
        )
        const contentFiltered = removeIdAndTitle(contents)
        const hasPlainValues = plainValues(contentFiltered)

        setVcState({
            ...vcState,
            contents: contents,
            isTable:
                (!!hasPlainValues?.length && !!hasPlainValues[0]) ||
                contentFiltered?.length === 1,
        })
    }

    useEffect(() => {
        loadVCDetailData()
    }, [])

    return vcState?.contents &&
        vcState?.contents?.length &&
        vcState?.contents[0] ? (
        <div className={containerStyle(vcState?.isTable)}>
            {vcState?.contents?.map((content, index) => {
                return (
                    <ComplexCredentialConfig
                        key={'complexCredConf__' + index}
                        content={content}
                        index={index}
                        level={1}
                        isDemoContent={isDemoContent}
                        isTable={vcState?.isTable}
                        currentLength={vcState?.contents?.length || 0}
                        action={() =>
                            props.passingOnPress(
                                getLevelContent(content.value),
                                1,
                                content?.dataType
                            )
                        }
                    />
                )
            })}
        </div>
    ) : (
        <>
            <p className={cx('bodyRegularSM neutral700 marginBottom12')}>
                {t('ew.requestCredentials.credEmptyContent')}
            </p>
        </>
    )
}

export default ComplexContent
