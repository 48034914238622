import React from 'react'

type CredentialIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const CredentialIcon: React.FC<CredentialIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const FilledIconPaths = (props) => {
    return (
        <>
            <g clipPath="url(#clip0_3791_29587)">
                <path
                    d="M27.9999 5.33334H3.99992C2.52716 5.33334 1.33325 6.52724 1.33325 8V24C1.33325 25.4728 2.52716 26.6667 3.99992 26.6667H27.9999C29.4727 26.6667 30.6666 25.4728 30.6666 24V8C30.6666 6.52724 29.4727 5.33334 27.9999 5.33334Z"
                    fill={props?.color || 'currentColor' || '#1E1E20'}
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.7893 15.5238L26.7893 15.5238"
                    stroke="white"
                    strokeWidth="2.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.4561 20.8571L26.7894 20.8571"
                    stroke="white"
                    strokeWidth="2.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="5.78931"
                    y="9.19042"
                    width="6"
                    height="6"
                    rx="1.341"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_3791_29587">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </>
    )
}

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <g clipPath="url(#clip0_3791_29582)">
                <path
                    d="M27.9999 5.33334H3.99992C2.52716 5.33334 1.33325 6.52724 1.33325 8V24C1.33325 25.4728 2.52716 26.6667 3.99992 26.6667H27.9999C29.4727 26.6667 30.6666 25.4728 30.6666 24V8C30.6666 6.52724 29.4727 5.33334 27.9999 5.33334Z"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.7893 15.5238L26.7893 15.5238"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.4561 20.8571L26.7894 20.8571"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="6.78931"
                    y="10.1904"
                    width="5.33333"
                    height="5.33333"
                    rx="1.341"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.67"
                />
            </g>
            <defs>
                <clipPath id="clip0_3791_29582">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </>
    )
}

export default CredentialIcon
