import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from './verificationPageForm.module.scss'
import { IAppIntegrationModel } from '../../../../../../interfaces/interfaces'
import { catalogSelectors } from '../../../../../common/catalog'
import DarkButton from '../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import GaTextInput from '../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../../Components/atoms/panels/LightPanel/LightPanel'
import ScreenHeader from '../../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import {
    checkIfURLIsSecure,
    getResponsive,
} from '../../../../../../utils/formUtils'
import VerificationPagePreview from '../../StepsContentPreview/VerificationPagePreview'

type IVerificationPageFormProps = {
    formData: IAppIntegrationModel
    companyLogoChecked: boolean
    secondFormModified: boolean
    setCompanyLogoChecked: (x) => void
    handleInputChange: (x?) => void
    buttonFunction: (x) => void
    setTutorialChecked: (x) => void
}

const VerificationPageForm: React.FC<IVerificationPageFormProps> = (props) => {
    const {
        formData,
        companyLogoChecked,
        secondFormModified,
        setCompanyLogoChecked,
        handleInputChange,
        buttonFunction,
        setTutorialChecked,
    } = props

    const { t } = useTranslation()
    const formIsInvalid =
        !!formData?.logo_uri?.trim()?.length &&
        !checkIfURLIsSecure(formData?.logo_uri?.trim())

    // Selectors
    const serviceProviders = useSelector(
        catalogSelectors.getCatalogServiceProvider
    )

    // Effets
    useEffect(() => {
        scrollToTop()
    }, [])

    useEffect(() => {}, [formData])

    useEffect(() => {}, [serviceProviders])

    // States
    const [show, setShowModal] = React.useState(false)

    // Functions
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    return (
        <>
            <div className={styles.leftColumn}>
                <p className={cx('heading5')}>
                    {t('appIntegrations.createTitle')}
                </p>
                <div className={cx('marginTop32')}>
                    <LightPanel>
                        <ScreenHeader
                            title={'appIntegrations.verificationPage.title'}
                            buttonFunction={showModal}
                            button={
                                getResponsive(1320)
                                    ? {
                                          label: 'general.showPreview',
                                          disabled: false,
                                      }
                                    : undefined
                            }
                            buttonIsLight={true}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            className={styles.formHeader}
                        />

                        <form>
                            <div
                                className={`${cx(
                                    'marginTop24 paddingBottom24 marginBottom24'
                                )} ${styles.tutorialContainer}`}
                            >
                                <div
                                    className={`${cx('marginRight20')} ${
                                        styles.tutorialText
                                    }`}
                                >
                                    <p className={cx('buttonSM neutral1000')}>
                                        {t(
                                            'appIntegrations.verificationPage.tutorial'
                                        )}
                                    </p>

                                    <p className={cx('bodyRegularXS')}>
                                        {t(
                                            'appIntegrations.verificationPage.tutorialDesc'
                                        )}
                                    </p>
                                </div>
                                <div className={styles.activationToggle}>
                                    <input
                                        checked={formData?.display_tutorial}
                                        className={styles.activationCheckbox}
                                        type="checkbox"
                                        id="switch"
                                    />
                                    <label
                                        onClick={(e) =>
                                            setTutorialChecked(
                                                !formData?.display_tutorial
                                            )
                                        }
                                        className={
                                            styles.activationCheckboxLabel
                                        }
                                    ></label>
                                </div>
                            </div>
                            <p className={cx('heading7')}>
                                {t(
                                    'appIntegrations.verificationPage.pageLayout'
                                )}
                            </p>
                            <div
                                className={`${cx('marginTop24')} ${
                                    styles.companyLogoContainer
                                }`}
                            >
                                <div
                                    className={`${cx('marginRight20')} ${
                                        styles.companyLogoText
                                    }`}
                                >
                                    <p className={cx('buttonSM neutral1000')}>
                                        {t(
                                            'appIntegrations.verificationPage.companyLogo'
                                        )}
                                    </p>

                                    <p className={cx('bodyRegularXS')}>
                                        {t(
                                            'appIntegrations.verificationPage.companyLogoDesc'
                                        )}
                                    </p>
                                </div>
                                <div className={styles.activationToggle}>
                                    <input
                                        checked={companyLogoChecked}
                                        className={styles.activationCheckbox}
                                        type="checkbox"
                                        id="switch"
                                    />
                                    <label
                                        onClick={(e) =>
                                            setCompanyLogoChecked(
                                                !companyLogoChecked
                                            )
                                        }
                                        className={
                                            styles.activationCheckboxLabel
                                        }
                                    ></label>
                                </div>
                            </div>
                            {companyLogoChecked ? (
                                <GaTextInput
                                    className={cx('marginTop16')}
                                    id={'logo_uri'}
                                    invalid={
                                        !!formData?.logo_uri?.trim()?.length &&
                                        !checkIfURLIsSecure(
                                            formData?.logo_uri?.trim()
                                        )
                                    }
                                    errorTexts={[
                                        t(
                                            'appIntegrations.verificationPage.urlStartHttps'
                                        ),
                                    ]}
                                    labelText={t(
                                        'appIntegrations.verificationPage.urlLogo'
                                    )}
                                    type={'url'}
                                    pattern="https://.*"
                                    name={'logo_uri'}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder={t(
                                        'appIntegrations.verificationPage.urlLogoPlaceholder'
                                    )}
                                    helperText={t(
                                        'appIntegrations.verificationPage.urlLogoHelper'
                                    )}
                                    value={formData.logo_uri}
                                />
                            ) : null}
                        </form>

                        {getResponsive(1320) ? (
                            <div className={styles.buttonContainer}>
                                <DarkButton
                                    text={
                                        !secondFormModified
                                            ? 'appIntegrations.verificationPage.skip'
                                            : 'appIntegrations.verificationPage.save'
                                    }
                                    disabled={!!formIsInvalid}
                                    functionality={buttonFunction}
                                />
                            </div>
                        ) : null}
                    </LightPanel>
                </div>
            </div>

            <VerificationPagePreview
                tutorialChecked={!!formData?.display_tutorial}
                companyLogoChecked={companyLogoChecked}
                companyLogo={formData?.logo_uri}
                saveButton={{
                    label: !secondFormModified
                        ? 'appIntegrations.verificationPage.skip'
                        : 'public.create',
                    disabled: !!formIsInvalid,
                    function: buttonFunction,
                }}
                showSaveButton={true}
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
                selectedScopes={formData?.formScopes}
            />
        </>
    )
}

export default VerificationPageForm
