import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { didTypeOptions } from '../../../../../assets/globalConstants'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import styles from '../../views/configDid/configDid.module.scss'

type IIdentifierFormProps = {
    currentDid: IDIDModel
    buttonFunction: (x?) => void
}

const IdentifierForm: React.FC<IIdentifierFormProps> = (props) => {
    const { currentDid, buttonFunction } = props
    const { t } = useTranslation()

    const initialFormState = {
        alias: currentDid?.alias || '',
        type: currentDid?.type?.value,
    }
    const [formData, setFormState] = useState(initialFormState)
    const { alias, type } = formData

    useEffect(() => setFormState(initialFormState), [currentDid])

    const onSubmit = (e) => {
        e.preventDefault()
        buttonFunction(formData)
    }

    return (
        <>
            <div className={styles.sectionContent}>
                <form onSubmit={onSubmit}>
                    <GaTextInput
                        readOnly={true}
                        className={cx('margin-bottom')}
                        id={'DidId'}
                        invalid={false}
                        labelText={t('createDid.step4.didIdentifierLabel')}
                        required={false}
                        name={'DidId'}
                        value={currentDid?.DidId}
                    />
                    <GaTextInput
                        className={cx('margin-bottom')}
                        id={'alias'}
                        invalid={false}
                        labelText={t('createDid.step4.aliasLabel')}
                        required={false}
                        name={'alias'}
                        onChange={(e) =>
                            setFormState({ ...formData, alias: e.target.value })
                        }
                        placeholder={t('createDid.step4.aliasPlaceholder')}
                        value={alias}
                    />
                    <GaSelect
                        idSelect={'privateKeyType'}
                        className={cx('margin-bottom')}
                        value={type}
                        id="select-privateKeyType"
                        invalid={false}
                        labelText={t('createDid.step2.privateKeyTypesLabel')}
                        name={'privateKeyType'}
                        onChange={(e) => {
                            setFormState({ ...formData, type: e.target.value })
                        }}
                        options={didTypeOptions}
                        optionTextProperty={'text'}
                        optionValueProperty={'value'}
                    />
                </form>
            </div>

            <LightButton
                className={cx('margin-top-m', 'margin-left-auto')}
                text={'public.save'}
                disabled={false}
                functionality={() => {
                    buttonFunction(formData)
                }}
            />
        </>
    )
}

export default IdentifierForm
