import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    ICreateDidModel,
    IDIDModel,
    IDidsRespondeModel,
    StepModel,
} from '../../../interfaces/interfaces'

// LIST
export interface IGetDidsAction {
    type: DidListActionsTypes.GET_DIDS
}

export interface IGetDidsSuccessAction {
    type: DidListActionsTypes.GET_DIDS_SUCCESS
    payload: {
        didsSetup: IDidsRespondeModel
    }
}

export interface IGetDidsFailed {
    type: DidListActionsTypes.GET_DIDS_FAILED
    payload: {
        error: string
    }
}

export interface ClearDidList {
    type: DidListActionsTypes.CLEAR_DID_LIST
}

export interface IDeleteDid {
    type: DidListActionsTypes.DELETE_DID
    payload: {
        id: string
    }
}

export interface IDeleteDidSuccess {
    type: DidListActionsTypes.DELETE_DID_SUCCESS
}

export interface IDeleteDidFailed {
    type: DidListActionsTypes.DELETE_DID_FAILED
    payload: {
        error: string
    }
}

export interface IDeleteIssuerType {
    type: DidListActionsTypes.DELETE_ISSUER_TYPE
    payload: {
        id: string
    }
}

export interface IDeleteIssuerTypeSuccess {
    type: DidListActionsTypes.DELETE_ISSUER_TYPE_SUCCESS
}

export interface IDeleteIssuerTypeFailed {
    type: DidListActionsTypes.DELETE_ISSUER_TYPE_FAILED
    payload: {
        error: string
    }
}

export interface IAddIssuerType {
    type: DidListActionsTypes.ADD_ISSUER_TYPE
    payload: {
        id: string
    }
}

export interface IAddIssuerTypeSuccess {
    type: DidListActionsTypes.ADD_ISSUER_TYPE_SUCCESS
}

export interface IAddIssuerTypeFailed {
    type: DidListActionsTypes.ADD_ISSUER_TYPE_FAILED
    payload: {
        error: string
    }
}
export interface IAddVerifierType {
    type: DidListActionsTypes.ADD_VERIFIER_TYPE
    payload: {
        id: string
    }
}

export interface IAddVerifierTypeSuccess {
    type: DidListActionsTypes.ADD_VERIFIER_TYPE_SUCCESS
}

export interface IAddVerifierTypeFailed {
    type: DidListActionsTypes.ADD_VERIFIER_TYPE_FAILED
    payload: {
        error: string
    }
}

export interface IDeleteVerifierType {
    type: DidListActionsTypes.DELETE_VERIFIER_TYPE
    payload: {
        id: string
    }
}

export interface IDeleteVerifierTypeSuccess {
    type: DidListActionsTypes.DELETE_VERIFIER_TYPE_SUCCESS
}

export interface IDeleteVerifierTypeFailed {
    type: DidListActionsTypes.DELETE_VERIFIER_TYPE_FAILED
    payload: {
        error: string
    }
}

export type DidListActions =
    | IGetDidsAction
    | IGetDidsSuccessAction
    | IGetDidsFailed
    | IDeleteIssuerType
    | IDeleteIssuerTypeSuccess
    | IDeleteIssuerTypeFailed
    | IDeleteDid
    | IDeleteDidSuccess
    | IDeleteDidFailed
    | IDeleteVerifierType
    | IDeleteVerifierTypeSuccess
    | IDeleteVerifierTypeFailed
    | IAddIssuerType
    | IAddVerifierType
    | ClearDidList

export enum DidListActionsTypes {
    GET_DIDS = 'did/getDids',
    GET_DIDS_SUCCESS = 'did/getDidsSuccess',
    GET_DIDS_FAILED = 'did/getDidsFailed',
    DELETE_ISSUER_TYPE = 'did/delete_issuer_type',
    DELETE_ISSUER_TYPE_SUCCESS = 'did/delete_issuer_typeSuccess',
    DELETE_ISSUER_TYPE_FAILED = 'did/delete_issuer_typeFailed',
    DELETE_DID = 'did/delete_did',
    DELETE_DID_SUCCESS = 'did/delete_did_success',
    DELETE_DID_FAILED = 'did/delete_did_Failed',
    DELETE_VERIFIER_TYPE = 'did/delete_verifier_type',
    DELETE_VERIFIER_TYPE_SUCCESS = 'did/delete_verifier_typeSuccess',
    DELETE_VERIFIER_TYPE_FAILED = 'did/delete_verifier_typeFailed',
    ADD_ISSUER_TYPE = 'did/add_issuer_type',
    ADD_ISSUER_TYPE_SUCCESS = 'did/add_issuer_typeSuccess',
    ADD_ISSUER_TYPE_FAILED = 'did/add_issuer_typeFailed',
    ADD_VERIFIER_TYPE = 'did/add_verifier_type',
    ADD_VERIFIER_TYPE_SUCCESS = 'did/add_verifier_typeSuccess',
    ADD_VERIFIER_TYPE_FAILED = 'did/add_verifier_typeFailed',
    CLEAR_DID_LIST = 'did/clearDidList',
}

export interface IDidListState {
    didsSetup: IDidsRespondeModel
    loadingStatus: boolean
    error: string
}

// FORM
export interface IConfigDidFormState {
    steps: StepModel[] | null
    firstStep: StepModel | null
    step: StepModel | null
    finalStep: StepModel | null
}

export interface SetActiveStepAction {
    type: ConfigDidFormActionsTypes.SETSTEP
    payload: {
        step: StepModel
    }
}
export interface SetStepsAction {
    type: ConfigDidFormActionsTypes.SETSTEPS
    payload: {
        steps: StepModel[]
    }
}

export interface ClearDidFormState {
    type: ConfigDidFormActionsTypes.CLEAR_FORM_STEP
}

export type ConfigDidFormActions =
    | SetActiveStepAction
    | SetStepsAction
    | ClearDidFormState

export enum ConfigDidFormActionsTypes {
    SETSTEPS = 'did/setSteps',
    SETSTEP = 'did/setStep',
    CLEAR_FORM_STEP = 'did/clearFormSteps',
}

export interface IDidState {
    did: IDIDModel
    token: string
    baseId: string
    status: ActionStatusTypes
    isKeypairLoading: boolean
    isSecretLoading: boolean
    error: string
}

// CONFIG DID
export interface IRequestKeypairAction {
    type: ConfigDidActionsTypes.GET_KEYPAIR
}

export interface IRequestKeypairSuccessAction {
    type: ConfigDidActionsTypes.GET_KEYPAIR_SUCCESS
    payload: {
        publicKey: string
        privateKey: string
    }
}

export interface IRequestKeypairFailedAction {
    type: ConfigDidActionsTypes.GET_KEYPAIR_FAILED
    payload: {
        error: string
    }
}

export interface IRequestSecretAction {
    type: ConfigDidActionsTypes.GET_SECRET
}

export interface IRequestSecretSuccessAction {
    type: ConfigDidActionsTypes.GET_SECRET_SUCCESS
    payload: {
        secret: string
    }
}

export interface IRequestSecretFailedAction {
    type: ConfigDidActionsTypes.GET_SECRET_FAILED
    payload: {
        error: string
    }
}

export interface IEditDid {
    type: ConfigDidActionsTypes.EDIT_DID
    payload: {
        did: IDIDModel
        token: string
    }
}

export interface ISelectDidAction {
    type: ConfigDidActionsTypes.SELECT_DID
    payload: {
        did: IDIDModel
    }
}

export interface ICreateDidAction {
    type: ConfigDidActionsTypes.CREATE_DID
    payload: {
        did: ICreateDidModel
    }
}

export interface ICreateFirstDidAction {
    type: ConfigDidActionsTypes.CREATE_FIRST_DID
    payload: {
        did: ICreateDidModel
    }
}

export interface ICreateDidSuccessAction {
    type: ConfigDidActionsTypes.CREATE_DID_SUCCESS
    payload: {
        didId?: string
    }
}

export interface ICreateDidFailedAction {
    type: ConfigDidActionsTypes.CREATE_DID_FAILED
    payload: {
        error: string
    }
}

export interface IUpdateDidAction {
    type: ConfigDidActionsTypes.UPDATE_DID
    payload: {
        did: ICreateDidModel
        didId: string
    }
}

export interface IUpdateDidSuccessAction {
    type: ConfigDidActionsTypes.UPDATE_DID_SUCCESS
}

export interface IUpdateDidFailedAction {
    type: ConfigDidActionsTypes.UPDATE_DID_FAILED
    payload: {
        error: string
    }
}

export interface IClearDid {
    type: ConfigDidActionsTypes.CLEAR_CURRENT_DID
}

export interface IClearActionStatus {
    type: ConfigDidActionsTypes.CLEAR_ACTION_STATUS
}

export type ConfigDidActions =
    | IEditDid
    | IClearDid
    | ISelectDidAction
    | IRequestKeypairAction
    | IRequestKeypairSuccessAction
    | IRequestKeypairFailedAction
    | IRequestSecretAction
    | IRequestSecretSuccessAction
    | IRequestSecretFailedAction
    | ICreateDidAction
    | ICreateFirstDidAction
    | ICreateDidSuccessAction
    | ICreateDidFailedAction
    | IUpdateDidAction
    | IUpdateDidSuccessAction
    | IUpdateDidFailedAction
    | IClearActionStatus

export enum ConfigDidActionsTypes {
    EDIT_DID = 'config/did/editDid',
    CLEAR_CURRENT_DID = 'config/did/clearDidForm',
    SELECT_DID = 'config/did/selectDid',
    GET_KEYPAIR = 'config/did/keypair',
    GET_KEYPAIR_FAILED = 'config/did/keypair_failed',
    GET_KEYPAIR_SUCCESS = 'config/did/keypair_result',
    GET_SECRET = 'config/did/secret',
    GET_SECRET_SUCCESS = 'config/did/secret_result',
    GET_SECRET_FAILED = 'config/did/secret_failed',
    CREATE_DID = 'config/did/create',
    CREATE_FIRST_DID = 'config/did/createFirst',
    CREATE_DID_SUCCESS = 'config/did/create_success',
    CREATE_DID_FAILED = 'config/did/create_failed',
    UPDATE_DID = 'config/did/update',
    UPDATE_DID_SUCCESS = 'config/did/update_success',
    UPDATE_DID_FAILED = 'config/did/update_failed',
    CLEAR_ACTION_STATUS = 'config/did/clear_action_status',
}

// EW DID ACCOUNT
export interface IEWDidState {
    selectedDid?: string
}

// CONFIG DID
export interface ISetEWDidAction {
    type: EWDidActionsTypes.SET_ACCOUNT_DID
    payload: {
        did?: string
    }
}

export type EWDidActions = ISetEWDidAction

export enum EWDidActionsTypes {
    SET_ACCOUNT_DID = 'config/did/editDid',
}
