import React from 'react'

type WarningCircleIconProps = {
    size?: number
    color?: string
    className?: string
}

const WarningCircleIcon: React.FC<WarningCircleIconProps> = React.memo(
    (props) => {
        const { size, color, className } = props

        return (
            <svg
                className={className && className}
                width={size || '17'}
                height={size || '16'}
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_14005_54402)">
                    <path
                        d="M8.7513 1.33366C5.06941 1.33366 2.08464 4.31843 2.08464 8.00032C2.08464 11.6822 5.0694 14.667 8.7513 14.667C12.4332 14.667 15.418 11.6822 15.418 8.00033C15.418 4.31843 12.4332 1.33366 8.7513 1.33366Z"
                        stroke={color || '#C6C6C8'}
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.75 5.33333L8.75 8"
                        stroke={color || '#C6C6C8'}
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.75 10.667L8.74333 10.667"
                        stroke={color || '#C6C6C8'}
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_14005_54402">
                        <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0.75)"
                        />
                    </clipPath>
                </defs>
            </svg>
        )
    }
)

export default WarningCircleIcon
