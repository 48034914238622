import React from 'react'
import { gaColors } from '../../../../../assets/globalConstants'
import { ImageModel } from '../../../../../interfaces/interfaces'
import GaImage from '../../../../atoms/Image/Image'
import styles from './infoPanel.module.scss'
import cx from 'classnames'
import InfoIcon from '../../../../../assets/iconsLibrary/simple/info/infoIcon'

type IInfoPanelProps = {
    label: React.ReactNode | string
    hasIcon?: boolean
    icon?: ImageModel
    classname?: string
}

const InfoPanel: React.FC<IInfoPanelProps> = (props) => {
    const { label, hasIcon, icon, classname } = props

    return (
        <div className={cx(styles.infoPanel, classname && classname)}>
            {hasIcon ? (
                icon ? (
                    <GaImage image={icon} />
                ) : (
                    <InfoIcon size={24} color={gaColors.info300} />
                )
            ) : null}

            {label}
        </div>
    )
}

export default InfoPanel
