import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { gaImages } from '../../../../../assets/globalConstants'
import { ReactComponent as CloseIcon } from '../../../../../assets/images/close.svg'
import { licensesSelectors } from '../../../../../features/common/licenses'
import { getLicenseType } from '../../../../../features/common/session/sessionSelectors'
import { ImageModel } from '../../../../../interfaces/interfaces'
import LightButton from '../../../buttons/LightButton/LightButton'
import GaImage from '../../../Image/Image'
// import ScreenHeader from '../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
// import WarningPanel from '../../../../Components/molecules/notification/panels/warning/warningPanel'
// import CardListSkeleton from '../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
// import LicenseCard from '../../../../Components/organisms/Cards/LicenseCard/LicenseCard'
// import { IPriceModel } from '../../../../interfaces/interfaces'
// import { setSelectedRoute } from '../../../PanelScafolding/scafoldingActions'
import styles from './statusModal.module.scss'

type IStatusModalProps = {
    status: { icon: ImageModel; label: string; description: string }
    show: boolean
    hideModal: (x?) => void
}

const StatusModal: React.FC<IStatusModalProps> = (props) => {
    const { show, status, hideModal } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            }`}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div
                        className={styles.modal__header__close}
                        onClick={() => hideModal()}
                    >
                        <CloseIcon />
                    </div>
                    <div className={styles.modal__header__icon}>
                        <GaImage image={status.icon || gaImages.infoCircle} />
                    </div>
                    <div className={styles.modal__header__title}>
                        <h3>{t(status.label)}</h3>
                    </div>
                </div>
                <div className={styles.modal__container}>
                    <p className={styles.modal__container__text}>
                        {t(status.description)}
                    </p>
                    <LightButton
                        className={styles.modal__container__button}
                        functionality={hideModal}
                        text={'public.close'}
                        disabled={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default StatusModal
