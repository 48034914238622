import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    CreateOrgPayloadModel,
    DataRequestModel,
    OrganizationModel,
} from '../../../interfaces/interfaces'

export interface ISubmitOrg {
    type: OrganizationTypes.LOAD_ORG
}

export interface IGetOrg {
    type: OrganizationTypes.GET_ORG
}

export interface ILoadOrgFailed {
    type: OrganizationTypes.LOAD_ORG_FAILED
}

export interface ILoadOrgSuccess {
    type: OrganizationTypes.LOAD_ORG_SUCCESS
    org: OrganizationModel
    termsAreAccepted?: boolean
}

export interface IUpdateOrg {
    type: OrganizationTypes.UPDATE_ORG
    org: OrganizationModel
}

export interface IUpdateOrgNoPayment {
    type: OrganizationTypes.UPDATE_ORG_NO_PAYMENT
    org: OrganizationModel
}

export interface IUpdateOrgSuccess {
    type: OrganizationTypes.UPDATE_ORG_SUCCESS
    org: OrganizationModel
}

export interface IUpdateOrgFailed {
    type: OrganizationTypes.UPDATE_ORG_FAILED
}
export interface ICreateOrg {
    type: OrganizationTypes.CREATE_ORG
    payload: {
        org: CreateOrgPayloadModel
        isStarter?: boolean
    }
}

export interface ICreateStarterOrg {
    type: OrganizationTypes.CREATE_STARTER_ORG
    payload: {
        org: CreateOrgPayloadModel
    }
}

export interface ICreateOrgSuccess {
    type: OrganizationTypes.CREATE_ORG_SUCCESS
    payload: {
        org: OrganizationModel
        isStarter?: boolean
    }
}

export interface ICreateStarterOrgSuccess {
    type: OrganizationTypes.CREATE_STARTER_ORG_SUCCESS
    payload: {
        org: OrganizationModel
    }
}

export interface ICreateOrgToRequestSub {
    type: OrganizationTypes.CREATE_ORG_TO_REQUEST_SUB
    payload: {
        org: CreateOrgPayloadModel
        data: DataRequestModel
    }
}

export interface ICreateOrgSuccess {
    type: OrganizationTypes.CREATE_ORG_SUCCESS
    payload: {
        org: OrganizationModel
        isStarter?: boolean
    }
}

export interface ICreateStarterOrgSuccess {
    type: OrganizationTypes.CREATE_STARTER_ORG_SUCCESS
    payload: {
        org: OrganizationModel
    }
}

export interface ICreateRequestOrgSuccess {
    type: OrganizationTypes.CREATE_REQUEST_ORG_SUCCESS
    payload: {
        org: OrganizationModel
    }
}

export interface ICreateOrgFailed {
    type: OrganizationTypes.CREATE_ORG_FAILED
    payload: {
        error: string
    }
}

export interface IDeleteOrg {
    type: OrganizationTypes.DELETE_ORG
    orgId: string
}

export interface IDeleteOrgSuccess {
    type: OrganizationTypes.DELETE_ORG_SUCCESS
}

export interface IDeleteOrgFailed {
    type: OrganizationTypes.DELETE_ORG_FAILED
}

export enum OrganizationTypes {
    LOAD_ORG = 'org/load',
    GET_ORG = 'org/get',
    LOAD_ORG_SUCCESS = 'org/load_success',
    LOAD_ORG_FAILED = 'org/load_failed',
    UPDATE_ORG = 'org/update',
    UPDATE_ORG_NO_PAYMENT = 'org/update_no_payment',
    UPDATE_ORG_SUCCESS = 'org/update_success',
    UPDATE_ORG_FAILED = 'org/update_failed',
    CREATE_ORG = 'org/create',
    CREATE_STARTER_ORG = 'org/create_starter',
    CREATE_STARTER_ORG_SUCCESS = 'org/create_starter_success',
    CREATE_REQUEST_ORG_SUCCESS = 'org/create_org_to_request',
    CREATE_ORG_TO_REQUEST_SUB = 'org/create_org_to_request_sub',
    CREATE_ORG_SUCCESS = 'org/create_success',
    CREATE_ORG_FAILED = 'org/create_failed',
    DELETE_ORG = 'org/delete',
    DELETE_ORG_SUCCESS = 'org/delete_success',
    DELETE_ORG_FAILED = 'org/delete_failed',
}

export type IOrganizationActions =
    | ISubmitOrg
    | IGetOrg
    | ILoadOrgSuccess
    | ILoadOrgFailed
    | IUpdateOrg
    | IUpdateOrgSuccess
    | IUpdateOrgFailed
    | ICreateOrg
    | ICreateStarterOrg
    | ICreateStarterOrgSuccess
    | ICreateRequestOrgSuccess
    | ICreateOrgSuccess
    | ICreateOrgFailed
    | ICreateOrgToRequestSub

export interface IOrganizationState {
    did: string
    name: string
    status: string
    licenseType: string
    org?: OrganizationModel
    creatingOrgLoading: Boolean
    termsAccepted?: boolean
    orgLoading?: boolean
    loadingOrgByIdStatus: ActionStatusTypes
}

export interface IVouch {
    client_id: string
    client_name: string
    client_secret: string
    whitelist?: Array<string>
    description?: string
    redirect_uris: string[]
    grant_types: Array<string>
    response_types: Array<string>
    scope: string
    owner: string
    connect_client: string
    connect_secret: string
    connect_tenant: string
    token_endpoint_auth_method: string
    qrCustom?: IQrCustom
}

export interface IQrCustom {
    title?: string
    description?: string
    isBrandHide?: boolean
    qrColor?: string
}
