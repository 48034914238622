import {
    ActionStatusTypes,
    PrivateKeyTypes,
} from '../../../assets/globalConstants'
import {
    ConfigDidActions,
    ConfigDidActionsTypes,
    ConfigDidFormActions,
    ConfigDidFormActionsTypes,
    DidListActions,
    DidListActionsTypes,
    EWDidActions,
    EWDidActionsTypes,
    IConfigDidFormState,
    IDidListState,
    IDidState,
    IEWDidState,
} from './didTypes'

// LIST
const emptyDidsSetup = {
    didConfigs: undefined,
    defaultDid: '',
    connectLoginConfig: {
        connectHost: '',
        connectClient: '',
        connectSecret: '',
        connectTenant: '',
    },
}

const initialDidListState: IDidListState = {
    didsSetup: emptyDidsSetup,
    loadingStatus: false,
    error: '',
}

export function didListReducer(
    state = initialDidListState,
    action: DidListActions
): IDidListState {
    switch (action.type) {
        case DidListActionsTypes.GET_DIDS:
            return {
                ...state,
                loadingStatus: true,
            }
        case DidListActionsTypes.GET_DIDS_SUCCESS:
            return {
                ...state,
                didsSetup: action.payload.didsSetup,
                loadingStatus: false,
                error: '',
            }
        case DidListActionsTypes.GET_DIDS_FAILED:
            return {
                ...state,
                didsSetup: emptyDidsSetup,
                loadingStatus: false,
                error: action.payload.error,
            }
        case DidListActionsTypes.CLEAR_DID_LIST:
            return initialDidListState as IDidListState
        default:
            return state
    }
}

const initialFormState: IConfigDidFormState = {
    steps: [],
    firstStep: null,
    step: null,
    finalStep: null,
}

export function configDidFormReducer(
    state = initialFormState,
    action: ConfigDidFormActions
): IConfigDidFormState {
    switch (action.type) {
        case ConfigDidFormActionsTypes.SETSTEP:
            return {
                ...state,
                step: action.payload.step,
            } as IConfigDidFormState
        case ConfigDidFormActionsTypes.SETSTEPS:
            const lastPosition = action.payload.steps.length - 1
            return {
                ...state,
                steps: action.payload.steps,
                firstStep: action.payload.steps[0],
                step: action.payload.steps[0],
                finalStep: action.payload.steps[lastPosition],
            } as IConfigDidFormState
        case ConfigDidFormActionsTypes.CLEAR_FORM_STEP:
            return initialFormState as IConfigDidFormState
        default:
            return state
    }
}

const initialDidState: IDidState = {
    did: {
        DidId: '',
        did: '',
        ledger: 'ETH',
        publicKey: '',
        privateKeyData: '',
        privateKeyType: undefined,
        sessionTimeout: 500,
        tokenByCookie: false,
        secret: '',
        sandbox: false,
        alias: '',
        default: false,
    },
    token: '',
    baseId: '',
    status: ActionStatusTypes.neutral,
    isKeypairLoading: false,
    isSecretLoading: false,
    error: '',
}

export function configDidReducer(
    state = initialDidState,
    action: ConfigDidActions
): IDidState {
    switch (action.type) {
        case ConfigDidActionsTypes.SELECT_DID:
            return {
                ...state,
                did: action.payload.did,
                baseId: action.payload.did?.DidId || action.payload.did?.did,
            }
        case ConfigDidActionsTypes.GET_KEYPAIR:
            return {
                ...state,
                isKeypairLoading: true,
            }
        case ConfigDidActionsTypes.GET_KEYPAIR_SUCCESS:
            return {
                ...state,
                isKeypairLoading: false,
                did: {
                    ...state.did,
                    publicKey: action.payload.publicKey,
                    privateKeyData: action.payload.privateKey,
                    privateKeyType: PrivateKeyTypes.DB,
                },
            }
        case ConfigDidActionsTypes.GET_KEYPAIR_FAILED:
            return {
                ...state,
                isKeypairLoading: false,
                did: {
                    ...state.did,
                    publicKey: '',
                    privateKeyData: '',
                },
            }
        case ConfigDidActionsTypes.GET_SECRET:
            return {
                ...state,
                isSecretLoading: true,
            }
        case ConfigDidActionsTypes.GET_SECRET_SUCCESS:
            return {
                ...state,
                isSecretLoading: false,
                did: {
                    ...state.did,
                    secret: action.payload.secret,
                },
            }
        case ConfigDidActionsTypes.GET_SECRET_FAILED:
            return {
                ...state,
                isSecretLoading: false,
                did: {
                    ...state.did,
                    secret: '',
                },
            }
        case ConfigDidActionsTypes.EDIT_DID:
            return {
                ...state,
                did: {
                    ...state.did,
                    ledger: !!action.payload.did?.ledger
                        ? action.payload.did?.ledger
                        : state.did?.ledger,
                    publicKey: !!action.payload.did?.publicKey
                        ? action.payload.did?.publicKey
                        : state.did?.publicKey,
                    privateKeyData: !!action.payload.did?.privateKeyData
                        ? action.payload.did?.privateKeyData
                        : state.did?.privateKeyData,
                    privateKeyType: !!action.payload.did?.privateKeyType
                        ? action.payload.did?.privateKeyType
                        : state.did?.privateKeyType,
                    sessionTimeout: !!action.payload.did?.sessionTimeout
                        ? action.payload.did?.sessionTimeout
                        : 500,
                    tokenByCookie: !!action.payload.did?.tokenByCookie
                        ? action.payload.did?.tokenByCookie
                        : false,
                    secret: !!action.payload.did?.secret
                        ? action.payload.did?.secret
                        : state.did?.secret,
                    sandbox: !!action.payload.did?.sandbox
                        ? action.payload.did?.sandbox
                        : false,
                    alias: !!action.payload.did?.alias
                        ? action.payload.did?.alias
                        : state.did?.alias,
                },
                token: !!action.payload.token
                    ? action.payload.token
                    : state.token,
            } as IDidState
        case ConfigDidActionsTypes.CREATE_DID:
            return {
                ...state,
                status: ActionStatusTypes.loading,
                error: '',
            }
        case ConfigDidActionsTypes.CREATE_DID_SUCCESS:
            return {
                ...state,
                status: ActionStatusTypes.success,
                error: '',
            }
        case ConfigDidActionsTypes.CREATE_DID_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
                error: action.payload?.error,
            }
        case ConfigDidActionsTypes.UPDATE_DID:
            return {
                ...state,
                status: ActionStatusTypes.loading,
            }
        case ConfigDidActionsTypes.UPDATE_DID_SUCCESS:
            return {
                ...state,
                status: ActionStatusTypes.success,
            }
        case ConfigDidActionsTypes.UPDATE_DID_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
                error: action.payload?.error,
            }
        case ConfigDidActionsTypes.CLEAR_ACTION_STATUS:
            return {
                ...state,
                status: ActionStatusTypes.neutral,
            }
        case ConfigDidActionsTypes.CLEAR_CURRENT_DID:
            return initialDidState as IDidState
        default:
            return state
    }
}

// EW DID

const initialEWDidState: IEWDidState = {
    selectedDid: undefined,
}

export function EWDidAccountReducer(
    state = initialEWDidState,
    action: EWDidActions | ConfigDidActions
): IEWDidState {
    switch (action.type) {
        case EWDidActionsTypes.SET_ACCOUNT_DID:
            return {
                ...state,
                selectedDid: action.payload.did,
            }
        case ConfigDidActionsTypes.CREATE_DID_SUCCESS:
            return {
                ...state,
                selectedDid: action.payload.didId || state.selectedDid,
            }
        default:
            return state
    }
}
