import React from 'react'

type ShieldCheckIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const ShieldCheckIcon: React.FC<ShieldCheckIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M15.9999 29.3333C15.9999 29.3333 26.6666 24 26.6666 16V6.66667L15.9999 2.66667L5.33325 6.66667V16C5.33325 24 15.9999 29.3333 15.9999 29.3333Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.7273 12L14.7273 18L12 15.2727"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <path
                d="M15.9999 29.3333C15.9999 29.3333 26.6666 24 26.6666 16V6.66667L15.9999 2.66667L5.33325 6.66667V16C5.33325 24 15.9999 29.3333 15.9999 29.3333Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.7273 12L14.7273 18L12 15.2727"
                stroke="white"
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export default ShieldCheckIcon
