import { t } from 'i18next'
import {
    CellContentTypes,
    TextAlignTypes,
} from '../../../../assets/globalConstants'
import {
    ButtonModel,
    StepModalModel,
    StepModel,
} from '../../../../interfaces/interfaces'
import { threshold } from '../../../../utils/fuzzySearchUtil'
import ConfigurationForm from '../components/forms/configuration/ConfigurationForm'
import VerificationPageForm from '../components/forms/verificationPage/VerificationPageForm'
import FirstStep from '../components/tutorial/steps/FirstStep'
import SecondStep from '../components/tutorial/steps/SecondStep'
import ThirdStep from '../components/tutorial/steps/ThirdStep'
import FourthStep from '../components/tutorial/steps/FourthStep'
import Success from '../components/forms/success/Success'
import EditConfigurationForm from '../components/editAppInt/modal/Steps/ConfigurationForm'
import EditVerificationPageForm from '../components/editAppInt/modal/Steps/VerificationPageForm'

export const headerButton: ButtonModel = {
    label: 'appIntegrations.createApp',
    hideIconTooltip: true,
    disabledTooltipLabel: t('subscription.limitReachedDescription', {
        feature: t('subscription.limitedFeatures.appIntegrations'),
    }),
}

export const propertiesToFuzzySearchAppIntergrations = [
    { key: 'client_id', threshold: threshold.low },
    { key: 'client_name', threshold: threshold.mediumLow },
    { key: 'scope', threshold: threshold.mediumLow },
    { key: 'created_at_formatted', threshold: threshold.low },
    { key: 'redirect_uris__str', threshold: threshold.medium },
]

export const headersAppIntegrationsTable = [
    {
        key: 'appData',
        text: 'appIntegrations.appName',
        tag: true,
        type: CellContentTypes.tag,
        short: true,
    },
    {
        key: 'createdAt',
        text: 'appIntegrations.creationDate',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.date,
    },
    {
        key: 'scope',
        text: 'appIntegrations.scope',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.action,
    },
    {
        key: 'redirectUrls',
        text: 'appIntegrations.redirectUrl',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.action,
    },
    {
        key: 'actions',
        text: 'appIntegrations.actions',
        textAlign: TextAlignTypes.right,
        type: CellContentTypes.action,
    },
]

// TODO: add scopes
export const appIntegrationsScopes = {
    read: [],
    edit: [],
    create: [],
    delete: [],
}

export const appIntFormSteps: StepModel[] = [
    // TODO: Set stepper labels
    {
        id: 1,
        label: 'appIntegrations.configuration.title',
        order: 0,
        component: ConfigurationForm,
    },
    {
        id: 2,
        label: 'appIntegrations.verificationPage.stepTitle',
        order: 1,
        component: VerificationPageForm,
    },
    {
        id: 3,
        label: 'appIntegrations.success.stepTitle',
        order: 2,
        component: Success,
    },
]

export const tutorialSteps: StepModel[] = [
    {
        id: 1,
        order: 0,
        label: '',
        component: FirstStep,
    },
    {
        id: 2,
        order: 1,
        label: '',
        component: SecondStep,
    },
    {
        id: 3,
        order: 2,
        label: '',
        component: ThirdStep,
    },
    {
        id: 4,
        order: 3,
        label: '',
        component: FourthStep,
    },
]

export const editAppIntModalStepsTwo: StepModalModel[] = [
    {
        id: 1,
        order: 0,
        content: EditConfigurationForm,
    },
    {
        id: 2,
        order: 1,
        content: EditVerificationPageForm,
    },
]

export const editAppIntModalSteps = [
    {
        id: '1',
        tabTitle: t('appIntegrations.configuration.title'),
    },
    {
        id: '2',
        tabTitle: t('appIntegrations.verificationPage.stepTabTitle'),
    },
]
