import React from 'react'
import styles from './storesPanel.module.scss'
import cx from 'classnames'
import { t } from 'i18next'
import GaImage from '../../../../../../../../Components/atoms/Image/Image'
import { gaImages } from '../../../../../../../../assets/globalConstants'

type StoresPanelProps = {
    isMobilePreview?: boolean
}

const StoresPanel: React.FC<StoresPanelProps> = React.memo((props) => {
    const { isMobilePreview } = props

    return (
        <div
            className={cx(
                styles.storesPanel,
                isMobilePreview && styles.mobileStoresPanel
            )}
        >
            <GaImage image={gaImages.downloadPurple} />

            <div className={cx(styles.storesPanel__texts)}>
                <p>{t('appIntegrations.verificationPage.firstTime')}</p>
                <p className={cx('neutral700')}>
                    {t('appIntegrations.verificationPage.downloadWallet')}
                </p>
            </div>
            <div className={cx(styles.storesPanel__links)}>
                <GaImage image={gaImages.googleBrandButtonColor} />
                <GaImage image={gaImages.appleStoreBordered} />
            </div>
        </div>
    )
})

export default StoresPanel
