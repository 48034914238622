import React from 'react'

type ConnectIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const ConnectIcon: React.FC<ConnectIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <g clipPath="url(#clip0_3811_28586)">
                <path
                    d="M10.665 6C10.665 8.57641 8.57641 10.665 6 10.665C3.42359 10.665 1.335 8.57641 1.335 6C1.335 3.42359 3.42359 1.335 6 1.335C8.57641 1.335 10.665 3.42359 10.665 6Z"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.665 26C30.665 28.5764 28.5764 30.665 26 30.665C23.4236 30.665 21.335 28.5764 21.335 26C21.335 23.4236 23.4236 21.335 26 21.335C28.5764 21.335 30.665 23.4236 30.665 26Z"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.99634 5.61601L14.4907 5.61601C15.2271 5.61601 15.8241 6.21297 15.8241 6.94935V24.1544C15.8241 24.8908 16.421 25.4877 17.1574 25.4877H22.0671"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                />
            </g>
            <defs>
                <clipPath id="clip0_3811_28586">
                    <rect
                        width={props?.size || '32'}
                        height={props?.size || '32'}
                        fill="white"
                    />
                </clipPath>
            </defs>
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <g clipPath="url(#clip0_3811_28608)">
                <path
                    d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                    fill={props?.color || 'currentColor' || '#1E1E20'}
                />
                <path
                    d="M26 32C29.3137 32 32 29.3137 32 26C32 22.6863 29.3137 20 26 20C22.6863 20 20 22.6863 20 26C20 29.3137 22.6863 32 26 32Z"
                    fill={props?.color || 'currentColor' || '#1E1E20'}
                />
                <path
                    d="M9.99634 5.61601L14.4907 5.61601C15.2271 5.61601 15.8241 6.21297 15.8241 6.94935V24.1544C15.8241 24.8908 16.421 25.4877 17.1574 25.4877H22.0671"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                />
            </g>
            <defs>
                <clipPath id="clip0_3811_28608">
                    <rect
                        width={props?.size || '32'}
                        height={props?.size || '32'}
                        fill="white"
                    />
                </clipPath>
            </defs>
        </>
    )
}

export default ConnectIcon
