import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ImageModel } from '../../../interfaces/interfaces'
import GaImage from '../Image/Image'
import style from './tag.module.scss'
import cx from 'classnames'

type GaTagProps = {
    text: React.ReactNode | string
    extraTranslationParams?: Object
    color: string
    className?: string
    icon?: ImageModel
    leftIconComponent?: any
    rightIconComponent?: any
    item?: any
    functionality?: (x?, y?) => void
    iconFunctionality?: (x, y?) => void
}

const GaTag: React.FC<GaTagProps> = React.memo((props) => {
    const {
        text,
        extraTranslationParams,
        color,
        className,
        icon,
        leftIconComponent,
        rightIconComponent,
        item,
        functionality,
        iconFunctionality,
    } = props
    const { t } = useTranslation()

    const setColorClass = () => {
        let colorClass = 'green'
        switch (color) {
            case 'yellow':
                colorClass = style.yellow
                break
            case 'green':
                colorClass = style.green
                break
            case 'red':
                colorClass = style.red
                break
            case 'gaBlue':
                colorClass = style.gaBlue
                break
            case 'grey':
                colorClass = style.grey
                break
            case 'salmon':
                colorClass = style.salmon
                break
            case 'pink':
                colorClass = style.pink
                break
            default:
                colorClass = style.green
        }

        return colorClass
    }

    return (
        <div
            className={`${cx(
                'buttonSM',
                style.tag
            )} ${className} ${setColorClass()}`}
            onClick={() => {
                functionality ? functionality(item, text) : ''
            }}
        >
            {leftIconComponent ? (
                <props.leftIconComponent color="white" size={16} />
            ) : null}{' '}
            {typeof text === 'string' ? (
                <Trans i18nKey={t(text)} values={extraTranslationParams} />
            ) : (
                text
            )}
            {icon ? (
                <div
                    onClick={() => {
                        iconFunctionality ? iconFunctionality(item, text) : null
                    }}
                >
                    <GaImage image={icon} />
                </div>
            ) : null}
            {rightIconComponent ? (
                <props.rightIconComponent color="white" size={16} />
            ) : null}
        </div>
    )
})

export default GaTag
