import {
    ICatalogModel,
    ICredentialAuthorityModel,
    ICredentialServiceProviderModel,
    ICredentialsGroupModel,
    ICredentialTypeModel,
} from '../../../interfaces/interfaces'

export interface IGetCatalog {
    type: CredentialActionTypes.GET_CATALOG
}

export interface IMergePrivateCatalog {
    type: CredentialActionTypes.GET_PRIVATE_CATALOG
    payload: {
        catalog: ICatalogModel
    }
}

export interface IGetCatalogSuccess {
    type: CredentialActionTypes.GET_CATALOG_SUCCESS
    payload: {
        catalog: ICatalogModel
    }
}
export interface IGetCatalogSuccessError {
    type: CredentialActionTypes.GET_CATALOG_ERROR
    error: string
}

export interface ICredentialRevoke {
    type: CredentialActionTypes.CREDENTIAL_REVOKE
    payload: {
        id: string
    }
}

export interface ISuspendCredential {
    type: CredentialActionTypes.CREDENTIAL_SUSPEND
    payload: {
        id: string
    }
}

export interface IReactivateCredential {
    type: CredentialActionTypes.CREDENTIAL_REACTIVATE
    payload: {
        id: string
    }
}

export interface IUpdateCredential {
    type: CredentialActionTypes.CREDENTIAL_UPDATE
    payload: {
        id: string
        status: string
    }
}

export interface IAddIssuerToGroup {
    type: CredentialActionTypes.ADD_TO_GROUP
    payload: {
        issuerDid: string
        groupId: string
    }
}

export interface IRemoveIssuerFromGroup {
    type: CredentialActionTypes.REMOVE_FROM_GROUP
    payload: {
        issuerDid: string
        groupId: string
    }
}

export interface IDeletePrivateGroup {
    type: CredentialActionTypes.DELETE_GROUP
    payload: {
        groupId: string
    }
}

export type CatalogAction =
    | ISuspendCredential
    | ICredentialRevoke
    | IGetCatalogSuccess
    | IGetCatalog
    | IGetCatalogSuccessError
    | IReactivateCredential
    | IUpdateCredential
    | IMergePrivateCatalog
    | IAddIssuerToGroup
    | IRemoveIssuerFromGroup
    | IDeletePrivateGroup

export interface CatalogState {
    authority: ICredentialAuthorityModel[]
    credentialGroups: ICredentialsGroupModel[]
    credentialTypes: ICredentialTypeModel[]
    serviceProviders: ICredentialServiceProviderModel[]
    loading: { status: string; description: string }
}

export enum CredentialActionTypes {
    GET_CATALOG = 'catalog/get',
    GET_PRIVATE_CATALOG = 'catalog/get_private',
    GET_CATALOG_SUCCESS = 'catalog/get_success',
    GET_CATALOG_ERROR = 'catalog/get_failed',
    CREDENTIAL_REVOKE = 'credential/revoke',
    CREDENTIAL_SUSPEND = 'credential/suspend',
    CREDENTIAL_REACTIVATE = 'credential/reactivate',
    CREDENTIAL_UPDATE = 'credential/update',
    ADD_TO_GROUP = 'catalog/add_issuer_in_private_group',
    REMOVE_FROM_GROUP = 'catalog/remove_issuer_from_private_group',
    DELETE_GROUP = 'catalog/delete_private_group',
}
