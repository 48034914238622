import { CertificateModel } from '../../../interfaces/interfaces'
import {
    CertificatesListActions,
    CertificatesListActionsTypes,
    ClearCertificatesList,
    ConfigCertificateActionsTypes,
    IClearActionStatus,
    IClearCertificate,
    IDeleteCertificateAction,
    IDeleteCertificateFailed,
    IDeleteCertificateSuccessAction,
    IGetCertificatesFailed,
    IGetCertificatesSuccessAction,
    ISelectCertificateAction,
    IUpdateCertificateAction,
    IUpdateCertificateFailedAction,
    IUpdateCertificateSuccessAction,
    IUploadCertificateFailedAction,
    IUploadCertificateSuccessAction,
} from './certificatesTypes'

// LIST
export const getCertificates = (): CertificatesListActions => {
    return {
        type: CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES,
    }
}

export const getCertificatesSuccess = (
    data: CertificateModel[]
): IGetCertificatesSuccessAction => {
    return {
        type: CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES_SUCCESS,
        payload: { data },
    }
}

export const getCertificatesFailed = (
    error: string
): IGetCertificatesFailed => {
    return {
        type: CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES_FAILED,
        payload: {
            error: error,
        },
    }
}

export const selectCertificate = (
    certificate: CertificateModel
): ISelectCertificateAction => {
    return {
        type: CertificatesListActionsTypes.SELECT_QUALIFIED_SIGNATURE,
        payload: {
            certificate: certificate,
        },
    }
}

export const deleteCertificateSuccess = (): IDeleteCertificateSuccessAction => {
    return {
        type: CertificatesListActionsTypes.DELETE_QUALIFIED_SIGNATURE_SUCCESS,
    }
}

export const deleteCertificateFailed = (
    error: string
): IDeleteCertificateFailed => {
    return {
        type: CertificatesListActionsTypes.DELETE_QUALIFIED_SIGNATURE_FAILED,
        payload: {
            error: error,
        },
    }
}

export const deleteCertificate = (id: string): IDeleteCertificateAction => {
    return {
        type: CertificatesListActionsTypes.DELETE_QUALIFIED_SIGNATURE,
        payload: {
            id,
        },
    }
}

export const uploadCertificate = (
    file: File,
    alias?: string,
    password?: string
): CertificatesListActions => {
    return {
        type: CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE,
        payload: { alias, password, file },
    }
}

export const uploadCertificateSuccess = (): IUploadCertificateSuccessAction => {
    return {
        type: CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE_SUCCESS,
    }
}

export const uploadCertificateFailed = (
    error: string
): IUploadCertificateFailedAction => {
    return {
        type: CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearCertificatesList = (): ClearCertificatesList => {
    return {
        type: CertificatesListActionsTypes.CLEAR_QUALIFIED_SIGNATURE_LIST,
    }
}

export const clearCertificate = (): IClearCertificate => {
    return {
        type: ConfigCertificateActionsTypes.CLEAR_CURRENT_QUALIFIED_SIGNATURE,
    }
}

export const updateCertificate = (
    newConfig: CertificateModel,
    certificateId: string
): IUpdateCertificateAction => {
    return {
        type: ConfigCertificateActionsTypes.UPDATE_QUALIFIED_SIGNATURE,
        payload: {
            certificate: newConfig,
            certificateId: certificateId,
        },
    }
}

export const updateCertificateSuccess = (): IUpdateCertificateSuccessAction => {
    return {
        type: ConfigCertificateActionsTypes.UPDATE_QUALIFIED_SIGNATURE_SUCCESS,
    }
}

export const updateCertificateFailed = (
    err: any
): IUpdateCertificateFailedAction => {
    return {
        type: ConfigCertificateActionsTypes.UPDATE_QUALIFIED_SIGNATURE_FAILED,
        payload: {
            error: err,
        },
    }
}

export const clearActionStatus = (): IClearActionStatus => {
    return {
        type: ConfigCertificateActionsTypes.CLEAR_ACTION_STATUS,
    }
}
