import React from 'react'
import { useTranslation } from 'react-i18next'
import { OptionsIcons } from '../../../../assets/globalConstants'
import { initialLang } from '../../../../i18n'
import style from './radioButtonOptionsCard.module.scss'
import GaRadioButton from '../../../atoms/formFields/radioButton/GaRadioButton'
import { OptionsModel } from '../../../../interfaces/interfaces'
import { TextSizes } from '../../../../data/globalVar'

type IRadioButtonOptionsCardProps = {
    option: OptionsModel
    index: number
    id?: string
    checked?: boolean
    handleOptionChange: (e) => void
}

const RadioButtonOptionsCard: React.FC<IRadioButtonOptionsCardProps> = (
    props
) => {
    const { option, index, id, checked, handleOptionChange } = props

    const { t } = useTranslation()
    function handleChange(option: string) {
        const selection = `openid ${option}`
        handleOptionChange(selection)
    }

    return (
        <div
            id={id}
            className={`${style.optionCard} `}
            key={option.id + Math.random()}
            onClick={() => {
                handleChange(option?.id)
            }}
        >
            <div className={style.optionCard__header}>
                <div className={style.optionCard__header__title}>
                    <img
                        className={style.optionCard__header__title__icon}
                        src={
                            OptionsIcons[
                                option?.icon ? option?.icon[0] : 'option'
                            ] || OptionsIcons?.userPlus
                        }
                        alt={option?.id}
                    />
                </div>

                <GaRadioButton
                    index={'ageVerificationOption' + index}
                    id={`ageVerificationOption_${index}`}
                    name={option?.id}
                    value={option?.id}
                    itemText={t(
                        option?.name[initialLang]
                            ? option?.name[initialLang]
                            : option?.name['en'] || option?.name || ''
                    )}
                    onChange={() => {}}
                    textSize={TextSizes.LG}
                    checked={checked}
                />
            </div>
        </div>
    )
}

export default RadioButtonOptionsCard
