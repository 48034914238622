import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './description.module.scss'

type DescriptionProps = {
    text: string
    extraTranslationParams?: Object
    className?: string
}

const Description: React.FC<DescriptionProps> = React.memo((props) => {
    const { text, extraTranslationParams, className } = props
    const { t } = useTranslation()

    return (
        <p className={`${styles.description} ${className}`}>
            <Trans i18nKey={text} values={{ ...extraTranslationParams }} />
        </p>
    )
})

export default Description
