import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './stepsContent.module.scss'
import GaImage from '../../../../atoms/Image/Image'
import GaTextInput from '../../../../atoms/formFields/textInput/GaTextInput'
import {
    ActionStatusTypes,
    brandLabels,
    gaImages,
} from '../../../../../assets/globalConstants'
import ScanModal from '../ScanModal'
import { StepModalModel } from '../../../../../interfaces/interfaces'
import ScanModalLoading from '../Status/Loading/Loading'
import { useDispatch, useSelector } from 'react-redux'
import {
    clearModalState,
    getProcess,
    sendProcessData,
    setProcessData,
} from '../../../../../store/scanModal/scanModalActions'
import { didsSelectors } from '../../../../../features/studio/Dids'
import { scanModalSelectors } from '../../../../../store/scanModal'
import ScanModalSuccess from '../Status/Success/Success'
import UnknowConsentError from '../Status/ConsentError/UnknowError/UnknowConsentError'

type IConnectionForm = {
    isConnection?: boolean
    scanCurrentStep: StepModalModel
    scanSteps: StepModalModel[]
    receiverDataName?: string
    setScanCurrentStep: (x?) => void
    hideModal: () => void
}

const ConnectionForm: React.FC<IConnectionForm> = (props) => {
    const {
        isConnection,
        scanCurrentStep,
        scanSteps,
        receiverDataName,
        setScanCurrentStep,
        hideModal,
    } = props

    const dispatch = useDispatch()

    // Selectors
    const EWSelectedDid = useSelector(didsSelectors.getEWDid)
    const gettingProcessStatus = useSelector(
        scanModalSelectors.gettingProcessStatus
    )
    const sendingDataProcessStatus = useSelector(
        scanModalSelectors.sendingProcessDataStatus
    )
    const process = useSelector(
        scanModalSelectors.getProccessWithFormattedCreds
    )
    const proccessDataToSend = useSelector(
        scanModalSelectors.getProccessDataToSend
    )

    const needsConsent = process?.needs_consent

    // States
    const [formData, setFormState] = useState({
        url: '',
    })
    const [isLoading, setIsLoading] = useState(false)
    const getProccessIsFailed =
        gettingProcessStatus === ActionStatusTypes.failed
    const sendDataIsFailed =
        sendingDataProcessStatus === ActionStatusTypes.failed

    const [isSuccess, setIsSuccess] = useState(false)

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formData,
            [name]: value,
        })
    }

    // Functions
    const continueButtonFunction = () => {
        setIsLoading(true),
            dispatch(getProcess(EWSelectedDid || '', formData?.url))
    }

    const functionForClose = () => {
        hideModal()
        dispatch(clearModalState())
    }

    useEffect(() => {
        if (gettingProcessStatus === ActionStatusTypes.success) {
            setIsLoading(false)

            // TODO: uncomment when 2FA is available
            // !needsConsent &&
            //     dispatch(
            //         setProcessData(EWSelectedDid || '', {
            //             process_id: process?.processId || '',
            //         })
            //     )
            // setScanCurrentStep(
            //     needsConsent
            //         ? scanSteps[scanCurrentStep?.order + 1]
            //         : scanSteps[scanCurrentStep?.order + 2]
            // )

            !needsConsent
                ? sendData()
                : setScanCurrentStep(scanSteps[scanCurrentStep?.order + 1])
        } else if (gettingProcessStatus === ActionStatusTypes.failed) {
            setIsLoading(false)
        }
    }, [gettingProcessStatus])

    const sendData = () => {
        setIsLoading(true)

        dispatch(
            sendProcessData(EWSelectedDid || '', {
                process_id: process?.processId || '',
            })
        )
    }

    useEffect(() => {}, [process])
    useEffect(() => {
        if (sendingDataProcessStatus === ActionStatusTypes.success) {
            setIsLoading(false)
            setIsSuccess(true)
        } else if (sendingDataProcessStatus === ActionStatusTypes.failed) {
            setIsLoading(false)
        }
    }, [sendingDataProcessStatus])

    return !isLoading && !isSuccess && !sendDataIsFailed ? (
        <ScanModal
            title={t(
                isConnection
                    ? 'ew.modalScan.connection.title'
                    : 'ew.modalScan.requestCred.title'
            )}
            description={t(
                isConnection
                    ? 'ew.modalScan.connection.description'
                    : 'ew.modalScan.requestCred.description'
            )}
            hideModal={functionForClose}
            continueButton={{
                label: t(
                    isConnection
                        ? 'ew.modalScan.connect'
                        : 'ew.modalScan.request'
                ),
                disabled: !formData.url?.trim(),
                function: continueButtonFunction,
            }}
            cancelButton={{
                label: t('public.cancel'),
                disabled: false,
                function: functionForClose,
            }}
        >
            <div className={`${styles.bodyContainer}`}>
                <div className={styles.imageContainer}>
                    <GaImage
                        className={styles.image}
                        image={gaImages.walletExampleConnection}
                    />
                    <p className={cx('marginTop8')}>
                        <span className={cx('bodyRegularXS primary700')}>
                            *{' '}
                        </span>
                        <span className={cx('bodyRegularXS neutral700')}>
                            {t('ew.modalScan.connection.imageText')}
                        </span>
                    </p>
                </div>
                <div>
                    <GaTextInput
                        id={'url'}
                        labelText={t('ew.modalScan.connection.inputLabel')}
                        placeholder={t(
                            'ew.modalScan.connection.inputPlaceholder'
                        )}
                        value={formData.url}
                        required
                        name={'url'}
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                    ></GaTextInput>
                    {getProccessIsFailed ? (
                        <p
                            key="required"
                            className={`${cx(
                                'bodyRegularCap alert300 marginTop4'
                            )} `}
                        >
                            {t('ew.modalScan.connection.inputError')}
                        </p>
                    ) : null}
                </div>
            </div>
        </ScanModal>
    ) : isLoading ? (
        <ScanModalLoading
            title={
                needsConsent
                    ? 'ew.modalScan.loader.processingConsent'
                    : 'ew.modalScan.loader.sharingWithService'
            }
            description={'ew.modalScan.loader.processTakeMinutes'}
        />
    ) : // This will be useful in the future
    // <ScanModalConsentError
    //     continueFunction={functionForClose}
    //     missingCreds={credsData}
    // />

    isSuccess ? (
        <ScanModalSuccess
            title={
                !isConnection
                    ? 'ew.modalScan.success.requirementsShared'
                    : 'ew.modalScan.success.loginSuccess'
            }
            description={
                !isConnection
                    ? t('ew.modalScan.success.requirementsSharedDesc', {
                          brand: brandLabels?.brand,
                      })
                    : receiverDataName
                    ? t('ew.modalScan.success.loginSuccessDesc', {
                          // TODO: Add real service here when BE is ready
                          service: receiverDataName,
                      })
                    : undefined
            }
            secondDescription={
                isConnection
                    ? t('ew.modalScan.success.loginSuccessDesc2')
                    : undefined
            }
            alertText={
                !isConnection
                    ? 'ew.modalScan.success.requirementsSharedAlert'
                    : 'ew.modalScan.success.requirementsAuthAndShared'
            }
            continueFunction={hideModal}
        />
    ) : sendDataIsFailed ? (
        <UnknowConsentError
            title={'ew.modalScan.error.consentUnknowErrorTitle'}
            description={'ew.modalScan.error.consentUnknowErrorDesc'}
            continueFunction={() => {
                dispatch(clearModalState()), setScanCurrentStep(scanSteps[0])
            }}
        />
    ) : null
}

export default ConnectionForm
