import { Elements } from '@stripe/react-stripe-js'
import { PaymentMethod, loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { yearMonths } from '../../../../../assets/globalConstants'
import StatusModal from '../../../../../Components/atoms/formFields/stripeCardInput/statusModal/StatusModal'
import { StripeCardForm } from '../../../../../Components/atoms/formFields/stripeCardInput/StripeCardForm'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import ErrorPanel from '../../../../../Components/molecules/notification/panels/error/ErrorPanel'
import { isValidEmail } from '../../../../../utils/validations/functions'
import { cardStatuses } from '../../../licenses/licenses.constants'
import { sendPaymentMethodAction } from '../../../licenses/licensesActions'
import {
    getClientSecret,
    getClientSecretError,
} from '../../../licenses/licensesSelectors'
import {
    hideScreenLoaderAction,
    showScreenLoaderAction,
} from '../../../Loader/loaderActions'
import { setSelectedRoute } from '../../../PanelScafolding/scafoldingActions'
import ErrorClientSecret from '../errorClientSecret/ErrorClientSecret'
import styles from './billing.module.scss'
import { onPremise } from '../../../../../data/globalVar'
import cx from 'classnames'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { licensesSelectors } from '../../../licenses'
import { sessionSelectors } from '../../../session'
import { orgSelectors } from '../..'
import { getOrgAction } from '../../organizationActions'

type IBillingProps = {}

const Billing: React.FC<IBillingProps> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const strpKey = process.env.REACT_APP_STRP_KEY
    const stripePromise = strpKey && loadStripe(strpKey)

    const [errorClientSecret, setErrorClientSecret] = useState(false)
    const [showStatusModal, setStatusModal] = useState(false)

    const sessionOrgId = useSelector(sessionSelectors.getUserOrganization)
    const org = useSelector(orgSelectors.getOrg)
    const clientSecret = useSelector(getClientSecret)
    const clientSecretErrorStatus = useSelector(getClientSecretError)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const subscriptionPaymentMethod = subscriptionInfo?.paymentMethod
    const sendingPaymentMethod = useSelector(
        licensesSelectors.isSendingPaymentMethod
    )
    useEffect(() => {
        setStatusModal(false)
    }, [])

    useEffect(() => {
        dispatch(hideScreenLoaderAction())
        setStatusModal(false)
    }, [clientSecret])

    useEffect(() => {
        if (clientSecretErrorStatus && clientSecretErrorStatus === 422) {
            setErrorClientSecret(true)
        } else {
            setErrorClientSecret(false)
        }
    }, [clientSecretErrorStatus])

    const hideStatusModal = () => {
        setStatusModal(false)
    }

    const hideClientSecretError = () => {
        setErrorClientSecret(false),
            dispatch(setSelectedRoute('settings')),
            history.push('settings')
    }

    const currentYear = new Date().getFullYear()
    const yearsOptions = () => {
        const start = currentYear
        const stop = currentYear + 20
        const step = +1
        return Array.from(
            { length: (stop - start) / step + 1 },
            (_, i) => start + i * step
        )
    }

    const cardDetails = {
        id: subscriptionPaymentMethod?.id || '',
        holderName: subscriptionPaymentMethod?.holderName || '',
        creditCardNumber: subscriptionPaymentMethod?.creditCardNumber || '',
        expirationMonth:
            subscriptionPaymentMethod?.expiration?.split('/')[0] ||
            yearMonths[0]?.value,
        expirationYear:
            subscriptionPaymentMethod?.expiration?.slice(-4) ||
            yearsOptions()[0].toString(),
        ccv: subscriptionPaymentMethod?.ccvCheck || '',
        email: subscriptionPaymentMethod?.email || '',
    }

    const [cardFormActive, setCardFormActive] = useState(
        !cardDetails?.id || !subscriptionPaymentMethod?.email?.trim()?.length
    )

    const [cardForm, setCardForm] = useState(cardDetails)
    const [loadingScreen, setLoadingScreen] = useState(false)

    useEffect(() => {}, [sendingPaymentMethod, subscriptionInfo])

    function submitData(paymentMethod) {
        dispatch(showScreenLoaderAction())
        !onPremise &&
            dispatch(
                sendPaymentMethodAction({
                    id: paymentMethod,
                    email: cardForm?.email,
                })
            )
        setStatusModal(true)
        setCardFormActive(false)
    }

    const resetFormData = () => {
        setCardFormActive(false),
            setCardForm({
                ...cardForm,
                email: cardDetails?.email,
            })
    }

    return (
        <React.Fragment>
            <ScreenHeader
                title={'billing.title'}
                subText={t('billing.description')}
                hideBorderBottom={true}
            />
            {/* Commented because this will be util when there's an API to know the quantity of features used */}
            {/* <LightPanel className={'margin-top margin-bottom-m'}>
                <div className={styles.header}>
                    <p className={styles.header__title}>
                        {t(licensePlanNames[currentLicenseType?.toLowerCase()])}
                    </p>
                    <LightButton
                        className={styles.header__button}
                        text={'billing.upgrade'}
                        disabled={false}
                        functionality={navigateSubscription}
                    />
                </div>
                <PercentageBar
                    completed={11}
                    total={20}
                    label={'billing.usage'}
                />
            </LightPanel> */}
            <div className={styles.details}>
                <LightPanel className={styles.details__content}>
                    <div className={styles.header}>
                        <p
                            className={cx(
                                'buttonMD neutral1000',
                                styles.header__title
                            )}
                        >
                            {t('billing.cardDetails')}
                        </p>
                        {!cardFormActive ? (
                            <DarkButton
                                className={styles.details__button}
                                text={'public.change'}
                                disabled={false}
                                functionality={() => {
                                    setCardFormActive(true)
                                }}
                            />
                        ) : null}
                    </div>

                    {!cardFormActive ? (
                        <>
                            <p className={cx('bodyRegularMD neutral700')}>
                                {t('billing.cardEnding', {
                                    number:
                                        subscriptionPaymentMethod?.creditCardNumber?.slice(
                                            -4
                                        ) || '-',
                                })}
                            </p>
                            <Description
                                className={cx('bodyRegularMD neutral700')}
                                text={'billing.expires'}
                                extraTranslationParams={{
                                    date:
                                        subscriptionPaymentMethod?.expiration ||
                                        '-',
                                }}
                            />
                            {subscriptionPaymentMethod?.ccvCheck &&
                            subscriptionPaymentMethod?.ccvCheck.toLowerCase() ===
                                'fail' ? (
                                <ErrorPanel
                                    hasIcon
                                    hasTitle
                                    label={'subscription.reviewCardData'}
                                />
                            ) : null}
                        </>
                    ) : (
                        <>
                            <div className={styles.personalData}>
                                <GaTextInput
                                    className={styles.paymentEmail}
                                    id="email"
                                    labelText={t('billing.email')}
                                    required
                                    errorTexts={[t('public.invalidEmail')]}
                                    invalid={!isValidEmail(cardForm?.email)}
                                    name="email"
                                    onChange={(e) => {
                                        setCardForm({
                                            ...cardForm,
                                            email: e.target.value,
                                        })
                                    }}
                                    placeholder={t('billing.email')}
                                    value={cardForm?.email}
                                />
                            </div>
                            {stripePromise && (
                                <Elements stripe={stripePromise}>
                                    <StripeCardForm
                                        organizationCreated={
                                            !!(org?.id || sessionOrgId)
                                        }
                                        loadingScreen={loadingScreen}
                                        setLoader={setLoadingScreen}
                                        validForm={isValidEmail(
                                            cardForm?.email
                                        )}
                                        successAction={submitData}
                                        clientSecret={clientSecret}
                                        hideForm={resetFormData}
                                        statusPanel={
                                            !!(
                                                subscriptionPaymentMethod?.ccvCheck &&
                                                subscriptionPaymentMethod?.ccvCheck?.toLowerCase() !==
                                                    'pass'
                                            )
                                        }
                                        status={
                                            cardStatuses[
                                                subscriptionPaymentMethod?.ccvCheck?.toLowerCase() ||
                                                    'pass'
                                            ]
                                        }
                                    />
                                </Elements>
                            )}
                        </>
                    )}
                </LightPanel>
            </div>
            {errorClientSecret ? (
                <ErrorClientSecret
                    show={!!errorClientSecret}
                    hideModal={hideClientSecretError}
                />
            ) : null}
            {!!showStatusModal && !!subscriptionPaymentMethod?.ccvCheck ? (
                <StatusModal
                    show={!!showStatusModal}
                    hideModal={hideStatusModal}
                    status={
                        cardStatuses[
                            subscriptionPaymentMethod?.ccvCheck?.toLowerCase() ||
                                'pass'
                        ]
                    }
                />
            ) : null}
        </React.Fragment>
    )
}

Billing.defaultProps = {}

export default Billing
