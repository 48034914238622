import React from 'react'
import cx from 'classnames'

import IconDataCell from './elements/cells/iconDataCell/IconDataCell'
import ListDataCell from './elements/cells/listDataCell/ListDataCell'
import QuantityDataCell from './elements/cells/quantityDataCell/QuantityDataCell'
import CardHeader from './elements/cardHeader/CardHeader'
import CardLeftColumn from './elements/cardLeftColumn/CardLeftColumn'
import styles from './licensesTableMobile.module.scss'

import Tag from '../../../../../../../Components/atoms/tag/Tag'
import { InfoTogglesPricingModel } from '../../../../../../../interfaces/interfaces'
import {
    brandLabels,
    gaImages,
} from '../../../../../../../assets/globalConstants'
import { t } from 'i18next'

type ILicensesTableMobileProps = {
    license?: any
    licenseIndex: number
    switchPeriodValue: string
    infoToggles: InfoTogglesPricingModel
    selectLicense: (x: any) => void
    setPeriodChecked: (x) => void
}

const LicensesTableMobile: React.FC<ILicensesTableMobileProps> = (props) => {
    const {
        license,
        switchPeriodValue,
        licenseIndex,
        infoToggles,
        selectLicense,
        setPeriodChecked,
    } = props

    const getPrice = (item: any) => {
        return switchPeriodValue === 'year'
            ? item?.yearlyPrice
            : item?.monthlyPrice
    }

    return (
        <>
            <div className={styles?.tableContainer}>
                <div>
                    <div className={styles.periodToggle}>
                        <input
                            checked={switchPeriodValue === 'year'}
                            className={styles.periodCheckbox}
                            onChange={() => {}}
                            type="checkbox"
                            id="switch"
                        />
                        <label
                            onClick={(e) =>
                                setPeriodChecked(
                                    switchPeriodValue === 'month'
                                        ? 'year'
                                        : 'month'
                                )
                            }
                            className={styles.periodCheckboxLabel}
                        ></label>
                        <span className={cx('buttonMD primary700')}>
                            {t('subscription.payYearly')}
                        </span>
                    </div>
                    <select
                        className={`${styles?.selector} ${cx('bodyRegularMD')}`}
                        defaultValue={licenseIndex || 0}
                        onChange={(event) => {
                            selectLicense(event?.target?.value)
                        }}
                    >
                        <option
                            defaultChecked={licenseIndex === 0}
                            className={`${cx('bodyRegularMD neutral1000')}`}
                            value={0}
                        >
                            Starter
                        </option>
                        <option
                            defaultChecked={licenseIndex === 1}
                            className={`${cx('bodyRegularMD neutral1000')}`}
                            value={1}
                        >
                            Profesional
                        </option>
                        <option
                            defaultChecked={licenseIndex === 2}
                            className={`${cx('bodyRegularMD neutral1000')}`}
                            value={2}
                        >
                            Essential
                        </option>
                        <option
                            defaultChecked={licenseIndex === 3}
                            className={`${cx('bodyRegularMD neutral1000')}`}
                            value={3}
                        >
                            Enterprise
                        </option>
                    </select>
                    <div className={styles?.header__container}>
                        <div>
                            {!license?.name
                                ?.toLowerCase()
                                ?.includes('enterprise') ? (
                                <>
                                    <p
                                        className={`${cx(
                                            ' bodyRegularXL neutral700'
                                        )}`}
                                    >
                                        <span
                                            className={`${cx(
                                                'heading4 neutral1000'
                                            )}`}
                                        >
                                            {getPrice(license)}€
                                        </span>
                                        {' /'}
                                        &nbsp;
                                        {t('subscription.' + switchPeriodValue)}
                                    </p>
                                    {switchPeriodValue === 'month'
                                        ? license?.subPriceMonthLabel && (
                                              <span
                                                  className={`${cx(
                                                      'neutral700 bodyRegularXS marginTop2'
                                                  )}`}
                                              >
                                                  {t(
                                                      license?.subPriceMonthLabel
                                                  )}
                                              </span>
                                          )
                                        : license?.subPriceYearLabel && (
                                              <span
                                                  className={`${cx(
                                                      'neutral700 bodyRegularXS marginTop2'
                                                  )}`}
                                              >
                                                  {t(
                                                      license?.subPriceYearLabel
                                                  )}
                                              </span>
                                          )}
                                </>
                            ) : (
                                <>
                                    <p
                                        className={`${cx(
                                            'heading5 neutral1000'
                                        )}`}
                                    >
                                        {getPrice(license)}
                                    </p>
                                </>
                            )}
                        </div>

                        <div>
                            {license?.type === 'Professional' && (
                                <Tag
                                    text={'Popular'}
                                    color="#00999E"
                                    className={styles?.popularTag}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {/* Verification features */}
                <div className={styles?.card}>
                    <CardHeader title={'subscription.features'} />

                    <div key={0} className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.verificationTemplates'}
                            information={t(infoToggles?.verificationTemplate)}
                        />
                        <QuantityDataCell
                            data={license?.features?.verificationTemplates}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.activeUsers'}
                            information={t(infoToggles?.activeUser)}
                        />
                        <QuantityDataCell
                            data={license?.features?.activeUsers}
                        />
                    </div>
                </div>

                {/* Issuance features */}
                <div className={styles?.card}>
                    <CardHeader title={'subscription.issuanceFeatures'} />

                    <div key={0} className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.issuanceTemplates'}
                            information={t(infoToggles?.issuanceTemplate)}
                        />
                        <QuantityDataCell
                            data={license?.features?.issuanceTemplates}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'Issued Credentials'}
                            information={t(infoToggles?.issuedCredential)}
                            extraTranslationParams={{
                                brand: brandLabels?.brand,
                            }}
                        />
                        <QuantityDataCell
                            data={license?.features?.issuedCredentials}
                        />
                    </div>
                </div>

                {/* Other features */}
                <div className={styles?.card}>
                    <CardHeader title={'subscription.otherFeatures'} />

                    <div key={0} className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.dids'} />
                        <QuantityDataCell data={license?.features?.dids} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.apiKeys'} />
                        <QuantityDataCell data={license?.features?.apiKeys} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.npNFactorMechanisms'}
                            information={t(
                                infoToggles?.securityFactorMechanism
                            )}
                        />
                        <ListDataCell data={license?.features?.nFactor} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.notificationsWithoutParams'}
                        />
                        <ListDataCell data={license?.features?.notifications} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.usageStatistics'} />
                        <IconDataCell data={license?.features?.stadistics} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.CredentialsW3Cformat'}
                        />
                        <IconDataCell
                            data={license?.features?.credentialsInW3CFormat}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.sandboxEnvironment'}
                            information={t(infoToggles?.sandboxEnvironment)}
                        />
                        <IconDataCell
                            data={license?.features?.sandBoxEnvironment}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.didPublic'}
                            information={t(infoToggles?.didInPublicCatalogue)}
                            extraTranslationParams={{
                                brand: brandLabels?.brand,
                            }}
                        />
                        <IconDataCell
                            data={license?.features?.didPublicCatalogue}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.SchemasPublicCatalogue'}
                            information={t(
                                infoToggles?.schemaInPublicCatalogue
                            )}
                            extraTranslationParams={{
                                brand: brandLabels?.brand,
                            }}
                        />
                        <IconDataCell
                            data={license?.features?.newSchemaPublicCatalogue}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.EBSIintegration'}
                            information={t(infoToggles?.ebsiIntegration)}
                        />
                        <IconDataCell
                            data={license?.features?.ebsiIntegration}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.customizationGDPRPurposes'}
                        />
                        <IconDataCell
                            data={license?.features?.customGDPRPurposes}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.customQr'} />
                        <IconDataCell data={license?.features?.customQR} />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.credentialsISO18013'}
                        />
                        <IconDataCell
                            data={license?.features?.credentialsInISO28013}
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={'subscription.integrationTrustFrameworks'}
                            information={t(
                                infoToggles?.integrationCustomFrameworks
                            )}
                        />
                        <IconDataCell
                            data={
                                license?.features
                                    ?.integrationCustomTrustFrameworks
                            }
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn
                            text={
                                'subscription.integrationCustomInfrastructure'
                            }
                            information={t(
                                infoToggles?.integrationCustomInfraestructure
                            )}
                        />
                        <IconDataCell
                            data={
                                license?.features
                                    ?.integrationCustomTrustInfraestructure
                            }
                        />
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.standardSupport'} />
                        <div className={`${cx('bodyBoldSM')}`}>
                            {typeof license?.features?.standardSupport ==
                            'boolean' ? (
                                <img
                                    className={styles?.icon}
                                    src={
                                        license?.features?.standardSupport
                                            ? gaImages.check?.src
                                            : gaImages.close?.src
                                    }
                                ></img>
                            ) : (
                                <p>
                                    ${' '}
                                    <span
                                        className={`${cx(
                                            'bodyRegularCap neutral700'
                                        )}`}
                                    >
                                        {t(license?.features?.standardSupport)}
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={styles?.card__row}>
                        <CardLeftColumn text={'subscription.premierSupport'} />
                        <div className={`${cx('bodyBoldSM')}`}>
                            {typeof license?.features?.premierSupport ==
                            'boolean' ? (
                                <img
                                    className={styles?.icon}
                                    src={
                                        license?.features?.premierSupport
                                            ? gaImages.check?.src
                                            : gaImages.close?.src
                                    }
                                ></img>
                            ) : (
                                <p>
                                    ${' '}
                                    <span
                                        className={`${cx(
                                            'bodyRegularCap neutral700'
                                        )}`}
                                    >
                                        {t(license?.features?.premierSupport)}
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LicensesTableMobile
