import cx from 'classnames'
import React from 'react'
import { IAppIntegrationModel } from '../../../../../../../interfaces/interfaces'
import styles from './appIntegrationsCells.module.scss'

// CELLS COMPONENTS
export type TableContentProps = {
    item: IAppIntegrationModel
    index?: number
    selectItem?: (x?) => void
    deleteItem?: (x, y?, z?) => void
}

export const AppIntegrationURLS: React.FC<TableContentProps> = (props) => {
    const { item } = props

    return (
        <td>
            <div className={styles.appIntegrationURLS}>
                <span className={cx('buttonSM')}>
                    {item?.redirect_uris?.slice(0, 2)?.join(', ')}
                </span>

                {item?.redirect_uris?.length > 2 ? (
                    <div className={styles.moreUrls}>
                        <p className={cx('neutral600')}>
                            {`+ ${item?.redirect_uris?.length - 2}`}
                        </p>
                    </div>
                ) : null}
            </div>
        </td>
    )
}
