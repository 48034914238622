import * as React from 'react'
import cx from 'classnames'
import { t } from 'i18next'

export type IListDataCellProps = {
    data: any
}

const ListDataCell: React.FC<IListDataCellProps> = (props) => {
    const { data } = props

    const isLastItem = (itemsArray: any[], item: any) => {
        const arrLength = itemsArray?.length
        const itemPosition = itemsArray?.indexOf(item)
        return itemPosition === arrLength - 1
    }

    return (
        <div className={`${cx('bodyBoldSM neutral1000')}`}>
            {data?.length ? (
                data?.map((el: string, index: number) => (
                    <p
                        key={'dataCM__' + index}
                        className={`${cx('bodyBoldSM neutral1000')}`}
                    >
                        {t(el)}
                        {!isLastItem(data, el) ? ',' : ''}
                    </p>
                ))
            ) : (
                <p className={`${cx('bodyBoldSM neutral1000')}`}>
                    {t('subscription.none')}
                </p>
            )}
        </div>
    )
}

export default ListDataCell
