import cx from 'classnames'
import React from 'react'
import styles from './noConnectionsPanel.module.scss'
import { gaImages } from '../../../../../assets/globalConstants'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import GaImage from '../../../../../interfaces/Image/Image'
import { ButtonSizes } from '../../../../../data/globalVar'
import { t } from 'i18next'

type INoConnectionsPanelProps = {
    showScanModal: (x?: any) => void
}

const NoConnectionsPanel: React.FC<INoConnectionsPanelProps> = (props) => {
    const { showScanModal } = props

    return (
        <div className={styles.noConnectionsPanel}>
            <div className={styles.noConnectionsPanel__content}>
                <GaImage image={gaImages.folderFindEmpty} />
                <div className={styles.noConnectionsPanel__content__body}>
                    <p className={cx('heading5 neutral1000 marginAuto')}>
                        {t('ew.connections.noConnectionsYet')}
                    </p>
                    <p className={cx('bodyRegularMD neutral700 marginAuto')}>
                        {t('ew.connections.readyToStart')}
                    </p>
                </div>
                <div className={styles.noConnectionsPanel__content__footer}>
                    <DarkButton
                        text={t('ew.connections.connect')}
                        disabled={false}
                        size={ButtonSizes.SM}
                        functionality={showScanModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default NoConnectionsPanel
