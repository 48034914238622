import angleForward from '../../../../assets/images/angle-forward.svg'
import {
    ButtonModel,
    ctaLinkModel,
    LinkModel,
} from '../../../../interfaces/interfaces'

// PanelScafolding params
export const loginLink: LinkModel = {
    label: '',
    route: 'login',
}

// FormPanel params
export const angleForwardIcon = angleForward
export const formLegend = 'forgetPassword.steps'
export const goBackLink: ctaLinkModel = {
    label: 'public.cancel',
    route: '/email-login',
}
export const sendEmailButton: ButtonModel = {
    label: 'public.send',
}
