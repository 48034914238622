import { IAppIntegrationModel, IAppIntScope } from '../../interfaces/interfaces'

import {
    ClearAppIntegrationsList,
    ConfigAppIntegrationActionsTypes,
    AppIntegrationsListActions,
    AppIntegrationsListActionsTypes,
    IClearActionStatus,
    IClearAppIntegration,
    IDeleteAppIntegration,
    IDeleteAppIntegrationFailed,
    IDeleteAppIntegrationSuccess,
    IGetAppIntegrationsFailed,
    IGetAppIntegrationsSuccessAction,
    IRequestSecretAction,
    IRequestSecretFailedAction,
    IRequestSecretSuccessAction,
    ISelectAppIntegrationAction,
    IUpdateAppIntegrationAction,
    IUpdateAppIntegrationFailedAction,
    IUpdateAppIntegrationSuccessAction,
    IGetScopesSuccessAction,
    IGetScopesFailed,
    IGetScopesAction,
    ICreateAppIntegrationAction,
} from './appIntegrationsTypes'

// LIST
export const getAppIntegrations = (): AppIntegrationsListActions => {
    return {
        type: AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS,
    }
}

export const getAppIntegrationsSuccess = (
    appIntegrations: IAppIntegrationModel[]
): IGetAppIntegrationsSuccessAction => {
    return {
        type: AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS_SUCCESS,
        payload: {
            appIntegrations,
        },
    }
}

export const getAppIntegrationsFailed = (
    error: string
): IGetAppIntegrationsFailed => {
    return {
        type: AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const getScopes = (): IGetScopesAction => {
    return {
        type: AppIntegrationsListActionsTypes.GET_SCOPES,
    }
}

export const getScopesSuccess = (
    scopes: IAppIntScope[]
): IGetScopesSuccessAction => {
    return {
        type: AppIntegrationsListActionsTypes.GET_SCOPES_SUCCESS,
        payload: {
            scopes,
        },
    }
}

export const getScopesFailed = (error: string): IGetScopesFailed => {
    return {
        type: AppIntegrationsListActionsTypes.GET_SCOPES_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearAppIntegrationsList = (): ClearAppIntegrationsList => {
    return {
        type: AppIntegrationsListActionsTypes.CLEAR_APP_INTEGRATIONS_LIST,
    }
}

// FORM STEP
export const clearAppIntegrations = (): IClearAppIntegration => {
    return {
        type: ConfigAppIntegrationActionsTypes.CLEAR_CURRENT_APP_INTEGRATION,
    }
}

export const selectAppIntegration = (
    appIntegration: IAppIntegrationModel
): ISelectAppIntegrationAction => {
    return {
        type: ConfigAppIntegrationActionsTypes.SELECT_APP_INTEGRATION,
        payload: {
            appIntegration,
        },
    }
}

// CONFIG APP INTEGRATION
export const getSecret = (
    appInt: IAppIntegrationModel
): IRequestSecretAction => {
    return {
        type: ConfigAppIntegrationActionsTypes.GET_SECRET,
        payload: {
            appInt,
        },
    }
}

export const getSecretSuccess = (
    appInt: IAppIntegrationModel
): IRequestSecretSuccessAction => {
    return {
        type: ConfigAppIntegrationActionsTypes.GET_SECRET_SUCCESS,
        payload: {
            appInt,
        },
    }
}

export const getSecretFailed = (error: string): IRequestSecretFailedAction => {
    return {
        type: ConfigAppIntegrationActionsTypes.GET_SECRET_FAILED,
        payload: {
            error: error,
        },
    }
}

// UPDATE
export const updateAppIntegration = (
    appIntegration: IAppIntegrationModel
): IUpdateAppIntegrationAction => {
    return {
        type: ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION,
        payload: {
            appIntegration,
        },
    }
}

export const updateAppIntegrationSuccess =
    (): IUpdateAppIntegrationSuccessAction => {
        return {
            type: ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION_SUCCESS,
        }
    }

export const updateAppIntegrationFailed = (
    err: any
): IUpdateAppIntegrationFailedAction => {
    return {
        type: ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION_FAILED,
        payload: {
            error: err,
        },
    }
}

// CREATE
export const createAppIntegration = (
    appIntegration: IAppIntegrationModel
): ICreateAppIntegrationAction => {
    return {
        type: ConfigAppIntegrationActionsTypes.CREATE_APP_INTEGRATION,
        payload: {
            appIntegration,
        },
    }
}

export const clearActionStatus = (): IClearActionStatus => {
    return {
        type: ConfigAppIntegrationActionsTypes.CLEAR_ACTION_STATUS,
    }
}

// Delete App Integration
export const deleteAppIntegration = (id: string): IDeleteAppIntegration => {
    return {
        type: AppIntegrationsListActionsTypes.DELETE_APP_INTEGRATION,
        payload: {
            id: id,
        },
    }
}

export const deleteAppIntegrationSuccess = (): IDeleteAppIntegrationSuccess => {
    return {
        type: AppIntegrationsListActionsTypes.DELETE_APP_INTEGRATION_SUCCESS,
    }
}

export const deleteAppIntegrationFailed = (
    error: string
): IDeleteAppIntegrationFailed => {
    return {
        type: AppIntegrationsListActionsTypes.DELETE_APP_INTEGRATION_FAILED,
        payload: {
            error: error,
        },
    }
}
