import React from 'react'

type EditIconProps = {
    size?: number
    color?: string
    className?: string
    onClick?: (x?: any) => void
}

const EditIcon: React.FC<EditIconProps> = React.memo((props) => {
    const { size, color, className, onClick } = props

    return (
        <svg
            onClick={onClick}
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.6665 5.33331H5.33317C4.62593 5.33331 3.94765 5.61426 3.44755 6.11436C2.94746 6.61446 2.6665 7.29274 2.6665 7.99998V26.6666C2.6665 27.3739 2.94746 28.0522 3.44755 28.5523C3.94765 29.0524 4.62593 29.3333 5.33317 29.3333H23.9998C24.7071 29.3333 25.3854 29.0524 25.8855 28.5523C26.3856 28.0522 26.6665 27.3739 26.6665 26.6666V17.3333"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.6665 3.33331C25.1969 2.80288 25.9164 2.50488 26.6665 2.50488C27.4166 2.50488 28.1361 2.80288 28.6665 3.33331C29.1969 3.86374 29.4949 4.58316 29.4949 5.33331C29.4949 6.08346 29.1969 6.80288 28.6665 7.33331L15.9998 20L10.6665 21.3333L11.9998 16L24.6665 3.33331Z"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})

export default EditIcon
