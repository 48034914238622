import React, { useState } from 'react'
import cx from 'classnames'
import styles from './gaHoverTooltip.module.scss'
import InfoIcon from '../../../../assets/iconsLibrary/simple/info/infoIcon'
import InfoCircleFilledIcon from '../../../../assets/iconsLibrary/simple/info/filled/ga-info-circle-filled'
import {
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../data/globalVar'
import { gaColors } from '../../../../assets/globalConstants'
import ArrowLeftDiagonalCircleIcon from '../../../../assets/iconsLibrary/arrowLeftDiagonalCircle/arrowLeftDiagonalCircleIcon'

type IGaHoverTooltipProps = {
    label?: React.ReactNode | string
    info: string
    position?: TooltipPosition
    positionArrowDirection?: TooltipArrowPosition
    noArrow?: boolean
    hideIcon?: boolean
    className?: string
    delay?: number
    warning?: boolean
    tooltipIconColor?: string
    upgradeNeeded?: boolean
}

const GaHoverTooltip: React.FC<IGaHoverTooltipProps> = (props) => {
    const {
        label,
        info,
        position,
        positionArrowDirection,
        noArrow,
        hideIcon,
        className,
        delay,
        warning,
        tooltipIconColor,
        upgradeNeeded,
    } = props

    let timeout

    const [hover, setHover] = useState(false)

    const showTip = () => {
        timeout = setTimeout(() => {
            setHover(true)
        }, delay || 200)
        document
            ?.getElementById('rightNavList')
            ?.classList.add(styles.removeScrollBar)
    }

    const hideTip = () => {
        clearInterval(timeout)
        setHover(false)
        document
            ?.getElementById('rightNavList')
            ?.classList.remove(styles.removeScrollBar)
    }

    const getTooltipIcon = () => {
        return warning ? (
            <InfoCircleFilledIcon
                colorFill={gaColors.tertiary600}
                colorStroke={gaColors.neutral100}
                size={16}
            />
        ) : upgradeNeeded ? (
            <ArrowLeftDiagonalCircleIcon
                style={'filled'}
                color={tooltipIconColor || gaColors.secondary600}
                size={16}
                className={styles.upgradeIcon}
            />
        ) : (
            <InfoIcon
                color={tooltipIconColor || gaColors.neutral700}
                size={16}
            />
        )
    }

    const getTooltipPositionClass = () => {
        let positionClass = styles.positionTop

        if (position) {
            switch (position) {
                case 'right':
                    positionClass = styles.positionRight
                    break

                case 'bottom':
                    positionClass = styles.positionBottom
                    break
                case 'left':
                    positionClass = styles.positionLeft
                    break
                default:
                    positionClass = styles.positionTop
            }
        }

        return positionClass
    }

    const getTooltipArrowDirection = () => {
        let arrowDirectionClass = ''

        if (positionArrowDirection) {
            switch (positionArrowDirection) {
                case 'right':
                    arrowDirectionClass = styles.directionRight
                    break
                case 'left':
                    arrowDirectionClass = styles.directionLeft
                    break
                default:
                    arrowDirectionClass = ''
            }
        }

        return arrowDirectionClass
    }

    const ToolTipIcon = getTooltipIcon()
    const positionClass = getTooltipPositionClass()
    const arrowDirectionClass = getTooltipArrowDirection()

    return (
        <div
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
            className={`${styles.gaTooltip__container} ${
                className ? className : ''
            }`}
        >
            {!hideIcon ? (
                <div className={`${styles.iconTextContainer}`}>
                    {label}
                    <div className={`${styles.iconTooltipContainer}`}>
                        {ToolTipIcon}
                        {hover && (
                            <div
                                className={`${
                                    styles.gaTooltip__info
                                } ${positionClass} ${arrowDirectionClass} ${
                                    noArrow ? styles.noArrow : ''
                                } ${cx('bodyRegularXS neutral100')}`}
                            >
                                {info}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <div className={`${styles.textTooltipContainer}`}>
                        {label}
                        {hover && (
                            <div
                                className={`${
                                    styles.gaTooltip__info
                                } ${positionClass} ${arrowDirectionClass} ${
                                    noArrow ? styles.noArrow : ''
                                } ${cx('bodyRegularXS neutral100')}`}
                            >
                                {info}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default GaHoverTooltip
