import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    CredentialIcons,
    gaColors,
    gaImages,
    purposesTypesList,
} from '../../../../assets/globalConstants'
import { initialLang, translateDate } from '../../../../i18n'
import style from './consentCredentialCard.module.scss'
import GaImage from '../../../atoms/Image/Image'
import CheckIcon from '../../../../assets/iconsLibrary/simple/check/checkIcon'
import GaCheckbox from '../../../atoms/formFields/checkbox/GaCheckbox'
import { CredentialCard } from '../../../../features/wallet/requestCredentials/data/requestCredentials.interfaces'
import LightButton from '../../../atoms/buttons/LightButton/LightButton'
import { ButtonSizes } from '../../../../data/globalVar'
import {
    getImageURI,
    isDate,
    isImage,
} from '../../../../utils/credentialsUtils'

type IConsentCredentialCardProps = {
    credentials: CredentialCard[]
    purpose?: string
    trustLevel?: number
    mandatory?: boolean
    index?: number
    isAccepted: boolean
    selectedCredIndex: number
    setSelectedIndexForMultipleCreds: (x?: any) => void
    setImageToShowURI: (x?: string) => void
    setCredentialsToSelect: ({ groupIndex, selectableCreds }) => void
}

const ConsentCredentialCard: React.FC<IConsentCredentialCardProps> = (
    props
) => {
    const {
        credentials,
        purpose,
        trustLevel,
        mandatory,
        index,
        isAccepted,
        selectedCredIndex,
        setSelectedIndexForMultipleCreds,
        setImageToShowURI,
        setCredentialsToSelect,
    } = props

    const { t } = useTranslation()

    // Constants
    const credentialsFilteredByTrustLevel = credentials?.filter((el) =>
        !!trustLevel ? el?.securityLevel === 1 : true
    )

    const credential =
        credentialsFilteredByTrustLevel?.length &&
        credentialsFilteredByTrustLevel[selectedCredIndex]?.vcs?.length
            ? credentialsFilteredByTrustLevel[selectedCredIndex]
            : undefined

    const credPurposeText =
        purpose && purposesTypesList?.find((el) => el?.id === purpose)?.text

    const credName = !!credential?.groupName
        ? credential?.groupName[initialLang] || credential?.groupName['en']
        : ''

    const imageURI = getImageURI(credential)

    return credential ? (
        <div className={`${style.consentCredentialCard} `}>
            <div className={style.consentCredentialCard__header}>
                <div className={cx(style.consentCredentialCard__header__left)}>
                    <GaImage
                        className={
                            style.consentCredentialCard__header__left__icon
                        }
                        image={{
                            src:
                                CredentialIcons[
                                    credential?.groupId
                                        ? credential?.groupId
                                        : 'credential'
                                ] || CredentialIcons?.credential,
                            alt: t(gaImages.credential.alt),
                        }}
                    />{' '}
                    <p
                        className={cx(
                            'buttonMD neutral1000',
                            style.textSetByWidth
                        )}
                    >
                        {(credName && credName) ||
                            t('ew.requestCredentials.credential')}
                        {credName && mandatory ? (
                            <span className={cx('buttonMD alert300')}> *</span>
                        ) : null}
                    </p>
                </div>

                <div className={cx(style.consentCredentialCard__header__right)}>
                    {mandatory ? (
                        <CheckIcon color={gaColors.success300} size={24} />
                    ) : (
                        <GaCheckbox
                            index={'0'}
                            name="accept_required"
                            checked={isAccepted}
                            onChange={() => {
                                setSelectedIndexForMultipleCreds(!isAccepted)
                            }}
                            className={cx('marginRight8')}
                            id="accept_required"
                        ></GaCheckbox>
                    )}
                </div>
            </div>

            <div className={style.mockedValue}>
                {isImage(credential) ? (
                    <div className={style.photoContainer}>
                        <img
                            className={style.photoContainer__image}
                            src={imageURI}
                        />
                        <p
                            onClick={() => setImageToShowURI(imageURI)}
                            className={cx('buttonSM primary700')}
                        >
                            {t('ew.modalScan.serviceRequirements.viewPhoto')}
                        </p>
                    </div>
                ) : isDate(credential) ? (
                    <p className={cx('bodyRegularXS primary700')}>
                        {credential?.mainClaim &&
                            translateDate(credential?.mainClaim)}
                    </p>
                ) : (
                    <p className={cx('bodyBoldXL primary700')}>
                        {credential?.mainClaim}
                    </p>
                )}
            </div>
            {credPurposeText?.length ||
            credentialsFilteredByTrustLevel?.length > 1 ? (
                <div className={style.bottomContainer}>
                    {credPurposeText?.length ? (
                        <div className={style.purpose}>
                            <p className={cx('bodyRegularSM neutral700')}>
                                {t(credPurposeText)}
                            </p>
                        </div>
                    ) : null}
                    {credentialsFilteredByTrustLevel?.length > 1 ? (
                        <LightButton
                            className={style.stripeForm__buttons__item}
                            text={'public.change'}
                            disabled={false}
                            size={ButtonSizes.SM}
                            functionality={() =>
                                setCredentialsToSelect({
                                    groupIndex: index,
                                    selectableCreds:
                                        credentialsFilteredByTrustLevel,
                                })
                            }
                        />
                    ) : null}
                </div>
            ) : null}
        </div>
    ) : null
}

export default ConsentCredentialCard
