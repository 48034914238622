import React from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './unknowConsentError.module.scss'
import ScanModal from '../../../ScanModal'
import GaImage from '../../../../../../atoms/Image/Image'
import { gaImages } from '../../../../../../../assets/globalConstants'

type IUnknowConsentError = {
    title: string
    description: string
    secondDescription?: string
    continueFunction: () => void
}

const UnknowConsentError: React.FC<IUnknowConsentError> = (props) => {
    const { title, description, secondDescription, continueFunction } = props

    return (
        <ScanModal
            hideModal={() => {}}
            continueButton={{
                label: t('ew.modalScan.error.tryAgain'),
                disabled: false,
                function: continueFunction,
            }}
        >
            <div className={styles.unknowError}>
                <GaImage image={gaImages.yellowWarningSvg} />
                <p className={cx('heading6 marginTop20 ')}>{t(title)}</p>
                <p className={cx('bodyRegularMD neutral800 marginTop8')}>
                    {t(description)}
                </p>
                {secondDescription ? (
                    <p className={cx('bodyRegularMD neutral800')}>
                        {t(secondDescription)}
                    </p>
                ) : null}
            </div>
        </ScanModal>
    )
}

export default UnknowConsentError
