import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    ICredentialsActivityModel,
    ICredentialsItemModel,
    IIssuancePaginatedResponseModel,
    StepModel,
} from '../../../interfaces/interfaces'

// LIST
export interface IGetCredentialsAction {
    type: CredentialListActionsTypes.GET_CREDENTIALS
    payload: {
        page: number
        size: number
        url?: string
    }
}

export interface IGetCredentialsSuccessAction {
    type: CredentialListActionsTypes.GET_CREDENTIALS_SUCCESS
    payload: IIssuancePaginatedResponseModel
}

export interface IGetCredentialsFailed {
    type: CredentialListActionsTypes.GET_CREDENTIALS_FAILED
    payload: {
        error: string
    }
}

export interface IGetPendingCredentialsAction {
    type: CredentialListActionsTypes.GET_PENDING_CREDENTIALS
    payload: {
        page: number
        size: number
        url?: string
    }
}

export interface IGetPendingCredentialsSuccessAction {
    type: CredentialListActionsTypes.GET_PENDING_CREDENTIALS_SUCCESS
    payload: IIssuancePaginatedResponseModel
}

export interface IGetPendingCredentialsFailed {
    type: CredentialListActionsTypes.GET_PENDING_CREDENTIALS_FAILED
    payload: {
        error: string
    }
}

export interface ISelectCredentialAction {
    type: CredentialListActionsTypes.SELECT_CREDENTIAL
    payload: {
        credential: ICredentialsActivityModel
    }
}

export interface IDeleteCredentialAction {
    type: CredentialListActionsTypes.DELETE_CREDENTIAL
    payload: {
        credential: ICredentialsItemModel
    }
}

export interface IDeleteCredentialSuccessAction {
    type: CredentialListActionsTypes.DELETE_CREDENTIAL_SUCCESS
}

export interface IDeleteCredentialFailed {
    type: CredentialListActionsTypes.DELETE_CREDENTIAL_FAILED
    payload: {
        error: string
    }
}

export interface IChangeCredentialStatusAction {
    type: CredentialListActionsTypes.CHANGE_CREDENTIAL_STATUS
    payload: {
        id: string
        status: string
        pageIndex: number
        pageSize: number
    }
}

export interface IIssueCredentialAction {
    type: CredentialListActionsTypes.ISSUE_CREDENTIAL
    payload: {
        id: string
        subjectDID: string
        credentials: any
        credType: string
        isMulti?: boolean
        expirationDate?: string
    }
}

export interface IIssueCredentialSuccessAction {
    type: CredentialListActionsTypes.ISSUE_CREDENTIAL_SUCCESS
}

export interface IIssueCredentialFailed {
    type: CredentialListActionsTypes.ISSUE_CREDENTIAL_FAILED
    payload: {
        error: string
    }
}

export interface IRejectCredentialAction {
    type: CredentialListActionsTypes.REJECT_CREDENTIAL
    payload: {
        id: string
    }
}

export interface IRejectCredentialSuccessAction {
    type: CredentialListActionsTypes.REJECT_CREDENTIAL_SUCCESS
}

export interface IRejectCredentialFailed {
    type: CredentialListActionsTypes.REJECT_CREDENTIAL_FAILED
    payload: {
        error: string
    }
}

export interface ClearCredentialList {
    type: CredentialListActionsTypes.CLEAR_CREDENTIAL_LIST
}

export type CredentialsListActions =
    | IGetCredentialsAction
    | IGetCredentialsSuccessAction
    | IGetCredentialsFailed
    | ISelectCredentialAction
    | IDeleteCredentialAction
    | IDeleteCredentialSuccessAction
    | IDeleteCredentialFailed
    | IGetPendingCredentialsAction
    | IGetPendingCredentialsSuccessAction
    | IGetPendingCredentialsFailed
    | IChangeCredentialStatusAction
    | IIssueCredentialAction
    | IIssueCredentialAction
    | IIssueCredentialSuccessAction
    | IIssueCredentialFailed
    | IRejectCredentialAction
    | IRejectCredentialSuccessAction
    | IRejectCredentialFailed
    | ClearCredentialList

export enum CredentialListActionsTypes {
    GET_CREDENTIALS = 'credentials/get',
    GET_CREDENTIALS_SUCCESS = 'credentials/get_success',
    GET_CREDENTIALS_FAILED = 'credentials/get_failed',
    SELECT_CREDENTIAL = 'credential/selectCredential',
    DELETE_CREDENTIAL = 'credential/deleteCredential',
    DELETE_CREDENTIAL_SUCCESS = 'credentials/delete_credential_success',
    DELETE_CREDENTIAL_FAILED = 'credentials/delete_credential_failed',
    GET_PENDING_CREDENTIALS = 'credentials/get_pending',
    GET_PENDING_CREDENTIALS_SUCCESS = 'credentials/get_pending_success',
    GET_PENDING_CREDENTIALS_FAILED = 'credentials/get_pending_failed',
    CHANGE_CREDENTIAL_STATUS = 'credentials/change_status',
    ISSUE_CREDENTIAL = 'requests/issue_credential',
    ISSUE_CREDENTIAL_SUCCESS = 'credentials/issue_credential_success',
    ISSUE_CREDENTIAL_FAILED = 'credentials/issue_credential_failed',
    REJECT_CREDENTIAL = 'requests/reject_credential',
    REJECT_CREDENTIAL_SUCCESS = 'credentials/reject_credential_success',
    REJECT_CREDENTIAL_FAILED = 'credentials/reject_credential_failed',
    CLEAR_CREDENTIAL_LIST = 'credentials/clear_list',
}

export interface ICredentialsListState {
    issuedCredentialsResponse?: IIssuancePaginatedResponseModel
    pendingCredentias?: IIssuancePaginatedResponseModel
    selectedCredential?: ICredentialsActivityModel
    loadingStatus: boolean
    issuingCredential: boolean
    error: string
}

// FORM
export interface IConfigCredentialFormState {
    steps: StepModel[] | null
    firstStep: StepModel | null
    step: StepModel | null
    finalStep: StepModel | null
}

export interface SetActiveStepAction {
    type: ConfigCredentialFormActionsTypes.SETSTEP
    payload: {
        step: StepModel
    }
}
export interface SetStepsAction {
    type: ConfigCredentialFormActionsTypes.SETSTEPS
    payload: {
        steps: StepModel[]
    }
}

export interface ClearCredentialFormState {
    type: ConfigCredentialFormActionsTypes.CLEAR_FORM_STEP
}

export type ConfigCredentialFormActions =
    | SetActiveStepAction
    | SetStepsAction
    | ClearCredentialFormState

export enum ConfigCredentialFormActionsTypes {
    SETSTEPS = 'credentials/setSteps',
    SETSTEP = 'credentials/setStep',
    CLEAR_FORM_STEP = 'credentials/clearFormSteps',
}

export interface ICredentialState {
    credential: ICredentialsItemModel
    token: string
    baseId: string
    status: ActionStatusTypes
    isKeypairLoading: boolean
    isSecretLoading: boolean
    error: string
}

export interface IEditCredential {
    type: ConfigCredentialActionsTypes.EDIT_CREDENTIAL
    payload: {
        credential: ICredentialsItemModel
        token: string
    }
}

export interface IUpdateCredentialAction {
    type: ConfigCredentialActionsTypes.UPDATE_CREDENTIAL
    payload: {
        credential: ICredentialsItemModel
        credId: string
    }
}

export interface IUpdateCredentialSuccessAction {
    type: ConfigCredentialActionsTypes.UPDATE_CREDENTIAL_SUCCESS
}

export interface IUpdateCredentialFailedAction {
    type: ConfigCredentialActionsTypes.UPDATE_CREDENTIAL_FAILED
    payload: {
        error: string
    }
}

export interface IClearCredential {
    type: ConfigCredentialActionsTypes.CLEAR_CURRENT_CREDENTIAL
}

export interface IClearActionStatus {
    type: ConfigCredentialActionsTypes.CLEAR_ACTION_STATUS
}

export type ConfigDidActions =
    | IEditCredential
    | IClearCredential
    | IUpdateCredentialAction
    | IUpdateCredentialSuccessAction
    | IUpdateCredentialFailedAction
    | IClearActionStatus

export enum ConfigCredentialActionsTypes {
    EDIT_CREDENTIAL = 'config/credential/editCredential',
    CLEAR_CURRENT_CREDENTIAL = 'config/credential/clearCredentialForm',
    CREATE_CREDENTIAL = 'config/credential/create',
    CREATE_CREDENTIAL_SUCCESS = 'config/credential/create_success',
    CREATE_CREDENTIAL_FAILED = 'config/credential/create_failed',
    UPDATE_CREDENTIAL = 'config/credential/update',
    UPDATE_CREDENTIAL_SUCCESS = 'config/credential/update_success',
    UPDATE_CREDENTIAL_FAILED = 'config/credential/create_failed',
    CLEAR_ACTION_STATUS = 'config/credential/clear_action_status',
}
