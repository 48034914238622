import * as React from 'react'
import cx from 'classnames'
import styles from './cardHeader.module.scss'
import { t } from 'i18next'

export type ICardHeaderProps = {
    title: any
}

export const CardHeader: React.FC<ICardHeaderProps> = (props) => {
    const { title } = props
    return (
        <div key={0} className={styles?.card__header}>
            <p className={`${cx('bodyBoldLG neutral1000')}`}>{t(title)}</p>
        </div>
    )
}

export default CardHeader
