import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import FormSection from '../../../../../Components/organisms/FormSection/FormSection'
import { ReactComponent as AngleDown } from '../../../../../assets/images/angle-down.svg'
import {
    ButtonModel,
    CertificateModel,
    IDIDModel,
    IIssuanceConfigItemModel,
} from '../../../../../interfaces/interfaces'
import { didsSelectors } from '../../../Dids'
import {
    getActiveStep,
    getAliasFromDid,
    getDidFromAlias,
} from '../../../Dids/didSelectors'
import { catalogSelectors } from '../../../../common/catalog'
import {
    getCertificatesList,
    getQSFromAlias,
    getQSFromID,
} from '../../../certificates/certificatesSelectors'
import { sessionSelectors } from '../../../../common/session'
import styles from './configurationForm.module.scss'
import TemplatesFormHeader from '../../../../common/TemplatesHeader/TemplatesFormHeader'
import ConfigurationCredentialPreview from '../../../../../Components/molecules/modals/CredentialPreview/components/StepsContentPreview/ConfigurationCredentialPreview'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import { issuanceSelectors } from '../..'
import { getLoadingStatus } from '../../../../common/catalog/catalogSelectors'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { getResponsive } from '../../../../../utils/formUtils'

type IConfigurationFormProps = {
    issuance: IIssuanceConfigItemModel
    canEditIssuances?: boolean
    creatingIssuance?: boolean
    buttonHeader?: ButtonModel
    infoApiKeyPanel?: boolean
    activeStep: any
    formSteps: any
    onHandleChange: (step) => void
    buttonFunctionHeader?: (x) => void
    buttonFunction: (x) => void
    setValidForm: (x) => void
}

const ConfigurationForm: React.FC<IConfigurationFormProps> = (props) => {
    const {
        issuance,
        creatingIssuance,
        canEditIssuances,
        buttonHeader,
        infoApiKeyPanel,
        activeStep,
        formSteps,
        buttonFunctionHeader,
        buttonFunction,
        onHandleChange,
        setValidForm,
    } = props
    const { t } = useTranslation()

    // Selectors
    const didOptions = useSelector(didsSelectors.getdidConfigsVms) || [
        { DidId: '', alias: '' } as IDIDModel,
    ]
    const certificatesOptions = useSelector(getCertificatesList)
    const isOnPremise = useSelector(sessionSelectors.isOnPremise)
    const catalogAuthority = useSelector(catalogSelectors.getCatalogAuthority)
    const updateStatus = useSelector(issuanceSelectors.getUpdateStatus)
    const step = useSelector(getActiveStep)
    const catalogLoadingStatus = useSelector(getLoadingStatus)
    const catalogIsLoading = !(
        catalogLoadingStatus?.status === 'finished' ||
        catalogLoadingStatus?.status === 'error'
    )

    const getDomain = (didId) => {
        const defaultHost = process.env['REACT_APP_CERTIFY_HOST']
        let domain = !(catalogAuthority?.length > 0)
            ? defaultHost
            : catalogAuthority?.filter((item) => {
                  return item.id?.split('#')[0] === didId?.split('#')[0]
              })[0]?.domain || defaultHost
        return domain
    }

    const changeFormData = issuance?.prConfig?.certificateId
        ? {
              id: issuance?.id || '',
              vmethodId: issuance?.prConfig?.vmethodId || '',
              domain:
                  issuance?.prConfig?.domain ||
                  (isOnPremise ? '' : getDomain(issuance?.prConfig?.vmethodId)),
              certificateId: issuance?.prConfig?.certificateId || '',
              eidasSignature: issuance?.eidasSignature || false,
          }
        : {
              id: issuance?.id || '',
              vmethodId: issuance?.prConfig?.vmethodId || '',
              domain:
                  issuance?.prConfig?.domain ||
                  (isOnPremise ? '' : getDomain(issuance?.prConfig?.vmethodId)),

              eidasSignature: issuance?.eidasSignature || false,
          }

    // States
    const [formDataModified, setFormDataModified] = useState(false)
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
    const [formData, setFormState] = useState({
        id: issuance?.id || '',
        vmethodId: issuance?.prConfig?.vmethodId,
        domain:
            issuance?.prConfig?.domain ||
            (isOnPremise ? '' : getDomain(issuance?.prConfig?.vmethodId)),
        certificateId: issuance?.prConfig?.certificateId,
        eidasSignature: issuance?.eidasSignature,
    })
    const { id, vmethodId, domain, certificateId, eidasSignature } = formData

    const validForm = creatingIssuance
        ? didOptions &&
          didOptions[0]?.DidId &&
          !!(id?.length && vmethodId?.length && domain?.trim()?.length)
        : !!(id?.length && vmethodId?.length && domain?.trim()?.length)

    // Effects
    useEffect(() => {
        scrollToTop()
    }, [step])

    useEffect(() => {
        setValidForm(validForm)
    }, [])

    useEffect(() => {}, [catalogAuthority])

    useEffect(() => {
        let formatedFilledData = {
            ...formData,
            id,
            vmethodId,
            domain,
            certificateId,
            eidasSignature,
        }

        if (
            JSON.stringify(formatedFilledData) ===
            JSON.stringify(changeFormData)
        ) {
            setFormDataModified(false)
        }
    }, [formData])

    useEffect(() => {
        if (updateStatus === ActionStatusTypes?.success) {
            setFormDataModified(false)
        }
    }, [updateStatus])

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    // Functions
    const handleInputChange = (event) => {
        const target = event?.target
        const value = target?.value
        const name = target.name
        const currentValue = changeFormData[name as keyof typeof changeFormData]

        if (currentValue !== value) {
            !formDataModified && setFormDataModified(true)
        }

        setFormState({
            ...formData,
            [name]: value,
        })
        setValidForm(validForm)
    }

    const setNewDidAndDomain = (did) => {
        let newDomain = getDomain(did)
        const currentDomain = changeFormData?.domain
        const currentDid = changeFormData?.vmethodId

        if (currentDomain !== newDomain || currentDid !== did) {
            !formDataModified && setFormDataModified(true)
        }

        setFormState({
            ...formData,
            vmethodId: did,
            domain: newDomain,
        })
    }

    const setQS = (certificateId) => {
        const currentDomain =
            changeFormData[certificateId as keyof typeof changeFormData]

        if (currentDomain !== certificateId) {
            !formDataModified && setFormDataModified(true)
        }
        setFormState({
            ...formData,
            certificateId: certificateId,
            eidasSignature: certificateId ? true : false,
        })
    }

    const onSubmit = (e) => {
        e.preventDefault(), saveAndNext()
    }

    const saveAndNext = () => {
        buttonFunction({
            ...issuance,
            id: id?.trim(),
            pollingMaxTime: 36000,
            prConfig: {
                ...issuance.prConfig,
                domain: domain,
                vmethodId: vmethodId,
                sessionTTL: 180,
                certificateId: certificateId,
            },
            eidasSignature: eidasSignature,
        })
        scrollToTop()
    }

    // Responsive preview

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    return (
        <>
            <div className={styles.leftColumn}>
                <div className={styles.headerStepper}>
                    <TemplatesFormHeader
                        title={
                            creatingIssuance
                                ? 'createIssuance.title'
                                : t('editIssuance.title', {
                                      id: issuance?.id || '',
                                  })
                        }
                        description={
                            creatingIssuance
                                ? 'createIssuance.description'
                                : 'editIssuance.description'
                        }
                        inactiveSteps={
                            !creatingIssuance &&
                            !issuance?.prConfig?.credentials?.length
                                ? [3, 4]
                                : undefined
                        }
                        buttonFunctionHeader={buttonFunctionHeader}
                        buttonHeader={buttonHeader}
                        formSteps={formSteps}
                        elementsAllignment={creatingIssuance ? false : true}
                        infoApiKeyPanel={infoApiKeyPanel}
                        titleInfoPanel={t('editIssuance.addApiKeyInfo')}
                        onHandleChange={onHandleChange}
                        activeStep={activeStep}
                        loadingProgressBar={catalogIsLoading}
                    />
                </div>
                <div className={styles.form}>
                    <LightPanel>
                        <ScreenHeader
                            title={'editIssuance.step1.sectionTitle'}
                            subText={'editIssuance.step1.sectionDescription'}
                            buttonFunction={showModal}
                            button={
                                getResponsive(1320)
                                    ? {
                                          label: 'general.showPreview',
                                          disabled: false,
                                      }
                                    : undefined
                            }
                            buttonIsLight={true}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            className={styles.formHeader}
                        />
                        <FormSection title="">
                            <form onSubmit={onSubmit}>
                                <GaTextInput
                                    className={cx('margin-bottom')}
                                    id={'app-id'}
                                    invalid={!id?.trim()?.length}
                                    labelText={t(
                                        'editIssuance.step1.issuanceIDLabel'
                                    )}
                                    required={true}
                                    name={'id'}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder={t(
                                        'editIssuance.step1.issuanceIDPlaceholder'
                                    )}
                                    value={id}
                                    readOnly={!creatingIssuance}
                                />

                                <GaSelect
                                    idSelect={'vmethodId'}
                                    className={cx('margin-bottom')}
                                    value={
                                        vmethodId
                                            ? getAliasFromDid(
                                                  didOptions,
                                                  vmethodId
                                              )
                                            : ''
                                    }
                                    invalid={!vmethodId?.trim()?.length}
                                    labelText={t(
                                        'editIssuance.step1.requesterDidLabel'
                                    )}
                                    placeholder={t(
                                        'editIssuance.step1.requesterDidPlaceholder'
                                    )}
                                    required
                                    name={'vmethodId'}
                                    permissions={{
                                        scopes: ['readDids', 'readTenants'],
                                        every: true,
                                    }}
                                    disabled={
                                        !canEditIssuances && !creatingIssuance
                                    }
                                    isLoading={
                                        !(didOptions && didOptions[0]?.DidId)
                                    }
                                    onChange={(e) => {
                                        let selected_vmid = getDidFromAlias(
                                            didOptions,
                                            e?.target?.value
                                        )

                                        setNewDidAndDomain(selected_vmid)
                                        setValidForm(
                                            didOptions && didOptions[0]?.DidId
                                                ? selected_vmid?.trim()?.length
                                                : false
                                        )
                                    }}
                                    options={didOptions.map(
                                        (did: IDIDModel) =>
                                            did?.alias || did?.DidId
                                    )}
                                />
                                {/*
                    <GaTextInput
                        className={cx('margin-bottom')}
                        id={'domain'}
                        invalid={!domain?.trim()?.length}
                        labelText={t('editIssuance.step1.domainLabel')}
                        required={true}
                        name={'domain'}
                        readOnly={!canEditIssuances && !creatingIssuance}
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                        placeholder={t('editIssuance.step1.domainPlaceholder')}
                        value={domain}
                    />
                    */}
                                <div className={cx(styles.advancedOptions)}>
                                    <p
                                        className={cx('buttonSM primary700')}
                                        onClick={() =>
                                            setShowAdvancedOptions(
                                                !showAdvancedOptions
                                            )
                                        }
                                    >
                                        {t('certificates.advancedOptions')}
                                        <AngleDown
                                            className={`${
                                                styles.advancedOptionsToogle
                                            } ${
                                                showAdvancedOptions &&
                                                styles.toogleOpen
                                            }`}
                                        />
                                    </p>

                                    {showAdvancedOptions ? (
                                        <GaSelect
                                            idSelect={'certificate'}
                                            className={cx('marginTop20')}
                                            value={
                                                certificateId
                                                    ? getQSFromID(
                                                          certificatesOptions ||
                                                              [],
                                                          certificateId
                                                      )
                                                    : undefined
                                            }
                                            labelText={t(
                                                'certificates.selectQSLabel'
                                            )}
                                            name={'certificate'}
                                            permissions={{
                                                scopes: [
                                                    'readDids',
                                                    'readTenants',
                                                ],
                                                every: true,
                                            }}
                                            disabled={
                                                !canEditIssuances &&
                                                !creatingIssuance
                                            }
                                            isLoading={
                                                !(
                                                    certificatesOptions &&
                                                    certificatesOptions[0]?.id
                                                )
                                            }
                                            onChange={(e) => {
                                                let selected_certificateId =
                                                    getQSFromAlias(
                                                        certificatesOptions ||
                                                            [],
                                                        e?.target?.value
                                                    )

                                                setQS(selected_certificateId)
                                                setValidForm(
                                                    certificatesOptions &&
                                                        certificatesOptions[0]
                                                            ?.id
                                                        ? selected_certificateId?.trim()
                                                              ?.length
                                                        : false
                                                )
                                            }}
                                            options={
                                                certificatesOptions
                                                    ? certificatesOptions?.map(
                                                          (
                                                              qs: CertificateModel
                                                          ) =>
                                                              qs?.alias ||
                                                              qs?.id
                                                      )
                                                    : []
                                            }
                                        />
                                    ) : null}
                                </div>
                            </form>
                        </FormSection>
                        {getResponsive(1320) ? (
                            <div className={styles.buttonContainer}>
                                <DarkButton
                                    text={
                                        canEditIssuances && !creatingIssuance
                                            ? 'public.save'
                                            : 'editIssuance.sectionButton'
                                    }
                                    disabled={
                                        !validForm ||
                                        (!creatingIssuance && !formDataModified)
                                    }
                                    functionality={saveAndNext}
                                />
                            </div>
                        ) : null}
                    </LightPanel>
                </div>
            </div>

            <ConfigurationCredentialPreview
                creatingTemplate={creatingIssuance}
                saveButton={{
                    label:
                        canEditIssuances && !creatingIssuance
                            ? 'public.save'
                            : 'editIssuance.sectionButton',
                    disabled:
                        !validForm || (!creatingIssuance && !formDataModified),
                    function: saveAndNext,
                }}
                issuanceTemplate={true}
                showSaveButton={canEditIssuances || creatingIssuance}
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
            />
        </>
    )
}

export default ConfigurationForm
