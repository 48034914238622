import { ActionStatusTypes } from '../../assets/globalConstants'
import {
    CredentialListActionsTypes,
    CredentialsListActions,
    ICredentialsListState,
} from './requestCredentialsTypes'

// LIST
const initialCredentialsListState: ICredentialsListState = {
    credentials: undefined,
    loadingStatus: false,
    deleteCredStatus: undefined,
    importCredStatus: undefined,
}

export function requestCredentialsReducer(
    state = initialCredentialsListState,
    action: CredentialsListActions
): ICredentialsListState {
    const deleteImportCredStatus =
        state?.importCredStatus !== ActionStatusTypes.success
    switch (action.type) {
        case CredentialListActionsTypes.GET_CREDENTIALS:
            return {
                ...state,
                loadingStatus: true,
                deleteCredStatus: undefined,
                importCredStatus: undefined,
                importedCredential: deleteImportCredStatus
                    ? undefined
                    : state.importedCredential,
            }
        case CredentialListActionsTypes.GET_CREDENTIALS_SUCCESS:
            return {
                ...state,
                credentials: action.payload?.data,
                loadingStatus: false,
            }
        case CredentialListActionsTypes.GET_CREDENTIALS_FAILED:
            return {
                ...state,
                credentials: undefined,
                loadingStatus: false,
            }
        case CredentialListActionsTypes.DELETE_CREDENTIAL:
            return {
                ...state,
                loadingStatus: true,
                deleteCredStatus: undefined,
            }
        case CredentialListActionsTypes.DELETE_CREDENTIAL_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                deleteCredStatus: ActionStatusTypes.success,
            }
        case CredentialListActionsTypes.DELETE_CREDENTIAL_FAILED:
            return {
                ...state,
                loadingStatus: false,
                deleteCredStatus: ActionStatusTypes.failed,
            }
        case CredentialListActionsTypes.IMPORT_CREDENTIAL:
            return {
                ...state,
                loadingStatus: true,
                importCredStatus: undefined,
                importedCredential: undefined,
            }
        case CredentialListActionsTypes.IMPORT_CREDENTIAL_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                importCredStatus: ActionStatusTypes.success,
                importedCredential: action?.payload?.credential,
            }
        case CredentialListActionsTypes.IMPORT_CREDENTIAL_FAILED:
            return {
                ...state,
                loadingStatus: false,
                importCredStatus: ActionStatusTypes.failed,
                importedCredential: undefined,
            }

        case CredentialListActionsTypes.CLEAR_CREDENTIAL_LIST:
            return initialCredentialsListState as ICredentialsListState
        default:
            return state
    }
}
