import React, { useState } from 'react' // we need this to make JSX compile
import styles from './credDeepLevelDetails.module.scss'
import cx from 'classnames'
import { ComplexCredentialConfig } from '../complexContent/ComplexContent'
import { plainValues } from '../../../../../../../../../utils/formatDataUtil'
import { t } from 'i18next'
import { CredContentDetailType } from '../../../../../../data/requestCredentials.interfaces'
import { gaImages } from '../../../../../../../../../assets/globalConstants'
import LightButton from '../../../../../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaTag from '../../../../../../../../../Components/atoms/tag/Tag'
import { ButtonSizes } from '../../../../../../../../../data/globalVar'
import WarningPanel from '../../../../../../../../../Components/molecules/notification/panels/warning/warningPanel'
import CredEmptyContent from '../credEmptyContent/CredEmptyContent'
import {
    containerStyle,
    getLevelContent,
    removeIdAndTitle,
} from '../../../../../../utils/complexCredUtil'
import GaModal from '../../../../../../../../../Components/molecules/modals/modal/modal'

type CredDeepLevelDetailsProps = {
    contents: any
    title?: string
    isDemoContent?: boolean
    setContentToShow: (x?: any) => void
}

const CredDeepLevelDetails: React.FC<CredDeepLevelDetailsProps> = (props) => {
    const { contents, title, isDemoContent, setContentToShow } = props

    const contentArr = new Array().concat([contents])
    const contentFiltered = removeIdAndTitle(contents)
    const hasPlainValues = plainValues(contentFiltered)

    const [vcState, setVcState] = useState<{
        level: number
        contents: CredContentDetailType[][]
        isTable?: boolean
        title?: string[]
        showLockedWarning?: boolean
    }>({
        level: 0,
        contents: contentArr,
        isTable:
            (!!hasPlainValues?.length && !!hasPlainValues[0]) ||
            contentFiltered?.length === 1,
        title: title ? [title] : undefined,
        showLockedWarning: false,
    })

    const currentContent = vcState?.contents[vcState.level]
    const previewLevelContent = vcState.contents[vcState?.level - 1]

    const goToMainDetailsAction = () => {
        const previewContentFiltered = removeIdAndTitle(previewLevelContent)
        const previousPlainValues = plainValues(previewContentFiltered)

        setVcState({
            ...vcState,
            level: vcState?.level - 1,
            isTable:
                (!!previousPlainValues && previousPlainValues[0]) ||
                previewContentFiltered?.length === 1,
        })
    }

    const showLockedContentWarning = () => {
        setVcState({
            ...vcState,
            showLockedWarning: true,
        }),
            setTimeout(() => {
                setVcState({
                    ...vcState,
                    showLockedWarning: false,
                })
            }, 3000)
    }

    const hideLockedContentWarning = () => {
        setVcState({
            ...vcState,
            showLockedWarning: false,
        })
    }

    const goDeepInDetail = (dataType?: any, value?: any) => {
        if (vcState?.level < 5) {
            let contentNew = vcState.contents
            let titles = vcState.title
            titles ? (titles[vcState?.level] = dataType) : ''
            contentNew[vcState?.level + 1] =
                getLevelContent(value, vcState.level) || []
            const newContentFiltered = removeIdAndTitle(
                contentNew[vcState?.level]
            )
            const newPlainValues = plainValues(newContentFiltered)
            setVcState({
                ...vcState,
                level: vcState?.level + 1,
                contents: contentNew,
                title: titles,
                isTable:
                    (!!newPlainValues && newPlainValues[0]) ||
                    newContentFiltered?.length === 1,
            })
        } else {
            showLockedContentWarning()
        }
    }

    return (
        <GaModal
            title={
                vcState.level !== 0
                    ? (vcState?.title && vcState?.title[vcState.level - 1]) ||
                      t('ew.requestCredentials.content')
                    : t('ew.requestCredentials.fileContent')
            }
            hideModal={() => {
                vcState.level !== 0
                    ? goToMainDetailsAction()
                    : setContentToShow(undefined)
            }}
            show={true}
            backButton={true}
            content={
                <div>
                    {vcState?.showLockedWarning ? (
                        <WarningPanel
                            label={t('ew.requestCredentials.contentLocked')}
                            className={styles.blockedContent}
                            hasIcon
                            icon={gaImages.warning}
                            closeAction={hideLockedContentWarning}
                        />
                    ) : null}

                    <div className={cx('marginBottom24', styles.contentHeader)}>
                        <LightButton
                            className={cx(styles.goMainbutton)}
                            size={ButtonSizes.SM}
                            text={t('ew.requestCredentials.goMain')}
                            functionality={() => setContentToShow(undefined)}
                            disabled={false}
                        />
                        <GaTag
                            className={cx(styles.levelTag)}
                            text={t('ew.requestCredentials.level', {
                                level: vcState?.level + 1 + '/5',
                            })}
                            color={'grey'}
                        />
                    </div>
                    {vcState?.contents && currentContent?.length ? (
                        <div className={containerStyle(vcState.isTable)}>
                            {currentContent?.map(
                                (content: any, index: number) => {
                                    return (
                                        <ComplexCredentialConfig
                                            key={
                                                'complexDeepCredConfig__' +
                                                index
                                            }
                                            content={content}
                                            index={index}
                                            level={vcState.level}
                                            isTable={vcState?.isTable}
                                            isDemoContent={isDemoContent}
                                            currentLength={
                                                currentContent?.length || 0
                                            }
                                            action={() =>
                                                goDeepInDetail(
                                                    content?.dataType,
                                                    content?.value
                                                )
                                            }
                                        />
                                    )
                                }
                            )}
                        </div>
                    ) : (
                        <CredEmptyContent />
                    )}
                </div>
            }
        />
    )
}

export default CredDeepLevelDetails
