import React from 'react'

type TrashIconProps = {
    size?: number
    color?: string
    className?: string
    onClick?: (x?: any) => void
}

const TrashIcon: React.FC<TrashIconProps> = React.memo((props) => {
    const { size, color, className, onClick } = props

    return (
        <svg
            onClick={onClick}
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 8H6.66667H28"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.3332 8V26.6667C25.3332 27.3739 25.0522 28.0522 24.5521 28.5523C24.052 29.0524 23.3737 29.3333 22.6665 29.3333H9.33317C8.62593 29.3333 7.94765 29.0524 7.44755 28.5523C6.94746 28.0522 6.6665 27.3739 6.6665 26.6667V8M10.6665 8V5.33334C10.6665 4.62609 10.9475 3.94782 11.4476 3.44772C11.9476 2.94762 12.6259 2.66667 13.3332 2.66667H18.6665C19.3737 2.66667 20.052 2.94762 20.5521 3.44772C21.0522 3.94782 21.3332 4.62609 21.3332 5.33334V8"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})

export default TrashIcon
