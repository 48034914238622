import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonModel } from '../../../../interfaces/interfaces'
import LightButton from '../../../atoms/buttons/LightButton/LightButton'
import LinkButton from '../../../atoms/buttons/LinkButton/LinkButton'
import Description from '../../../atoms/texts/Description/Description'
import styles from './modal.module.scss'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import CloseIcon from '../../../../assets/iconsLibrary/simple/close/closeIcon'

type IModalProps = {
    title: string
    subtitle?: string
    headerClose?: boolean
    headerButton?: ButtonModel
    primaryButton?: ButtonModel
    secondaryButton?: ButtonModel
    className?: string
    show: boolean
    invisibleBg?: boolean
    primaryButtonDisabled?: boolean
    headerButtonFunction?: (x?) => void
    primaryButtonFunction?: (x?) => void
    handleClose: () => void
}

const Modal: React.FC<IModalProps> = (props) => {
    const { t } = useTranslation()
    const {
        title,
        headerButton,
        headerClose,
        primaryButtonDisabled,
        invisibleBg,
        primaryButton,
        secondaryButton,
        show,
        className,
        subtitle,
        headerButtonFunction,
        primaryButtonFunction,
        handleClose,
        children,
    } = props

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'
        }
        document?.getElementById('topOfTheList')?.scrollIntoView(false)
    }, [show, primaryButtonDisabled])

    return (
        <div
            className={`${styles.backgroundLayer} ${
                invisibleBg ? styles.transparent : ''
            } ${show ? styles.visibleModal : styles.hiddenModal}`}
        >
            <div className={`${styles.modal} ${className ? className : ''}`}>
                <div className={styles.modal__header}>
                    <div>
                        <h3 className={cx('heading5 neutral1000')}>
                            {t(title)}
                        </h3>
                        {subtitle ? (
                            <Description
                                className={cx('margin-top-micro')}
                                text={subtitle}
                            />
                        ) : null}
                    </div>
                    {headerButton ? (
                        <LightButton
                            className={styles.modal__header__primaryButton}
                            functionality={
                                headerButtonFunction
                                    ? headerButtonFunction
                                    : handleClose
                            }
                            text={headerButton?.label || ''}
                            icon={headerButton?.image}
                            disabled={false}
                        />
                    ) : null}
                    {headerClose ? (
                        <div
                            className={styles.modal__header__close}
                            onClick={() => handleClose()}
                        >
                            <CloseIcon size={24} color="#1E1E20" />
                        </div>
                    ) : null}
                </div>
                <div
                    className={`${styles.modal__content} ${
                        !primaryButton ? styles.shortBottom : ''
                    }`}
                >
                    {children}
                </div>
                {primaryButton ? (
                    <div className={styles.modal__footer}>
                        {secondaryButton ? (
                            <LightButton
                                className={styles.modal__footer__linkButton}
                                functionality={handleClose}
                                text={secondaryButton.label}
                                icon={secondaryButton.image}
                                disabled={false}
                            />
                        ) : null}
                        <DarkButton
                            className={styles.modal__footer__primaryButton}
                            functionality={
                                primaryButtonFunction
                                    ? primaryButtonFunction
                                    : handleClose
                            }
                            text={primaryButton.label}
                            icon={primaryButton.image}
                            SvgIcon={primaryButton.svgIcon}
                            disabled={primaryButtonDisabled || false}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default Modal
