import cx from 'classnames'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CanvasJSReact from '@canvasjs/react-charts' // We don’t have CanvasJS React Charts TypeScript definition as of now, so we have to use ts-ignore.
import {
    IDataChart,
    IGeneralSelectOption,
} from '../../../../../../interfaces/interfaces'
import styles from './issuedCredentialsStatistics.module.scss'
import {
    ActionStatusTypes,
    brandColors,
    gaColors,
    gaFonts,
} from '../../../../../../assets/globalConstants'
import DatesFilter from '../../datesFilter/datesFilter'
import ModalDatePicker from '../../modalDatePicker/modalDatePicker'
import {
    getICStatistics,
    setICSelectedPeriodIsYear,
} from '../../../homeActions'
import { homeSelectors } from '../../..'
import CardListSkeleton from '../../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import moment from 'moment'
import { sessionSelectors } from '../../../../../common/session'
import { licensesSelectors } from '../../../../../common/licenses'
import NoDataAvailable from '../components/noDataAvailable/NoDataAvailable'

const CanvasJSChart = CanvasJSReact.CanvasJSChart
interface IssuedCredentialsStatisticsProps {
    dataLoading?: boolean
    homeLoaded?: boolean
    selectedFilterPeriod: string
    setHomeLoaded: (x: any) => void
    setSelectedFilterDate: (x: any) => void
}

const IssuedCredentialsStatistics: React.FC<
    IssuedCredentialsStatisticsProps
> = (props) => {
    const { homeLoaded, selectedFilterPeriod, setSelectedFilterDate } = props
    const dispatch = useDispatch()

    const filterDatesOptions: IGeneralSelectOption[] = [
        {
            id: 'W',
            text: 'dashboard.last7Days',
            value: '7',
            action: () => (
                setSelectedFilterDate('7'),
                dispatch(setICSelectedPeriodIsYear(false))
            ),
        },
        {
            id: 'M',
            text: 'dashboard.last30Days',
            value: '30',
            action: () => (
                setSelectedFilterDate('30'),
                dispatch(setICSelectedPeriodIsYear(false))
            ),
        },
        {
            id: 'Y',
            text: 'dashboard.last365Days',
            value: '365',
            action: () => (
                setSelectedFilterDate('365'),
                dispatch(setICSelectedPeriodIsYear(true))
            ),
        },
    ]

    // Selectors
    const dataFeatures = useSelector(homeSelectors.getIssuedCredentials)

    const loadingStatistics =
        useSelector(homeSelectors.getStastisticsStatus) ===
        ActionStatusTypes?.loading

    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const allowedScopes = useSelector(sessionSelectors.getAllowedScopes)

    // Functions
    const getAxisYFormat = () => {
        const max = dataFeatures?.reduce(function (
            prev: IDataChart,
            current: IDataChart
        ) {
            return prev && prev.y > current.y ? prev : current
        })
        return max > 999 ? '#,##0,.##K' : '#'
    }

    const getAxisXFormat = () => {
        const periodDays = selectedFilterPeriod
            ? parseInt(selectedFilterPeriod)
            : 0
        return periodDays > 30
            ? 'MMM'
            : startDateYear === endDateYear
            ? 'DD/MMM'
            : 'DD/MM/YY'
    }

    // Variables

    const activeLicense = subscriptionInfo?.subscriptionStatus === 'active'
    const dataAvailable = !!allowedScopes?.length && activeLicense

    const firstEntry = dataFeatures?.length && dataFeatures[0]
    const lastEntry =
        dataFeatures?.length && dataFeatures[dataFeatures?.length - 1]
    const startDate =
        firstEntry?.dStr && moment(firstEntry?.dStr)?.format('DD/MM/YY')
    const endDate =
        lastEntry?.dStr && moment(lastEntry?.dStr)?.format('DD/MM/YY')
    const startDateYear = moment(firstEntry?.dStr)?.format('YY')
    const endDateYear = moment(lastEntry?.dStr)?.format('YY')

    const options = {
        animationEnabled: true,
        axisX: {
            valueFormatString: getAxisXFormat(),
            labelFontColor: gaColors.neutral500,
            labelFontSize: '12',
            labelFontWeight: '400',
            lineColor: gaColors.neutral300,
            tickLength: 0,
            fontFamily: gaFonts.base,
        },
        axisY: {
            valueFormatString: getAxisYFormat(),
            prefix: '',
            gridDashType: 'dot',
            gridThickness: 0,
            labelFontColor: gaColors.neutral500,
            labelFontSize: '12',
            labelFontWeight: '400',
            lineColor: gaColors.neutral300,
            tickLength: 0,
            minimum: 0,
            fontFamily: gaFonts.base,
        },
        toolTip: {
            cornerRadius: 8,
            labelFontSize: '12',
            labelFontWeight: '400',
            fontFamily: gaFonts.base,
            borderColor: gaColors.neutral300,
            fontColor: brandColors.primaryColor,
            content:
                "<span style='\"'color: {gaColors.neutral100};'\"'>{x}</span>: {y}",
        },

        data: [
            {
                markerColor: brandColors.primaryColor,
                yValueFormatString: '####',
                xValueFormatString: getAxisXFormat(),
                type: 'spline',
                lineColor: brandColors.primaryColor,
                highlightEnabled: false,
                dataPoints: dataFeatures,
            },
        ],
        creditText: undefined,
        creditHref: undefined,
    }

    // TODO: This will usefull in the future to select a period for search Stats
    // const [showRangeModal, setShowRangeModal] = useState<boolean>(false)

    const getSelectedPeriodLabel = filterDatesOptions?.find(
        (option) => option?.value === selectedFilterPeriod
    )?.text

    const getEndDate = moment()?.format('YYYY-MM-DDTHH:mm:ss[Z]') // today date

    const getBeginDate = moment()
        ?.subtract(parseInt(selectedFilterPeriod) - 1, 'd')
        ?.format('YYYY-MM-DDTHH:mm:ss[Z]') // selected period start date

    const loadingICStats =
        useSelector(homeSelectors.getICStastisticsStatus) ===
        ActionStatusTypes?.loading
    const statsParamsURL = `&end=${getEndDate}&begin=${getBeginDate}&window=current`

    const loadICStats = () => dispatch(getICStatistics(statsParamsURL, 'Ctf2'))

    useEffect(() => {}, [loadingStatistics])

    useEffect(() => {
        !homeLoaded
            ? !loadingICStats && loadICStats()
            : !loadingStatistics && loadICStats()
    }, [selectedFilterPeriod])

    return (
        <div className={styles.issuedCredentialsStatistics}>
            {!loadingStatistics ? (
                <>
                    <div className={styles.issuedCredentialsStatistics__header}>
                        <div>
                            <h6 className={cx('heading6 neutral1000')}>
                                {t('subscription.issuedCredentials')}
                            </h6>
                            {startDate && endDate ? (
                                <div
                                    className={`${styles.datesFilter} ${cx(
                                        'marginTop4'
                                    )}`}
                                >
                                    <span className={cx('bodyRegularSM')}>
                                        {startDate} - {endDate}
                                    </span>
                                </div>
                            ) : null}
                        </div>

                        <div className={styles.filtersRightContent}>
                            <DatesFilter
                                activePeriodLabel={t(
                                    getSelectedPeriodLabel || ''
                                )}
                                selectedFilterDate={selectedFilterPeriod}
                                setSelectedFilterDate={setSelectedFilterDate}
                                datesOptions={filterDatesOptions}
                            />
                            {/* TODO: This will usefull in the future to select a
                            period for search Stats
                            {showRangeModal ? <ModalDatePicker /> : null} */}
                        </div>
                    </div>

                    {dataAvailable ? (
                        <div className={styles.chartContainer}>
                            <CanvasJSChart options={options} />
                        </div>
                    ) : (
                        <NoDataAvailable />
                    )}
                </>
            ) : (
                <CardListSkeleton
                    cardsNumber={1}
                    className={`${styles.skeleton}`}
                />
            )}
        </div>
    )
}

export default IssuedCredentialsStatistics
