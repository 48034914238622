import { ActionStatusTypes } from '../../assets/globalConstants'
import {
    AppIntegrationModalListActionsTypes,
    AppIntegrationModalListActions,
    IAppIntegrationModalListState,
} from './appIntegrationModalTypes'

// LIST
const initialAppIntegrationModalListState: IAppIntegrationModalListState = {
    client: undefined,
    statusSendingData: undefined,
}

export function appIntegrationModalReducer(
    state = initialAppIntegrationModalListState,
    action: AppIntegrationModalListActions
): IAppIntegrationModalListState {
    switch (action.type) {
        case AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA:
            return {
                ...state,
                statusSendingData: ActionStatusTypes.loading,
                client: undefined,
            }
        case AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA_SUCCESS:
            return {
                ...state,
                statusSendingData: ActionStatusTypes.success,
                client: action?.payload?.client,
            }
        case AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA_FAILED:
            return {
                ...state,
                statusSendingData: ActionStatusTypes.failed,
                client: undefined,
            }
        case AppIntegrationModalListActionsTypes.CLEAR_STATE:
            return {
                ...initialAppIntegrationModalListState,
            }
        default:
            return state
    }
}
