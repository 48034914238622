import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GaSelect from '../../../../../../Components/atoms/formFields/select/GaSelect'
import GaTextInput from '../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import MediumModal from '../../../../../../Components/molecules/modals/Medium/MediumModal'
import { didMethodOptions } from '../../../../../../assets/globalConstants'
import { onPremise } from '../../../../../../data/globalVar'
import {
    ICreateDidModel,
    IDIDModel,
} from '../../../../../../interfaces/interfaces'
import {
    clearDid,
    createDid,
    createFirstDid,
    updateDid,
} from '../../../didActions'
import { getSelectedDid, getSelectedDidBaseId } from '../../../didSelectors'
import {
    modalPrimaryButton,
    modalPrimaryEditButton,
    modalSecondaryButton,
} from '../didList.constants'
import style from './didModal.module.scss'

type IDidModalProps = {
    show: boolean
    hideModal: () => void
    dids: IDIDModel[] | undefined
}

const DidModal: React.FC<IDidModalProps> = (props) => {
    const { hideModal, show, dids } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const selectedDid = useSelector(getSelectedDid)
    const selectedDidBaseId = useSelector(getSelectedDidBaseId)

    const initialFormState = {
        alias: selectedDid?.alias || '',
        didMethod:
            selectedDid?.DidId?.split(':')[1] || didMethodOptions[0]?.value,
        ebsiToken: selectedDid?.ebsiToken || '',
    }

    const [formData, setFormState] = useState(initialFormState)
    const { alias, didMethod, ebsiToken } = formData

    useEffect(() => setFormState(initialFormState), [selectedDid])

    const saveDid = () => {
        // Just in cloud for now
        dispatch(
            !!dids && dids?.length && !!dids[0]
                ? createDid({ didMethod, alias, ebsiToken })
                : !onPremise && createFirstDid({ didMethod, alias, ebsiToken })
        )
        hideModalAndClean()
    }

    const hideModalAndClean = () => {
        dispatch(clearDid()), setFormState(initialFormState)
        hideModal()
    }

    const updateDidData = () => {
        const simplifiedDid = {
            ...selectedDid,
            alias: alias,
            ebsiToken: ebsiToken,
        } as ICreateDidModel
        dispatch(updateDid(simplifiedDid, selectedDidBaseId))
        dispatch(clearDid())
        hideModal()
    }

    return (
        <MediumModal
            title={!selectedDid?.DidId ? 'createDid.title' : 'dids.detail'}
            subtitle={selectedDid?.DidId}
            headerClose={true}
            show={show}
            primaryButton={
                !selectedDid?.DidId
                    ? modalPrimaryButton
                    : modalPrimaryEditButton
            }
            secondaryButton={{
                label: modalSecondaryButton?.label,
                function: hideModalAndClean,
            }}
            primaryButtonFunction={
                !selectedDid?.DidId ? saveDid : updateDidData
            }
            handleClose={hideModalAndClean}
            className={style.mediumModal__customClass}
        >
            <div>
                <form>
                    <GaTextInput
                        id={'alias'}
                        invalid={false}
                        labelText={t('createDid.step4.aliasLabel')}
                        required={false}
                        name={'alias'}
                        onChange={(e) =>
                            setFormState({ ...formData, alias: e.target.value })
                        }
                        placeholder={t('createDid.step4.aliasPlaceholder')}
                        value={alias}
                    />
                    <GaSelect
                        idSelect={'privateKeyType'}
                        className={cx('marginTop20', style.methodInput)}
                        value={didMethod}
                        id="select-privateKeyType"
                        invalid={false}
                        disabled={!!selectedDid?.DidId}
                        labelText={t('dids.selectMethod')}
                        name={'privateKeyType'}
                        optionTextProperty={'text'}
                        optionValueProperty={'value'}
                        onChange={(e) =>
                            setFormState({
                                ...formData,
                                didMethod: e.target.value,
                            })
                        }
                        options={didMethodOptions}
                    />
                    {!selectedDid?.DidId && didMethod === 'ebsi' ? (
                        <>
                            <GaTextInput
                                className={cx('marginTop20')}
                                id={'did-ebsi-token'}
                                invalid={false}
                                labelText={t('createDid.step4.token')}
                                required={true}
                                name={'EBSItoken'}
                                onChange={(e) =>
                                    setFormState({
                                        ...formData,
                                        ebsiToken: e.target.value,
                                    })
                                }
                                placeholder={t('createDid.step4.token')}
                                value={ebsiToken}
                            />
                        </>
                    ) : null}
                </form>
            </div>
        </MediumModal>
    )
}

export default DidModal
