import { t } from 'i18next'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    brandLabels,
    brandPaymentMethod,
    gaImages,
} from '../../../../assets/globalConstants'
import { licensesSelectors } from '../../../../features/common/licenses'
import { popularLicenseType } from '../../../../features/common/licenses/licenses.constants'
import { ButtonModel, ITierModel } from '../../../../interfaces/interfaces'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import GaImage from '../../../atoms/Image/Image'
import LightPanel from '../../../atoms/panels/LightPanel/LightPanel'
import Description from '../../../atoms/texts/Description/Description'
import styles from './onboardingLicenseCard.module.scss'
import cx from 'classnames'
import {
    getIfProductHasAmount,
    getPrice,
    getTierActivePriceMonthly,
    getTierActivePriceYearly,
    getTierLimitToDisplay,
    getTierLimitsValues,
} from '../../../../utils/licensesUtil'
import { isDefaultBrandActive } from '../../../../utils/brandThemeUtil'
import GaHoverTooltip from '../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import {
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../data/globalVar'

type IOnboardingLicenseCardProps = {
    license: ITierModel
    showAllDetails?: boolean
    firstButton?: ButtonModel
    period: string
    isNewLicense?: boolean
    newLicensePeriod: string
    buttonExtraTranslationParams?: Object
}

type IFeatureProps = {
    feature: any
    label: string
    info?: string
    notAvailableNow?: boolean
    tooltipArrowPosition?: TooltipArrowPosition
}

const Feature: React.FC<IFeatureProps> = (props) => {
    const { feature, label, info, notAvailableNow, tooltipArrowPosition } =
        props
    let formattedFeatureLimit = getTierLimitToDisplay(feature)

    return !info ? (
        <li
            className={`${styles.license__features__list__item} ${
                notAvailableNow ? styles.notAvailableNow : ''
            }`}
        >
            {!notAvailableNow && (
                <>
                    <GaImage image={gaImages.check} />
                </>
            )}
            <div
                className={
                    notAvailableNow
                        ? styles.featureNotAvailableNow
                        : styles.license__features__list__item__content
                }
            >
                <span
                    className={cx(
                        'bodyRegularSM neutral1000',
                        styles.featureName
                    )}
                >
                    {formattedFeatureLimit && (
                        <span
                            className={cx(
                                'bodyBoldMD neutral1000 marginRight4'
                            )}
                        >
                            {formattedFeatureLimit}
                        </span>
                    )}
                    {t(label)}
                </span>
            </div>
        </li>
    ) : (
        <li className={styles.license__features__list__item}>
            <GaHoverTooltip
                position={TooltipPosition.bottom}
                positionArrowDirection={
                    tooltipArrowPosition || TooltipArrowPosition.center
                }
                label={
                    <>
                        <GaImage image={gaImages.check} />
                        &nbsp;&nbsp;
                        <span
                            className={cx(
                                'bodyBoldSM neutral1000',
                                styles.featureQuantity
                            )}
                        >
                            {feature}
                        </span>
                        <p
                            className={cx(
                                'bodyRegularSM neutral1000',
                                styles.featureName
                            )}
                        >
                            &nbsp;
                            {t(label)}
                        </p>
                    </>
                }
                info={info || ''}
            />
        </li>
    )
}

const OnboardingLicenseCard: React.FC<IOnboardingLicenseCardProps> = (
    props
) => {
    const {
        license,
        showAllDetails,
        firstButton,
        period,
        isNewLicense,
        buttonExtraTranslationParams,
    } = props
    const { t } = useTranslation()
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)
    const licenseIsEnterprise = !getIfProductHasAmount(license, selectedPeriod)

    return (
        <LightPanel className={`${styles.license} `}>
            {isNewLicense && (
                <div
                    className={cx(
                        styles.license__tag,
                        styles.license__tag__newLicense
                    )}
                >
                    <p className={'bodyBoldCap primary700'}>
                        {t('subscription.newPlan')}
                    </p>
                </div>
            )}
            <div className={styles.license__header}>
                <p
                    className={cx(
                        'heading6 neutral1000',
                        styles.license__header__title
                    )}
                >
                    {license?.name}
                </p>
                {!!license?.isPopular && (
                    <div className={styles.license__header__popularTag}>
                        <p className={cx('buttonSM')}>
                            {t('subscription.popular')}
                        </p>
                    </div>
                )}
            </div>
            {license?.description && (
                <Description
                    className={cx(
                        'bodyRegularSM neutral700',
                        styles.license__description
                    )}
                    text={license?.description}
                />
            )}
            {!licenseIsEnterprise ? (
                <>
                    <p
                        className={cx(
                            'bodyRegularMD neutral700',
                            styles.license__price
                        )}
                    >
                        <span className={cx('bodyRegularSM neutral1000')}>
                            {getPrice(license?.prices, selectedPeriod)}€
                        </span>{' '}
                        &nbsp;
                        {'/ ' + t('subscription.' + period)}
                    </p>
                    {/* COMEMNTED because this will be restored in the future
                     {period === 'year' && (
                        <span
                            className={cx(
                                'bodyRegularXS neutral1000',
                                styles.license__save
                            )}
                        >
                            {t('subscription.save10Percent')}
                        </span>
                    )} */}
                </>
            ) : (
                <p
                    className={`${cx(
                        'heading5 neutral1000',
                        styles.license__customPrice
                    )} ${
                        period === 'year'
                            ? styles.license__customPriceLarge
                            : ''
                    }`}
                >
                    {t('subscription.customPricing')}
                </p>
            )}
            <div className={styles.license__features}>
                <div>
                    <p
                        className={cx(
                            'bodyBoldXS neutral700',
                            styles.license__features__title
                        )}
                    >
                        {t('subscription.features')}
                    </p>
                    <ul className={styles.license__features__list}>
                        <Feature
                            feature={
                                getTierLimitsValues(license)
                                    ?.verificationTemplates
                            }
                            label={'subscription.verificationTemplates'}
                        />

                        <Feature
                            feature={getTierLimitsValues(license)?.activeUsers}
                            label={'subscription.activeUsers'}
                            // info={getTierLimitsValues(license)?.activeUsersInfo}
                        />
                    </ul>
                </div>
                <div>
                    <p
                        className={cx(
                            'bodyBoldXS neutral700',
                            styles.license__features__title
                        )}
                    >
                        {t('subscription.issuanceFeatures')}
                    </p>
                    <ul className={styles.license__features__list}>
                        <Feature
                            feature={
                                getTierLimitsValues(license)?.issuanceTemplates
                            }
                            label={'subscription.issuanceTemplates'}
                        />

                        <Feature
                            feature={
                                getTierLimitsValues(license)?.issuedCredentials
                            }
                            label={'subscription.issuedCredentials'}
                        />
                    </ul>
                </div>
                {showAllDetails && (
                    <div>
                        <p className={styles.license__features__title}>
                            {t('subscription.otherFeatures')}
                        </p>

                        <ul className={styles.license__features__list}>
                            <Feature
                                feature={getTierLimitsValues(license)?.dids}
                                label={'subscription.dids'}
                            />

                            <Feature
                                feature={getTierLimitsValues(license)?.apiKeys}
                                label={'subscription.apiKeys'}
                            />
                            {/* COMEMNTED because this will be restored in the future

                            {!!getTierLimitsValues(license)?.nFactor?.length && (
                                <li
                                    className={
                                        styles.license__features__list__item
                                    }
                                >
                                    <GaImage image={gaImages.check} />
                                    &nbsp;&nbsp;
                                    <span className={styles.featureName}>
                                        <Trans
                                            i18nKey={
                                                'subscription.nFactorMechanisms'
                                            }
                                            values={{
                                                all: getTierLimitsValues(license)?.nFactor?.join(
                                                    ', '
                                                ),
                                            }}
                                        />
                                    </span>
                                </li>
                            )} */}
                            {/* <li
                                className={styles.license__features__list__item}
                            >
                                <GaImage image={gaImages.check} />
                                &nbsp;&nbsp;
                                <span className={styles.featureName}>
                                    <Trans
                                        i18nKey={'subscription.notifications'}
                                        values={{
                                            all: getTierLimitsValues(license)?.notifications?.join(
                                                ', '
                                            ),
                                        }}
                                    />
                                </span>
                            </li> */}
                            {/* <li
                                className={styles.license__features__list__item}
                            >
                                <GaImage image={gaImages.check} />
                                &nbsp;&nbsp;
                                <span className={styles.featureName}>
                                    {t('subscription.stadistics')}
                                </span>
                            </li> */}
                            {/* {!!getTierLimitsValues(license)?.didPublicCatalogue && (
                                <Feature
                                    feature={''}
                                    label={'subscription.didPublic'}
                                />
                            )}

                            {getTierLimitsValues(license)?.support && (
                                <Feature
                                    feature={getTierLimitsValues(license)?.support}
                                    label={'subscription.support'}
                                />
                            )}
                            {getTierLimitsValues(license)?.slas && (
                                <Feature
                                    feature={''}
                                    label={'subscription.slas'}
                                />
                            )}

                            {getTierLimitsValues(license)?.newSchemaPublicCatalogue && (
                                <Feature
                                    feature={
                                        getTierLimitsValues(license)
                                            ?.newSchemaPublicCatalogue
                                    }
                                    label={'subscription.slas'}
                                    notAvailableNow
                                />
                            )}
                            {getTierLimitsValues(license)?.customQR && (
                                <Feature
                                    feature={getTierLimitsValues(license)?.customQR}
                                    label={'subscription.customQr'}
                                    notAvailableNow
                                />
                            )}
                            {!getTierLimitsValues(license)?.nFactor?.length && (
                                <li
                                    className={`${styles.license__features__list__item} ${styles.notAvailableNow}`}
                                >
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={styles.featureName}>
                                        <Trans
                                            i18nKey={
                                                'subscription.npNFactorMechanisms'
                                            }
                                        />
                                    </span>
                                </li>
                            )}
                            {!getTierLimitsValues(license)?.didPublicCatalogue && (
                                <Feature
                                    feature={''}
                                    label={'subscription.didPublic'}
                                    notAvailableNow
                                />
                            )}
                            {!getTierLimitsValues(license)?.newSchemaPublicCatalogue && (
                                <Feature
                                    feature={''}
                                    label={'subscription.newPublicSchemas'}
                                    notAvailableNow
                                />
                            )}
                            {!getTierLimitsValues(license)?.customQR && (
                                <li
                                    className={`${styles.license__features__list__item} ${styles.notAvailableNow}`}
                                >
                                    &nbsp; &nbsp;
                                    {t('subscription.customQr')}
                                </li>
                            )}
                            {!getTierLimitsValues(license)?.support && (
                                <Feature
                                    feature={''}
                                    label={'subscription.support'}
                                    notAvailableNow
                                />
                            )}
                            {!getTierLimitsValues(license)?.slas && (
                                <Feature
                                    feature={''}
                                    label={'subscription.slas'}
                                    notAvailableNow
                                />
                            )} */}
                        </ul>
                    </div>
                )}
            </div>
            {/* COMEMNTED because this will be restored in the future
            
            {firstButton && userIsOrgOwner && userPaymnetByStripe ? (
                !!isCurrentLicense &&
                currentLicensePeriod.toLowerCase() === selectedPeriod &&
                license?.licenseType !== 'Starter' ? (
                    <LightButton
                        className={cx(
                            'buttonSM',
                            styles.license__button,
                            !!isCurrentLicense && styles.license__buttonCurrent
                        )}
                        functionality={cancelSubscriptionFunction}
                        text={t('subscription.cancelSubscription')}
                        disabled={false}
                    />
                ) : (!!isCurrentLicense &&
                      license?.licenseType !== 'Starter') ||
                  !isCurrentLicense ||
                  (!!isCurrentLicense &&
                      currentLicensePeriod.toLowerCase() !== selectedPeriod) ? ( */}
            {firstButton ? (
                <DarkButton
                    className={cx('buttonMD', styles.license__button)}
                    text={firstButton?.label}
                    buttonExtraTranslationParams={buttonExtraTranslationParams}
                    disabled={false}
                    functionality={() => {
                        !licenseIsEnterprise || !isDefaultBrandActive()
                            ? firstButton.function()
                            : window?.open(brandLabels.contactlink, '_blank')
                    }}
                />
            ) : null}
            {/* ) : null
            ) : null} */}
            {/* COMEMNTED because this will be restored in the future
            
            {secondButton && (
                <LightButton
                    className={cx(
                        'buttonSM',
                        styles.license__button,
                        !!isCurrentLicense && styles.license__buttonCurrent
                    )}
                    functionality={secondButton?.function}
                    text={secondButton.label}
                    disabled={false}
                />
            )} */}
        </LightPanel>
    )
}

export default OnboardingLicenseCard
