import {
    IOrganizationActions,
    OrganizationTypes,
} from '../organization/organizationTypes'
import { ILoaderState, LoaderActions, LoaderActionTypes } from './loaderTypes'

const sessionInitialState: ILoaderState = {
    showScreenLoader: false,
}

export const loaderReducer = (
    state = sessionInitialState,
    action: LoaderActions | IOrganizationActions
) => {
    switch (action.type) {
        case LoaderActionTypes.SHOW_SCREEN_LOADER:
            return {
                ...state,
                showScreenLoader: true,
            }
        case LoaderActionTypes.HIDE_SCREEN_LOADER:
            return {
                ...state,
                showScreenLoader: false,
            }
        case OrganizationTypes.CREATE_STARTER_ORG:
            return {
                ...state,
                showScreenLoader: true,
            }
        case OrganizationTypes.CREATE_STARTER_ORG_SUCCESS:
            return {
                ...state,
                showScreenLoader: true,
            }
        default:
            return state
    }
}
