import {
    CredentialStatus,
    CredentialStatusType,
    CredentialStatusWrapper,
    credentialStatusTypes,
} from '../../data/requestCredentials.interfaces'
import { getRemoteStatus } from '../displayDetailsUtil'

export class CredentialStatusList2017 implements CredentialStatusWrapper {
    static myInstance: CredentialStatusList2017

    static getInstance() {
        if (!CredentialStatusList2017.myInstance) {
            CredentialStatusList2017.myInstance = new CredentialStatusList2017()
        }
        return this.myInstance
    }

    constructor() {}

    apply(type: CredentialStatusType): boolean {
        return type === 'CredentialStatusList2017'
    }

    getType(): CredentialStatusType {
        return 'CredentialStatusList2017'
    }

    async resolve(
        status: CredentialStatus,
        credentialId: string
    ): Promise<credentialStatusTypes> {
        try {
            let vs = await getRemoteStatus(status.id)
            // console.log('[CredentialStatus2017] getRemoteStatus', vs);

            const cstatus = vs.verifiableCredential.find(function (
                element: any
            ) {
                if (element.claim.id === credentialId) return true
            })
            // console.log('Found status', cstatus);
            const statusState = cstatus
                ? cstatus.claim.currentStatus
                : 'INVALID'

            return statusState
        } catch (err) {
            // console.log('[Credential Manager] getRemoteStatus', err)
            return 'INVALID'
        }
    }
}
