import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FormPanel from '../../../../Components/FormPanel/FormPanel'
import GaTextInput from '../../../../Components/atoms/formFields/textInput/GaTextInput'
import { isDefaultBrandActive } from '../../../../utils/brandThemeUtil'
import { isValidEmail } from '../../../../utils/validations/functions'
import PanelScafoldingPublic from '../../PanelScafolding/public/PanelScafoldingPublic'
import { setSelectedRoute } from '../../PanelScafolding/scafoldingActions'
import { getUserEmail_s } from '../../session/sessionSelectors'
import { submitEmailLogin } from '../loginAction'
import { loginButton, ssiLoginLink } from './emailLoginConstants'
import { sessionSelectors } from '../../session'
import GaTextInputPassword from '../../../../Components/atoms/formFields/textInputPassword/GaTextInputPassword'

type IEmailLoginProps = {}

const EmailLogin: React.FC<IEmailLoginProps> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const user = useSelector(getUserEmail_s)
    const tokenExpiration = useSelector(sessionSelectors.getTokenExpiration)
    const tokenOrg = useSelector(sessionSelectors.getUserOrganization)

    const goToHome = () => {
        dispatch(setSelectedRoute('home'))
        history.push('/home')
    }
    const goToCreateOrg = () => {
        dispatch(setSelectedRoute('home'))
    }
    const initApp = async () => {
        if (!!user && !!tokenOrg) {
            !!tokenOrg ? goToHome() : goToCreateOrg()
        }
        // else if (isDefaultBrandActive()) {
        dispatch(setSelectedRoute('email-login'))
        history.push('/email-login')
        // }
        //  else {
        //     dispatch(setSelectedRoute('login'))
        //     history.push('/login')
        // }
    }

    useEffect(() => {
        initApp()
    }, [user, tokenExpiration])

    const [formData, setFormState] = useState({
        email: '',
        password: '',
    })

    const { email, password } = formData
    const validForm = !!(
        isValidEmail(formData?.email) && formData?.password.trim()?.length
    )

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formData,
            [name]: value,
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()

        validForm ? submitUserData() : null
    }

    const submitUserData = () => {
        dispatch(submitEmailLogin(email, password))
    }

    return (
        <PanelScafoldingPublic>
            <FormPanel
                title={'login.signIn'}
                legend={'login.logInDescription'}
                // link={forgetPasswordLink}
                primaryButtonDisabled={!validForm}
                primaryButton={loginButton}
                primaryFunction={onSubmit}
                onSubmit={onSubmit}
                ctaSeparator={'login.or'}
                ctaLink={ssiLoginLink}
                signUpLink={true}
            >
                <GaTextInput
                    className={cx('margin-bottom')}
                    id={'email'}
                    invalid={!isValidEmail(formData?.email)}
                    errorTexts={[t('public.invalidEmail')]}
                    labelText={t('login.email')}
                    required={true}
                    name={'email'}
                    onChange={(e) => {
                        handleInputChange(e)
                    }}
                    placeholder={'Email'}
                    value={formData.email}
                />

                <GaTextInputPassword
                    className={cx('margin-bottom')}
                    id={'password'}
                    invalid={!formData?.password?.trim()}
                    errorTexts={[t('public.invalidPassword')]}
                    labelText={t('login.password')}
                    required={true}
                    name={'password'}
                    onChange={(e) => {
                        handleInputChange(e)
                    }}
                    placeholder={t('login.password')}
                    type="password"
                    value={formData.password}
                />
            </FormPanel>
        </PanelScafoldingPublic>
    )
}

EmailLogin.defaultProps = {}

export default EmailLogin
