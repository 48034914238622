import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DarkButton from '../../../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import LightButton from '../../../../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaSelect from '../../../../../../../../Components/atoms/formFields/select/GaSelect'
import GaSelectCheckbox, {
    Selectable,
} from '../../../../../../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import GaTextInput from '../../../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import {
    IRoleModel,
    IUserItem,
} from '../../../../../../../../interfaces/interfaces'
import { apiKeysSelectors } from '../../../../../../../studio/ApiKeys'
import {
    apiKeysScopes,
    propertiesToSearchApiKeys,
    propertiesToSearchSSIOperations,
} from '../../../../../../../studio/ApiKeys/views/apiKeysList/apiKeysList.constants'
import { didsSelectors } from '../../../../../../../studio/Dids'
import { sessionSelectors } from '../../../../../../session'
import { rolesScopes } from '../../../store/team.constants'
import styles from '../../../views/users.module.scss'
import { rolesSelectors } from '../../../../roles/store'
import { propertiesToSearchTenants } from '../../../../../constants/team.constants'
import { propertiesToSearchDids } from '../../../../../../../../Components/molecules/didsSelector/data/didsSelector.constants'
import GaCheckbox from '../../../../../../../../Components/atoms/formFields/checkbox/GaCheckbox'
import {
    LabelPosition,
    TextSizes,
} from '../../../../../../../../data/globalVar'

type IUpdateFormProps = {
    currentUser: IUserItem
    buttonFunction: (x?) => void
    closeModal: () => void
}

const provider = process.env['REACT_APP_PROVIDER'] || 'gataca'

const UpdateForm: React.FC<IUpdateFormProps> = (props) => {
    const { currentUser, buttonFunction, closeModal } = props
    const { t } = useTranslation()

    const anyOption = {
        key: '*',
        value: t('createUser.any'),
    }
    const selectAllOption = {
        key: '*',
        value: t('createUser.selectAll'),
    }
    const currentTenant = useSelector(sessionSelectors.getCurrentTenant)
    const userTenants = useSelector(sessionSelectors.getTenants)
    const useDids = useSelector(sessionSelectors.getUserDids)
    const selectableDids = useSelector(didsSelectors.getDidsForSelect)
    const getOrgId = useSelector(sessionSelectors.getUserOrganization)
    const apiKeys = useSelector(sessionSelectors.getUserApiKeys)
    const apiKeysData = useSelector(apiKeysSelectors.getApiKeys)
    const ssiConfigs = useSelector(sessionSelectors.getSsiOperations)
    const userSSIOperations = useSelector(sessionSelectors.getUserSSIOperations)
    const allDidsAllowed = useSelector(sessionSelectors.allDidsAllowed)
    const allApiKeysAllowed = useSelector(sessionSelectors.allApiKeysAllowed)
    const allConfigsAllowed = useSelector(
        sessionSelectors.allSsiOperationsAllowed
    )
    const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const allTenantsAllowed = useSelector(sessionSelectors.allTenantsAllowed)
    const rolesList: IRoleModel[] | undefined = useSelector(
        rolesSelectors.getRoles
    )
    const apiKeysLoading = useSelector(apiKeysSelectors.loadingStatus)

    // States & Consts
    const [role, setSelectedRole] = useState<IRoleModel>()
    const [availableDidsOptions, setAvailableDidsOptions] = useState(
        new Array()
    )
    const [availableApiKeysOptions, setAvailableApiKeysOptions] = useState(
        new Array()
    )
    const [availableSSIOptions, setAvailableSSIOptions] = useState(new Array())
    const initialFormState = {
        user: {
            email: currentUser?.email || '',
            role: currentUser.role || '',
            provider: currentUser.provider || provider,
            dids: currentUser?.dids,
            ssi_operations: currentUser?.ssi_operations,
            api_keys: currentUser?.api_keys,
            tenants: currentUser.tenants || ['*'],
            org_id: currentUser.org_ID,
        },
        validEmail: true,
    }
    const [formData, setFormState] = useState(initialFormState)
    const { user } = formData
    const { email, dids, ssi_operations, api_keys, tenants } = formData?.user
    const [anyTenantAllowed, setAnyTenantAllowed] = useState(
        tenants?.includes('*')
    )
    const [anyDidAllowed, setAnyDidAllowed] = useState(dids?.includes('*'))
    const [anyApiKeyAllowed, setAnyApiKeyAllowed] = useState(
        api_keys?.includes('*')
    )
    const [anySSIAllowed, setAnySSIAllowed] = useState(
        ssi_operations?.includes('*')
    )

    const validForm = !!user.email?.trim() && !!user.role?.trim()
    const currentTenantSelected =
        (currentTenant && user?.tenants?.includes(currentTenant)) ||
        user?.tenants?.includes('*')

    const displayAnyTenantCheckbox =
        allTenantsAllowed &&
        user?.tenants?.length > 0 &&
        (user?.tenants?.length === userTenants?.length ||
            user?.tenants?.includes('*'))

    const displayAnyDidCheckbox =
        allDidsAllowed &&
        user?.dids?.length > 0 &&
        (dids?.length === selectableDids?.length || dids?.includes('*'))

    const displayAnyApiKeyCheckbox =
        displayAnyDidCheckbox &&
        allApiKeysAllowed &&
        anyDidAllowed &&
        user?.api_keys?.length > 0 &&
        (user?.api_keys?.length === availableApiKeysOptions?.length ||
            user?.api_keys?.includes('*'))

    const displayAnySSICheckbox =
        displayAnyDidCheckbox &&
        allDidsAllowed &&
        anyDidAllowed &&
        user?.ssi_operations?.length > 0 &&
        (user?.ssi_operations?.length === availableSSIOptions?.length ||
            user?.ssi_operations?.includes('*'))

    // Functions
    const canEditUserTenants = () => {
        const userHasMoreTenants = !(
            userTenants?.length >= (currentUser?.tenants || []).length
        )
        const matchingTenants = currentUser?.tenants?.filter(function (tag) {
            userTenants?.includes(tag)
        })
        const userHaveDifferentTenants =
            matchingTenants?.length !== userTenants?.length

        return (
            allTenantsAllowed ||
            (!userHasMoreTenants && !userHaveDifferentTenants)
        )
    }

    const canEditUserDids = () => {
        const userHasMoreDids =
            currentUser?.dids?.length >= (useDids || []).length
        const matchingDids = currentUser?.tenants?.filter(function (tag) {
            useDids?.includes(tag)
        })
        const userHaveDifferentDids = matchingDids?.length !== useDids?.length

        return allDidsAllowed || (!userHasMoreDids && !userHaveDifferentDids)
    }

    const canEditUserApiKeys = () => {
        const userHasMoreApiKeys =
            currentUser?.api_keys?.length >= (apiKeys || []).length
        const matchingApiKeys = currentUser?.api_keys?.filter(function (tag) {
            apiKeys?.includes(tag)
        })
        const userHaveDifferentApiKeys =
            matchingApiKeys?.length !== apiKeys?.length

        return (
            allApiKeysAllowed ||
            (!userHasMoreApiKeys && !userHaveDifferentApiKeys)
        )
    }

    const canEditUserSSIConfigs = () => {
        const userHasMoreApiKeys =
            currentUser?.ssi_operations?.length >=
            (userSSIOperations || []).length
        const matchingApiKeys = currentUser?.ssi_operations?.filter(function (
            tag
        ) {
            userSSIOperations?.includes(tag)
        })
        const userHaveDifferentApiKeys =
            matchingApiKeys?.length !== userSSIOperations?.length

        return (
            allConfigsAllowed ||
            (!userHasMoreApiKeys && !userHaveDifferentApiKeys)
        )
    }

    const selectableDidsIds = (selectableDidsList) =>
        selectableDidsList?.map((did) => did?.key)

    const checkedDids = () => {
        let didsChecked = selectableDids
        if (!dids?.includes('*')) {
            didsChecked = selectableDids?.filter((did) =>
                dids?.includes(did?.key)
            )
        }
        return selectableDidsIds(didsChecked)
    }

    useEffect(() => {
        selectRole(user.role)
    }, [role])

    useEffect(() => {}, [user?.tenants])

    useEffect(() => {
        !apiKeysLoading && getApiKeys()
        getSsiOperations()
    }, [formData])

    const selectRole = (role_id?: string) => {
        let selectedRole = rolesList?.find((role) => role.role_id === role_id)
        setSelectedRole(selectedRole)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const formattedUser = formatUserInfo()
        buttonFunction({ ...formattedUser })
    }

    const getTenants = (): Selectable[] => {
        let mapped = userTenants.map((t) => {
            return { key: t, value: t }
        })
        if (currentUser?.tenants?.includes('*')) {
            mapped.unshift({ key: '*', value: t('createUser.any') })
        }
        return mapped
    }

    const getApiKeys = (): Selectable[] => {
        let filteredApiKeys = apiKeysData
        if (!user.dids.includes('*')) {
            filteredApiKeys = filteredApiKeys.filter((ak) =>
                user.dids?.includes(ak.did)
            )
        }

        let mapped = new Array()

        if (currentTenantSelected) {
            filteredApiKeys?.forEach((apk) => {
                mapped?.push({ key: apk?.id, value: apk?.id })
            })
            currentUser?.api_keys?.includes('*') && mapped?.unshift(anyOption)
        } else {
            mapped = [anyOption]
        }
        setAvailableApiKeysOptions(mapped)
        return mapped
    }

    const getSsiOperations = (): Selectable[] => {
        let filteredSSIConfigs = ssiConfigs
        let ssiDid
        if (!user.dids.includes('*')) {
            filteredSSIConfigs = filteredSSIConfigs.filter((ssi) => {
                ssiDid = ssi?.vmethodId?.split('#')[0]
                return user.dids?.includes(ssiDid)
            })
        }

        let mapped = new Array()

        if (currentTenantSelected) {
            filteredSSIConfigs?.forEach((ssi) => {
                mapped?.push({ key: ssi?.id, value: ssi?.id })
            })
            currentUser?.ssi_operations?.includes('*') &&
                mapped?.unshift(anyOption)
        } else {
            mapped = [anyOption]
        }

        setAvailableSSIOptions(mapped)
        return mapped
    }

    const displayTenants = (): boolean =>
        !!allowedScopes?.includes('readTenants') &&
        !!getTenants().length &&
        canEditUserTenants()

    const displayDids = (): boolean =>
        canEditUserDids() &&
        checkScopes([
            'deleteDids',
            'updateDids',
            'deleteApiKeys',
            'updateApiKeys',
            'deleteSSIConfigs',
            'updateSSIConfigs',
        ])

    const displayApiKeys = (): boolean =>
        canEditUserDids() &&
        canEditUserApiKeys() &&
        checkScopes(['deleteApiKeys', 'updateApiKeys'])

    const displaySSIConfigs = (): boolean =>
        canEditUserDids() &&
        canEditUserSSIConfigs() &&
        checkScopes(['deleteSSIConfigs', 'updateSSIConfigs'])

    const checkScopes = (scopes: string[]): boolean =>
        scopes.some((s) => role?.scopes?.includes(s))

    const [state, setState] = React.useState<{
        selections: Selectable[]
        searched: string
        searchedResult: Selectable[]
    }>({
        selections: [],
        searched: '',
        searchedResult:
            availableDidsOptions ||
            availableSSIOptions ||
            availableApiKeysOptions ||
            [],
    })
    function handleSearchedChange(e) {
        setState({
            selections: state.selections,
            searched: state.searched,
            searchedResult: e,
        })
    }
    const getDidsSelectorOption = () => {
        if ((displayTenants() && currentTenantSelected) || !displayTenants()) {
            return selectableDids
        } else {
            return []
        }
    }

    const formatUserInfo = () => {
        const userDidsIds = selectableDids?.map((did) => did?.key)
        const userSsiOperationsIds = ssiConfigs?.map((ssiConf) => ssiConf?.id)
        const userApiKeysIds = apiKeysData?.map((apkey) => apkey?.id)

        return {
            email: email,
            password: '',
            role: formData?.user?.role,
            provider: provider,
            dids:
                (anyDidAllowed && displayAnyDidCheckbox) ||
                (displayTenants() && !currentTenantSelected)
                    ? ['*']
                    : dids?.length > 0
                    ? dids
                    : userDidsIds,
            ssi_operations:
                (anySSIAllowed && displayAnySSICheckbox) ||
                (displayTenants() && !currentTenantSelected)
                    ? ['*']
                    : ssi_operations?.length > 0
                    ? ssi_operations
                    : userSsiOperationsIds,
            api_keys:
                (anyApiKeyAllowed && displayAnyApiKeyCheckbox) ||
                (displayTenants() && !currentTenantSelected)
                    ? ['*']
                    : api_keys?.length > 0
                    ? api_keys
                    : userApiKeysIds,
            tenants:
                anyTenantAllowed && displayAnyTenantCheckbox
                    ? ['*']
                    : tenants?.length > 0
                    ? tenants
                    : [currentTenant || userTenants[0]],
            org_id: getOrgId,
        }
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <GaSelect
                    idSelect={'role'}
                    className={cx('marginBottom20')}
                    value={user?.role}
                    id="select-role"
                    labelText={t('configUser.roleLabel')}
                    name={'role'}
                    permissions={{
                        scopes: rolesScopes.read,
                        every: true,
                    }}
                    optionTextProperty={'role_id'}
                    optionValueProperty={'role_id'}
                    onChange={(e) => {
                        selectRole(e.target.value)
                        setFormState({
                            ...formData,
                            user: { ...user, role: e.target.value },
                        })
                    }}
                    options={rolesList}
                />
                {displayTenants() ? (
                    <>
                        <GaSelectCheckbox
                            id={'tenantsCheckbox'}
                            className={cx('marginTop20')}
                            invalid={!user?.tenants?.length}
                            labelText={t('createUser.tenantLabel')}
                            required={true}
                            name={'tenants'}
                            permissions={{
                                scopes: ['createTenants'],
                                every: true,
                            }}
                            onChange={(e) => {
                                setFormState({
                                    ...formData,
                                    user: {
                                        ...user,
                                        tenants: e,
                                    },
                                })
                            }}
                            options={getTenants()}
                            checkedData={tenants}
                            handleSearchedChange={handleSearchedChange}
                            propertiesToSearch={propertiesToSearchTenants}
                        />
                        {displayAnyTenantCheckbox && (
                            <div className={styles.setAnyCheckbox}>
                                <GaCheckbox
                                    index={'0'}
                                    name="set_any"
                                    defaultChecked={anyTenantAllowed}
                                    onChange={() =>
                                        setAnyTenantAllowed(!anyTenantAllowed)
                                    }
                                    itemText={t('createUser.allowAnyTenant')}
                                    textSize={TextSizes.LG}
                                    position={LabelPosition.right}
                                ></GaCheckbox>
                            </div>
                        )}
                    </>
                ) : (
                    <GaTextInput
                        labelText={t('users.tenants')}
                        readOnly
                        onChange={(e) => {}}
                        value={
                            user?.tenants?.includes('*')
                                ? t('createUser.selectAll')
                                : user?.tenants?.join(', ')
                        }
                    />
                )}
                {displayDids() ? (
                    <>
                        <GaSelectCheckbox
                            id={'didsCheckbox'}
                            className={cx('marginTop20')}
                            selectAllOption={selectAllOption}
                            invalid={!useDids?.length}
                            labelText={t('createUser.didLabel')}
                            required={!allDidsAllowed}
                            name={'dids'}
                            onChange={(e) => {
                                setFormState({
                                    ...formData,
                                    user: {
                                        ...formData?.user,
                                        dids: e,
                                        api_keys: [],
                                        ssi_operations: [],
                                    },
                                }),
                                    setAnyApiKeyAllowed(false)
                                setAnySSIAllowed(false)
                            }}
                            permissions={{
                                scopes: ['readDids', 'updateUsers'],
                                every: true,
                            }}
                            options={getDidsSelectorOption()}
                            checkedData={checkedDids()}
                            handleSearchedChange={handleSearchedChange}
                            propertiesToSearch={propertiesToSearchDids}
                        />

                        {displayAnyDidCheckbox && (
                            <div className={styles.setAnyCheckbox}>
                                <GaCheckbox
                                    index={'0'}
                                    name="set_any"
                                    defaultChecked={anyDidAllowed}
                                    onChange={() =>
                                        setAnyDidAllowed(!anyDidAllowed)
                                    }
                                    itemText={t('createUser.allowAnyDid')}
                                    textSize={TextSizes.LG}
                                    position={LabelPosition.right}
                                ></GaCheckbox>
                            </div>
                        )}
                    </>
                ) : (
                    <GaTextInput
                        className={cx('marginTop20')}
                        labelText={t('users.dids')}
                        readOnly
                        onChange={(e) => {}}
                        value={
                            user?.dids?.includes('*')
                                ? t('createUser.selectAll')
                                : user?.dids?.join(', ')
                        }
                    />
                )}

                {displayApiKeys() ? (
                    <>
                        <GaSelectCheckbox
                            id={'apiKeysCheckbox'}
                            className={cx('marginTop20 margin-bottom')}
                            labelText={t('createUser.apiKeysLabel')}
                            invalid={!user?.api_keys?.length}
                            required={true}
                            name={'api_keys'}
                            onChange={(e) => {
                                setFormState({
                                    ...formData,
                                    user: {
                                        ...user,
                                        api_keys: e,
                                        ssi_operations: [],
                                    },
                                })
                                setAnySSIAllowed(false)
                            }}
                            permissions={{
                                scopes: apiKeysScopes.read,
                                every: true,
                            }}
                            checkedData={user.api_keys}
                            options={availableApiKeysOptions}
                            handleSearchedChange={handleSearchedChange}
                            propertiesToSearch={propertiesToSearchApiKeys}
                        />
                        {displayAnyApiKeyCheckbox && (
                            <div className={styles.setAnyCheckbox}>
                                <GaCheckbox
                                    index={'0'}
                                    name="set_any"
                                    defaultChecked={anyApiKeyAllowed}
                                    onChange={() =>
                                        setAnyApiKeyAllowed(!anyApiKeyAllowed)
                                    }
                                    itemText={t('createUser.allowAnyApiKey')}
                                    textSize={TextSizes.LG}
                                    position={LabelPosition.right}
                                ></GaCheckbox>
                            </div>
                        )}
                    </>
                ) : (
                    <GaTextInput
                        className={cx('marginTop20')}
                        labelText={t('users.apiKeys')}
                        readOnly
                        onChange={(e) => {}}
                        value={
                            user?.api_keys?.includes('*')
                                ? t('createUser.selectAll')
                                : user?.api_keys?.join(', ')
                        }
                    />
                )}

                {displaySSIConfigs() ? (
                    <>
                        <GaSelectCheckbox
                            id={'ssiConfigsCheckbox'}
                            className={cx('marginTop20 margin-bottom')}
                            labelText={t('createUser.ssiConfigsLabel')}
                            name={'ssi_operations'}
                            onChange={(e) => {
                                setFormState({
                                    ...formData,
                                    user: {
                                        ...user,
                                        ssi_operations: e,
                                    },
                                })
                            }}
                            permissions={{
                                scopes: ['readSSIConfigs'],
                                every: true,
                            }}
                            checkedData={currentUser.ssi_operations}
                            options={availableSSIOptions}
                            handleSearchedChange={handleSearchedChange}
                            propertiesToSearch={propertiesToSearchSSIOperations}
                        />
                        {displayAnySSICheckbox && (
                            <div className={styles.setAnyCheckbox}>
                                <GaCheckbox
                                    index={'0'}
                                    name="set_any"
                                    defaultChecked={anySSIAllowed}
                                    onChange={() =>
                                        setAnySSIAllowed(!anySSIAllowed)
                                    }
                                    itemText={t('createUser.allowAnySSIConfig')}
                                    textSize={TextSizes.LG}
                                    position={LabelPosition.right}
                                ></GaCheckbox>
                            </div>
                        )}
                    </>
                ) : (
                    <GaTextInput
                        className={cx('marginTop20')}
                        labelText={t('users.ssiConfigs')}
                        readOnly
                        onChange={(e) => {}}
                        value={
                            user?.api_keys?.includes('*')
                                ? t('createUser.selectAll')
                                : user?.api_keys?.join(', ')
                        }
                    />
                )}
            </form>
            <div className={styles.buttonsContainer}>
                <LightButton
                    className={cx('margin-left-auto')}
                    text={'public.cancel'}
                    disabled={false}
                    functionality={() => closeModal()}
                />
                <DarkButton
                    text={'public.save'}
                    disabled={!validForm}
                    functionality={() => buttonFunction(formData?.user)}
                />
            </div>
        </>
    )
}

export default UpdateForm
