import { createSelector } from 'reselect'
import {
    IDataChart,
    MetricStatistic,
    MetricValue,
} from '../../../interfaces/interfaces'
import { RootState } from '../../../store/indexReducers'
import moment from 'moment'
import { ActionStatusTypes } from '../../../assets/globalConstants'

export const getStastisticsStatus = (state: RootState): ActionStatusTypes =>
    state.homeReducer.getStaticsStatus

export const getAUStastisticsStatus = (state: RootState): ActionStatusTypes =>
    state.homeReducer.getAUStaticsStatus

export const getICStastisticsStatus = (state: RootState): ActionStatusTypes =>
    state.homeReducer.getICStaticsStatus

export const getStastisticsAU = (state: RootState) =>
    state.homeReducer.statisticsAU
export const getStastisticsIC = (state: RootState) =>
    state.homeReducer.statisticsIC

export const getUsedFeaturesNumberStatsAU = (state: RootState) =>
    getFeaturesNumberAU(
        state.homeReducer.statistics,
        state.homeReducer.generalDataLoaded
    )

export const getUsedFeaturesNumberStatsIC = (state: RootState) =>
    getFeaturesNumberIC(
        state.homeReducer.statistics,
        state.homeReducer.generalDataLoaded
    )

export const getVerifiers = (state: RootState) => state.homeReducer.verifiers
export const getNotifications = (state: RootState) =>
    state.homeReducer.notifications
export const getPendingActions = (state: RootState) =>
    state.homeReducer.pendingActions
export const displayScan = (state: RootState) => state.homeReducer.displayScan
export const displayScanNavigation = (state: RootState) =>
    state.homeReducer.displayScanNavigation
export const invitationUrl = (state: RootState) =>
    state.homeReducer.invitationUrl
export const generalDataLoaded = (state: RootState) =>
    state.homeReducer.generalDataLoaded

export const getIsICYearPeriodSelected = (state: RootState) =>
    state.homeReducer.selectedICPeriodIsYear

export const getIsAUYearPeriodSelected = (state: RootState) =>
    state.homeReducer.selectedAUPeriodIsYear

export const getIssuedCredentials = createSelector(
    [getStastisticsIC, getIsICYearPeriodSelected],
    (statistics, isYearlyPeriod) => {
        return getLastValueOfEachDay(statistics?.values, isYearlyPeriod)
    }
)

export const getActiveUsers = createSelector(
    [getStastisticsAU, getIsAUYearPeriodSelected],
    (statistics, isYearlyPeriod) => {
        return getLastValueOfEachDay(statistics?.values, isYearlyPeriod)
    }
)

export const showAgeVerificationModal = (state: RootState) =>
    state.homeReducer.showAgeVerificationModal

const getFeaturesNumberAU = (
    statistics: MetricStatistic | undefined,
    getStaticsStatus
) => {
    if (!getStaticsStatus) {
        return {
            activeUsers: getLastValue(statistics) || 0,
        }
    }
}

const getFeaturesNumberIC = (
    statistics: MetricStatistic | undefined,
    getStaticsStatus
) => {
    if (!getStaticsStatus) {
        return {
            issuedCredentials: getLastValue(statistics) || 0,
        }
    }
}

const getLastValue = (data: MetricStatistic | undefined) => {
    const lastData = data?.values?.filter((value) => value?.time === data?.end)
    return lastData && lastData?.length > 0 ? lastData[0]?.value : 0
}

const getLastValueOfEachDay = (
    periodValues: MetricValue[] | undefined,
    isYearlyPeriod?: boolean
) => {
    let lastValues = periodValues?.reduce((acc: any, cur: any) => {
        let curDate = new Date(cur.time)
        let curDateStr = moment(curDate)?.format('YYYY-MM-DD')

        let existDateObj = acc.find((f: any) => {
            return curDateStr && f.dStr && f.dStr === curDateStr
        })

        let existDateObjIndex = acc.findIndex(
            (f: any) => curDateStr && f.dStr && f.dStr === curDateStr
        )

        const curDisplayData = {
            x: new Date(curDateStr),
            y: cur?.value,
            dStr: curDateStr,
        }

        if (existDateObj) {
            let existDate = new Date(existDateObj.time)

            if (curDate.getTime() > existDate.getTime()) {
                acc[existDateObjIndex] = curDisplayData
            }
        } else {
            acc.push(curDisplayData)
        }

        return acc
    }, new Array())

    return isYearlyPeriod && lastValues?.length > 30
        ? getLastValueOfMonth(lastValues)
        : lastValues
}

const getLastValueOfMonth = (data: any) => {
    const grouped = {}
    data?.forEach((obj: any) => {
        const date = new Date(obj?.dStr)
        const key = date?.getFullYear() + '-' + (date?.getMonth() + 1)
        if (!grouped[key as keyof typeof grouped]) {
            // @ts-ignore
            grouped[key as keyof typeof grouped] = []
        }

        const newDate = new Date(obj?.dStr)
        newDate?.setDate(1)
        obj.x = newDate
        // @ts-ignore
        grouped[key as keyof typeof grouped]?.push(obj)
    })

    const result = Object.values(grouped)?.map((month: any) => {
        return month?.reduce((last: any, curr: any) => {
            return new Date(curr.dStr) > new Date(last?.dStr) ? curr : last
        })
    })

    return sortEntriesByDate(result)
}

const sortEntriesByDate = (entries: any): IDataChart[] => {
    return entries?.sort(function (a: any, b: any) {
        return moment.utc(a.dStr).diff(moment.utc(b.dStr))
    })
}
