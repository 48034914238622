import { t } from 'i18next'
import styles from '../components/credentialCardDetail/components/content/content.module.scss'
import {
    isArray,
    isNumber,
    isObject,
    isString,
    isBoolean,
    isStringOrNumber,
} from '../../../../utils/formatDataUtil'
import { CredContentDetailType } from '../data/requestCredentials.interfaces'
import cx from 'classnames'

// Modify content and get content details
export const removeIdAndTitle = (data: any) => {
    return (
        data &&
        [...data]?.filter(
            (el) =>
                !(
                    el?.dataType?.toLowerCase() === 'title' ||
                    el?.dataType?.toLowerCase() === 'id'
                )
        )
    )
}

export const sortContentItems = (content: CredContentDetailType[]) => {
    const currentTitleIndex = content?.findIndex((el) => el?.isTitle)
    const titleItem =
        currentTitleIndex &&
        currentTitleIndex > 0 &&
        content?.splice(currentTitleIndex, 1)
    const sortedContent =
        currentTitleIndex && currentTitleIndex > 0 && titleItem
            ? //@ts-ignore
              titleItem?.concat(content)
            : content
    return sortedContent
}

// Get detail previews
export const getPreviewOfArrayItem = (data: any) => {
    /* If the current item is an array and contains an the first child is an object,
     * return the first and second keys of the object.
     * If the first child have another type, return empty string.
     */
    let firstItem = new Array()
    firstItem = data[0]

    return isObject(firstItem)
        ? getPreviewOfObjectItem(firstItem)
        : isStringOrNumber(firstItem)
        ? firstItem
        : ''
}

// If the item is an object the first and second keys are returned.
export const getPreviewOfObjectItem = (data: any) => {
    let item = { ...data }
    let preview
    delete item?.id
    if (item?.title) {
        preview = item?.title
        preview =
            preview?.length > 37 ? preview?.substring(0, 37) + '...' : preview
    } else {
        let properties = Object.keys(item)
        const parsedProps = properties?.map((prop) => parseToTitleCase(prop))
        preview = parsedProps.slice(0, 2).join(', ')
        preview =
            preview?.length > 37
                ? preview?.substring(0, 37) + '...'
                : parsedProps.length > 2
                ? preview + ` (+${parsedProps.length - 2})`
                : preview
    }
    return preview
}

export const parseToTitleCase = (text: string) => {
    const upperCaseText = text?.toUpperCase()
    if (upperCaseText !== text) {
        let result = text.replace(/([a-z])([A-Z])/g, '$1 $2')
        result = result.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
        return finalResult
    } else {
        return text
    }
}

export const getLevelContent = (
    data: any,
    level?: number
): CredContentDetailType[] | undefined => {
    let content = new Array()

    if (isArray(data)) {
        if (!!data && data?.length > 0) {
            for (let item of data) {
                return getLevelContent(item, level)
            }
        }
    } else if (isObject(data)) {
        for (const [key, value] of Object.entries(data)) {
            if (
                isNumber(parseInt(key)) &&
                !!(isObject(value) || isArray(value))
            ) {
                return getLevelContent(value, level)
            } else {
                if (key !== 'id') {
                    // @ts-ignore
                    content.push({
                        dataType: parseToTitleCase(key),
                        // @ts-ignore
                        value: isBoolean(value) ? value?.toString() : value,
                        preview:
                            level !== -1
                                ? isArray(value)
                                    ? getPreviewOfArrayItem(value)
                                    : isObject(value)
                                    ? getPreviewOfObjectItem(value)
                                    : ''
                                : t(
                                      'ew.requestCredentials.navigationJsonPreview'
                                  ),
                        isTitle: !!(key?.toLowerCase() === 'title'),
                    })
                }
            }
        }

        return sortContentItems(content)
    } else {
        if (data !== 'id') {
            // @ts-ignore
            content.push({
                dataType: isString(data) ? data : '',
                value: undefined,
                preview: undefined,
                isTitle: !!(data && data?.toLowerCase() === 'title'),
            })
        }

        return content
    }
}

// Conditional styles
export const getCredentialDetailStyle = (
    isTitle: boolean,
    index: number,
    length?: number,
    isTable?: boolean,
    isDemoContent?: boolean
) => {
    const isLastItem = !!length && length - 1 === index
    const tableDetailStyle = !!isTitle
        ? styles.tableDetailTitleStyle
        : isLastItem
        ? cx(styles.tableLastDetailStyle, isDemoContent && styles.demoContent)
        : styles.tableDetailStyle
    const singleDetailStyle = !!isTitle
        ? styles.singleDetailTitleStyle
        : styles.singleDetailStyle

    return isTable ? tableDetailStyle : singleDetailStyle
}

export const containerStyle = (isTable?: boolean) =>
    isTable ? styles.tableContainerStyle : styles.containerStyle

// Get cred Subjects
export const getCredentialSubjects = (
    selectedCredentials: any,
    category: string
) => {
    let credential = {}
    selectedCredentials &&
        selectedCredentials
            ?.map((vc: any) => vc?.credentialSubject)
            ?.forEach((subj: any) => (credential = { ...credential, ...subj }))
    var key = category
    var credObject: Object = {}

    credObject = { [key]: credential }
    return credObject
}
