import { Epic, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, delay, map, switchMap } from 'rxjs/operators'
import dataService from '../../../services/dataService'
import { showNotification } from '../Notification/notificationActions'
import {
    getMetricFailed,
    getMetricSuccess,
    getStatisticsFailed,
    getStatisticsSuccess,
} from './statisticsActions'
import { StatisticsTypes } from './statisticsTypes'

export const getStatisticsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(StatisticsTypes.GET_ALL_STATISTICS),
        delay(100),
        switchMap((action: any): any => {
            const orgID = state$.value.sessionReducer.tokenData?.organizationId
            return from(dataService.getAllStatistics(orgID)).pipe(
                map((response?: any) => {
                    return getStatisticsSuccess(response?.response)
                }),
                catchError((error) => of(getStatisticsFailed(error)))
            )
        })
    )
}

export const getMetricEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(StatisticsTypes.GET_METRIC),
        delay(100),
        switchMap((action: any): any => {
            return from(
                dataService.requestMetricStatistic(
                    action.metricCode,
                    action.payload
                )
            ).pipe(
                map((response?: any) => {
                    return getMetricSuccess(action.code, response?.response)
                }),
                catchError((error) => of(getMetricFailed(error)))
            )
        })
    )
}
