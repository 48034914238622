import React from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './success.module.scss'
import ScanModal from '../../ScanModal'
import GaImage from '../../../../../atoms/Image/Image'
import { gaImages } from '../../../../../../assets/globalConstants'
import WarningIcon from '../../../../../../assets/iconsLibrary/simple/warning/warningIcon'

type IScanModalSuccess = {
    title: string
    description?: string
    secondDescription?: string
    alertText: string
    continueFunction: () => void
}

const ScanModalSuccess: React.FC<IScanModalSuccess> = (props) => {
    const {
        title,
        description,
        secondDescription,
        alertText,
        continueFunction,
    } = props

    return (
        <ScanModal
            hideModal={() => {}}
            alert={{ type: 'warning', text: alertText, icon: WarningIcon }}
            continueButton={{
                label: t('public.ok'),
                disabled: false,
                function: continueFunction,
            }}
        >
            <div className={styles.success}>
                <GaImage image={gaImages.greenCheckPng} />
                <p className={cx('heading6 marginTop20 ')}>{t(title)}</p>
                {description ? (
                    <p className={cx('bodyRegularMD neutral800 marginTop8')}>
                        {t(description)}
                    </p>
                ) : null}
                {secondDescription ? (
                    <p className={cx('bodyRegularMD neutral800')}>
                        {t(secondDescription)}
                    </p>
                ) : null}
            </div>
        </ScanModal>
    )
}

export default ScanModalSuccess
