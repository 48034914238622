import { ActionStatusTypes } from '../../assets/globalConstants'
import { IAppIntegrationModel, IAppIntScope } from '../../interfaces/interfaces'

// LIST
export interface IGetAppIntegrationsAction {
    type: AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS
}

export interface IGetAppIntegrationsSuccessAction {
    type: AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS_SUCCESS
    payload: {
        appIntegrations: IAppIntegrationModel[]
    }
}

export interface IGetAppIntegrationsFailed {
    type: AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS_FAILED
    payload: {
        error: string
    }
}

export interface IGetScopesAction {
    type: AppIntegrationsListActionsTypes.GET_SCOPES
}

export interface IGetScopesSuccessAction {
    type: AppIntegrationsListActionsTypes.GET_SCOPES_SUCCESS
    payload: {
        scopes: IAppIntScope[]
    }
}

export interface IGetScopesFailed {
    type: AppIntegrationsListActionsTypes.GET_SCOPES_FAILED
    payload: {
        error: string
    }
}

export interface ClearAppIntegrationsList {
    type: AppIntegrationsListActionsTypes.CLEAR_APP_INTEGRATIONS_LIST
}

export interface IDeleteAppIntegration {
    type: AppIntegrationsListActionsTypes.DELETE_APP_INTEGRATION
    payload: {
        id: string
    }
}

export interface IDeleteAppIntegrationSuccess {
    type: AppIntegrationsListActionsTypes.DELETE_APP_INTEGRATION_SUCCESS
}

export interface IDeleteAppIntegrationFailed {
    type: AppIntegrationsListActionsTypes.DELETE_APP_INTEGRATION_FAILED
    payload: {
        error: string
    }
}

export type AppIntegrationsListActions =
    | IGetAppIntegrationsAction
    | IGetAppIntegrationsSuccessAction
    | IGetAppIntegrationsFailed
    | IGetScopesAction
    | IGetScopesSuccessAction
    | IGetScopesFailed
    | IDeleteAppIntegration
    | IDeleteAppIntegrationSuccess
    | IDeleteAppIntegrationFailed
    | ClearAppIntegrationsList

export enum AppIntegrationsListActionsTypes {
    GET_APP_INTEGRATIONS = 'app_integrations/get_list',
    GET_APP_INTEGRATIONS_SUCCESS = 'app_integrations/get_list_success',
    GET_APP_INTEGRATIONS_FAILED = 'app_integrations/get_list_failed',
    GET_SCOPES = 'app_integrations/get_scopes',
    GET_SCOPES_SUCCESS = 'app_integrations/get_scopes_success',
    GET_SCOPES_FAILED = 'app_integrations/get_scopes_failed',
    DELETE_APP_INTEGRATION = 'app_integrations/delete_app_integration',
    DELETE_APP_INTEGRATION_SUCCESS = 'app_integrations/delete_app_integration_success',
    DELETE_APP_INTEGRATION_FAILED = 'app_integrations/delete_app_integration_Failed',
    CLEAR_APP_INTEGRATIONS_LIST = 'app_integrations/clear_app_integration_list',
}

export interface IAppIntegrationsListState {
    appIntegrations?: IAppIntegrationModel[]
    scopes?: IAppIntScope[]
    loadingStatus: boolean
    loadingScopesStatus: boolean
    error: string
}

export interface IAppIntegrationState {
    appIntegration?: IAppIntegrationModel
    status: ActionStatusTypes
    getSecretStatus: ActionStatusTypes
    secret?: string
    error: string
}

// UPDATE CLIENT SECRET
export interface IRequestSecretAction {
    type: ConfigAppIntegrationActionsTypes.GET_SECRET
    payload: {
        appInt: IAppIntegrationModel
    }
}

export interface IRequestSecretSuccessAction {
    type: ConfigAppIntegrationActionsTypes.GET_SECRET_SUCCESS
    payload: {
        appInt: IAppIntegrationModel
    }
}

export interface IRequestSecretFailedAction {
    type: ConfigAppIntegrationActionsTypes.GET_SECRET_FAILED
    payload: {
        error: string
    }
}

// UPDATE CLIENT
export interface ISelectAppIntegrationAction {
    type: ConfigAppIntegrationActionsTypes.SELECT_APP_INTEGRATION
    payload: {
        appIntegration: IAppIntegrationModel
    }
}

export interface IUpdateAppIntegrationAction {
    type: ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION
    payload: {
        appIntegration: IAppIntegrationModel
    }
}

export interface IUpdateAppIntegrationSuccessAction {
    type: ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION_SUCCESS
}

export interface IUpdateAppIntegrationFailedAction {
    type: ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION_FAILED
    payload: {
        error: string
    }
}

export interface ICreateAppIntegrationAction {
    type: ConfigAppIntegrationActionsTypes.CREATE_APP_INTEGRATION
    payload: {
        appIntegration: IAppIntegrationModel
    }
}

export interface IClearAppIntegration {
    type: ConfigAppIntegrationActionsTypes.CLEAR_CURRENT_APP_INTEGRATION
}

export interface IClearActionStatus {
    type: ConfigAppIntegrationActionsTypes.CLEAR_ACTION_STATUS
}

export type ConfigAppIntegrationActions =
    | IClearAppIntegration
    | ISelectAppIntegrationAction
    | IRequestSecretAction
    | IRequestSecretSuccessAction
    | IRequestSecretFailedAction
    | IUpdateAppIntegrationAction
    | IUpdateAppIntegrationSuccessAction
    | IUpdateAppIntegrationFailedAction
    | IClearActionStatus

export enum ConfigAppIntegrationActionsTypes {
    EDIT_APP_INTEGRATION = 'app_integrations/edit',
    CLEAR_CURRENT_APP_INTEGRATION = 'app_integrations/clear_form',
    SELECT_APP_INTEGRATION = 'app_integrations/select',
    GET_SECRET = 'app_integrations/get_secret',
    GET_SECRET_SUCCESS = 'app_integrations/get_secret_result',
    GET_SECRET_FAILED = 'app_integrations/get_secret_failed',
    CREATE_APP_INTEGRATION = 'app_integrations/create',
    UPDATE_APP_INTEGRATION = 'app_integrations/update',
    UPDATE_APP_INTEGRATION_SUCCESS = 'app_integrations/update_success',
    UPDATE_APP_INTEGRATION_FAILED = 'app_integrations/update_failed',
    CLEAR_ACTION_STATUS = 'app_integrations/clear_action_status',
}
