import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from './configurationForm.module.scss'
import { configVerifierSelectors } from '../..'
import GaNumberInput from '../../../../../Components/atoms/formFields/numberInput/GaNumberInput'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import GaTextArea from '../../../../../Components/atoms/formFields/textArea/GaTextArea'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import FormSection from '../../../../../Components/organisms/FormSection/FormSection'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import {
    ButtonModel,
    IDIDModel,
    IVerifierConfigModel,
} from '../../../../../interfaces/interfaces'
import { didsSelectors } from '../../../Dids'
import {
    getActiveStep,
    getAliasFromDid,
    getDidFromAlias,
} from '../../../Dids/didSelectors'
import { catalogSelectors } from '../../../../common/catalog'
import { sessionSelectors } from '../../../../common/session'
import ConfigurationCredentialPreview from '../../../../../Components/molecules/modals/CredentialPreview/components/StepsContentPreview/ConfigurationCredentialPreview'
import TemplatesFormHeader from '../../../../common/TemplatesHeader/TemplatesFormHeader'
import { getLoadingStatus } from '../../../../common/catalog/catalogSelectors'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { getResponsive } from '../../../../../utils/formUtils'

type IConfigurationFormProps = {
    verifier: IVerifierConfigModel
    canEditVerifiers?: boolean
    creatingVerifier?: boolean
    buttonFunction: (x) => void
    setValidForm: (x) => void
    buttonHeader?: ButtonModel
    buttonFunctionHeader?: (x) => void
    infoApiKeyPanel?: boolean
    onHandleChange: (step) => void
    activeStep: any
    formSteps: any
}

const ConfigurationForm: React.FC<IConfigurationFormProps> = (props) => {
    const {
        verifier,
        canEditVerifiers,
        creatingVerifier,
        buttonFunction,
        setValidForm,
        buttonHeader,
        buttonFunctionHeader,
        infoApiKeyPanel,
        onHandleChange,
        activeStep,
        formSteps,
    } = props
    const { t } = useTranslation()

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    // States
    const [formDataModified, setFormDataModified] = useState(false)

    const didOptions = useSelector(didsSelectors.getdidConfigsVms) || [
        { DidId: '', alias: '' } as IDIDModel,
    ]
    const isOnPremise = useSelector(sessionSelectors.isOnPremise)
    const serviceProviders = useSelector(
        catalogSelectors.getCatalogServiceProvider
    )
    const catalogLoadingStatus = useSelector(getLoadingStatus)
    const catalogIsLoading = !(
        catalogLoadingStatus?.status === 'finished' ||
        catalogLoadingStatus?.status === 'error'
    )
    const step = useSelector(getActiveStep)

    const getDomain = (didId) => {
        const defaultHost = process.env['REACT_APP_CONNECT_HOST']
        let domain = !(serviceProviders?.length > 0)
            ? defaultHost
            : serviceProviders?.filter((item) => {
                  return item.id?.split('#')[0] === didId?.split('#')[0]
              })[0]?.domain || defaultHost
        return domain
    }

    const updateStatus = useSelector(configVerifierSelectors.getUpdateStatus)

    const changeFormData = {
        id: verifier?.id || '',
        vmethodId: verifier?.vmethodId || '',
        sessionTTL: verifier?.sessionTTL || 300,
        domain:
            verifier?.domain ||
            (isOnPremise ? '' : getDomain(verifier?.vmethodId)),
        callback: verifier?.callback || '',
        consent_duration:
            verifier?.dataAgreementTemplate?.data_receiver?.consent_duration ||
            365,
        service: verifier?.dataAgreementTemplate?.data_receiver?.service || '',
    }

    const [formData, setFormState] = useState({
        id: verifier?.id || '',
        vmethodId: verifier?.vmethodId,
        sessionTTL: verifier?.sessionTTL || 300,
        domain:
            verifier?.domain ||
            (isOnPremise ? '' : getDomain(verifier?.vmethodId)),
        callback: verifier?.callback || '',
        consent_duration:
            verifier?.dataAgreementTemplate?.data_receiver?.consent_duration ||
            365,
        service: verifier?.dataAgreementTemplate?.data_receiver?.service || '',
    })

    const {
        id,
        vmethodId,
        service,
        domain,
        sessionTTL,
        callback,
        consent_duration,
    } = formData

    const validForm = creatingVerifier
        ? didOptions &&
          didOptions[0]?.DidId &&
          !!(id?.length && vmethodId?.length && domain?.trim()?.length)
        : !!(id?.length && vmethodId?.length && domain?.trim()?.length)

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        const currentValue = changeFormData[name as keyof typeof changeFormData]

        if (currentValue !== value) {
            !formDataModified && setFormDataModified(true)
        }
        setFormState({
            ...formData,
            [name]: value,
        })
        setValidForm(validForm)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        saveAndNext()
    }
    useEffect(() => {
        scrollToTop()
    }, [step])

    useEffect(() => {
        setValidForm(validForm)
    }, [])

    useEffect(() => {
        !creatingVerifier && setValidForm(validForm)
    }, [formData])

    useEffect(() => {
        let formatedFilledData = {
            ...formData,
            id,
            vmethodId,
            service,
            domain,
            sessionTTL: parseInt(sessionTTL?.toString()),
            callback,
        }
        if (
            JSON.stringify(formatedFilledData) ===
            JSON.stringify(changeFormData)
        ) {
            setFormDataModified(false)
        }
    }, [formData])

    useEffect(() => {
        if (updateStatus === ActionStatusTypes?.success) {
            setFormDataModified(false)
        }
    }, [updateStatus])

    useEffect(() => {}, [serviceProviders])

    const saveAndNext = () => {
        const dataAgreementTemplate = {
            ...verifier.dataAgreementTemplate,
            template_id: verifier.id?.trim(),
            data_receiver: {
                ...verifier.dataAgreementTemplate?.data_receiver,
                consent_duration: consent_duration,
            },
        }

        buttonFunction({
            ...verifier,
            id: id?.trim(),
            vmethodId,
            service,
            domain,
            sessionTTL: parseInt(sessionTTL?.toString()),
            callback,
            dataAgreementTemplate,
        })
        scrollToTop()
    }
    const saveChange = () => {
        const dataAgreementTemplate = {
            ...verifier.dataAgreementTemplate,
            template_id: verifier.id?.trim(),
            data_receiver: {
                ...verifier.dataAgreementTemplate?.data_receiver,
                consent_duration: consent_duration,
            },

            template_version: verifier?.dataAgreementTemplate?.template_version
                ? (
                      parseInt(
                          verifier?.dataAgreementTemplate?.template_version
                      ) + 1
                  ).toString()
                : '1',
        }

        buttonFunction({
            ...verifier,
            id: id?.trim(),
            vmethodId,
            service,
            domain,
            sessionTTL: parseInt(sessionTTL?.toString()),
            callback,
            dataAgreementTemplate,
        })
        scrollToTop()
    }

    const setNewDidAndDomain = (did) => {
        let newDomain = getDomain(did)

        const currentDomain = changeFormData[did as keyof typeof changeFormData]

        if (currentDomain !== newDomain) {
            !formDataModified && setFormDataModified(true)
        }
        setFormState({
            ...formData,
            vmethodId: did,
            domain: newDomain,
        })
    }

    // Responsive preview

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    return (
        <>
            <div className={styles.leftColumn}>
                <div className={styles.headerStepper}>
                    <TemplatesFormHeader
                        title={
                            creatingVerifier
                                ? 'createVerifier.title'
                                : t('editVerifier.title', {
                                      id: verifier?.id || '',
                                  })
                        }
                        description={
                            creatingVerifier
                                ? 'createVerifier.description'
                                : 'editVerifier.description'
                        }
                        buttonFunctionHeader={buttonFunctionHeader}
                        buttonHeader={buttonHeader}
                        formSteps={formSteps}
                        elementsAllignment={creatingVerifier ? false : true}
                        infoApiKeyPanel={infoApiKeyPanel}
                        titleInfoPanel={t('editVerifier.addApiKeyInfo')}
                        onHandleChange={onHandleChange}
                        activeStep={activeStep}
                        loadingProgressBar={catalogIsLoading}
                        inactiveSteps={
                            !creatingVerifier && !verifier?.credentials?.length
                                ? [2, 3]
                                : undefined
                        }
                    />
                </div>
                <div className={styles.form}>
                    <LightPanel>
                        <ScreenHeader
                            title={'editVerifier.step1.sectionTitle'}
                            subText={'editVerifier.step1.sectionDescription'}
                            buttonFunction={showModal}
                            button={
                                getResponsive(1320)
                                    ? {
                                          label: 'general.showPreview',
                                          disabled: false,
                                      }
                                    : undefined
                            }
                            buttonIsLight={true}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            className={styles.formHeader}
                        />
                        <FormSection title="">
                            <form onSubmit={onSubmit}>
                                <GaTextInput
                                    className={cx('margin-bottom')}
                                    id={'qr-identifier'}
                                    invalid={!id?.trim()?.length}
                                    labelText={t(
                                        'editVerifier.step1.qrIdentifierLabel'
                                    )}
                                    required
                                    name={'id'}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder={t(
                                        'editVerifier.step1.qrIdentifierPlaceholder'
                                    )}
                                    value={id}
                                    readOnly={!creatingVerifier}
                                />
                                <GaSelect
                                    idSelect={'vmethodId'}
                                    className={cx('margin-bottom')}
                                    value={getAliasFromDid(
                                        didOptions,
                                        vmethodId
                                    )}
                                    invalid={!vmethodId?.trim()?.length}
                                    labelText={t(
                                        'editVerifier.step1.requesterDidLabel'
                                    )}
                                    placeholder={t(
                                        'editVerifier.step1.requesterDidPlaceholder'
                                    )}
                                    required
                                    name={'vmethodId'}
                                    permissions={{
                                        scopes: ['readDids', 'readTenants'],
                                        every: true,
                                    }}
                                    isLoading={!didOptions?.length}
                                    disabled={
                                        !canEditVerifiers && !creatingVerifier
                                    }
                                    onChange={(e) => {
                                        let selected_vmid = getDidFromAlias(
                                            didOptions,
                                            e?.target?.value
                                        )
                                        setNewDidAndDomain(selected_vmid),
                                            setValidForm(validForm)
                                    }}
                                    options={didOptions.map(
                                        (did: IDIDModel) =>
                                            did?.alias || did?.DidId
                                    )}
                                />
                                <GaNumberInput
                                    className={cx('margin-bottom')}
                                    name={'sessionTTL'}
                                    helperText={t(
                                        'editVerifier.step1.durantionQRTooltip'
                                    )}
                                    label={t('editVerifier.step1.durantionQR')}
                                    onChange={(e) => {
                                        const target = e.target
                                        const value = target.value

                                        const sessionTTL = target.name

                                        const currentSession =
                                            changeFormData[
                                                sessionTTL as keyof typeof changeFormData
                                            ]

                                        if (currentSession !== value) {
                                            !formDataModified &&
                                                setFormDataModified(true)
                                        }
                                        setFormState({
                                            ...formData,
                                            sessionTTL: e.target.value,
                                        })
                                    }}
                                    readOnly={
                                        !canEditVerifiers && !creatingVerifier
                                    }
                                    value={sessionTTL}
                                />
                                <GaNumberInput
                                    className={cx('margin-bottom')}
                                    name={'consent_duration'}
                                    helperText={t(
                                        'editVerifier.step1.consentDurationTooltip'
                                    )}
                                    label={t(
                                        'editVerifier.step1.consentDuration'
                                    )}
                                    onChange={(e) => {
                                        const target = e.target
                                        const value = target.value

                                        const consentDuration = target.name

                                        const currentData =
                                            changeFormData[
                                                consentDuration as keyof typeof changeFormData
                                            ]

                                        if (currentData !== value) {
                                            !formDataModified &&
                                                setFormDataModified(true)
                                        }
                                        setFormState({
                                            ...formData,
                                            consent_duration:
                                                parseInt(e.target?.value) || 0,
                                        })
                                    }}
                                    readOnly={
                                        !canEditVerifiers && !creatingVerifier
                                    }
                                    value={consent_duration}
                                />

                                {/*
                    <GaTextInput
                        id={'domain'}
                        invalid={!domain?.trim()?.length}
                        labelText={t('editIssuance.step1.domainLabel')}
                        required={true}
                        name={'domain'}
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                        readOnly={!canEditVerifiers && !creatingVerifier}
                        placeholder={t('editIssuance.step1.domainPlaceholder')}
                        value={domain}
                    />
                    */}

                                {canEditVerifiers ||
                                    creatingVerifier ||
                                    (callback && (
                                        <GaTextInput
                                            className={cx('margin-bottom')}
                                            id={'tenant-id-name'}
                                            labelText={t(
                                                'editVerifier.step1.callback'
                                            )}
                                            name={'callback'}
                                            onChange={(e) => {
                                                handleInputChange(e)
                                            }}
                                            readOnly={
                                                !canEditVerifiers &&
                                                !creatingVerifier
                                            }
                                            placeholder={t(
                                                'editVerifier.step1.callback'
                                            )}
                                            helperText={t(
                                                'editVerifier.step1.callbackTooltip'
                                            )}
                                            value={callback}
                                        />
                                    ))}
                                <GaTextArea
                                    className={cx('margin-bottom')}
                                    disabled={
                                        !canEditVerifiers && !creatingVerifier
                                    }
                                    id={'service-serviceDescription'}
                                    labelText={t(
                                        'editVerifier.step1.serviceDescriptionLabel'
                                    )}
                                    name={'service'}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder={t(
                                        'editVerifier.step1.serviceDescriptionPlaceholder'
                                    )}
                                    readOnly={
                                        !canEditVerifiers && !creatingVerifier
                                    }
                                    value={service}
                                ></GaTextArea>
                            </form>
                        </FormSection>
                        {getResponsive(1320) ? (
                            <div className={styles.buttonContainer}>
                                <DarkButton
                                    text={
                                        canEditVerifiers && !creatingVerifier
                                            ? 'public.save'
                                            : 'editVerifier.sectionButton'
                                    }
                                    disabled={
                                        !validForm ||
                                        (!creatingVerifier && !formDataModified)
                                    }
                                    functionality={
                                        canEditVerifiers && !creatingVerifier
                                            ? saveChange
                                            : saveAndNext
                                    }
                                />
                            </div>
                        ) : null}
                    </LightPanel>
                </div>
            </div>

            <ConfigurationCredentialPreview
                creatingTemplate={creatingVerifier}
                saveButton={{
                    label:
                        canEditVerifiers && !creatingVerifier
                            ? 'public.save'
                            : 'editVerifier.sectionButton',
                    disabled:
                        !validForm || (!creatingVerifier && !formDataModified),
                    function:
                        canEditVerifiers && !creatingVerifier
                            ? saveChange
                            : saveAndNext,
                }}
                showSaveButton={canEditVerifiers || creatingVerifier}
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
            />
        </>
    )
}

export default ConfigurationForm
