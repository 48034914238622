import React from 'react'

type ProfileIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const ProfileIcon: React.FC<ProfileIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <g clipPath="url(#clip0_4010_28291)">
                <path
                    d="M25.3334 26.6667V24.8889C25.3334 23.9459 24.8417 23.0415 23.9666 22.3747C23.0914 21.7079 21.9044 21.3333 20.6667 21.3333H11.3334C10.0957 21.3333 8.90875 21.7079 8.03358 22.3747C7.15841 23.0415 6.66675 23.9459 6.66675 24.8889V26.6667"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.0001 17.3333C18.9456 17.3333 21.3334 14.9455 21.3334 12C21.3334 9.05448 18.9456 6.66666 16.0001 6.66666C13.0546 6.66666 10.6667 9.05448 10.6667 12C10.6667 14.9455 13.0546 17.3333 16.0001 17.3333Z"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.62502"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.9999 30.6667C24.1001 30.6667 30.6666 24.1002 30.6666 16C30.6666 7.89983 24.1001 1.33334 15.9999 1.33334C7.89974 1.33334 1.33325 7.89983 1.33325 16C1.33325 24.1002 7.89974 30.6667 15.9999 30.6667Z"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4010_28291">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <g clipPath="url(#clip0_4010_28313)">
                <path
                    d="M15.9999 30.6667C24.1001 30.6667 30.6666 24.1002 30.6666 16C30.6666 7.89982 24.1001 1.33333 15.9999 1.33333C7.89974 1.33333 1.33325 7.89982 1.33325 16C1.33325 24.1002 7.89974 30.6667 15.9999 30.6667Z"
                    fill={props?.color || 'currentColor' || '#1E1E20'}
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7816 30.5H18.2182C20.7491 30.116 23.0677 29.0852 25 27.5814V25C25 23.9391 24.5259 22.9217 23.682 22.1716C22.8381 21.4214 21.6935 21 20.5 21H11.5C10.3065 21 9.16193 21.4214 8.31802 22.1716C7.47411 22.9217 7 23.9391 7 25V27.5815C8.93227 29.0853 11.2508 30.116 13.7816 30.5Z"
                    fill="white"
                />
                <path
                    d="M13.7816 30.5L13.5844 31.8001C13.6497 31.81 13.7156 31.815 13.7816 31.815V30.5ZM18.2182 30.5V31.815C18.2842 31.815 18.3502 31.81 18.4154 31.8001L18.2182 30.5ZM25 27.5814L25.8076 28.6192C26.1278 28.37 26.315 27.9871 26.315 27.5814H25ZM7 27.5815H5.685C5.685 27.9872 5.87224 28.3702 6.19239 28.6193L7 27.5815ZM13.7816 31.815H18.2182V29.185H13.7816V31.815ZM18.4154 31.8001C21.1752 31.3814 23.7027 30.2573 25.8076 28.6192L24.1924 26.5437C22.4327 27.9131 20.3231 28.8506 18.021 29.1999L18.4154 31.8001ZM26.315 27.5814V25H23.685V27.5814H26.315ZM26.315 25C26.315 23.537 25.6599 22.1703 24.5556 21.1887L22.8083 23.1544C23.3919 23.6732 23.685 24.3412 23.685 25H26.315ZM24.5556 21.1887C23.4564 20.2117 21.9955 19.685 20.5 19.685V22.315C21.3914 22.315 22.2197 22.6312 22.8083 23.1544L24.5556 21.1887ZM20.5 19.685H11.5V22.315H20.5V19.685ZM11.5 19.685C10.0045 19.685 8.54357 20.2117 7.44438 21.1887L9.19166 23.1544C9.7803 22.6312 10.6086 22.315 11.5 22.315V19.685ZM7.44438 21.1887C6.34015 22.1703 5.685 23.537 5.685 25H8.315C8.315 24.3412 8.60807 23.6732 9.19166 23.1544L7.44438 21.1887ZM5.685 25V27.5815H8.315V25H5.685ZM6.19239 28.6193C8.29726 30.2574 10.8247 31.3814 13.5844 31.8001L13.9789 29.1999C11.6768 28.8506 9.56729 27.9132 7.80761 26.5438L6.19239 28.6193Z"
                    fill="white"
                />
                <path
                    d="M16.0001 17.3333C18.9456 17.3333 21.3334 14.9455 21.3334 12C21.3334 9.05448 18.9456 6.66666 16.0001 6.66666C13.0546 6.66666 10.6667 9.05448 10.6667 12C10.6667 14.9455 13.0546 17.3333 16.0001 17.3333Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="2.62502"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.9999 30.6667C24.1001 30.6667 30.6666 24.1002 30.6666 16C30.6666 7.89982 24.1001 1.33333 15.9999 1.33333C7.89974 1.33333 1.33325 7.89982 1.33325 16C1.33325 24.1002 7.89974 30.6667 15.9999 30.6667Z"
                    stroke={props?.color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.63"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4010_28313">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </>
    )
}

export default ProfileIcon
