import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    CellContentTypes,
    gaImages,
    TextAlignTypes,
} from '../../../../../assets/globalConstants'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { sessionSelectors } from '../../../../common/session'
import { certificatesScopes } from '../certificatesList/certificatesList.constants'
import styles from './certificatesTable.module.scss'
import { DeleteButton } from './certificateContent/certificateCells'
import { CertificateModel } from '../../../../../interfaces/interfaces'
import { didsScopes } from '../../../Dids/views/didList/didList.constants'
import ChevronDownIcon from '../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import CopyCell from '../../../../../Components/organisms/table/cells/copyCell/CopyCell'

export type CertificatesTableProps = {
    tableData: CertificateModel[] | undefined
    headerData: {
        key: string
        text: string
        textAlign?: TextAlignTypes
        type?: CellContentTypes
        tag?: boolean
        short?: boolean
        tagText?: string
        action?: (x?) => void
    }[]
    noResults?: boolean
    className?: string
    itemOptions?: {
        text: string
        value: string
        color?: string
    }[]
    cantChangeCertificateStatus?: boolean
    mobileItem?: ((x) => any) | ((x) => Element)
    selectItem?: (x?) => void
    deleteItem?: (x, y?, z?) => void
    handleDropdownChange: (x, y, z?) => void
    certificate?: (x?) => void
    rejectCertificate?: (x?) => void
    handleOptions: (x, y?, z?) => void
}

const CertificatesTable: React.FC<CertificatesTableProps> = (props) => {
    const { tableData, headerData, noResults, deleteItem, mobileItem } = props
    const { t } = useTranslation()
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canEdit = !!(
        availableScopes &&
        userHasScopes(
            didsScopes?.edit.concat(didsScopes.delete),
            availableScopes,
            true
        )
    )

    const canValidate = !!(
        availableScopes &&
        userHasScopes(certificatesScopes?.validate, availableScopes, true)
    )

    const headersWithPermissions = () => {
        return !canEdit
            ? headerData?.filter((el) => {
                  return el?.key !== 'actions'
              })
            : !canValidate
            ? headerData?.filter((el) => {
                  return el?.key !== 'update'
              })
            : headerData
    }

    useEffect(() => {}, [tableData])

    const getHeaderIcon = (type: CellContentTypes) => {
        let icon
        switch (type || '') {
            case CellContentTypes.date:
                icon = gaImages.clock
                break
            case CellContentTypes.action:
                icon = gaImages.arrowCircleDown
                break
            case CellContentTypes.modal:
                icon = gaImages.arrowCircleDown
                break
            case CellContentTypes.checkbox:
                icon = gaImages.checkbox
                break
            case CellContentTypes.detail:
                icon = gaImages.link
                break
            case CellContentTypes.icon:
                icon = gaImages.clock
                break
            case CellContentTypes.number:
                icon = gaImages.number
                break
            case CellContentTypes.text:
                icon = gaImages.textFile
                break
            default:
                icon = gaImages.textFile
        }
        return icon
    }
    return (
        <>
            <div className={styles.table}>
                <table className="">
                    <thead>
                        <tr>
                            {headersWithPermissions()?.map((header, idx) => (
                                <th
                                    className={cx('testAlignLeft')}
                                    key={header.key + idx + Math.random()}
                                >
                                    <div>
                                        {header.text ? (
                                            <span
                                                className={cx(
                                                    'bodyBoldCap neutral700'
                                                )}
                                            >
                                                {t(header.text)}
                                            </span>
                                        ) : null}
                                        <ChevronDownIcon
                                            color="#707074"
                                            size={16}
                                        />
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {!!tableData
                            ? tableData?.map((item, idx) => (
                                  <>
                                      <tr
                                          data-id={idx}
                                          key={idx + Math.random()}
                                          className={
                                              !!mobileItem
                                                  ? styles.table__longDisplay
                                                  : ''
                                          }
                                      >
                                          {!!item ? (
                                              <>
                                                  <CopyCell
                                                      item={item}
                                                      mainData={item?.alias}
                                                      textToCopy={item?.id}
                                                  />
                                                  {/* <CreationDate
                                                          item={item}
                                                          {...idx}
                                                      />
                                                      <Status
                                                          item={item}
                                                          {...idx}
                                                      /> */}
                                                  {canEdit && (
                                                      <DeleteButton
                                                          item={item}
                                                          {...idx}
                                                          deleteItem={
                                                              deleteItem
                                                          }
                                                      />
                                                  )}
                                              </>
                                          ) : null}
                                      </tr>
                                  </>
                              ))
                            : null}
                    </tbody>
                </table>
            </div>

            {noResults ? (
                <Description
                    className={cx('margin-top')}
                    text={t('dids.noResults')}
                />
            ) : null}
        </>
    )
}

export default CertificatesTable
