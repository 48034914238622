import React from 'react'

type HomeIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const HomeIcon: React.FC<HomeIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M4 12L16 2.66666L28 12V26.6667C28 27.3739 27.719 28.0522 27.219 28.5523C26.7189 29.0524 26.0406 29.3333 25.3333 29.3333H6.66667C5.95942 29.3333 5.28115 29.0524 4.78105 28.5523C4.28095 28.0522 4 27.3739 4 26.6667V12Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 29.3333V16H20V29.3333"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <path
                d="M4 12L16 2.66666L28 12V26.6667C28 27.3739 27.719 28.0522 27.219 28.5523C26.7189 29.0524 26.0406 29.3333 25.3333 29.3333H6.66667C5.95942 29.3333 5.28115 29.0524 4.78105 28.5523C4.28095 28.0522 4 27.3739 4 26.6667V12Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 29.3333V17C12 16.4477 12.4477 16 13 16H19C19.5523 16 20 16.4477 20 17V29.3333"
                fill="white"
            />
        </>
    )
}

export default HomeIcon
