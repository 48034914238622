import { ActionStatusTypes } from '../../assets/globalConstants'
import {
    NotificationsActions,
    NotificationsActionsTypes,
    INotificationsState,
} from './notificationsTypes'

// LIST
const initialNotificationsState: INotificationsState = {
    notificationsList: undefined,
    getNotificationListStatus: ActionStatusTypes.neutral,
    getNoticicationStatus: ActionStatusTypes.neutral,
    downloadCredStatus: ActionStatusTypes.neutral,
    deleteNotificationStatus: ActionStatusTypes.neutral,
    readNotificationsNotSeen: undefined,
    readNotifications: undefined,
    polling: false,
    pollingId: null,
}

export function notificationsReducer(
    state = initialNotificationsState,
    action: NotificationsActions
): INotificationsState {
    switch (action.type) {
        // POLLING CRED IS SENT
        case NotificationsActionsTypes.START_NOTIFICATIONS_POLLING:
            clearInterval(state.pollingId)
            return {
                ...state,
                polling: true,
                pollingId: setInterval(
                    action.payload.fetch,
                    action.payload.interval
                ),
            }
        case NotificationsActionsTypes.STOP_NOTIFICATIONS_POLLING:
            clearInterval(state.pollingId)
            return {
                ...state,
                polling: false,
                pollingId: null,
            }
        case NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                getNotificationListStatus: ActionStatusTypes.loading,
            }
        case NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notificationsList: action.payload.notifications,
                getNotificationListStatus: ActionStatusTypes.success,
            }
        case NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS_FAILED:
            return {
                ...state,
                notificationsList: undefined,
                getNotificationListStatus: ActionStatusTypes.failed,
            }
        case NotificationsActionsTypes.DOWNLOAD_CREDENTIAL:
            return {
                ...state,
                downloadCredStatus: ActionStatusTypes.loading,
            }
        case NotificationsActionsTypes.DOWNLOAD_CREDENTIAL_SUCCESS:
            return {
                ...state,
                downloadCredStatus: ActionStatusTypes.success,
            }
        case NotificationsActionsTypes.DOWNLOAD_CREDENTIAL_FAILED:
            return {
                ...state,
                downloadCredStatus: ActionStatusTypes.failed,
            }
        case NotificationsActionsTypes.DELETE_NOTIFICATION:
            return {
                ...state,
                deleteNotificationStatus: ActionStatusTypes.loading,
            }
        case NotificationsActionsTypes.DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                deleteNotificationStatus: ActionStatusTypes.success,
            }
        case NotificationsActionsTypes.DELETE_NOTIFICATION_FAILED:
            return {
                ...state,
                deleteNotificationStatus: ActionStatusTypes.failed,
            }
        case NotificationsActionsTypes.SET_READ_NOTIFICATIONS:
            return {
                ...state,
                readNotifications: action.payload.readedNotifications,
            }
        case NotificationsActionsTypes.SET_READ_NOTIFICATIONS_NOT_SEEN:
            return {
                ...state,
                readNotificationsNotSeen: action.payload.readedNotifications,
            }
        case NotificationsActionsTypes.CLEAR_NOTIFICATIONS_LIST:
            return {
                ...state,
                notificationsList: undefined,
                getNotificationListStatus: ActionStatusTypes.neutral,
                getNoticicationStatus: ActionStatusTypes.neutral,
                downloadCredStatus: ActionStatusTypes.neutral,
                deleteNotificationStatus: ActionStatusTypes.neutral,
            }
        default:
            return state
    }
}
