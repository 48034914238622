import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { gaImages } from '../../../../assets/globalConstants'
import { getApiKeys } from '../../../../features/studio/ApiKeys/apiKeysSelectors'
import { propertiesToSearchApiKeys } from '../../../../features/studio/ApiKeys/views/apiKeysList/apiKeysList.constants'
import { sandboxScopes } from '../../../../features/studio/Home/HomeComponents/sandboxModal/sandboxModalConstants'
import {
    modalNoSandboxPrimaryButton,
    modalPrimaryButton,
    modalSecondaryButton,
} from '../../../../features/studio/Issuance/issuance.constants'
import { sessionSelectors } from '../../../../features/common/session'
import { IApiKeyModel } from '../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../utils/permissionsUtil'
import Description from '../../../atoms/texts/Description/Description'
import ApiKeyCard from '../../../organisms/Cards/ApiKeyCard/ApiKeyCard'
import SearchBar from '../../searchBar/SearchBar'
import style from './addToApiKeyModal.module.scss'
import MediumModal from '../Medium/MediumModal'

type IAddToApiKeyModalProps = {
    show: boolean
    configurationId: string
    configurationDID?: string
    updateApiKeys: (x) => void
    hideModal: () => void
}

const AddToApiKeyModal: React.FC<IAddToApiKeyModalProps> = (props) => {
    const {
        hideModal,
        updateApiKeys,
        configurationId,
        show,
        configurationDID,
    } = props
    const { t } = useTranslation()

    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const apiKeysList = useSelector(getApiKeys)
    const filteredApiKeys = apiKeysList?.filter(
        (e) =>
            configurationDID?.includes(e?.did) &&
            !e?.ssi_operations?.includes(configurationId)
    )

    const canUseSandbox = !!(
        availableScopes && userHasScopes(sandboxScopes, availableScopes)
    )

    const [state, setState] = React.useState<{
        selections: IApiKeyModel[]
        searched: string
        searchedResult: IApiKeyModel[]
    }>({
        selections: [],
        searched: '',
        searchedResult: filteredApiKeys?.slice() || [],
    })

    function handleCheckboxChange(selected: IApiKeyModel[]) {
        setState({
            selections: selected,
            searched: state.searched,
            searchedResult: state.searchedResult,
        })
    }

    function handleSearchedChange(e) {
        setState({
            selections: state.selections,
            searched: state.searched,
            searchedResult: e,
        })
    }

    function addToApiKeys() {
        updateApiKeys(state.selections)
    }

    function hideModalAndClean() {
        setState({
            selections: [],
            searched: '',
            searchedResult: filteredApiKeys?.slice() || [],
        })
        hideModal()
    }

    useEffect(() => {
        setState({
            selections: [],
            searched: '',
            searchedResult: filteredApiKeys?.slice() || [],
        })
    }, [apiKeysList])

    return (
        <MediumModal
            title={'apiKeys.addToApiKey'}
            subtitle={'apiKeys.searchAvailable'}
            primaryButton={
                canUseSandbox ? modalPrimaryButton : modalNoSandboxPrimaryButton
            }
            secondaryButton={modalSecondaryButton}
            primaryButtonFunction={addToApiKeys}
            show={show}
            headerClose={true}
            handleClose={hideModalAndClean}
        >
            <div className={style.modalContainer}>
                <div className={style.modalContainer__types}>
                    <div className={style.modalContainer__types__header}>
                        <SearchBar
                            className={style.addToApiKeySearch}
                            options={filteredApiKeys || []}
                            propertiesToSearch={propertiesToSearchApiKeys}
                            icon={gaImages.searchBar}
                            border
                            handleSearchedChange={handleSearchedChange}
                            placeholder={'public.search'}
                        />
                    </div>
                    {state.searchedResult?.length > 0 ? (
                        state.searchedResult?.map((e, index) => (
                            <ApiKeyCard
                                key={index}
                                id={index === 0 ? 'topOfTheList' : e.id}
                                apiKey={e}
                                index={index}
                                selectedApiKeys={state.selections.slice()}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        ))
                    ) : (
                        <Description text={'public.noResultsForSearch'} />
                    )}
                </div>
            </div>
        </MediumModal>
    )
}

export default AddToApiKeyModal
