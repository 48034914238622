import cx from 'classnames'
import React from 'react'
import styles from './dataAgreementCard.module.scss'
import { gaColors } from '../../../../../../../assets/globalConstants'
import { Trans } from 'react-i18next'
import TrashIcon from '../../../../../../../assets/iconsLibrary/simple/trash/trashIcon'
import GaTag from '../../../../../../../Components/atoms/tag/Tag'
import ChevronRightIcon from '../../../../../../../assets/iconsLibrary/simple/chevronRight/chevronRightIcon'
import { DA_PersonalData } from '../../../../../../../interfaces/interfaces'
import moment from 'moment'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { catalogSelectors } from '../../../../../../common/catalog'
import { storedLang } from '../../../../../../../i18n'

type IDataAgreementCardProps = {
    index: number
    sharedData?: DA_PersonalData[]
    createdAt?: string
    setShowCredDetail: (x?: any) => void
    setShowDelete: (x: boolean) => void
}

const DataAgreementCard: React.FC<IDataAgreementCardProps> = (props) => {
    const { index, sharedData, createdAt, setShowCredDetail, setShowDelete } =
        props

    // Selectors
    const catalogTypes = useSelector(catalogSelectors.getCatalogTypes)

    // Functions
    const getCatalogName = (id: string) =>
        catalogTypes?.find((type) => type?.id === id)
    const translatedCatalogName = (id: string) => {
        const catalogName = getCatalogName(id)
        return storedLang === 'en'
            ? catalogName?.name?.es || catalogName?.name?.en
            : catalogName?.name?.en || id
    }

    return (
        <div className={styles.dataAgreementCard}>
            <div className={styles.dataAgreementCard__header}>
                <div>
                    <p className={cx('heading6 neutral1000')}>
                        <Trans
                            i18nKey={'ew.connections.dataAgreementIndex'}
                            values={{ index: index + 1 }}
                        />
                    </p>
                    {createdAt ? (
                        <GaTag
                            text={'ew.connections.connectedDate'}
                            extraTranslationParams={{
                                date: moment(createdAt)?.format('DD/MM/YYYY'),
                            }}
                            color={'green'}
                        />
                    ) : null}
                </div>
                <div
                    className={styles.dataAgreementCard__header__delete}
                    onClick={() => setShowDelete(true)}
                >
                    <TrashIcon size={24} color={gaColors.alert300} />
                </div>
            </div>

            <div className={cx('marginTop24', styles.dataAgreementCard__body)}>
                {sharedData?.map((el) => {
                    return (
                        <div
                            className={styles.dataAgreementCard__body__cred}
                            onClick={() => setShowCredDetail(el?.attribute_id)}
                        >
                            <div>
                                <p className={cx('buttonSM neutral1000')}>
                                    {translatedCatalogName(el?.attribute_name)}
                                </p>
                                <p className={cx('bodyRegularCap neutral700')}>
                                    {t('public.for')}{' '}
                                    {el.purposes?.map((purpose) => {
                                        return t(`public.${purpose}`)
                                    })}
                                </p>
                            </div>
                            <ChevronRightIcon size={24} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DataAgreementCard
