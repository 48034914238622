import React, { useEffect, useState, InputHTMLAttributes } from 'react'
import InputSkeleton from '../../../molecules/skeletons/inlineSkeleton/InputSkeleton'
import style from './gaTextInput.module.scss'
import cx from 'classnames'
import CopyIconInput from '../../icons/copyIconInput/CopyIconInput'
import { useTranslation } from 'react-i18next'
import { TextSizes, TooltipPosition } from '../../../../data/globalVar'
import GaHoverTooltip from '../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import CloseIcon from '../../../../assets/iconsLibrary/simple/close/closeIcon'
import { gaColors } from '../../../../assets/globalConstants'

interface GaTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    id?: string
    labelText?: string
    placeholder?: string
    helperText?: string
    extraDescription?: string
    value?: string
    readOnly?: boolean
    defaultValue?: string
    disabled?: boolean
    errorTexts?: NonNullable<React.ReactNode[]>
    invalid?: boolean
    name?: string
    required?: boolean
    className?: string
    autoComplete?: string
    copyOption?: boolean
    copyIconSize?: number
    type?: 'text' | 'url'
    isLoading?: boolean
    maxLength?: number
    minLength?: number
    ariaLabel?: string
    sucessText?: string
    textSize?: TextSizes
    tooltip?: boolean
    infoText?: string
    pattern?: string
    showErrorsBeforeTouched?: boolean
    deleteFunctionality?: (e?) => void
    onChange?: (e?) => void
    onKeyDown?: (e?) => void
}

const GaTextInput: React.FC<GaTextInputProps> = React.memo((props) => {
    const {
        id,
        labelText,
        placeholder,
        className,
        helperText,
        extraDescription,
        disabled,
        name,
        value,
        readOnly,
        defaultValue,
        errorTexts,
        invalid,
        required,
        autoComplete,
        type,
        copyOption,
        copyIconSize,
        isLoading,
        maxLength,
        minLength,
        ariaLabel,
        sucessText,
        textSize,
        tooltip,
        infoText,
        pattern,
        showErrorsBeforeTouched,
        onChange,
        onKeyDown,
        deleteFunctionality,
    } = props
    const { t } = useTranslation()

    const sizeFontClass = {
        LG: 'bodyRegularMD',
        MD: 'bodyRegularSM',
    }

    const [touched, setTouched] = useState(showErrorsBeforeTouched || false)
    const [success, setSuccess] = useState(false)
    const [missing, setMissing] = useState(false)
    const invalidErrorTexts =
        invalid &&
        errorTexts?.length &&
        (touched || showErrorsBeforeTouched) &&
        !readOnly
    const sucessTextActive = success && sucessText

    useEffect(() => {
        invalid && setTouched(false)
    }, [value])

    useEffect(() => {
        success && setSuccess(true)
    }, [value])

    useEffect(() => {
        if (touched && !readOnly) {
            required && setMissing(!value?.trim()?.length)
        }
    }, [touched, readOnly, value])

    return (
        <div
            className={`${className} ${style.gaTextInputContainer} ${
                required ? style.required : ''
            } ${readOnly ? style.readOnly : ''} ${
                copyOption ? style.copyOption : ''
            } ${disabled ? style.disabled : ''} ${
                (missing && !readOnly) || (invalid && touched && !readOnly)
                    ? style.error
                    : ''
            } ${sucessTextActive ? style.success : ''}`}
        >
            {!isLoading ? (
                <>
                    {(labelText || extraDescription) && (
                        <div className={style.topTextsContainer}>
                            {labelText ? (
                                <label
                                    className={`${cx(
                                        'bodyRegularXS neutral700'
                                    )}`}
                                >
                                    {labelText}
                                    {tooltip && infoText ? (
                                        <GaHoverTooltip
                                            info={infoText && infoText}
                                            position={TooltipPosition.right}
                                        />
                                    ) : null}
                                </label>
                            ) : null}
                            {extraDescription ? (
                                <span
                                    className={`${cx(
                                        'bodyRegularCap neutral600'
                                    )} ${style.extraDescription} ${
                                        !labelText ? style.noLabel : ''
                                    }`}
                                >
                                    {extraDescription}
                                </span>
                            ) : null}
                        </div>
                    )}
                    <input
                        className={`${cx(sizeFontClass[textSize || 'LG'])}`}
                        maxLength={maxLength}
                        minLength={minLength}
                        aria-label={
                            labelText ? labelText : ariaLabel ? ariaLabel : ''
                        }
                        readOnly={readOnly}
                        defaultValue={defaultValue}
                        disabled={disabled || false}
                        id={id ? id : labelText || '' + Math.random()}
                        onKeyDown={(e) => {
                            onKeyDown ? onKeyDown(e) : () => {}
                        }}
                        pattern={pattern}
                        onBlur={() => setTouched(true)}
                        onChange={(e) => {
                            onChange ? onChange(e) : () => {}
                        }}
                        name={name ? name : ''}
                        required={required || false}
                        autoComplete={autoComplete ? autoComplete : 'Off'}
                        placeholder={placeholder ? placeholder : ''}
                        type={type ? type : 'text'}
                        value={value || ''}
                    ></input>
                    {copyOption ? (
                        <CopyIconInput
                            size={copyIconSize}
                            value={value || ''}
                        />
                    ) : deleteFunctionality ? (
                        <div
                            className={style.deleteOption}
                            onClick={deleteFunctionality}
                        >
                            <CloseIcon size={24} color={gaColors.neutral600} />
                        </div>
                    ) : null}
                    {helperText ||
                    invalidErrorTexts ||
                    missing ||
                    sucessTextActive ? (
                        <ul
                            className={`${style.bottomTextsContainer} ${cx(
                                'marginTop4'
                            )}`}
                        >
                            {helperText ? (
                                <li
                                    key="helperText"
                                    className={`${cx(
                                        'bodyRegularCap neutral600'
                                    )}`}
                                >
                                    {helperText}
                                </li>
                            ) : null}
                            {invalidErrorTexts
                                ? errorTexts?.map((errorText) => (
                                      <li
                                          key="errorText"
                                          className={`${cx(
                                              'bodyRegularCap alert300'
                                          )}`}
                                      >
                                          {errorText}
                                      </li>
                                  ))
                                : null}

                            {missing && !readOnly ? (
                                <li
                                    key="required"
                                    className={`${cx(
                                        'bodyRegularCap alert300'
                                    )} `}
                                >
                                    {t('public.required')}
                                </li>
                            ) : null}
                            {sucessTextActive ? (
                                <li
                                    key="successText"
                                    className={`${cx(
                                        'bodyRegularCap success300'
                                    )}`}
                                >
                                    {sucessText}
                                </li>
                            ) : null}
                        </ul>
                    ) : null}
                </>
            ) : (
                <InputSkeleton
                    className={className || ''}
                    labelText={labelText}
                    required={required}
                />
            )}
        </div>
    )
})

export default GaTextInput
