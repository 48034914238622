import React from 'react'
import { t } from 'i18next'
import cx from 'classnames'
import styles from './stepsAppIntegration.module.scss'
import { IAppIntegrationModel } from '../../../../../../../interfaces/interfaces'
import EditAppIntModal from '../EditAppIntModal'
import GaTextInput from '../../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import ScreenHeader from '../../../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import { checkIfURLIsSecure } from '../../../../../../../utils/formUtils'
import ModalVerificationPagePreview from '../StepsContentPreview/ModalVerificationPagePreview'

type IVerificationPageFormProps = {
    formData: IAppIntegrationModel
    companyLogoChecked: boolean
    formsModified: boolean
    hideModal: () => void
    setCompanyLogoChecked: (x) => void
    handleInputChange: (x?) => void
    setTutorialChecked: (x) => void
    saveButtonFunction: (x) => void
    setEditAppIntCurrentStep: (x?: any) => void
}

const VerificationPageForm: React.FC<IVerificationPageFormProps> = (props) => {
    const {
        formData,
        companyLogoChecked,
        formsModified,
        hideModal,
        saveButtonFunction,
        setCompanyLogoChecked,
        handleInputChange,
        setTutorialChecked,
        setEditAppIntCurrentStep,
    } = props

    return (
        <EditAppIntModal
            hideModal={hideModal}
            saveButton={{
                label: t('appIntegrations.edit.saveChanges'),
                function: saveButtonFunction,
                disabled: !formsModified,
            }}
            onChangeFunction={setEditAppIntCurrentStep}
            cancelBackButton={{
                label: t('public.cancel'),
                function: hideModal,
            }}
            progressIndicatorOptions={2}
            progressIndicatorCurrentOption={1}
        >
            <div
                className={`${styles.bodyContainer} 
                    `}
            >
                <div className={`${styles.verificationAppIntSection}`}>
                    <div className={styles.leftColumn}>
                        <ScreenHeader
                            title={'appIntegrations.verificationPage.title'}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            className={styles.formHeader}
                        />

                        <form>
                            <div
                                className={`${cx(
                                    'marginTop24 paddingBottom24 marginBottom24'
                                )} ${styles.tutorialContainer}`}
                            >
                                <div
                                    className={`${cx('marginRight20')} ${
                                        styles.tutorialText
                                    }`}
                                >
                                    <p className={cx('buttonSM neutral1000')}>
                                        {t(
                                            'appIntegrations.verificationPage.tutorial'
                                        )}
                                    </p>

                                    <p className={cx('bodyRegularXS')}>
                                        {t(
                                            'appIntegrations.verificationPage.tutorialDesc'
                                        )}
                                    </p>
                                </div>
                                <div className={styles.activationToggle}>
                                    <input
                                        checked={formData?.display_tutorial}
                                        className={styles.activationCheckbox}
                                        type="checkbox"
                                        id="switch"
                                    />
                                    <label
                                        onClick={(e) =>
                                            setTutorialChecked(
                                                !formData?.display_tutorial
                                            )
                                        }
                                        className={
                                            styles.activationCheckboxLabel
                                        }
                                    ></label>
                                </div>
                            </div>
                            <p className={cx('heading7')}>
                                {t(
                                    'appIntegrations.verificationPage.pageLayout'
                                )}
                            </p>
                            <div
                                className={`${cx('marginTop24')} ${
                                    styles.companyLogoContainer
                                }`}
                            >
                                <div
                                    className={`${cx('marginRight20')} ${
                                        styles.companyLogoText
                                    }`}
                                >
                                    <p className={cx('buttonSM neutral1000')}>
                                        {t(
                                            'appIntegrations.verificationPage.companyLogo'
                                        )}
                                    </p>

                                    <p className={cx('bodyRegularXS')}>
                                        {t(
                                            'appIntegrations.verificationPage.companyLogoDesc'
                                        )}
                                    </p>
                                </div>
                                <div className={styles.activationToggle}>
                                    <input
                                        checked={companyLogoChecked}
                                        className={styles.activationCheckbox}
                                        type="checkbox"
                                        id="switch"
                                    />
                                    <label
                                        onClick={(e) =>
                                            setCompanyLogoChecked(
                                                !companyLogoChecked
                                            )
                                        }
                                        className={
                                            styles.activationCheckboxLabel
                                        }
                                    ></label>
                                </div>
                            </div>
                            {companyLogoChecked ? (
                                <GaTextInput
                                    className={cx('marginTop16')}
                                    id={'logo_uri'}
                                    invalid={
                                        !!formData?.logo_uri?.trim()?.length &&
                                        !checkIfURLIsSecure(
                                            formData?.logo_uri?.trim()
                                        )
                                    }
                                    showErrorsBeforeTouched
                                    errorTexts={[
                                        t(
                                            'appIntegrations.verificationPage.urlStartHttps'
                                        ),
                                    ]}
                                    labelText={t(
                                        'appIntegrations.verificationPage.urlLogo'
                                    )}
                                    type={'url'}
                                    pattern="https://.*"
                                    name={'logo_uri'}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder={t(
                                        'appIntegrations.verificationPage.urlLogoPlaceholder'
                                    )}
                                    helperText={t(
                                        'appIntegrations.verificationPage.urlLogoHelper'
                                    )}
                                    value={formData.logo_uri}
                                />
                            ) : null}
                        </form>
                    </div>
                    <ModalVerificationPagePreview
                        tutorialChecked={!!formData?.display_tutorial}
                        companyLogoChecked={companyLogoChecked}
                        companyLogo={formData?.logo_uri}
                        selectedScopes={formData?.formScopes}
                    />
                </div>
            </div>
        </EditAppIntModal>
    )
}

export default VerificationPageForm
