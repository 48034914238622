import { Epic, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { ICredentialsItemModel } from '../../../interfaces/interfaces'
import dataService from '../../../services/dataService'
import { showNotification } from '../../common/Notification/notificationActions'
import {
    deleteCredentialFailed,
    deleteCredentialSuccess,
    getCredentials,
    getCredentialsFailed,
    getCredentialsSuccess,
    getPendingCredentials,
    getPendingCredentialsFailed,
    getPendingCredentialsSuccess,
    issueCredentialFailed,
    issueCredentialSuccess,
    rejectCredentialFailed,
    rejectCredentialSuccess,
    updateCredentialFailed,
    updateCredentialSuccess,
} from './credentialsActions'
import { CredentialListActionsTypes } from './credentialsTypes'
import { hideScreenLoaderAction } from '../../common/Loader/loaderActions'

// LIST
export const getCredentialsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CredentialListActionsTypes.GET_CREDENTIALS),
        switchMap((action): any => {
            const size = action?.payload.size
            const page = action?.payload.page
            const url = action?.payload.url

            return from(dataService.getIssuedCredentials(page, size, url)).pipe(
                map((response?: any) => {
                    return getCredentialsSuccess(response.response)
                }),
                catchError((error) =>
                    of(
                        getCredentialsFailed(error),
                        showNotification(
                            'credentials.getCredentialsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const getPendingCredentialsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CredentialListActionsTypes.GET_PENDING_CREDENTIALS),
        switchMap((action): any => {
            const size = action?.payload.size
            const page = action?.payload.page
            const url = action?.payload.url

            return from(
                dataService.getPendingCredentials(page, size, url)
            ).pipe(
                mergeMap((response?: any) => {
                    return of(getPendingCredentialsSuccess(response.response))
                }),
                catchError((error) =>
                    of(
                        getPendingCredentialsFailed(error),
                        showNotification(
                            'credentials.getPendingCredentialsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const issueCredentialsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CredentialListActionsTypes.ISSUE_CREDENTIAL),
        switchMap((action): any => {
            const credID = action?.payload.id
            const subjectDID = action?.payload.subjectDID
            const selectCredential = action?.payload.credentials
            const isMulti = action?.payload.isMulti
            const type = action?.payload.credType
            const expirationDate = action?.payload.expirationDate
            const credentials = !isMulti
                ? mapToCredentials(subjectDID, selectCredential)
                : formatMultiCredentials(
                      subjectDID,
                      selectCredential,
                      type,
                      expirationDate
                  )
            return from(dataService.issueCredential(credID, credentials)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        issueCredentialSuccess(),
                        getPendingCredentials(1, 10),
                        getCredentials(1, 10),
                        hideScreenLoaderAction(),
                        showNotification(
                            'credentials.issueCredentialsSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        issueCredentialFailed(error),
                        hideScreenLoaderAction(),
                        showNotification(
                            'credentials.issueCredentialsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const rejectCredentialsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CredentialListActionsTypes.REJECT_CREDENTIAL),
        switchMap((action): any => {
            const credID = action?.payload.id
            return from(dataService.rejectCredential(credID)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        rejectCredentialSuccess(),
                        getPendingCredentials(1, 10),
                        getCredentials(1, 10),
                        showNotification(
                            'credentials.rejectCredentialsSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        rejectCredentialFailed(error),
                        showNotification(
                            'credentials.rejectCredentialsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deleteCredentialsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CredentialListActionsTypes.DELETE_CREDENTIAL),
        switchMap((action): any => {
            const credID = action.payload.credential?.id
            return from(dataService.deleteCredential(credID)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        deleteCredentialSuccess(response.response),
                        showNotification(
                            'credentials.deleteCredentialsSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        deleteCredentialFailed(error),
                        showNotification(
                            'credentials.deleteCredentialsError',
                            'error',
                            error.response?.data?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const updateCredentialStatusEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CredentialListActionsTypes.CHANGE_CREDENTIAL_STATUS),
        switchMap((action: any): any => {
            const credId = action.payload.id
            const newStatus = action.payload.status
            const pageIndex = action.payload.pageIndex
            const pageSize = action.payload.pageSize
            return from(dataService.updateCredential(credId, newStatus)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        updateCredentialSuccess(),
                        getCredentials(pageIndex, pageSize),
                        showNotification(
                            'credentials.updateCredentialStatusSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        updateCredentialFailed(error),
                        showNotification(
                            'credentials.updateCredentialStatusError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

const formatMultiCredentials = (
    id: string,
    credentials,
    typeCred: string,
    expirationDate?: string
): ICredentialsItemModel[] => {
    const formattedCredential = [{ id: id }]
    credentials.id = id

    let item = [
        {
            type: ['VerifiableCredential', typeCred],
            credentialSubject: credentials,
            expirationDate: expirationDate,
        },
    ]
    return item
}

const mapToCredentials = (id: string, credentials): ICredentialsItemModel[] => {
    let result: ICredentialsItemModel[] = []
    for (const [key, value] of Object.entries(credentials)) {
        let credentialSubject = {
            id: id,
        }
        credentialSubject[key] = value
        let item: ICredentialsItemModel = {
            type: ['VerifiableCredential', key + 'Credential'],
            credentialSubject: credentialSubject,
        }
        result.push(item)
    }
    return result
}
