import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './credentialCard.module.scss'
import {
    CredentialIcons,
    gaColors,
    gaImages,
} from '../../../../../assets/globalConstants'
import { t } from 'i18next'
import GaTag from '../../../../../Components/atoms/tag/Tag'
import ShieldCheckIcon from '../../../../../assets/iconsLibrary/shiedlCheck/shieldCheckIcon'
import { getStatusColorAndText } from '../../../../../utils/credentialsUtils'
import moment from 'moment'
import GaImage from '../../../../../interfaces/Image/Image'
import {
    CredentialGroup,
    VerifiableCredential,
} from '../../data/requestCredentials.interfaces'
import { translateCatalog } from '../../utils/formatCredUtil'
import { getStatus } from '../../utils/displayDetailsUtil'

export type ICredentialCardProps = {
    id: string
    groupId: string
    group: CredentialGroup
    groupName: Map<string, string> | string
    issuer?: string | string[]
    icon?: any
    mainClaim?: string
    securityLevel?: number
    isPrivate?: boolean
    isActive?: boolean
    inactive?: boolean
    isDisabled?: boolean
    passingOnPress?: any
    isQsign?: boolean
    date?: any
    status?: string
    vcs: VerifiableCredential[]
    expirationDate?: any
    rawExpirationDate?: any
    isExpired?: boolean
    className?: string
    isNew?: boolean
    selectCred?: (x?: any) => void
}

const CredentialCard: React.FC<ICredentialCardProps> = (props) => {
    const {
        groupId,
        groupName,
        issuer,
        mainClaim,
        securityLevel,
        vcs,
        date,
        expirationDate,
        isExpired,
        className,
        isNew,
        selectCred,
    } = props

    const [statusValue, setStatusValue] = useState<string>('')

    useEffect(() => {
        if (statusValue?.length < 2) {
            getStatus(vcs[0])
                .then((status) => {
                    setStatusValue(isExpired ? 'EXPIRED' : status)
                })
                .catch((e) => setStatusValue(''))
        }
    }, [])

    useEffect(() => {}, [statusValue])

    const showStatus =
        statusValue && statusValue !== 'ISSUED' && statusValue !== 'ACTIVE'
    const showExpirationDate =
        expirationDate &&
        !isExpired &&
        statusValue !== 'EXPIRED' &&
        (statusValue === 'ISSUED' || statusValue === 'ACTIVE')

    const formattedIssuanceDate = moment(date)?.format('MMMM DD, YYYY')
    const formattedExpirationDate = expirationDate
        ? moment(expirationDate)?.format('MMMM DD, YYYY')
        : undefined

    return (
        <div
            className={cx(styles.credentialCard, className && className)}
            onClick={() => {
                selectCred && selectCred(props)
            }}
        >
            <div className={cx(styles.credentialCard__header)}>
                <div
                    className={cx(
                        styles.credentialCard__header__left,
                        (!securityLevel || (securityLevel === 0 && !isNew)) &&
                            styles.fullWidth
                    )}
                >
                    <div>
                        <GaImage
                            className={
                                styles.credentialCard__header__left__icon
                            }
                            image={{
                                src:
                                    CredentialIcons[
                                        groupId ? groupId : 'credential'
                                    ] || CredentialIcons?.credential,
                                alt: t(gaImages.credential.alt),
                            }}
                        />
                    </div>
                    <div
                        className={cx(
                            styles.credentialCard__header__left__texts
                        )}
                    >
                        <p
                            className={cx(
                                'buttonMD neutral1000',
                                styles.textSetByWidth
                            )}
                        >
                            {typeof groupName == 'string'
                                ? t(groupName)
                                : translateCatalog(groupName) ||
                                  t('ew.requestCredentials.credential')}
                        </p>
                        <p
                            className={cx(
                                'bodyRegularSM neutral700',
                                styles.textSetByWidth
                            )}
                        >
                            {Array.isArray(issuer) ? issuer[0] : issuer}
                        </p>
                    </div>
                </div>
                {isNew ? (
                    <GaTag
                        text={'ew.requestCredentials.new'}
                        color={'pink'}
                        className={styles.newTag}
                    />
                ) : null}
                {securityLevel && securityLevel > 0 ? (
                    <div className={cx(styles.credentialCard__header__right)}>
                        <ShieldCheckIcon
                            style="filled"
                            color={gaColors.success300}
                            size={24}
                        />
                    </div>
                ) : null}
            </div>
            <div className={cx(styles.credentialCard__body)}>
                <p
                    className={cx(
                        'bodyBoldXL primary700',
                        styles.textSetByWidth
                    )}
                >
                    {mainClaim}
                </p>
            </div>
            <div className={cx(styles.credentialCard__footer)}>
                <div
                    className={cx(styles.credentialCard__footer__issuanceDate)}
                >
                    <p className={cx('buttonSM neutral1000')}>
                        {t('ew.requestCredentials.dateIssue')}
                    </p>
                    <p className={cx('bodyRegularSM neutral700')}>
                        {formattedIssuanceDate}
                    </p>
                </div>
                {showExpirationDate ? (
                    <div
                        className={cx(
                            styles.credentialCard__footer__expirationDate
                        )}
                    >
                        <p className={cx('buttonSM neutral1000')}>
                            {t('ew.requestCredentials.expirationDate')}
                        </p>
                        <p className={cx('bodyRegularSM neutral700')}>
                            {formattedExpirationDate}
                        </p>
                    </div>
                ) : showStatus ? (
                    <GaTag
                        color={getStatusColorAndText(statusValue)?.color}
                        text={getStatusColorAndText(statusValue)?.text}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default CredentialCard
