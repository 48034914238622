import React from 'react'
import style from './scrollableList.module.scss'

type IScrollableListProps = {
    hasSearch?: boolean
    className?: string
}

const ScrollableList: React.FC<IScrollableListProps> = (props) => {
    const { hasSearch, className, children } = props

    return (
        <div
            className={`${style.scrollableList} ${
                hasSearch ? style.shortList : style.longList
            } ${className ? className : ''}`}
        >
            {children}
        </div>
    )
}

export default ScrollableList
