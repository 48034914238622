import cx from 'classnames'
import { t } from 'i18next'
import jwtDecode from 'jwt-decode'
import React from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import GaTag from '../../../../../../Components/atoms/tag/Tag'
import {
    activeCredentialActions,
    gaImages,
    suspendedCredentialActions,
} from '../../../../../../assets/globalConstants'
import CheckCircleIcon from '../../../../../../assets/iconsLibrary/simple/checkCircle/checkCircleIcon'
import CopyIcon from '../../../../../../assets/iconsLibrary/simple/copy/copyIcon'
import SlashIcon from '../../../../../../assets/iconsLibrary/simple/slash/slashIcon'
import WarningIcon from '../../../../../../assets/iconsLibrary/simple/warning/warningIcon'
import { ReactComponent as BinIcon } from '../../../../../../assets/images/bin.svg'
import {
    ICredentialsActivityModel,
    ICredentialsItemModel,
    IDIDModel,
} from '../../../../../../interfaces/interfaces'
import { shortValue } from '../../../../../../utils/formatDataUtil'
import { didsSelectors } from '../../../../Dids'
import DropdownActions from '../dropdownActions/dropdownActions'
import styles from './credentialCells.module.scss'
import { getStatusColorAndText } from '../../../../../../utils/credentialsUtils'

const getIcon = (status?: string) => {
    let icon
    if (status === 'REVOKED') {
        icon = SlashIcon
    } else if (status === 'SUSPENDED') {
        icon = WarningIcon
    } else if (status === 'ACTIVE') {
        icon = CheckCircleIcon
    } else if (status === 'ISSUED') {
        icon = CheckCircleIcon
    } else if (status === 'VALIDATED') {
        icon = CheckCircleIcon
    }
    return icon
}

const getActionOptions = (status?: string) => {
    let options
    if (status === 'SUSPENDED') {
        options = suspendedCredentialActions
    } else if (
        status === 'ACTIVE' ||
        status === 'ISSUED' ||
        status === 'VALIDATED'
    ) {
        options = activeCredentialActions
    }
    return {
        options: options,
        color: options
            ? options[0]?.value === 'SUSPENDED'
                ? 'green'
                : 'yellow'
            : '',
        text: options ? options[0]?.text : '',
    }
}

const setColorClass = (color) => {
    let colorClass = 'green'
    switch (color) {
        case 'yellow':
            colorClass = styles.yellow
            break
        case 'green':
            colorClass = styles.green
            break
        case 'red':
            colorClass = styles.red
            break
        case 'gaBlue':
            colorClass = styles.gaBlue
            break
        default:
            colorClass = styles.green
    }

    return colorClass
}

export const getActionCellContent = (x, disabled) => {
    if (getActionOptions) {
        return {
            text: getActionOptions(x)?.text || x?.text,
            selectOptions: getActionOptions(x)?.options,
        }
    }
}

// CELLS COMPONENTS

export type TableContentProps = {
    item: ICredentialsActivityModel
    index?: number
    cantChangeCredentialsStatus?: boolean
    selectItem?: (x?) => void
    deleteItem?: (x, y?, z?) => void
    issueCredential?: (x?) => void
    rejectCredential?: (x?) => void
    handleDropdownChange?: (x, y, z?) => void
}

export const CredStatus: React.FC<TableContentProps> = (props) => {
    const { item, index } = props

    return (
        <td
            key={item.id + index?.toString()}
            className={`${cx('testAlignLeft')}`}
        >
            <div className={styles.credStatus}>
                <GaTag
                    leftIconComponent={getIcon(item?.data?.status)}
                    color={getStatusColorAndText(item?.data?.status)?.color}
                    text={getStatusColorAndText(item?.data?.status)?.text}
                />
            </div>
        </td>
    )
}

function getVCfromJWT(vcstr: string): ICredentialsItemModel {
    const decoded = jwtDecode(vcstr) as any
    return decoded.vc as ICredentialsItemModel
}

export const CredType: React.FC<TableContentProps> = (props) => {
    const { item, index } = props
    const formattedTypes = item?.typeNames?.slice(1 - item?.typeNames?.length)
    const types = item.data?.data?.map((vc: ICredentialsItemModel) => {
        if (typeof vc === 'string') {
            vc = getVCfromJWT(vc)
        }
        return vc.type[vc.type?.length - 1]
    })

    const currentTypes = formattedTypes?.length ? formattedTypes : types

    return (
        <td>
            <span className={cx('buttonSM neutral1000')}>
                {currentTypes?.join(', ')}
            </span>
        </td>
    )
}

export const CredIssuer: React.FC<TableContentProps> = (props) => {
    const { item, index } = props
    const dids = useSelector(didsSelectors.getDids)
    let issuer = item.data ? item.data?.data[0].issuer || '' : ''
    const didAlias =
        dids?.didConfigs?.find((did: IDIDModel) => did?.DidId === issuer)
            ?.alias || shortValue(issuer, 15, 5)
    return (
        <td>
            <div className={styles.copyCell}>
                <span className={cx('buttonSM neutral1000')}>{didAlias}</span>
            </div>
        </td>
    )
}

export const CredIssuedId: React.FC<TableContentProps> = (props) => {
    const { item, index } = props
    let proofs = item?.data?.presentationRequest?.proof || []
    let creator: string =
        proofs.length > 0 ? proofs[0].creator?.split('#')[0] || '' : ''

    return (
        <td>
            <div className={styles.copyCell}>
                <span>{shortValue(creator, 15, 5)}</span>
                <div
                    className={styles.copyCell__button}
                    onClick={() => {
                        navigator.clipboard.writeText(item.id)
                    }}
                >
                    <CopyIcon color="#1E1E20" size={16} />
                </div>
            </div>
        </td>
    )
}

export const CredPendingId: React.FC<TableContentProps> = (props) => {
    const { item, index } = props
    let proofs = item?.data?.validator?.verifiablePresentation?.proof || []
    let creator: string =
        proofs.length > 0 ? proofs[0].creator?.split('#')[0] || '' : ''
    return (
        <td>
            <div className={styles.copyCell}>
                <span>{shortValue(creator, 15, 5)}</span>
                <div
                    className={styles.copyCell__button}
                    onClick={() => {
                        navigator.clipboard.writeText(creator)
                    }}
                >
                    <CopyIcon color="#1E1E20" size={16} />
                </div>
            </div>
        </td>
    )
}

export const CredCreationDate: React.FC<TableContentProps> = (props) => {
    const { item, index } = props

    return (
        <td>
            <Moment className={cx('buttonSM neutral1000')} format="DD/MM/YYYY">
                {item?.data?.createdAt}
            </Moment>
        </td>
    )
}

export const CredStatusActions: React.FC<TableContentProps> = (props) => {
    const {
        item,
        cantChangeCredentialsStatus,
        index,
        handleDropdownChange,
        deleteItem,
        selectItem,
    } = props

    return (
        <td>
            <div className={styles.credIssuedAction}>
                <DropdownActions
                    selectOptions={
                        !cantChangeCredentialsStatus
                            ? getActionCellContent(
                                  item?.data?.status,
                                  cantChangeCredentialsStatus
                              )?.selectOptions
                            : undefined
                    }
                    item={item}
                    onHandleChange={
                        handleDropdownChange ? handleDropdownChange : () => {}
                    }
                    icon={gaImages.moreVerticalIcon}
                    iconClassName={styles.action__icon}
                    className={styles.action__iconContainer}
                    deleteItem={deleteItem}
                    seeDetails={selectItem}
                />
            </div>
        </td>
    )
}

export const CredDetailLink: React.FC<TableContentProps> = (props) => {
    const { item, index, selectItem } = props

    return (
        <td>
            <span
                onClick={() => (selectItem ? selectItem(item) : null)}
                className={cx('primary700 buttonSM', styles.detailLink)}
            >
                {t('credentials.detailData')}
            </span>
        </td>
    )
}

export const CredDeleteButton: React.FC<TableContentProps> = (props) => {
    const { item, index, deleteItem } = props

    return (
        <td>
            {deleteItem ? (
                <BinIcon
                    className={styles.bin}
                    onClick={() => deleteItem(item)}
                />
            ) : null}
            {/* TODO: Uncomment when table has more than one option available
        <DropdownTable
        setActive={setIsDropdownActive}
        onHandleChange={handleOptions}
        selectOptions={tableItemOptions}
        item={item}
        left={true}
        noLayer={true}
        icon={gaImages.infoTable}
        iconClassName={styles.action__icon}
        className={styles.action__iconContainer}
      /> */}
        </td>
    )
}

export const CredGroup: React.FC<TableContentProps> = (props) => {
    const { item, index } = props
    let proofs = item?.data?.presentationRequest?.proof || []
    let creator: string =
        proofs.length > 0 ? proofs[0].creator?.split('#')[0] || '' : ''
    return (
        <td>
            <span className={cx('neutral1000 buttonSM')}>
                {item?.data?.group}
            </span>
            <>
                <p className={cx('bodyRegularSM neutral700 width100')}>
                    {shortValue(creator, 15, 5)}
                    <span
                        className={styles.copyCell__button}
                        onClick={() => {
                            navigator.clipboard.writeText(item.id)
                        }}
                    >
                        <CopyIcon color="#1E1E20" size={16} />
                    </span>
                </p>
            </>
        </td>
    )
}

export const CredIssueRejectButtons: React.FC<TableContentProps> = (props) => {
    const { item, index, issueCredential, rejectCredential } = props

    return (
        <td className={styles.pendingAction}>
            <div className={styles.pendingAction__content}>
                {issueCredential ? (
                    <div
                        className={`${styles.pendingAction__content__button} ${styles.issue}`}
                        onClick={() =>
                            issueCredential ? issueCredential(item) : ''
                        }
                    >
                        <GaTag color={'green'} text={'✓'} />
                    </div>
                ) : null}
                {rejectCredential ? (
                    <div
                        className={`${styles.pendingAction__content__button} ${styles.reject}`}
                        onClick={() =>
                            rejectCredential ? rejectCredential(item) : ''
                        }
                    >
                        <GaTag color={'red'} text={'✕'} />
                    </div>
                ) : null}
            </div>
        </td>
    )
}
