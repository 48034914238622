import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

type IAuthProps = {}

const Auth: React.FC<IAuthProps> = ({}) => {
    const [count, setCount] = useState(0)
    let { path } = useRouteMatch()
    const search = useLocation().search
    const user = new URLSearchParams(search).get('user')
    const token = new URLSearchParams(search).get('token')
    const state = new URLSearchParams(search).get('state')

    useEffect(() => {}, [])

    const success = () => {
        !!user ? sessionStorage.setItem('user-email', user) : null
        setTimeout(() => {
            window.close()
        }, 1000)
    }

    const closeWindow = () => {
        window.close()
    }

    const failed = () => {
        setTimeout(() => {
            window.close()
        }, 1000)
    }

    return (
        <React.Fragment>
            <Switch>
                {/*<Redirect exact from="/welcome" to="/welcome/company" />*/}
                {!!user && (
                    <Route exact path={`${path}/success`}>
                        {success()}
                        <div>Sign In Successull!</div>
                        <div>
                            If it does not redirect and close click to close the
                            window.
                        </div>
                        <button onClick={() => closeWindow()}>Close</button>
                    </Route>
                )}
                {!user && (
                    <Route exact path={`${path}/failed`}>
                        <div>Sign in Failed</div>
                        <div>
                            If it does not redirect and close click to close the
                            window.
                        </div>
                        <button onClick={() => closeWindow()}>Close</button>
                        {/* TODO: Uncomment when login qrCode is implemented
            <a href={iframeURI}>
              <button>Retry</button>
            </a> */}
                        {failed()}
                    </Route>
                )}
            </Switch>
        </React.Fragment>
    )
}

Auth.defaultProps = {}

export default Auth
