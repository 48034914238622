import React, { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import './App.scss'
import { ActionStatusTypes } from './assets/globalConstants'
import { ApiKeysList } from './features/studio/ApiKeys/views/apiKeysList/ApiKeysList'
import ConfigApiKey from './features/studio/ApiKeys/views/configApiKey/ConfigApiKey'
import CreateApiKey from './features/studio/ApiKeys/views/createApiKey/CreateApiKey'
import Auth from './features/common/Auth/Auth'
import ConfigDid from './features/studio/Dids/views/configDid/ConfigDid'
import CreateDid from './features/studio/Dids/views/createDid/CreateDid'
import DidsList from './features/studio/Dids/views/didList/DidList'
import Home from './features/studio/Home/Home'
import ConfigIssuance from './features/studio/Issuance/views/configIssuance/ConfigIssuance'
import CreateIssuance from './features/studio/Issuance/views/createIssuance/CreateIssuance'
import EmailLogin from './features/common/Login/EmailLogin/EmailLogin'
import EmailSignUp from './features/common/Login/EmailSignUp/EmailSignUp'
import ForgetPassword from './features/common/Login/ForgetPassword/ForgetPassword'
import Login from './features/common/Login/Login'
import ResetPassword from './features/common/Login/ResetPassword/ResetPassword'
import { getSignUpStatus } from './features/common/Login/loginSelectors'
import PanelScafoldingPrivate from './features/common/PanelScafolding/private/PanelScafoldingPrivate'
import { setSelectedRoute } from './features/common/PanelScafolding/scafoldingActions'
import IssuersList from './features/studio/Services/views/issuersList/IssuersList'
import VerifiersList from './features/studio/Services/views/verifiers/VerifiersList'
import Status from './features/common/Status'
import ConfigUser from './features/common/Team/tabsContent/users/components/configUser/ConfigUser'
import CreateUser from './features/common/Team/tabsContent/users/components/createUser/CreateUser'
import ConfigVerifier from './features/studio/Verifier/views/configVerifier/ConfigVerifier'
import Consents from './features/studio/Verifier/views/consentsTable/ConsentsView'
import CreateVerifier from './features/studio/Verifier/views/createVerifier/CreateVerifier'
import Welcome from './features/common/Welcome/Welcome'
import CertificatesList from './features/studio/certificates/views/certificatesList/CertificatesList'
import CredentialList from './features/studio/credentials/views/credentialsList/CredentialsList'
import Subscriptions from './features/common/licenses/views/Licenses'
import ChangeSubscription from './features/common/licenses/views/changeSubscription/ChangeSubscription'
import Billing from './features/common/organization/views/billing/Billing'
import Organization from './features/common/organization/views/general/Organization'
import {
    deleteSession,
    readSession,
} from './features/common/session/sessionActions'
import {
    getSessionRead,
    getUserEmail_s,
    getUserOrganization,
} from './features/common/session/sessionSelectors'
import Profile from './features/common/session/views/Profile'
import './i18n'
import i18n from './i18n'
import { history } from './services/history'
import auth from './services/auth'
import {
    getLicenseFromPath,
    isLicenseSelectedFromWeb,
} from './utils/licensesUtil'
import { Team } from './features/common/Team/views/Team'
import { RequestCredentials } from './features/wallet/requestCredentials/views/RequestCredentials'
import { Connections } from './features/wallet/connections/views/Connections'
import AppIntegrationsList from './features/studio/AppIntegrations/views/appIntegrationsList/AppIntegrationsList'
import CreateAppIntegration from './features/studio/AppIntegrations/views/createAppIntegration/createAppIntegration'

const App: React.FC<any> = () => {
    const dispatch = useDispatch()
    const isRegistrationInProccess =
        useSelector(getSignUpStatus) === ActionStatusTypes.success
    const user = useSelector(getUserEmail_s)
    const dataloaded = useSelector(getSessionRead)

    const goToHome = () => {
        dispatch(setSelectedRoute('home'))
        history.push('/home')
    }
    const goToLoginAndDeleteSession = () => {
        dispatch(setSelectedRoute('login'))
        history.push('/login')
        dispatch(deleteSession())
    }

    const initApp = async () => {
        if (
            dataloaded &&
            history.location?.pathname !== '/status' &&
            !isRegistrationInProccess
        ) {
            !!user ? goToHome() : goToLoginAndDeleteSession()
        }
    }

    useEffect(() => {
        const choosenLicense = getLicenseFromPath()
        isLicenseSelectedFromWeb() && auth?.setChoosenLicense(choosenLicense)

        if (!dataloaded) {
            dispatch(readSession())
        }
        initApp()
    }, [dataloaded])

    return (
        <Router history={history}>
            <I18nextProvider i18n={i18n}>
                <Switch>
                    <Redirect exact from="/" to="/home" />
                    <Route path="/login" exact component={Login} />
                    <Route path="/status" exact component={Status} />
                    <Route path="/email-login" component={EmailLogin} />
                    <Route path="/email-signup" component={EmailSignUp} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/forget-password" component={ForgetPassword} />
                    <Route path="/welcome" component={Welcome} />
                    <Route path="/auth" component={Auth} />
                    <PanelScafoldingPrivate>
                        <Route path="/home" exact component={Home} />
                        <Route
                            path="/issuance_templates"
                            exact
                            component={IssuersList}
                        />
                        <Route
                            path="/verification_templates"
                            exact
                            component={VerifiersList}
                        />
                        <Route
                            path="/verified_users"
                            exact
                            component={Consents}
                        />
                        <Route
                            path="/create-verifier"
                            exact
                            component={CreateVerifier}
                        />
                        <Route
                            path="/config-verifier"
                            exact
                            component={ConfigVerifier}
                        />
                        <Route
                            path="/create-issuer"
                            exact
                            component={CreateIssuance}
                        />
                        <Route
                            path="/config-issuer"
                            exact
                            component={ConfigIssuance}
                        />
                        <Route
                            path="/app-integrations"
                            exact
                            component={AppIntegrationsList}
                        />
                        <Route
                            path="/create-app-integration"
                            exact
                            component={CreateAppIntegration}
                        />
                        <Route path="/api_keys" exact component={ApiKeysList} />
                        <Route
                            path="/create-apikey"
                            exact
                            component={CreateApiKey}
                        />
                        <Route
                            path="/config-apikey"
                            exact
                            component={ConfigApiKey}
                        />
                        <Route path="/dids" exact component={DidsList} />
                        <Route path="/create-did" exact component={CreateDid} />
                        <Route path="/config-did" exact component={ConfigDid} />
                        <Route
                            path="/credentials"
                            exact
                            component={CredentialList}
                        />
                        <Route
                            path="/certificates"
                            exact
                            component={CertificatesList}
                        />
                        {/* SETTINGS PATHS */}
                        <Route
                            path="/create-user"
                            exact
                            component={CreateUser}
                        />
                        <Route
                            path="/config-user"
                            exact
                            component={ConfigUser}
                        />
                        <Route
                            path="/organization"
                            exact
                            component={Organization}
                        />
                        <Route path="/settings" exact component={Profile} />
                        <Route path="/billing" exact component={Billing} />
                        <Route
                            path="/subscription"
                            exact
                            component={Subscriptions}
                        />
                        <Route
                            path="/change-subscription"
                            exact
                            component={ChangeSubscription}
                        />
                        <Route path="/team" exact component={Team} />
                        {/* WALLET PATHS */}
                        <Route
                            path="/wallet"
                            exact
                            component={RequestCredentials}
                        />
                        <Route
                            path="/connections"
                            exact
                            component={Connections}
                        />
                    </PanelScafoldingPrivate>
                </Switch>
            </I18nextProvider>
        </Router>
    )
}

export default App
