import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { credentialsSelectors } from '../..'
import Modal from '../../../../../Components/molecules/modals/Base/Modal'
import { ICredentialsItemModel } from '../../../../../interfaces/interfaces'
import styles from './dataModal.module.scss'
import MediumModal from '../../../../../Components/molecules/modals/Medium/MediumModal'

type ICredentialDataModalProps = {
    show: boolean
    hideModal: () => void
}

type IDataModalProps = ICredentialDataModalProps & {
    selectedCredentials: ICredentialsItemModel[]
}

// Get view of each type according to its type
const createListView = (markupArray, items, deep?: number) => {
    let counter = deep
    counter = deep ? deep + 1 : 1
    if (!counter || counter < 8) {
        switch (true) {
            case isObject(items):
                if (items?.title) {
                    markupArray.push(`<h5> ${items.title}</h5>`)
                }
                getObjectItemView(markupArray, items, counter)
                break
            case isStringOrNumber(items):
                markupArray.push(`<div><span> ${items}</span></div>`)
                break
            case isArray(items):
                markupArray.push('<ul>')
                items.forEach((item) => {
                    createListView(markupArray, item, deep)
                })
                markupArray.push('</ul>')
                break
            default:
                markupArray.push(`<div><span> ${items}</span></div>`)
                break
        }
    }
    return markupArray
}

/*
 * Get elements to show from an object
 * Create a list item <li> by object.
 * Add inside a list <ul> by array or object nested or span by string nested.
 */
const getObjectItemView = (markupArray, items, deep?: number) => {
    for (let item in items) {
        let show = false

        // The title property is discarded since it has a different presentation
        // if(isArray(items[item])
        if (
            item !== 'title' &&
            item !== items.title &&
            !isNumber(parseInt(item))
        ) {
            markupArray.push(
                `<li id=${item + deep?.toString()}> <div ><p>${item}</p>`
            )
        }

        // If the child has nested content, add a plus or caplock icon according to the level (since 5 the content i slocked)
        if (isArray(items[item]) || isObject(items[item])) {
            if (!isNumber(parseInt(item))) {
                markupArray.push(
                    !deep || deep + 1 < 9
                        ? `<button active class='button'
          onClick="
            this.innerHTML = this.innerHTML !== '+' ? '+' : '-';
            this.parentNode.parentElement.style.overflow = this.parentNode.parentElement.style.maxHeight === 'none' ? 'hidden' : 'scroll';
            this.parentNode.parentElement.style.maxHeight = this.parentNode.parentElement.style.maxHeight === 'none' ? '60px' : 'none';
            this.parentNode.parentElement.className = this.parentNode.parentElement.className !== 'active' ? 'active' : 'inactive'
          "
        >+</button>
        <span>${getPreview(items[item])}</span></div>`
                        : `<button disabled><p>Content locked</p><img src="https://cdn.iconscout.com/icon/free/png-256/lock-3114529-2598161.png" width="20" height="20"></button></div>`
                )
            }

            // Get view of each nested item */
            getObjectItemChildsView(markupArray, items, item, show, deep)
        } else if (
            item !== 'title' &&
            item !== items.title &&
            items.title !== items[item]
        ) {
            markupArray.push(`<div><span> ${items[item]}</span></div>`)
        }
        markupArray.push(!isNumber(parseInt(item)) ? '</li>' : '')
    }
}

// Get view of each item inside another
const getObjectItemChildsView = (
    markupArray,
    items,
    item,
    show,
    deep?: number
) => {
    markupArray.push(`<ul class=${show ? 'hidden' : 'showed'}>`)
    createListView(markupArray, items[item], deep)
    markupArray.push('</ul>')
}

// Get type functions
const isArray = (item) => !!Array.isArray(item)

const isStringOrNumber = (item) => typeof item === 'string' || 'number'

const isNumber = (item) => typeof item === 'number' && !Number.isNaN(item)

const isObject = (item) => {
    for (let keys in item) {
        if (typeof item[keys] === 'object' && item[keys] !== null) {
            return true
        } else {
            return (
                item.toString() === '[object Object]' ||
                typeof item === 'object'
            )
        }
    }
}

// Get Preview of content in neested items
let previewContent

const getPreview = (data) => {
    previewContent =
        isArray(data) && data?.length
            ? getPreviewOfArrayItem(data)
            : getPreviewOfObjectItem(data)

    return previewContent
}

/* If the current item is an array and contains an the first child is an object,
 * return the first and second keys of the object.
 * If the first child have another type, return empty string.
 */
const getPreviewOfArrayItem = (data) => {
    let firstItem = new Array()
    firstItem = data[0]

    return isObject(firstItem) ? getPreviewOfObjectItem(firstItem) : ''
}

/* If the item is an object the first and second keys are returned. */
const getPreviewOfObjectItem = (data) => {
    let firstTwoItems
    let item = { ...data }
    delete item?.title
    let properties = Object.keys(item)
    firstTwoItems =
        properties.slice(0, 2).join(', ') + (properties.length > 2 ? '...' : '')

    return firstTwoItems
}

const CredentialsDataModal: React.FC<ICredentialDataModalProps> = (props) => {
    const selectedCredential = useSelector(
        credentialsSelectors.getSelectedCredential
    )

    const selectedCredData = selectedCredential?.data

    const getCredentialsFromValidator = (): ICredentialsItemModel[] => {
        return (
            selectedCredData?.validator?.verifiablePresentation
                ?.verifiableCredential || []
        )
    }

    const getCredentialsFromData = (): ICredentialsItemModel[] => {
        return selectedCredData?.data || []
    }

    const detailToShow: ICredentialsItemModel[] =
        selectedCredData?.status !== 'READY'
            ? getCredentialsFromData()
            : getCredentialsFromValidator()

    return (
        detailToShow && (
            <DataModal selectedCredentials={detailToShow} {...props} />
        )
    )
}

const DataModal: React.FC<IDataModalProps> = (props) => {
    const [markupArray, setMarkupArray] = useState(new Array())
    const { hideModal, show, selectedCredentials } = props

    const getCredentialSubjects = () => {
        let credential = {}
        selectedCredentials
            ?.map((vc) => vc.credentialSubject)
            ?.forEach((subj) => (credential = { ...credential, ...subj }))
        return credential
    }

    useEffect(() => {
        if (markupArray.length === 0) {
            let data = getCredentialSubjects()
            const data2 = createListView(markupArray, data)
            setMarkupArray(data2)
        }
    }, [markupArray, selectedCredentials])

    useEffect(() => {}, [markupArray])

    function hideModalAndClean() {
        hideModal()
    }

    return (
        <Modal
            title={'public.detailData'}
            subtitle={''}
            headerClose={true}
            show={show}
            handleClose={hideModalAndClean}
        >
            <div
                className={styles.detailContainer}
                dangerouslySetInnerHTML={{
                    __html: markupArray?.join(''),
                }}
            ></div>
        </Modal>
    )
}

const DataUsersModal: React.FC<IDataModalProps> = (props) => {
    let markupArray = new Array()
    const { hideModal, show, selectedCredentials } = props

    const getCredentialSubjects = () => {
        let credential = {}
        selectedCredentials
            ?.map((vc) => vc?.credentialSubject)
            ?.forEach((subj) => (credential = { ...credential, ...subj }))
        return credential
    }

    const data = getCredentialSubjects()
    createListView(markupArray, data)

    function hideModalAndClean() {
        hideModal()
        markupArray = new Array()
    }

    return (
        <MediumModal
            title={'credentials.userValidationData'}
            description={''}
            headerClose={true}
            show={show}
            hideFooter
            handleClose={hideModalAndClean}
        >
            <div
                className={styles.detailContainer}
                dangerouslySetInnerHTML={{
                    __html: markupArray?.join(''),
                }}
            ></div>
        </MediumModal>
    )
}

export default DataModal
export { CredentialsDataModal, DataUsersModal }
