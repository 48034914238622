import { Selectable } from '../../../../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import { RootState } from '../../../../../../store/indexReducers'

export const getRoles = (state: RootState) => state.rolesReducer.roles
export const loadingStatus = (state: RootState) =>
    state.rolesReducer.rolesLoadingStatus
export const scopesLoadingStatus = (state: RootState) =>
    state.rolesReducer.rolesLoadingStatus
export const getSelectedRole = (state: RootState) =>
    state.rolesReducer.selectedRole
export const getScopes = (state: RootState) => state.rolesReducer.scopes
export const getScopesSelectables = (state: RootState) =>
    getSelectableScopes(state.rolesReducer.scopes)

export const getStatus = (state: RootState) => state.rolesReducer.status
export const getError = (state: RootState) => state.rolesReducer.error

const getSelectableScopes = (scopes): Selectable[] => {
    return (
        scopes?.map((sc) => {
            return { key: sc, value: sc }
        }) || []
    )
}
