import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FormPanel from '../../../Components/FormPanel/FormPanel'
import GaSelect from '../../../Components/atoms/formFields/select/GaSelect'
import GaTextInput from '../../../Components/atoms/formFields/textInput/GaTextInput'
import {
    ActionStatusTypes,
    brandLabels,
    countriesAndStates,
    domains,
} from '../../../assets/globalConstants'
import { onPremise } from '../../../data/globalVar'
import { initialLang } from '../../../i18n'
import {
    hideScreenLoaderAction,
    showScreenLoaderAction,
} from '../Loader/loaderActions'
import { default as ScreenLoader } from '../Loader/screenLoader/ScreenLoader'
import { getSignUpStatus } from '../Login/loginSelectors'
import { setSelectedRoute } from '../PanelScafolding/scafoldingActions'
import { createOrgAction } from '../organization/organizationActions'
import { creatingOrgLoading } from '../organization/organizationSelectors'
import { sessionSelectors } from '../session'
import { deleteSession, setSession } from '../session/sessionActions'
import styles from './Welcome.module.scss'
import {
    backButton,
    continueButton,
    orgServicesEndpoints,
} from './welcomeConstants'
import {
    defaultCountry,
    defaultCountryByBrand,
    defaultCountryIndexByBrand,
} from '../../../utils/formUtils'
import GaCheckbox from '../../../Components/atoms/formFields/checkbox/GaCheckbox'

type IWelcomeProps = {}

const Welcome: React.FC<IWelcomeProps> = ({}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const history = useHistory()
    const userName = useSelector(sessionSelectors.getUserEmail_s)
    const isCreateOrgLoading = useSelector(creatingOrgLoading)
    const isRegistrationInProccess =
        useSelector(getSignUpStatus) === ActionStatusTypes.success
    const tokenExpiration = useSelector(sessionSelectors.getTokenExpiration)
    const tokenOrg = useSelector(sessionSelectors.getUserOrganization)

    const goToHome = () => {
        dispatch(setSelectedRoute('home'))
        history.push('/home')
    }

    // This viewis hidden and will be deleted when the new onboarding has been tested
    const goToCreateOrg = () => {}

    const goToLoginAndDeleteSession = () => {
        dispatch(setSelectedRoute('login'))
        dispatch(deleteSession())
        history.push('/login')
    }

    const initApp = async () => {
        if (!!tokenExpiration && !isRegistrationInProccess) {
            !!tokenOrg ? goToHome() : goToCreateOrg()
        }
    }

    useEffect(() => {
        if (!isCreateOrgLoading) {
            initApp()
        }
    }, [])

    useEffect(() => {
        if (!isCreateOrgLoading) {
            dispatch(hideScreenLoaderAction())
        }
    }, [isCreateOrgLoading])

    const [formData, setFormState] = useState({
        name: '',
        legalName: '',
        domainName: domains ? domains[0]?.value : '',
        services: orgServicesEndpoints,
        VATRegistration: '',
        discoveryURL: '',
    })

    const defaultStates = defaultCountryByBrand?.states
    const [states, setStates] = useState(defaultStates)

    const [legalAddres, setLegalAddressState] = useState({
        street: '',
        suite: '',
        number: '',
        locality: '',
        region: states ? states[0] : '',
        country: defaultCountry(defaultCountryIndexByBrand),
        postalCode: '',
    })

    const {
        name,
        legalName,
        domainName,
        services,
        VATRegistration,
        discoveryURL,
    } = formData
    const { street, suite, number, locality, region, country, postalCode } =
        legalAddres
    const [aceptedConditions, setConditionsState] = useState(false)

    const validForm = !!(
        name.trim()?.length &&
        legalName.trim()?.length &&
        domainName.trim()?.length &&
        discoveryURL.trim()?.length &&
        street.trim()?.length &&
        number.trim()?.length &&
        locality.trim()?.length &&
        region.trim()?.length &&
        country.trim()?.length &&
        postalCode.trim()?.length &&
        VATRegistration.trim()?.length &&
        aceptedConditions
    )

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formData,
            [name]: value,
        })
    }

    const handleAddressInputsChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setLegalAddressState({
            ...legalAddres,
            [name]: value,
        })
    }

    const getStates = (name: string) => {
        setStates([])
        const selectedItem = countriesAndStates.find((el) => {
            return (
                name === el.name_en ||
                name === el.name_es ||
                name === el.name_pt
            )
        })

        setStates(selectedItem?.states || [])
    }

    function submitData() {
        dispatch(showScreenLoaderAction())
        const formattedOrg = buildDataObject()
        // Just in cloud for now
        // !onPremise && dispatch(createOrgAction(formattedOrg))
    }

    const buildDataObject = () => {
        const data = {
            id: legalName,
            providerId: 'gataca',
            orgInfo: {
                name: name,
                domainName: domainName,
                discoveryURL: discoveryURL,
                services: services,
            },
            billingInfo: {
                legalAddress: {
                    street,
                    suite,
                    number,
                    locality,
                    region,
                    country,
                    postalCode,
                },
                LegalName: legalName,
                vatRegistration: VATRegistration,
            },
        }
        return data
    }

    return (
        <>
            <FormPanel
                title={'subscription.organizationDetails'}
                primaryButton={continueButton}
                legend={'welcome.description'}
                primaryFunction={submitData}
                primaryButtonDisabled={!validForm || !!isCreateOrgLoading}
                secondaryButton={backButton}
                secondaryFunction={goToLoginAndDeleteSession}
            >
                <GaTextInput
                    name="name"
                    invalid={false}
                    labelText={t('welcome.companyName')}
                    onChange={(e) => handleInputChange(e)}
                    placeholder={t('welcome.companyName')}
                    type="text"
                    value={name}
                    required
                />
                <GaTextInput
                    name="legalName"
                    invalid={false}
                    labelText={t('billing.legalName')}
                    onChange={(e) => handleInputChange(e)}
                    placeholder={t('billing.legalName')}
                    type="text"
                    value={legalName}
                    required
                />
                <div className={styles.doubleColumn}>
                    <GaTextInput
                        name="discoveryURL"
                        invalid={false}
                        labelText={t('login.website')}
                        onChange={(e) => handleInputChange(e)}
                        placeholder={t('login.website')}
                        type="text"
                        value={discoveryURL}
                        required
                    />
                    <GaSelect
                        idSelect={'domainName'}
                        value={domainName}
                        name="domainName"
                        invalid={false}
                        isLoading={!domains?.length}
                        labelText={t('welcome.domainName')}
                        onChange={(e) => handleInputChange(e)}
                        placeholder={t('welcome.domainName')}
                        optionTextProperty={'text'}
                        optionValueProperty={'value'}
                        options={domains}
                    />
                </div>

                <GaTextInput
                    name="street"
                    invalid={false}
                    labelText={t('welcome.street')}
                    onChange={(e) => handleAddressInputsChange(e)}
                    placeholder={t('welcome.street')}
                    type="text"
                    value={street}
                    required
                />
                <div className={styles.doubleColumn}>
                    <GaTextInput
                        name="number"
                        invalid={false}
                        labelText={t('welcome.number')}
                        onChange={(e) => handleAddressInputsChange(e)}
                        placeholder={t('welcome.number')}
                        type="text"
                        value={number}
                        required
                    />
                    <GaTextInput
                        name="suite"
                        invalid={false}
                        labelText={t('welcome.suite')}
                        onChange={(e) => handleAddressInputsChange(e)}
                        placeholder={t('welcome.suite')}
                        type="text"
                        value={suite}
                    />
                </div>
                <div className={styles.doubleColumn}>
                    <GaSelect
                        idSelect={'country'}
                        value={country}
                        name="country"
                        invalid={false}
                        isLoading={!countriesAndStates?.length}
                        labelText={t('welcome.country')}
                        optionValueProperty={
                            initialLang ? 'name_' + initialLang : 'name_es'
                        }
                        optionTextProperty={
                            initialLang ? 'name_' + initialLang : 'name_es'
                        }
                        onChange={(e) => {
                            handleAddressInputsChange(e),
                                getStates(e.target.value)
                        }}
                        options={countriesAndStates}
                    />
                    <GaSelect
                        idSelect={'region'}
                        value={region}
                        name="region"
                        invalid={false}
                        isLoading={!states?.length}
                        labelText={t('welcome.state')}
                        onChange={(e) => handleAddressInputsChange(e)}
                        options={states}
                    />
                </div>
                <div className={styles.doubleColumn}>
                    <GaTextInput
                        name="locality"
                        invalid={false}
                        labelText={t('welcome.locality')}
                        onChange={(e) => handleAddressInputsChange(e)}
                        placeholder={t('welcome.locality')}
                        type="text"
                        value={locality}
                        required
                    />

                    <GaTextInput
                        name="postalCode"
                        invalid={false}
                        labelText={t('welcome.postalCode')}
                        onChange={(e) => handleAddressInputsChange(e)}
                        placeholder={t('welcome.postalCode')}
                        type="text"
                        value={postalCode}
                        required
                    />
                </div>
                <GaTextInput
                    name="VATRegistration"
                    invalid={false}
                    labelText={t('welcome.nif')}
                    onChange={(e) => handleInputChange(e)}
                    placeholder={t('welcome.nif')}
                    type="text"
                    value={VATRegistration}
                    required
                />
                <div className={styles.terms}>
                    <GaCheckbox
                        index={'0'}
                        name="accept_terms"
                        checked={aceptedConditions}
                        onChange={() => setConditionsState(!aceptedConditions)}
                        className={`${styles.required} ${cx('marginRight8')}`}
                        id="accept-terms"
                    ></GaCheckbox>
                    <span
                        className={cx(
                            'neutral700 bodyRegularSM',
                            styles.checkbox__text
                        )}
                    >
                        <Trans
                            i18nKey={'welcome.agreeToGataca'}
                            values={{ brand: brandLabels?.brand }}
                        />
                    </span>
                    <a
                        className={cx('primary700 bodyRegularSM')}
                        target="_blank"
                        href={brandLabels?.termsOfServiceLink}
                    >
                        {t('welcome.termsOfUse')}
                    </a>
                    <span
                        className={cx(
                            'primary700 bodyRegularSM',
                            styles.checkbox__text
                        )}
                    >
                        <Trans
                            i18nKey={'welcome.and'}
                            values={{ brand: brandLabels?.brand }}
                        />
                    </span>
                    <a
                        className={cx('primary700 bodyRegularSM')}
                        target="_blank"
                        href={brandLabels?.privacypolicylink}
                    >
                        {t('welcome.privacyPolicy')}
                    </a>
                </div>
            </FormPanel>
            {!!isCreateOrgLoading && <ScreenLoader />}
        </>
    )
}

Welcome.defaultProps = {}

export default Welcome
