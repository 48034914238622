import React from 'react'
import { t } from 'i18next'
import cx from 'classnames'
import styles from './stepsAppIntegration.module.scss'
import { IAppIntegrationModel } from '../../../../../../../interfaces/interfaces'
import AgeVerificationModal from '../AgeVerificationModal'
import { gaImages } from '../../../../../../../assets/globalConstants'
import GaTextInput from '../../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import GaTextInputPassword from '../../../../../../../Components/atoms/formFields/textInputPassword/GaTextInputPassword'
import { clearModalState } from '../../../../../../../store/appIntegrationModal/appIntegrationModalActions'
import { useDispatch } from 'react-redux'
import { showNotification } from '../../../../../../common/Notification/notificationActions'

type IIntegrationCompleteFormProps = {
    savedAppInt: IAppIntegrationModel
    hideModal: () => void
}

const IntegrationCompleteForm: React.FC<IIntegrationCompleteFormProps> = (
    props
) => {
    const { savedAppInt, hideModal } = props

    const dispatch = useDispatch()

    const formData = {
        clientID: savedAppInt?.client_id,
        clientSecret: savedAppInt?.client_secret,
    }

    // Functions
    const continueButtonFunction = () => {
        hideModal()
        dispatch(
            showNotification(
                'appIntegrations.createAppIntSuccess',
                'success',
                ''
            )
        )
        dispatch(clearModalState())
    }

    return (
        <AgeVerificationModal
            stepTitle={t(
                'dashboard.vouchModal.integrationCompleteForm.stepTitle'
            )}
            title={t('dashboard.vouchModal.integrationCompleteForm.title')}
            description={t(
                'dashboard.vouchModal.integrationCompleteForm.description'
            )}
            hideModal={hideModal}
            continueButton={{
                label: t(
                    'dashboard.vouchModal.integrationCompleteForm.continueButton'
                ),
                function: continueButtonFunction,
            }}
            rightImage={gaImages.saveBox}
        >
            <div className={`${styles.bodyContainer}`}>
                <GaTextInput
                    className={`${cx('marginTop32')}`}
                    id={'clientID'}
                    name={'clientID'}
                    labelText={t(
                        'dashboard.vouchModal.integrationCompleteForm.clientIDLabel'
                    )}
                    helperText={t(
                        'dashboard.vouchModal.integrationCompleteForm.clientIDHelperText'
                    )}
                    copyOption
                    copyIconSize={24}
                    value={formData.clientID}
                />
                <GaTextInputPassword
                    id={'clientSecret'}
                    name={'clientSecret'}
                    labelText={t(
                        'dashboard.vouchModal.integrationCompleteForm.clientSecretLabel'
                    )}
                    helperText={t(
                        'dashboard.vouchModal.integrationCompleteForm.clientSecretHelperText'
                    )}
                    copyOption
                    copyIconSize={24}
                    value={formData.clientSecret}
                />
            </div>
        </AgeVerificationModal>
    )
}

export default IntegrationCompleteForm
