import { gaImages } from '../../../../../../assets/globalConstants'
import { ButtonModel } from '../../../../../../interfaces/interfaces'

export const headerUsersButton: ButtonModel = {
    label: 'createUser.inviteUser',
    image: gaImages.plus,
}

export const headerRolesButton: ButtonModel = {
    label: 'roles.newRole',
    image: gaImages.plus,
}

export const propertiesToSearch = [
    {
        text: 'email',
        searchMultipleLanguages: false,
        latinize: true,
    },
    {
        text: 'role',
        searchMultipleLanguages: false,
        latinize: true,
    },
    {
        text: 'status',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

// TODO: Remove when API to get roles is implemented
export const userRolesOptions = ['TenantAdmin']

export const rolesScopes = {
    read: ['readRoles'],
    edit: ['updateRoles'],
    create: ['createRoles'],
}

export const scopeScopes = {
    read: ['readRoles'],
}

export const usersScopes = {
    read: ['readUsers'],
    edit: ['updateUsers'],
    create: ['createUsers'],
}

export const usersReadCreateScopes = ['readUsers', 'createUsers']
