import React from 'react'

type ActiveCircleIconProps = {
    size?: number
    color?: string
    className?: string
}

const ActiveCircleIcon: React.FC<ActiveCircleIconProps> = React.memo(
    (props) => {
        const { size, color, className } = props

        return (
            <svg
                className={className && className}
                width={size || '17'}
                height={size || '16'}
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.50018 14.75C12.2281 14.75 15.2502 11.7279 15.2502 8C15.2502 4.27208 12.2281 1.25 8.50018 1.25C4.77223 1.25 1.75012 4.27208 1.75012 8C1.75012 11.7279 4.77223 14.75 8.50018 14.75Z"
                    fill={color || 'currentColor' || '#4745B7'}
                />
            </svg>
        )
    }
)

export default ActiveCircleIcon
