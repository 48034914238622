import {
    DA_Purpose,
    ICredentialRequestedModel,
    IIssuanceConfigItemModel,
    StepModel,
} from '../../../interfaces/interfaces'
import {
    ClearIssuance,
    ClearIssuanceFormState,
    ClearSelectedCredential,
    ConfigIssuanceFormActionsTypes,
    EditIssuance,
    IClearIssuanceGeneralStateAction,
    ICreateIssuanceAction,
    ICreateIssuanceFailedAction,
    ICreateIssuanceSuccessAction,
    IDeleteIssuanceAction,
    IDeleteIssuanceFailedAction,
    IDeleteIssuanceSuccessAction,
    IGetIssuancesAction,
    IGetIssuancesFailedAction,
    IGetIssuancesSuccessAction,
    ISelectIssuanceAction,
    IssuanceActionsTypes,
    IUpdateIssuanceAction,
    IUpdateIssuanceFailedAction,
    IUpdateIssuanceSuccessAction,
    SetActiveStepAction,
    SetPurposes,
    SetQRIssuance,
    SetSelectedCredential,
    SetSelectedCredentials,
    SetStepsAction,
} from './issuanceTypes'

// LIST
export const getIssuances = (): IGetIssuancesAction => {
    return {
        type: IssuanceActionsTypes.GET_ISSUANCES,
    }
}
export const getIssuancesSuccess = (
    issuances: IIssuanceConfigItemModel[]
): IGetIssuancesSuccessAction => {
    return {
        type: IssuanceActionsTypes.GET_ISSUANCES_SUCCESS,
        payload: {
            issuances: issuances,
        },
    }
}
export const getIssuancesFailed = (err: any): IGetIssuancesFailedAction => {
    return {
        type: IssuanceActionsTypes.GET_ISSUANCES_FAILED,
        payload: {
            error: err,
        },
    }
}

// EDIT OR CREATE
export const setSteps = (steps: StepModel[]): SetStepsAction => {
    return {
        type: ConfigIssuanceFormActionsTypes.SETSTEPS,
        payload: {
            steps: steps,
        },
    }
}

export const setActiveStep = (step: StepModel): SetActiveStepAction => {
    return {
        type: ConfigIssuanceFormActionsTypes.SETSTEP,
        payload: {
            step: step,
        },
    }
}

export const setSelectedCredentials = (
    credentials: ICredentialRequestedModel[]
): SetSelectedCredentials => {
    return {
        type: ConfigIssuanceFormActionsTypes.SET_SELECTED_CREDENTIALS,
        payload: {
            credentials: credentials,
        },
    }
}

export const setSelectedCredential = (
    selectedCredential: ICredentialRequestedModel
): SetSelectedCredential => {
    return {
        type: ConfigIssuanceFormActionsTypes.SET_SELECTED_CREDENTIAL,
        payload: selectedCredential,
    }
}

export const clearFormState = (): ClearIssuanceFormState => {
    return {
        type: ConfigIssuanceFormActionsTypes.CLEAR_ISSUANCE_FORM,
    }
}

export const setPurposes = (purposes: DA_Purpose[]): SetPurposes => {
    return {
        type: ConfigIssuanceFormActionsTypes.SET_PURPOSES,
        payload: {
            purposes: purposes,
        },
    }
}

export const clearSelectedCredential = (): ClearSelectedCredential => {
    return {
        type: ConfigIssuanceFormActionsTypes.CLEAR_SELECTED_CREDENTIAL,
    }
}

export const setQRIssuance = (qrIssuance: string): SetQRIssuance => {
    return {
        type: ConfigIssuanceFormActionsTypes.SET_QR_VERIFIER,
        payload: qrIssuance,
    }
}

export const editIssuance = (
    issuance: IIssuanceConfigItemModel
): EditIssuance => {
    return {
        type: ConfigIssuanceFormActionsTypes.EDIT_ISSUANCE,
        payload: issuance,
    }
}

export const createIssuance = (
    issuance: IIssuanceConfigItemModel
): ICreateIssuanceAction => {
    return {
        type: IssuanceActionsTypes.CREATE_ISSUANCE,
        payload: {
            issuance: issuance,
        },
    }
}
export const createIssuanceSuccess = (): ICreateIssuanceSuccessAction => {
    return {
        type: IssuanceActionsTypes.CREATE_ISSUANCE_SUCCESS,
        payload: {
            message: 'Issuance created successfully',
        },
    }
}
export const createIssuanceFailed = (err: any): ICreateIssuanceFailedAction => {
    return {
        type: IssuanceActionsTypes.CREATE_ISSUANCE_FAILED,
        payload: {
            error: err,
        },
    }
}
export const deleteIssuance = (issuanceID: string): IDeleteIssuanceAction => {
    return {
        type: IssuanceActionsTypes.DELETE_ISSUANCE,
        payload: {
            issuanceID: issuanceID,
        },
    }
}

export const deleteIssuanceSuccess = (
    id: string
): IDeleteIssuanceSuccessAction => {
    return {
        type: IssuanceActionsTypes.DELETE_ISSUANCE_SUCCESS,
        payload: {
            id: id,
        },
    }
}
export const deleteIssuanceFailed = (err: any): IDeleteIssuanceFailedAction => {
    return {
        type: IssuanceActionsTypes.DELETE_ISSUANCE_FAILED,
    }
}
export const selectIssuance = (
    issuance: IIssuanceConfigItemModel
): ISelectIssuanceAction => {
    return {
        type: IssuanceActionsTypes.SELECT_ISSUANCE,
        payload: {
            issuance: issuance,
        },
    }
}
export const updateIssuance = (
    issuance: IIssuanceConfigItemModel
): IUpdateIssuanceAction => {
    return {
        type: IssuanceActionsTypes.UPDATE_ISSUANCE,
        payload: {
            issuance: issuance,
        },
    }
}

export const updateIssuanceSuccess = (
    issuance: IIssuanceConfigItemModel
): IUpdateIssuanceSuccessAction => {
    return {
        type: IssuanceActionsTypes.UPDATE_ISSUANCE_SUCCESS,
        payload: {
            issuance,
        },
    }
}
export const updateIssuanceFailed = (
    error: any
): IUpdateIssuanceFailedAction => {
    return {
        type: IssuanceActionsTypes.UPDATE_ISSUANCE_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearIssuance = (): ClearIssuance => {
    return {
        type: ConfigIssuanceFormActionsTypes.CLEAR_ISSUANCE,
    }
}

export const clearIssuancesGeneralState =
    (): IClearIssuanceGeneralStateAction => {
        return {
            type: IssuanceActionsTypes.CLEAR_ISSUANCES_GENERAL_STATE,
        }
    }
