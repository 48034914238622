import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './ewNotificationsModal.module.scss'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmationModal from '../../../../../Components/molecules/modals/confirmation/Confirmation'
import CloseIcon from '../../../../../assets/iconsLibrary/simple/close/closeIcon'
import {
    ActionStatusTypes,
    gaColors,
    gaImages,
} from '../../../../../assets/globalConstants'
import NotificationCard from './Components/NotificationCard/NotificationCard'
import { IEWNotificationModel } from '../../../../../interfaces/interfaces'
import SuccessFilledPanel from '../../../../../Components/molecules/notification/panels/success/errorFilledPanel/SuccessFilledPanel'
import WarningPanel from '../../../../../Components/molecules/notification/panels/warning/warningPanel'
import AlertIcon from '../../../../../assets/iconsLibrary/simple/alert/alertIcon'
import GaImage from '../../../../../Components/atoms/Image/Image'
import {
    notificationActions,
    notificationsSelectors,
} from '../../../../../store/notifications'
import { showScreenLoaderAction } from '../../../Loader/loaderActions'
import {
    setReadedNotifications,
    setReadedNotificationsNotSeen,
} from '../../../../../store/notifications/notificationsActions'

type IEWNotificationsModalProps = {
    hideModal: (x?) => void
    notifications?: IEWNotificationModel[]
    EWSelectedDid?: string
    loadingNotifications?: boolean
}

const EWNotificationsModal: React.FC<IEWNotificationsModalProps> = (props) => {
    const { hideModal, notifications, EWSelectedDid, loadingNotifications } =
        props

    const dispatch = useDispatch()

    // Selectors
    const downloadCredStatus = useSelector(
        notificationsSelectors.downloadCredStatus
    )

    // States
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [notificationToDelete, setNotificationToDelete] = useState('')

    const showDeleteNotificationConfirmation = (id: string) => {
        setNotificationToDelete(id)
        setShowDeleteConfirmation(true)
    }

    const deleteNotification = () => {
        setShowDeleteConfirmation(false)
        if (EWSelectedDid) {
            hideModalAndSetAsReaded()
            dispatch(showScreenLoaderAction())
            dispatch(
                notificationActions.deleteNotification(
                    EWSelectedDid,
                    notificationToDelete
                )
            )
        }
    }

    // Effects
    useEffect(() => {
        if (downloadCredStatus === ActionStatusTypes.success) {
            hideModalAndSetAsReaded()
        }
    }, [downloadCredStatus])

    useEffect(() => {
        dispatch(setReadedNotifications(notifications))
    }, [])

    // Functions
    const downloadCred = (id: string) => {
        EWSelectedDid &&
            dispatch(notificationActions.downloadCredential(EWSelectedDid, id))
    }

    const hideModalAndSetAsReaded = () => {
        dispatch(setReadedNotificationsNotSeen(notifications))
        hideModal()
    }

    return (
        <>
            <div
                className={cx(styles.backgroundLayer)}
                onClick={() => hideModalAndSetAsReaded()}
            ></div>
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div className={styles.modal__header__top}>
                        <div className={styles.modal__header__top__texts}>
                            <p className={cx('heading5 neutral1000')}>
                                {t('ew.notifications.title')}
                            </p>
                        </div>

                        <div
                            className={cx(styles.modal__header__top__close)}
                            onClick={hideModalAndSetAsReaded}
                        >
                            <CloseIcon size={32} color={gaColors.neutral1000} />
                        </div>
                    </div>
                </div>

                {notifications?.length ? (
                    <div className={`${styles.modal__scrollable}`}>
                        {notifications?.map((notification, index) => (
                            <NotificationCard
                                key={'ew-notification__' + index}
                                notification={notification}
                                showDeleteNotificationConfirmation={
                                    showDeleteNotificationConfirmation
                                }
                                downloadCred={downloadCred}
                                loadingStatus={
                                    downloadCredStatus ===
                                    ActionStatusTypes.loading
                                }
                            />
                        ))}
                    </div>
                ) : (
                    <div className={`${styles.modal__noContent}`}>
                        <GaImage image={gaImages.greenCheckPng} />
                        <p className={cx('heading6')}>
                            {t('ew.notifications.noNotificationsTitle')}
                        </p>
                        <p className={cx('bodyRegularMD neutral700')}>
                            {t('ew.notifications.noNotificationsDesc')}
                        </p>
                    </div>
                )}

                {downloadCredStatus === ActionStatusTypes.loading ? (
                    <SuccessFilledPanel
                        label={'ew.notifications.downloading'}
                        loadingAnimation
                        className={styles.downloadingPanel}
                    />
                ) : null}
                {downloadCredStatus === ActionStatusTypes.failed ? (
                    <WarningPanel
                        hasIcon
                        className={cx(styles.failedPanel, 'bodyRegularSM')}
                        IconComponent={
                            <AlertIcon color={gaColors.alert300} size={24} />
                        }
                        label={t('ew.notifications.errorDownloading')}
                    />
                ) : null}
            </div>
            {showDeleteConfirmation ? (
                <ConfirmationModal
                    title="ew.connections.sureDeleteDataAgreement"
                    description="ew.connections.sureDeleteDataAgreementDesc"
                    confirmFunction={deleteNotification}
                    show
                    hideModal={() => {
                        setShowDeleteConfirmation(false)
                    }}
                />
            ) : null}
        </>
    )
}

export default EWNotificationsModal
