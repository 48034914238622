import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './users.module.scss'
import cx from 'classnames'
import { gaImages } from '../../../../../../assets/globalConstants'
import BasicCard from '../../../../../../Components/organisms/Cards/BasicCard'
import Description from '../../../../../../Components/atoms/texts/Description/Description'
import CardListSkeleton from '../../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import PermissionsPanel from '../../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import { rolesScopes, scopeScopes, usersScopes } from '../store/team.constants'
import MediumModal from '../../../../../../Components/molecules/modals/Medium/MediumModal'
import ConfigUser from '../components/configUser/ConfigUser'
import { IUserItem } from '../../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../../utils/permissionsUtil'
import { useDispatch, useSelector } from 'react-redux'
import { userSelectors } from '../store'
import { sessionSelectors } from '../../../../session'
import { didsScopes } from '../../../../../studio/Dids/views/didList/didList.constants'
import { verifierScopes } from '../../../../../studio/Verifier/data/verifier.constants'
import { issuancesScopes } from '../../../../../studio/Issuance/issuance.constants'
import { apiKeysScopes } from '../../../../../studio/ApiKeys/views/apiKeysList/apiKeysList.constants'
import { issuanceSelectors } from '../../../../../studio/Issuance'
import { configVerifierSelectors } from '../../../../../studio/Verifier'
import { apiKeysSelectors } from '../../../../../studio/ApiKeys'
import { didsSelectors } from '../../../../../studio/Dids'
import { getVerifierConfigs } from '../../../../../studio/Verifier/verifierActions'
import { getApiKeys } from '../../../../../studio/ApiKeys/apiKeysActions'
import { getIssuances } from '../../../../../studio/Issuance/issuanceActions'
import { getDids } from '../../../../../studio/Dids/didActions'
import { getUsers, selectUser } from '../store/usersActions'
import {
    getTotalPageItems,
    paginate,
} from '../../../../../../utils/paginationUtil'
import { getRoles, getScopes } from '../../roles/store/rolesActions'
import { rolesSelectors } from '../../roles/store'
import { defaultPageSize } from '../../../../../../Components/Pagination/paginationConstants'
import { GaPagination } from '../../../../../../Components/Pagination/Pagination'

type ISearchState = {
    searched: string
    searchedResult: any
}

export type UsersProps = {
    canReadUsers: boolean
    clearState: () => void
    newUser: boolean
}

const Users: React.FC<UsersProps> = (props) => {
    const { canReadUsers, newUser, clearState } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // Selectors
    const usersList: IUserItem[] = useSelector(userSelectors.getUsers)
    const usersAreLoading = useSelector(userSelectors.loadingStatus)
    const selectedUser = useSelector(userSelectors.getSelectedUser)
    const issuancesAreLoading = useSelector(issuanceSelectors.getIsLoading)
    const verifiersAreLoading = useSelector(
        configVerifierSelectors.getIsLoading
    )
    const apiKeysLoading = useSelector(apiKeysSelectors.loadingStatus)
    const didsAreLoading = useSelector(didsSelectors.isLoading)
    const rolesAreLoading = useSelector(rolesSelectors.loadingStatus)
    const scopesAreLoading = useSelector(rolesSelectors.scopesLoadingStatus)

    // States
    const [users, setUsers] = React.useState<IUserItem[]>()
    const [totalItems, setTotalItems] = useState(users?.length)

    const [pageIndex, setPageIndex] = useState(1)
    const [paginatedUsers, setPaginatedUsers] = useState([])
    const [showUser, setShowUser] = React.useState<boolean>(false)

    // Consts
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canEditUsers = !!(
        availableScopes && userHasScopes(usersScopes?.edit, availableScopes)
    )

    const initialListState = {
        searched: '',
        searchedResult: paginatedUsers?.slice() || [],
    }

    const canReadDids = !!(
        availableScopes && userHasScopes(didsScopes?.read, availableScopes)
    )

    const canReadVerifiers = !!(
        availableScopes && userHasScopes(verifierScopes?.read, availableScopes)
    )

    const canReadIssuances = !!(
        availableScopes && userHasScopes(issuancesScopes?.read, availableScopes)
    )

    const canReadApiKeys = !!(
        availableScopes && userHasScopes(apiKeysScopes?.read, availableScopes)
    )

    const canReadRoles = !!(
        availableScopes && userHasScopes(rolesScopes?.read, availableScopes)
    )

    const canReadScopes = !!(
        availableScopes && userHasScopes(scopeScopes?.read, availableScopes)
    )

    // Effects
    useEffect(() => {
        canReadApiKeys && !apiKeysLoading && dispatch(getApiKeys())
        canReadVerifiers &&
            !verifiersAreLoading &&
            dispatch(getVerifierConfigs())
        canReadIssuances && !issuancesAreLoading && dispatch(getIssuances())
        canReadDids && !didsAreLoading && dispatch(getDids())
        canReadRoles && !rolesAreLoading && dispatch(getRoles())
        canReadScopes && !scopesAreLoading && dispatch(getScopes())
        canReadUsers && !usersAreLoading && dispatch(getUsers())
    }, [])

    useEffect(() => {
        setUsers(usersList)
        setState(initialListState)
    }, [usersList, pageIndex])

    useEffect(() => {
        setPaginatedUsers(paginate(users, defaultPageSize, pageIndex))
    }, [users?.length])

    useEffect(() => {
        setState(initialListState)
        setTotalItems(users?.length)
    }, [paginatedUsers])

    // Functions
    const handlePageChange = (page) => {
        setPageIndex(page)
        setPaginatedUsers(paginate(users, defaultPageSize, page))
        setState(initialListState)
    }

    const selectUserHandler = (item) => {
        setShowUser(true)
        dispatch(selectUser(item))
    }

    const hideUserModal = () => {
        clearState()
        setShowUser(false)
        dispatch(selectUser())
        canReadUsers && dispatch(getUsers())
    }

    const [state, setState] = React.useState<ISearchState>(initialListState)
    return (
        <>
            {canReadUsers ? (
                !usersAreLoading ? (
                    users && users?.length > 0 ? (
                        <>
                            {state?.searchedResult?.map((e, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={cx('margin-bottom')}
                                    >
                                        <BasicCard
                                            item={e}
                                            name={e.email}
                                            description={{
                                                text: e.role,
                                            }}
                                            className={styles.tabCard}
                                            rightIcon={
                                                canEditUsers
                                                    ? gaImages.pencilIcon
                                                    : undefined
                                            }
                                            buttonFunction={
                                                canEditUsers
                                                    ? selectUserHandler
                                                    : () => {}
                                            }
                                        />
                                    </div>
                                )
                            })}
                            {users?.length > defaultPageSize ? (
                                <GaPagination
                                    className={cx('margin-top')}
                                    currentPage={pageIndex}
                                    totalPages={
                                        getTotalPageItems(
                                            totalItems || 0,
                                            defaultPageSize
                                        ) || 0
                                    }
                                    onChange={handlePageChange}
                                    totalItems={totalItems || 0}
                                    pageSize={defaultPageSize}
                                />
                            ) : null}
                        </>
                    ) : (
                        <Description text={t('users.noUsersCreated')} />
                    )
                ) : (
                    <CardListSkeleton cardsNumber={5} />
                )
            ) : (
                <PermissionsPanel scopes={usersScopes?.read} readScope />
            )}
            {selectedUser && (
                <MediumModal
                    title={'configUser.title'}
                    subtitle={selectedUser?.email}
                    headerClose={true}
                    show={showUser}
                    handleClose={hideUserModal}
                >
                    <ConfigUser hideUserModal={hideUserModal} />
                </MediumModal>
            )}
        </>
    )
}

export default Users
