import cx from 'classnames'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
    brandColors,
    brandImages,
    brandLabels,
    gaImages,
} from '../../../assets/globalConstants'
import GaImage from '../../../Components/atoms/Image/Image'
import { LinkModel } from '../../../interfaces/interfaces'
import { history } from '../../../services/history'
import PanelScafoldingPublic from '../PanelScafolding/public/PanelScafoldingPublic'
import { setSelectedRoute } from '../PanelScafolding/scafoldingActions'
import { deleteSession } from '../session/sessionActions'
import loginStyles from './Login.module.scss'
import * as loginConstants from './loginConstants'
import LoginQR from './LoginQR'
import { ReactComponent as AppleBrandButtonIcon } from '../../../assets/newImages/appleBrandButton.svg'
import { ReactComponent as GoogleBrandButtonIcon } from '../../../assets/newImages/googleBrandButton.svg'
import { ReactComponent as ScanButtonIcon } from '../../../assets/newImages/scanButtonIcon.svg'

import styles from './Login.module.scss'

type ILoginProps = {}

/* TODO: Uncomment when qrCode is implemented
// iframeURI
const clientID = process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : ''
const redirectURI = process.env.REACT_APP_REDIRECT_URI ? process.env.REACT_APP_REDIRECT_URI : ''
const scopes = 'openid email'
const responseType = 'code'
const responseMode = 'query'
const state = 'asjdkfjasliur'
const nonce = 'testNonce'

 export let iframeURI = process.env.REACT_APP_VOUCH_API
  + '/oauth2/auth?client_id=' + clientID
  + '&redirect_uri=' + redirectURI
  + '&scope=' + scopes
  + '&response_type=' + responseType
  + '&response_mode=' + responseMode
  + '&state=' + state
  + '&nonce=' + nonce
*/

const Login: React.FC<ILoginProps> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const goToLoginAndDeleteSession = () => {
        dispatch(setSelectedRoute('login'))
        dispatch(deleteSession())
        history.push('/login')
    }

    useEffect(() => {
        goToLoginAndDeleteSession()
        dispatch(setSelectedRoute('login'))
    }, [])

    /* TODO: Uncomment when qrCode is implemented
  let pollingInterval;

  const dispatch = useDispatch()
  const history = useHistory()
  const [sessionStatus, setSessionStatus] = useState<null | string>(null);

  const polling = () => {
    pollingInterval = setInterval(() => {
      const dataToken = localStorage.getItem('user-email');
      dataToken ? setSessionStatus(dataToken) : null
    }, 1000)
  }

  useEffect(() => {
    if (sessionStatus !== null) {
      clearInterval(pollingInterval)
      history.push('/home')
    }

  }, [
    dispatch,
    sessionStatus
  ])

  const loginButton = () => {
    popupCenter({url: '/welcome', title: 'Vouch Pop', w: 350, h: 450});
    polling()
  }

  const popupCenter = ({url, title, w, h}) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `
      popup,
      scrollbars=no,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )
    // @ts-ignore
    if (window.focus) newWindow.focus();
  } */

    const loginLink: LinkModel = {
        label: 'login.emailLoginText',
        route: 'email-login',
    }

    return (
        // TODO: Add link={loginLink} as prop of PanelScafoldingPublic when the login by email is active
        <PanelScafoldingPublic link={loginLink}>
            <div className={styles.sections}>
                <div className={loginStyles.qrModule}>
                    <div className={loginStyles.infoContainer}>
                        <GaImage image={brandImages?.unlock} />

                        <p className={cx('heading3')}>
                            <Trans
                                defaults={'login.headline'}
                                values={{
                                    studio: brandLabels?.brand + ' Studio',
                                }}
                                components={{
                                    bold: <span />,
                                }}
                            />
                        </p>
                        <p
                            className={cx(
                                'bodyBoldLG neutral700 marginBottom2 marginTop30'
                            )}
                        >
                            {'1.'}
                            <span className={cx('bodyRegularLG neutral700')}>
                                <Trans
                                    i18nKey={'login.subHeadline'}
                                    values={{
                                        walletName: brandLabels?.walletName,
                                    }}
                                />
                            </span>
                        </p>

                        <div
                            className={cx(
                                'margin-top',
                                loginStyles.marketsContainer
                            )}
                        >
                            <div>
                                <a
                                    href={loginConstants.marketIosURI}
                                    target="_blank"
                                    className={`${styles.marketButton} ${cx(
                                        'marginRight12'
                                    )}`}
                                >
                                    <AppleBrandButtonIcon />
                                </a>
                                <a
                                    className={`${styles.marketButton}`}
                                    href={loginConstants.marketAndroidURI}
                                    target="_blank"
                                >
                                    <GoogleBrandButtonIcon />
                                </a>
                            </div>
                        </div>
                        <p
                            className={cx(
                                'bodyBoldLG neutral700 marginBottom22 marginTop22',
                                styles.scanStep
                            )}
                        >
                            {'2.'}
                            <span className={cx('bodyRegularLG neutral700')}>
                                {t('login.secondSubHeadline')}
                            </span>
                            <ScanButtonIcon />
                        </p>
                        <p className={cx('bodyBoldLG neutral700')}>
                            {'3.'}
                            <span className={cx('bodyRegularLG neutral700')}>
                                {t('login.thirdSubHeadline')}
                            </span>
                        </p>
                    </div>
                    {/* TODO: Uncomment when login by email is available
          <Link className={loginStyles.emailLoginLink} to={loginLink.route}>{t(loginLink.label)}</Link> */}
                </div>
                <div className={styles.qrSection}>
                    <LoginQR
                        qrColor={brandColors?.primaryColor}
                        logoSrc={brandImages?.entityLogoIcon?.src}
                    ></LoginQR>
                </div>
            </div>
        </PanelScafoldingPublic>
    )
}

Login.defaultProps = {}

export default Login
