import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { screenLoaderActive } from '../loaderSelectors'
import styles from './screenLoader.module.scss'
import Loading from '../../../../Components/loader/loader/Loader'

type ILoaderProps = {}

const Loader: React.FC<ILoaderProps> = ({ children }) => {
    const isLoading = useSelector(screenLoaderActive)

    useEffect(() => {}, [isLoading])

    const Loaded = <div>{children}</div>

    const loading = <Loading className={styles.screenLoader} />
    return <React.Fragment>{!isLoading ? Loaded : loading}</React.Fragment>
}

Loader.defaultProps = {}

export default Loader
