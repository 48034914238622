import React from 'react'

type EyeOffIconProps = {
    size?: number
    color?: string
    className?: string
}

const EyeOffIcon: React.FC<EyeOffIconProps> = React.memo((props) => {
    const { size, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3872_25975)">
                <path
                    d="M23.9202 23.92C21.641 25.6573 18.8656 26.6198 16.0002 26.6667C6.66683 26.6667 1.3335 16 1.3335 16C2.99202 12.9092 5.29234 10.2088 8.08016 8.07999M13.2002 5.65332C14.1179 5.4385 15.0576 5.33111 16.0002 5.33332C25.3335 5.33332 30.6668 16 30.6668 16C29.8575 17.5141 28.8922 18.9396 27.7868 20.2533M18.8268 18.8267C18.4606 19.2197 18.019 19.5349 17.5284 19.7535C17.0377 19.9721 16.508 20.0897 15.971 20.0991C15.4339 20.1086 14.9004 20.0098 14.4023 19.8086C13.9042 19.6075 13.4518 19.308 13.072 18.9282C12.6921 18.5484 12.3927 18.0959 12.1915 17.5978C11.9903 17.0998 11.8915 16.5663 11.901 16.0292C11.9105 15.4921 12.028 14.9625 12.2467 14.4718C12.4653 13.9811 12.7805 13.5395 13.1735 13.1733"
                    stroke={color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.3335 1.33334L30.6668 30.6667"
                    stroke={color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3872_25975">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
})

export default EyeOffIcon
