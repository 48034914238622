import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../../../../assets/images/close.svg'
import { ButtonModel } from '../../../../../interfaces/interfaces'
import { hideNotification } from '../../notificationActions'
import cx from 'classnames'
import styles from './successNotification.module.scss'

type ISuccessNotificationProps = {
    title: string
    label: string
    closeButton?: ButtonModel
    className?: string
}

const SuccessNotification: React.FC<ISuccessNotificationProps> = (props) => {
    const { title, label, closeButton, className } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {}, [label])

    const closeNotification = () => dispatch(hideNotification())

    return (
        <div
            className={`${styles.successNotification} ${
                className ? className : ''
            }`}
        >
            <div className={styles.successNotification__content}>
                <div
                    className={`${closeButton ? '' : styles.fullWidth} ${
                        styles.successNotification__content__texts
                    }`}
                >
                    {title ? (
                        <p
                            className={`${
                                closeButton ? '' : styles.fullWidth
                            } ${styles.title} ${cx('bodyBoldSM neutral1000')}`}
                        >
                            {t(title)}
                        </p>
                    ) : null}
                    {label && (
                        <p className={styles.label}>
                            <Trans
                                className={cx('bodyRegularSM neutral1000')}
                                i18nKey={label}
                            />
                        </p>
                    )}
                </div>
                {closeButton && (
                    <div
                        className={styles.successNotification__content__close}
                        onClick={() => closeNotification()}
                    >
                        <CloseIcon className={styles.closeIcon} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default SuccessNotification
