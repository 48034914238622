import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './dropdownActions.module.scss'
import cx from 'classnames'
import MoreVerticalIcon from '../../../../../assets/iconsLibrary/simple/moreVertical/moreVerticalIcon'

type IDropdownActionsProps = {
    className?: string
    item: any
    isLastItem: boolean
    options: {
        text: string
        hide?: boolean
        color?: string
        action?: (x?: any, y?: any, z?: any) => void | ((x?: any) => void)
    }[]
    closeDropdownOnSelectAction?: boolean
}

const DropdownActions: React.FC<IDropdownActionsProps> = (props) => {
    const {
        item,
        className,
        isLastItem,
        options,
        closeDropdownOnSelectAction,
    } = props
    const [active, setIsActive] = useState(false)
    const { t } = useTranslation()

    return (
        <div
            className={cx(
                styles.dropdownActionsContainer,
                className && className
            )}
        >
            <button
                className={styles.dropdownActionsButton}
                onClick={() => {
                    setIsActive(!active)
                }}
            >
                <MoreVerticalIcon color="#1E1E20" size={24} />
            </button>
            {active && (
                <div
                    className={cx(
                        active ? styles.active : styles.inActive,
                        styles.menuOptionsCard,
                        isLastItem ? styles.lastItemActions : ''
                    )}
                    id="dropdownOptions"
                >
                    <ul className={styles.menuOptionsCard__list}>
                        {options?.map((option) => {
                            const { text, hide, color, action } = option
                            return !hide ? (
                                <li
                                    className={`${styles.menuOptionsCard__item} `}
                                    onClick={() => {
                                        action && action(item)
                                        closeDropdownOnSelectAction
                                            ? setIsActive(false)
                                            : undefined
                                    }}
                                >
                                    <p
                                        className={cx(
                                            'buttonMD',
                                            color ? color : 'neutral1000'
                                        )}
                                    >
                                        {t(text)}
                                    </p>
                                </li>
                            ) : null
                        })}
                    </ul>
                </div>
            )}
            <div
                className={
                    active
                        ? styles.backgroundLayerActive
                        : styles.backgroundLayerInactive
                }
                onClick={() => {
                    setIsActive(!active)
                }}
            ></div>
        </div>
    )
}

export default DropdownActions
