import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    ISandboxInvitation,
    MetricStatistic,
    NotificationModel,
    PendingActionModel,
    VerifierModel,
} from '../../../interfaces/interfaces'

export interface IHomeState {
    notifications?: NotificationModel[] | null
    pendingActions?: PendingActionModel[] | null
    verifiers?: VerifierModel[] | null
    displayScan?: boolean
    displayScanNavigation?: boolean
    invitationUrl?: string
    generalDataLoaded?: boolean
    getStaticsStatus: ActionStatusTypes
    statistics?: MetricStatistic
    statisticsAU?: MetricStatistic
    statisticsIC?: MetricStatistic
    getAUStaticsStatus: ActionStatusTypes
    getICStaticsStatus: ActionStatusTypes
    selectedAUPeriodIsYear?: boolean
    selectedICPeriodIsYear?: boolean
    showAgeVerificationModal?: boolean
}

export interface IGetNotificationsAction {
    type: HomeActionsTypes.GET_NOTIFICATIONS
}

export interface IGetNotificationsSuccessAction {
    type: HomeActionsTypes.GET_NOTIFICATIONS_SUCCESS
    payload: {
        notifications: any[]
    }
}

export interface IGetNotificationsFailedAction {
    type: HomeActionsTypes.GET_NOTIFICATIONS_FAILED
}

export interface IGetPendingActionsAction {
    type: HomeActionsTypes.GET_PENDING_ACTIONS
}

export interface IGetPendingActionsSuccessAction {
    type: HomeActionsTypes.GET_PENDING_ACTIONS_SUCCESS
    payload: {
        pendingActions: any[]
    }
}
export interface IGetPendingActionsFailedAction {
    type: HomeActionsTypes.GET_PENDING_ACTIONS_FAILED
}

export interface IHideScanAction {
    type: HomeActionsTypes.HIDE_SCAN
}

export interface IDisplayScanAction {
    type: HomeActionsTypes.DISPLAY_SCAN
}

export interface IDisplaySuccessScanAction {
    type: HomeActionsTypes.DISPLAY_SCAN_SUCCESS
    payload: {
        invitation: ISandboxInvitation
    }
}

export interface IDisplayFailedScanAction {
    type: HomeActionsTypes.DISPLAY_SCAN_FAILED
    payload: {
        err: string
    }
}

export interface IHideScanNavigationAction {
    type: HomeActionsTypes.HIDE_SCAN_NAVIGATION
}

export interface IDisplayScanNavigationAction {
    type: HomeActionsTypes.DISPLAY_SCAN_NAVIGATION
}

export interface IDisplaySuccessScanNavigationAction {
    type: HomeActionsTypes.DISPLAY_SCAN_NAVIGATION_SUCCESS
    payload: {
        invitation: ISandboxInvitation
    }
}

export interface IDisplayFailedScanNavigationAction {
    type: HomeActionsTypes.DISPLAY_SCAN_NAVIGATION_FAILED
    payload: {
        err: string
    }
}

export interface ISetGeneralDataLoadedAction {
    type: HomeActionsTypes.SET_GENERAL_DATA_LOADED
    payload: {
        dataLoaded: boolean
    }
}
export interface IGetICStatistics {
    type: HomeActionsTypes.GET_IC_STATISTICS
    payload: {
        params: string
        id?: string
    }
}

export interface IGetICStatisticsSuccess {
    type: HomeActionsTypes.GET_IC_STATISTICS_SUCCESS
    payload: {
        statistics: MetricStatistic
        id?: string
    }
}

export interface IGetICStatisticsFailed {
    type: HomeActionsTypes.GET_IC_STATISTICS_FAILED
    payload: {
        error: string
    }
}

export interface IGetAUStatistics {
    type: HomeActionsTypes.GET_AU_STATISTICS
    payload: {
        params: string
        id?: string
    }
}

export interface IGetAUStatisticsSuccess {
    type: HomeActionsTypes.GET_AU_STATISTICS_SUCCESS
    payload: {
        statistics: MetricStatistic
        id?: string
    }
}

export interface IGetAUStatisticsFailed {
    type: HomeActionsTypes.GET_AU_STATISTICS_FAILED
    payload: {
        error: string
    }
}

export interface ISetICSelectedPeriodYear {
    type: HomeActionsTypes.SET_IC_SELECTED_PERIOD_YEAR_STATS
    payload: { isYear: boolean }
}

export interface ISetAUSelectedPeriodYear {
    type: HomeActionsTypes.SET_AU_SELECTED_PERIOD_YEAR_STATS
    payload: { isYear: boolean }
}

export interface ISetShowAgeverificationModal {
    type: HomeActionsTypes.SET_SHOW_AGE_VERIFICATION_MODAL
    payload: { show: boolean }
}

export type HomeActions =
    | IGetNotificationsAction
    | IGetNotificationsSuccessAction
    | IGetNotificationsFailedAction
    | IGetPendingActionsAction
    | IGetPendingActionsSuccessAction
    | IGetPendingActionsFailedAction
    | IDisplayScanAction
    | IDisplaySuccessScanAction
    | IDisplayFailedScanAction
    | IHideScanAction
    | IDisplayScanNavigationAction
    | IDisplaySuccessScanNavigationAction
    | IDisplayFailedScanNavigationAction
    | ISetGeneralDataLoadedAction
    | IHideScanNavigationAction
    | IGetICStatistics
    | IGetICStatisticsSuccess
    | IGetICStatisticsFailed
    | IGetAUStatistics
    | IGetAUStatisticsSuccess
    | IGetAUStatisticsFailed
    | ISetAUSelectedPeriodYear
    | ISetICSelectedPeriodYear
    | ISetShowAgeverificationModal

export enum HomeActionsTypes {
    GET_NOTIFICATIONS = 'notificaions/get',
    GET_NOTIFICATIONS_SUCCESS = 'notificaions/success',
    GET_NOTIFICATIONS_FAILED = 'notificaions/failed',
    GET_PENDING_ACTIONS = 'pendingactions/get',
    GET_PENDING_ACTIONS_SUCCESS = 'pendingactions/success',
    GET_PENDING_ACTIONS_FAILED = 'pendingactions/failed',
    DISPLAY_SCAN = 'dashboard/display_scan',
    DISPLAY_SCAN_SUCCESS = 'dashboard/display_scan_sucess',
    DISPLAY_SCAN_FAILED = 'dashboard/display_scan_failed',
    HIDE_SCAN = 'dashboard/hide_scan',
    DISPLAY_SCAN_NAVIGATION = 'dashboard/display_scan_navigation',
    DISPLAY_SCAN_NAVIGATION_SUCCESS = 'dashboard/display_scan_navigation_sucess',
    DISPLAY_SCAN_NAVIGATION_FAILED = 'dashboard/display_scan_navigation_failed',
    HIDE_SCAN_NAVIGATION = 'dashboard/hide_scan_navigation',
    SET_GENERAL_DATA_LOADED = 'dashboard/set_general_data_loaded',
    GET_IC_STATISTICS = 'charts/get_ic_stats',
    GET_IC_STATISTICS_SUCCESS = 'charts/get_ic_stats_success',
    GET_IC_STATISTICS_FAILED = 'charts/get_ic_stats_failed',
    GET_AU_STATISTICS = 'charts/get_au_stats',
    GET_AU_STATISTICS_SUCCESS = 'charts/get_au_stats_success',
    GET_AU_STATISTICS_FAILED = 'charts/get_au_stats_failed',
    SET_IC_SELECTED_PERIOD_YEAR_STATS = 'charts/set_ic_stats_selected_period_year',
    SET_AU_SELECTED_PERIOD_YEAR_STATS = 'charts/set_au_stats_selected_period_year',
    SET_SHOW_AGE_VERIFICATION_MODAL = 'dashboard/set_show_age_ver_modal',
}
