export function searchOnComplexTree(obj, element) {
    const jsonObj = obj
    for (let key in jsonObj) {
        let value = jsonObj[key]

        if (element == key) {
            return [element, value]
        }

        if (typeof value === 'object' && !Array.isArray(value)) {
            let y = searchOnComplexTree(value, element)
            if (y && y[0] == element) return y
        }
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; ++i) {
                let x = searchOnComplexTree(value[i], element)
                if (x && x[0] == element) return x
            }
        }
    }
    return null
}
