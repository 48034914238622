import React from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './editAppIntModal.module.scss'
import { ButtonModel } from '../../../../../../interfaces/interfaces'
import DarkButton from '../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../../../data/globalVar'
import LightButton from '../../../../../../Components/atoms/buttons/LightButton/LightButton'
import CloseIcon from '../../../../../../assets/iconsLibrary/simple/close/closeIcon'
import { gaColors } from '../../../../../../assets/globalConstants'
import Tabs from '../../../../../../Components/Tabs/Tabs'
import { editAppIntModalSteps } from '../../../data/AppIntegrationsList.constants'

type IEditAppIntModalProps = {
    className?: string
    saveButton?: ButtonModel
    cancelBackButton?: ButtonModel
    progressIndicatorOptions?: number
    progressIndicatorCurrentOption?: number
    onChangeFunction: (x?) => void
    hideModal: () => void
}

const EditAppIntModal: React.FC<IEditAppIntModalProps> = (props) => {
    const {
        className,
        saveButton,
        cancelBackButton,
        progressIndicatorOptions,
        progressIndicatorCurrentOption,
        children,
        onChangeFunction,
        hideModal,
    } = props

    return (
        <div
            className={cx(
                styles.backgroundLayer,
                styles.visibleModal,
                className && className
            )}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div className={styles.modal__header__top}>
                        <div className={styles.modal__header__top__texts}>
                            <p className={cx('heading5')}>
                                {t('appIntegrations.edit.modalTitle')}
                            </p>
                            <p className={cx('bodyRegularMD neutral700')}>
                                {t('appIntegrations.edit.modalDesc')}
                            </p>
                        </div>
                        <div
                            className={cx(styles.modal__header__top__close)}
                            onClick={() => hideModal()}
                        >
                            <CloseIcon size={32} color={gaColors.neutral1000} />
                        </div>
                    </div>
                </div>
                {progressIndicatorOptions ? (
                    <Tabs
                        sections={editAppIntModalSteps}
                        className={cx(styles.progressIndicator)}
                        selected={progressIndicatorCurrentOption}
                        onChangeFunction={onChangeFunction}
                    />
                ) : null}
                <div className={`${styles.modal__body}`}>{children}</div>

                {saveButton || cancelBackButton ? (
                    <div className={styles.modal__footer}>
                        {cancelBackButton ? (
                            <LightButton
                                className={styles.modal__footer__noBorderButton}
                                functionality={cancelBackButton?.function}
                                text={cancelBackButton?.label || ''}
                                disabled={cancelBackButton?.disabled || false}
                                size={ButtonSizes.MD}
                                noBorder
                            />
                        ) : null}
                        {saveButton ? (
                            <DarkButton
                                className={styles.modal__footer__primaryButton}
                                functionality={saveButton?.function}
                                text={saveButton?.label || ''}
                                disabled={saveButton?.disabled || false}
                                size={ButtonSizes.MD}
                            />
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default EditAppIntModal
