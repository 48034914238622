import {
    DA_Purpose,
    ICredentialRequestedModel,
    IVerifierConfigModel,
    StepModel,
} from '../../../interfaces/interfaces'
import {
    ClearSelectedCredential,
    ClearVerifier,
    ClearVerifierFormState,
    ConfigVerifierFormActionsTypes,
    EditVerifier,
    IClearVeririfersGeneralStateAction,
    ICreateVerifierAction,
    ICreateVerifierFailedAction,
    ICreateVerifierSuccessAction,
    IDeleteVerifierAction,
    IDeleteVerifierFailedAction,
    IDeleteVerifierSuccessAction,
    IGetVerifierConfigsAction,
    IGetVerifierConfigsFailedAction,
    IGetVerifierConfigsSuccessAction,
    IGetVerifierPurposesAction,
    IGetVerifierPurposesFailedAction,
    IGetVerifierPurposesSuccessAction,
    ISelectVerifierAction,
    IUpdateVerifierAction,
    IUpdateVerifierFailedAction,
    IUpdateVerifierSuccessAction,
    SetActiveStepAction,
    SetPurposes,
    SetQRVerifier,
    SetSelectedCredential,
    SetSelectedCredentials,
    SetStepsAction,
    VerifierConfigActionsTypes,
} from './verifierTypes'

// LIST
export const getVerifierConfigs = (): IGetVerifierConfigsAction => {
    return {
        type: VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS,
    }
}
export const getVerifierConfigsSuccess = (
    verifiers: IVerifierConfigModel[]
): IGetVerifierConfigsSuccessAction => {
    return {
        type: VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS_SUCCESS,
        payload: {
            verifiers: verifiers,
        },
    }
}
export const getVerifierConfigsFailed = (
    err: any
): IGetVerifierConfigsFailedAction => {
    return {
        type: VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS_FAILED,
        payload: {
            error: err,
        },
    }
}

export const getVerifierPurposes = (): IGetVerifierPurposesAction => {
    return {
        type: VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES,
    }
}
export const getVerifierPurposesSuccess = (
    purposes: DA_Purpose[]
): IGetVerifierPurposesSuccessAction => {
    return {
        type: VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES_SUCCESS,
        payload: {
            purposes: purposes,
        },
    }
}
export const getVerifierPurposesFailed = (
    err: any
): IGetVerifierPurposesFailedAction => {
    return {
        type: VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES_FAILED,
        payload: {
            error: err,
        },
    }
}

// EDIT OR CREATE
export const setSteps = (steps: StepModel[]): SetStepsAction => {
    return {
        type: ConfigVerifierFormActionsTypes.SETSTEPS,
        payload: {
            steps: steps,
        },
    }
}

export const setActiveStep = (step: StepModel): SetActiveStepAction => {
    return {
        type: ConfigVerifierFormActionsTypes.SETSTEP,
        payload: {
            step: step,
        },
    }
}

export const setSelectedCredentials = (
    credentials: ICredentialRequestedModel[]
): SetSelectedCredentials => {
    return {
        type: ConfigVerifierFormActionsTypes.SET_SELECTED_CREDENTIALS,
        payload: {
            credentials: credentials,
        },
    }
}

export const setSelectedCredential = (
    selectedCredential: ICredentialRequestedModel
): SetSelectedCredential => {
    return {
        type: ConfigVerifierFormActionsTypes.SET_SELECTED_CREDENTIAL,
        payload: selectedCredential,
    }
}

export const clearFormState = (): ClearVerifierFormState => {
    return {
        type: ConfigVerifierFormActionsTypes.CLEAR_VERIFIER_FORM,
    }
}

export const setPurposes = (purposes: DA_Purpose[]): SetPurposes => {
    return {
        type: ConfigVerifierFormActionsTypes.SET_PURPOSES,
        payload: {
            purposes: purposes,
        },
    }
}

export const clearSelectedCredential = (): ClearSelectedCredential => {
    return {
        type: ConfigVerifierFormActionsTypes.CLEAR_SELECTED_CREDENTIAL,
    }
}

export const setQRVerifier = (qrVerifier: string): SetQRVerifier => {
    return {
        type: ConfigVerifierFormActionsTypes.SET_QR_VERIFIER,
        payload: qrVerifier,
    }
}

export const editVerifier = (verifier: IVerifierConfigModel): EditVerifier => {
    return {
        type: ConfigVerifierFormActionsTypes.EDIT_VERIFIER,
        payload: verifier,
    }
}

export const createVerifier = (
    verifier: IVerifierConfigModel
): ICreateVerifierAction => {
    return {
        type: VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG,
        payload: {
            verifier: verifier,
        },
    }
}
export const createVerifierSuccess = (): ICreateVerifierSuccessAction => {
    return {
        type: VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG_SUCCESS,
        payload: {
            message: 'Verifier created successfully',
        },
    }
}
export const createVerifierFailed = (err: any): ICreateVerifierFailedAction => {
    return {
        type: VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG_FAILED,
        payload: {
            error: err,
        },
    }
}
export const deleteVerifier = (verifierID: string): IDeleteVerifierAction => {
    return {
        type: VerifierConfigActionsTypes.DELETE_VERIFIER_CONFIG,
        payload: {
            verifierID: verifierID,
        },
    }
}
export const deleteVerifierSuccess = (
    id: string
): IDeleteVerifierSuccessAction => {
    return {
        type: VerifierConfigActionsTypes.DELETE_VERIFIER_CONFIG_SUCCESS,
        payload: {
            id: id,
        },
    }
}
export const deleteVerifierFailed = (err: any): IDeleteVerifierFailedAction => {
    return {
        type: VerifierConfigActionsTypes.DELETE_VERIFIER_CONFIG_FAILED,
    }
}
export const selectVerifier = (
    verifier: IVerifierConfigModel
): ISelectVerifierAction => {
    return {
        type: VerifierConfigActionsTypes.SELECT_VERIFIER_CONFIG,
        payload: {
            verifier: verifier,
        },
    }
}
export const updateVerifier = (
    verifier: IVerifierConfigModel
): IUpdateVerifierAction => {
    return {
        type: VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG,
        payload: {
            verifier: verifier,
        },
    }
}

export const updateVerifierSuccess = (): IUpdateVerifierSuccessAction => {
    return {
        type: VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG_SUCCESS,
    }
}
export const updateVerifierFailed = (
    error: any
): IUpdateVerifierFailedAction => {
    return {
        type: VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearVerifier = (): ClearVerifier => {
    return {
        type: ConfigVerifierFormActionsTypes.CLEAR_VERIFIER,
    }
}

export const clearVerifiersGeneralState =
    (): IClearVeririfersGeneralStateAction => {
        return {
            type: VerifierConfigActionsTypes.CLEAR_VERIFIERS_GENERAL_STATE,
        }
    }
