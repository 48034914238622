import React from 'react'
import styles from './progressIndicator.module.scss'
import cx from 'classnames'

type ProgressIndicatorProps = {
    optionsNumber: number
    currentOption: number
    className?: string
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = (props) => {
    const { optionsNumber, className, currentOption } = props

    // Const
    const getIsActiveOrCompleted = (index: number) => currentOption >= index

    return (
        <div className={cx(styles.progressIndicator, className && className)}>
            <ul className={styles.progressIndicator__items}>
                {[...Array(optionsNumber).keys()].map((option, index) => (
                    <li
                        key={'progressIndicatorection__' + index}
                        className={cx(
                            styles.option,
                            getIsActiveOrCompleted(index + 1) &&
                                styles.optionActiveOrCompleted
                        )}
                    ></li>
                ))}
            </ul>
        </div>
    )
}

export default ProgressIndicator
