import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    CredentialModel,
    IApiKeyModel,
    ITenantConfigModel,
    StepModel,
} from '../../../interfaces/interfaces'

export interface IConfigApiKeyFormState {
    steps: StepModel[] | null
    firstStep: StepModel | null
    step: StepModel | null
    finalStep: StepModel | null
    apiKey: IApiKeyModel
}

export interface SetActiveStepAction {
    type: ConfigApiKeyFormActionsTypes.SETSTEP
    payload: {
        step: StepModel
    }
}
export interface SetStepsAction {
    type: ConfigApiKeyFormActionsTypes.SETSTEPS
    payload: {
        steps: StepModel[]
    }
}
export interface EditApiKey {
    type: ConfigApiKeyFormActionsTypes.EDIT_API_KEY
    payload: {
        apiKey: IApiKeyModel
    }
}
export interface ClearApiKeyFormState {
    type: ConfigApiKeyFormActionsTypes.CLEAR_API_KEY_FORM
}

export type ConfigApiKeyFormActions =
    | SetActiveStepAction
    | SetStepsAction
    | EditApiKey
    | ClearApiKeyFormState

export enum ConfigApiKeyFormActionsTypes {
    SETSTEPS = 'apiKeys/set_steps',
    SETSTEP = 'apiKeys/set_step',
    EDIT_API_KEY = 'apiKeys/edit',
    CLEAR_API_KEY_FORM = 'apiKeys/clear_form',
}

export interface IApiKeyState {
    appID: string
    issuanceID: string
    requesterDid: string
    domain: string
    credentials: CredentialModel[]
}

export interface IGetApiKeysAction {
    type: ApiKeysActionTypes.GET_API_KEYS
}

export interface IGetApiKeysSuccessAction {
    type: ApiKeysActionTypes.GET_API_KEYS_SUCCESS
    payload: IApiKeyModel[]
}

export interface IGetApiKeysFailedAction {
    type: ApiKeysActionTypes.GET_API_KEYS_FAILED
    payload: { error: string }
}

export interface ICreateApiKeyAction {
    type: ApiKeysActionTypes.CREATE_API_KEY
    payload: {
        app: IApiKeyModel
    }
}

export interface ICreateApiKeySuccessAction {
    type: ApiKeysActionTypes.CREATE_API_KEY_SUCCESS
    payload: {
        message: string
        app: IApiKeyModel
    }
}

export interface ICreateApiKeyFailedAction {
    type: ApiKeysActionTypes.CREATE_API_KEY_FAILED
    payload: {
        error: string
    }
}
export interface IGetTenantConfigAction {
    type: ApiKeysActionTypes.GET_TENANTS_CONFIG
    payload: {
        tenant: string
    }
}

export interface IGetTenantConfigSuccessAction {
    type: ApiKeysActionTypes.GET_TENANTS_CONFIG_SUCCESS
    payload: {
        tenantConfig: ITenantConfigModel
    }
}

export interface IGetTenantConfigFailedAction {
    type: ApiKeysActionTypes.GET_TENANTS_CONFIG_FAILED
    payload: {
        error: string
    }
}

export interface IGetRegistrationTokenAction {
    type: ApiKeysActionTypes.GET_REGISTRATION_TOKEN
    payload: {
        app: IApiKeyModel
        role: string
        tenant: string
    }
}

export interface IGetRegistrationTokenSuccessAction {
    type: ApiKeysActionTypes.GET_REGISTRATION_TOKEN_SUCCESS
    payload: {
        message: string
    }
}

export interface IDeleteApiKeyAction {
    type: ApiKeysActionTypes.DELETE_APP
    payload: {
        appID: string
    }
}

export interface IDeleteApiKeySuccessAction {
    type: ApiKeysActionTypes.DELETE_API_KEY_SUCCESS
    payload: {
        id: string
    }
}

export interface IDeleteApiKeyFailedAction {
    type: ApiKeysActionTypes.DELETE_API_KEY_FAILED
}

export interface IUpdateApiKeyAction {
    type: ApiKeysActionTypes.UPDATE_API_KEY
    payload: {
        app: IApiKeyModel
        id: string
    }
}

export interface IUpdateApiKeySuccessAction {
    type: ApiKeysActionTypes.UPDATE_API_KEY_SUCCESS
}

export interface IUpdateApiKeyFailedAction {
    type: ApiKeysActionTypes.UPDATE_API_KEY_FAILED
    payload: {
        error: string
    }
}

export interface IRegenerateApiKeyAction {
    type: ApiKeysActionTypes.REGENERATE_API_KEY
    payload: {
        app: IApiKeyModel
    }
}

export interface IRegenerateApiKeySuccessAction {
    type: ApiKeysActionTypes.REGENERATE_API_KEY_SUCCESS
    payload: {
        newApiKey: IApiKeyModel
    }
}

export interface IRegenerateApiKeyFailedAction {
    type: ApiKeysActionTypes.REGENERATE_API_KEY_FAILED
    payload: {
        error: string
    }
}

export interface IClearApiKeyListAction {
    type: ApiKeysActionTypes.CLEAR_API_KEY_LIST
}

export interface IClearActionStatus {
    type: ApiKeysActionTypes.CLEAR_ACTION_STATUS
}

export type AppsAction =
    | IGetApiKeysAction
    | IGetApiKeysSuccessAction
    | IGetApiKeysFailedAction
    | IGetTenantConfigAction
    | IGetTenantConfigSuccessAction
    | IGetTenantConfigFailedAction
    | ICreateApiKeyAction
    | ICreateApiKeySuccessAction
    | ICreateApiKeyFailedAction
    | IGetRegistrationTokenAction
    | IDeleteApiKeyAction
    | IDeleteApiKeySuccessAction
    | IDeleteApiKeyFailedAction
    | IUpdateApiKeyAction
    | IUpdateApiKeySuccessAction
    | IUpdateApiKeyFailedAction
    | IClearApiKeyListAction
    | IClearActionStatus
    | IRegenerateApiKeyAction
    | IRegenerateApiKeySuccessAction
    | IRegenerateApiKeyFailedAction

export interface IApiKeysState {
    apiKeys: IApiKeyModel[]
    didOptions?: string[]
    selectedApiKey?: IApiKeyModel
    showApiKeyDetail: boolean
    loadingStatus: boolean
    status: ActionStatusTypes
    error: string
}

export enum ApiKeysActionTypes {
    GET_API_KEYS = 'apiKeys/get',
    GET_API_KEYS_SUCCESS = 'apiKeys/get_success',
    GET_API_KEYS_FAILED = 'apiKeys/set_failed',
    GET_TENANTS_CONFIG = 'apiKeys/get_tenant_did',
    GET_TENANTS_CONFIG_SUCCESS = 'apiKeys/get_tenant_dids_success',
    GET_TENANTS_CONFIG_FAILED = 'apiKeys/get_tenant_dids_failed',
    CREATE_API_KEY = 'apiKeys/create',
    CREATE_API_KEY_SUCCESS = 'apiKeys/create_success',
    CREATE_API_KEY_FAILED = 'apiKeys/create_failed',
    GET_REGISTRATION_TOKEN = 'apiKeys/get_registration_token',
    GET_REGISTRATION_TOKEN_SUCCESS = 'apiKeys/get_registration_token_success',
    DELETE_APP = 'apiKeys/delete',
    DELETE_API_KEY_SUCCESS = 'apiKeys/delete_success',
    DELETE_API_KEY_FAILED = 'apiKeys/delete_failed',
    SELECT_API_KEYS = 'apiKeys/select',
    CLOSE_API_KEY_DETAIL = 'apiKeys/close_app_detail',
    UPDATE_API_KEY = 'apiKeys/update',
    UPDATE_API_KEY_SUCCESS = 'apiKeys/update_success',
    UPDATE_API_KEY_FAILED = 'apiKeys/update_failed',
    CLEAR_API_KEY_LIST = 'apiKeys/clear_list',
    CLEAR_ACTION_STATUS = 'apiKeys/clear_action_status',
    REGENERATE_API_KEY = 'apiKeys/regenerate',
    REGENERATE_API_KEY_SUCCESS = 'apiKeys/regenerate_sucess',
    REGENERATE_API_KEY_FAILED = 'apiKeys/regenerate_fail',
}
