import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    CellContentTypes,
    gaImages,
    TextAlignTypes,
} from '../../../../../assets/globalConstants'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import {
    ICredentialsActivityModel,
    PaginationProps,
} from '../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { sessionSelectors } from '../../../../common/session'
import { credentialsScopes } from '../credentialsList/credentialsList.constants'
import styles from './credentialsTable.module.scss'
import {
    CredCreationDate,
    CredDeleteButton,
    CredDetailLink,
    CredGroup,
    CredIssuedId,
    CredIssuer,
    CredIssueRejectButtons,
    CredPendingId,
    CredStatus,
    CredStatusActions,
    CredType,
} from './issuedContent/CredetialsCells'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import ChevronDownIcon from '../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import { GaPagination } from '../../../../../Components/Pagination/Pagination'
import CopyCell from '../../../../../Components/organisms/table/cells/copyCell/CopyCell'
import { shortValue } from '../../../../../utils/formatDataUtil'

export type CredentialsTableProps = {
    tableData: ICredentialsActivityModel[]
    headerData: {
        key: string
        text: string
        textAlign?: TextAlignTypes
        type?: CellContentTypes
        tag?: boolean
        short?: boolean
        tagText?: string
        action?: (x?) => void
    }[]
    noResults?: boolean
    paginationData?: PaginationProps
    className?: string
    itemOptions?: {
        text: string
        value: string
        color?: string
    }[]
    pending?: boolean
    cantChangeCredentialsStatus?: boolean
    mobileItem?: ((x) => any) | ((x) => Element)
    selectItem?: (x?) => void
    deleteItem?: (x, y?, z?) => void
    handleDropdownChange: (x, y, z?) => void
    issueCredential?: (x?) => void
    rejectCredential?: (x?) => void
    handleOptions: (x, y?, z?) => void
}

const CredentialsTable: React.FC<CredentialsTableProps> = (props) => {
    const {
        tableData,
        headerData,
        paginationData,
        noResults,
        pending,
        cantChangeCredentialsStatus,
        deleteItem,
        mobileItem,
        selectItem,
        handleDropdownChange,
        issueCredential,
        rejectCredential,
    } = props
    const { t } = useTranslation()
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canEdit = !!(
        availableScopes &&
        userHasScopes(credentialsScopes?.edit, availableScopes, true)
    )

    const canValidate = !!(
        availableScopes &&
        userHasScopes(credentialsScopes?.validate, availableScopes, true)
    )

    const headersWithPermissions = () => {
        return !canEdit
            ? headerData?.filter((el) => {
                  return el?.key !== 'actions'
              })
            : !canValidate
            ? headerData?.filter((el) => {
                  return el?.key !== 'issueReject'
              })
            : headerData
    }

    useEffect(() => {}, [tableData])

    const getHeaderIcon = (type: CellContentTypes) => {
        let icon
        switch (type || '') {
            case CellContentTypes.date:
                icon = gaImages.clock
                break
            case CellContentTypes.action:
                icon = gaImages.arrowCircleDown
                break
            case CellContentTypes.modal:
                icon = gaImages.arrowCircleDown
                break
            case CellContentTypes.checkbox:
                icon = gaImages.checkbox
                break
            case CellContentTypes.detail:
                icon = gaImages.link
                break
            case CellContentTypes.icon:
                icon = gaImages.clock
                break
            case CellContentTypes.number:
                icon = gaImages.number
                break
            case CellContentTypes.text:
                icon = gaImages.textFile
                break
            default:
                icon = gaImages.textFile
        }
        return icon
    }

    const showPagination =
        paginationData?.totalItems &&
        paginationData.pageSize &&
        paginationData?.totalItems > paginationData.pageSize &&
        !noResults

    return (
        <>
            <LightPanel className={styles.table}>
                <table className="">
                    <thead>
                        <tr>
                            {headersWithPermissions()?.map((header, idx) => (
                                <th
                                    className={cx('testAlignLeft')}
                                    key={header.key + idx + Math.random()}
                                >
                                    <div>
                                        {header.text ? (
                                            <span
                                                className={cx(
                                                    'bodyBoldCap neutral700'
                                                )}
                                            >
                                                {t(header.text)}
                                            </span>
                                        ) : null}
                                        {idx !== headerData?.length - 1 ? (
                                            <ChevronDownIcon
                                                color="#707074"
                                                size={16}
                                            />
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                            {!pending ? <th></th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {!!tableData
                            ? tableData?.map((item, idx) => {
                                  let proofs =
                                      item?.data?.presentationRequest?.proof ||
                                      []

                                  let creator: string =
                                      proofs.length > 0
                                          ? proofs[0].creator?.split('#')[0] ||
                                            ''
                                          : ''
                                  return (
                                      <>
                                          <tr
                                              data-id={idx}
                                              key={idx + Math.random()}
                                              className={
                                                  !!mobileItem
                                                      ? styles.table__longDisplay
                                                      : ''
                                              }
                                          >
                                              {!!item ? (
                                                  !pending ? (
                                                      <>
                                                          <CopyCell
                                                              item={item}
                                                              mainData={
                                                                  item?.data
                                                                      ?.group
                                                              }
                                                              textToCopy={
                                                                  item.id
                                                              }
                                                              textToCopyDisplayValue={shortValue(
                                                                  creator,
                                                                  15,
                                                                  5
                                                              )}
                                                          />
                                                          <CredType
                                                              item={item}
                                                              {...idx}
                                                          />
                                                          <CredIssuer
                                                              item={item}
                                                              {...idx}
                                                          />
                                                          <CredCreationDate
                                                              item={item}
                                                              {...idx}
                                                          />
                                                          <CredStatus
                                                              item={item}
                                                              {...idx}
                                                          />
                                                          <CredStatusActions
                                                              item={item}
                                                              {...idx}
                                                              cantChangeCredentialsStatus={
                                                                  cantChangeCredentialsStatus
                                                              }
                                                              handleDropdownChange={
                                                                  canEdit
                                                                      ? handleDropdownChange
                                                                      : undefined
                                                              }
                                                              selectItem={
                                                                  selectItem
                                                              }
                                                              deleteItem={
                                                                  canEdit
                                                                      ? deleteItem
                                                                      : undefined
                                                              }
                                                          />
                                                      </>
                                                  ) : (
                                                      <>
                                                          <CredGroup
                                                              item={item}
                                                              {...idx}
                                                          />
                                                          <CredCreationDate
                                                              item={item}
                                                              {...idx}
                                                          />
                                                          <CredDetailLink
                                                              item={item}
                                                              {...idx}
                                                              selectItem={
                                                                  selectItem
                                                              }
                                                          />
                                                          {canValidate && (
                                                              <CredIssueRejectButtons
                                                                  item={item}
                                                                  {...idx}
                                                                  issueCredential={
                                                                      issueCredential
                                                                  }
                                                                  rejectCredential={
                                                                      rejectCredential
                                                                  }
                                                              />
                                                          )}
                                                      </>
                                                  )
                                              ) : null}
                                          </tr>
                                          {!!mobileItem ? (
                                              <tr
                                                  className={
                                                      styles.table__mobileDisplay
                                                  }
                                                  key={
                                                      'mobile' + idx.toString()
                                                  }
                                              >
                                                  {mobileItem(item)}
                                              </tr>
                                          ) : null}
                                      </>
                                  )
                              })
                            : null}
                    </tbody>
                </table>
            </LightPanel>

            {noResults ? (
                <Description
                    className={cx('margin-top')}
                    text={t('dids.noResults')}
                />
            ) : null}

            {paginationData && showPagination ? (
                <div>
                    <GaPagination {...paginationData} />
                </div>
            ) : null}
        </>
    )
}

export default CredentialsTable
