export const propertiesToSearchAllScopes = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const propertiesToSearchTenants = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
]
