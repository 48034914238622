import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GaImage from '../../../../../Components/atoms/Image/Image'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import MediumModal from '../../../../../Components/molecules/modals/Medium/MediumModal'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import QrBuilder from '../../../../../Components/qrBuilder/QrBuilder'
import TestQR from '../../../../../Components/testQR/testQR'
import { brandImages, gaImages } from '../../../../../assets/globalConstants'
import CloseIcon from '../../../../../assets/iconsLibrary/simple/close/closeIcon'
import { onPremise } from '../../../../../data/globalVar'
import { IApiKeyModel } from '../../../../../interfaces/interfaces'
import { apiKeysSelectors } from '../../../ApiKeys'
import { apiKeysScopes } from '../../../ApiKeys/views/apiKeysList/apiKeysList.constants'
import { homeSelectors } from '../../../Home'
import { modalConstants } from '../../../Home/HomeComponents/sandboxModal/sandboxModalConstants'
import { displayScan, hideScan } from '../../../Home/homeActions'
import { sessionSelectors } from '../../../../common/session'
import { getSelectedVerifier } from '../../verifierSelectors'
import { modalPrimaryButton, modalSecondaryButton } from './testModal.constants'
import styles from './testModal.module.scss'
import GaTextInputPassword from '../../../../../Components/atoms/formFields/textInputPassword/GaTextInputPassword'

type ITestModalProps = {
    show: boolean
    hideModal: () => void
}

const TestModal: React.FC<ITestModalProps> = (props) => {
    const { hideModal, show } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const currentTenant = useSelector(sessionSelectors.getCurrentTenant)
    const selectedVerifier = useSelector(getSelectedVerifier)
    const apiKeysListUnfiltered = useSelector(apiKeysSelectors.getApiKeys)
    const invitationUrl = useSelector(homeSelectors.invitationUrl) || ''

    const apiKeysList = apiKeysListUnfiltered?.filter(
        (ak: IApiKeyModel) => ak.ssi_operations?.includes(selectedVerifier?.id) //The apikey list should be filter for status == ACTIVE too.
    )

    const initialFormState = {
        apiKey: apiKeysList && !!apiKeysList[0] ? apiKeysList[0]?.id : '',
        password: '',
        callback: selectedVerifier?.callback || '',
    }
    const [formData, setFormState] = useState(initialFormState)
    const [showQR, setShowQRState] = useState(false)
    const [appToken, setAppTokenState] = useState('')
    const [filledDataError, setFilledDataError] = useState(false)
    const [displayQR, setDisplayQR] = useState(false)

    const { apiKey, password, callback } = formData

    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    useEffect(
        () => setFormState(initialFormState),
        [selectedVerifier, apiKeysListUnfiltered]
    )
    useEffect(() => {}, [invitationUrl])

    const validForm = !!apiKey?.trim() && !!password?.trim()
    const showSandBoxQR = () => {
        setDisplayQR(true), !onPremise && dispatch(displayScan())
    }

    const getAppToken = async () => {
        let response = await fetch('/admin/v1/api_keys/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + btoa(apiKey + ':' + password),
                tenant: currentTenant || '',
                ssiconfig: selectedVerifier?.id,
            },
            body: '{}',
            mode: 'cors',
            credentials: 'include',
        })
            .then((response) => {
                setShowQRState(response?.ok)
                response?.ok
                    ? setAppTokenState(response?.headers?.get('Token') || '')
                    : setFilledDataError(true)
            })
            .catch((error) => {
                setShowQRState(false)
                throw new Error(error)
            })
    }

    const hideAndCleanModal = () => {
        dispatch(hideScan())
        setDisplayQR(false)
        hideModal()
    }

    return !showQR ? (
        <MediumModal
            title={'createVerifier.almostReadyTitle'}
            subtitle={'createVerifier.needNextSteps'}
            headerClose={true}
            primaryButton={modalPrimaryButton}
            secondaryButton={modalSecondaryButton}
            primaryButtonFunction={() => getAppToken()}
            primaryButtonDisabled={!validForm}
            show={show}
            handleClose={hideAndCleanModal}
        >
            <p
                className={`${styles.testModalStep} ${cx(
                    'margin-bottom-min buttonSM neutral1000'
                )}`}
            >
                {t('createVerifier.testStep1')}
            </p>
            <form>
                <GaSelect
                    idSelect={'apiKey'}
                    className={cx('margin-bottom')}
                    value={apiKey}
                    id="select-apiKey"
                    isLoading={!apiKeysList?.length}
                    invalid={!!filledDataError}
                    labelText={t('createVerifier.selectApiKeyLabel')}
                    name={'apiKey'}
                    permissions={{
                        scopes: apiKeysScopes?.read,
                        every: true,
                    }}
                    required
                    onChange={(e) => {
                        setFormState({ ...formData, apiKey: e.target.value })
                    }}
                    options={apiKeysList}
                    optionTextProperty={'alias'}
                    optionValueProperty={'id'}
                />
                <GaTextInputPassword
                    className={cx('margin-bottom')}
                    id={'password'}
                    invalid={!!filledDataError}
                    errorTexts={[
                        !filledDataError
                            ? t('public.required')
                            : t('public.checkInvalidData'),
                    ]}
                    labelText={t('createVerifier.password')}
                    required={true}
                    name={'password'}
                    onChange={(e) =>
                        setFormState({ ...formData, password: e.target.value })
                    }
                    placeholder={t('createVerifier.password')}
                    value={password}
                    type={'password'}
                />
                <GaTextInput
                    className={cx('margin-bottom')}
                    id={'callback'}
                    invalid={false}
                    labelText={t('createVerifier.callback')}
                    required={false}
                    name={'callback'}
                    onChange={(e) => {
                        setFormState({ ...formData, callback: e.target.value })
                    }}
                    placeholder={t('createVerifier.callback')}
                    value={callback}
                />
            </form>

            {!onPremise ? (
                <>
                    <p
                        className={`${cx(
                            'buttonSM neutral1000',
                            styles.testModalStep
                        )}`}
                    >
                        {t('createVerifier.testStep2')}
                    </p>

                    <p
                        className={cx(
                            'bodyRegularSM neutral700',
                            styles.testModalStepInfo
                        )}
                    >
                        {t('createVerifier.joinYourSanboxLabel1')}
                    </p>

                    <p
                        className={cx(
                            'bodyRegularSM neutral700 marginTop0',
                            styles.testModalStepInfo
                        )}
                    >
                        {t('createVerifier.joinYourSanboxLabel2')}
                        <strong>
                            {t('createVerifier.joinYourSanboxLabel3')}
                        </strong>
                        {t('createVerifier.joinYourSanboxLabel4')}
                    </p>

                    {!displayQR ? (
                        <LightButton
                            className={styles.showSandBoxQRButton}
                            text={'createVerifier.scanNow'}
                            disabled={false}
                            functionality={showSandBoxQR}
                        />
                    ) : null}
                </>
            ) : (
                <>
                    <div className={styles.errorFilledPanel}>
                        <div className={styles.errorFilledPanel__content}>
                            <GaImage image={gaImages.redWarning} />
                            <div
                                className={`${styles.errorFilledPanel__content__texts}`}
                            >
                                <p className={`${styles.title}`}>
                                    {t('createVerifier.testStep2OPTitle')}
                                </p>

                                <p>
                                    {t('createVerifier.testStep2OPDesc1')}
                                    <a
                                        href="https://studio.gataca.io/"
                                        target="_blank"
                                    >
                                        {t(
                                            'createVerifier.testStep2OPDescLink'
                                        )}
                                    </a>
                                    {t('createVerifier.testStep2OPDesc2')}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className={styles.testModalSanboxQR}>
                {invitationUrl && displayQR ? (
                    <QrBuilder
                        size={modalConstants.size}
                        logoSize={modalConstants.logoSize}
                        qrData={invitationUrl}
                        logoSrc={brandImages?.brandLogo?.src}
                    ></QrBuilder>
                ) : displayQR ? (
                    <CardListSkeleton
                        className={styles.testModalSanboxSkeleton}
                        cardsNumber={2}
                    />
                ) : null}
            </div>
        </MediumModal>
    ) : (
        <>
            <div
                className={`${styles.backgroundLayer} ${
                    show ? styles.visibleMediumModal : styles.hiddenMediumModal
                }`}
            >
                <div className={styles.testModal__qr}>
                    <div
                        className={styles.testModal__qr__close}
                        onClick={() => {
                            dispatch(hideScan())
                            setDisplayQR(false)
                            setShowQRState(false)
                        }}
                    >
                        <CloseIcon size={24} color="#1E1E20" />
                    </div>
                    <TestQR
                        show={show}
                        configId={selectedVerifier?.id}
                        appName={apiKey}
                        appPassword={password}
                        successRoute={'verified_users'}
                        callBack={callback}
                        appToken={appToken}
                        verifier={true}
                        v2={
                            (selectedVerifier?.dataAgreementTemplate?.purposes
                                ?.length || 0) > 0
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default TestModal
