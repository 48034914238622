import AuthProcess2FA from '../../../../Components/molecules/modals/ScanModal/Steps/AuthProcess2FA'
import AuthProcessQR from '../../../../Components/molecules/modals/ScanModal/Steps/AuthProcessQR'
import ConnectionForm from '../../../../Components/molecules/modals/ScanModal/Steps/ConnectionForm'
import ServiceRequirements from '../../../../Components/molecules/modals/ScanModal/Steps/ServiceRequirements'
import { CellContentTypes } from '../../../../assets/globalConstants'
import { StepModalModel } from '../../../../interfaces/interfaces'
import { threshold } from '../../../../utils/fuzzySearchUtil'

export const headersConnectionsTable = [
    { key: 'conection', text: 'ew.connections.connection' },
    {
        key: 'dataAgreements',
        text: 'ew.connections.dataAgreements',
        type: CellContentTypes.array,
    },
    {
        key: 'actions',
        text: 'ew.connections.actions',
        type: CellContentTypes.action,
    },
]

export const connectionsScopes = {
    // TODO: Change to real scopes when API is ready
    read: ['readSessions'],
    edit: ['manageCredentials'],
}

export const scanConnectionSteps: StepModalModel[] = [
    {
        id: 1,
        order: 0,
        content: ConnectionForm,
    },
    {
        id: 2,
        order: 1,
        content: ServiceRequirements,
    },
    // Commented until 2AF is available
    // {
    //     id: 3,
    //     order: 2,
    //     content: AuthProcessQR,
    // },
    // {
    //     id: 4,
    //     order: 3,
    //     content: AuthProcess2FA,
    // },
]

export const propertiesToFuzzySearchConnections = [
    { key: 'dataReceiverId', threshold: threshold.low },
    { key: 'dataReceiver.name.en', threshold: threshold.medium },
    { key: 'dataReceiver.name.es', threshold: threshold.medium },
]
