import React from 'react'
import cx from 'classnames'
import styles from './noActionPreview.module.scss'
import GaImage from '../../../../../atoms/Image/Image'
import { gaImages } from '../../../../../../assets/globalConstants'
import { ImageModel } from '../../../../../../interfaces/interfaces'

type NoActionPreviewProps = {
    title?: string
    description?: string
    image?: ImageModel
}

const NoActionPreview: React.FC<NoActionPreviewProps> = (props) => {
    const { title, description, image } = props
    return (
        <div className={styles.noAction}>
            {image ? <GaImage image={image} /> : null}
            <h6 className={cx('heading6 neutral1000 marginTop24')}>{title}</h6>
            <p className={cx('bodyRegularSM neutral700')}>{description}</p>
        </div>
    )
}

export default NoActionPreview
