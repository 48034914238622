import React from 'react'

type EyeOnIconProps = {
    size?: number
    color?: string
    className?: string
}

const EyeOnIcon: React.FC<EyeOnIconProps> = React.memo((props) => {
    const { size, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.3335 16C1.3335 16 6.66683 5.33337 16.0002 5.33337C25.3335 5.33337 30.6668 16 30.6668 16C30.6668 16 25.3335 26.6667 16.0002 26.6667C6.66683 26.6667 1.3335 16 1.3335 16Z"
                stroke="#1E1E20"
                stroke-width="2.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
                stroke="#1E1E20"
                stroke-width="2.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
})

export default EyeOnIcon
