import React from 'react'

type CircleIconProps = {
    size?: number
    color?: string
    className?: string
}

const CircleIcon: React.FC<CircleIconProps> = React.memo((props) => {
    const { size, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill={color || 'currentColor' || '#DC3D43'}
            />
        </svg>
    )
})

export default CircleIcon
