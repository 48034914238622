import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    brandLabels,
    brandPaymentMethod,
} from '../../../../../assets/globalConstants'
import { licensesSelectors } from '../../../../../features/common/licenses'
import { ButtonModel, ITierModel } from '../../../../../interfaces/interfaces'
import DarkButton from '../../../../atoms/buttons/DarkButton/DarkButton'
import styles from './primaryLicenseButton.module.scss'
import cx from 'classnames'
import { noPaymentMethod } from '../../../../../data/globalVar'
import { getIfProductHasAmount } from '../../../../../utils/licensesUtil'
import { isDefaultBrandActive } from '../../../../../utils/brandThemeUtil'

type IPrimaryLicenseButtonProps = {
    license: ITierModel
    firstButton?: ButtonModel
    isCurrentLicense?: boolean
    lowerThanCurrent: boolean
    currentLicensePeriod: string
    noPaymentAction?: (x?) => void
}

const PrimaryLicenseButton: React.FC<IPrimaryLicenseButtonProps> = (props) => {
    const {
        license,
        firstButton,
        isCurrentLicense,
        lowerThanCurrent,
        currentLicensePeriod,
        noPaymentAction,
    } = props
    const { t } = useTranslation()
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)

    const licenseIsEnterprise = !getIfProductHasAmount(license, selectedPeriod)

    const getLabelFirstButton = () => {
        const currentLicensePeriodIsSelectedPeriod =
            currentLicensePeriod === selectedPeriod
        let buttonLabel = ''
        if (noPaymentMethod || !isDefaultBrandActive()) {
            buttonLabel = 'requestSubscription.requestSubscription'
        } else if (licenseIsEnterprise) {
            buttonLabel = t('subscription.contactUs')
        } else {
            switch (true) {
                case isCurrentLicense && !currentLicensePeriodIsSelectedPeriod:
                    buttonLabel =
                        currentLicensePeriod === 'year'
                            ? t('subscription.switchToMonthlyPlan', {
                                  type: t('subscription.thisPlan') || '',
                              })
                            : t('subscription.upgradeToYearlyPlan', {
                                  type: t('subscription.thisPlan') || '',
                              })
                    break
                case !isCurrentLicense:
                    buttonLabel = !lowerThanCurrent
                        ? t('subscription.upgradeTo') +
                          t('subscription.thisPlan')
                        : t('subscription.switchTo') +
                          t('subscription.thisPlan')
                    break
            }
        }

        return buttonLabel
    }

    const buttonAction = () => {
        if (!noPaymentMethod || !noPaymentAction || isDefaultBrandActive()) {
            return !licenseIsEnterprise
                ? firstButton?.function()
                : window?.open(brandLabels?.contactlink, '_blank')
        } else {
            return noPaymentAction(license)
        }
    }

    return !!isCurrentLicense ||
        !isCurrentLicense ||
        (!!isCurrentLicense &&
            currentLicensePeriod.toLowerCase() !== selectedPeriod) ? (
        <DarkButton
            className={cx('buttonSM', styles.license__button)}
            text={getLabelFirstButton()}
            disabled={false}
            functionality={buttonAction}
        />
    ) : null
}

export default PrimaryLicenseButton
