import chart from '../../../assets/images/Chart.png'
import notification from '../../../assets/images/notification.svg'
import { BlockModel, StepModalModel } from '../../../interfaces/interfaces'
import IntegrationCompleteForm from './HomeComponents/ageVerification/modal/Steps/IntegrationCompleteForm'
import LinksForm from './HomeComponents/ageVerification/modal/Steps/LinksForm'
import RedirectionForm from './HomeComponents/ageVerification/modal/Steps/RedirectionForm'
import ScopeForm from './HomeComponents/ageVerification/modal/Steps/ScopeForm'

export const homeHealine = 'dashboard.gettingStarted'
export const chartImage = chart
export const notificationIcon = notification

export const analytics = [
    {
        type: 'Sessions',
        data: 256,
    },
    {
        type: 'NewUsers',
        data: 1256,
    },
    {
        type: 'Users',
        data: 4,
    },
]

export const blocks: BlockModel[] = [
    {
        title: 'dashboard.firstIssuanceTitle',
        description: 'dashboard.firstIssuingDescription',
        type: 'issuances',
        route: '/create-issuer',
    },
    {
        title: 'dashboard.firstVerifierTitle',
        description: 'dashboard.firstVerifierDescription',
        type: 'verifiers',
        route: '/create-verifier',
    },
    {
        title: 'dashboard.firstVouchTitle',
        description: 'dashboard.firstVouchDescription',
        type: 'age-verification',
    },
    // Commented until invitations are available
    // {
    //     title: 'dashboard.inviteMembersTitle',
    //     subtitle: 'dashboard.inviteMembersHeadline',
    //     description: 'dashboard.inviteMembersDescription',
    //     icon: {
    //         src: inviteMembersIcon,
    //         alt: 'public.inviteMembersIconAlt',
    //     },
    //     type: 'users',
    //     rightText: 'dashboard.inviteMembersRightText',
    //     route: '/create-user',
    // },
]

export const ageVerificationSteps: StepModalModel[] = [
    {
        id: 1,
        order: 0,
        content: ScopeForm,
    },
    {
        id: 2,
        order: 1,
        content: RedirectionForm,
    },
    {
        id: 3,
        order: 2,
        content: LinksForm,
    },
    {
        id: 4,
        order: 3,
        content: IntegrationCompleteForm,
    },
]
