import {
    ISetActiveRoute,
    ISetSettings,
    ISetWalletActive,
    ScafoldingActionsTypes,
} from './scafoldingTypes'

// NAVIGATION
export const setSelectedRoute = (route: string): ISetActiveRoute => {
    return {
        type: ScafoldingActionsTypes.SET_ACTIVE_ROUTE,
        payload: {
            route: route,
        },
    }
}

// NAVIGATION
export const setSettings = (useSettings?: any): ISetSettings => {
    let settings = useSettings !== undefined
    return {
        type: ScafoldingActionsTypes.SET_SETTINGS_PANEL,
        payload: {
            settings: settings,
        },
    }
}

export const setWalletActive = (isWalletActive?: any): ISetWalletActive => {
    return {
        type: ScafoldingActionsTypes.SET_WALLET_ACTIVE,
        payload: {
            walletActive: isWalletActive,
        },
    }
}
