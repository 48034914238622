import { RootState } from '../../../store/indexReducers'

export const getCatalogAuthority = (state: RootState) =>
    state.catalogReducer.authority
export const getCatalogServiceProvider = (state: RootState) =>
    state.catalogReducer.serviceProviders
export const getCatalogGroups = (state: RootState) =>
    state.catalogReducer.credentialGroups
export const getCatalogTypes = (state: RootState) =>
    JSON.parse(JSON.stringify(state.catalogReducer.credentialTypes))
export const getLoadingStatus = (state: RootState) =>
    state.catalogReducer.loading
