import React, { FunctionComponent, SVGProps } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageModel } from '../../../../interfaces/interfaces'
import GaImage from '../../Image/Image'
import style from './linkButtonIcon.module.scss'
import cx from 'classnames'
import { ButtonSizes } from '../../../../data/globalVar'

type LinkButtonProps = {
    id?: string
    text: React.ReactNode | string
    SvgIcon?: FunctionComponent<SVGProps<SVGSVGElement>>
    disabled: boolean
    type?: 'button' | 'submit' | 'reset' | undefined
    icon?: ImageModel
    className?: string
    size?: ButtonSizes
    functionality?: ((x?: any) => void) | any
    iconRight?: boolean
    textColor?: string
}

const LinkButtonIcon: React.FC<LinkButtonProps> = React.memo((props) => {
    const {
        id,
        text,
        SvgIcon,
        type,
        disabled,
        icon,
        className,
        size,
        functionality,
        iconRight,
        textColor,
    } = props
    const { t } = useTranslation()

    const sizeFontClass = {
        LG: 'buttonLG',
        MD: 'buttonMD',
        SM: 'buttonSM',
    }

    return (
        <button
            id={id || ''}
            className={`${style.linkButtonIcon} ${className && className} ${cx(
                sizeFontClass[size || 'MD'],
                textColor ? textColor : 'primary700'
            )}`}
            onClick={functionality}
            disabled={disabled}
            type={type || 'button'}
        >
            {icon && SvgIcon ? (
                <>
                    {iconRight ? (
                        <>
                            <span
                                className={`${cx(
                                    sizeFontClass[size || 'MD'],
                                    textColor ? textColor : 'primary700'
                                )}`}
                            >
                                {text}
                            </span>
                            {SvgIcon ? (
                                <SvgIcon />
                            ) : icon ? (
                                <GaImage image={icon} />
                            ) : null}
                        </>
                    ) : (
                        <>
                            {SvgIcon ? (
                                <SvgIcon />
                            ) : icon ? (
                                <GaImage image={icon} />
                            ) : null}
                            <span
                                className={`${cx(
                                    sizeFontClass[size || 'MD'],
                                    textColor ? textColor : 'primary700'
                                )}`}
                            >
                                {text}
                            </span>
                        </>
                    )}
                </>
            ) : (
                <>{text}</>
            )}
        </button>
    )
})

export default LinkButtonIcon
