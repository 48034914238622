import { RootState } from '../../../store/indexReducers'
import {
    getCurrentTenant,
    getLicenses,
    getProviderOrg,
} from '../session/sessionSelectors'
import { licensesFeatures } from './licenses.constants'
import { createSelector } from 'reselect'

// export const getBlocks = (state:RootState): IBlock[] => state.organizationReducer.services
export const getCurrentProduct = (state: RootState) =>
    state.licensesReducer.currentProduct
export const getCurrentSubscriptionPeriod = (state: RootState) =>
    state.licensesReducer.currentSubscriptionPeriod
export const getSubscriptionInfo = (state: RootState) =>
    state.licensesReducer.subscriptionInfo
export const getSubscriptionInfoIsLoading = (state: RootState) =>
    state.licensesReducer.subscriptionInfoIsLoading
export const getNewLicense = (state: RootState) =>
    state.licensesReducer.newLicense
export const getNewLicensePeriod = (state: RootState) =>
    state.licensesReducer.newLicensePeriod
export const getSelectedPeriod = (state: RootState) =>
    state.licensesReducer.selectedPeriod
export const getProducts = (state: RootState) => state.licensesReducer.tiers
export const getProductsWithFeatures = (state: RootState) =>
    tiersAndFeatures(state.licensesReducer.tiers)
export const getCurrentStep = (state: RootState) =>
    state.licensesReducer.currentStep
export const getClientSecret = (state: RootState) =>
    state.licensesReducer.clientSecret
export const getClientSecretError = (state: RootState) =>
    state.licensesReducer.clientSecretErrorStatus
export const updatingSubscriptionLoading = (state: RootState) =>
    state.licensesReducer.updatingSubscriptionLoading
export const subscriptionInfoUpdatedSuccess = (state: RootState) =>
    state.licensesReducer.subscriptionInfoUpdatedSuccess
export const cancelDowngradeLoading = (state: RootState) =>
    state.licensesReducer.cancelDowngradeLoading
export const cancelSubscriptionLoading = (state: RootState) =>
    state.licensesReducer.cancelSubscriptionLoading
export const tiersAreLoading = (state: RootState) =>
    state.licensesReducer.tiersAreLoading
export const excededLimits = (state: RootState) =>
    state.licensesReducer.excededLimits
export const areReachedLimits = (state: RootState) =>
    state.licensesReducer.reachedLimits
export const isSendingPaymentMethod = (state: RootState) =>
    state.licensesReducer.sendingPaymentMethod
export const showActivatedAccountPanel = (state: RootState) =>
    state.licensesReducer.showAccountActivatedPanel
export const getSubscriptionPaymentMethod = (state: RootState) =>
    state.licensesReducer?.subscriptionInfo?.paymentMethod
export const getPreselectedLicense = (state: RootState) =>
    state.licensesReducer.preSelectedLicense
export const getRequestSubscritptionStatus = (state: RootState) =>
    state.licensesReducer.subscriptionRequestStatus

export const getProviderTiersAndFeatures = createSelector(
    [
        getProducts,
        getCurrentTenant,
        getProviderOrg,
        getLicenses,
        getSubscriptionInfo,
    ],
    (tiers, provider, providerOrg, licenses, subscriptionInfo) => {
        const prov =
            subscriptionInfo?.provider ||
            provider ||
            providerOrg ||
            (licenses?.length && licenses[0]?.providerId)
        return getProviderProductsWithFeatures(tiers, prov)
    }
)

const getProviderProductsWithFeatures = (tiers, provider) => {
    const providerProducts = tiers?.filter((tier) => tier.provider === provider)

    const sorteredProducts = providerProducts?.sort((a, b) =>
        a.index > b.index ? 1 : -1
    )

    return sorteredProducts
}

const tiersAndFeatures = (tiers) => {
    const onlyLicenses = tiers?.filter((tier) => {
        return !tier?.name.includes('Issued VCs')
    })
    for (const tier of onlyLicenses) {
        for (const license of licensesFeatures) {
            if (tier?.id === license.id_dev || tier?.id === license?.id) {
                tier.features = license?.features
                tier.licenseType = license?.licenseType
                tier.order = license?.order
                tier.description = license?.description
            }
        }
    }

    onlyLicenses.sort((a, b) => (a.index > b.index ? 1 : -1))
    return onlyLicenses
}
