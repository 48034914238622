import React from 'react'
import { useTranslation } from 'react-i18next'
import { gaImages } from '../../../../../../assets/globalConstants'
import {
    ButtonModel,
    IIssuanceConfigItemModel,
    ImageModel,
} from '../../../../../../interfaces/interfaces'
import styles from './deleteWarningModal.module.scss'
import GaImage from '../../../../../../Components/atoms/Image/Image'
import Description from '../../../../../../Components/atoms/texts/Description/Description'
import LightButton from '../../../../../../Components/atoms/buttons/LightButton/LightButton'
import { history } from '../../../../../../services/history'
import { useDispatch } from 'react-redux'
import { setSelectedRoute } from '../../../../../common/PanelScafolding/scafoldingActions'

type IDeleteWarningModalProps = {
    title?: string
    description?: string
    item: any
    icon?: ImageModel
    primaryButton: ButtonModel
    secondaryButton: ButtonModel
    affectedTemplates?: IIssuanceConfigItemModel[]
    hideModal: () => void
}

const DeleteWarningModal: React.FC<IDeleteWarningModalProps> = (props) => {
    const {
        title,
        description,
        icon,
        item,
        primaryButton,
        secondaryButton,
        affectedTemplates,
        hideModal,
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const continueFunction = (x, item) =>
        primaryButton?.function ? primaryButton?.function(item) : null

    return (
        <>
            <div
                className={`${styles.backgroundLayer}`}
                onClick={() => hideModal()}
            >
                <div className={styles.modal}>
                    <GaImage
                        className={styles.modal__icon}
                        image={icon || gaImages.yellowWarningSvg}
                    />
                    <p className={styles.modal__title}>
                        {t(title || 'certificates.sureYouWantDelete')}
                    </p>
                    <Description
                        className={styles.modal__description}
                        text={description || 'certificates.notAbleToRecover'}
                    />
                    {affectedTemplates?.length ? (
                        <>
                            <div className={styles.errorFilledPanel}>
                                <div
                                    className={styles.errorFilledPanel__content}
                                >
                                    <GaImage image={gaImages.redWarning} />
                                    <div
                                        className={`${styles.errorFilledPanel__content__texts}`}
                                    >
                                        <p className={`${styles.title}`}>
                                            {t(
                                                'certificates.issuanceTemplatesAffected'
                                            )}
                                        </p>

                                        <p>
                                            {t(
                                                'certificates.deleteFromTemplates',
                                                {
                                                    quantity:
                                                        affectedTemplates?.length,
                                                }
                                            )}
                                            <p
                                                className={styles.issuancesLink}
                                                onClick={() => {
                                                    history.push(
                                                        'issuance_templates'
                                                    )
                                                    dispatch(
                                                        setSelectedRoute(
                                                            'issuance_templates'
                                                        )
                                                    )
                                                }}
                                            >
                                                {t(
                                                    affectedTemplates?.length >
                                                        1
                                                        ? 'createVerifier.deleteFromTemplatesLink'
                                                        : 'certificates.deleteFromTemplatesSingLink'
                                                )}
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.templateList}>
                                {affectedTemplates?.map((templ) => {
                                    return (
                                        <>
                                            <div>{templ.id}</div>
                                        </>
                                    )
                                })}
                            </div>
                        </>
                    ) : null}
                    <div>
                        <LightButton
                            className={styles.modal__delete}
                            text={primaryButton?.label}
                            disabled={false}
                            functionality={(e) => continueFunction(e, item)}
                        />
                        <LightButton
                            className={styles.modal__cancel}
                            text={secondaryButton?.label}
                            disabled={false}
                            functionality={secondaryButton?.function}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteWarningModal
