export enum CredGroupMockedValues {
    'email' = 'mockedCredValues.email',
    'phone' = '+34 612 345 678',
    'address' = 'mockedCredValues.address',
    'government' = '123456789V',
    'driverslicense' = '234567890M',
    'facePhoto' = '',
    'university' = '7.8 Avg',
    'student' = '345678901A',
    'gatacaEmployee' = 'mockedCredValues.employee',
    'deviceInfo' = 'B64UD0JG-8305-7251-J3L7-2JKC52GXW973',
    'movement' = 'mockedCredValues.yes',
    'essentialWorker' = 'mockedCredValues.worker',
    'covid' = 'mockedCredValues.negative',
    'vid' = '123456789V',
    'conferenceTicket' = 'mockedCredValues.conferenceTicket',
    'allianceID' = '789012345K',
    'myAcademicID' = '456789012Y',
    'diploma' = 'mockedCredValues.diploma',
    'legalAge' = '21',
    'transcript' = 'mockedCredValues.transcript',
    'application' = 'mockedCredValues.application',
    'individualID' = '123456789V',
    'bankAccount' = 'ES12 3456 7890 1234 5678 9876',
    'creditCard' = '1234 5678 9012 3456 ',
    'Europass' = 'YU3456789V',
    'workshopTicket' = 'mockedCredValues.ticket',
    'educationID' = '890123456E',
    'ferryBoardingPass' = '67K725RS',
    'socialSecurity' = '2345 6789 0123',
    'physicalSecurity' = '2345 6789 0123',
    'boardingPass' = '67K725RS',
    'ePrescription' = 'mockedCredValues.preescription',
    'birthCertificate' = '12/08/1996',
    'MyAcademicID' = '901234567D',
}

export enum CredClaimsMockedValues {
    'emailCredential' = 'mockedCredValues.email',
    'addressCredential' = '',
    'addressOptCredential' = '',
    'postalCodeCredential' = '',
    'cityCredential' = '',
    'countryCredential' = '',
    'stateCredential' = '',
    'academicInstitutionCredential' = '',
    'gradeCredential' = '',
    'qualificationCredential' = '',
    'academicYearCredential' = 'YYYY',
    'studentIdCredential' = '345678901A',
    'phoneCredential' = '+34 612 345 678',
    'nationalityCredential' = '',
    'lastNameCredential' = 'Doe',
    'firstNameCredential' = 'Jhon',
    'documentIdCredential' = '123456789V',
    'documentTypeCredential' = '',
    'dlNumberCredential' = '123456798T',
    'expirationDateCredential' = 'DD/MM/YYYY',
    'expeditionIdCredential' = '456789012Y',
    'expeditionDateCredential' = 'DD/MM/YYYY',
    'birthDateCredential' = 'DD/MM/YYYY',
    'birthPlaceCredential' = '',
    'genderCredential' = '',
    'dlLicenseCredential' = '',
    'deviceIdCredential' = 'B64UD0JG-8305-7251-J3L7-2JKC52GXW973',
    'documentImageCredential' = '',
    'documentBackImageCredential' = '',
    'faceImageCredential' = '',
    'profileImageCredential' = '',
    'gatacaEmployeeCredential' = '567890129Z',
    'gatacaRoleCredential' = '',
    'employeeCredential' = '567890129Z',
    'positionCredential' = '',
    'companyCredential' = '',
    'specialPermitCredential' = '',
    'inmunityPermitCredential' = '',
    'essentialWorkerCredential' = '',
    'inmunityCredential' = '',
    'diagnosticCredential' = '',
    'vidCredential' = '678901234P',
    'conferenceTicketCredential' = 'mockedCredValues.conferenceTicket',
    'allianceIDCredential' = '678901234P',
    'myAcademicIDCredential' = '678901234P',
    'diplomaCredential' = '',
    'diplomaTypeCredential' = '',
    'studyProgramCredential' = '',
    'immatriculationNumberCredential' = '',
    'currentGivenNameCredential' = 'Jhon',
    'learningAchievementCredential' = '',
    'dateAchievementCredential' = 'DD/MM/YYYY',
    'overalEvaluationCredential' = '',
    'eqfLevelCredential' = '',
    'targetFrameworkNameCredential' = '',
    'legalAgeCredential' = '',
    'programCredential' = '',
    'vIdCredential' = '567890123B',
    'vIdTypeCredential' = '',
    'vIdIdentifierCredential' = '',
    'vIdDateBirthCredential' = 'DD/MM/YYYY',
    'vIdRegistrationPlaceCredential' = '',
    'vIdLastNameCredential' = 'Doe',
    'vIdFirstNameCredential' = 'John',
    'vIdNationalityCredential' = '',
    'vIdGenderCredential' = '',
    'vIdBsnCredential' = '',
    'vIdPortraitCredential' = '',
    'vIdDtc1Credential' = '',
    'applicationCredential' = '',
    'applicationIdCredential' = '678901234P',
    'applicationDateCredential' = 'DD/MM/YYYY',
    'individualIDCredential' = '678901234P',
    'bankAccountCredential' = 'ES12 3456 7890 1234 5678 9876',
    'creditCardCredential' = '1234 5678 9012 3456 ',
    'workshopTicketCredential' = 'mockedCredValues.ticket',
    'educationIDv1Credential' = '890123456E',
    'educationIDCredential' = '890123456E',
    'ferryBoardingPassCredential' = '67K725RS',
    'boardingPassCredential' = '67K725RS',
    'europassEDTCredential' = 'YU3456789V',
    'pensionCertificateCredential' = '2345 6789 0123',
    'keyCredential' = '105',
    'ePrescriptionCredential' = '',
    'birthCertificateCredential' = 'DD/MM/YYYY',
}
