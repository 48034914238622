import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import cx from 'classnames'
import styles from './stepsAppIntegration.module.scss'
import { IAppIntegrationModel } from '../../../../../../../interfaces/interfaces'
import EditAppIntModal from '../EditAppIntModal'
import { gaColors } from '../../../../../../../assets/globalConstants'
import PlusIcon from '../../../../../../../assets/iconsLibrary/simple/plus/plusIcon'
import LinkButtonIcon from '../../../../../../../Components/atoms/buttons/LinkButtonIcon/LinkButtonIcon'
import GaTextInput from '../../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import GaSelectCheckbox, {
    Selectable,
} from '../../../../../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import { useSelector } from 'react-redux'
import { appIntegrationsSelectors } from '../../../../../../../store/appIntegrations'

type IConfigurationFormProps = {
    formData: IAppIntegrationModel
    formsModified: boolean
    saveButtonFunction: (x?) => void
    hideModal: () => void
    handleInputChange: (x?) => void
    handleInputUrlChange: (e: any, x: number, y: string) => void
    removeUrl: (x: number, y: string) => void
    handleScopeChange: (x?: any) => void
    setEditAppIntCurrentStep: (x?: any) => void
}

const ConfigurationForm: React.FC<IConfigurationFormProps> = (props) => {
    const {
        formData,
        formsModified,
        saveButtonFunction,
        hideModal,
        handleScopeChange,
        handleInputChange,
        handleInputUrlChange,
        removeUrl,
        setEditAppIntCurrentStep,
    } = props

    useEffect(() => {}, [formData])

    // Constants
    const redirectValue = formData?.redirect_uris
    const logoutValue = formData?.post_logout_redirect_uris || ['']
    const [redirectInputs, setRedirectInputs] = useState(redirectValue)
    const [logoutInputs, setLogoutInputs] = useState(logoutValue)

    // Selectors
    const areScopesLoading = useSelector(
        appIntegrationsSelectors.areScopesLoading
    )
    const scopes = useSelector(appIntegrationsSelectors.selectableScopeList)

    // Functions
    const newRedirectInput = () => {
        setRedirectInputs([...redirectInputs, ''])
    }

    const newLogoutInput = () => {
        setLogoutInputs([...logoutInputs, ''])
    }

    const removeRedirectInput = (index: number, name: string) => {
        redirectInputs.splice(index, 1)
        setRedirectInputs(redirectInputs)
        removeUrl(index, name)
    }

    const removeLogoutInput = (index: number, name: string) => {
        logoutInputs.splice(index, 1)
        setLogoutInputs(logoutInputs)
        removeUrl(index, name)
    }

    return (
        <EditAppIntModal
            hideModal={hideModal}
            saveButton={{
                label: t('appIntegrations.edit.saveChanges'),
                function: saveButtonFunction,
                disabled: !formsModified,
            }}
            cancelBackButton={{
                label: t('public.cancel'),
                function: hideModal,
            }}
            onChangeFunction={setEditAppIntCurrentStep}
            progressIndicatorOptions={2}
            progressIndicatorCurrentOption={0}
        >
            <div className={`${styles.bodyContainer}`}>
                <form>
                    <GaTextInput
                        className={cx('margin-bottom')}
                        id={'client_name'}
                        invalid={!formData?.client_name?.trim()?.length}
                        labelText={t(
                            'appIntegrations.configuration.appNameLabel'
                        )}
                        required
                        name={'client_name'}
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                        placeholder={t(
                            'appIntegrations.configuration.appNamePlaceholder'
                        )}
                        showErrorsBeforeTouched
                        value={formData?.client_name}
                    />
                    <GaSelectCheckbox
                        className={cx('margin-bottom')}
                        checkedData={formData?.formScopes}
                        invalid={
                            !formData?.formScopes?.length ||
                            !formData?.formScopes[0]
                        }
                        labelText={t('appIntegrations.scopes')}
                        placeholder={t(
                            'appIntegrations.configuration.scopePlaceholder'
                        )}
                        showErrorsBeforeTouched
                        optionSubTextProperty={'subText'}
                        required
                        name={'formScopes'}
                        onChange={(e) => {
                            handleScopeChange(e)
                        }}
                        isLoading={areScopesLoading}
                        options={scopes as Selectable[]}
                        id={'scope'}
                        tooltip
                        infoText={t(
                            'appIntegrations.configuration.scopeTooltip'
                        )}
                    />
                    <div className={`${styles.multipleUrlsForm} `}>
                        {redirectInputs?.map((uri, index) => {
                            return (
                                <GaTextInput
                                    id={'redirectURL_' + index}
                                    key={'redirectURL_' + index}
                                    name={'redirect_uris'}
                                    labelText={
                                        index === 0
                                            ? t(
                                                  'dashboard.vouchModal.redirectionForm.redirectURLLabel'
                                              )
                                            : undefined
                                    }
                                    showErrorsBeforeTouched
                                    deleteFunctionality={
                                        index !== 0
                                            ? () => {
                                                  removeRedirectInput(
                                                      index,
                                                      'redirect_uris'
                                                  )
                                              }
                                            : undefined
                                    }
                                    value={
                                        uri?.length &&
                                        formData?.redirect_uris[index]
                                            ? formData?.redirect_uris[index]
                                            : ''
                                    }
                                    onChange={(e) => {
                                        handleInputUrlChange(
                                            e,
                                            index,
                                            'redirect_uris'
                                        )
                                    }}
                                    placeholder="https://url.com"
                                    required
                                    infoText={t(
                                        'dashboard.vouchModal.redirectionForm.redirectURLTooltip'
                                    )}
                                    tooltip
                                />
                            )
                        })}
                        {redirectInputs.length &&
                        formData?.redirect_uris[
                            redirectInputs?.length - 1
                        ]?.trim() ? (
                            <LinkButtonIcon
                                className={cx(styles.linkButton)}
                                functionality={newRedirectInput}
                                text={
                                    <span>
                                        <PlusIcon
                                            size={16}
                                            color={gaColors.primary700}
                                        />
                                        <p
                                            className={cx(
                                                'buttonSM primary700'
                                            )}
                                        >
                                            {t(
                                                'dashboard.vouchModal.redirectionForm.addUrl'
                                            )}
                                        </p>
                                    </span>
                                }
                                disabled={false}
                            />
                        ) : null}
                    </div>
                    <GaTextInput
                        className={cx('margin-bottom')}
                        id={'policy_uri'}
                        name={'policy_uri'}
                        labelText={t(
                            'dashboard.vouchModal.linksForm.privacyURLLabel'
                        )}
                        showErrorsBeforeTouched
                        value={formData?.policy_uri}
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                        placeholder="https://url.com"
                        required
                        tooltip
                        infoText={t(
                            'dashboard.vouchModal.linksForm.privacyURLTooltip'
                        )}
                    />

                    <GaTextInput
                        id={'tos_uri'}
                        name={'tos_uri'}
                        className={cx('margin-bottom')}
                        labelText={t(
                            'dashboard.vouchModal.linksForm.termsServiceURLLabel'
                        )}
                        value={formData?.tos_uri}
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                        showErrorsBeforeTouched
                        placeholder="https://url.com"
                        required
                        tooltip
                        infoText={t(
                            'dashboard.vouchModal.linksForm.termsServiceURLTooltip'
                        )}
                    />
                    <div className={`${styles.multipleUrlsForm} `}>
                        {logoutInputs?.map((uri, index) => {
                            return (
                                <GaTextInput
                                    id={'post_logout_redirect_uris'}
                                    name={'post_logout_redirect_uris'}
                                    labelText={
                                        index === 0
                                            ? t(
                                                  'appIntegrations.configuration.logoutURL'
                                              )
                                            : undefined
                                    }
                                    showErrorsBeforeTouched
                                    deleteFunctionality={
                                        index !== 0
                                            ? () => {
                                                  removeLogoutInput(
                                                      index,
                                                      'post_logout_redirect_uris'
                                                  )
                                              }
                                            : undefined
                                    }
                                    value={
                                        formData?.post_logout_redirect_uris &&
                                        formData?.post_logout_redirect_uris[
                                            index
                                        ]
                                            ? formData
                                                  ?.post_logout_redirect_uris[
                                                  index
                                              ]
                                            : ''
                                    }
                                    onChange={(e) => {
                                        handleInputUrlChange(
                                            e,
                                            index,
                                            'post_logout_redirect_uris'
                                        )
                                    }}
                                    placeholder="https://url.com"
                                    tooltip
                                    infoText={t(
                                        'appIntegrations.configuration.logoutTooltip'
                                    )}
                                />
                            )
                        })}
                        {logoutInputs.length &&
                        formData?.post_logout_redirect_uris &&
                        formData?.post_logout_redirect_uris[
                            logoutInputs.length - 1
                        ]?.trim() ? (
                            <LinkButtonIcon
                                className={cx(styles.linkButton)}
                                functionality={newLogoutInput}
                                text={
                                    <span>
                                        <PlusIcon
                                            size={16}
                                            color={gaColors.primary700}
                                        />
                                        <p
                                            className={cx(
                                                'buttonSM primary700'
                                            )}
                                        >
                                            {t(
                                                'dashboard.vouchModal.redirectionForm.addUrl'
                                            )}
                                        </p>
                                    </span>
                                }
                                disabled={false}
                            />
                        ) : null}
                    </div>
                </form>
            </div>
        </EditAppIntModal>
    )
}

export default ConfigurationForm
