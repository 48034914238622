import { IRoleModel } from '../../../../../../interfaces/interfaces'
import {
    ClearRoleList,
    IClearActionStatus,
    IClearRole,
    ICreateRoleAction,
    ICreateRoleFailedAction,
    ICreateRoleSuccessAction,
    IDeleteRoleAction,
    IDeleteRoleFailedAction,
    IDeleteRoleSuccessAction,
    IGetRoleAction,
    IGetRoleFailedAction,
    IGetRolesAction,
    IGetRolesFailedAction,
    IGetRolesSuccessAction,
    IGetRoleSuccessAction,
    IGetScopesAction,
    IGetScopesFailedAction,
    IGetScopesSuccessAction,
    ISelectRoleAction,
    IUpdateRoleAction,
    IUpdateRoleFailedAction,
    IUpdateRoleSuccessAction,
    RolesActionTypes,
} from './rolesTypes'

export const getRole = (roleId: string): IGetRoleAction => {
    return {
        type: RolesActionTypes.GET_ROLE,
        payload: {
            roleId: roleId,
        },
    }
}
export const getRoleSuccess = (roles: IRoleModel): IGetRoleSuccessAction => {
    return {
        type: RolesActionTypes.GET_ROLE_SUCCESS,
        payload: {
            role: roles,
        },
    }
}
export const getRoleFailed = (err: any): IGetRoleFailedAction => {
    return {
        type: RolesActionTypes.GET_ROLE_FAILED,
        payload: {
            error: err,
        },
    }
}

export const getRoles = (): IGetRolesAction => {
    return {
        type: RolesActionTypes.GET_ROLES,
    }
}
export const getRolesSuccess = (
    roles: IRoleModel[],
    tenants?: string
): IGetRolesSuccessAction => {
    return {
        type: RolesActionTypes.GET_ROLES_SUCCESS,
        payload: {
            roles: roles,
            tenants: tenants,
        },
    }
}
export const getRolesFailed = (err: any): IGetRolesFailedAction => {
    return {
        type: RolesActionTypes.GET_ROLES_FAILED,
        payload: {
            error: err,
        },
    }
}

export const getScopes = (): IGetScopesAction => {
    return {
        type: RolesActionTypes.GET_SCOPES,
    }
}
export const getScopesSuccess = (scopes: string[]): IGetScopesSuccessAction => {
    return {
        type: RolesActionTypes.GET_SCOPES_SUCCESS,
        payload: {
            scopes: scopes,
        },
    }
}
export const getScopesFailed = (err: any): IGetScopesFailedAction => {
    return {
        type: RolesActionTypes.GET_SCOPES_FAILED,
        payload: {
            error: err,
        },
    }
}

export const createRole = (role: IRoleModel): ICreateRoleAction => {
    return {
        type: RolesActionTypes.CREATE_ROLE,
        payload: {
            role: role,
        },
    }
}
export const createRoleSuccess = (): ICreateRoleSuccessAction => {
    return {
        type: RolesActionTypes.CREATE_ROLE_SUCCESS,
    }
}
export const createRoleFailed = (error: string): ICreateRoleFailedAction => {
    return {
        type: RolesActionTypes.CREATE_ROLE_FAILED,
        payload: {
            error: error,
        },
    }
}

export const deleteRole = (roleID: string): IDeleteRoleAction => {
    return {
        type: RolesActionTypes.DELETE_ROLE,
        payload: {
            roleID: roleID,
        },
    }
}
export const deleteRoleSuccess = (): IDeleteRoleSuccessAction => {
    return {
        type: RolesActionTypes.DELETE_ROLE_SUCCESS,
    }
}
export const deleteRoleFailed = (err: any): IDeleteRoleFailedAction => {
    return {
        type: RolesActionTypes.DELETE_ROLE_FAILED,
    }
}
export const selectRole = (role?: IRoleModel): ISelectRoleAction => {
    return {
        type: RolesActionTypes.SELECT_ROLE,
        payload: {
            role: role,
        },
    }
}

export const updateRole = (role: IRoleModel): IUpdateRoleAction => {
    return {
        type: RolesActionTypes.UPDATE_ROLE,
        payload: {
            role: role,
        },
    }
}

export const updateRoleSuccess = (): IUpdateRoleSuccessAction => {
    return {
        type: RolesActionTypes.UPDATE_ROLE_SUCCESS,
    }
}

export const updateRoleFailed = (error: string): IUpdateRoleFailedAction => {
    return {
        type: RolesActionTypes.UPDATE_ROLE_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearRole = (): IClearRole => {
    return {
        type: RolesActionTypes.CLEAR_CURRENT_ROLE,
    }
}

export const clearRoleList = (): ClearRoleList => {
    return {
        type: RolesActionTypes.CLEAR_ROLE_LIST,
    }
}

export const clearActionStatus = (): IClearActionStatus => {
    return {
        type: RolesActionTypes.CLEAR_ACTION_STATUS,
    }
}
