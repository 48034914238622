import cx from 'classnames'
import React from 'react'
import styles from './noLinkedServices.module.scss'
import { t } from 'i18next'
import { brandColors } from '../../../../../../../../assets/globalConstants'
import MehIcon from '../../../../../../../../assets/iconsLibrary/simple/meh/mehIcon'

type INoLinkedServicesProps = {}

const NoLinkedServices: React.FC<INoLinkedServicesProps> = () => {
    return (
        <div className={styles.noLinkedServices}>
            <div className={styles.noLinkedServices__content}>
                <MehIcon color={brandColors.neutral700} size={32} />
                <p className={cx('heading6 neutral700 marginAuto')}>
                    {t('ew.requestCredentials.sorry')}
                </p>
                <p className={cx('bodyRegularSM neutral700 marginAuto')}>
                    {t('ew.requestCredentials.noLinkedServices')}
                </p>
            </div>
        </div>
    )
}

export default NoLinkedServices
