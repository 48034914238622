import cx from 'classnames'
import React, { useState } from 'react'
import styles from './tutorial.module.scss'
import { t } from 'i18next'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../../data/globalVar'
import { gaColors } from '../../../../../assets/globalConstants'
import { tutorialSteps } from '../../data/AppIntegrationsList.constants'
import ChevronLeftIcon from '../../../../../assets/iconsLibrary/simple/chevronLeft/chevronLeftIcon'
import ActiveCircleIcon from '../../../../../assets/iconsLibrary/stepper-progress-bar/active-circle/activeCircleIcon'
import DefaultCircleIcon from '../../../../../assets/iconsLibrary/stepper-progress-bar/default-circle/defaultCircleIcon'

type ITutorialrops = {
    currentStep: number
    back: (x?: any) => void
    next: (x?: any) => void
    hideModal: (x?: boolean) => void
}

const Tutorial: React.FC<ITutorialrops> = (props) => {
    const { currentStep, next, back, hideModal } = props

    const StepContent = tutorialSteps[currentStep]?.component

    const [notShowAgain, setNotShowAgain] = useState(false)

    return (
        <div className={`${styles.backgroundLayer} ${styles.visibleModal} `}>
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    {currentStep > 0 ? (
                        <div onClick={back}>
                            <ChevronLeftIcon size={24} color={'#2D3648'} />
                        </div>
                    ) : null}

                    <div className={styles.modal__header__close}>
                        <LightButton
                            className={styles.modal__footer__lightButton}
                            functionality={hideModal}
                            text={'appIntegrations.tutorial.skip'}
                            disabled={false}
                            size={ButtonSizes.SM}
                        />
                    </div>
                </div>
                <div className={`${styles.modal__content}`}>
                    {<StepContent />}
                </div>
                <div className={styles.stepper}>
                    {currentStep === 0 ? (
                        <ActiveCircleIcon
                            size={10}
                            color={gaColors.primary700}
                        />
                    ) : (
                        <DefaultCircleIcon
                            size={8}
                            color={gaColors.neutral600}
                        />
                    )}
                    {currentStep === 1 ? (
                        <ActiveCircleIcon
                            size={10}
                            color={gaColors.primary700}
                        />
                    ) : (
                        <DefaultCircleIcon
                            size={8}
                            color={gaColors.neutral600}
                        />
                    )}
                    {currentStep === 2 ? (
                        <ActiveCircleIcon
                            size={10}
                            color={gaColors.primary700}
                        />
                    ) : (
                        <DefaultCircleIcon
                            size={8}
                            color={gaColors.neutral600}
                        />
                    )}
                    {currentStep === 3 ? (
                        <ActiveCircleIcon
                            size={10}
                            color={gaColors.primary700}
                        />
                    ) : (
                        <DefaultCircleIcon
                            size={8}
                            color={gaColors.neutral600}
                        />
                    )}
                </div>
                <div className={styles.modal__footer}>
                    <DarkButton
                        className={styles.modal__footer__primaryButton}
                        functionality={next}
                        text={
                            currentStep !== 3
                                ? 'public.next'
                                : 'appIntegrations.tutorial.finishTutorial'
                        }
                        disabled={false}
                        size={ButtonSizes.MD}
                    />
                    <div className={styles.notShowAgain}>
                        <input
                            type="checkbox"
                            disabled={false}
                            onChange={() => setNotShowAgain(!notShowAgain)}
                            checked={!!notShowAgain}
                        />
                        <p className={cx('bodyRegularXS')}>
                            {t('appIntegrations.tutorial.doNotShowAgain')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tutorial
