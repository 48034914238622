import React, { useState } from 'react'
import { t } from 'i18next'
import cx from 'classnames'
import styles from './previewModal.module.scss'
import { ButtonModel } from '../../../../../interfaces/interfaces'
import MediumModal from '../../../../../Components/molecules/modals/Medium/MediumModal'
import { gaColors } from '../../../../../assets/globalConstants'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import FormSection from '../../../../../Components/organisms/FormSection/FormSection'
import { ButtonSizes } from '../../../../../data/globalVar'
import MonitorIcon from '../../../../../assets/iconsLibrary/simple/monitor/monitorIcon'
import SmartphoneIcon from '../../../../../assets/iconsLibrary/simple/smathphone/smartphoneIcon'
import TabletIcon from '../../../../../assets/iconsLibrary/simple/tablet/tabletIcon'
import DesktopPreview from './responsivePreviews/desktop/DesktopPreview'
import TabletPreview from './responsivePreviews/tablet/TabletPreview'
import MobilePreview from './responsivePreviews/mobile/MobilePreview'
import Tutorial from '../tutorial/Tutorial'
import { useSelector } from 'react-redux'
import { orgSelectors } from '../../../../common/organization'
import { appIntegrationsSelectors } from '../../../../../store/appIntegrations'

type IVerificationPagePreviewProps = {
    saveButton?: ButtonModel
    showSaveButton?: boolean
    smallResolution: boolean
    show: boolean
    tutorialChecked: boolean
    companyLogoChecked: boolean
    companyLogo?: string
    selectedScopes?: string[]
    hideModal: (x?) => void
}

const VerificationPagePreview: React.FC<IVerificationPagePreviewProps> = (
    props
) => {
    const {
        saveButton,
        showSaveButton,
        smallResolution,
        show,
        tutorialChecked,
        companyLogoChecked,
        selectedScopes,
        companyLogo,
        hideModal,
    } = props

    // Selectors
    const orgName = useSelector(orgSelectors.getOrg)?.orgInfo?.name
    const scopes = useSelector(appIntegrationsSelectors.scopeList)

    enum PreviewSizes {
        'desktop' = 'desktop',
        'tablet' = 'tablet',
        'mobile' = 'mobile',
    }

    const selectedPreviewComponent = {
        desktop: (
            <DesktopPreview
                companyLogo={companyLogo}
                orgName={orgName}
                selectedScopes={selectedScopes}
                companyLogoChecked={companyLogoChecked}
                scopes={scopes}
            />
        ),
        tablet: (
            <TabletPreview
                companyLogo={companyLogo}
                orgName={orgName}
                selectedScopes={selectedScopes}
                companyLogoChecked={companyLogoChecked}
                scopes={scopes}
            />
        ),
        mobile: (
            <MobilePreview
                companyLogo={companyLogo}
                orgName={orgName}
                selectedScopes={selectedScopes}
                companyLogoChecked={companyLogoChecked}
                scopes={scopes}
            />
        ),
    }

    // States
    const [selectedPreview, setSelectedPreview] = useState<PreviewSizes>(
        PreviewSizes.desktop
    )
    const [showTutorial, setShowTutorial] = useState(false)
    const [tutorialStep, setTutorialStep] = useState(0)

    return (
        <>
            {smallResolution ? (
                <>
                    {show ? (
                        <MediumModal
                            title={t(
                                'appIntegrations.verificationPage.previewTitle'
                            )}
                            subtitle={t(
                                'appIntegrations.verificationPage.previewDesc'
                            )}
                            titleClassName={cx(
                                'heading6 neutral1000 marginBottom4'
                            )}
                            subtitleClassName={cx('bodyRegularSM neutral700')}
                            headerClose={true}
                            show={show}
                            handleClose={hideModal}
                            className={cx(
                                styles.previewModalResponsive,
                                styles.previewVerificationModalResponsive
                            )}
                        >
                            <div
                                className={cx(
                                    'marginBottom12',
                                    styles.previewResponsive
                                )}
                            >
                                <div
                                    className={cx(
                                        selectedPreview ===
                                            PreviewSizes.desktop &&
                                            styles.active
                                    )}
                                    onClick={() =>
                                        setSelectedPreview(PreviewSizes.desktop)
                                    }
                                >
                                    <MonitorIcon
                                        size={24}
                                        color={gaColors.neutral1000}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        selectedPreview ===
                                            PreviewSizes.tablet && styles.active
                                    )}
                                    onClick={() =>
                                        setSelectedPreview(PreviewSizes.tablet)
                                    }
                                >
                                    <TabletIcon
                                        size={24}
                                        color={gaColors.neutral700}
                                    />
                                </div>
                                <div
                                    className={cx(
                                        selectedPreview ===
                                            PreviewSizes.mobile && styles.active
                                    )}
                                    onClick={() =>
                                        setSelectedPreview(PreviewSizes.mobile)
                                    }
                                >
                                    <SmartphoneIcon
                                        size={24}
                                        color={gaColors.neutral700}
                                    />
                                </div>
                            </div>
                            {tutorialChecked ? (
                                <div
                                    className={cx(
                                        'marginTop12 marginBottom24',
                                        styles.previewTutorial
                                    )}
                                >
                                    <div>
                                        <p className={cx('buttonSM')}>
                                            {t(
                                                'appIntegrations.verificationPage.tutorial'
                                            )}
                                        </p>
                                        <p
                                            className={cx(
                                                'bodyRegularXS neutral800'
                                            )}
                                        >
                                            {t(
                                                'appIntegrations.verificationPage.previewTutorialDesc'
                                            )}
                                        </p>
                                    </div>
                                    <DarkButton
                                        text={
                                            'appIntegrations.verificationPage.showTutorial'
                                        }
                                        disabled={false}
                                        functionality={() =>
                                            setShowTutorial(true)
                                        }
                                        size={ButtonSizes.SM}
                                    />
                                </div>
                            ) : null}
                            {selectedPreviewComponent[selectedPreview]}
                        </MediumModal>
                    ) : null}
                </>
            ) : (
                <div className={styles.verificationPreviewContainer}>
                    <div className={styles.verificationPreview}>
                        <LightPanel>
                            <>
                                <div
                                    className={
                                        styles.verificationPreview__header
                                    }
                                >
                                    <FormSection
                                        title={
                                            'appIntegrations.verificationPage.previewTitle'
                                        }
                                        description={
                                            'appIntegrations.verificationPage.previewDesc'
                                        }
                                    ></FormSection>
                                    <div
                                        className={cx(
                                            'marginBottom12',
                                            styles.previewResponsive
                                        )}
                                    >
                                        <div
                                            className={cx(
                                                selectedPreview ===
                                                    PreviewSizes.desktop &&
                                                    styles.active
                                            )}
                                            onClick={() =>
                                                setSelectedPreview(
                                                    PreviewSizes.desktop
                                                )
                                            }
                                        >
                                            <MonitorIcon
                                                size={24}
                                                color={gaColors.neutral1000}
                                            />
                                        </div>
                                        <div
                                            className={cx(
                                                selectedPreview ===
                                                    PreviewSizes.tablet &&
                                                    styles.active
                                            )}
                                            onClick={() =>
                                                setSelectedPreview(
                                                    PreviewSizes.tablet
                                                )
                                            }
                                        >
                                            <TabletIcon
                                                size={24}
                                                color={gaColors.neutral700}
                                            />
                                        </div>
                                        <div
                                            className={cx(
                                                selectedPreview ===
                                                    PreviewSizes.mobile &&
                                                    styles.active
                                            )}
                                            onClick={() =>
                                                setSelectedPreview(
                                                    PreviewSizes.mobile
                                                )
                                            }
                                        >
                                            <SmartphoneIcon
                                                size={24}
                                                color={gaColors.neutral700}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>

                            <div className={`${styles.scrollableArea}`}>
                                <div
                                    className={styles.verificationPreview__body}
                                >
                                    {tutorialChecked ? (
                                        <div
                                            className={cx(
                                                'marginTop12 marginBottom24',
                                                styles.previewTutorial
                                            )}
                                        >
                                            <div>
                                                <p className={cx('buttonSM')}>
                                                    {t(
                                                        'appIntegrations.verificationPage.tutorial'
                                                    )}
                                                </p>
                                                <p
                                                    className={cx(
                                                        'bodyRegularXS neutral800'
                                                    )}
                                                >
                                                    {t(
                                                        'appIntegrations.verificationPage.previewTutorialDesc'
                                                    )}
                                                </p>
                                            </div>
                                            <DarkButton
                                                text={
                                                    'appIntegrations.verificationPage.showTutorial'
                                                }
                                                disabled={false}
                                                functionality={() =>
                                                    setShowTutorial(true)
                                                }
                                                size={ButtonSizes.SM}
                                            />
                                        </div>
                                    ) : null}
                                    {selectedPreviewComponent[selectedPreview]}
                                </div>
                            </div>

                            {showSaveButton && saveButton ? (
                                <div
                                    className={
                                        styles.verificationPreview__footer
                                    }
                                >
                                    <DarkButton
                                        text={saveButton?.label}
                                        disabled={!!saveButton?.disabled}
                                        functionality={
                                            saveButton?.function &&
                                            saveButton?.function
                                        }
                                    />
                                </div>
                            ) : null}
                        </LightPanel>
                    </div>
                </div>
            )}
            {showTutorial ? (
                <Tutorial
                    hideModal={() => {
                        setShowTutorial(false), setTutorialStep(0)
                    }}
                    currentStep={tutorialStep}
                    back={() =>
                        tutorialStep > 0
                            ? setTutorialStep(tutorialStep - 1)
                            : (setShowTutorial(false), setTutorialStep(0))
                    }
                    next={() =>
                        tutorialStep < 3
                            ? setTutorialStep(tutorialStep + 1)
                            : (setShowTutorial(false), setTutorialStep(0))
                    }
                />
            ) : null}
        </>
    )
}

export default VerificationPagePreview
