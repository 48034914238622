import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sessionSelectors } from '..'
import GaTextInput from '../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../Components/atoms/panels/LightPanel/LightPanel'
import ScreenHeader from '../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import { userHasScopes } from '../../../../utils/permissionsUtil'
import { updateUser } from '../../Team/tabsContent/users/store/usersActions'
import {
    rolesScopes,
    usersScopes,
} from '../../Team/tabsContent/users/store/team.constants'
import styles from './profile.module.scss'
import { rolesSelectors } from '../../Team/tabsContent/roles/store'
import { getRoles } from '../../Team/tabsContent/roles/store/rolesActions'

type IProfileProps = {}

const Profile: React.FC<IProfileProps> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const userEmail = useSelector(sessionSelectors.getUserEmail_s)
    const uRole = useSelector(sessionSelectors.getUserRole_s)
    const userDids = useSelector(sessionSelectors?.getUserDids)
    const userApiKeys = useSelector(sessionSelectors?.getUserApiKeys)
    const userTenant = useSelector(sessionSelectors?.getUserTenant)

    const userSSIOperations = useSelector(
        sessionSelectors?.getUserSSIOperations
    )
    const roles = useSelector(rolesSelectors.getRoles)
    const userOrganization = useSelector(sessionSelectors.getUserOrganization)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canReadRoles = !!(
        availableScopes && userHasScopes(rolesScopes?.read, availableScopes)
    )

    const canUpdateUsers = !!(
        availableScopes && userHasScopes(usersScopes?.edit, availableScopes)
    )

    const initialFormState = {
        name: '',
        email: userEmail,
        // TODO: Get role from state when it is saved in localstore
        role: uRole || roles[0]?.role_id,
        photo: undefined,
    }

    const [preview, setPreview] = useState('')
    const [formData, setFormState] = useState(initialFormState)
    const { name, email, role, photo } = formData

    useEffect(() => {
        canReadRoles && dispatch(getRoles())
    }, [])

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!photo) {
            setPreview('')
            return
        }

        const objectUrl = URL.createObjectURL(photo)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [photo])

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setFormState({ ...formData, photo: undefined })
            return
        }

        // Use the first image instead of multiple
        setFormState({ ...formData, photo: e.target.files[0] })
    }

    const saveUser = () => {
        const updatedUser = buildDataObject()
        dispatch(updateUser(updatedUser, email))
    }

    const buildDataObject = () => {
        const data = {
            email: userEmail,
            role: role,
            provider: 'gataca',
            org_ID: userOrganization || '',
            dids: userDids || [''],
            ssi_operations: userSSIOperations || [''],
            api_keys: userApiKeys || [''],
            tenant: userTenant,
        }
        return data
    }

    return (
        <React.Fragment>
            <ScreenHeader
                title={'profile.title'}
                subText={t('profile.description')}
                hideBorderBottom={true}
            />
            <LightPanel className={styles.formLightPanel}>
                <div className={styles.form}>
                    <div className={styles.form__textInputs}>
                        <GaTextInput
                            className={cx('margin-bottom')}
                            id={'email'}
                            labelText={t('profile.email')}
                            name={'email'}
                            value={email}
                            readOnly
                            onChange={(e) => {}}
                        />
                        <GaTextInput
                            className={cx('margin-bottom')}
                            id={'role'}
                            labelText={t('profile.role')}
                            name={'role'}
                            value={role}
                            readOnly
                            onChange={(e) => {}}
                        />
                    </div>
                </div>

                {/* THIS CAN BE USEFUL IN THE FUTURE
                {canUpdateUsers && (
                    <LightButton
                        className={cx('margin-top', 'margin-right-auto')}
                        text={'public.save'}
                        disabled={false}
                        functionality={saveUser}
                    />
                )} */}
            </LightPanel>
        </React.Fragment>
    )
}

Profile.defaultProps = {}

export default Profile
