import { t } from 'i18next'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ReactComponent as CloseLightIcon } from '../../../../assets/images/close-light.svg'
import { gaImages } from '../../../../assets/globalConstants'
import {
    setSelectedRoute,
    setSettings,
} from '../../../../features/common/PanelScafolding/scafoldingActions'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import LightButton from '../../../atoms/buttons/LightButton/LightButton'
import GaImage from '../../../atoms/Image/Image'
import style from './limitReachedModal.module.scss'

type ISandboxModalProps = {
    item: string
    hideModal: () => void
}

const LimitReachedModal: React.FC<ISandboxModalProps> = (props) => {
    const { hideModal, item } = props
    const dispatch = useDispatch()
    const history = useHistory()

    const supportLink = process.env.REACT_APP_SUPPORT_URL

    const hideAndCleanModal = () => {
        hideModal()
    }

    const navigateSubscription = () => {
        hideModal()
        dispatch(setSettings('subscription'))
        dispatch(setSelectedRoute('subscription')), history.push('subscription')
    }

    const openSupportPage = () => {
        window.open(supportLink, '_blank')
    }

    return (
        <div
            className={`${style.limitReachedModalBackgroundLayer} ${
                !!item ? style.visibleSandboxModal : style.hiddenSandboxModal
            }`}
        >
            <div className={style.limitReachedModal}>
                <div
                    className={style.limitReachedModal__header}
                    onClick={() => {
                        hideAndCleanModal()
                    }}
                >
                    <div className={style.limitReachedModal__header__close}>
                        <CloseLightIcon />
                    </div>
                    <GaImage
                        className={style.darkButton__icon}
                        image={gaImages.errorCircleIcon}
                    />
                    <p className={style.limitReachedModal__header__title}>
                        {t('subscription.subscriptionLimitReached')}
                    </p>
                </div>
                <p className={style.limitReachedModal__description}>
                    {t('subscription.subscriptionLimitReachedDescription', {
                        feature: t(item),
                    })}
                    ,{' '}
                </p>
                <DarkButton
                    className={style.button}
                    text={'subscription.upgradeNow'}
                    disabled={false}
                    functionality={navigateSubscription}
                />
                <LightButton
                    className={style.button}
                    text={'subscription.contactUs'}
                    disabled={false}
                    functionality={openSupportPage}
                />
            </div>
        </div>
    )
}

export default LimitReachedModal
