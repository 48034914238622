import cx from 'classnames'
import React, { Component, FunctionComponent } from 'react'
import styles from './scanModal.module.scss'
import { t } from 'i18next'
import CloseIcon from '../../../../assets/iconsLibrary/simple/close/closeIcon'
import LightButton from '../../../atoms/buttons/LightButton/LightButton'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../data/globalVar'
import { gaColors } from '../../../../assets/globalConstants'
import { ButtonModel } from '../../../../interfaces/interfaces'
import WarningPanel from '../../notification/panels/warning/warningPanel'
import InfoPanel from '../../notification/panels/info/infoPanel'
import AlertIcon from '../../../../assets/iconsLibrary/simple/alert/alertIcon'
import ProgressIndicator from '../../progress/ProgressIndicator/ProgressIndicator'

type IScanModalProps = {
    title?: string
    description?: string
    continueButton?: ButtonModel
    cancelButton?: ButtonModel
    progressIndicatorOptions?: number
    progressIndicatorCurrentOption?: number
    alert?: {
        type: 'info' | 'warning' | 'error'
        text: string
        icon?: Component | FunctionComponent | any
    }
    className?: string
    hideModal: (x?) => void
}

const ScanModal: React.FC<IScanModalProps> = (props) => {
    const {
        title,
        description,
        continueButton,
        cancelButton,
        progressIndicatorOptions,
        progressIndicatorCurrentOption,
        alert,
        className,
        children,
        hideModal,
    } = props

    return (
        <div
            className={cx(
                styles.backgroundLayer,
                styles.visibleModal,
                className && className
            )}
        >
            <div className={`${styles.modal}`}>
                {title || description ? (
                    <div className={styles.modal__header}>
                        <div className={styles.modal__header__top}>
                            <div className={styles.modal__header__top__texts}>
                                {title ? (
                                    <p className={cx('heading6 neutral1000')}>
                                        {t(title)}
                                    </p>
                                ) : null}
                                {description ? (
                                    <p
                                        className={cx(
                                            'bodyRegularMD neutral700 marginTop4'
                                        )}
                                    >
                                        {t(description)}
                                    </p>
                                ) : null}
                            </div>

                            <div
                                className={cx(styles.modal__header__top__close)}
                                onClick={hideModal}
                            >
                                <CloseIcon
                                    size={24}
                                    color={gaColors.neutral1000}
                                />
                            </div>
                        </div>
                        {progressIndicatorOptions &&
                        progressIndicatorCurrentOption ? (
                            <ProgressIndicator
                                optionsNumber={progressIndicatorOptions}
                                currentOption={progressIndicatorCurrentOption}
                            />
                        ) : null}
                    </div>
                ) : null}

                <div className={`${styles.modal__scrollable}`}>{children}</div>

                {alert ? (
                    alert?.type === 'info' ? (
                        <InfoPanel
                            hasIcon
                            classname={cx(styles.modal__alert, 'bodyRegularSM')}
                            label={t(alert?.text)}
                        />
                    ) : alert?.type === 'error' ? (
                        <WarningPanel
                            hasIcon
                            className={cx(
                                styles.modal__alert,
                                styles.modal__errorAlert,
                                'bodyRegularSM'
                            )}
                            IconComponent={
                                !alert?.icon ? (
                                    <AlertIcon
                                        color={gaColors.alert300}
                                        size={24}
                                    />
                                ) : (
                                    <alert.icon
                                        color={gaColors.warning400}
                                        size={24}
                                    />
                                )
                            }
                            label={t(alert?.text)}
                        />
                    ) : (
                        <WarningPanel
                            hasIcon
                            className={cx(
                                styles.modal__alert,
                                styles.modal__warningAlert,
                                'bodyRegularSM'
                            )}
                            IconComponent={
                                !alert?.icon ? (
                                    <AlertIcon
                                        color={gaColors.warning400}
                                        size={24}
                                    />
                                ) : (
                                    <alert.icon
                                        color={gaColors.warning400}
                                        size={24}
                                    />
                                )
                            }
                            label={t(alert?.text)}
                        />
                    )
                ) : null}
                {continueButton || cancelButton ? (
                    <div className={styles.modal__footer}>
                        {continueButton || cancelButton ? (
                            <div className={cx(styles.modal__footer)}>
                                {cancelButton ? (
                                    <LightButton
                                        className={
                                            styles.modal__footer__lightButton
                                        }
                                        functionality={cancelButton?.function}
                                        text={cancelButton?.label || ''}
                                        disabled={
                                            cancelButton?.disabled || false
                                        }
                                        size={ButtonSizes.MD}
                                    />
                                ) : null}
                                {continueButton ? (
                                    <DarkButton
                                        className={
                                            styles.modal__footer__primaryButton
                                        }
                                        functionality={continueButton?.function}
                                        text={continueButton?.label || ''}
                                        disabled={
                                            continueButton?.disabled || false
                                        }
                                        size={ButtonSizes.MD}
                                    />
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default ScanModal
