// STATE & REDUCER

import {
    MetricCode,
    MetricStatistic,
    Statistics,
} from '../../../interfaces/interfaces'

export interface IStatisticsState {
    isLoading: boolean
    statistics?: Statistics
    statisticsError?: boolean
}

// ACTIONS
export enum StatisticsTypes {
    GET_ALL_STATISTICS = 'stats/get',
    GET_ALL_STATISTICS_SUCCESS = 'stats/get_success',
    GET_ALL_STATISTICS_FAILED = 'stats/get_failed',
    GET_METRIC = 'stats/get_metric',
    GET_METRIC_SUCCESS = 'stats/get_metric_success',
    GET_METRIC_FAILED = 'stats/get_metric_failed',
}

export interface StatisticsParams {
    org?: string
    begin?: string
    end?: string
}

export interface IGetStatistics {
    type: StatisticsTypes.GET_ALL_STATISTICS
    payload?: StatisticsParams
}

export interface IGetStatisticsSuccess {
    type: StatisticsTypes.GET_ALL_STATISTICS_SUCCESS
    payload: {
        statistics: Statistics
    }
}

export interface IGetStatisticsFailed {
    type: StatisticsTypes.GET_ALL_STATISTICS_FAILED
    payload: {
        error: string
    }
}

export interface IGetMetric {
    type: StatisticsTypes.GET_METRIC
    payload?: StatisticsParams
    metricCode: MetricCode
}

export interface IGetMetricSuccess {
    type: StatisticsTypes.GET_METRIC_SUCCESS
    payload: {
        metricCode: MetricCode
        statistics: MetricStatistic
    }
}

export interface IGetMetricFailed {
    type: StatisticsTypes.GET_METRIC_FAILED
    payload: {
        error: string
    }
}

export type IStatisticsActions =
    | IGetStatistics
    | IGetStatisticsSuccess
    | IGetStatisticsFailed
    | IGetMetric
    | IGetMetricSuccess
    | IGetMetricFailed
