import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
    sideMenuExtraMobileOnPremiseOptions,
    sideMenuExtraMobileOptions,
} from '../../features/common/PanelScafolding/private/panelScafoldingPrivateConstants'
import { LinkModel } from '../../interfaces/interfaces'
import RightNav from '../RightNav/RightNav'
import styles from './burgerMenuButton.module.scss'
import { onPremise } from '../../data/globalVar'
import { scafoldingSelectors } from '../../features/common/PanelScafolding'

type IBurgerMenuProps = {
    menuOptions: LinkModel[]
    expanded?: string[]
    selected: string
    setExpanded: (x) => void
}

const BurgerMenu: React.FC<IBurgerMenuProps> = (props) => {
    const { menuOptions, expanded, selected, setExpanded } = props
    const isSettingsSelected = useSelector(scafoldingSelectors.showSettings)
    const currentRoute = useSelector(scafoldingSelectors.activeRoute)

    const sideMenuExtraMobileOptionsList = !onPremise
        ? sideMenuExtraMobileOptions
        : sideMenuExtraMobileOnPremiseOptions
    const activeRouteIsExpandable =
        currentRoute === 'issuers' ||
        currentRoute === 'verifiers' ||
        currentRoute === 'generalSettings'
    const [open, setOpen] = useState(!!activeRouteIsExpandable)

    const closeRightNav = () => {
        setOpen(false)
    }

    return (
        <>
            <div
                className={
                    open
                        ? styles.backgroundLayerActive
                        : styles.backgroundLayerInactive
                }
            ></div>
            <div
                className={
                    (open ? styles.open : styles.closed) +
                    ' ' +
                    styles.buttonContainer
                }
                onClick={() => setOpen(!open)}
            >
                <div />
                <div />
                <div />
            </div>
            <RightNav
                open={open}
                menuOptions={menuOptions}
                menuExtraMobileOptions={
                    !isSettingsSelected ? sideMenuExtraMobileOptionsList : []
                }
                expanded={expanded}
                selected={selected}
                setExpanded={(e) => {
                    setExpanded(e), setOpen(open)
                }}
                close={closeRightNav}
            />
        </>
    )
}

export default BurgerMenu
