import cx from 'classnames'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    brandLabels,
    countriesAndStates,
    domains,
} from '../../../../../../../assets/globalConstants'
import GaSelect from '../../../../../../../Components/atoms/formFields/select/GaSelect'
import GaTextInput from '../../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../../../Components/atoms/panels/LightPanel/LightPanel'
import Description from '../../../../../../../Components/atoms/texts/Description/Description'
import { initialLang } from '../../../../../../../i18n'
import styles from './changeSubscriptionSecondStep.module.scss'
import { Trans } from 'react-i18next'
import { getOrgAction } from '../../../../../organization/organizationActions'
import { onPremise } from '../../../../../../../data/globalVar'
import { StepModel } from '../../../../../../../interfaces/interfaces'
import { sessionSelectors } from '../../../../../session'
import GaCheckbox from '../../../../../../../Components/atoms/formFields/checkbox/GaCheckbox'

type IChangeSubscriptionSecondStepProps = {
    states
    formOrgData
    legalAddres
    email
    isNewUser?: boolean
    termsAreAccepted?: boolean
    validOrgForm?: boolean
    flowSteps: StepModel[]
    handleInputChange: (x?) => void
    handleTermsInput: (x?) => void
    handleAddressInputsChange: (x?) => void
    getStates: (x?) => void
    setIsNextStepActive: (x?) => void
}

const ChangeSubscriptionSecondStep: React.FC<
    IChangeSubscriptionSecondStepProps
> = (props) => {
    const {
        states,
        formOrgData,
        legalAddres,
        email,
        termsAreAccepted,
        isNewUser,
        validOrgForm,
        getStates,
        handleInputChange,
        handleTermsInput,
        handleAddressInputsChange,
        setIsNextStepActive,
    } = props
    const dispatch = useDispatch()

    const { name, legalName, domainName, VATRegistration, discoveryURL } =
        formOrgData
    const { street, number, suite, country, locality, postalCode, region } =
        legalAddres

    const tokenOrg = useSelector(sessionSelectors.getUserOrganization)

    useEffect(() => {
        if (tokenOrg) {
            !onPremise && dispatch(getOrgAction())
        }
    }, [])

    useEffect(() => {
        setIsNextStepActive(
            !!validOrgForm && (!isNewUser || termsAreAccepted) ? true : false
        )
    }, [formOrgData, legalAddres, termsAreAccepted])

    return (
        <React.Fragment>
            <div className={styles.changeSubscriptionSecondStep}>
                <Description
                    className={cx('bodyRegularMD neutral700 marginBottom32')}
                    text={'subscription.reviewOrganizationData'}
                />
                <LightPanel className={`${styles.form}`}>
                    <p
                        className={cx(
                            'heading6 neutral1000 marginBottom24',
                            styles.form__title
                        )}
                    >
                        {t('subscription.customerDetails')}
                    </p>
                    <GaTextInput
                        id={'email'}
                        labelText={t('profile.email')}
                        name={'email'}
                        value={email}
                        readOnly
                        onChange={(e) => {}}
                    />
                </LightPanel>
                <LightPanel className={cx('marginTop24')}>
                    <div className={styles.form}>
                        <p
                            className={cx(
                                'heading6 neutral1000 marginBottom24',
                                styles.form__title
                            )}
                        >
                            {t('subscription.organizationDetails')}
                        </p>
                        <div className={styles.form__textInputs}>
                            <GaTextInput
                                name="name"
                                invalid={false}
                                labelText={t('welcome.companyName')}
                                onChange={(e) => handleInputChange(e)}
                                placeholder={t('welcome.companyName')}
                                type="text"
                                value={name}
                                required
                            />
                            <GaTextInput
                                className={cx('marginTop12')}
                                name="legalName"
                                invalid={false}
                                labelText={t('welcome.legalName')}
                                onChange={(e) => handleInputChange(e)}
                                placeholder={t('welcome.companyName')}
                                type="text"
                                value={legalName}
                                required
                            />
                            <div
                                className={
                                    styles.form__textInputs__doubleColumn
                                }
                            >
                                <GaTextInput
                                    className={cx('marginTop12')}
                                    name="discoveryURL"
                                    invalid={false}
                                    labelText={t('login.website')}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={t('login.website')}
                                    type="text"
                                    value={discoveryURL}
                                    required
                                />
                                <GaSelect
                                    idSelect={'domainName'}
                                    value={domainName}
                                    className={cx('marginTop12')}
                                    name="domainName"
                                    invalid={false}
                                    isLoading={!states?.length}
                                    labelText={t('welcome.domainName')}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={t('welcome.domainName')}
                                    optionTextProperty={'text'}
                                    optionValueProperty={'value'}
                                    options={domains}
                                />
                            </div>

                            <GaTextInput
                                className={cx('marginTop12')}
                                name="street"
                                invalid={false}
                                labelText={t('welcome.street')}
                                onChange={(e) => handleAddressInputsChange(e)}
                                placeholder={t('welcome.street')}
                                type="text"
                                value={street}
                                required
                            />
                            <div
                                className={
                                    styles.form__textInputs__doubleColumn
                                }
                            >
                                <GaTextInput
                                    className={cx('marginTop12')}
                                    name="number"
                                    invalid={false}
                                    labelText={t('welcome.number')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    placeholder={t('welcome.number')}
                                    type="text"
                                    value={number}
                                    required
                                />
                                <GaTextInput
                                    className={cx('marginTop12')}
                                    name="suite"
                                    invalid={false}
                                    labelText={t('welcome.suite')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    placeholder={t('welcome.suite')}
                                    type="text"
                                    value={suite}
                                />
                            </div>
                            <div
                                className={
                                    styles.form__textInputs__doubleColumn
                                }
                            >
                                <GaSelect
                                    idSelect={'country'}
                                    value={country}
                                    name="country"
                                    className={cx('marginTop12')}
                                    invalid={false}
                                    isLoading={!countriesAndStates?.length}
                                    labelText={t('welcome.state')}
                                    optionValueProperty={
                                        initialLang
                                            ? 'name_' + initialLang
                                            : 'name_es'
                                    }
                                    optionTextProperty={
                                        initialLang
                                            ? 'name_' + initialLang
                                            : 'name_es'
                                    }
                                    onChange={(e) => {
                                        handleAddressInputsChange(e),
                                            getStates(e.target.value)
                                    }}
                                    options={countriesAndStates}
                                />
                                <GaSelect
                                    idSelect={'region'}
                                    value={region}
                                    name="region"
                                    className={cx('marginTop12')}
                                    invalid={false}
                                    isLoading={!states?.length}
                                    labelText={t('welcome.country')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    options={states}
                                />
                            </div>
                            <div
                                className={
                                    styles.form__textInputs__doubleColumn
                                }
                            >
                                <GaTextInput
                                    className={cx('marginTop12')}
                                    name="locality"
                                    invalid={false}
                                    labelText={t('welcome.locality')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    placeholder={t('welcome.locality')}
                                    type="text"
                                    value={locality}
                                    required
                                />

                                <GaTextInput
                                    className={cx('marginTop12')}
                                    name="postalCode"
                                    invalid={false}
                                    labelText={t('welcome.postalCode')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    placeholder={t('welcome.postalCode')}
                                    type="text"
                                    value={postalCode}
                                    required
                                />
                            </div>
                            <GaTextInput
                                className={cx('marginTop12')}
                                name="VATRegistration"
                                invalid={false}
                                labelText={t('welcome.nif')}
                                onChange={(e) => handleInputChange(e)}
                                placeholder={t('welcome.nif')}
                                type="text"
                                value={VATRegistration}
                                required
                            />
                        </div>
                        {isNewUser ? (
                            <div className={styles.terms}>
                                <GaCheckbox
                                    index={'0'}
                                    name="accept_terms"
                                    checked={termsAreAccepted}
                                    onChange={() =>
                                        handleTermsInput(!termsAreAccepted)
                                    }
                                    className={`${styles.required} ${cx(
                                        'marginRight8'
                                    )}`}
                                    id="accept-terms"
                                ></GaCheckbox>
                                <span
                                    className={cx(
                                        'neutral700 bodyRegularSM',
                                        styles.checkbox__text
                                    )}
                                >
                                    <Trans
                                        i18nKey={'welcome.agreeToGataca'}
                                        values={{ brand: brandLabels?.brand }}
                                    />
                                </span>
                                <a
                                    className={cx('primary700 bodyRegularSM')}
                                    target="_blank"
                                    href={brandLabels?.termsOfServiceLink}
                                >
                                    {t('welcome.termsOfUse')}
                                </a>
                                <span
                                    className={cx(
                                        'primary700 bodyRegularSM',
                                        styles.checkbox__text
                                    )}
                                >
                                    <Trans
                                        i18nKey={'welcome.and'}
                                        values={{ brand: brandLabels?.brand }}
                                    />
                                </span>
                                <a
                                    className={cx('primary700 bodyRegularSM')}
                                    target="_blank"
                                    href={brandLabels.privacypolicylink}
                                >
                                    {t('welcome.privacyPolicy')}
                                </a>
                            </div>
                        ) : null}
                    </div>
                </LightPanel>
            </div>
        </React.Fragment>
    )
}

ChangeSubscriptionSecondStep.defaultProps = {}

export default ChangeSubscriptionSecondStep
