import { ReactComponent as PlusLogo } from '../../../../assets/images/plus.svg'
import { ButtonModel } from '../../../../../interfaces/interfaces'

export const modalPrimaryButton: ButtonModel = {
    label: 'createVerifier.generatingTest',
}

export const modalSecondaryButton: ButtonModel = {
    label: 'public.cancel',
}

export const propertiesToSearchApiKeys = [
    {
        text: 'alias',
        searchMultipleLanguages: false,
        latinize: true,
    },
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
]
