import React from 'react'
import styles from './copyIconInput.module.scss'
import CopyIcon from '../../../../assets/iconsLibrary/simple/copy/copyIcon'

type CopyIconInputProps = {
    value: string
    size?: number
}

const CopyIconInput: React.FC<CopyIconInputProps> = React.memo((props) => {
    const { value, size } = props
    return (
        <div className={styles.copyButton}>
            <div
                className={styles.copyButton__button}
                onClick={() => {
                    navigator.clipboard.writeText(value || '')
                }}
            >
                <CopyIcon size={size ? size : 16} color="#1E1E20" />
            </div>
        </div>
    )
})

export default CopyIconInput
