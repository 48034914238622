import { IScopesActions, IScopesState, ScopesTypes } from './scopesTypes'

const mockScopes = {
    email: [
        {
            type: 'emailCredential',
            purpose: 'authentication',
            mandatory: true,
            trustLevel: 0,
        },
    ],
    email_validated: [
        {
            type: 'emailCredential',
            purpose: 'authentication',
            mandatory: true,
            trustLevel: 1,
        },
    ],
    device: [
        {
            type: 'deviceIdCredential',
            purpose: 'authentication',
            mandatory: true,
            trustLevel: 0,
        },
    ],
}

const scopesState: IScopesState = {
    isLoading: false,
    scopes: mockScopes,
}

export const scopesReducer = (
    state = scopesState,
    action: IScopesActions
): IScopesState => {
    switch (action.type) {
        case ScopesTypes.SUBMIT_SCOPES:
            // console.log('REDUCER_SUBMIT_SCOPES', action)
            return {
                ...state,
                isLoading: true,
            }
        case ScopesTypes.UPDATE_SCOPES:
            // console.log('REDUCER_UPDATE_SCOPES', action)
            return {
                ...state,
                isLoading: false,
                scopes: action.scopes,
            }
        default:
            return state
    }
}
