import * as React from 'react'
import cx from 'classnames'
import styles from './categoryCell.module.scss'
import { t } from 'i18next'

export type ICategoryCellProps = {
    category: string
    rowsPan: number
}

const CategoryCell: React.FC<ICategoryCellProps> = (props) => {
    const { category, rowsPan } = props
    return (
        <td
            className={`${styles?.categoryCell} ${cx('bodyBoldCap')}`}
            rowSpan={rowsPan}
        >
            {t(category)}
        </td>
    )
}

export default CategoryCell
