import { validEmailRegex, validEmailRegex2 } from './regexs'

export const isValidEmail = (email: string) => {
    const emailIsFilled = email?.trim()?.length
    const emailIsValid =
        email?.match(validEmailRegex) && email?.match(validEmailRegex2)
    return emailIsFilled && emailIsValid ? true : false
}

export const isValidURL = (url: string) => {
    const urlIsFilled = url?.trim()?.length
    const urlIsValid =
        url?.match(validEmailRegex) && url?.match(validEmailRegex2)
    return urlIsFilled && urlIsValid ? true : false
}
