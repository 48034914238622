import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    ConfigIssuanceFormActions,
    ConfigIssuanceFormActionsTypes,
    CreateNewPurposeActions,
    CreateNewPurposeActionsTypes,
    IConfigIssuanceFormState,
    ICreateNewPurposeActionsState,
    IssuanceActionsTypes,
    IssuanceListActions,
    IssuancesListState,
} from './issuanceTypes'

// LIST
const initialIssuanceListState: IssuancesListState = {
    issuances: null,
    loadingStatus: false,
    status: ActionStatusTypes.neutral,
    updateStatus: ActionStatusTypes.neutral,
    deletedConfigId: undefined,
}

export function issuancesReducer(
    state = initialIssuanceListState,
    action: IssuanceListActions
): IssuancesListState {
    switch (action.type) {
        case IssuanceActionsTypes.GET_ISSUANCES:
            return {
                issuances: null,
                status: ActionStatusTypes.loading,
                loadingStatus: true,
            } as IssuancesListState
        case IssuanceActionsTypes.GET_ISSUANCES_SUCCESS:
            return {
                issuances: action?.payload?.issuances,
                status: ActionStatusTypes.neutral,
                loadingStatus: false,
            } as IssuancesListState
        case IssuanceActionsTypes.GET_ISSUANCES_FAILED:
            return {
                issuances: null,
                status: ActionStatusTypes.failed,
                loadingStatus: false,
            } as IssuancesListState
        case IssuanceActionsTypes.CREATE_ISSUANCE:
            return {
                status: ActionStatusTypes.loading,
            } as IssuancesListState
        case IssuanceActionsTypes.CREATE_ISSUANCE_FAILED:
            return {
                status: ActionStatusTypes.failed,
            } as IssuancesListState
        case IssuanceActionsTypes.CREATE_ISSUANCE_SUCCESS:
            return {
                status: ActionStatusTypes.success,
            } as IssuancesListState
        case IssuanceActionsTypes.UPDATE_ISSUANCE:
            return {
                status: ActionStatusTypes.loading,
            } as IssuancesListState
        case IssuanceActionsTypes.UPDATE_ISSUANCE_SUCCESS:
            return {
                ...state,
                status: ActionStatusTypes.success,
                updateStatus: ActionStatusTypes.success,
            }
        case IssuanceActionsTypes.UPDATE_ISSUANCE_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
            }
        case IssuanceActionsTypes.DELETE_ISSUANCE_SUCCESS:
            return {
                ...state,
                deletedConfigId: action?.payload?.id,
            }
        case IssuanceActionsTypes.CLEAR_ISSUANCES_GENERAL_STATE:
            return initialIssuanceListState as IssuancesListState
        default:
            return state
    }
}

// UPDATE AND CREATE FORMS
const initialFormState: IConfigIssuanceFormState = {
    steps: [],
    firstStep: null,
    step: null,
    finalStep: null,
    credentialsSelected: null,
    selectedCredential: null,
    credentialsSelectedInitial: null,
    purposesList: [],
    issuance: null,
    selectedIssuance: {
        id: '',
    },
    credentialTypesSelected: null,
    credentialTypesSelectedInitial: null,
    credentialGroupSelected: null,
    credentialGroupSelectedInitial: null,
}

export function configIssuanceFormReducer(
    state = initialFormState,
    action: ConfigIssuanceFormActions | IssuanceListActions
): IConfigIssuanceFormState {
    switch (action.type) {
        case ConfigIssuanceFormActionsTypes.SETSTEP:
            return {
                ...state,
                step: action.payload.step,
                credentialsSelected: state.credentialsSelectedInitial,
                credentialTypesSelected: state.credentialTypesSelectedInitial,
                credentialGroupSelected: state.credentialGroupSelectedInitial,
            } as IConfigIssuanceFormState
        case ConfigIssuanceFormActionsTypes.SETSTEPS:
            const lastPosition = action.payload.steps.length - 1
            return {
                ...state,
                steps: action.payload.steps,
                firstStep: action.payload.steps[0],
                step: action.payload.steps[0],
                finalStep: action.payload.steps[lastPosition],
            } as IConfigIssuanceFormState
        case ConfigIssuanceFormActionsTypes.SET_SELECTED_CREDENTIALS:
            return {
                ...state,
                credentialsSelected: action.payload.credentials,
            } as IConfigIssuanceFormState
        case ConfigIssuanceFormActionsTypes.SET_SELECTED_CREDENTIAL:
            return {
                ...state,
                selectedCredential: action.payload,
            } as IConfigIssuanceFormState
        case ConfigIssuanceFormActionsTypes.CLEAR_SELECTED_CREDENTIAL:
            return {
                ...state,
                selectedCredential: null,
            } as IConfigIssuanceFormState
        case ConfigIssuanceFormActionsTypes.SET_PURPOSES:
            return {
                ...state,
                purposesList: action.payload.purposes,
            } as IConfigIssuanceFormState
        case ConfigIssuanceFormActionsTypes.SET_VERIFIER:
            return {
                ...state,
                issuance: action.payload.issuance,
            } as IConfigIssuanceFormState
        case ConfigIssuanceFormActionsTypes.CLEAR_ISSUANCE_FORM:
            return initialFormState as IConfigIssuanceFormState
        case ConfigIssuanceFormActionsTypes.SET_QR_VERIFIER:
            return {
                ...state,
                qrIssuance: action.payload,
            } as IConfigIssuanceFormState
        case IssuanceActionsTypes.UPDATE_ISSUANCE_SUCCESS:
            const savedRequestedCredentials =
                action.payload?.issuance?.prConfig?.credentials || null
            const savedCredentialTypesToIssue =
                action.payload?.issuance?.credentialTypes || null
            return {
                ...state,
                selectedIssuance: action.payload?.issuance || { id: '' },
                credentialsSelectedInitial: savedRequestedCredentials,
                credentialTypesSelected: savedCredentialTypesToIssue,
                credentialTypesSelectedInitial: savedCredentialTypesToIssue,
            }
        case ConfigIssuanceFormActionsTypes.EDIT_ISSUANCE:
            const requestedCredentials = action.payload?.prConfig?.credentials
            const credentialTypesToIssue = action.payload?.credentialTypes
            const credentialsGroupToIssue = action.payload?.credentialGroup
            return {
                ...state,
                selectedIssuance: action.payload || { id: '' },
                credentialsSelected: requestedCredentials,
                credentialsSelectedInitial: requestedCredentials,
                credentialTypesSelected: credentialTypesToIssue,
                credentialTypesSelectedInitial: credentialTypesToIssue,
                credentialGroupSelected: credentialsGroupToIssue,
                credentialGroupSelectedInitial: credentialsGroupToIssue,
            } as IConfigIssuanceFormState
        case ConfigIssuanceFormActionsTypes.CLEAR_ISSUANCE:
            return {
                ...state,
                selectedIssuance: { id: '' },
            }
        default:
            return state
    }
}

const createNewPurposeState: ICreateNewPurposeActionsState = {
    newPurposeFormData: null,
}

export function purposesReducer(
    state = createNewPurposeState,
    action: CreateNewPurposeActions
): ICreateNewPurposeActionsState {
    switch (action.type) {
        case CreateNewPurposeActionsTypes.SET_NEW_PURPOSE:
            return {
                ...state,
                newPurposeFormData: action.payload,
            } as ICreateNewPurposeActionsState
        case CreateNewPurposeActionsTypes.CLEAR_NEW_PURPOSE:
            return {
                ...state,
                newPurposeFormData: null,
            } as ICreateNewPurposeActionsState
        default:
            return state
    }
}
