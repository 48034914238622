import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    ILicenseItem,
    ITierModel,
    ISubscriptionInfo,
    LicenseToPreselect,
    PaymentMethodModel,
    StepModel,
} from '../../../interfaces/interfaces'

export interface IUpdateLicense {
    type: LicensesTypes.UPDATE_LICENSE
    payload: { newLicense: ITierModel; priceId: string; orgID: string }
}

export interface IUpdateLicenseSuccess {
    type: LicensesTypes.UPDATE_LICENSE_SUCCESS
    payload: { subscriptionInfo? }
}

export interface IUpdateLicenseFailed {
    type: LicensesTypes.UPDATE_LICENSE_FAILED
    error: any
}

export interface IGetClientSecret {
    type: LicensesTypes.GET_CLIENT_SECRET
}

export interface IGetClientSecretSuccess {
    type: LicensesTypes.GET_CLIENT_SECRET_SUCCESS
    payload: {
        clientSecret: string
    }
}
export interface IGetClientSecretFailed {
    type: LicensesTypes.GET_CLIENT_SECRET_FAILED
    error?: any
}

export interface IGetSubscriptionInfo {
    type: LicensesTypes.GET_SUBSCRIPTION_INFO
    payload: {
        hideLoader?: boolean
    }
}

export interface IGetSubscriptionInfoSuccess {
    type: LicensesTypes.GET_SUBSCRIPTION_INFO_SUCCESS
    payload: {
        subscriptionInfo: ISubscriptionInfo
    }
}
export interface IGetSubscriptionInfoFailed {
    type: LicensesTypes.GET_SUBSCRIPTION_INFO_FAILED
}
export interface IGetCurrentSubscriptionInfo {
    type: LicensesTypes.GET_CURRENT_SUBSCRIPTION_INFO
}
export interface ICancelSubscription {
    type: LicensesTypes.CANCEL_SUBSCRIPTION
}

export interface ICancelSubscriptionSuccess {
    type: LicensesTypes.CANCEL_SUBSCRIPTION_SUCCESS
    payload: {
        subscription: string
    }
}
export interface ICancelSubscriptionFailed {
    type: LicensesTypes.CANCEL_SUBSCRIPTION_FAILED
}

export interface ICancelDowngrade {
    type: LicensesTypes.CANCEL_DOWNGRADE
}

export interface ICancelDowngradeSuccess {
    type: LicensesTypes.CANCEL_DOWNGRADE_SUCCESS
    payload: {
        subscription: string
    }
}
export interface ICancelDowngradeFailed {
    type: LicensesTypes.CANCEL_DOWNGRADE_FAILED
}

export interface IGetTiers {
    type: LicensesTypes.GET_TIERS
}

export interface IGetTiersSuccess {
    type: LicensesTypes.GET_TIERS_SUCCESS
    payload: {
        tiers: ITierModel[]
    }
}
export interface IGetTiersFailed {
    type: LicensesTypes.GET_TIERS_FAILED
}

export interface ISetAvailableLicenses {
    type: LicensesTypes.SET_AVAILABLE_LICENSES
    licenses: ITierModel[]
}

export interface ISetCurrentLicense {
    type: LicensesTypes.SET_CURRENT_LICENSE
    license: ILicenseItem
}

export interface ISetNewLicense {
    type: LicensesTypes.SET_NEW_LICENSE
    license: ITierModel
}

export interface ISetCurrentTier {
    type: LicensesTypes.SET_CURRENT_TIER
    tier: ITierModel
}

export interface ISetSelectedPeriod {
    type: LicensesTypes.SET_SELECTED_PERIOD
    period: string
}

export interface ISetCurrentStep {
    type: LicensesTypes.SET_CURRENT_STEP
    step: StepModel
}

export interface ISetExcededLimits {
    type: LicensesTypes.SET_EXCEDED_LIMITS
    excededLimits: string[]
}

export interface ISetReachedLimits {
    type: LicensesTypes.SET_REACHED_LIMITS
    limitsAreReached: string
}

export interface ISendPaymentMethod {
    type: LicensesTypes.SEND_PAYMENT_METHOD
    payload: {
        paymentMethod?: PaymentMethodModel
    }
}

export interface ISendPaymentMethodSuccess {
    type: LicensesTypes.SEND_PAYMENT_METHOD_SUCCESS
}

export interface ISendPaymentMethodFailed {
    type: LicensesTypes.SEND_PAYMENT_METHOD_FAILED
}

export interface ISetShowAccountActivated {
    type: LicensesTypes.SET_SHOW_ACCOUNT_ACTIVATED
    payload: {
        showActivatedAccount: boolean
    }
}

export interface IRemoveClientSecret {
    type: LicensesTypes.REMOVE_CLIENT_SECRET
}

export interface IPreselectLicense {
    type: LicensesTypes.SET_PRESELECTED_LICENSE
    payload: {
        license?: LicenseToPreselect
    }
}

export interface IRequestSubscription {
    type: LicensesTypes.REQUEST_SUBSCRIPTION
    payload: {
        priceId: string
        orgId: string
        message?: string
    }
}

export interface IRequestSubscriptionSuccess {
    type: LicensesTypes.REQUEST_SUBSCRIPTION_SUCCESS
    payload: { subscriptionInfo?: any }
}

export interface IRequestSubscriptionFailed {
    type: LicensesTypes.REQUEST_SUBSCRIPTION_FAILED
    error: any
}

export interface IResetRequestSubscriptionStatus {
    type: LicensesTypes.RESET_REQUEST_SUBSCRIPTION_STATUS
}

export enum LicensesTypes {
    UPDATE_LICENSE = 'licenses/update',
    UPDATE_LICENSE_SUCCESS = 'licenses/update_success',
    UPDATE_LICENSE_FAILED = 'licenses/update_failed',
    REQUEST_SUBSCRIPTION = 'licenses/request',
    REQUEST_SUBSCRIPTION_SUCCESS = 'licenses/request_success',
    REQUEST_SUBSCRIPTION_FAILED = 'licenses/request_failed',
    RESET_REQUEST_SUBSCRIPTION_STATUS = 'licenses/reset_request_status',
    GET_TIERS = 'licenses/get_all',
    GET_TIERS_SUCCESS = 'licenses/get_all_success',
    GET_TIERS_FAILED = 'licenses/get_all_failed',
    GET_CLIENT_SECRET = 'licenses/get_client_secret',
    GET_CLIENT_SECRET_SUCCESS = 'licenses/get_client_secret_success',
    GET_CLIENT_SECRET_FAILED = 'licenses/get_client_secret_failed',
    GET_SUBSCRIPTION_INFO = 'licenses/get_subscription_info',
    GET_SUBSCRIPTION_INFO_SUCCESS = 'licenses/get_subscription_info_success',
    GET_SUBSCRIPTION_INFO_FAILED = 'licenses/get_subscription_info_failed',
    GET_CURRENT_SUBSCRIPTION_INFO = 'licenses/get_current_subscription_info',
    CANCEL_SUBSCRIPTION = 'licenses/cancel_subscription',
    CANCEL_SUBSCRIPTION_SUCCESS = 'licenses/cancel_subscription_success',
    CANCEL_SUBSCRIPTION_FAILED = 'licenses/cancel_subscription_failed',
    CANCEL_DOWNGRADE = 'licenses/cancel_downgrade',
    CANCEL_DOWNGRADE_SUCCESS = 'licenses/cancel_downgrade_success',
    CANCEL_DOWNGRADE_FAILED = 'licenses/cancel_downgrade_failed',
    SET_AVAILABLE_LICENSES = 'licenses/set_available',
    SET_CURRENT_LICENSE = 'licenses/set_current',
    SET_NEW_LICENSE = 'licenses/set_new',
    SET_CURRENT_TIER = 'licenses/set_current_tier',
    SET_SELECTED_PERIOD = 'licenses/set_period',
    SET_CURRENT_STEP = 'licenses/set_current_step',
    SET_EXCEDED_LIMITS = 'licenses/set_exceded_limits',
    SET_REACHED_LIMITS = 'licenses/set_reached_limits',
    SEND_PAYMENT_METHOD = 'licenses/send_payment_method',
    SEND_PAYMENT_METHOD_SUCCESS = 'licenses/send_payment_method_success',
    SEND_PAYMENT_METHOD_FAILED = 'licenses/send_payment_method_failed',
    SET_ORG_INFO_STATE = 'licenses/set_org_info_in_state',
    SET_SHOW_ACCOUNT_ACTIVATED = 'licenses/set_show_account_activated',
    REMOVE_CLIENT_SECRET = 'licenses/remove_client_secret',
    SET_PRESELECTED_LICENSE = 'licenses/set_preselected_license',
}

export type ILicensesActions =
    | IUpdateLicense
    | IUpdateLicenseSuccess
    | IUpdateLicenseFailed
    | IRequestSubscription
    | IRequestSubscriptionSuccess
    | IRequestSubscriptionFailed
    | IResetRequestSubscriptionStatus
    | IGetTiers
    | IGetTiersSuccess
    | IGetTiersFailed
    | IGetClientSecret
    | IGetClientSecretSuccess
    | IGetClientSecretFailed
    | IGetSubscriptionInfo
    | IGetSubscriptionInfoSuccess
    | IGetSubscriptionInfoFailed
    | IGetCurrentSubscriptionInfo
    | ICancelSubscription
    | ICancelSubscriptionSuccess
    | ICancelSubscriptionFailed
    | ICancelDowngrade
    | ICancelDowngradeSuccess
    | ICancelDowngradeFailed
    | ISetAvailableLicenses
    | ISetCurrentLicense
    | ISetCurrentTier
    | ISetNewLicense
    | ISetSelectedPeriod
    | ISetCurrentStep
    | ISetExcededLimits
    | ISetReachedLimits
    | ISendPaymentMethod
    | ISendPaymentMethodSuccess
    | ISendPaymentMethodFailed
    | ISetShowAccountActivated
    | IRemoveClientSecret
    | IPreselectLicense

export interface ILicensesState {
    subscriptionInfo: ISubscriptionInfo
    subscriptionInfoIsLoading: boolean
    currentProduct: ITierModel
    newLicense: ITierModel
    selectedPeriod: string
    clientSecret: string
    updatingSubscriptionLoading: boolean
    subscriptionInfoUpdatedSuccess: boolean
    cancelDowngradeLoading: boolean
    cancelSubscriptionLoading: boolean
    newLicensePeriod?: string
    currentSubscriptionPeriod?: string
    clientSecretErrorStatus?: string | number
    tiers: ITierModel[]
    currentStep: StepModel | undefined
    tiersAreLoading: boolean
    excededLimits: string[]
    reachedLimits: string
    sendingPaymentMethod?: boolean
    showAccountActivatedPanel?: boolean
    preSelectedLicense?: LicenseToPreselect
    subscriptionRequestStatus: ActionStatusTypes
}
