import React, { useEffect, useState } from 'react'

import {
    getAllContents,
    getType,
    getVerifiableCredentialName,
} from '../../../../../utils/displayDetailsUtil'
import { translateDate, translateProperty } from '../../../../../../../../i18n'
import {
    CredentialGroup,
    VerifiableCredential,
} from '../../../../../data/requestCredentials.interfaces'
import styles from '../content.module.scss'
import cx from 'classnames'

type ISimpleContent = {
    vc: VerifiableCredential
    group: CredentialGroup
    types: any
    inactive?: boolean
    passingOnPress?: any
    isFirst: boolean
    isLast: boolean
}

const defaultProps = {}

const SimpleContent: React.FC<ISimpleContent> = (props) => {
    const vc = props.vc

    const [vcState, setVcState] = useState<{
        vcValue: string
        vc: VerifiableCredential
        vcType: string
        isImage: boolean
        isFace: boolean
        vcContents: { dataType: string; value: string }[]
        iconId: string | null
    }>({
        vcValue: '',
        vc: vc,
        vcType: '',
        vcContents: [],
        isImage: false,
        isFace: false,
        iconId: null,
    })

    const loadVCDetailData = async () => {
        const contents = await getAllContents(vc)
        const type = await getVerifiableCredentialName(vc, props.types)
        const vcType = getType(vc)

        const imagesTypes = ['face', 'profile', 'Image']
        const faceTypes = ['face', 'profile']
        const isImage = imagesTypes.find((i) => getType(vc).includes(i))
        const isFace = faceTypes.find((i) => getType(vc).includes(i))

        setVcState({
            ...vcState,
            isImage: !!isImage,
            isFace: !!isFace,
            vcType: type,
            // @ts-ignore
            vcContents: contents,
            iconId: type,
        })
    }

    useEffect(() => {
        loadVCDetailData()
    }, [])

    useEffect(() => {
        loadVCDetailData()
    }, [vc])

    return (
        <>
            {vcState.vcContents?.map((vcVal, index) => {
                // PROFILE IMAGE CREDENTIAL & PORTRAIT
                const imagesTypes = ['face', 'profile', 'image', 'portrait']
                const isImage = imagesTypes.find((i) =>
                    vcVal?.dataType.includes(i)
                )
                const isDate = vcVal?.dataType.includes('date')
                const imageURI = vcVal?.value.includes('data:image')
                    ? vcVal?.value
                    : 'data:image/jpg;base64,' + vcVal?.value

                return (
                    <div
                        key={'simpleContentDet__' + index}
                        className={cx(
                            styles.claimCredential,
                            vcState.vcContents?.length > 1 &&
                                styles.multiClaimElement
                        )}
                    >
                        {!isImage && (
                            <p className={cx('buttonSM')}>
                                {translateProperty(vcVal?.dataType)}
                            </p>
                        )}
                        {isImage ? (
                            <img className={styles.photo} src={imageURI} />
                        ) : isDate ? (
                            <p className={cx('bodyRegularXS neutral800')}>
                                {translateDate(vcVal?.value)}
                            </p>
                        ) : (
                            <p className={cx('bodyRegularXS neutral800')}>
                                {vcVal?.value}
                            </p>
                        )}
                    </div>
                )
            })}
        </>
    )
}

SimpleContent.defaultProps = defaultProps

export default SimpleContent
