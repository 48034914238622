/* Add or remove an item from a list */
export function addOrRemove(item, itemList) {
    let find = itemList.indexOf(item)
    if (find > -1) {
        item?.mandatory && delete item.mandatory
        itemList.splice(find, 1)
    } else {
        itemList.push(item)
    }

    return itemList
}

/* Add or remove an object item from a list */
export function removeObjectFromList(item, itemList) {
    let indexRepeated: number | undefined

    itemList?.find((el, index) => {
        indexRepeated =
            JSON.stringify(el) === JSON.stringify(item) ? index : undefined
        return JSON.stringify(el) === JSON.stringify(item)
    })

    itemList?.splice(indexRepeated, 1)

    return itemList
}

export function toggle(item, itemList) {
    const isItemInList = getIfItemIsSelected(item, itemList)
    let newToggleValue = new Array()
    if (isItemInList) {
        newToggleValue = itemList ? itemList.filter((e) => e !== item) : []
    } else {
        newToggleValue.push(item)
    }
    return newToggleValue
}

export const getIfItemIsSelected = (item, list) => {
    return !!list && !!list?.filter((i) => i === item)[0]
}
