import cx from 'classnames'
import React from 'react'
import styles from './didsSelectorDropdown.module.scss'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import PlusIcon from '../../../../../assets/iconsLibrary/simple/plus/plusIcon'
import { brandColors } from '../../../../../assets/globalConstants'
import ChevronRightIcon from '../../../../../assets/iconsLibrary/simple/chevronRight/chevronRightIcon'
import SelectableDidCard from '../selectableDidCard/SelectableDidCard'
import { t } from 'i18next'

type IDidsSelectorDropdownProps = {
    options?: IDIDModel[]
    value?: string
    canCreate?: boolean
    select: (x?) => void
    setShowProfilesModal: (x?) => void
    setShowAddAccountModal: (x?) => void
}

const DidsSelectorDropdown: React.FC<IDidsSelectorDropdownProps> = (props) => {
    const {
        options,
        value,
        canCreate,
        select,
        setShowProfilesModal,
        setShowAddAccountModal,
    } = props

    const isLongOptionsList = options && options?.length > 4

    return (
        <div
            className={styles.optionsCustomContainer}
            role="listbox"
            aria-label="Choose an item"
            placeholder={t('general.chooseAnOption')}
        >
            {!!options?.length ? (
                <>
                    {options?.slice(0, 4)?.map((item, index) => {
                        return (
                            <div key={item?.DidId || 'did' + index}>
                                <SelectableDidCard
                                    item={item}
                                    index={index}
                                    selectedDid={value}
                                    select={select}
                                />
                            </div>
                        )
                    })}
                    {isLongOptionsList ? (
                        <div
                            className={`${styles.option} ${styles.showMoreOption}`}
                            key={'show-more'}
                            onClick={() => {
                                setShowProfilesModal(true)
                            }}
                        >
                            <p className={cx('bodyRegularSM primary700')}>
                                {t('ew.showMore')}
                            </p>
                            <ChevronRightIcon
                                size={16}
                                color={brandColors.primary700}
                            />
                        </div>
                    ) : null}
                    {canCreate ? (
                        <>
                            <div className={cx(styles.separator)} />
                            <div
                                className={`${styles.option} ${styles.addAccountOption}`}
                                key={'show-add'}
                                onClick={() => {
                                    setShowAddAccountModal(true)
                                }}
                            >
                                <p className={cx('buttonSM')}>
                                    {t('ew.addAccount')}
                                </p>
                                <PlusIcon
                                    size={24}
                                    color={brandColors.neutral1000}
                                />
                            </div>
                        </>
                    ) : null}
                </>
            ) : null}
        </div>
    )
}

export default DidsSelectorDropdown
