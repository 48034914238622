import cx from 'classnames'
import React, { InputHTMLAttributes } from 'react'
import style from './gaRadioButton.module.scss'
import {
    CheckboxSizes,
    LabelPosition,
    TextSizes,
} from '../../../../data/globalVar'
import GaHoverTooltip from '../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'

interface GaRadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    item?: any
    itemText?: string | NonNullable<React.ReactNode[]>
    index: string
    checked?: boolean
    disabled?: boolean
    radioButtonSize?: CheckboxSizes
    position?: LabelPosition
    infoIcon?: boolean
    infoText?: string
    textSize?: TextSizes
    name?: string
    id?: string
    onChange: (e?) => void
}

const GaRadioButton: React.FC<GaRadioButtonProps> = React.memo((props) => {
    const {
        className,
        item,
        itemText,
        index,
        checked,
        disabled,
        radioButtonSize,
        position,
        infoIcon,
        infoText,
        textSize,
        name,
        id,
        onChange,
    } = props

    const sizeClass = {
        MD: style.medium,
        SM: style.small,
    }

    const sizeFontClass = {
        LG: 'buttonSM',
        MD: 'bodyRegularXS',
    }

    return (
        <div
            className={`${className} ${style.gaRadioButton} ${
                disabled ? style.disabled : ''
            } ${cx(sizeClass[radioButtonSize || 'MD'])}`}
        >
            {position === 'right' ? (
                <>
                    <input
                        type="radio"
                        id={id || `custom-radio-button-${index}`}
                        name={name}
                        value={index}
                        disabled={disabled}
                        onChange={() => onChange(item)}
                        checked={checked}
                    />
                    {itemText && (
                        <div className={style.label__container}>
                            <label
                                htmlFor={id || `custom-radio-button-${index}`}
                                className={`${cx('neutral1000')} ${cx(
                                    sizeFontClass[textSize || 'MD']
                                )}`}
                            >
                                {itemText}
                            </label>
                            {infoIcon && infoText && (
                                <>
                                    <GaHoverTooltip info={infoText} />
                                </>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <>
                    {itemText && (
                        <div className={style.label__container}>
                            <label
                                htmlFor={id || `custom-radio-button-${index}`}
                                className={`${cx('neutral1000')} ${cx(
                                    sizeFontClass[textSize || 'MD']
                                )}`}
                            >
                                {itemText}
                            </label>
                            {infoIcon && infoText && (
                                <>
                                    <GaHoverTooltip info={infoText} />
                                </>
                            )}
                        </div>
                    )}
                    <input
                        type="radio"
                        id={id || `custom-radio-button-${index}`}
                        name={name}
                        value={index}
                        disabled={disabled}
                        onChange={() => onChange(item)}
                        checked={checked}
                    />
                </>
            )}
        </div>
    )
})

export default GaRadioButton
