import React, { useState } from 'react'
import styles from './noDataAvailable.module.scss'
import cx from 'classnames'
import MehIcon from '../../../../../../../assets/iconsLibrary/simple/meh/mehIcon'
import { useTranslation } from 'react-i18next'

const NoDataAvailable: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div className={`${styles.noDataAvailable__container}`}>
            <div>
                <MehIcon />
                <p className={cx('bodyRegularMD marginTop8')}>
                    {t('dashboard.noData')}
                </p>
            </div>
        </div>
    )
}

export default NoDataAvailable
