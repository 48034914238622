import {t} from 'i18next';
import React from 'react';
import style from './loadingContent.module.scss';

type LoadingContentProps = {
  className?: string
  loadingText?: string
}

const LoadingContent: React.FC<LoadingContentProps> = React.memo((props) => {
  const {className, loadingText } = props;

  return (
    <p className={style.loadingContentMessage + className ? ' ' + className : ''} >{t(loadingText || 'public.processingOperation')}</p>
  )
})

export default LoadingContent;
