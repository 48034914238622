import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonModel } from '../../../../interfaces/interfaces'
import LightButton from '../../../atoms/buttons/LightButton/LightButton'
import Description from '../../../atoms/texts/Description/Description'
import styles from './mediumModal.module.scss'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import CloseIcon from '../../../../assets/iconsLibrary/simple/close/closeIcon'

type IMediumModalProps = {
    title: string
    subtitle?: string
    description?: string
    headerClose?: boolean
    headerButton?: ButtonModel
    primaryButton?: ButtonModel
    secondaryButton?: ButtonModel
    hideFooter?: boolean
    show: boolean
    invisibleBg?: boolean
    primaryButtonDisabled?: boolean
    className?: string
    headerButtonFunction?: (x?) => void
    primaryButtonFunction?: (x?) => void
    handleClose: () => void
    titleClassName?: string
    subtitleClassName?: string
}

const MediumModal: React.FC<IMediumModalProps> = (props) => {
    const { t } = useTranslation()
    const {
        title,
        headerButton,
        headerClose,
        primaryButtonDisabled,
        invisibleBg,
        primaryButton,
        secondaryButton,
        show,
        subtitle,
        description,
        hideFooter,
        className,
        headerButtonFunction,
        primaryButtonFunction,
        handleClose,
        children,
        titleClassName,
        subtitleClassName,
    } = props

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden'
        }
        document?.getElementById('topOfTheList')?.scrollIntoView(false)
    }, [show, primaryButtonDisabled])

    return (
        <div
            className={`${styles.backgroundLayer} ${
                invisibleBg ? styles.transparent : ''
            } ${show ? styles.visibleMediumModal : styles.hiddenMediumModal} ${
                className && className
            }`}
        >
            <div className={styles.modalContainer}>
                <div className={styles.modal}>
                    <div className={styles.modal__header}>
                        <div>
                            <h3
                                className={
                                    titleClassName
                                        ? titleClassName
                                        : cx('heading5 neutral1000')
                                }
                            >
                                {t(title)}
                            </h3>
                            {subtitle ? (
                                <p
                                    className={
                                        subtitleClassName
                                            ? subtitleClassName
                                            : cx('bodyRegularMD neutral700')
                                    }
                                >
                                    {t(subtitle)}
                                </p>
                            ) : null}

                            {description ? (
                                <Description
                                    className={cx(
                                        'bodyRegularMD neutral700 marginTop4'
                                    )}
                                    text={description}
                                />
                            ) : null}
                        </div>
                        {headerButton ? (
                            <LightButton
                                className={styles.modal__header__primaryButton}
                                functionality={
                                    headerButtonFunction
                                        ? headerButtonFunction
                                        : handleClose
                                }
                                text={headerButton?.label || ''}
                                icon={headerButton?.image}
                                disabled={false}
                            />
                        ) : null}
                        {headerClose ? (
                            <div
                                className={styles.modal__header__close}
                                onClick={() => handleClose()}
                            >
                                <CloseIcon size={24} color="#1E1E20" />
                            </div>
                        ) : null}
                    </div>
                    <div className={styles.modal__content}>{children}</div>
                    {!hideFooter && (primaryButton || secondaryButton) ? (
                        <div className={styles.modal__footer}>
                            {primaryButton && (
                                <DarkButton
                                    className={
                                        styles.modal__footer__primaryButton
                                    }
                                    functionality={
                                        primaryButtonFunction
                                            ? primaryButtonFunction
                                            : null
                                    }
                                    text={primaryButton.label}
                                    icon={primaryButton.image}
                                    SvgIcon={primaryButton.svgIcon}
                                    disabled={primaryButtonDisabled || false}
                                />
                            )}
                            {secondaryButton ? (
                                <LightButton
                                    className={styles.modal__footer__linkButton}
                                    functionality={() => handleClose()}
                                    text={secondaryButton.label}
                                    icon={secondaryButton.image}
                                    disabled={false}
                                />
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default MediumModal
