import { initialLang } from '../../i18n'
import {
    IAppIntegrationModel,
    IAppIntegrationModelSearchable,
    IAppIntScope,
} from '../../interfaces/interfaces'
import { sortByDate } from '../../utils/operationsUtil'
import { RootState } from '../indexReducers'
import moment from 'moment'

// List
export const isLoading = (state: RootState) =>
    state.appIntegrationsListReducer.loadingStatus
export const getAppIntegrations = (state: RootState) =>
    formatIntegrationsForSearch(
        state.appIntegrationsListReducer.appIntegrations
    )?.sort(sortByDate)

// Scopes
export const areScopesLoading = (state: RootState) =>
    state.appIntegrationsListReducer.loadingStatus
export const scopeList = (state: RootState) =>
    state.appIntegrationsListReducer.scopes
export const selectableScopeList = (state: RootState) =>
    formatScopesToBeSelectable(state.appIntegrationsListReducer.scopes)

// Config App Integration
export const getSelectedAppIntegration = (state: RootState) =>
    state.configAppIntegrationsReducer.appIntegration
export const getSelectedAppIntegrationToEdit = (state: RootState) =>
    formatSelectedAppIntToEditScopes(
        state.configAppIntegrationsReducer.appIntegration
    )
export const getStatus = (state: RootState) =>
    state.configAppIntegrationsReducer.status
export const getIsSecretStatus = (state: RootState) =>
    state.configAppIntegrationsReducer.getSecretStatus

// Functions

const formatIntegrationsForSearch = (
    appIntegrations?: IAppIntegrationModel[]
) => {
    const formattedApps = appIntegrations as IAppIntegrationModelSearchable[]
    return formattedApps?.map((app) => {
        const fomattedApp = app as IAppIntegrationModelSearchable
        fomattedApp.created_at_formatted = app.created_at
            ? moment(app.created_at)?.format('DD/MM/YYYY')
            : ''
        fomattedApp.redirect_uris__str = app.redirect_uris?.join(' ')

        return fomattedApp
    })
}

const formatScopesToBeSelectable = (scopes?: IAppIntScope[]) => {
    const formattedScopes = scopes
    return formattedScopes?.map((scope) => {
        return {
            value:
                scope?.description_short &&
                scope?.description_short[initialLang]
                    ? scope?.description_short[initialLang] ||
                      scope?.description_short['en']
                    : scope?.scope,
            key: scope?.scope,
            subText: `id: ${scope?.scope}`,
        }
    })
}

const formatSelectedAppIntToEditScopes = (appInt?: IAppIntegrationModel) => {
    if (appInt) {
        return { ...appInt, formScopes: selectedAppformScopes(appInt) || [] }
    }
    return undefined
}

const selectedAppformScopes = (appInt: IAppIntegrationModel) => {
    const scopes = appInt?.scope?.replace('openid', '')
    const scopesArr = scopes?.split(' ')

    return scopesArr?.filter((e) => !!e?.length)
}
