import React from 'react'
import cx from 'classnames'
import QuantityDataCell from './elements/cells/quantityDataCell/QuantityDataCell'
import ListDataCell from './elements/cells/listDataCell/ListDataCell'
import SubcategoryCell from './elements/cells/subcategoryCell/SubcategoryCell'
import IconDataCell from './elements/cells/iconDataCell/IconDataCell'
import CategoryCell from './elements/cells/category/CategoryCell'

import styles from './licensesTable.module.scss'
import {
    brandLabels,
    gaImages,
} from '../../../../../../../assets/globalConstants'
import {
    ITierModel,
    InfoTogglesPricingModel,
} from '../../../../../../../interfaces/interfaces'
import Tag from '../../../../../../../Components/atoms/tag/Tag'
import PurpleButton from '../../../../../../../Components/atoms/buttons/purple/PurpleButton'
import { t } from 'i18next'
import { getTierLimitsValues } from '../../../../../../../utils/licensesUtil'

type ILicensesTableProps = {
    tiers?: any[]
    switchPeriodValue: string
    infoToggles: InfoTogglesPricingModel
    setPeriodChecked: (x) => void
}

const LicensesTable: React.FC<ILicensesTableProps> = (props) => {
    const { tiers, switchPeriodValue, infoToggles, setPeriodChecked } = props

    const getPrice = (item: any) => {
        return switchPeriodValue === 'year'
            ? item?.yearlyPrice
            : item?.monthlyPrice
    }

    return (
        <>
            <div className={styles?.tableContainer}>
                <table className={styles.licensesTable}>
                    <thead className={styles?.table__header__container}>
                        <tr
                            className={styles?.table__header}
                            id={'featuresTableDesk'}
                        >
                            <th colSpan={2}>
                                <div className={styles.periodToggle}>
                                    <input
                                        checked={switchPeriodValue === 'year'}
                                        className={styles.periodCheckbox}
                                        type="checkbox"
                                        id="switch"
                                    />
                                    <label
                                        onClick={(e) =>
                                            setPeriodChecked(
                                                switchPeriodValue === 'month'
                                                    ? 'year'
                                                    : 'month'
                                            )
                                        }
                                        className={styles.periodCheckboxLabel}
                                    ></label>
                                    <span className={cx('buttonMD primary700')}>
                                        {t('subscription.payYearly')}
                                    </span>
                                </div>
                            </th>

                            {tiers?.map((license) => {
                                return (
                                    <th
                                        key={'headerT__' + license?.type}
                                        className={styles?.table__header__cell}
                                    >
                                        <div
                                            className={
                                                styles?.table__header__cell__content
                                            }
                                        >
                                            <div
                                                className={
                                                    styles?.license__header
                                                }
                                            >
                                                <p
                                                    className={`${
                                                        styles?.licenseName
                                                    } ${cx('bodyBoldMD')}`}
                                                >
                                                    {license?.type}
                                                </p>
                                                {license?.type ===
                                                    'Professional' && (
                                                    <Tag
                                                        text={'Popular'}
                                                        color={'#00999E'}
                                                        className={
                                                            styles?.license__header__popularTag
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div>
                                                {!license?.name
                                                    ?.toLowerCase()
                                                    ?.includes('enterprise') ? (
                                                    <>
                                                        <p
                                                            className={`${
                                                                styles?.licensePrice
                                                            } ${
                                                                switchPeriodValue !==
                                                                'year'
                                                                    ? styles?.licensePriceLarge
                                                                    : ''
                                                            } ${cx(
                                                                'heading4'
                                                            )}`}
                                                        >
                                                            <span>
                                                                {getPrice(
                                                                    license
                                                                )}
                                                                €
                                                            </span>
                                                            {' /'}
                                                            &nbsp;
                                                            {t(
                                                                'subscription.' +
                                                                    switchPeriodValue
                                                            )}
                                                        </p>
                                                        {switchPeriodValue ===
                                                        'month'
                                                            ? license?.subPriceMonthLabel && (
                                                                  <span
                                                                      className={`${cx(
                                                                          'neutral700 bodyRegularXS marginTop2'
                                                                      )} ${
                                                                          styles?.licenseSave
                                                                      }`}
                                                                  >
                                                                      {t(
                                                                          license?.subPriceMonthLabel
                                                                      )}
                                                                  </span>
                                                              )
                                                            : license?.subPriceYearLabel && (
                                                                  <span
                                                                      className={`${cx(
                                                                          'neutral700 bodyRegularXS marginTop2'
                                                                      )} ${
                                                                          styles?.licenseSave
                                                                      }`}
                                                                  >
                                                                      {t(
                                                                          license?.subPriceYearLabel
                                                                      )}
                                                                  </span>
                                                              )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <p
                                                            className={`${
                                                                styles?.licenseCustomPrice
                                                            } ${cx(
                                                                'heading5'
                                                            )}`}
                                                        >
                                                            {t(
                                                                getPrice(
                                                                    license
                                                                )
                                                            )}
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody className={styles?.table__body}>
                        <tr key={1} className={styles?.table__body__row}>
                            <CategoryCell
                                category={'subscription.features'}
                                rowsPan={2}
                            />
                            <SubcategoryCell
                                subcategory={
                                    'subscription.verificationTemplates'
                                }
                                information={t(
                                    infoToggles?.verificationTemplate
                                )}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <QuantityDataCell
                                        key={'VT__' + license?.type}
                                        data={
                                            license?.features
                                                ?.verificationTemplates
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={2} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.activeUsers'}
                                information={t(infoToggles?.activeUser)}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <QuantityDataCell
                                        key={'AU__' + license?.type}
                                        data={license?.features?.activeUsers}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={3} className={styles?.table__body__row}>
                            <CategoryCell
                                category={'subscription.issuanceFeatures'}
                                rowsPan={2}
                            />
                            <SubcategoryCell
                                subcategory={'subscription.issuanceTemplates'}
                                information={t(infoToggles?.issuanceTemplate)}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <QuantityDataCell
                                        key={'IT__' + license?.type}
                                        data={
                                            license?.features?.issuanceTemplates
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={4} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'Issued Credentials p/m'}
                                information={t(infoToggles?.issuedCredential)}
                                extraTranslationParams={{
                                    brand: brandLabels?.brand,
                                }}
                            />
                            {tiers?.map((license: ITierModel) => {
                                return (
                                    <QuantityDataCell
                                        key={'IC__' + license?.name}
                                        data={
                                            getTierLimitsValues(license)
                                                ?.issuedCredentials
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={5} className={styles?.table__body__row}>
                            <CategoryCell
                                category={'subscription.otherFeatures'}
                                rowsPan={25}
                            />
                            <SubcategoryCell
                                subcategory={'subscription.dids'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <QuantityDataCell
                                        key={'DIDS__' + license?.type}
                                        data={license?.features?.dids}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={6} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.apiKeys'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <QuantityDataCell
                                        key={'AK__' + license?.type}
                                        data={license?.features?.apiKeys}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={7} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.npNFactorMechanisms'}
                                information={t(
                                    infoToggles?.securityFactorMechanism
                                )}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <ListDataCell
                                        key={'2FM__' + license?.type}
                                        data={license?.features?.nFactor}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={8} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={
                                    'subscription.notificationsWithoutParams'
                                }
                            />
                            {tiers?.map((license) => {
                                return (
                                    <ListDataCell
                                        key={'NOT__' + license?.type}
                                        data={license?.features?.notifications}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={9} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.usageStatistics'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'US__' + license?.type}
                                        data={license?.features?.stadistics}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={10} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={
                                    'subscription.CredentialsW3Cformat'
                                }
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'CW3C__' + license?.type}
                                        data={
                                            license?.features
                                                ?.credentialsInW3CFormat
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={11} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.sandboxEnvironment'}
                                information={t(infoToggles?.sandboxEnvironment)}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'SE__' + license?.type}
                                        data={
                                            license?.features
                                                ?.sandBoxEnvironment
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={12} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.didPublic'}
                                information={t(
                                    infoToggles?.didInPublicCatalogue
                                )}
                                extraTranslationParams={{
                                    brand: brandLabels?.brand,
                                }}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'DPC__' + license?.type}
                                        data={
                                            license?.features
                                                ?.didPublicCatalogue
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={13} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={
                                    'subscription.SchemasPublicCatalogue'
                                }
                                information={t(
                                    infoToggles?.schemaInPublicCatalogue
                                )}
                                extraTranslationParams={{
                                    brand: brandLabels?.brand,
                                }}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'PSPC__' + license?.type}
                                        data={
                                            license?.features
                                                ?.newSchemaPublicCatalogue
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={14} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.EBSIintegration'}
                                information={t(infoToggles?.ebsiIntegration)}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'EBSII__' + license?.type}
                                        data={
                                            license?.features?.ebsiIntegration
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={15} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={
                                    'subscription.customizationGDPRPurposes'
                                }
                            />

                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'GDPRC__' + license?.type}
                                        data={
                                            license?.features
                                                ?.customGDPRPurposes
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={16} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.customQr'}
                            />

                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'CQR__' + license?.type}
                                        data={license?.features?.customQR}
                                    />
                                )
                            })}
                        </tr>
                        <tr key={17} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.credentialsISO18013'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'CIF__' + license?.type}
                                        data={
                                            license?.features
                                                ?.credentialsInISO28013
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={18} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={
                                    'subscription.integrationTrustFrameworks'
                                }
                                information={t(
                                    infoToggles?.integrationCustomFrameworks
                                )}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'ICF__' + license?.type}
                                        data={
                                            license?.features
                                                ?.integrationCustomTrustFrameworks
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={20} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={
                                    'subscription.integrationCustomInfrastructure'
                                }
                                information={t(
                                    infoToggles?.integrationCustomInfraestructure
                                )}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <IconDataCell
                                        key={'ICI__' + license?.type}
                                        data={
                                            license?.features
                                                ?.integrationCustomTrustInfraestructure
                                        }
                                    />
                                )
                            })}
                        </tr>
                        <tr key={21} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.standardSupport'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <td
                                        key={'SSPT__' + license?.type}
                                        className={`${cx('bodyBoldSM')}`}
                                    >
                                        {typeof license?.features
                                            ?.standardSupport == 'boolean' ? (
                                            <img
                                                className={
                                                    styles?.table__body__row__cell__icon
                                                }
                                                src={
                                                    license?.features
                                                        ?.standardSupport
                                                        ? gaImages.check?.src
                                                        : gaImages.close?.src
                                                }
                                            ></img>
                                        ) : (
                                            <p
                                                className={`${cx(
                                                    'bodyRegularCap neutral700'
                                                )}`}
                                            >
                                                ${' '}
                                                <span
                                                    className={`${cx(
                                                        'bodyRegularCap neutral700'
                                                    )}`}
                                                >
                                                    {t(
                                                        license?.features
                                                            ?.standardSupport
                                                    )}
                                                </span>
                                            </p>
                                        )}
                                    </td>
                                )
                            })}
                        </tr>
                        <tr key={22} className={styles?.table__body__row}>
                            <SubcategoryCell
                                subcategory={'subscription.premierSupport'}
                            />
                            {tiers?.map((license) => {
                                return (
                                    <>
                                        <td
                                            key={'PSPT__' + license?.type}
                                            className={`${cx('bodyBoldSM')}`}
                                        >
                                            {typeof license?.features
                                                ?.premierSupport ==
                                            'boolean' ? (
                                                <img
                                                    className={
                                                        styles?.table__body__row__cell__icon
                                                    }
                                                    src={
                                                        license?.features
                                                            ?.premierSupport
                                                            ? gaImages.check
                                                                  ?.src
                                                            : gaImages.close
                                                                  ?.src
                                                    }
                                                ></img>
                                            ) : (
                                                <p>
                                                    ${' '}
                                                    <span
                                                        className={`${cx(
                                                            'bodyRegularCap neutral700'
                                                        )}`}
                                                    >
                                                        {t(
                                                            license?.features
                                                                ?.premierSupport
                                                        )}
                                                    </span>
                                                </p>
                                            )}
                                        </td>
                                    </>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default LicensesTable
