import React from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './closeConfirmation.module.scss'
import ScanModal from '../../ScanModal'
import GaImage from '../../../../../atoms/Image/Image'
import { gaImages } from '../../../../../../assets/globalConstants'

type IScanModalCloseConfirmation = {
    continueFunction: () => void
    goBackFunction: () => void
}

const ScanModalCloseConfirmation: React.FC<IScanModalCloseConfirmation> = (
    props
) => {
    const { continueFunction, goBackFunction } = props

    return (
        <ScanModal
            className={styles.closeConfirmationModal}
            hideModal={() => {}}
            cancelButton={{
                label: t('public.noGoBack'),
                disabled: false,
                function: goBackFunction,
            }}
            continueButton={{
                label: t('public.yesClose'),
                disabled: false,
                function: continueFunction,
            }}
        >
            <div className={styles.closeConfirmation}>
                <GaImage image={gaImages.warning1} />
                <p className={cx('heading6 marginTop20 ')}>
                    {t('ew.modalScan.close.title')}
                </p>
                <p className={cx('bodyRegularMD neutral800 marginTop8')}>
                    {t('ew.modalScan.close.description')}
                </p>
            </div>
        </ScanModal>
    )
}

export default ScanModalCloseConfirmation
