import { gaImages } from '../../../../assets/globalConstants'
import { ButtonModel, StepModel } from '../../../../interfaces/interfaces'
import ConfigurationForm from '../forms/configuration/ConfigurationForm'
import ConstraintsForm from '../forms/constraints/ConstraintsForm'
import CredentialsRequirementsForm from '../forms/credentials-requirements/CredentialsRequirementsForm'
import CredentialsForm from '../forms/credentials/CredentialsForm'
import { t } from 'i18next'

export const formSteps: StepModel[] = [
    {
        id: 1,
        label: 'editVerifier.configuration',
        order: 0,
        component: ConfigurationForm,
    },
    {
        id: 2,
        label: 'editVerifier.request',
        order: 1,
        component: CredentialsForm,
    },
    {
        id: 3,
        label: 'editVerifier.requirements',
        order: 2,
        component: CredentialsRequirementsForm,
        disabledInfo: t('editIssuance.requirementsUnavailable'),
    },
    {
        id: 4,
        label: 'editVerifier.restrictions',
        order: 3,
        component: ConstraintsForm,
        disabledInfo: t('editIssuance.restrictionsUnavailable'),
    },
]

export const modalHeaderButton: ButtonModel = {
    label: 'editVerifier.step3.newPurpose',
    image: gaImages.plus,
}

export const modalPrimaryButton: ButtonModel = {
    label: 'public.add',
}

export const modalSecondaryButton: ButtonModel = {
    label: 'public.cancel',
}

export const propertiesToSearchPurposes = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
    {
        text: 'purpose_category',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const propertiesToSearchCredentials = [
    {
        text: 'name',
        searchMultipleLanguages: true,
        latinize: true,
    },
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const propertiesToSearchApiKeys = [
    {
        text: 'alias',
        searchMultipleLanguages: false,
        latinize: true,
    },
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const verifierScopes = {
    read: ['readSSIConfigs'],
    edit: ['updateSSIConfigs'],
    create: ['createSSIConfigs'],
    delete: ['deleteSSIConfigs'],
}

export const verifierReadCreateScopes = ['readSSIConfigs', 'createSSIConfigs']
