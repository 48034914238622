import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './dropdownMenu.module.scss'
import cx from 'classnames'
import {
    IGeneralSelectOption,
    ImageModel,
    LinkModel,
} from '../../../../../interfaces/interfaces'
import { gaColors } from '../../../../../assets/globalConstants'
import ChevronDownIcon from '../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'

type IDropdownMenuProps = {
    icon?: ImageModel
    iconClassName?: string
    className?: string
    menuOptions?: LinkModel[]
    left?: boolean
    noLayer?: boolean
    selectOptions?: IGeneralSelectOption[]
    item: any
    active: boolean
    setIsActive: (x: boolean) => void
}

const DropdownMenu: React.FC<IDropdownMenuProps> = (props) => {
    const { left, noLayer, className, selectOptions, setIsActive, active } =
        props
    const { t } = useTranslation()

    return (
        <div className={styles.dropdownMenuContainer}>
            <button className={styles.dropdownMenuButton + ' ' + className}>
                <ChevronDownIcon size={24} color={gaColors.neutral400} />
            </button>
            {active && (
                <div
                    className={
                        (active ? styles.active : styles.inActive) +
                        ' ' +
                        styles.menuOptionsCard +
                        ' ' +
                        (left ? styles.alignLeft : '')
                    }
                    id="dropdownOptions"
                >
                    <ul className={styles.menuOptionsCard__list}>
                        {selectOptions?.map((option, index) => {
                            return (
                                <li
                                    key={index}
                                    className={styles.menuOptionsCard__item}
                                    onClick={() => {
                                        option?.action && option?.action(),
                                            setIsActive(false)
                                    }}
                                >
                                    <p className={cx('buttonMD neutral1000')}>
                                        {t(option?.text || '')}
                                    </p>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
            {!noLayer ? (
                <div
                    className={
                        active
                            ? styles.backgroundLayerActive
                            : styles.backgroundLayerInactive
                    }
                    onClick={() => {
                        setIsActive(!active)
                    }}
                ></div>
            ) : null}
        </div>
    )
}

export default DropdownMenu
