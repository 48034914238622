import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GaTextInput from '../../../atoms/formFields/textInput/GaTextInput'
import MediumModal from '../Medium/MediumModal'
import styles from './requestSubscriptionModal.module.scss'
import {
    ActionStatusTypes,
    brandLabels,
    domains,
    gaImages,
} from '../../../../assets/globalConstants'
import GaSelect from '../../../atoms/formFields/select/GaSelect'
import { ITierModel } from '../../../../interfaces/interfaces'
import { licensesSelectors } from '../../../../features/common/licenses'
import GaTextArea from '../../../atoms/formFields/textArea/GaTextArea'
import { selectPeriodOptions } from '../../../../features/common/licenses/licenses.constants'
import GaImage from '../../../atoms/Image/Image'
import {
    getPriceIntervalOptions,
    getTiersOptions,
} from '../../../../utils/licensesUtil'
import GaCheckbox from '../../../atoms/formFields/checkbox/GaCheckbox'
import Loader from '../../../loader/loader/Loader'

type IRequestSubscriptionModalProps = {
    show: boolean
    selectedLicense: any
    selectedPeriodChecked: string
    loading?: boolean
    licenses: ITierModel[]
    setPeriodChecked: (x) => void
    hideLicensesModal: (x?: any) => void
    hideModal: () => void
    submitData: (x?) => void
}

const RequestSubscriptionModal: React.FC<IRequestSubscriptionModalProps> = (
    props
) => {
    const {
        hideModal,
        show,
        selectedLicense,
        selectedPeriodChecked,
        loading,
        licenses,
        hideLicensesModal,
        setPeriodChecked,
        submitData,
    } = props
    const { t } = useTranslation()

    const recaptchaRef = React.useRef<any>(null)
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)
    const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY
    const tiers = useSelector(licensesSelectors.getProviderTiersAndFeatures)

    const [formData, setFormState] = useState({
        subscriptionPlan:
            selectedLicense?.id || (tiers?.length && tiers[0]?.id) || '',
        paymentPeriod: selectedPeriodChecked || '',
        companyName: '',
        domainName: domains ? domains[0]?.value : '',
        message: '',
        aceptedConditions: false,
        marketingAccepted: false,
    })

    const {
        subscriptionPlan,
        paymentPeriod,
        companyName,
        message,
        domainName,
        aceptedConditions,
        marketingAccepted,
    } = formData

    const [successReq, setSuccessReq] = React.useState(false)
    const requestSubStatus = useSelector(
        licensesSelectors.getRequestSubscritptionStatus
    )

    useEffect(() => {
        if (requestSubStatus === ActionStatusTypes?.success) {
            setSuccessReq(true)
        }
    })

    const validForm = !!(
        subscriptionPlan?.trim() &&
        paymentPeriod?.trim()?.length &&
        companyName?.trim()?.length &&
        domainName?.trim()?.length &&
        aceptedConditions &&
        isCaptchaSuccessful
    )

    const handlePaymentPeriodChange = (value: string) => {
        setFormState({
            ...formData,
            paymentPeriod: value,
        })
    }

    const handlePlanChange = (e) => {
        const value = e?.target?.value
        const newSelectedTier = licenses?.find(
            (el) => el.id === e?.target?.value
        )

        const priceIntervalOptions = getPriceIntervalOptions(
            selectPeriodOptions,
            newSelectedTier
        )

        if (priceIntervalOptions?.length) {
            const period = priceIntervalOptions[0]?.value
            setPeriodChecked(period)
            setFormState({
                ...formData,
                subscriptionPlan: value,
                paymentPeriod: period,
            })
        } else {
            setFormState({
                ...formData,
                subscriptionPlan: value,
                paymentPeriod: '',
            })
        }
    }

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        setFormState({
            ...formData,
            [name]: value,
        })
    }

    const handleCheckboxChange = (targetName: string, value: boolean) => {
        setFormState({
            ...formData,
            [targetName]: value,
        })
    }

    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)

    const selectedPlan = licenses?.find((el) => el.id === subscriptionPlan)

    const getPriceId = () => {
        const selectedPlan = licenses?.find((el) => el.id === subscriptionPlan)

        const selectedPeriodPrice =
            selectedPlan &&
            selectedPlan?.prices?.filter((el) => {
                return el.recurringInterval === selectedPeriod
            })[0]

        return selectedPeriodPrice?.id
    }

    const submitRequest = () => {
        const orgId = companyName
        const priceId = getPriceId()

        submitData({ orgId, priceId, message, domainName })
    }

    return (
        <>
            <MediumModal
                className={cx(
                    styles.requestSubscription,
                    successReq && styles.success
                )}
                title={
                    !successReq
                        ? !loading
                            ? 'requestSubscription.requestSubscription'
                            : 'requestSubscription.sendingRequest'
                        : ''
                }
                description={
                    !successReq && !loading
                        ? 'subscription.fillTheInformation'
                        : undefined
                }
                headerClose={!loading}
                show={show}
                handleClose={!loading ? hideModal : () => {}}
                primaryButton={
                    !loading
                        ? {
                              label: !successReq
                                  ? 'public.submit'
                                  : 'requestSubscription.accessPlatform',
                          }
                        : undefined
                }
                primaryButtonFunction={
                    !successReq
                        ? submitRequest
                        : () => (hideModal(), hideLicensesModal())
                }
                primaryButtonDisabled={!validForm && !successReq}
                secondaryButton={
                    !loading && !successReq
                        ? {
                              label: 'public.cancel',
                              function: hideModal,
                          }
                        : undefined
                }
            >
                <div className={styles.requestSubscription__content}>
                    {!successReq ? (
                        !loading ? (
                            <form
                                action="javascript:alert(grecaptcha.getResponse(widgetId1));"
                                className={styles.form}
                            >
                                <div className={styles.form__textInputs}>
                                    <div className={styles.doubleColumn}>
                                        {getTiersOptions(tiers) ? (
                                            <GaSelect
                                                idSelect={'subscriptionPlan'}
                                                value={subscriptionPlan}
                                                id="plan-selector"
                                                invalid={false}
                                                isLoading={!tiers?.length}
                                                labelText={t(
                                                    'requestSubscription.plan'
                                                )}
                                                placeholder={t(
                                                    'requestSubscription.plan'
                                                )}
                                                optionTextProperty={'text'}
                                                optionValueProperty={'value'}
                                                name={'subscriptionPlan'}
                                                onChange={(e) => {
                                                    handlePlanChange(e)
                                                }}
                                                options={getTiersOptions(tiers)}
                                            />
                                        ) : null}
                                        {getPriceIntervalOptions(
                                            selectPeriodOptions,
                                            selectedPlan || tiers[0]
                                        ) ? (
                                            <GaSelect
                                                idSelect={'paymentPeriod'}
                                                value={paymentPeriod}
                                                id="select-paymentPeriod"
                                                invalid={false}
                                                labelText={t(
                                                    'requestSubscription.paymentPeriod'
                                                )}
                                                optionTextProperty={'text'}
                                                optionValueProperty={'value'}
                                                name={'paymentPeriod'}
                                                onChange={(e) => {
                                                    setPeriodChecked(
                                                        e?.target?.value
                                                    ),
                                                        handlePaymentPeriodChange(
                                                            e?.target?.value
                                                        )
                                                }}
                                                options={getPriceIntervalOptions(
                                                    selectPeriodOptions,
                                                    selectedPlan
                                                )}
                                            />
                                        ) : null}
                                    </div>
                                    <div className={styles.doubleColumn}>
                                        <GaTextInput
                                            className={cx('marginTop12')}
                                            name="companyName"
                                            invalid={false}
                                            labelText={t(
                                                'requestSubscription.companyName'
                                            )}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                            placeholder={t(
                                                'requestSubscription.companyName'
                                            )}
                                            type="text"
                                            required
                                            value={companyName}
                                        />
                                        <GaSelect
                                            idSelect={'domainName'}
                                            className={cx('marginTop12')}
                                            value={domainName}
                                            name="domainName"
                                            invalid={false}
                                            isLoading={!domains?.length}
                                            labelText={t('welcome.domainName')}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                            placeholder={t(
                                                'welcome.domainName'
                                            )}
                                            required
                                            optionTextProperty={'text'}
                                            optionValueProperty={'value'}
                                            options={domains}
                                        />
                                    </div>
                                    <GaTextArea
                                        className={cx('marginTop12')}
                                        name="message"
                                        invalid={false}
                                        labelText={t(
                                            'requestSubscription.message'
                                        )}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder={t(
                                            'requestSubscription.message'
                                        )}
                                        value={message}
                                        required
                                    />
                                </div>

                                <div
                                    className={`${styles.terms} ${cx(
                                        'marginBottom6'
                                    )}`}
                                >
                                    <GaCheckbox
                                        index={'0'}
                                        name="aceptedConditions"
                                        checked={aceptedConditions}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                'aceptedConditions',
                                                !aceptedConditions
                                            )
                                        }
                                        className={`${cx('marginRight8')}`}
                                        id="accept-terms"
                                    ></GaCheckbox>
                                    <span
                                        className={cx(
                                            'neutral1000 bodyRegularSM',
                                            styles.checkbox__text
                                        )}
                                    >
                                        <Trans
                                            i18nKey={'welcome.agreeToGataca'}
                                            values={{
                                                brand: brandLabels?.brand,
                                            }}
                                        />
                                    </span>
                                    <a
                                        className={cx(
                                            'primary700 bodyRegularSM'
                                        )}
                                        target="_blank"
                                        href={brandLabels?.termsOfServiceLink}
                                    >
                                        {t('welcome.termsOfUse')}
                                    </a>
                                    <span
                                        className={cx(
                                            'neutral1000 bodyRegularSM',
                                            styles.checkbox__text
                                        )}
                                    >
                                        <Trans
                                            i18nKey={'welcome.and'}
                                            values={{
                                                brand: brandLabels?.brand,
                                            }}
                                        />
                                    </span>
                                    <a
                                        className={cx(
                                            'primary700 bodyRegularSM',
                                            styles.required
                                        )}
                                        target="_blank"
                                        href={brandLabels.privacypolicylink}
                                    >
                                        {t('welcome.privacyPolicy')}
                                    </a>
                                </div>
                                <div
                                    className={`${styles.terms} ${cx(
                                        'marginBottom6'
                                    )}`}
                                >
                                    <GaCheckbox
                                        index={'0'}
                                        name="marketingAccepted"
                                        checked={marketingAccepted}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                'marketingAccepted',
                                                !marketingAccepted
                                            )
                                        }
                                        className={`${cx('marginRight8')}`}
                                        id="accept-terms"
                                    ></GaCheckbox>
                                    <span
                                        className={cx(
                                            'neutral1000 bodyRegularSM',
                                            styles.checkbox__text
                                        )}
                                    >
                                        <Trans
                                            i18nKey={
                                                'requestSubscription.agreeMarketing'
                                            }
                                            values={{
                                                brand: brandLabels?.brand,
                                            }}
                                        />
                                    </span>
                                    <span
                                        className={cx(
                                            'neutral1000 bodyRegularSM',
                                            styles.checkbox__text
                                        )}
                                    >
                                        <Trans
                                            i18nKey={
                                                'requestSubscription.marketingCommunication'
                                            }
                                            values={{
                                                brand: brandLabels?.brand,
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className={cx('marginTop12')}>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        onChange={(value) =>
                                            setIsCaptchaSuccess(!!value)
                                        }
                                        sitekey={captchaSiteKey || ''}
                                    />
                                </div>
                            </form>
                        ) : (
                            <Loader withNoOverlay />
                        )
                    ) : (
                        <>
                            <GaImage image={gaImages?.greenCheckPng} />
                            <div>
                                <p className={cx('heading5')}>
                                    {t('requestSubscription.successfullySend')}
                                </p>
                                <p className={cx('bodyRegularMD marginTop12')}>
                                    {t(
                                        'requestSubscription.successfullySendDesc'
                                    )}
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </MediumModal>
        </>
    )
}

export default RequestSubscriptionModal
