import { unescape } from 'querystring'
import { ActionStatusTypes } from '../../assets/globalConstants'
import {
    ScanModalListActionsTypes,
    scanModalListActions,
    IscanModalListState,
} from './scanModalTypes'

// LIST
const initialConnectionsListState: IscanModalListState = {
    process: undefined,
    statusGettingProccess: undefined,
    processData: undefined,
    statusSendingData: undefined,
}

export function scanModalReducer(
    state = initialConnectionsListState,
    action: scanModalListActions
): IscanModalListState {
    switch (action.type) {
        case ScanModalListActionsTypes.GET_PROCCESS:
            return {
                ...state,
                statusGettingProccess: ActionStatusTypes.loading,
            }
        case ScanModalListActionsTypes.GET_PROCCESS_SUCCESS:
            return {
                ...state,
                process: {
                    ...action.payload?.data,
                },
                statusGettingProccess: ActionStatusTypes.success,
            }
        case ScanModalListActionsTypes.GET_PROCCESS_FAILED:
            return {
                ...state,
                process: undefined,
                statusGettingProccess: ActionStatusTypes.failed,
            }
        case ScanModalListActionsTypes.SET_PROCESS_DATA:
            return {
                ...state,
                processData: action?.payload,
            }
        case ScanModalListActionsTypes.SEND_PROCESS_DATA:
            return {
                ...state,
                statusSendingData: ActionStatusTypes.loading,
            }
        case ScanModalListActionsTypes.SEND_PROCESS_DATA_SUCCESS:
            return {
                ...state,
                statusSendingData: ActionStatusTypes.success,
            }
        case ScanModalListActionsTypes.SEND_PROCESS_DATA_FAILED:
            return {
                ...state,
                statusSendingData: ActionStatusTypes.failed,
            }
        case ScanModalListActionsTypes.CLEAR_STATE:
            return {
                ...initialConnectionsListState,
            }
        default:
            return state
    }
}
