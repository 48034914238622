import {
    CellContentTypes,
    TextAlignTypes,
} from '../../../../../assets/globalConstants'
import { ButtonModel } from '../../../../../interfaces/interfaces'

export const propertiesToSearch = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: false,
    },
    {
        text: 'alias',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const modalPrimaryButton: ButtonModel = {
    label: 'public.submit',
}

export const modalSecondaryButton: ButtonModel = {
    label: 'public.cancel',
}

export const tableItemOptions = [
    {
        text: 'public.delete',
        value: 'delete',
        color: 'red',
    },
]

export const headersConsentsTable = [
    // Commented until we can know the template id
    // {
    //     key: 'templateName',
    //     text: 'consents.template',
    //     tag: true,
    //     type: CellContentTypes.text,
    // },
    {
        key: 'userDid',
        text: 'consents.did',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.text,
    },
    {
        key: 'createdAt',
        text: 'credentials.creationDate',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.date,
    },
    {
        key: 'data',
        text: 'consents.data',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.array,
    },
    // {
    //     key: 'actions',
    //     text: 'credentials.actions',
    //     textAlign: TextAlignTypes.center,
    //     type: CellContentTypes.action,
    // },
    {
        key: 'validation',
        text: 'credentials.validationData',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.detail,
    },
]

export const consentScopes = {
    read: ['readDataAgreements'],
    create: ['createDataAgreements'],
    delete: ['revokeDataAgreements'],
}
