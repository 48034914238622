import { ActionStatusTypes } from '../../assets/globalConstants'
import { IAppIntegrationModel } from '../../interfaces/interfaces'

export interface ISendAppIntegrationDataAction {
    type: AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA
    payload: {
        client: IAppIntegrationModel
    }
}

export interface ISendAppIntegrationDataSuccessAction {
    type: AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA_SUCCESS
    payload: {
        client: IAppIntegrationModel
    }
}

export interface ISendAppIntegrationDataFailed {
    type: AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA_FAILED
    payload: {
        error: string
    }
}
export interface IClearStateAction {
    type: AppIntegrationModalListActionsTypes.CLEAR_STATE
}

export type AppIntegrationModalListActions =
    | ISendAppIntegrationDataAction
    | ISendAppIntegrationDataSuccessAction
    | ISendAppIntegrationDataFailed
    | IClearStateAction

export enum AppIntegrationModalListActionsTypes {
    SEND_APP_INTEGRATION_DATA = 'appIntegrationModal/send_process_data',
    SEND_APP_INTEGRATION_DATA_SUCCESS = 'appIntegrationModal/send_process_data_success',
    SEND_APP_INTEGRATION_DATA_FAILED = 'appIntegrationModal/send_process_data_failed',
    CLEAR_STATE = 'appIntegrationModal/clear_state',
}

export interface IAppIntegrationModalListState {
    client?: IAppIntegrationModel
    statusSendingData?: ActionStatusTypes
}
