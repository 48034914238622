import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './connectionDetail.module.scss'
import { t } from 'i18next'
import DataAgreementCard from './components/dataAgreementCard/DataAgreementCard'
import { getFormattedCreds } from '../../../requestCredentials/utils/formatCredUtil'
import { useDispatch, useSelector } from 'react-redux'
import { catalogSelectors } from '../../../../common/catalog'
import CredentialCardDetail from '../../../requestCredentials/components/credentialCardDetail/CredentialCardDetail'
import { centerEllipsisTrim } from '../../../../../utils/formatDataUtil'
import GaModal from '../../../../../Components/molecules/modals/modal/modal'
import ConfirmationModal from '../../../../../Components/molecules/modals/confirmation/Confirmation'
import {
    IConnectionsDataAgreementGroup,
    IEWCredentialsPaginatedResponseModel,
} from '../../../../../interfaces/interfaces'
import { requestCredentialsSelectors } from '../../../../../store/requestCredentials'
import { CredentialCard } from '../../../requestCredentials/data/requestCredentials.interfaces'
import { deleteDataAgreement } from '../../../../../store/connections/connectionsActions'
import { didsSelectors } from '../../../../studio/Dids'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'

type IConnectionDetailProps = {
    selectedItem?: IConnectionsDataAgreementGroup
    show?: boolean
    dataReceiverName?: string
    hideModal: (x?: boolean) => void
}

const ConnectionDetail: React.FC<IConnectionDetailProps> = (props) => {
    const { selectedItem, show, dataReceiverName, hideModal } = props
    const dataAgreements = selectedItem?.dataAgreements as Array<any>
    const dispatch = useDispatch()

    // Selectors
    const catalogGroups = useSelector(catalogSelectors.getCatalogGroups)
    const catalogIssuers = useSelector(catalogSelectors.getCatalogAuthority)
    const catalogTypes = useSelector(catalogSelectors.getCatalogTypes)
    const credentialsData = useSelector(
        requestCredentialsSelectors.getFormattedCreds
    ) as IEWCredentialsPaginatedResponseModel
    const EWSelectedDid = useSelector(didsSelectors.getEWDid)

    // States
    const [selectedCredential, setSelectedCredential] = useState<
        CredentialCard | undefined
    >()
    const [showDelete, setShowDelete] = useState(false)
    const [dagToDelete, setDagToDelete] = useState<string | undefined>()
    const [creds, setCredentials] = useState<CredentialCard[] | undefined>()

    // Functions
    const getSelectedCred = (id: string) => {
        return creds?.find((el) => el.id === id)
    }

    const hideModalAndClean = () => {
        hideModal()
        setDagToDelete(undefined)
    }

    const deleteDataAgreementAndHide = () => {
        if (EWSelectedDid && dagToDelete) {
            dispatch(showScreenLoaderAction())
            dispatch(deleteDataAgreement(EWSelectedDid, dagToDelete)),
                setShowDelete(false)
            setDagToDelete(undefined)
        }
    }

    // Effects
    useEffect(() => {
        setCredentials(
            credentialsData?.items?.length
                ? getFormattedCreds(
                      credentialsData?.items,
                      catalogGroups,
                      catalogIssuers,
                      catalogTypes
                  )
                : undefined
        )
    }, [credentialsData])

    return !selectedCredential ? (
        <>
            {' '}
            {!showDelete ? (
                <GaModal
                    title={'ew.connections.connectionDetail'}
                    hideModal={hideModalAndClean}
                    show={show}
                    backButton={true}
                    closeButton={true}
                    content={
                        <>
                            <div>
                                <p className={cx('heading5 neutral1000')}>
                                    {dataReceiverName}
                                </p>
                                {!!selectedItem?.dataReceiverId ? (
                                    <p
                                        className={cx(
                                            'bodyRegularMD neutral700 marginTop4'
                                        )}
                                    >
                                        {centerEllipsisTrim(
                                            selectedItem?.dataReceiverId as string,
                                            23
                                        )}
                                    </p>
                                ) : null}
                            </div>
                            <div className={`${styles.dataAgreements}`}>
                                <p className={cx('bodyRegularXS neutral1000')}>
                                    {t('ew.connections.dataAgreementShared')}
                                </p>
                                {dataAgreements?.map((dataAgr, index) => {
                                    return (
                                        <DataAgreementCard
                                            index={index}
                                            setShowCredDetail={(credId) => {
                                                const selectedCred =
                                                    getSelectedCred(credId)
                                                setSelectedCredential(
                                                    selectedCred
                                                )
                                            }}
                                            createdAt={dataAgr.createdAt}
                                            sharedData={
                                                dataAgr?.dag?.personal_data
                                            }
                                            setShowDelete={(e) => {
                                                setDagToDelete(
                                                    dataAgr?.dag?.id
                                                ),
                                                    setShowDelete(e)
                                            }}
                                        />
                                    )
                                })}
                            </div>
                        </>
                    }
                />
            ) : (
                <ConfirmationModal
                    title="ew.connections.sureDeleteDataAgreement"
                    description="ew.connections.sureDeleteDataAgreementDesc"
                    confirmFunction={() => {
                        deleteDataAgreementAndHide()
                    }}
                    show
                    hideModal={() => {
                        setShowDelete(false), setDagToDelete(undefined)
                    }}
                />
            )}
        </>
    ) : !!selectedCredential ? (
        <CredentialCardDetail
            show={!!selectedCredential}
            {...selectedCredential}
            selectCred={setSelectedCredential}
            hideModal={() => setSelectedCredential(undefined)}
            closeParentModal={hideModalAndClean}
            showConnectionsAndDelete={false}
        />
    ) : null
}

export default ConnectionDetail
