import React from 'react'

type TabletIconProps = {
    size?: number
    color?: string
    className?: string
}

const TabletIcon: React.FC<TabletIconProps> = React.memo((props) => {
    const { size, color, className } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                d="M24.0002 2.66675H8.00016C6.5274 2.66675 5.3335 3.86066 5.3335 5.33341V26.6667C5.3335 28.1395 6.5274 29.3334 8.00016 29.3334H24.0002C25.4729 29.3334 26.6668 28.1395 26.6668 26.6667V5.33341C26.6668 3.86066 25.4729 2.66675 24.0002 2.66675Z"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 24H20"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})

export default TabletIcon
