import {
    IConnectionsDataAgreement,
    IEWConnectionsPaginatedResponseModel,
} from '../../interfaces/interfaces'

import {
    IGetConnectionsAction,
    IGetConnectionsSuccessAction,
    IGetConnectionsFailed,
    ISelectConnectionAction,
    IDeleteDataAgreementAction,
    IDeleteDataAgreementSuccessAction,
    IDeleteDataAgreementFailed,
    ConnectionListActionsTypes,
} from './connectionsTypes'

// LIST
export const getConnections = (
    did: string,
    page?: number,
    size?: number,
    url?: string
): IGetConnectionsAction => {
    return {
        type: ConnectionListActionsTypes.GET_CONNECTIONS,
        payload: {
            did,
            page,
            size,
            url,
        },
    }
}

export const getConnectionsSuccess = (
    data: IEWConnectionsPaginatedResponseModel | IConnectionsDataAgreement[]
): IGetConnectionsSuccessAction => {
    return {
        type: ConnectionListActionsTypes.GET_CONNECTIONS_SUCCESS,
        payload: { data },
    }
}

export const getConnectionsFailed = (error: string): IGetConnectionsFailed => {
    return {
        type: ConnectionListActionsTypes.GET_CONNECTIONS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const selectConnection = (
    dataAgreement: IConnectionsDataAgreement
): ISelectConnectionAction => {
    return {
        type: ConnectionListActionsTypes.SELECT_CONNECTION,
        payload: {
            dataAgreement: dataAgreement,
        },
    }
}

export const deleteDataAgreement = (
    did: string,
    dataAgreementId: string
): IDeleteDataAgreementAction => {
    return {
        type: ConnectionListActionsTypes.DELETE_DATA_AGREEMENT,
        payload: {
            did,
            dataAgreementId,
        },
    }
}

export const deleteDataAgreementSuccess =
    (): IDeleteDataAgreementSuccessAction => {
        return {
            type: ConnectionListActionsTypes.DELETE_DATA_AGREEMENT_SUCCESS,
        }
    }

export const deleteDataAgreementFailed = (
    error: string
): IDeleteDataAgreementFailed => {
    return {
        type: ConnectionListActionsTypes.DELETE_DATA_AGREEMENT_FAILED,
        payload: {
            error: error,
        },
    }
}
