import { sessionTokenData } from '../features/common/session/sessionTypes'

/* Return if the user has one or more scopes */
export function userHasScopes(
    scopes: string[],
    allowedScopes: string[],
    every?: boolean
) {
    let hasScopes = false
    if (scopes?.length) {
        if (allowedScopes?.length) {
            hasScopes = !every
                ? scopes.some((ai) => allowedScopes?.includes(ai))
                : scopes.every((ai) => allowedScopes?.includes(ai))
        }
    } else {
        hasScopes = true
    }
    return hasScopes
}

export function getIsActiveSession(tokenExpiration?: number) {
    return (tokenExpiration || 0) * 1000 > new Date().getTime()
}
