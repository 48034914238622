import React from 'react'

type CheckCircleIconProps = {
    size?: number
    color?: string
    className?: string
}

const CheckCircleIcon: React.FC<CheckCircleIconProps> = React.memo((props) => {
    const { size, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.3332 14.7733V16C29.3315 18.8752 28.4005 21.6729 26.679 23.9758C24.9574 26.2786 22.5376 27.9633 19.7803 28.7785C17.0231 29.5938 14.0762 29.4959 11.3791 28.4994C8.68208 27.503 6.37938 25.6615 4.81445 23.2494C3.24953 20.8374 2.50623 17.9841 2.69541 15.1151C2.88459 12.2461 3.99611 9.5151 5.86421 7.32943C7.73231 5.14376 10.2569 3.62051 13.0614 2.98686C15.866 2.35321 18.8002 2.64312 21.4265 3.81333"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.3333 5.33334L16 18.68L12 14.68"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})

export default CheckCircleIcon
