import { t } from 'i18next'
import { gaImages } from '../../../assets/globalConstants'
import chart from '../../../assets/images/Chart.png'
import notification from '../../../assets/images/notification.svg'
import { BlockModel, ButtonModel } from '../../../interfaces/interfaces'

export const homeHealine = 'dashboard.gettingStarted'
export const chartImage = chart
export const notificationIcon = notification

export const analytics = [
    {
        type: 'Sessions',
        data: 256,
    },
    {
        type: 'NewUsers',
        data: 1256,
    },
    {
        type: 'Users',
        data: 4,
    },
]

export const blocks: BlockModel[] = [
    {
        title: 'createVerifier.title',
        subtitle: 'services.newVerifierCardSubtitle',
        description: 'services.newVerifierDescription',
        icon: gaImages.verifier,
        type: 'verifier',
        route: '/create-verifier',
        rightText: 'public.create',
    },
    {
        title: 'services.newIssueCardTitle',
        subtitle: 'services.newIssueCardSubtitle',
        description: 'services.newIssueDescription',
        icon: gaImages.issuance,
        type: 'issuer',
        route: '/create-issuer',
        rightText: 'public.create',
    },
]

export const headerVerifiersButton: ButtonModel = {
    label: 'createVerifier.newVerifier',
    hideIconTooltip: true,
    disabledTooltipLabel: t('subscription.limitReachedDescription', {
        feature: t('subscription.limitedFeatures.verificationTemplates'),
    }),
}

export const headerIssuersButton: ButtonModel = {
    label: 'createIssuance.newIssuance',
    hideIconTooltip: true,
    disabledTooltipLabel: t('subscription.limitReachedDescription', {
        feature: t('subscription.limitedFeatures.issuanceTemplates'),
    }),
}

export const propertiesToSearchVerifers = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const propertiesToSearchIssuers = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
    {
        text: 'appID',
        searchMultipleLanguages: false,
        latinize: true,
    },
    {
        text: 'issuanceID',
        searchMultipleLanguages: false,
        latinize: true,
    },
]
