export default {
    setSessionStorageData(key: string, payload: string) {
        sessionStorage.setItem(key, payload)
    },
    getSessionStorageData(key: string) {
        return sessionStorage.getItem(key)
    },
    deleteSessionStorageData(key: string) {
        return sessionStorage.removeItem(key)
    },
}
