import React from 'react'
import { t } from 'i18next'
import cx from 'classnames'
import CredentialPreviewModal from '../../CredentialPreview'
import { ButtonModel } from '../../../../../../interfaces/interfaces'
import NoActionPreview from '../NoActionPreview/NoActionPreview'
import { gaImages } from '../../../../../../assets/globalConstants'
import MediumModal from '../../../Medium/MediumModal'
import styles from '../../credentialPreviewModal.module.scss'

type IConfigurationCredentialPreviewProps = {
    issuanceTemplate?: boolean
    saveButton?: ButtonModel
    showSaveButton?: boolean
    creatingTemplate?: boolean
    smallResolution: boolean
    show: boolean
    hideModal: (x?) => void
}

const ConfigurationCredentialPreview: React.FC<
    IConfigurationCredentialPreviewProps
> = (props) => {
    const {
        issuanceTemplate,
        saveButton,
        showSaveButton,
        creatingTemplate,
        smallResolution,
        show,
        hideModal,
    } = props

    return (
        <>
            {smallResolution ? (
                <>
                    {show ? (
                        <MediumModal
                            title={t(
                                issuanceTemplate
                                    ? 'editIssuance.step1.credPrevTitle'
                                    : 'editVerifier.step1.credPrevTitle'
                            )}
                            subtitle={t(
                                issuanceTemplate
                                    ? 'editIssuance.step1.credPrevDescription'
                                    : 'editVerifier.step1.credPrevDescription'
                            )}
                            titleClassName={cx(
                                'heading6 neutral1000 marginBottom4'
                            )}
                            subtitleClassName={cx('bodyRegularSM neutral700')}
                            headerClose={true}
                            show={show}
                            handleClose={hideModal}
                            className={styles.previewModalResponsive}
                        >
                            <CredentialPreviewModal
                                titleHeader=""
                                descriptionHeader=""
                                modalResponsiveView={true}
                            >
                                <NoActionPreview
                                    title={t(
                                        issuanceTemplate && creatingTemplate
                                            ? 'createIssuance.credPrevBodyTitle'
                                            : issuanceTemplate &&
                                              !creatingTemplate
                                            ? 'editIssuance.step1.credPrevBodyTitle'
                                            : !issuanceTemplate &&
                                              creatingTemplate
                                            ? 'createVerifier.credPrevBodyTitle'
                                            : 'editVerifier.step1.credPrevBodyTitle'
                                    )}
                                    description={t(
                                        issuanceTemplate && creatingTemplate
                                            ? 'createIssuance.credPrevBodyDescription'
                                            : issuanceTemplate &&
                                              !creatingTemplate
                                            ? 'editIssuance.step1.credPrevBodyDescription'
                                            : !issuanceTemplate &&
                                              creatingTemplate
                                            ? 'createVerifier.credPrevBodyDescription'
                                            : 'editVerifier.step1.credPrevBodyDescription'
                                    )}
                                    image={gaImages.sandClock}
                                />
                            </CredentialPreviewModal>
                        </MediumModal>
                    ) : null}
                </>
            ) : (
                <CredentialPreviewModal
                    titleHeader={t(
                        issuanceTemplate
                            ? 'editIssuance.step1.credPrevTitle'
                            : 'editVerifier.step1.credPrevTitle'
                    )}
                    descriptionHeader={t(
                        issuanceTemplate
                            ? 'editIssuance.step1.credPrevDescription'
                            : 'editVerifier.step1.credPrevDescription'
                    )}
                    saveButton={saveButton}
                    showSaveButton={showSaveButton ? showSaveButton : false}
                >
                    <NoActionPreview
                        title={t(
                            issuanceTemplate && creatingTemplate
                                ? 'createIssuance.credPrevBodyTitle'
                                : issuanceTemplate && !creatingTemplate
                                ? 'editIssuance.step1.credPrevBodyTitle'
                                : !issuanceTemplate && creatingTemplate
                                ? 'createVerifier.credPrevBodyTitle'
                                : 'editVerifier.step1.credPrevBodyTitle'
                        )}
                        description={t(
                            issuanceTemplate && creatingTemplate
                                ? 'createIssuance.credPrevBodyDescription'
                                : issuanceTemplate && !creatingTemplate
                                ? 'editIssuance.step1.credPrevBodyDescription'
                                : !issuanceTemplate && creatingTemplate
                                ? 'createVerifier.credPrevBodyDescription'
                                : 'editVerifier.step1.credPrevBodyDescription'
                        )}
                        image={gaImages.sandClock}
                    />
                </CredentialPreviewModal>
            )}
        </>
    )
}

export default ConfigurationCredentialPreview
