import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import {
    ActionStatusTypes,
    purposesTypesList,
} from '../../../../../assets/globalConstants'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import EditableAssuranceCard from '../../../../../Components/organisms/CredentialCard/EditableCard/assurance/EditableAssuranceCard'
import FormSection from '../../../../../Components/organisms/FormSection/FormSection'
import {
    LabelPosition,
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../../data/globalVar'
import {
    ButtonModel,
    ISecurityConfig,
} from '../../../../../interfaces/interfaces'
import { removeObjectFromList } from '../../../../../utils/checkboxUtil'
import { getOrg } from '../../../../common/organization/organizationSelectors'
import { setSelectedCredentials } from '../../verifierActions'
import {
    formatRequestedCredentials,
    getFormattedRequestedCredentials,
    getSelectedVerifier,
    returnOriginalRequestedCredential,
} from '../../verifierSelectors'
import style from './constraintsForm.module.scss'
import SecondFactorModal from './modal/secondFactor/SecondFactorModal'
import { configVerifierSelectors } from '../..'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import GaCheckbox from '../../../../../Components/atoms/formFields/checkbox/GaCheckbox'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import SecondFactorCard from '../../../../../Components/organisms/Cards/SecondFactorCard/SecondFactorCard'
import TemplatesFormHeader from '../../../../common/TemplatesHeader/TemplatesFormHeader'
import ConstraintsCredentialPreview from '../../../../../Components/molecules/modals/CredentialPreview/components/StepsContentPreview/ContraintsCredentialPreview'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import { getLoadingStatus } from '../../../../common/catalog/catalogSelectors'
import { getResponsive } from '../../../../../utils/formUtils'

type IConstraintsFormProps = {
    canEditVerifiers?: boolean
    creatingVerifier?: boolean
    buttonHeader?: ButtonModel
    infoApiKeyPanel?: boolean
    activeStep: any
    formSteps: any
    buttonFunctionHeader?: (x) => void
    buttonFunction: (x) => void
    setValidForm: (x) => void
    onHandleChange: (step) => void
}

// TODO: change this when the option to select wallets is implemented
const appAuthSecurity = [
    {
        accepted: ['any'],
        type: 'AppAuth',
    },
]

const ConstraintsForm: React.FC<IConstraintsFormProps> = (props) => {
    const {
        canEditVerifiers,
        creatingVerifier,
        buttonHeader,
        infoApiKeyPanel,
        activeStep,
        formSteps,
        buttonFunctionHeader,
        buttonFunction,
        setValidForm,
        onHandleChange,
    } = props
    const dispatch = useDispatch()

    // Selectors
    const selected = useSelector(getFormattedRequestedCredentials)
    const verifier = useSelector(getSelectedVerifier)
    const org = useSelector(getOrg)
    const updateStatus = useSelector(configVerifierSelectors.getUpdateStatus)
    const initialRequestedUnfCred = useSelector(
        configVerifierSelectors.getInitialRequestedUnfCredentials
    )
    const currentRequestedUnfCred = useSelector(
        configVerifierSelectors.getRequestedUnfCredentials
    )
    const step = useSelector(configVerifierSelectors.getActiveStep)
    const credTypesList = useSelector(
        configVerifierSelectors.getOriginalCatalogTypes
    )
    const catalogLoadingStatus = useSelector(getLoadingStatus)
    const catalogIsLoading = !(
        catalogLoadingStatus?.status === 'finished' ||
        catalogLoadingStatus?.status === 'error'
    )
    // States and constants
    const [credentials, setSelection] = React.useState(
        formatRequestedCredentials(credTypesList, currentRequestedUnfCred) || []
    )
    const [formDataModified, setFormDataModified] = useState(false)
    const hasSecurity = (security: string) => {
        const issuanceSecutiry = verifier?.security
        return issuanceSecutiry?.filter((e) => e?.type === security)
    }
    const initial2AF = {
        has2FA: !!hasSecurity('AuthNFactor')?.length,
        factors: hasSecurity('AuthNFactor'),
    }
    const [appAuthentication, setAppAuthentication] = useState(
        !!hasSecurity('AppAuth')?.length
    )
    const [secondAuthFactors, setSecondAuthFactors] = useState({
        has2FA: !!hasSecurity('AuthNFactor')?.length,
        factors: hasSecurity('AuthNFactor'),
    })
    const [selected2AF, setSelected2FA] = useState(undefined)
    const [showSecModal, setShowSecurityModal] = useState(false)

    const sortedCurrentAuthFactors = { ...secondAuthFactors }?.factors?.map(
        (el) => {
            // @ts-ignore
            const elementCopy = { ...el }
            return {
                accepted: elementCopy?.accepted?.filter((e) => !!e),
                type: elementCopy?.type,
            }
        }
    )

    const sortedInitialSecondAuthFactors = { ...initial2AF }?.factors?.map(
        (el) => {
            const elementCopy = { ...el }
            return {
                accepted: elementCopy?.accepted?.filter((e) => !!e),
                type: elementCopy?.type,
            }
        }
    )

    const dataAgreementTemplateContextUrl =
        process.env.REACT_APP_DATA_AGREEMENT_CONTEXT_URL

    // Effects

    useEffect(() => {
        scrollToTop()
    }, [step])

    useEffect(() => {
        setSelection(
            formatRequestedCredentials(credTypesList, currentRequestedUnfCred)
        ),
            setValidForm(selected && !!selected[0])
    }, [step, currentRequestedUnfCred])

    const secondInitialFactors = secondAuthFactors?.has2FA
        ? sortedInitialSecondAuthFactors
        : undefined
    const secondInitialAuthFactorsSetted = secondInitialFactors
        ? appAuthentication
            ? sortedInitialSecondAuthFactors?.concat(appAuthSecurity)
            : sortedInitialSecondAuthFactors
        : appAuthentication
        ? appAuthSecurity
        : undefined

    const secondCurrentFactors = secondAuthFactors?.has2FA
        ? sortedCurrentAuthFactors
        : undefined
    const secondCurrentAuthFactorsSetted = secondCurrentFactors
        ? appAuthentication
            ? sortedCurrentAuthFactors?.concat(appAuthSecurity)
            : sortedCurrentAuthFactors
        : appAuthentication
        ? appAuthSecurity
        : undefined

    useEffect(() => {
        if (
            updateStatus === ActionStatusTypes?.success &&
            JSON.stringify(
                returnOriginalRequestedCredential(initialRequestedUnfCred)
            ) ===
                JSON.stringify(
                    returnOriginalRequestedCredential(currentRequestedUnfCred)
                ) &&
            !!hasSecurity('AppAuth')?.length === appAuthentication &&
            (initial2AF?.has2FA === secondAuthFactors?.has2FA ||
                (secondAuthFactors?.has2FA &&
                    !initial2AF?.has2FA &&
                    !hasSecurity('AuthNFactor')?.length)) &&
            JSON.stringify(secondInitialAuthFactorsSetted) ===
                JSON.stringify(secondCurrentAuthFactorsSetted)
        ) {
            setFormDataModified(false)
        }
    }, [updateStatus])

    useEffect(() => {
        if (
            JSON.stringify(
                returnOriginalRequestedCredential(initialRequestedUnfCred)
            ) ===
                JSON.stringify(
                    returnOriginalRequestedCredential(currentRequestedUnfCred)
                ) &&
            !!hasSecurity('AppAuth')?.length === appAuthentication &&
            (initial2AF?.has2FA === secondAuthFactors?.has2FA ||
                (secondAuthFactors?.has2FA &&
                    !initial2AF?.has2FA &&
                    !hasSecurity('AuthNFactor')?.length)) &&
            JSON.stringify(secondInitialAuthFactorsSetted) ===
                JSON.stringify(secondCurrentAuthFactorsSetted)
        ) {
            setFormDataModified(false)
        } else {
            setFormDataModified(true)
        }
    }, [
        selected,
        credentials,
        appAuthentication,
        secondAuthFactors,
        selected2AF,
    ])

    // Functions
    const setAssuranceLevel = (indexCred: number, value: number) => {
        const modifiedList = credentials

        if (modifiedList[indexCred]) {
            modifiedList[indexCred].trustLevel =
                modifiedList[indexCred].trustLevel !== 1 ? 1 : 0
        }

        dispatch(setSelectedCredentials(modifiedList))
        setSelection(modifiedList)
        setValidForm(credentials && credentials[0]?.length)
    }

    const setSelectedSecondFactors = (
        secondFactor: ISecurityConfig,
        value: ISecurityConfig
    ) => {
        const secondFactors = secondAuthFactors?.factors
        if (selected2AF && secondFactors) {
            const index = secondFactors?.indexOf(selected2AF || secondFactor)
            const modifiedList = secondFactors
            modifiedList[index] = value
            setSecondAuthFactors({
                ...secondAuthFactors,
                factors: modifiedList,
            })
        } else if (!!secondFactors) {
            secondFactors.push(value)
            setSecondAuthFactors({
                ...secondAuthFactors,
                factors: secondFactors,
            })
        } else {
            setSecondAuthFactors({ ...secondAuthFactors, factors: [value] })
        }
        setSelected2FA(undefined)
    }

    const hideSecurityModal = () => setShowSecurityModal(false)
    const showSecurityModal = () => setShowSecurityModal(true)

    const editFactor = (factor) => {
        setSelected2FA(factor)
    }

    const removeFactor = (factor: ISecurityConfig) => {
        const formattedFactor = {
            accepted: factor?.accepted?.filter((e) => !!e),
            type: factor?.type,
        }
        const selection = removeObjectFromList(
            formattedFactor,
            sortedCurrentAuthFactors
        )
        setSecondAuthFactors({
            has2FA: secondAuthFactors?.has2FA,
            factors: selection?.length ? selection : undefined,
        })
        setSelected2FA(selection?.length ? selection : undefined)
    }

    const formatVerifier = () => {
        const secondFactors = secondAuthFactors?.has2FA
            ? secondAuthFactors?.factors
            : undefined
        const secondAuthFactorsSetted = secondFactors
            ? appAuthentication
                ? secondAuthFactors?.factors?.concat(appAuthSecurity)
                : secondAuthFactors?.factors
            : appAuthentication
            ? appAuthSecurity
            : undefined

        const urlConnect = process.env['REACT_APP_CONNECT_HOST']
        let formattedVerifier = {
            ...verifier,
            credentials: credentials,
            security: secondAuthFactorsSetted,
            dataAgreementTemplate: {
                data_receiver: {
                    id: verifier?.vmethodId?.split('#')[0], //without keys
                    consent_duration:
                        verifier?.dataAgreementTemplate?.data_receiver
                            ?.consent_duration || 365, // Lo mismo que QR Duration
                    form_of_consent: 'explicit',
                    name: org?.orgInfo?.name, // Organization name
                    service: verifier.service,
                    url: urlConnect,
                },
                id: '',
                personal_data: credentials.map((cred) => {
                    return {
                        attribute_name: cred.id,
                        attribute_sensitive: true,
                        purposes: [cred.purpose],
                    }
                }),
                purposes: credentials
                    ?.map((cred) =>
                        purposesTypesList?.find((p) => p.id === cred.purpose)
                    )
                    .filter((p) => !!p)
                    .filter(
                        (p, index, array) =>
                            array.findIndex((dap) => p?.id === dap?.id) ===
                            index
                    ),
                template_id: verifier.id,
                template_version: verifier?.dataAgreementTemplate
                    ?.template_version
                    ? (
                          parseInt(
                              verifier?.dataAgreementTemplate?.template_version
                          ) + 1
                      ).toString()
                    : '1',
            },
        }

        formattedVerifier.dataAgreementTemplate['@context'] =
            dataAgreementTemplateContextUrl

        return formattedVerifier
    }

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    const saveAndNext = () => {
        let verifier = formatVerifier()
        buttonFunction(verifier)
        scrollToTop()
    }

    // Responsive preview

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    return (
        <>
            <div className={style.leftColumn}>
                <div className={style.headerStepper}>
                    <TemplatesFormHeader
                        title={
                            creatingVerifier
                                ? 'createVerifier.title'
                                : t('editVerifier.title', {
                                      id: verifier?.id || '',
                                  })
                        }
                        description={
                            creatingVerifier
                                ? 'createVerifier.description'
                                : 'editVerifier.description'
                        }
                        buttonFunctionHeader={buttonFunctionHeader}
                        buttonHeader={buttonHeader}
                        formSteps={formSteps}
                        elementsAllignment={creatingVerifier ? false : true}
                        infoApiKeyPanel={infoApiKeyPanel}
                        titleInfoPanel={t('editVerifier.addApiKeyInfo')}
                        onHandleChange={onHandleChange}
                        activeStep={activeStep}
                        loadingProgressBar={catalogIsLoading}
                        inactiveSteps={
                            !creatingVerifier && !verifier?.credentials?.length
                                ? [2, 3]
                                : undefined
                        }
                    />
                </div>
                <div className={style.form}>
                    <LightPanel>
                        <ScreenHeader
                            title={'editVerifier.step4.sectionTitle'}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            subText={'editVerifier.step4.sectionDescription'}
                            className={`${style.formHeader} ${cx(
                                'marginBottom32'
                            )}`}
                            buttonFunction={showModal}
                            button={
                                getResponsive(1320)
                                    ? {
                                          label: 'general.showPreview',
                                          disabled: false,
                                      }
                                    : undefined
                            }
                            buttonIsLight={true}
                        />
                        <FormSection
                            title={'editVerifier.step4.secondSectionTitle'}
                            description={
                                'editVerifier.step4.secondSectionDescription'
                            }
                            titleClassName={'buttonSM'}
                            titleInfo={t(
                                'editVerifier.step4.secondSectionTitleTooltip'
                            )}
                            tooltipPosition={TooltipPosition.bottom}
                            tooltipArrowPosition={TooltipArrowPosition.right}
                        >
                            <div className={style.panelContainer}>
                                {selected && selected.length ? (
                                    selected?.map((credential, index) => (
                                        <div key={index}>
                                            <EditableAssuranceCard
                                                index={index}
                                                canEdit={
                                                    canEditVerifiers ||
                                                    creatingVerifier
                                                }
                                                credential={credential}
                                                handleSelectChange={
                                                    setAssuranceLevel
                                                }
                                                indexCheckbox={credential.id}
                                                checkedValue={
                                                    !!credential?.trustLevel &&
                                                    credential?.trustLevel > 0
                                                }
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <CardListSkeleton
                                        key={'credentials__skeleton'}
                                        className={style.credentialsSkeleton}
                                        cardsNumber={1}
                                    />
                                )}
                            </div>
                        </FormSection>

                        <div
                            className={`${style.formSection} ${cx(
                                'marginTop32 width100'
                            )}`}
                        >
                            <FormSection
                                title={'editVerifier.step4.thirdSectionTitle'}
                                description={
                                    'editVerifier.step4.thirdSectionDescription'
                                }
                                titleClassName={'buttonSM'}
                            >
                                <div>
                                    <GaCheckbox
                                        index={'0'}
                                        itemText={t(
                                            'editVerifier.step4.addAppFactorLabel'
                                        )}
                                        id="appAuth"
                                        disabled={
                                            !canEditVerifiers &&
                                            !creatingVerifier
                                        }
                                        checked={!!appAuthentication}
                                        onChange={(e) => {
                                            setAppAuthentication(
                                                !appAuthentication
                                            )
                                        }}
                                        position={LabelPosition.right}
                                        className={`${
                                            style.appAuthenticationCheckbox
                                        } ${cx('marginBottom8')}`}
                                        infoIcon
                                        infoText={t(
                                            'editVerifier.step4.addAppFactorTooltip'
                                        )}
                                        tooltipPosition={TooltipPosition.bottom}
                                        tooltipArrowDirection={
                                            TooltipArrowPosition.left
                                        }
                                    />
                                    <GaCheckbox
                                        index={'1'}
                                        itemText={t(
                                            'editVerifier.step4.twoAuthFactorLabel'
                                        )}
                                        disabled={
                                            !canEditVerifiers &&
                                            !creatingVerifier
                                        }
                                        id="twoFactor"
                                        checked={!!secondAuthFactors?.has2FA}
                                        onChange={() => {
                                            setSecondAuthFactors({
                                                has2FA: !secondAuthFactors?.has2FA,
                                                factors:
                                                    sortedInitialSecondAuthFactors,
                                            })
                                        }}
                                        position={LabelPosition.right}
                                        infoIcon
                                        infoText={t(
                                            'editVerifier.step4.twoAuthFactorTooltip'
                                        )}
                                        tooltipPosition={TooltipPosition.bottom}
                                        tooltipArrowDirection={
                                            TooltipArrowPosition.left
                                        }
                                        className={style.twoAuthFactorCheckbox}
                                    />
                                    {!!secondAuthFactors?.has2FA ? (
                                        <LightPanel
                                            className={`${
                                                style.panelContainer__lightPanel
                                            } ${cx('marginTop20')}`}
                                        >
                                            <div className={style.panelHeader}>
                                                <div>
                                                    <p
                                                        className={cx(
                                                            'buttonSM'
                                                        )}
                                                    >
                                                        {t(
                                                            'editVerifier.step4.twoAuthFactor'
                                                        )}
                                                    </p>
                                                    <Description
                                                        className={cx(
                                                            'bodyRegularXS neutral800'
                                                        )}
                                                        text={
                                                            'editVerifier.step4.twoAuthFactorDescription'
                                                        }
                                                    />
                                                </div>
                                                {canEditVerifiers ||
                                                creatingVerifier ? (
                                                    <DarkButton
                                                        text={
                                                            'editVerifier.step4.addSecondFactor'
                                                        }
                                                        disabled={false}
                                                        functionality={
                                                            showSecurityModal
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                            {secondAuthFactors?.factors?.map(
                                                (el, index) => {
                                                    return (
                                                        <SecondFactorCard
                                                            item={el}
                                                            canEdit={
                                                                canEditVerifiers ||
                                                                creatingVerifier
                                                            }
                                                            key={
                                                                'secondFactorCard' +
                                                                index.toString()
                                                            }
                                                            requiredFactor={
                                                                el?.accepted[0]
                                                            }
                                                            backup={el.accepted?.slice(
                                                                1,
                                                                el.accepted
                                                                    ?.length
                                                            )}
                                                            removeFactor={
                                                                removeFactor
                                                            }
                                                            selectFactor={
                                                                editFactor
                                                            }
                                                            openModal={
                                                                showSecurityModal
                                                            }
                                                            editVerifier
                                                        />
                                                    )
                                                }
                                            )}
                                        </LightPanel>
                                    ) : null}
                                </div>
                            </FormSection>

                            {showSecModal ? (
                                <SecondFactorModal
                                    show={showSecModal}
                                    selected2AF={selected2AF}
                                    hideModal={hideSecurityModal}
                                    setSecondFactors={setSelectedSecondFactors}
                                />
                            ) : null}
                        </div>
                        {getResponsive(1320) ? (
                            <div className={style.buttonContainer}>
                                <DarkButton
                                    text={
                                        canEditVerifiers && !creatingVerifier
                                            ? 'public.save'
                                            : 'editVerifier.step4.sectionButton'
                                    }
                                    disabled={
                                        !formDataModified && !creatingVerifier
                                    }
                                    functionality={saveAndNext}
                                />
                            </div>
                        ) : null}
                    </LightPanel>
                </div>
            </div>

            <ConstraintsCredentialPreview
                saveButton={{
                    label:
                        canEditVerifiers && !creatingVerifier
                            ? 'public.save'
                            : 'editVerifier.step4.sectionButton',
                    disabled: !formDataModified && !creatingVerifier,
                    function: saveAndNext,
                }}
                data={selected}
                showSaveButton={canEditVerifiers || creatingVerifier}
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
            />
        </>
    )
}

export default ConstraintsForm
