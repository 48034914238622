import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { appIntegrationsSelectors } from '../../../../../store/appIntegrations'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import SearchableListHeader from '../../../../../Components/molecules/headers/SearchableListHeader/SearchableListHeader'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import TableSkeleton from '../../../../../Components/molecules/skeletons/tableSkeleton/TableSkeleton'
import { IAppIntegrationModel } from '../../../../../interfaces/interfaces'
import {
    getTotalPageItems,
    paginate,
} from '../../../../../utils/paginationUtil'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../../utils/permissionsUtil'
import { getCatalog } from '../../../../common/catalog/catalogActions'
import { sessionSelectors } from '../../../../common/session'
import { statsSelectors } from '../../../../common/Statistics'
import { getStatistics } from '../../../../common/Statistics/statisticsActions'
import {
    appIntegrationsScopes,
    headerButton,
    headersAppIntegrationsTable,
    propertiesToFuzzySearchAppIntergrations,
} from '../../data/AppIntegrationsList.constants'
import AppIntegrationsTable from './table/AppIntegrationsTable'
import { defaultPageSize } from '../../../../../Components/Pagination/paginationConstants'
import styles from './appIntegrationsList.module.scss'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import {
    clearAppIntegrations,
    deleteAppIntegration,
    getAppIntegrations,
    getScopes,
    getSecret,
    selectAppIntegration,
} from '../../../../../store/appIntegrations/appIntegrationsActions'
import ConfirmationModal from '../../../../../Components/molecules/modals/confirmation/Confirmation'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'
import RegenerateConfirmation from '../../components/regenerateModals/regenerateConfirmation/RegenerateConfirmation'
import {
    ActionStatusTypes,
    gaImages,
} from '../../../../../assets/globalConstants'
import NewClientSecret from '../../components/regenerateModals/newClientSecret/NewClientSecret'
import base64url from 'base64url'
import { setSelectedRoute } from '../../../../common/PanelScafolding/scafoldingActions'
import { history } from '../../../../../services/history'
import { sendingAppIntegrationDataStatus } from '../../../../../store/appIntegrationModal/appIntegrationModalSelectors'
import { clearModalState } from '../../../../../store/appIntegrationModal/appIntegrationModalActions'
import EditAppInt from '../../components/editAppInt/EditAppInt'
import { getOrgAction } from '../../../../common/organization/organizationActions'
import { orgSelectors } from '../../../../common/organization'
import { onPremise } from '../../../../../data/globalVar'
import { organizationScopes } from '../../../../common/organization/views/general/organization.constants'
import { getStatus } from '../../../../../store/appIntegrations/appIntegrationsSelectors'

type IAppIntegrationsListProps = {}

type ISearchState = {
    searched: string
    searchedResult: any
}

const AppIntegrationsList: React.FC<IAppIntegrationsListProps> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // Selectors
    const isLoading = useSelector(appIntegrationsSelectors.isLoading)
    const appIntegrationsList = useSelector(
        appIntegrationsSelectors.getAppIntegrations
    )
    const areScopesLoading = useSelector(
        appIntegrationsSelectors.areScopesLoading
    )
    const sendingAppStatus = useSelector(sendingAppIntegrationDataStatus)
    const updatingAppStatus = useSelector(getStatus)

    const selectedAppIntegration = useSelector(
        appIntegrationsSelectors.getSelectedAppIntegration
    )
    const selectedAppIntToEdit = useSelector(
        appIntegrationsSelectors.getSelectedAppIntegrationToEdit
    )
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)
    const regenerateSecretStatus = useSelector(
        appIntegrationsSelectors.getIsSecretStatus
    )
    const orgId = useSelector(sessionSelectors.getUserOrganization)
    const orgName = useSelector(orgSelectors.getOrg)?.orgInfo?.name
    const orgIsLoading = useSelector(orgSelectors.getOrgIsLoading)

    // States and constants
    const [pageIndex, setPageIndex] = useState(1)
    const [paginatedAppIntegrations, setPaginatedAppIntegrations] =
        useState<IAppIntegrationModel[]>()
    const [appIntegrations, setAppIntegrations] =
        useState<IAppIntegrationModel[]>()
    const [totalItems, setTotalItems] = useState(appIntegrations?.length)

    const initialPurposeListState = {
        searched: '',
        searchedResult: paginatedAppIntegrations?.slice(),
    }

    const [state, setState] = useState<ISearchState>(initialPurposeListState)
    const [showClientSecretModal, setShowClientSecretModal] = useState(false)

    const [showCreationErrorModal, setShowCreationErrorModal] = useState(false)
    const [showEditAppIntModal, setShowEditAppIntModal] = useState(false)

    const hasReadScope = !!(
        availableScopes &&
        userHasScopes(appIntegrationsScopes?.read, availableScopes)
    )

    const canReadOrg = !!(
        availableScopes &&
        userHasScopes(organizationScopes.read, availableScopes)
    )

    const [showDeleteWarning, setShowDeleteModal] = useState(false)
    const [showRegenerateWarning, setShowRegenerateModal] = useState(false)

    // Functions
    const showDeleteModal = (item) => {
        dispatch(selectAppIntegration(item))
        setShowDeleteModal(true)
    }

    const hideDeleteModal = () => {
        dispatch(clearAppIntegrations())
        setShowDeleteModal(false)
    }

    const showRegenerateModal = (item) => {
        dispatch(selectAppIntegration(item))
        setShowRegenerateModal(true)
    }

    const hideRegenerateModal = () => {
        dispatch(clearAppIntegrations())
        setShowRegenerateModal(false)
    }

    const showNewClientSecretModal = () => {
        setShowRegenerateModal(false)
        setShowClientSecretModal(true)
    }

    const hideClientSecretModal = () => {
        dispatch(clearAppIntegrations())
        setShowClientSecretModal(false)
    }

    const showEditClientModal = (item) => {
        dispatch(selectAppIntegration(item))
        setShowEditAppIntModal(true)
    }

    const handlePageChange = (page) => {
        setPageIndex(page)
        setPaginatedAppIntegrations(
            paginate(appIntegrations, defaultPageSize, page)
        )
        setState(initialPurposeListState)
    }

    const handleSearchedChange = (items) => {
        if (!!items) {
            setState({
                searched: state.searched,
                searchedResult: paginate(items, defaultPageSize, pageIndex),
            })
            setTotalItems(items.length)
        }
    }

    const deleteSelectedAppIntegration = () => {
        const selectedAppId = selectedAppIntegration?.client_id
        selectedAppId && dispatch(showScreenLoaderAction())
        selectedAppId && dispatch(deleteAppIntegration(selectedAppId))
        hideDeleteModal()
    }

    const navigateToCreateAppIntView = () => {
        history?.push('/create-app-integration')
        dispatch(setSelectedRoute('create-app-integration'))
    }

    const renerateSecret = () => {
        selectedAppIntegration && dispatch(showScreenLoaderAction())
        const appIntWithNewClientSecret = selectedAppIntegration
        const nameToEncode =
            appIntWithNewClientSecret?.client_name &&
            appIntWithNewClientSecret?.client_name?.length > 8
                ? appIntWithNewClientSecret?.client_name.slice(0, 8)
                : appIntWithNewClientSecret?.client_name
        const encodedName = base64url.encode(nameToEncode || '')
        const randomNum = (Math.random() + 1).toString(36).substring(2)
        const nameAndRandomNumConcatenate = encodedName + randomNum?.toString()
        const newClientSecret = nameAndRandomNumConcatenate
            .split('')
            .sort(function () {
                return 0.5 - Math.random()
            })
            .join('')
        const encodedNewClientSecret = base64url.encode(newClientSecret)
        appIntWithNewClientSecret
            ? (appIntWithNewClientSecret.client_secret = encodedNewClientSecret)
            : null
        selectedAppIntegration && dispatch(getSecret(selectedAppIntegration))
        hideRegenerateModal()
    }

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    // Effects
    useEffect(() => {
        if (sendingAppStatus === ActionStatusTypes.failed) {
            setShowCreationErrorModal(true)
        }
    }, [sendingAppStatus])

    useEffect(() => {
        if (
            updatingAppStatus === ActionStatusTypes.failed ||
            updatingAppStatus === ActionStatusTypes.success
        ) {
            scrollToTop()
            dispatch(clearAppIntegrations())
        }
    }, [updatingAppStatus])

    useEffect(() => {
        if (hasReadScope) {
            dispatch(getCatalog())
            dispatch(getScopes())
            dispatch(getAppIntegrations())
        }
        // Just in cloud for now
        if (!statisticsIsLoading && getIsActiveSession()) {
            dispatch(getStatistics())
        }

        if (orgId && canReadOrg && !orgName && !orgIsLoading) {
            // Just in cloud for now
            !onPremise && dispatch(getOrgAction())
        }
    }, [])

    useEffect(() => {
        if (regenerateSecretStatus === ActionStatusTypes.success) {
            showNewClientSecretModal()
        } else if (regenerateSecretStatus === ActionStatusTypes.failed) {
            hideRegenerateModal()
        }
    }, [regenerateSecretStatus])

    useEffect(() => {
        setAppIntegrations(appIntegrationsList)
        setState(initialPurposeListState)
    }, [appIntegrationsList, areScopesLoading, pageIndex])

    useEffect(() => {
        if (!!appIntegrations) {
            setPaginatedAppIntegrations(
                paginate(appIntegrations, defaultPageSize, pageIndex)
            )
        }
    }, [appIntegrations])

    useEffect(() => {
        if (!!appIntegrations) {
            setState(initialPurposeListState)
            setTotalItems(appIntegrations?.length)
        }
    }, [paginatedAppIntegrations])

    const paginationData = {
        currentPage: pageIndex,
        totalPages: getTotalPageItems(totalItems || 0, defaultPageSize) || 0,
        onChange: handlePageChange,
        totalItems: totalItems || 0,
        pageSize: defaultPageSize,
        page: pageIndex,
        className: 'marginTop32',
    }

    return (
        <>
            <ScreenHeader
                title={'appIntegrations.title'}
                subText="appIntegrations.desc"
                buttonScopes={appIntegrationsScopes?.create}
                button={headerButton}
                buttonFunction={navigateToCreateAppIntView}
            />

            {hasReadScope ? (
                <>
                    {!isLoading && !areScopesLoading ? (
                        !!appIntegrations &&
                        appIntegrations?.length &&
                        !!appIntegrations[0] ? (
                            <SearchableListHeader
                                title={''}
                                isLoading={isLoading}
                                options={appIntegrations}
                                className={styles.searchBar}
                                propertiesToFuzzySearch={
                                    propertiesToFuzzySearchAppIntergrations
                                }
                                handleSearchedChange={handleSearchedChange}
                            />
                        ) : null
                    ) : (
                        <CardListSkeleton
                            cardsNumber={1}
                            className={styles.searchBarSkeleton}
                        />
                    )}

                    {!isLoading ? (
                        !!appIntegrations &&
                        state.searchedResult &&
                        !!state.searchedResult[0] ? (
                            <>
                                <AppIntegrationsTable
                                    tableData={state.searchedResult}
                                    headerData={headersAppIntegrationsTable}
                                    paginationData={paginationData}
                                    deleteItem={showDeleteModal}
                                    editItem={showEditClientModal}
                                    regenerateSecret={showRegenerateModal}
                                    noResults={
                                        appIntegrations &&
                                        appIntegrations?.length > 0 &&
                                        !(
                                            state.searchedResult &&
                                            state.searchedResult?.length
                                        )
                                    }
                                />

                                {showDeleteWarning ? (
                                    <ConfirmationModal
                                        className={styles.confirmationModal}
                                        title={
                                            'appIntegrations.deleteModalTitle'
                                        }
                                        description={
                                            'appIntegrations.deleteModalDesc'
                                        }
                                        show={true}
                                        confirmFunction={
                                            deleteSelectedAppIntegration
                                        }
                                        hideModal={hideDeleteModal}
                                    />
                                ) : null}

                                {showRegenerateWarning ? (
                                    <RegenerateConfirmation
                                        className={styles.confirmationModal}
                                        title={
                                            'appIntegrations.regenerateClientSecretTitle'
                                        }
                                        description={
                                            'appIntegrations.regenerateClientSecretDesc'
                                        }
                                        show={true}
                                        confirmFunction={renerateSecret}
                                        hideModal={hideRegenerateModal}
                                    />
                                ) : null}
                                {showClientSecretModal ? (
                                    <NewClientSecret
                                        hideModal={hideClientSecretModal}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <Description
                                text={t('appIntegrations.noResults')}
                            />
                        )
                    ) : (
                        <TableSkeleton
                            columnsNumber={3}
                            rowsNumber={defaultPageSize}
                            responsiveCards={true}
                        />
                    )}
                    {showCreationErrorModal ? (
                        <ConfirmationModal
                            confirmFunction={() => {
                                setShowCreationErrorModal(false),
                                    dispatch(clearModalState())
                            }}
                            hideModal={() => (
                                setShowCreationErrorModal(false),
                                dispatch(clearModalState())
                            )}
                            title={t('appIntegrations.creationErrorTitle')}
                            description={t('appIntegrations.creationErrorDesc')}
                            continueText={t('appIntegrations.okClose')}
                            image={gaImages.yellowWarningPng}
                            show={true}
                            warning
                            noCancel
                        />
                    ) : null}

                    {showEditAppIntModal && selectedAppIntToEdit ? (
                        <EditAppInt
                            setShowEditAppIntModal={setShowEditAppIntModal}
                            selectedAppIntegration={selectedAppIntToEdit}
                        />
                    ) : null}
                </>
            ) : (
                <>
                    <PermissionsPanel
                        scopes={appIntegrationsScopes?.read}
                        readScope
                    />
                </>
            )}
        </>
    )
}

export default AppIntegrationsList
