import { t } from 'i18next'
import { gaImages } from '../../../assets/globalConstants'
import { ReactComponent as PlusLogo } from '../../../assets/images/plus.svg'
import { ButtonModel, StepModel } from '../../../interfaces/interfaces'
import ConfigurationForm from './forms/configuration/ConfigurationForm'
import ConstraintsForm from './forms/constraints/ConstraintsForm'
import CredentialsRequirementsForm from './forms/credentials-requirements/CredentialsRequirementsForm'
import CredentialsForm from './forms/credentials/CredentialsForm'
import IssuanceForm from './forms/issuance/IssuanceForm'

export const formSteps: StepModel[] = [
    {
        id: 1,
        label: 'editIssuance.configuration',
        order: 0,
        component: ConfigurationForm,
    },
    {
        id: 2,
        label: 'editIssuance.issuance',
        order: 1,
        component: IssuanceForm,
    },
    {
        id: 3,
        label: 'editIssuance.request',
        order: 2,
        component: CredentialsForm,
    },
    {
        id: 4,
        label: 'editIssuance.requirements',
        order: 3,
        component: CredentialsRequirementsForm,
        disabledInfo: t('editIssuance.requirementsUnavailable'),
    },
    {
        id: 5,
        label: 'editIssuance.restrictions',
        order: 4,
        component: ConstraintsForm,
        disabledInfo: t('editIssuance.restrictionsUnavailable'),
    },
]

export const modalHeaderButton: ButtonModel = {
    label: 'editIssuance.step4.newPurpose',
    image: gaImages.plus,
}

export const modalPrimaryButton: ButtonModel = {
    label: 'public.add',
}

export const modalNoSandboxPrimaryButton: ButtonModel = {
    label: 'public.save',
    svgIcon: PlusLogo,
}

export const modalSecondaryButton: ButtonModel = {
    label: 'public.cancel',
}

export const propertiesToSearchPurposes = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const propertiesToSearchCredentials = [
    {
        text: 'name',
        searchMultipleLanguages: true,
        latinize: true,
    },
]

export const issuancesScopes = {
    read: ['readSSIConfigs'],
    edit: ['updateSSIConfigs'],
    create: ['createSSIConfigs'],
    delete: ['deleteSSIConfigs'],
}
export const issuancesReadCreateScopes = ['readSSIConfigs', 'createSSIConfigs']
