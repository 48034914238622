import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    ICredentialsItemModel,
    IDataAgreementItem,
} from '../../../interfaces/interfaces'

// LIST
export interface ConsentsListState {
    consents: IDataAgreementItem[] | null
    consentData?: ICredentialsItemModel[]
    loadingStatus: boolean
    status: ActionStatusTypes
}

export interface IGetConsentsAction {
    type: ConsentActionsTypes.GET_CONSENTS
}

export interface IGetConsentsSuccessAction {
    type: ConsentActionsTypes.GET_CONSENTS_SUCCESS
    payload: {
        consents: IDataAgreementItem[]
    }
}

export interface IGetConsentsFailedAction {
    type: ConsentActionsTypes.GET_CONSENTS_FAILED
    payload: {
        error: string
    }
}

export interface IDeleteConsentAction {
    type: ConsentActionsTypes.DELETE_CONSENT
    payload: {
        consentId: string
    }
}

export interface IDeleteConsentSuccessAction {
    type: ConsentActionsTypes.DELETE_CONSENT_SUCCESS
}

export interface IDeleteConsentFailedAction {
    type: ConsentActionsTypes.DELETE_CONSENT_FAILED
    payload: {
        error: string
    }
}

export interface ISelectConsentAction {
    type: ConsentActionsTypes.SELECT_CONSENT
    payload: {
        consentId: string
    }
}

export interface ISelectConsentSuccessAction {
    type: ConsentActionsTypes.SELECT_CONSENT_SUCCESS
    payload: {
        data: ICredentialsItemModel[]
    }
}

export interface ISelectConsentFailedAction {
    type: ConsentActionsTypes.SELECT_CONSENT_FAILED
    payload: {
        error: string
    }
}

export interface IClearSelectedConsentAction {
    type: ConsentActionsTypes.CLEAR_SELECTED_CONSENT
}

export enum ConsentActionsTypes {
    GET_CONSENTS = 'consents/get',
    GET_CONSENTS_SUCCESS = 'consents/get_success',
    GET_CONSENTS_FAILED = 'consents/get_failed',
    DELETE_CONSENT = 'consents/delete',
    DELETE_CONSENT_SUCCESS = 'consents/delete_success',
    DELETE_CONSENT_FAILED = 'consents/delete_failed',
    SELECT_CONSENT = 'consents/select',
    SELECT_CONSENT_SUCCESS = 'consents/select_success',
    SELECT_CONSENT_FAILED = 'consents/select_failed',
    CLEAR_SELECTED_CONSENT = 'consents/clear_selected',
}

export type ConsentsListActions =
    | IGetConsentsAction
    | IGetConsentsSuccessAction
    | IGetConsentsFailedAction
    | IDeleteConsentAction
    | IDeleteConsentSuccessAction
    | IDeleteConsentFailedAction
    | ISelectConsentAction
    | ISelectConsentSuccessAction
    | ISelectConsentFailedAction
    | IClearSelectedConsentAction
