import {
    MetricCode,
    MetricStatistic,
    Statistics,
} from '../../../interfaces/interfaces'
import {
    IGetMetric,
    IGetMetricFailed,
    IGetMetricSuccess,
    IGetStatistics,
    IGetStatisticsFailed,
    IGetStatisticsSuccess,
    StatisticsParams,
    StatisticsTypes,
} from './statisticsTypes'

export const getStatistics = (params?: StatisticsParams): IGetStatistics => {
    return {
        type: StatisticsTypes.GET_ALL_STATISTICS,
    }
}

export const getStatisticsSuccess = (
    stats: Statistics
): IGetStatisticsSuccess => {
    return {
        type: StatisticsTypes.GET_ALL_STATISTICS_SUCCESS,
        payload: {
            statistics: stats,
        },
    }
}

export const getStatisticsFailed = (error: any): IGetStatisticsFailed => {
    return {
        type: StatisticsTypes.GET_ALL_STATISTICS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const getMetric = (
    code: MetricCode,
    params?: StatisticsParams
): IGetMetric => {
    return {
        type: StatisticsTypes.GET_METRIC,
        metricCode: code,
        payload: params,
    }
}

export const getMetricSuccess = (
    code: MetricCode,
    stats: MetricStatistic
): IGetMetricSuccess => {
    return {
        type: StatisticsTypes.GET_METRIC_SUCCESS,
        payload: {
            metricCode: code,
            statistics: stats,
        },
    }
}

export const getMetricFailed = (error: any): IGetMetricFailed => {
    return {
        type: StatisticsTypes.GET_METRIC_FAILED,
        payload: {
            error: error,
        },
    }
}
