import cx from 'classnames'
import React, { useState } from 'react'
import styles from './credentialCardDetail.module.scss'
import { gaColors } from '../../../../../assets/globalConstants'
import { t } from 'i18next'
import CredentialCard from '../credentialCard/CredentialCard'
import ChevronDownIcon from '../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import Tabs from '../../../../../Components/Tabs/Tabs'
import Info from './components/info/Info'
import Connections from './components/connections/Connections'
import {
    CredentialGroup,
    IDeepContent,
    VerifiableCredential,
} from '../../data/requestCredentials.interfaces'
import Content from './components/content/Content'
import { useSelector } from 'react-redux'
import { catalogSelectors } from '../../../../common/catalog'
import { getType } from '../../utils/displayDetailsUtil'
import { multiTypeCredentials } from '../../data/requestCredentials.constants'
import ChevronUpIcon from '../../../../../assets/iconsLibrary/simple/chevronUp/chevronUpIcon'
import CredentialDeepLevelDetails from './components/content/components/credDeepLevelDetails/CredDeepLevelDetails'
import GaModal from '../../../../../Components/molecules/modals/modal/modal'
import ConfirmationModal from '../../../../../Components/molecules/modals/confirmation/Confirmation'

type ICredentialCardDetailProps = {
    didAlias?: string
    id: string
    groupId: string
    group: CredentialGroup
    groupName: Map<string, string> | string
    issuer?: string | string[]
    icon?: any
    mainClaim?: string
    securityLevel?: number
    isPrivate?: boolean
    isActive?: boolean
    inactive?: boolean
    isDisabled?: boolean
    passingOnPress?: any
    isQsign?: boolean
    date?: any
    status?: string
    vcs: VerifiableCredential[]
    expirationDate?: any
    rawExpirationDate?: any
    isExpired?: boolean
    show?: boolean
    showConnectionsAndDelete?: boolean
    selectCred: (x?: any) => void
    hideModal: (x?: boolean) => void
    closeParentModal?: (x?: boolean) => void
    deleteCred?: (x?: any) => void
}

const CredentialCardDetail: React.FC<ICredentialCardDetailProps> = (props) => {
    const {
        didAlias,
        id,
        groupId,
        group,
        groupName,
        issuer,
        icon,
        mainClaim,
        securityLevel,
        isPrivate,
        isActive,
        inactive,
        isDisabled,
        passingOnPress,
        isQsign,
        date,
        status,
        vcs,
        expirationDate,
        rawExpirationDate,
        isExpired,
        showConnectionsAndDelete,
        show,
        selectCred,
        closeParentModal,
        hideModal,
        deleteCred,
    } = props

    // Constants
    const vcType = getType(vcs[0])
    const isMulti = multiTypeCredentials?.includes(vcType)

    // Selectors
    const catalogTypes = useSelector(catalogSelectors.getCatalogTypes)

    // States
    const [showDelete, setShowDelete] = useState(false)
    const [contentOpen, setContentOpen] = useState(false)

    // Functions
    const hideModalAndClean = () => {
        selectCred(undefined)
        hideModal()
    }

    const deleteCredAndHide = () => {
        deleteCred && deleteCred()
        setShowDelete(false)
    }

    // Tab Sections
    const detailSections = [
        {
            id: '1',
            tabTitle: t('ew.requestCredentials.info'),
            tabContent: (
                <Info
                    didAlias={didAlias || ''}
                    issuer={issuer}
                    status={status}
                    isPrivate={isPrivate}
                    issuanceDate={date}
                    expirationDate={expirationDate}
                    isExpired={isExpired}
                    vcs={vcs}
                />
            ),
        },
        {
            id: '2',
            tabTitle: t('ew.requestCredentials.connections'),
            tabContent: <Connections />,
        },
    ]

    const [contentToShow, setContentToShow] = useState<
        IDeepContent | undefined
    >()

    return (
        <>
            {!showDelete ? (
                !contentToShow ? (
                    <GaModal
                        title={'ew.requestCredentials.credDetail'}
                        hideModal={hideModalAndClean}
                        show={show}
                        backButton={true}
                        closeButton={!showConnectionsAndDelete}
                        trashButton={showConnectionsAndDelete}
                        deleteFunctionality={() => setShowDelete(true)}
                        className={styles.modal}
                        content={
                            <>
                                <div className={styles.credDetail}>
                                    <CredentialCard
                                        {...props}
                                        className={styles.credCard}
                                    />
                                    {mainClaim ? (
                                        <div className={styles.content}>
                                            <div
                                                className={styles.header}
                                                onClick={() =>
                                                    setContentOpen(!contentOpen)
                                                }
                                            >
                                                <p
                                                    className={cx(
                                                        'buttonSM',
                                                        contentOpen
                                                            ? 'primary700'
                                                            : 'neutral1000'
                                                    )}
                                                >
                                                    {t(
                                                        'ew.requestCredentials.content'
                                                    )}
                                                </p>
                                                {contentOpen ? (
                                                    <ChevronUpIcon
                                                        size={24}
                                                        color={
                                                            contentOpen
                                                                ? gaColors.primary700
                                                                : gaColors.neutral1000
                                                        }
                                                    />
                                                ) : (
                                                    <ChevronDownIcon
                                                        size={24}
                                                        color={
                                                            gaColors.neutral1000
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <Content
                                                group={group}
                                                vcs={vcs}
                                                isMulti={isMulti}
                                                groupName={groupName}
                                                contentOpen={contentOpen}
                                                types={catalogTypes}
                                                setContentToShow={
                                                    setContentToShow
                                                }
                                            />
                                        </div>
                                    ) : null}
                                </div>
                                <Tabs
                                    sections={
                                        showConnectionsAndDelete
                                            ? detailSections
                                            : [detailSections[0]]
                                    }
                                    className={cx(
                                        styles.tabs,
                                        !showConnectionsAndDelete &&
                                            styles.tabCursorPointer
                                    )}
                                />
                            </>
                        }
                        closeParentModal={closeParentModal}
                    />
                ) : (
                    <CredentialDeepLevelDetails
                        {...contentToShow}
                        setContentToShow={setContentToShow}
                    />
                )
            ) : (
                <ConfirmationModal
                    title="ew.requestCredentials.sureDelete"
                    description="ew.requestCredentials.wontRecover"
                    confirmFunction={() => {
                        hideModal(), deleteCredAndHide()
                    }}
                    show
                    hideModal={() => {
                        setShowDelete(false)
                    }}
                />
            )}
        </>
    )
}

export default CredentialCardDetail
