import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FormPanel from '../../../../Components/FormPanel/FormPanel'
import GaTextInput from '../../../../Components/atoms/formFields/textInput/GaTextInput'
import { isValidEmail } from '../../../../utils/validations/functions'
import PanelScafoldingPublic from '../../PanelScafolding/public/PanelScafoldingPublic'
import { getUserEmail_s } from '../../session/sessionSelectors'
import { emailSignUp } from '../loginAction'
import { goBackLink, loginButton } from './emailSignUpConstants'
import { sessionSelectors } from '../../session'
import GaTextInputPassword from '../../../../Components/atoms/formFields/textInputPassword/GaTextInputPassword'

type IEmailSignUpProps = {}

const EmailSignUp: React.FC<IEmailSignUpProps> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const user = useSelector(getUserEmail_s)
    const tokenExpiration = useSelector(sessionSelectors.getTokenExpiration)

    const initApp = async () => {
        if (!!user && !!tokenExpiration) {
            history.push('home')
        }
    }

    const goToBack = () => history.push('email-login')

    useEffect(() => {
        initApp()
    }, [tokenExpiration, user])

    const [formData, setFormState] = useState({
        email: '',
        password: '',
    })

    const { email, password } = formData
    const validForm = !!(
        formData?.email.trim()?.length && formData?.password.trim()?.length
    )

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formData,
            [name]: value,
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        validForm ? submitUserData() : null
    }

    const submitUserData = () => {
        dispatch(emailSignUp(email, password))
    }

    return (
        <PanelScafoldingPublic>
            <FormPanel
                title={'login.signIn'}
                legend={'login.signUpDescription'}
                primaryButtonDisabled={!validForm}
                primaryButton={loginButton}
                secondaryButton={goBackLink}
                secondaryFunction={goToBack}
                primaryFunction={onSubmit}
                onSubmit={onSubmit}
            >
                <GaTextInput
                    className={cx('margin-bottom')}
                    id={'email'}
                    invalid={!isValidEmail(formData?.email)}
                    errorTexts={[t('public.invalidEmail')]}
                    labelText={t('login.email')}
                    required={true}
                    name={'email'}
                    onChange={(e) => {
                        handleInputChange(e)
                    }}
                    placeholder={'Email'}
                    value={formData.email}
                />

                <GaTextInputPassword
                    className={cx('margin-bottom')}
                    id={'password'}
                    invalid={!formData?.password?.trim()}
                    errorTexts={[t('public.invalidPassword')]}
                    labelText={t('login.password')}
                    required={true}
                    name={'password'}
                    onChange={(e) => {
                        handleInputChange(e)
                    }}
                    placeholder={t('login.password')}
                    type="password"
                    value={formData.password}
                />
            </FormPanel>
        </PanelScafoldingPublic>
    )
}

EmailSignUp.defaultProps = {}

export default EmailSignUp
