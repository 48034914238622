import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './dropdownActions.module.scss'
import cx from 'classnames'
import { ImageModel, LinkModel } from '../../../../../../interfaces/interfaces'
import MoreVerticalIcon from '../../../../../../assets/iconsLibrary/simple/moreVertical/moreVerticalIcon'
import WarningIcon from '../../../../../../assets/iconsLibrary/simple/warning/warningIcon'
import SlashIcon from '../../../../../../assets/iconsLibrary/simple/slash/slashIcon'
import CheckCircleIcon from '../../../../../../assets/iconsLibrary/simple/checkCircle/checkCircleIcon'

type IDropdownActionsProps = {
    icon?: ImageModel
    iconClassName?: string
    className?: string
    menuOptions?: LinkModel[]
    left?: boolean
    noLayer?: boolean
    selectOptions?: {
        text: string
        value: string
        color?: string
    }[]
    item: any
    onHandleChange: (x, y?, z?) => void
    deleteItem: ((x: any, y?: any, z?: any) => void) | undefined
    seeDetails: ((x?: any) => void) | undefined
}

const DropdownActions: React.FC<IDropdownActionsProps> = (props) => {
    const {
        left,
        noLayer,
        item,
        className,
        selectOptions,
        onHandleChange,
        deleteItem,
        seeDetails,
    } = props
    const [active, setIsActive] = useState(false)
    const { t } = useTranslation()

    return (
        <div className={styles.dropdownActionsContainer}>
            <button
                className={styles.dropdownActionsButton + ' ' + className}
                onClick={() => {
                    setIsActive(!active)
                }}
            >
                <MoreVerticalIcon color="#1E1E20" size={34} />
            </button>
            {active && (
                <div
                    className={
                        (active ? styles.active : styles.inActive) +
                        ' ' +
                        styles.menuOptionsCard +
                        ' ' +
                        (left ? styles.alignLeft : '')
                    }
                    id="dropdownOptions"
                >
                    <ul className={styles.menuOptionsCard__list}>
                        {selectOptions && selectOptions?.length ? (
                            <p
                                className={cx(
                                    'bodyRegularXS neutral700',
                                    styles.menuOptionsCard__item__section
                                )}
                            >
                                {t('credentials.status')}
                            </p>
                        ) : null}
                        {selectOptions?.map((e, index) => {
                            return (
                                <li
                                    key={index}
                                    className={styles.menuOptionsCard__item}
                                    onClick={() => {
                                        setIsActive(!active)
                                        onHandleChange(item, e)
                                    }}
                                >
                                    {/* {e.color ? (
                                        <GaTag text={e.text} color={e.color} />
                                    ) : (
                                        t(e.text)
                                    )} */}
                                    {e.value === 'SUSPENDED' ? (
                                        <WarningIcon
                                            size={16}
                                            color="#1E1E20"
                                        />
                                    ) : e.value === 'REVOKED' ? (
                                        <SlashIcon size={16} color="#1E1E20" />
                                    ) : (
                                        <CheckCircleIcon
                                            size={16}
                                            color="#1E1E20"
                                        />
                                    )}
                                    <p
                                        className={cx(
                                            'buttonMD neutral1000 marginLeft8'
                                        )}
                                    >
                                        {t(e.text)}
                                    </p>
                                </li>
                            )
                        })}
                        {seeDetails || deleteItem ? (
                            <p
                                className={cx(
                                    'bodyRegularXS neutral700',
                                    styles.menuOptionsCard__item
                                )}
                            >
                                {t('credentials.actions')}
                            </p>
                        ) : null}
                        {seeDetails ? (
                            <li
                                className={`${styles.menuOptionsCard__item} `}
                                onClick={() => {
                                    seeDetails(item)
                                }}
                            >
                                <p className={cx('buttonMD neutral1000')}>
                                    {t('credentials.detailData')}
                                </p>
                            </li>
                        ) : null}
                        {deleteItem ? (
                            <li
                                className={`${styles.menuOptionsCard__item} `}
                                onClick={() => {
                                    deleteItem(item)
                                }}
                            >
                                <p className={cx('buttonMD alert300')}>
                                    {t('public.delete')}
                                </p>
                            </li>
                        ) : null}
                    </ul>
                </div>
            )}
            {!noLayer ? (
                <div
                    className={
                        active
                            ? styles.backgroundLayerActive
                            : styles.backgroundLayerInactive
                    }
                    onClick={() => {
                        setIsActive(!active)
                    }}
                ></div>
            ) : null}
        </div>
    )
}

export default DropdownActions
