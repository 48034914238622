import { Epic, ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import dataService from '../../services/dataService'
import { showNotification } from '../../features/common/Notification/notificationActions'
import {
    deleteNotificationFailed,
    deleteNotificationSuccess,
    downloadCredentialFailed,
    downloadCredentialSuccess,
    getUnreadNotificationsFailed,
    getUnreadNotificationsSuccess,
} from './notificationsActions'
import { NotificationsActionsTypes } from './notificationsTypes'
import { hideScreenLoaderAction } from '../../features/common/Loader/loaderActions'
import {
    getCredentials,
    importCredentialSuccess,
} from '../requestCredentials/requestCredentialsActions'
import { history } from '../../services/history'
import { setSelectedRoute } from '../../features/common/PanelScafolding/scafoldingActions'

// LIST
export const getUnreadNotificationsListEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS),
        switchMap((action): any => {
            const { profile } = action.payload

            return from(dataService.getUnreadNotifications(profile)).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(getUnreadNotificationsSuccess(response.response))
                    )
                }),
                catchError((error) => of(getUnreadNotificationsFailed(error)))
            )
        })
    )
}

export const downloadCredentialEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(NotificationsActionsTypes.DOWNLOAD_CREDENTIAL),
        switchMap((action): any => {
            const { profile, id } = action.payload

            return from(dataService.downloadCredential(profile, id)).pipe(
                mergeMap((response?: any) => {
                    history?.push('wallet')
                    return merge(
                        of(
                            downloadCredentialSuccess(response.response),
                            importCredentialSuccess({
                                vc:
                                    response.response?.length &&
                                    response.response[0]
                                        ? response.response[0]
                                        : undefined,
                            }),
                            setSelectedRoute('wallet'),
                            getCredentials(profile),
                            showNotification(
                                'ew.notifications.credDownloaded',
                                'success',
                                ''
                            )
                        )
                    )
                }),
                catchError((error) => of(downloadCredentialFailed(error)))
            )
        })
    )
}

export const deleteNotificationEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(NotificationsActionsTypes.DELETE_NOTIFICATION),
        switchMap((action): any => {
            const { profile, id } = action.payload

            return from(dataService.deleteNotification(profile, id)).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(
                            hideScreenLoaderAction(),
                            deleteNotificationSuccess(response.response),
                            showNotification(
                                'ew.notifications.notificationDeleted',
                                'success',
                                ''
                            )
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        hideScreenLoaderAction(),
                        deleteNotificationFailed(error),
                        showNotification(
                            'ew.notifications.errorDeletingNotification',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
