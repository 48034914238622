import * as d3 from 'd3'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './circlePercentageBar.module.scss'
import cx from 'classnames'
import { gaColors } from '../../../../assets/globalConstants'

type CirclePercentageBarProps = {
    id?: string
    quantity: number
    total: number | string | undefined
    width: number
    label: string
    arcWidth: number
    color: string
    unlimitedLabel: string
    hideTotal: boolean
    isOnHome?: boolean
}

const CirclePercentageBar: React.FC<CirclePercentageBarProps> = React.memo(
    (props) => {
        const { t } = useTranslation()
        const {
            id,
            quantity,
            total,
            width,
            arcWidth,
            color,
            label,
            unlimitedLabel,
            hideTotal,
            isOnHome,
        } = props

        const isNumber = (item) =>
            typeof item === 'number' && !Number.isNaN(item)
        const totalNumber =
            total && isNumber(total) && typeof total === 'number' && total > 0
                ? parseInt(total?.toString())
                : 0
        const percentageTotal =
            totalNumber > 0 ? (quantity * 100) / totalNumber : quantity
        const height = width
        const arcOuterRadius = width / 2
        const arcInnerRadius = width / 2 - arcWidth

        const arcGenerator = d3
            .arc()
            .innerRadius(arcInnerRadius)
            .outerRadius(arcOuterRadius)
            .startAngle(0)
            .cornerRadius(5)

        const percentageArc = (value) =>
            arcGenerator({ endAngle: 2 * Math.PI * value })

        return (
            <div className={styles.circlePercentageBar} id={id || ''}>
                <div className={styles.circleContainer}>
                    <svg height={height} width={width}>
                        <g transform={`translate(${width / 2}, ${height / 2})`}>
                            <path
                                d={percentageArc(1)}
                                opacity="0.2"
                                fill="#c6c6c8"
                            />
                        </g>
                        <g transform={`translate(${width / 2}, ${height / 2})`}>
                            <path
                                d={percentageArc(
                                    totalNumber > 0 ? percentageTotal / 100 : 1
                                )}
                                fill={
                                    total &&
                                    typeof total === 'number' &&
                                    total > 0
                                        ? color
                                        : gaColors.neutral300
                                }
                            />
                        </g>
                    </svg>
                    <p
                        className={`${cx(
                            'neutral1000 bodyBoldXL',
                            styles.quantity
                        )} ${hideTotal ? styles.centeredQuantity : ''}`}
                    >
                        {quantity}
                    </p>
                    {!hideTotal ? (
                        <p
                            className={cx(
                                styles.total,
                                'bodyRegularCap neutral700'
                            )}
                        >
                            {total && typeof total === 'number' && total > 0
                                ? t('public.ofTotalFeatures', { total: total })
                                : t(unlimitedLabel)}
                        </p>
                    ) : null}
                </div>
                <p
                    className={`${
                        styles.circlePercentageBar__labels__title
                    } ${cx(
                        'bodyRegularXS',
                        isOnHome && styles.responsiveText
                    )}`}
                >
                    {t(label)}
                </p>
            </div>
        )
    }
)

export default CirclePercentageBar
