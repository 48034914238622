import React from 'react'

type IdIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const IdIcon: React.FC<IdIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M19.1372 6.58823H25.4117C25.7445 6.58823 26.0637 6.72045 26.299 6.95578C26.5343 7.19113 26.6666 7.51031 26.6666 7.84314V29.1765C26.6666 29.5093 26.5343 29.8285 26.299 30.0638C26.0637 30.2991 25.7445 30.4314 25.4117 30.4314H6.58815C6.25533 30.4314 5.93614 30.2991 5.7008 30.0638C5.46547 29.8285 5.33325 29.5093 5.33325 29.1765V7.84314C5.33325 7.51031 5.46547 7.19113 5.7008 6.95578C5.93614 6.72045 6.25533 6.58823 6.58815 6.58823H12.8627"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.1371 8.47059C19.1371 8.637 19.0709 8.7966 18.9533 8.91426C18.8356 9.03193 18.676 9.09804 18.5096 9.09804H13.49C13.3236 9.09804 13.164 9.03193 13.0463 8.91426C12.9287 8.7966 12.8625 8.637 12.8625 8.47059V4.70588C12.8625 3.87383 13.1931 3.07585 13.7814 2.4875C14.3698 1.89916 15.1678 1.56863 15.9998 1.56863C16.8318 1.56863 17.6298 1.89916 18.2182 2.4875C18.8065 3.07585 19.1371 3.87383 19.1371 4.70588V8.47059Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.084 29.3333V27.608C23.084 26.6929 22.7204 25.8152 22.0733 25.1681C21.4262 24.521 20.5485 24.1574 19.6333 24.1574H12.7321C11.8169 24.1574 10.9393 24.521 10.2922 25.1681C9.64504 25.8152 9.28149 26.6929 9.28149 27.608V29.3333"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.1825 20.7068C18.0883 20.7068 19.6332 19.1619 19.6332 17.2562C19.6332 15.3505 18.0883 13.8056 16.1825 13.8056C14.2768 13.8056 12.7319 15.3505 12.7319 17.2562C12.7319 19.1619 14.2768 20.7068 16.1825 20.7068Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <path
                d="M19.1372 6.58823H25.4117C25.7445 6.58823 26.0637 6.72045 26.299 6.95578C26.5343 7.19113 26.6666 7.51031 26.6666 7.84314V29.1765C26.6666 29.5093 26.5343 29.8285 26.299 30.0638C26.0637 30.2991 25.7445 30.4314 25.4117 30.4314H6.58815C6.25533 30.4314 5.93614 30.2991 5.7008 30.0638C5.46547 29.8285 5.33325 29.5093 5.33325 29.1765V7.84314C5.33325 7.51031 5.46547 7.19113 5.7008 6.95578C5.93614 6.72045 6.25533 6.58823 6.58815 6.58823H12.8627"
                fill={props?.color || 'currentColor' || '#1E1E20'}
            />
            <path
                d="M19.1372 6.58823H25.4117C25.7445 6.58823 26.0637 6.72045 26.299 6.95578C26.5343 7.19113 26.6666 7.51031 26.6666 7.84314V29.1765C26.6666 29.5093 26.5343 29.8285 26.299 30.0638C26.0637 30.2991 25.7445 30.4314 25.4117 30.4314H6.58815C6.25533 30.4314 5.93614 30.2991 5.7008 30.0638C5.46547 29.8285 5.33325 29.5093 5.33325 29.1765V7.84314C5.33325 7.51031 5.46547 7.19113 5.7008 6.95578C5.93614 6.72045 6.25533 6.58823 6.58815 6.58823H12.8627"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.1371 8.47059C19.1371 8.637 19.0709 8.7966 18.9533 8.91426C18.8356 9.03193 18.676 9.09804 18.5096 9.09804H13.49C13.3236 9.09804 13.164 9.03193 13.0463 8.91426C12.9287 8.7966 12.8625 8.637 12.8625 8.47059V4.70588C12.8625 3.87383 13.1931 3.07585 13.7814 2.4875C14.3698 1.89916 15.1678 1.56863 15.9998 1.56863C16.8318 1.56863 17.6298 1.89916 18.2182 2.4875C18.8065 3.07585 19.1371 3.87383 19.1371 4.70588V8.47059Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke="white"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.084 29.3333V27.608C23.084 26.6929 22.7204 25.8152 22.0733 25.1681C21.4262 24.521 20.5485 24.1574 19.6333 24.1574H12.7321C11.8169 24.1574 10.9393 24.521 10.2922 25.1681C9.64504 25.8152 9.28149 26.6929 9.28149 27.608V29.3333"
                fill="white"
            />
            <path
                d="M16.1825 20.7068C18.0883 20.7068 19.6332 19.1619 19.6332 17.2562C19.6332 15.3505 18.0883 13.8056 16.1825 13.8056C14.2768 13.8056 12.7319 15.3505 12.7319 17.2562C12.7319 19.1619 14.2768 20.7068 16.1825 20.7068Z"
                fill="white"
            />
        </>
    )
}

export default IdIcon
