import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gaImages } from '../../../../assets/globalConstants'
import { getSimpleSearch } from '../../../../utils/formatDataUtil'
import searchBarStyles from '../../../molecules/searchBar/searchBar.module.scss'
import GaImage from '../../Image/Image'
import GaTag from '../../tag/Tag'
import Description from '../../texts/Description/Description'
import gaSelectTagStyles from './gaSelectTag.module.scss'
import ChevronUpIcon from '../../../../assets/iconsLibrary/simple/chevronUp/chevronUpIcon'
import ChevronDownIcon from '../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'

type GaSelectTagProps = {
    id?: string
    item?: any
    labelText?: string
    placeholder?: string
    value?: string
    hasSearch?: boolean
    disabled?: boolean
    name?: string
    required?: boolean
    className?: string
    options: any[]
    optionTextProperty?: string
    optionValueProperty?: string
    autoComplete?: string
    isComboBox?: boolean
    isLoading?: boolean
    checked?: boolean
    canEdit?: boolean
    onChange: (e?) => void
    iconTagFunctionality?: (x, y?) => void
}

const GaSelectTag: React.FC<GaSelectTagProps> = React.memo((props) => {
    const {
        id,
        item,
        labelText,
        placeholder,
        className,
        disabled,
        name,
        value,
        hasSearch,
        required,
        options,
        autoComplete,
        isLoading,
        onChange,
        canEdit,
        iconTagFunctionality,
    } = props
    const { t } = useTranslation()

    const [availableOptions, setOptions] = useState(new Array())
    const [touched, setTouched] = useState(false)
    const [checked, setChecked] = useState(new Array())
    // In case we need this in the future
    // const didConfigs = useSelector(didsSelectors.getdidConfigs)
    // const catalogIsLoaded = useSelector(catalogSelectors.getLoadingStatus)
    const [searchedResult, setSearchState] = React.useState(
        options?.slice() || []
    )
    const [showDropdown, setShowDropdown] = useState(false)

    function handleSearchedChange(e) {
        let searchResult = getSimpleSearch(options, e)
        getSimpleSearch(options, e)
        setSearchState(searchResult)
    }

    useEffect(() => {
        setOptions(options)
    }, [options])
    useEffect(() => {}, [checked?.length])

    useEffect(() => {}, [options])

    useEffect(() => {}, [isLoading])
    return !isLoading ? (
        <div
            id={id ? id : labelText || '' + Math.random()}
            className={`${className} ${
                gaSelectTagStyles.gaSelectTagContainer
            } ${required ? gaSelectTagStyles.required : ''}`}
        >
            {canEdit && (
                <div
                    className={gaSelectTagStyles.overSelectTag}
                    onClick={() =>
                        setShowDropdown((showDropdown) => !showDropdown)
                    }
                >
                    <select
                        className={gaSelectTagStyles.noneEvents}
                        disabled={disabled}
                        id={id ? id : labelText || '' + Math.random()}
                        name={name ? name : ''}
                        onChange={() => () => {}}
                        onBlur={() => setTouched(true)}
                        required={required || false}
                        autoComplete={autoComplete ? autoComplete : 'Off'}
                        placeholder={placeholder ? t(placeholder) : ''}
                        value={value || ''}
                    ></select>
                    {showDropdown ? (
                        <ChevronUpIcon size={18} />
                    ) : (
                        <ChevronDownIcon size={18} />
                    )}
                </div>
            )}
            {showDropdown ? (
                <div
                    className={gaSelectTagStyles.checkboxes}
                    id={gaSelectTagStyles.checkboxes + id}
                >
                    {!!availableOptions?.length ? (
                        <>
                            {hasSearch ? (
                                <div className={`${searchBarStyles.searchBar}`}>
                                    <GaImage
                                        className={
                                            searchBarStyles.searchBar__icon
                                        }
                                        image={gaImages.searchBar}
                                    />
                                    <input
                                        type="text"
                                        className={
                                            searchBarStyles.searchBar__input
                                        }
                                        placeholder={t('public.search')}
                                        onChange={(e) =>
                                            handleSearchedChange(e.target.value)
                                        }
                                    />
                                </div>
                            ) : null}

                            <ul className={gaSelectTagStyles.list}>
                                <Description
                                    className={cx('margin-top-min')}
                                    text={t('dids.addType')}
                                />
                                {searchedResult?.map((option, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={cx('margin-top-min')}
                                        >
                                            <GaTag
                                                color={option.color}
                                                text={option.text}
                                                icon={gaImages.lightPlus}
                                                item={item ? item : ''}
                                                functionality={
                                                    iconTagFunctionality
                                                }
                                            />
                                        </div>
                                    )
                                })}
                            </ul>
                        </>
                    ) : null}
                    <div
                        className={
                            showDropdown
                                ? gaSelectTagStyles.backgroundLayerActive
                                : ''
                        }
                        onClick={() => {
                            setShowDropdown(!showDropdown)
                        }}
                    ></div>
                </div>
            ) : null}
        </div>
    ) : (
        <span className={`${gaSelectTagStyles.skeleton}`} />
    )
})

export default GaSelectTag
