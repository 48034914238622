import { applyMiddleware, compose, createStore } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { history } from '../services/history'
import rootEpic from './indexEpics'
import rootReducer from './indexReducers'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}

const epicMiddleware = createEpicMiddleware({
    dependencies: {
        history: history,
    },
})

export default function configureStore() {
    const store = createStore(
        rootReducer,
        compose(applyMiddleware(epicMiddleware), devToolsEnhancer({}))
    )
    epicMiddleware.run(rootEpic)
    return store
}
