import React from 'react'
import styles from './templatesFormHeader.module.scss'
import ScreenHeader from '../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import LineProgressBar from '../../../Components/molecules/progress/LineProgressBar/LineProgressBar'
import { ButtonModel } from '../../../interfaces/interfaces'
import InfoPanelWithAction from '../../../Components/molecules/notification/panels/info/infoPanelWithAction/InfoPanelWithAction'
import CardListSkeleton from '../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'

type ITemplatesFormHeaderProps = {
    title: string
    description: string
    buttonHeader?: ButtonModel
    buttonFunctionHeader?: ((x?: any) => void) | any
    infoApiKeyPanel?: boolean
    titleInfoPanel?: string
    activeStep: any
    formSteps: any
    loadingProgressBar?: boolean
    elementsAllignment?: boolean
    inactiveSteps?: number[]
    onHandleChange: (step) => void
}

const TemplatesFormHeader: React.FC<ITemplatesFormHeaderProps> = (props) => {
    const {
        title,
        description,
        formSteps,
        buttonHeader,
        buttonFunctionHeader,
        infoApiKeyPanel,
        titleInfoPanel,
        elementsAllignment,
        loadingProgressBar,
        activeStep,
        inactiveSteps,
        onHandleChange,
    } = props

    return (
        <>
            <ScreenHeader
                className={
                    elementsAllignment && elementsAllignment
                        ? styles.elementsAllignment
                        : ''
                }
                title={title}
                titleClassname={'heading5'}
                subText={description}
                button={buttonHeader}
                subTextClassname={'bodyRegularMD'}
                buttonFunction={buttonFunctionHeader}
            />
            {infoApiKeyPanel ? (
                <div className={styles.infoPanel}>
                    <InfoPanelWithAction
                        hasIcon={true}
                        title={titleInfoPanel}
                    />
                </div>
            ) : null}
            <div className={styles.stepper}>
                {!loadingProgressBar ? (
                    <LineProgressBar
                        activeStep={activeStep}
                        formSteps={formSteps}
                        inactiveSteps={inactiveSteps}
                        onHandleChange={onHandleChange}
                    />
                ) : (
                    <CardListSkeleton
                        cardsNumber={1}
                        className={styles.stepperSkeleton}
                    />
                )}
            </div>
        </>
    )
}

export default TemplatesFormHeader
