import { t } from 'i18next'
import { ButtonModel } from '../../../interfaces/interfaces'

export const headerCertificatesListButton: ButtonModel = {
    label: 'certificates.upload',
    hideIconTooltip: true,
    disabledTooltipLabel: t('subscription.limitReachedDescription', {
        feature: t('subscription.limitedFeatures.apiKeys'),
    }),
}
