import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import EditableAssuranceCard from '../../../../../Components/organisms/CredentialCard/EditableCard/assurance/EditableAssuranceCard'
import FormSection from '../../../../../Components/organisms/FormSection/FormSection'
import {
    ButtonModel,
    ISecurityConfig,
} from '../../../../../interfaces/interfaces'
import { removeObjectFromList } from '../../../../../utils/checkboxUtil'
import { setSelectedCredentials } from '../../issuanceActions'
import {
    formatRequestedCredentials,
    getActiveStep,
    getFormattedRequestedCredentials,
    getInitialRequestedUnfCredentials,
    getRequestedUnfCredentials,
    getSelectedIssuance,
    getUpdateStatus,
    returnOriginalRequestedCredential,
} from '../../issuanceSelectors'
import style from './constraintsForm.module.scss'
import SecondFactorModal from './modal/secondFactor/SecondFactorModal'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import GaCheckbox from '../../../../../Components/atoms/formFields/checkbox/GaCheckbox'
import {
    LabelPosition,
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../../data/globalVar'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import SecondFactorCard from '../../../../../Components/organisms/Cards/SecondFactorCard/SecondFactorCard'
import TemplatesFormHeader from '../../../../common/TemplatesHeader/TemplatesFormHeader'
import ConstraintsCredentialPreview from '../../../../../Components/molecules/modals/CredentialPreview/components/StepsContentPreview/ContraintsCredentialPreview'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import { issuanceSelectors } from '../..'
import { getLoadingStatus } from '../../../../common/catalog/catalogSelectors'
import { getResponsive } from '../../../../../utils/formUtils'

type IConstraintsFormProps = {
    canEditIssuances?: boolean
    creatingIssuance?: boolean
    buttonHeader?: ButtonModel
    infoApiKeyPanel?: boolean
    activeStep: any
    formSteps: any
    onHandleChange: (step) => void
    buttonFunctionHeader?: (x) => void
    buttonFunction: (x) => void
    setValidForm: (x) => void
}

// TODO: change this when the option to select wallets is implemented
const appAuthSecurity = [
    {
        accepted: ['any'],
        type: 'AppAuth',
    },
]

const ConstraintsForm: React.FC<IConstraintsFormProps> = (props) => {
    const {
        canEditIssuances,
        creatingIssuance,
        buttonHeader,
        infoApiKeyPanel,
        activeStep,
        formSteps,
        buttonFunction,
        setValidForm,
        onHandleChange,
        buttonFunctionHeader,
    } = props
    const dispatch = useDispatch()

    // Selectors
    const selected = useSelector(getFormattedRequestedCredentials)
    const updateStatus = useSelector(getUpdateStatus)
    const initialRequestedUnfCred = useSelector(
        getInitialRequestedUnfCredentials
    )
    const currentRequestedUnfCred = useSelector(getRequestedUnfCredentials)
    const step = useSelector(getActiveStep)
    const credTypesList = useSelector(issuanceSelectors.getOriginalCatalogTypes)
    const issuance = useSelector(getSelectedIssuance)
    const catalogLoadingStatus = useSelector(getLoadingStatus)
    const catalogIsLoading = !(
        catalogLoadingStatus?.status === 'finished' ||
        catalogLoadingStatus?.status === 'error'
    )
    // States and constants
    const issuanceSecutiry = issuance?.prConfig?.security

    const [credentials, setSelection] = React.useState(
        formatRequestedCredentials(credTypesList, currentRequestedUnfCred) || []
    )

    const [formDataModified, setFormDataModified] = useState(false)
    const hasSecurity = (security: string) => {
        return issuanceSecutiry?.filter((e) => e?.type && e?.type === security)
    }
    const [appAuthentication, setAppAuthentication] = React.useState(
        !!hasSecurity('AppAuth')?.length
    )
    const [showSecModal, setShowSecurityModal] = React.useState(false)
    const [secondAuthFactors, setSecondAuthFactors] = React.useState({
        has2FA: !!hasSecurity('AuthNFactor')?.length,
        factors: hasSecurity('AuthNFactor'),
    })
    const [selected2AF, setSelected2FA] = React.useState<
        ISecurityConfig | undefined
    >(undefined)

    const initial2AF = {
        has2FA: !!hasSecurity('AuthNFactor')?.length,
        factors: hasSecurity('AuthNFactor'),
    }

    const sortedCurrentAuthFactors = { ...secondAuthFactors }?.factors?.map(
        (el) => {
            // @ts-ignore
            const elementCopy = { ...el }
            return {
                accepted: elementCopy?.accepted?.filter((e) => !!e),
                type: elementCopy?.type,
            }
        }
    )

    const sortedInitialSecondAuthFactors = { ...initial2AF }?.factors?.map(
        (el) => {
            const elementCopy = { ...el }
            return {
                accepted: elementCopy?.accepted?.filter((e) => !!e),
                type: elementCopy?.type,
            }
        }
    )

    const secondInitialFactors = secondAuthFactors?.has2FA
        ? sortedInitialSecondAuthFactors
        : undefined
    const secondInitialAuthFactorsSetted = secondInitialFactors
        ? appAuthentication
            ? sortedInitialSecondAuthFactors?.concat(appAuthSecurity)
            : sortedInitialSecondAuthFactors
        : appAuthentication
        ? appAuthSecurity
        : undefined

    const secondCurrentFactors = secondAuthFactors?.has2FA
        ? sortedCurrentAuthFactors
        : undefined
    const secondCurrentAuthFactorsSetted = secondCurrentFactors
        ? appAuthentication
            ? sortedCurrentAuthFactors?.concat(appAuthSecurity)
            : sortedCurrentAuthFactors
        : appAuthentication
        ? appAuthSecurity
        : undefined

    // Effects

    useEffect(() => {
        scrollToTop()
    }, [step])

    useEffect(() => {
        setSelection(
            formatRequestedCredentials(credTypesList, currentRequestedUnfCred)
        ),
            setValidForm(selected && !!selected[0])
    }, [step, currentRequestedUnfCred])

    useEffect(() => {
        if (
            updateStatus === ActionStatusTypes?.success &&
            JSON.stringify(
                returnOriginalRequestedCredential(initialRequestedUnfCred)
            ) ===
                JSON.stringify(
                    returnOriginalRequestedCredential(currentRequestedUnfCred)
                ) &&
            !!hasSecurity('AppAuth')?.length === appAuthentication &&
            (initial2AF?.has2FA === secondAuthFactors?.has2FA ||
                (secondAuthFactors?.has2FA &&
                    !initial2AF?.has2FA &&
                    !hasSecurity('AuthNFactor')?.length)) &&
            JSON.stringify(secondInitialAuthFactorsSetted) ===
                JSON.stringify(secondCurrentAuthFactorsSetted)
        ) {
            setFormDataModified(false)
        }
    }, [updateStatus])

    useEffect(() => {
        if (
            JSON.stringify(
                returnOriginalRequestedCredential(initialRequestedUnfCred)
            ) ===
                JSON.stringify(
                    returnOriginalRequestedCredential(currentRequestedUnfCred)
                ) &&
            !!hasSecurity('AppAuth')?.length === appAuthentication &&
            (initial2AF?.has2FA === secondAuthFactors?.has2FA ||
                (secondAuthFactors?.has2FA &&
                    !initial2AF?.has2FA &&
                    !hasSecurity('AuthNFactor')?.length)) &&
            JSON.stringify(secondInitialAuthFactorsSetted) ===
                JSON.stringify(secondCurrentAuthFactorsSetted)
        ) {
            setFormDataModified(false)
        } else {
            setFormDataModified(true)
        }
    }, [
        selected,
        credentials,
        appAuthentication,
        secondAuthFactors,
        selected2AF,
    ])

    // Functions
    const setAssuranceLevel = (indexCred: number, value: number) => {
        const modifiedList = credentials

        if (modifiedList[indexCred]) {
            modifiedList[indexCred].trustLevel =
                modifiedList[indexCred].trustLevel !== 1 ? 1 : 0
        }

        dispatch(setSelectedCredentials(modifiedList))
        setSelection(modifiedList)
        setValidForm(credentials && credentials[0]?.length)
    }

    const setSelectedSecondFactors = (
        secondFactor: ISecurityConfig,
        value: ISecurityConfig
    ) => {
        const secondFactors = secondAuthFactors?.factors
        if (selected2AF?.type && secondFactors?.length) {
            const index = secondFactors?.indexOf(selected2AF || secondFactor)
            const modifiedList = secondFactors
            modifiedList[index] = value
            setSecondAuthFactors({
                ...secondAuthFactors,
                factors: modifiedList,
            })
        } else if (!!secondFactors?.length) {
            secondFactors.push(value)
            setSecondAuthFactors({
                ...secondAuthFactors,
                factors: secondFactors,
            })
        } else {
            setSecondAuthFactors({ ...secondAuthFactors, factors: [value] })
        }
        setSelected2FA(undefined)
    }

    const hideSecurityModal = () => setShowSecurityModal(false)
    const showSecurityModal = () => setShowSecurityModal(true)

    const editFactor = (factor) => {
        setSelected2FA(factor)
    }

    const removeFactor = (factor: ISecurityConfig) => {
        const formattedFactor = {
            accepted: factor?.accepted?.filter((e) => !!e),
            type: factor?.type,
        }
        const selection = removeObjectFromList(
            formattedFactor,
            sortedCurrentAuthFactors
        )
        setSecondAuthFactors({
            has2FA: secondAuthFactors?.has2FA,
            factors: selection?.length ? selection : undefined,
        })
        setSelected2FA(selection?.length ? selection : undefined)
    }

    const saveAndNext = () => {
        const secondFactors = secondAuthFactors?.has2FA
            ? secondAuthFactors?.factors
            : undefined
        const secondAuthFactorsSetted = secondFactors
            ? appAuthentication
                ? secondAuthFactors?.factors?.concat(appAuthSecurity)
                : secondAuthFactors?.factors
            : appAuthentication
            ? appAuthSecurity
            : undefined

        buttonFunction({
            prConfig: {
                ...issuance.prConfig,
                credentials: credentials,
                security: secondAuthFactorsSetted,
            },
        })
        scrollToTop()
    }

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    // Responsive preview

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    return (
        <>
            <div className={style.leftColumn}>
                <div className={style.headerStepper}>
                    <TemplatesFormHeader
                        title={
                            creatingIssuance
                                ? 'createIssuance.title'
                                : t('editIssuance.title', {
                                      id: issuance?.id || '',
                                  })
                        }
                        description={
                            creatingIssuance
                                ? 'createIssuance.description'
                                : 'editIssuance.description'
                        }
                        buttonFunctionHeader={buttonFunctionHeader}
                        buttonHeader={buttonHeader}
                        formSteps={formSteps}
                        elementsAllignment={creatingIssuance ? false : true}
                        infoApiKeyPanel={infoApiKeyPanel}
                        titleInfoPanel={t('editIssuance.addApiKeyInfo')}
                        onHandleChange={onHandleChange}
                        activeStep={activeStep}
                        loadingProgressBar={catalogIsLoading}
                        inactiveSteps={
                            !creatingIssuance &&
                            !issuance?.prConfig?.credentials?.length
                                ? [3, 4]
                                : undefined
                        }
                    />
                </div>
                <div className={style.form}>
                    <LightPanel>
                        <ScreenHeader
                            title={'editIssuance.step5.sectionTitle'}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            subText={'editIssuance.step5.sectionDescription'}
                            className={`${style.formHeader} ${cx(
                                'marginBottom32'
                            )}`}
                            buttonFunction={showModal}
                            button={
                                getResponsive(1320)
                                    ? {
                                          label: 'general.showPreview',
                                          disabled: false,
                                      }
                                    : undefined
                            }
                            buttonIsLight={true}
                        />
                        <FormSection
                            title={'editIssuance.step5.secondSectionTitle'}
                            description="editIssuance.step5.secondSectionDescription"
                            titleClassName={'buttonSM'}
                            titleInfo={t(
                                'editIssuance.step5.secondSectionTitleTooltip'
                            )}
                            tooltipPosition={TooltipPosition.bottom}
                            tooltipArrowPosition={TooltipArrowPosition.right}
                        >
                            <div className={style.panelContainer}>
                                {selected && selected.length ? (
                                    selected?.map((credential, index) => (
                                        <div
                                            key={
                                                'editableAssurance' +
                                                index.toString()
                                            }
                                        >
                                            <EditableAssuranceCard
                                                index={index}
                                                canEdit={
                                                    canEditIssuances ||
                                                    creatingIssuance
                                                }
                                                credential={credential}
                                                handleSelectChange={
                                                    setAssuranceLevel
                                                }
                                                indexCheckbox={credential.id}
                                                checkedValue={
                                                    !!credential?.trustLevel &&
                                                    credential?.trustLevel > 0
                                                }
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <CardListSkeleton
                                        key={'credentials__skeleton'}
                                        className={style.credentialsSkeleton}
                                        cardsNumber={1}
                                    />
                                )}
                            </div>
                        </FormSection>

                        <div
                            className={`${style.formSection} ${cx(
                                'marginTop32 width100'
                            )}`}
                        >
                            <FormSection
                                title={'editIssuance.step5.thirdSectionTitle'}
                                description={
                                    'editIssuance.step5.thirdSectionDescription'
                                }
                                titleClassName={'buttonSM'}
                            >
                                <div>
                                    <GaCheckbox
                                        index={'0'}
                                        itemText={t(
                                            'editIssuance.step5.addAppFactorLabel'
                                        )}
                                        id="appAuth"
                                        disabled={
                                            !canEditIssuances &&
                                            !creatingIssuance
                                        }
                                        checked={!!appAuthentication}
                                        onChange={(e) => {
                                            setAppAuthentication(
                                                !appAuthentication
                                            )
                                        }}
                                        position={LabelPosition.right}
                                        className={`${
                                            style.appAuthenticationCheckbox
                                        } ${cx('marginBottom8')}`}
                                        infoIcon
                                        infoText={t(
                                            'editIssuance.step5.addAppFactorTooltip'
                                        )}
                                        tooltipPosition={TooltipPosition.bottom}
                                        tooltipArrowDirection={
                                            TooltipArrowPosition.left
                                        }
                                    />
                                    <GaCheckbox
                                        index={'1'}
                                        itemText={t(
                                            'editIssuance.step5.twoAuthFactorLabel'
                                        )}
                                        id="twoFactor"
                                        disabled={
                                            !canEditIssuances &&
                                            !creatingIssuance
                                        }
                                        checked={!!secondAuthFactors?.has2FA}
                                        onChange={() => {
                                            setSecondAuthFactors({
                                                has2FA: !secondAuthFactors?.has2FA,
                                                factors:
                                                    sortedInitialSecondAuthFactors,
                                            })
                                        }}
                                        position={LabelPosition.right}
                                        infoIcon
                                        infoText={t(
                                            'editIssuance.step5.twoAuthFactorTooltip'
                                        )}
                                        tooltipPosition={TooltipPosition.bottom}
                                        tooltipArrowDirection={
                                            TooltipArrowPosition.left
                                        }
                                        className={style.twoAuthFactorCheckbox}
                                    />
                                    {!!secondAuthFactors?.has2FA ? (
                                        <LightPanel
                                            className={`${
                                                style.panelContainer__lightPanel
                                            } ${cx('marginTop20')}`}
                                        >
                                            <div className={style.panelHeader}>
                                                <div>
                                                    <p
                                                        className={cx(
                                                            'buttonSM'
                                                        )}
                                                    >
                                                        {t(
                                                            'editIssuance.step5.twoAuthFactor'
                                                        )}
                                                    </p>
                                                    <Description
                                                        className={cx(
                                                            'bodyRegularXS neutral800'
                                                        )}
                                                        text={
                                                            'editIssuance.step5.twoAuthFactorDescription'
                                                        }
                                                    />
                                                </div>
                                                {canEditIssuances ||
                                                creatingIssuance ? (
                                                    <DarkButton
                                                        text={
                                                            'editIssuance.step5.addSecondFactor'
                                                        }
                                                        disabled={false}
                                                        functionality={
                                                            showSecurityModal
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                            {secondAuthFactors?.factors?.map(
                                                (el, index) => {
                                                    return (
                                                        <SecondFactorCard
                                                            item={el}
                                                            canEdit={
                                                                canEditIssuances ||
                                                                creatingIssuance
                                                            }
                                                            key={
                                                                'secondFactorCard' +
                                                                index.toString()
                                                            }
                                                            requiredFactor={
                                                                el?.accepted[0]
                                                            }
                                                            backup={el.accepted?.slice(
                                                                1,
                                                                el.accepted
                                                                    ?.length
                                                            )}
                                                            removeFactor={
                                                                removeFactor
                                                            }
                                                            selectFactor={
                                                                editFactor
                                                            }
                                                            openModal={
                                                                showSecurityModal
                                                            }
                                                        />
                                                    )
                                                }
                                            )}
                                        </LightPanel>
                                    ) : null}
                                </div>
                            </FormSection>

                            {showSecModal ? (
                                <SecondFactorModal
                                    show={showSecModal}
                                    selected2AF={
                                        selected2AF && selected2AF?.type
                                            ? selected2AF
                                            : undefined
                                    }
                                    hideModal={hideSecurityModal}
                                    setSecondFactors={setSelectedSecondFactors}
                                />
                            ) : null}
                        </div>
                        {getResponsive(1320) ? (
                            <div className={style.buttonContainer}>
                                <DarkButton
                                    text={
                                        canEditIssuances && !creatingIssuance
                                            ? 'public.save'
                                            : 'editIssuance.step5.sectionButton'
                                    }
                                    disabled={
                                        !selected?.length ||
                                        (!formDataModified && !creatingIssuance)
                                    }
                                    functionality={saveAndNext}
                                />
                            </div>
                        ) : null}
                    </LightPanel>
                </div>
            </div>
            <ConstraintsCredentialPreview
                saveButton={{
                    label:
                        canEditIssuances && !creatingIssuance
                            ? 'public.save'
                            : 'editIssuance.step5.sectionButton',
                    disabled:
                        !selected?.length ||
                        (!formDataModified && !creatingIssuance),
                    function: saveAndNext,
                }}
                data={selected}
                showSaveButton={canEditIssuances || creatingIssuance}
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
            />
        </>
    )
}

export default ConstraintsForm
