import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CredentialIcons, gaImages } from '../../../assets/globalConstants'
import { initialLang } from '../../../i18n'
import { ICredentialRequestedModel } from '../../../interfaces/interfaces'
import { addOrRemove } from '../../../utils/checkboxUtil'
import Subtext from '../../atoms/texts/Subtext/Subtext'
import style from './credentialCard.module.scss'
import CloseIcon from '../../../assets/iconsLibrary/simple/close/closeIcon'
import GaCheckbox from '../../atoms/formFields/checkbox/GaCheckbox'

type ICredentialsCardProps = {
    credential: ICredentialRequestedModel
    index: number
    id?: string
    selectedCredentials: ICredentialRequestedModel[]
    showClaims: boolean
    hasOnlyRemove: boolean
    handleCheckboxChange: (e) => void
}

const CredentialCard: React.FC<ICredentialsCardProps> = (props) => {
    const {
        credential,
        index,
        id,
        selectedCredentials,
        showClaims,
        hasOnlyRemove,
        handleCheckboxChange,
    } = props
    const { t } = useTranslation()

    function handleChange(credential: ICredentialRequestedModel) {
        const selection = addOrRemove(credential, selectedCredentials)
        handleCheckboxChange(selection)
    }

    return (
        <div
            id={id}
            className={`${style.credentialCard} ${
                hasOnlyRemove ? '' : style.pointer
            }`}
            key={credential.id + Math.random()}
            onClick={() => (!hasOnlyRemove ? handleChange(credential) : null)}
        >
            <div className={style.credentialCard__header}>
                <div className={style.credentialCard__header__title}>
                    <img
                        className={style.credentialCard__header__title__icon}
                        src={
                            CredentialIcons[
                                credential?.groupList
                                    ? credential?.groupList[0]
                                    : 'credential'
                            ] || CredentialIcons?.credential
                        }
                        alt={t(gaImages.credential.alt)}
                    />
                    <p
                        className={cx(
                            'buttonSM neutral1000',
                            style.credentialCard__header__title__text
                        )}
                    >
                        {credential.name
                            ? credential.name[initialLang] ||
                              credential.name['en']
                            : ''}
                    </p>
                </div>

                {hasOnlyRemove ? (
                    <button
                        onClick={() => handleChange(credential)}
                        className={style.credentialCard__header__button}
                    >
                        <CloseIcon
                            className={
                                style.credentialCard__header__button__icon
                            }
                            color={'#1E1E20'}
                            size={24}
                        />
                    </button>
                ) : (
                    <GaCheckbox
                        index={'' + index}
                        id={`custom-checkbox-${index}`}
                        name={credential.id}
                        value={index}
                        onChange={() => {}}
                        checked={selectedCredentials?.includes(credential)}
                    />
                )}
            </div>
            {showClaims &&
            credential?.credentials &&
            credential?.credentials?.length > 1 ? (
                <div>
                    <ul className={style.credentialCard__credentials}>
                        {credential?.credentials?.map((i, index) => (
                            <li key={index + Math.random()}>
                                <Subtext text={i.name[initialLang] || ''} />
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </div>
    )
}

export default CredentialCard
