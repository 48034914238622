import React from 'react'
import CredentialPreviewModal from '../../CredentialPreview'
import { t } from 'i18next'
import cx from 'classnames'
import {
    ButtonModel,
    ICredentialRequestedModel,
} from '../../../../../../interfaces/interfaces'
import CredentialCardPreview from '../../../../../organisms/CredentialCard/CredentialCardPreview/CredentialCardPreview'
import styles from '../../credentialPreviewModal.module.scss'
import CardListSkeleton from '../../../../skeletons/cardListSkeleton/CardListSkeleton'
import MediumModal from '../../../Medium/MediumModal'

type IConstraintsCredentialPreviewProps = {
    issuanceTemplate?: boolean
    saveButton?: ButtonModel
    showSaveButton?: boolean
    data?: ICredentialRequestedModel[]
    smallResolution: boolean
    show: boolean
    hideModal: (x?) => void
}

const ConstraintsCredentialPreview: React.FC<
    IConstraintsCredentialPreviewProps
> = (props) => {
    const {
        issuanceTemplate,
        saveButton,
        showSaveButton,
        data,
        smallResolution,
        show,
        hideModal,
    } = props

    return smallResolution ? (
        show ? (
            <MediumModal
                title={t(
                    issuanceTemplate
                        ? 'editIssuance.step5.credPrevTitle'
                        : 'editVerifier.step4.credPrevTitle'
                )}
                subtitle={t(
                    issuanceTemplate
                        ? 'editIssuance.step5.credPrevDescription'
                        : 'editVerifier.step4.credPrevDescription'
                )}
                titleClassName={cx('heading6 neutral1000 marginBottom4')}
                subtitleClassName={cx('bodyRegularSM neutral700')}
                headerClose={true}
                show={show}
                handleClose={hideModal}
                className={styles.previewModalResponsive}
            >
                <CredentialPreviewModal
                    titleHeader=""
                    descriptionHeader=""
                    modalResponsiveView={true}
                    hasBottomAlert={!!data?.length}
                >
                    {data?.length ? (
                        <div className={styles.credConstraints}>
                            {data?.map((el, index) => (
                                <CredentialCardPreview
                                    key={'credpreview__' + index}
                                    credential={el}
                                    isOnConstraints
                                />
                            ))}
                        </div>
                    ) : (
                        <CardListSkeleton
                            key={'credentials__skeleton'}
                            className={styles.credentialsSkeleton}
                            cardsNumber={1}
                        />
                    )}
                </CredentialPreviewModal>
            </MediumModal>
        ) : null
    ) : (
        <CredentialPreviewModal
            titleHeader={t(
                issuanceTemplate
                    ? 'editIssuance.step5.credPrevTitle'
                    : 'editVerifier.step4.credPrevTitle'
            )}
            descriptionHeader={t(
                issuanceTemplate
                    ? 'editIssuance.step5.credPrevDescription'
                    : 'editVerifier.step4.credPrevDescription'
            )}
            saveButton={saveButton}
            showSaveButton={showSaveButton ? showSaveButton : false}
            hasBottomAlert={!!data?.length}
        >
            {data?.length ? (
                <div className={styles.credConstraints}>
                    {data?.map((el, index) => (
                        <CredentialCardPreview
                            key={'credpreview__' + index}
                            credential={el}
                            isOnConstraints
                        />
                    ))}
                </div>
            ) : (
                <CardListSkeleton
                    key={'credentials__skeleton'}
                    className={styles.credentialsSkeleton}
                    cardsNumber={1}
                />
            )}
        </CredentialPreviewModal>
    )
}
export default ConstraintsCredentialPreview
