import cx from 'classnames'
import React, { InputHTMLAttributes } from 'react'
import style from './gaCheckbox.module.scss'
import {
    CheckboxSizes,
    LabelPosition,
    TextSizes,
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../data/globalVar'
import GaHoverTooltip from '../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'

interface GaCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    item?: any
    itemText?: string | NonNullable<React.ReactNode[]>
    itemSubText?: string | NonNullable<React.ReactNode[]>
    index?: string | number
    checked?: boolean
    disabled?: boolean
    checkboxSize?: CheckboxSizes
    position?: LabelPosition
    tooltipPosition?: TooltipPosition
    tooltipArrowDirection?: TooltipArrowPosition
    infoIcon?: boolean
    infoText?: string
    textSize?: TextSizes
    name?: string
    fullElementClickable?: boolean
    onChange: (e?) => void
}

const GaCheckbox: React.FC<GaCheckboxProps> = React.memo((props) => {
    const {
        className,
        item,
        itemText,
        itemSubText,
        index,
        checked,
        disabled,
        checkboxSize,
        position,
        infoIcon,
        infoText,
        textSize,
        name,
        tooltipPosition,
        tooltipArrowDirection,
        fullElementClickable,
        onChange,
    } = props

    const sizeClass = {
        MD: style.medium,
        SM: style.small,
    }

    const sizeFontClass = {
        LG: 'buttonSM',
        MD: 'bodyRegularXS',
    }

    return (
        <div
            className={`${className} ${style.gaCheckbox} ${
                disabled ? style.disabled : ''
            } ${cx(sizeClass[checkboxSize || 'MD'])}`}
            onClick={() => (fullElementClickable ? onChange(item) : undefined)}
        >
            {position === 'right' ? (
                <>
                    <input
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name={name}
                        value={index}
                        disabled={disabled}
                        onChange={() => onChange(item)}
                        checked={checked}
                    />
                    {itemText && (
                        <div className={style.label__container}>
                            <label
                                htmlFor={`custom-checkbox-${index}`}
                                className={`${cx('neutral1000')} ${cx(
                                    sizeFontClass[textSize || 'MD']
                                )}`}
                            >
                                {itemText}
                                {itemSubText ? (
                                    <span
                                        className={`${cx(
                                            'neutral800 bodyRedularXS marginTop3'
                                        )}`}
                                    >
                                        {itemSubText}
                                    </span>
                                ) : null}
                            </label>
                            {infoIcon && infoText && (
                                <>
                                    <GaHoverTooltip
                                        info={infoText}
                                        position={
                                            tooltipPosition && tooltipPosition
                                        }
                                        positionArrowDirection={
                                            tooltipArrowDirection &&
                                            tooltipArrowDirection
                                        }
                                    />
                                </>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <>
                    {itemText && (
                        <div className={style.label__container}>
                            <label
                                htmlFor={`custom-checkbox-${index}`}
                                className={`${cx('neutral1000')} ${cx(
                                    sizeFontClass[textSize || 'MD']
                                )}`}
                            >
                                {itemText}
                                {itemSubText ? (
                                    <span
                                        className={`${cx(
                                            'neutral800 bodyRedularXS marginTop3'
                                        )}`}
                                    >
                                        {itemSubText}
                                    </span>
                                ) : null}
                            </label>
                            {infoIcon && infoText && (
                                <>
                                    <GaHoverTooltip info={infoText} />
                                </>
                            )}
                        </div>
                    )}
                    <input
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name={name}
                        value={index}
                        disabled={disabled}
                        onChange={() =>
                            !fullElementClickable ? onChange(item) : undefined
                        }
                        checked={checked}
                    />
                </>
            )}
        </div>
    )
})

export default GaCheckbox
