import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../views/credentialsList/credentialsList.module.scss'
import BasicCard from '../../../../../Components/organisms/Cards/BasicCard'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import {
    ICredentialsItemModel,
    IIssuanceRequestModel,
} from '../../../../../interfaces/interfaces'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../../utils/permissionsUtil'
import { useDispatch, useSelector } from 'react-redux'
import { sessionSelectors } from '../../../../common/session'
import { issuancesScopes } from '../../../Issuance/issuance.constants'
import { getIssuances } from '../../../Issuance/issuanceActions'
import {
    getTotalPageItems,
    paginate,
} from '../../../../../utils/paginationUtil'
import TableSkeleton from '../../../../../Components/molecules/skeletons/tableSkeleton/TableSkeleton'
import {
    credentialsScopes,
    headersPendingCredentialsTable,
} from '../../views/credentialsList/credentialsList.constants'
import { CredentialsDataModal } from '../../views/dataModal/DataModal'
import { shortValue } from '../../../../../utils/formatDataUtil'
import CredentialsTable from '../../views/credentialsTable/CredentialsTable'
import {
    changeCredentialStatus,
    getCredentials,
    getPendingCredentials,
    rejectCredential,
    selectCredential,
} from '../../credentialsActions'
import { getCatalog } from '../../../../common/catalog/catalogActions'
import { getStatistics } from '../../../../common/Statistics/statisticsActions'
import { getCatalogTypes } from '../../../../common/catalog/catalogSelectors'
import { credentialsSelectors } from '../..'
import { statsSelectors } from '../../../../common/Statistics'
import { onPremise } from '../../../../../data/globalVar'
import IssuingModal from '../../views/issuingModal/IssuingModal'
import { defaultPageSize } from '../../../../../Components/Pagination/paginationConstants'

type ISearchState = {
    searched: string
    searchedIssuedResult: any
    searchedPendingResult: any
}

const credentialSelectedTab = {
    issued: 'issued',
    pending: 'pending',
}

export type PendingCredsProps = {}

const PendingCreds: React.FC<PendingCredsProps> = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const hasReadScope = !!(
        availableScopes &&
        userHasScopes(credentialsScopes?.read, availableScopes)
    )
    const canReadIssuances = !!(
        availableScopes && userHasScopes(issuancesScopes?.read, availableScopes)
    )
    const canEdit = !!(
        availableScopes &&
        userHasScopes(credentialsScopes?.edit, availableScopes)
    )
    const canValidate = !!(
        availableScopes &&
        userHasScopes(credentialsScopes?.validate, availableScopes)
    )
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)

    const isLoading = useSelector(credentialsSelectors.isLoading)
    const isIssuingCredential = useSelector(
        credentialsSelectors.isIssuingCredentialLoading
    )

    const credentials = useSelector(
        credentialsSelectors.getCredentials
    )?.credentials

    let selectedCredential = useSelector(
        credentialsSelectors.getSelectedCredential
    )
    const issuedCredentials = useSelector(
        credentialsSelectors.getIssuedCredentialsList
    )
    const pendingCredentials = useSelector(
        credentialsSelectors.getPendingCredentialsList
    )

    const pendingCredentialsData = useSelector(
        credentialsSelectors.getPendingCredentialsDataList
    )

    const credentialTypes = useSelector(getCatalogTypes)

    const [pageIndex, setPageIndex] = useState(1)

    const [paginatedIssuedCredentials, setPaginatedIssuedCredentials] =
        React.useState<ICredentialsItemModel[]>()
    const [paginatedPendingCredentials, setPaginatedPendingCredentials] =
        React.useState<ICredentialsItemModel[]>()

    const [creds, setCredentials] = React.useState<{
        issued: ICredentialsItemModel[] | undefined
        pending: ICredentialsItemModel[]
    }>()

    const initialPurposeListState = {
        searched: '',
        searchedIssuedResult: paginatedIssuedCredentials || [''],
        searchedPendingResult: paginatedPendingCredentials || [''],
    }
    const [totalItems, setTotalItems] = useState(
        pendingCredentialsData?.total || 0
    )
    const [state, setState] = React.useState<ISearchState>(
        initialPurposeListState
    )

    useEffect(() => {
        // Just in cloud for now
        if (!statisticsIsLoading && getIsActiveSession() && !onPremise) {
            dispatch(getStatistics())
        }
        hasReadScope &&
            (canReadIssuances && dispatch(getIssuances()),
            dispatch(getCatalog()),
            dispatch(getCredentials(1, defaultPageSize)),
            dispatch(getPendingCredentials(1, defaultPageSize)))
    }, [])

    useEffect(() => {}, [selectedCredential])
    useEffect(() => {}, [isIssuingCredential])

    useEffect(() => {
        setCredentials({
            issued: issuedCredentials,
            pending: pendingCredentials,
        })
        setState(initialPurposeListState)
    }, [credentials, pendingCredentials, pageIndex, credentialTypes])

    useEffect(() => {
        if (!!creds?.issued) {
            setPaginatedIssuedCredentials(
                paginate(creds?.issued, defaultPageSize, pageIndex)
            )
        }
        if (!!creds?.pending) {
            setPaginatedPendingCredentials(
                paginate(creds?.pending, defaultPageSize, pageIndex)
            )
        }
    }, [creds])

    useEffect(() => {
        if (!!creds) {
            setState(initialPurposeListState)
        }
    }, [paginatedIssuedCredentials, paginatedPendingCredentials])

    useEffect(() => {
        setTotalItems(pendingCredentialsData?.total || 0)
    }, [pendingCredentialsData])

    const handlePageChange = (page) => {
        dispatch(getPendingCredentials(page, defaultPageSize))
        setPageIndex(page)

        /* { TODO: Uncomment when BE Search is available */
        // setPaginatedIssuedCredentials(
        //     paginate(creds?.issued, defaultPageSize, page)
        // )
        // setPaginatedPendingCredentials(
        //     paginate(creds?.pending, defaultPageSize, page)
        // )

        // setState(initialPurposeListState)
    }

    const rejectSelectedCredential = (cred) => {
        dispatch(rejectCredential(cred?.id || ''))
    }

    const [showDetail, setShowDetailModal] = React.useState(false)
    const showDetailModal = () => setShowDetailModal(true)
    const hideDetailModal = () => setShowDetailModal(false)

    const [showIssueCredential, setShowIssueCredentialModal] =
        React.useState(false)

    const showIssueCredentialModal = (item) => {
        dispatch(selectCredential(item))
        setShowIssueCredentialModal(true)
    }

    const hideIssueCredentialModal = () => setShowIssueCredentialModal(false)

    /* { TODO: Uncomment when BE Search is available */
    // const handleSearchedChange = (items) => {
    //   if(!!items) {
    //     setState({
    //       searched: state.searched,
    //       searchedResult: paginate(items, pageSize, pageIndex)
    //     })
    //     setTotalItems(items.length)
    //   }
    // }

    const selectCredentialHandler = (item) => {
        dispatch(selectCredential(item))
        showDetailModal()
    }

    const handleStatusType = (ir: IIssuanceRequestModel, newStatus) => {
        dispatch(
            changeCredentialStatus(
                ir?.id,
                newStatus.value,
                pageIndex,
                defaultPageSize
            )
        )
    }

    const handleOptions = (item, selection?, y?) => {
        selectedCredential = item
        dispatch(selectCredential(item))
    }

    const paginationData = {
        currentPage: pageIndex,
        totalPages: getTotalPageItems(totalItems || 0, defaultPageSize) || 0,
        onChange: handlePageChange,
        totalItems: totalItems || 0,
        pageSize: defaultPageSize,
        page: pageIndex,
        className: 'marginTop32',
    }

    return (
        <>
            {!isLoading ? (
                hasReadScope ? (
                    !!creds && creds?.pending?.length && !!creds?.pending[0] ? (
                        <div className={styles.tabPendingContent}>
                            <CredentialsTable
                                tableData={pendingCredentials}
                                headerData={headersPendingCredentialsTable}
                                pending={true}
                                cantChangeCredentialsStatus={
                                    !(canEdit && canValidate)
                                }
                                mobileItem={(item) => (
                                    <BasicCard
                                        item={item}
                                        name={item?.data?.group}
                                        description={{
                                            text: shortValue(item.id, 15, 5),
                                        }}
                                        rightDate={item?.createdAt}
                                        tag={
                                            canEdit && canValidate
                                                ? {
                                                      text: 'credentials.issue',
                                                      color: 'green',
                                                  }
                                                : undefined
                                        }
                                        buttonFunction={
                                            showIssueCredentialModal
                                        }
                                    />
                                )}
                                issueCredential={showIssueCredentialModal}
                                rejectCredential={rejectSelectedCredential}
                                paginationData={paginationData}
                                selectItem={selectCredentialHandler}
                                noResults={
                                    !(
                                        creds?.pending ||
                                        creds?.pending?.length > 0
                                    )
                                    /* { TODO: Uncomment when BE Search is available */
                                    // !(
                                    //     state.searchedPendingResult &&
                                    //     state.searchedPendingResult
                                    //         ?.length
                                    // )
                                }
                                handleDropdownChange={handleStatusType}
                                handleOptions={handleOptions}
                            />
                            {showDetail ? (
                                <CredentialsDataModal
                                    show={true}
                                    hideModal={hideDetailModal}
                                />
                            ) : null}
                            {showIssueCredential ? (
                                <IssuingModal
                                    hideModal={hideIssueCredentialModal}
                                    show={true}
                                />
                            ) : null}
                        </div>
                    ) : (
                        <Description
                            text={t('credentials.noPendingCredentials')}
                        />
                    )
                ) : (
                    <PermissionsPanel
                        scopes={credentialsScopes?.read}
                        readScope
                    />
                )
            ) : (
                <TableSkeleton
                    columnsNumber={3}
                    rowsNumber={defaultPageSize}
                    responsiveCards={true}
                />
            )}
        </>
    )
}

export default PendingCreds
