import {
    ISandboxInvitation,
    MetricStatistic,
} from '../../../interfaces/interfaces'
import {
    HomeActionsTypes,
    IDisplayFailedScanAction,
    IDisplayFailedScanNavigationAction,
    IDisplayScanAction,
    IDisplayScanNavigationAction,
    IDisplaySuccessScanAction,
    IDisplaySuccessScanNavigationAction,
    IGetNotificationsAction,
    IGetNotificationsFailedAction,
    IGetNotificationsSuccessAction,
    IGetPendingActionsAction,
    IGetPendingActionsFailedAction,
    IGetPendingActionsSuccessAction,
    IHideScanAction,
    IHideScanNavigationAction,
    ISetGeneralDataLoadedAction,
    IGetAUStatisticsFailed,
    IGetICStatisticsFailed,
    IGetICStatisticsSuccess,
    IGetICStatistics,
    IGetAUStatistics,
    IGetAUStatisticsSuccess,
    ISetICSelectedPeriodYear,
    ISetAUSelectedPeriodYear,
    ISetShowAgeverificationModal,
} from './homeTypes'

export const getNotifications = (): IGetNotificationsAction => {
    return {
        type: HomeActionsTypes.GET_NOTIFICATIONS,
    }
}

export const getNotificationsSuccess = (
    notifications: any[]
): IGetNotificationsSuccessAction => {
    return {
        type: HomeActionsTypes.GET_NOTIFICATIONS_SUCCESS,
        payload: {
            notifications,
        },
    }
}

export const getNotificationsFailed = (): IGetNotificationsFailedAction => {
    return {
        type: HomeActionsTypes.GET_NOTIFICATIONS_FAILED,
    }
}

export const getPendingActions = (): IGetPendingActionsAction => {
    return {
        type: HomeActionsTypes.GET_PENDING_ACTIONS,
    }
}

export const getPendingActionsSuccess = (
    pendingActions: any[]
): IGetPendingActionsSuccessAction => {
    return {
        type: HomeActionsTypes.GET_PENDING_ACTIONS_SUCCESS,
        payload: {
            pendingActions,
        },
    }
}

export const getPendingActionsFailed = (): IGetPendingActionsFailedAction => {
    return {
        type: HomeActionsTypes.GET_PENDING_ACTIONS_FAILED,
    }
}

export const displayScan = (): IDisplayScanAction => {
    return {
        type: HomeActionsTypes.DISPLAY_SCAN,
    }
}

export const displayScanNavigation = (): IDisplayScanNavigationAction => {
    return {
        type: HomeActionsTypes.DISPLAY_SCAN_NAVIGATION,
    }
}

export const hideScan = (): IHideScanAction => {
    return {
        type: HomeActionsTypes.HIDE_SCAN,
    }
}

export const hideScanNavigation = (): IHideScanNavigationAction => {
    return {
        type: HomeActionsTypes.HIDE_SCAN_NAVIGATION,
    }
}

export const displayScanSucess = (
    invitation: ISandboxInvitation
): IDisplaySuccessScanAction => {
    return {
        type: HomeActionsTypes.DISPLAY_SCAN_SUCCESS,
        payload: {
            invitation: invitation,
        },
    }
}

export const displayScanError = (error: string): IDisplayFailedScanAction => {
    return {
        type: HomeActionsTypes.DISPLAY_SCAN_FAILED,
        payload: {
            err: error,
        },
    }
}

export const displayScanNavigationSucess = (
    invitation: ISandboxInvitation
): IDisplaySuccessScanNavigationAction => {
    return {
        type: HomeActionsTypes.DISPLAY_SCAN_NAVIGATION_SUCCESS,
        payload: {
            invitation: invitation,
        },
    }
}

export const displayScanNavigationError = (
    error: string
): IDisplayFailedScanNavigationAction => {
    return {
        type: HomeActionsTypes.DISPLAY_SCAN_NAVIGATION_FAILED,
        payload: {
            err: error,
        },
    }
}

export const setGeneralDataLoaded = (
    dataLoaded: boolean
): ISetGeneralDataLoadedAction => {
    return {
        type: HomeActionsTypes.SET_GENERAL_DATA_LOADED,
        payload: {
            dataLoaded,
        },
    }
}

export const getICStatistics = (
    urlParams: string,
    id?: string
): IGetICStatistics => {
    return {
        type: HomeActionsTypes.GET_IC_STATISTICS,
        payload: { params: urlParams, id: id },
    }
}

export const getICStatisticsSuccess = (
    stats: MetricStatistic,
    id?: string
): IGetICStatisticsSuccess => {
    return {
        type: HomeActionsTypes.GET_IC_STATISTICS_SUCCESS,
        payload: {
            statistics: stats,
            id: id,
        },
    }
}

export const getICStatisticsFailed = (error: any): IGetICStatisticsFailed => {
    return {
        type: HomeActionsTypes.GET_IC_STATISTICS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const getAUStatistics = (
    urlParams: string,
    id?: string
): IGetAUStatistics => {
    return {
        type: HomeActionsTypes.GET_AU_STATISTICS,
        payload: { params: urlParams, id: id },
    }
}

export const getAUStatisticsSuccess = (
    stats: MetricStatistic,
    id?: string
): IGetAUStatisticsSuccess => {
    return {
        type: HomeActionsTypes.GET_AU_STATISTICS_SUCCESS,
        payload: {
            statistics: stats,
            id: id,
        },
    }
}

export const getAUStatisticsFailed = (error: any): IGetAUStatisticsFailed => {
    return {
        type: HomeActionsTypes.GET_AU_STATISTICS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const setICSelectedPeriodIsYear = (
    isYear: boolean
): ISetICSelectedPeriodYear => {
    return {
        type: HomeActionsTypes.SET_IC_SELECTED_PERIOD_YEAR_STATS,
        payload: {
            isYear,
        },
    }
}

export const setAUSelectedPeriodIsYear = (
    isYear: boolean
): ISetAUSelectedPeriodYear => {
    return {
        type: HomeActionsTypes.SET_AU_SELECTED_PERIOD_YEAR_STATS,
        payload: {
            isYear,
        },
    }
}

export const setShowAgeVerificationModal = (
    show: boolean
): ISetShowAgeverificationModal => {
    return {
        type: HomeActionsTypes.SET_SHOW_AGE_VERIFICATION_MODAL,
        payload: { show },
    }
}
