import React, { FunctionComponent, SVGProps } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageModel, OptionModel } from '../../../../interfaces/interfaces'
import style from './switchButton.module.scss'
import cx from 'classnames'
import GaHoverTooltip from '../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import {
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../data/globalVar'
import { gaColors } from '../../../../assets/globalConstants'
import InfoCircleFilledIcon from '../../../../assets/iconsLibrary/simple/info/filled/ga-info-circle-filled'

type SwitchButtonProps = {
    options?: OptionModel[]
    SvgIcon?: FunctionComponent<SVGProps<SVGSVGElement>>
    checkedValue: string
    disabled?: boolean
    type?: 'button' | 'submit' | 'reset' | undefined
    icon?: ImageModel
    className?: string
    onChangeSwitchSelect?: ((x?: any) => void) | any
    nameSwitchOption?: string
}

const SwitchButton: React.FC<SwitchButtonProps> = React.memo((props) => {
    const {
        options,
        SvgIcon,
        type,
        disabled,
        icon,
        className,
        checkedValue,
        onChangeSwitchSelect,
        nameSwitchOption,
    } = props

    const { t } = useTranslation()

    return (
        <div className={`${style.switchButton} ${className && className}`}>
            <div className={style.switchesContainer}>
                {options?.map((option, index) => {
                    return (
                        <input
                            key={'swicthButtonOption__' + option?.value + index}
                            type="radio"
                            id={
                                'switch__' + option.value + '__' + index ||
                                'switchMonthly'
                            }
                            name={nameSwitchOption || 'switchPlan'}
                            value={option.value || 'Monthly'}
                            checked={checkedValue === option.value}
                            onChange={() => {}}
                        />
                    )
                })}
                {options?.map((option, index) => {
                    const Icon = option?.Icon
                    return (
                        <label
                            key={'swicthButtonLabel__' + option?.value + index}
                            className={`${
                                checkedValue === option.value
                                    ? !option?.disabled &&
                                      style.labelSelectedValue
                                    : (style.labelUnselectedValue,
                                      option?.disabled &&
                                          style.labelDisabledValue)
                            } ${cx('buttonMD')}`}
                            onClick={() => {
                                !option?.disabled &&
                                    onChangeSwitchSelect(option.value)
                            }}
                        >
                            {option?.disabled ? (
                                <GaHoverTooltip
                                    position={TooltipPosition.right}
                                    positionArrowDirection={
                                        TooltipArrowPosition.left
                                    }
                                    className={style.disabledTooltip}
                                    label={
                                        <div className={style.disbledOption}>
                                            {Icon ? (
                                                <Icon
                                                    style={'filled'}
                                                    color={gaColors.neutral600}
                                                    size={24}
                                                    className={
                                                        style.upgradeIcon
                                                    }
                                                />
                                            ) : (
                                                <InfoCircleFilledIcon
                                                    size={24}
                                                    colorFill={
                                                        gaColors.tertiary600
                                                    }
                                                    colorStroke={
                                                        gaColors.neutral100
                                                    }
                                                />
                                            )}
                                            <p
                                                className={`${cx(
                                                    'buttonMD neutral600'
                                                )}`}
                                            >
                                                {t(option.text)}
                                            </p>
                                        </div>
                                    }
                                    info={t(
                                        option?.info
                                            ? option.info
                                            : 'public.notNecessaryPermission'
                                    )}
                                    hideIcon
                                />
                            ) : (
                                t(option.text)
                            )}
                        </label>
                    )
                })}
                <div className={style.switchWrapper}>
                    <div className={style.switch}>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default SwitchButton
