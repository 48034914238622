import {
    ConfigCertificateFormActions,
    ConfigCertificateFormActionsTypes,
    CertificatesListActionsTypes,
    CertificatesListActions,
    IConfigCertificateFormState,
    ICertificatesListState,
} from './certificatesTypes'

// LIST
const initialCertificatesListState: ICertificatesListState = {
    certificatesResponse: undefined,
    selectedCertificate: undefined,
    loadingStatus: false,
    error: '',
}

export function certificatesReducer(
    state = initialCertificatesListState,
    action: CertificatesListActions
): ICertificatesListState {
    switch (action.type) {
        case CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES:
            return {
                ...state,
                loadingStatus: true,
            }
        case CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES_SUCCESS:
            return {
                ...state,
                certificatesResponse: action.payload.data,
                loadingStatus: false,
                error: '',
            }
        case CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES_FAILED:
            return {
                ...state,
                certificatesResponse: undefined,
                loadingStatus: false,
                error: action.payload.error,
            }
        case CertificatesListActionsTypes.SELECT_QUALIFIED_SIGNATURE:
            return {
                ...state,
                selectedCertificate: action.payload.certificate,
            }
        case CertificatesListActionsTypes.DELETE_QUALIFIED_SIGNATURE:
            return {
                ...state,
                certificatesResponse: undefined,
                loadingStatus: true,
                error: '',
            }
        case CertificatesListActionsTypes.DELETE_QUALIFIED_SIGNATURE_SUCCESS:
            return {
                ...state,
                certificatesResponse: undefined,
                loadingStatus: false,
                error: '',
            }
        case CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE:
            return {
                ...state,
                loadingStatus: true,
            }
        case CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                error: '',
            }
        case CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE_FAILED:
            return {
                ...state,
                loadingStatus: false,
                error: action.payload.error,
            }
        case CertificatesListActionsTypes.CLEAR_QUALIFIED_SIGNATURE_LIST:
            return initialCertificatesListState as ICertificatesListState
        default:
            return state
    }
}

const initialFormState: IConfigCertificateFormState = {
    steps: [],
    firstStep: null,
    step: null,
    finalStep: null,
}

export function configCertificateFormReducer(
    state = initialFormState,
    action: ConfigCertificateFormActions
): IConfigCertificateFormState {
    switch (action.type) {
        case ConfigCertificateFormActionsTypes.SETSTEP:
            return {
                ...state,
                step: action.payload.step,
            } as IConfigCertificateFormState
        case ConfigCertificateFormActionsTypes.SETSTEPS:
            const lastPosition = action.payload.steps.length - 1
            return {
                ...state,
                steps: action.payload.steps,
                firstStep: action.payload.steps[0],
                step: action.payload.steps[0],
                finalStep: action.payload.steps[lastPosition],
            } as IConfigCertificateFormState
        case ConfigCertificateFormActionsTypes.CLEAR_FORM_STEP:
            return initialFormState as IConfigCertificateFormState
        default:
            return state
    }
}
