import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { appIntegrationsSelectors } from '../../../../../../store/appIntegrations'
import {
    CellContentTypes,
    TextAlignTypes,
} from '../../../../../../assets/globalConstants'
import Description from '../../../../../../Components/atoms/texts/Description/Description'
import {
    IAppIntegrationModel,
    PaginationProps,
} from '../../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../../utils/permissionsUtil'
import { catalogSelectors } from '../../../../../common/catalog'
import { sessionSelectors } from '../../../../../common/session'
import { clearAppIntegrations } from '../../../../../../store/appIntegrations/appIntegrationsActions'
import { appIntegrationsScopes } from '../../../data/AppIntegrationsList.constants'
import styles from './appIntegrationsTable.module.scss'
import LightPanel from '../../../../../../Components/atoms/panels/LightPanel/LightPanel'
import ChevronDownIcon from '../../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import { GaPagination } from '../../../../../../Components/Pagination/Pagination'
import { AppIntegrationURLS } from './content/AppIntegrationsCells'
import CopyCell from '../../../../../../Components/organisms/table/cells/copyCell/CopyCell'
import { centerEllipsisTrim } from '../../../../../../utils/formatDataUtil'
import DropdownActions from '../../../../../../Components/organisms/table/cells/dropdownActions/dropdownActions'
import { initialLang } from '../../../../../../i18n'

export type AppIntegrationsTableProps = {
    tableData: IAppIntegrationModel[]
    headerData: {
        key: string
        text: string
        textAlign?: TextAlignTypes
        type?: CellContentTypes
        tag?: boolean
        short?: boolean
        tagText?: string
        action?: (x?) => void
    }[]
    noResults?: boolean
    paginationData?: PaginationProps
    deleteItem?: (x?) => void
    editItem?: (x?) => void
    regenerateSecret?: (x?) => void
}

const AppIntegrationsTable: React.FC<AppIntegrationsTableProps> = (props) => {
    const {
        tableData,
        headerData,
        paginationData,
        noResults,
        editItem,
        deleteItem,
        regenerateSecret,
    } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const appIntegrations = useSelector(
        appIntegrationsSelectors.getAppIntegrations
    )
    const catalog = useSelector(catalogSelectors.getLoadingStatus)
    const scopes = useSelector(appIntegrationsSelectors.scopeList)

    useEffect(() => {
        dispatch(clearAppIntegrations())
    }, [])
    useEffect(() => {}, [tableData, catalog])
    useEffect(() => {}, [appIntegrations])

    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canEdit = !!(
        availableScopes &&
        userHasScopes(appIntegrationsScopes?.edit, availableScopes)
    )
    const canDelete = !!(
        availableScopes &&
        userHasScopes(appIntegrationsScopes?.delete, availableScopes)
    )

    const showPagination =
        !!paginationData &&
        paginationData?.totalItems &&
        paginationData.pageSize &&
        paginationData?.totalItems > paginationData.pageSize &&
        !noResults

    const tableActions = [
        {
            text: 'public.edit',
            hide: !canEdit,
            action: editItem,
        },
        {
            text: 'appIntegrations.regenerateClientSecret',
            hide: !canEdit,
            action: regenerateSecret,
        },
        {
            text: 'public.delete',
            hide: !canDelete,
            color: 'alert300',
            action: deleteItem,
        },
    ]

    const scopesDisplay = (item) => {
        const filteredScoped = item?.scope
            ?.match(/\b\w+\b/g)
            ?.filter((scope) => scope !== 'openid')

        return filteredScoped
            ?.map((el) => {
                const fullOption = scopes?.find((option) => option.scope === el)

                return fullOption?.description_short &&
                    fullOption?.description_short[initialLang]
                    ? fullOption?.description_short[initialLang]
                    : fullOption?.description_short['en'] || el
            })
            ?.join(', ')
    }

    return (
        <div className={styles.container}>
            <LightPanel className={styles.panelContainer}>
                <div className={cx(styles.tableContainer)}>
                    <table>
                        <thead>
                            <tr>
                                {headerData.map((header, idx) =>
                                    !(
                                        header.key &&
                                        header.key === 'actions' &&
                                        !(canEdit || canDelete)
                                    ) ? (
                                        <th
                                            className={cx(
                                                'testAlignLeft bodyBoldXS neutral700'
                                            )}
                                            key={
                                                header.key + idx + Math.random()
                                            }
                                        >
                                            <div className={cx('width100')}>
                                                {header.text ? (
                                                    <span
                                                        className={cx(
                                                            'testAlignLeft bodyBoldXS neutral700 margin-left-auto'
                                                        )}
                                                    >
                                                        {t(header.text)}
                                                    </span>
                                                ) : null}
                                                {idx !==
                                                headerData?.length - 1 ? (
                                                    <ChevronDownIcon
                                                        color="#707074"
                                                        size={16}
                                                    />
                                                ) : null}
                                            </div>
                                        </th>
                                    ) : null
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {!!tableData
                                ? tableData?.map((item, idx) => {
                                      return (
                                          <>
                                              <tr
                                                  data-id={idx}
                                                  key={idx + Math.random()}
                                                  className={
                                                      idx ===
                                                      tableData?.length - 1
                                                          ? styles.noBorder
                                                          : ''
                                                  }
                                              >
                                                  {!!item ? (
                                                      <>
                                                          <CopyCell
                                                              item={item}
                                                              mainData={
                                                                  item?.client_name
                                                              }
                                                              textToCopyDesc={
                                                                  'appIntegrations.clientId'
                                                              }
                                                              textToCopy={
                                                                  item?.client_id
                                                              }
                                                              textToCopyDisplayValue={centerEllipsisTrim(
                                                                  item?.client_id,
                                                                  20,
                                                                  '...'
                                                              )}
                                                              selectItem={
                                                                  editItem
                                                              }
                                                              mainDataClickable
                                                              className={
                                                                  styles.appIntegrationData
                                                              }
                                                          />
                                                          <td
                                                              className={
                                                                  styles.creationDate
                                                              }
                                                          >
                                                              <Moment
                                                                  className={cx(
                                                                      'buttonSM neutral1000'
                                                                  )}
                                                                  format="MMMM DD, YYYY"
                                                              >
                                                                  {
                                                                      item?.created_at
                                                                  }
                                                              </Moment>
                                                          </td>
                                                          <td
                                                              className={
                                                                  styles.scopes
                                                              }
                                                          >
                                                              <span
                                                                  className={cx(
                                                                      'buttonSM neutral1000'
                                                                  )}
                                                              >
                                                                  {scopesDisplay(
                                                                      item
                                                                  )}
                                                              </span>
                                                          </td>
                                                          <AppIntegrationURLS
                                                              item={item}
                                                          />
                                                          {canEdit ||
                                                          canDelete ? (
                                                              <td>
                                                                  <div
                                                                      className={
                                                                          styles.actions
                                                                      }
                                                                  >
                                                                      <DropdownActions
                                                                          item={
                                                                              item
                                                                          }
                                                                          isLastItem={
                                                                              idx ===
                                                                              tableData?.length -
                                                                                  1
                                                                          }
                                                                          className={
                                                                              styles.action__iconContainer
                                                                          }
                                                                          options={
                                                                              tableActions
                                                                          }
                                                                      />
                                                                  </div>
                                                              </td>
                                                          ) : null}
                                                      </>
                                                  ) : null}
                                              </tr>
                                          </>
                                      )
                                  })
                                : null}
                        </tbody>
                    </table>
                </div>
            </LightPanel>

            {noResults ? (
                <Description
                    className={cx('marginTop32')}
                    text={t('appIntegrations.noResults')}
                />
            ) : null}

            {paginationData && showPagination ? (
                <div>
                    <GaPagination {...paginationData} />
                </div>
            ) : null}
        </div>
    )
}

export default AppIntegrationsTable
