import moment from 'moment'
import { RootState } from '../indexReducers'
import { IEWNotificationModel } from '../../interfaces/interfaces'

// List
export const notificationsList = (state: RootState) =>
    getNotificationToDisplay(state.notificationsReducer.notificationsList)

export const theresNewNotifications = (state: RootState) =>
    getIfTheresNewNotifications(
        state.notificationsReducer.notificationsList,
        state.notificationsReducer.readNotifications
    )

export const notificationsReadedInFront = (state: RootState) =>
    state.notificationsReducer.readNotifications

export const notificationsReadedInFrontNotSeen = (state: RootState) =>
    state.notificationsReducer.readNotificationsNotSeen

export const getNotificationListStatus = (state: RootState) =>
    state.notificationsReducer.getNotificationListStatus

export const getNoticicationStatus = (state: RootState) =>
    state.notificationsReducer.getNoticicationStatus

export const downloadCredStatus = (state: RootState) =>
    state.notificationsReducer.downloadCredStatus

export const deleteNotificationStatus = (state: RootState) =>
    state.notificationsReducer.deleteNotificationStatus

export const getNotificationToDisplay = (
    notificationList?: IEWNotificationModel[]
) =>
    notificationList?.map((notification) => {
        return {
            ...notification,
            expired: getIfNotificationIsExpired(notification?.expirationAt),
            createdFromTime: getCreatedFromTine(notification?.createdAt),
        }
    })

const getIfNotificationIsExpired = (expirationDate?: string) => {
    if (!!expirationDate) {
        const currentDate = new Date().toISOString()
        const expirationDateObj = new Date(expirationDate).toISOString()

        const expired = moment(currentDate).isAfter(expirationDateObj)

        return expired
    } else {
        return false
    }
}

const getCreatedFromTine = (time?: string) => {
    if (time) {
        let ms = new Date().getTime() - new Date(time).getTime()
        let d, h, m, s
        s = Math.floor(ms / 1000)
        m = Math.floor(s / 60)
        s = s % 60
        h = Math.floor(m / 60)
        m = m % 60
        d = Math.floor(h / 24)
        h = h % 24

        if (d > 0) {
            return d + 'd'
        } else {
            if (h > 0) {
                return h + 'h'
            } else {
                return m + 'm'
            }
        }
    }
}

const getIfTheresNewNotifications = (
    notifications?: IEWNotificationModel[],
    readedNotificationsInFront?: IEWNotificationModel[]
) => {
    const unreadNotificationsFromBE = notifications?.filter(
        (notification) => !notification?.readAt
    )
    const readNotificationsFromBE = notifications?.filter(
        (notification) => notification?.readAt
    )

    const unreadNotificationsInFront =
        !!unreadNotificationsFromBE?.length ||
        !!readNotificationsFromBE?.filter(
            (readInBackNot) =>
                !readedNotificationsInFront?.some(
                    (e) => e.notificationId === readInBackNot.notificationId
                )
        )?.length

    return (
        (notifications?.length && unreadNotificationsFromBE?.length) ||
        unreadNotificationsInFront
    )
}
