import { t } from 'i18next'
import React from 'react'
import { gaImages } from '../../../../../assets/globalConstants'
import { ReactComponent as CloseIcon } from '../../../../../assets/images/close.svg'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaImage from '../../../../../Components/atoms/Image/Image'
import styles from './errorClientSecret.module.scss'

type IErrorClientSecretProps = {
    show: boolean
    hideModal: () => void
}

const ErrorClientSecret: React.FC<IErrorClientSecretProps> = (props) => {
    const { hideModal, show } = props

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            }`}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div
                        className={styles.modal__header__close}
                        onClick={() => hideModal()}
                    >
                        <CloseIcon />
                    </div>
                    <div className={styles.modal__header__icon}>
                        <GaImage image={gaImages.warningFlag} />
                    </div>
                    <div className={styles.modal__header__title}>
                        <h3>{t('public.uncontrolledError')}</h3>
                    </div>
                </div>
                <div className={styles.modal__container}>
                    <p className={styles.modal__container__text}>
                        {t('public.generatedIncident')}
                    </p>
                    <LightButton
                        className={styles.modal__container__button}
                        functionality={hideModal}
                        text={'billing.iUndestand'}
                        disabled={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default ErrorClientSecret
