import React, { useState } from 'react'
import styles from './datesFilter.module.scss'
import { gaImages } from '../../../../../assets/globalConstants'
import cx from 'classnames'
import DropdownMenu from '../dropdownMenu/dropdownMenu'
import { IGeneralSelectOption } from '../../../../../interfaces/interfaces'

export type IDatesFilterProps = {
    activePeriodLabel: string
    selectedFilterDate: string
    datesOptions: IGeneralSelectOption[]
    setSelectedFilterDate: (x: any) => void
}

const DatesFilter: React.FC<IDatesFilterProps> = (props) => {
    const { activePeriodLabel, datesOptions } = props

    const [active, setIsActive] = useState(false)

    return (
        <div className={`${styles.datesFilter}`}>
            <>
                <div
                    className={cx(styles.datesFilterContainer)}
                    onClick={() => setIsActive(!active)}
                >
                    {activePeriodLabel ? (
                        <p className={cx('bodyRegularSM neutral1000')}>
                            {activePeriodLabel}
                        </p>
                    ) : null}

                    <DropdownMenu
                        item={''}
                        icon={gaImages.angleDown}
                        iconClassName={styles.action__icon}
                        selectOptions={datesOptions}
                        active={active}
                        setIsActive={setIsActive}
                    />
                </div>
            </>
        </div>
    )
}

export default DatesFilter
