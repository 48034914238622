import cx from 'classnames'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './success.module.scss'
import { gaImages } from '../../../../../../assets/globalConstants'
import DarkButton from '../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import GaTextInput from '../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../../Components/atoms/panels/LightPanel/LightPanel'
import GaImage from '../../../../../../interfaces/Image/Image'
import GaTextInputPassword from '../../../../../../Components/atoms/formFields/textInputPassword/GaTextInputPassword'
import { ButtonSizes } from '../../../../../../data/globalVar'
import { useSelector } from 'react-redux'
import { appIntegrationModalSelectors } from '../../../../../../store/appIntegrationModal'

type ISuccessProps = {
    buttonFunction: () => void
}

const Success: React.FC<ISuccessProps> = (props) => {
    const { buttonFunction } = props
    const { t } = useTranslation()

    // Selectors
    const newClient = useSelector(
        appIntegrationModalSelectors.getAppIntegrationDataSaved
    )

    // Effects
    useEffect(() => {
        scrollToTop()
    }, [])

    // Functions
    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    return (
        <div className={styles.appIntSuccess}>
            <LightPanel className={styles.appIntSuccess__messagePanel}>
                <GaImage
                    className={styles.appIntSuccess__messagePanel__icon}
                    image={gaImages.greenCheckSvg}
                />
                <p className={cx('heading5 neutral1000')}>
                    {t('appIntegrations.success.title')}
                </p>
            </LightPanel>
            <LightPanel className={styles.appIntSuccess__data}>
                <div>
                    <p className={cx('heading5 neutral1000')}>
                        {t('appIntegrations.success.save')}
                    </p>
                    <p className={cx('buttonSM neutral700 marginTop4')}>
                        <Trans
                            defaults={'appIntegrations.success.saveDesc'}
                            components={{
                                bold: <strong />,
                            }}
                        />
                    </p>
                </div>
                <div className={`${styles.bodyContainer}`}>
                    <GaTextInput
                        id={'clientID'}
                        name={'clientID'}
                        labelText={t(
                            'dashboard.vouchModal.integrationCompleteForm.clientIDLabel'
                        )}
                        helperText={t(
                            'dashboard.vouchModal.integrationCompleteForm.clientIDHelperText'
                        )}
                        copyOption
                        copyIconSize={24}
                        value={newClient?.client_id}
                    />
                    <GaTextInputPassword
                        id={'clientSecret'}
                        name={'clientSecret'}
                        className={cx('marginTop12')}
                        labelText={t(
                            'dashboard.vouchModal.integrationCompleteForm.clientSecretLabel'
                        )}
                        helperText={t(
                            'dashboard.vouchModal.integrationCompleteForm.clientSecretHelperText'
                        )}
                        copyOption
                        copyIconSize={24}
                        value={newClient?.client_secret}
                    />
                </div>
                <DarkButton
                    text={
                        'dashboard.vouchModal.integrationCompleteForm.continueButton'
                    }
                    disabled={false}
                    size={ButtonSizes.MD}
                    functionality={buttonFunction}
                />
            </LightPanel>
        </div>
    )
}

export default Success
