import cx from 'classnames'
import React from 'react'
import styles from './regenerateConfirmation.module.scss'
import { t } from 'i18next'
import CloseIcon from '../../../../../../assets/iconsLibrary/simple/close/closeIcon'
import GaImage from '../../../../../../interfaces/Image/Image'
import LightButton from '../../../../../../Components/atoms/buttons/LightButton/LightButton'
import { gaImages } from '../../../../../../assets/globalConstants'
import DarkButton from '../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../../../data/globalVar'

type IRegenerateConfirmationProps = {
    title: string
    description: string
    show: boolean
    className?: string
    confirmFunction: (x?: any) => void
    hideModal: (x?: boolean) => void
}

const RegenerateConfirmation: React.FC<IRegenerateConfirmationProps> = (
    props
) => {
    const { title, description, show, className, confirmFunction, hideModal } =
        props

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            } ${className && className}`}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div
                        className={styles.modal__header__close}
                        onClick={() => hideModal()}
                    >
                        <CloseIcon size={32} />
                    </div>
                </div>
                <div className={`${styles.modal__content}`}>
                    <GaImage image={gaImages.yellowWarningPng} />
                    <p className={cx('heading6 marginTop20')}>{t(title)}</p>
                    <p className={cx('bodyRegularMD neutral800 marginTop8')}>
                        {t(description)}
                    </p>
                </div>
                <div className={styles.modal__footer}>
                    <LightButton
                        className={styles.modal__footer__lightButton}
                        functionality={hideModal}
                        text={'public.noGoBack'}
                        disabled={false}
                        size={ButtonSizes.MD}
                    />
                    <DarkButton
                        className={styles.modal__footer__primaryButton}
                        functionality={confirmFunction}
                        text={'public.yesRegenerate'}
                        disabled={false}
                        size={ButtonSizes.MD}
                    />
                </div>
            </div>
        </div>
    )
}

export default RegenerateConfirmation
