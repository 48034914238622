import React from 'react'
import styles from './credentialPreviewModal.module.scss'
import LightPanel from '../../../atoms/panels/LightPanel/LightPanel'
import FormSection from '../../../organisms/FormSection/FormSection'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import { ButtonModel } from '../../../../interfaces/interfaces'
import InfoPanel from '../../notification/panels/info/infoPanel'
import cx from 'classnames'
import { t } from 'i18next'

type ICredentialPreviewModalProps = {
    titleHeader: string
    descriptionHeader: string
    saveButton?: ButtonModel
    showSaveButton?: boolean
    hasBottomAlert?: boolean
    modalResponsiveView?: boolean
    className?: string
}

const CredentialPreviewModal: React.FC<ICredentialPreviewModalProps> = (
    props
) => {
    const {
        titleHeader,
        descriptionHeader,
        saveButton,
        showSaveButton,
        hasBottomAlert,
        children,
        modalResponsiveView,
        className,
    } = props

    return (
        <div
            className={cx(
                styles.credentialPreviewContainer,
                className && className
            )}
        >
            <div className={styles.credentialPreview}>
                <LightPanel>
                    {!modalResponsiveView ? (
                        <>
                            <div className={styles.credentialPreview__header}>
                                <FormSection
                                    title={titleHeader}
                                    description={descriptionHeader}
                                ></FormSection>
                            </div>
                        </>
                    ) : null}
                    <div
                        className={`${styles.scrollableArea} ${
                            hasBottomAlert ? styles.withInfoAlert : ''
                        }`}
                    >
                        <div className={styles.credentialPreview__body}>
                            {children}
                        </div>
                    </div>

                    {hasBottomAlert ? (
                        <InfoPanel
                            hasIcon
                            classname={cx(
                                styles.credentialPreview__infoAlert,
                                'bodyRegularSM'
                            )}
                            label={t('editIssuance.step5.fakeCredInfo')}
                        />
                    ) : null}
                    {showSaveButton && saveButton && !modalResponsiveView ? (
                        <div className={styles.credentialPreview__footer}>
                            <DarkButton
                                text={saveButton?.label}
                                disabled={!!saveButton?.disabled}
                                functionality={
                                    saveButton?.function && saveButton?.function
                                }
                            />
                        </div>
                    ) : null}
                </LightPanel>
            </div>
        </div>
    )
}

export default CredentialPreviewModal
