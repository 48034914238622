import {
    ICreateDidModel,
    IDIDModel,
    IDidsRespondeModel,
    StepModel,
} from '../../../interfaces/interfaces'
import { walletRoutes } from '../../common/PanelScafolding/private/panelScafoldingPrivateConstants'

import {
    ClearDidFormState,
    ClearDidList,
    ConfigDidActionsTypes,
    ConfigDidFormActionsTypes,
    DidListActions,
    DidListActionsTypes,
    IAddIssuerType,
    IAddIssuerTypeFailed,
    IAddIssuerTypeSuccess,
    IAddVerifierType,
    IAddVerifierTypeFailed,
    IAddVerifierTypeSuccess,
    IClearActionStatus,
    IClearDid,
    ICreateDidAction,
    ICreateFirstDidAction,
    ICreateDidFailedAction,
    ICreateDidSuccessAction,
    IDeleteDid,
    IDeleteDidFailed,
    IDeleteDidSuccess,
    IDeleteIssuerType,
    IDeleteIssuerTypeFailed,
    IDeleteIssuerTypeSuccess,
    IDeleteVerifierType,
    IDeleteVerifierTypeFailed,
    IDeleteVerifierTypeSuccess,
    IEditDid,
    IGetDidsFailed,
    IGetDidsSuccessAction,
    IRequestKeypairAction,
    IRequestKeypairFailedAction,
    IRequestKeypairSuccessAction,
    IRequestSecretAction,
    IRequestSecretFailedAction,
    IRequestSecretSuccessAction,
    ISelectDidAction,
    IUpdateDidAction,
    IUpdateDidFailedAction,
    IUpdateDidSuccessAction,
    SetActiveStepAction,
    SetStepsAction,
    ISetEWDidAction,
    EWDidActionsTypes,
} from './didTypes'

// LIST
export const getDids = (): DidListActions => {
    return {
        type: DidListActionsTypes.GET_DIDS,
    }
}

export const getDidsSuccess = (
    didsSetup: IDidsRespondeModel
): IGetDidsSuccessAction => {
    return {
        type: DidListActionsTypes.GET_DIDS_SUCCESS,
        payload: {
            didsSetup,
        },
    }
}

export const getDidsFailed = (error: string): IGetDidsFailed => {
    return {
        type: DidListActionsTypes.GET_DIDS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearDidList = (): ClearDidList => {
    return {
        type: DidListActionsTypes.CLEAR_DID_LIST,
    }
}

// FORM STEPS
export const setSteps = (steps: StepModel[]): SetStepsAction => {
    return {
        type: ConfigDidFormActionsTypes.SETSTEPS,
        payload: {
            steps: steps,
        },
    }
}

export const setActiveStep = (step: StepModel): SetActiveStepAction => {
    return {
        type: ConfigDidFormActionsTypes.SETSTEP,
        payload: {
            step: step,
        },
    }
}

export const clearFormState = (): ClearDidFormState => {
    return {
        type: ConfigDidFormActionsTypes.CLEAR_FORM_STEP,
    }
}

export const editDid = (did: IDIDModel, token: string): IEditDid => {
    return {
        type: ConfigDidActionsTypes.EDIT_DID,
        payload: {
            did: did,
            token: token,
        },
    }
}

export const clearDid = (): IClearDid => {
    return {
        type: ConfigDidActionsTypes.CLEAR_CURRENT_DID,
    }
}

export const selectDid = (did: IDIDModel): ISelectDidAction => {
    return {
        type: ConfigDidActionsTypes.SELECT_DID,
        payload: {
            did: did,
        },
    }
}

// CONFIG DID
export const getKeypair = (): IRequestKeypairAction => {
    return {
        type: ConfigDidActionsTypes.GET_KEYPAIR,
    }
}

export const getKeypairSuccess = (
    privateKey: string,
    publicKey: string
): IRequestKeypairSuccessAction => {
    return {
        type: ConfigDidActionsTypes.GET_KEYPAIR_SUCCESS,
        payload: {
            privateKey: privateKey,
            publicKey: publicKey,
        },
    }
}

export const getKeypairFailed = (
    error: string
): IRequestKeypairFailedAction => {
    return {
        type: ConfigDidActionsTypes.GET_KEYPAIR_FAILED,
        payload: {
            error: error,
        },
    }
}

export const getSecret = (): IRequestSecretAction => {
    return {
        type: ConfigDidActionsTypes.GET_SECRET,
    }
}

export const getSecretSuccess = (
    secret: string
): IRequestSecretSuccessAction => {
    return {
        type: ConfigDidActionsTypes.GET_SECRET_SUCCESS,
        payload: {
            secret: secret,
        },
    }
}

export const getSecretFailed = (error: string): IRequestSecretFailedAction => {
    return {
        type: ConfigDidActionsTypes.GET_SECRET_FAILED,
        payload: {
            error: error,
        },
    }
}

// UPDATE / CREATE
export const createDid = (didData: ICreateDidModel): ICreateDidAction => {
    return {
        type: ConfigDidActionsTypes.CREATE_DID,
        payload: {
            did: didData,
        },
    }
}

export const createFirstDid = (
    didData: ICreateDidModel
): ICreateFirstDidAction => {
    return {
        type: ConfigDidActionsTypes.CREATE_FIRST_DID,
        payload: {
            did: didData,
        },
    }
}

export const createDidSuccess = (didId?: string): ICreateDidSuccessAction => {
    const pathname = window?.location?.pathname
    const formattedPathName = pathname?.replace('/', '')
    const currentRouteIsFromWallet = !!walletRoutes?.includes(formattedPathName)

    return {
        type: ConfigDidActionsTypes.CREATE_DID_SUCCESS,
        payload: {
            didId: currentRouteIsFromWallet ? didId : undefined,
        },
    }
}

export const createDidFailed = (err: any): ICreateDidFailedAction => {
    return {
        type: ConfigDidActionsTypes.CREATE_DID_FAILED,
        payload: {
            error: err,
        },
    }
}

export const updateDid = (
    newConfig: ICreateDidModel,
    oldDid: string
): IUpdateDidAction => {
    return {
        type: ConfigDidActionsTypes.UPDATE_DID,
        payload: {
            did: newConfig,
            didId: oldDid,
        },
    }
}

export const updateDidSuccess = (): IUpdateDidSuccessAction => {
    return {
        type: ConfigDidActionsTypes.UPDATE_DID_SUCCESS,
    }
}

export const updateDidFailed = (err: any): IUpdateDidFailedAction => {
    return {
        type: ConfigDidActionsTypes.UPDATE_DID_FAILED,
        payload: {
            error: err,
        },
    }
}

export const clearActionStatus = (): IClearActionStatus => {
    return {
        type: ConfigDidActionsTypes.CLEAR_ACTION_STATUS,
    }
}

// Delete DID
export const deleteDid = (id: string): IDeleteDid => {
    return {
        type: DidListActionsTypes.DELETE_DID,
        payload: {
            id: id,
        },
    }
}

export const deleteDidSuccess = (): IDeleteDidSuccess => {
    return {
        type: DidListActionsTypes.DELETE_DID_SUCCESS,
    }
}

export const deleteDidFailed = (error: string): IDeleteDidFailed => {
    return {
        type: DidListActionsTypes.DELETE_DID_FAILED,
        payload: {
            error: error,
        },
    }
}

// Change type
export const deleteIssuerType = (id: string): IDeleteIssuerType => {
    return {
        type: DidListActionsTypes.DELETE_ISSUER_TYPE,
        payload: {
            id: id,
        },
    }
}

export const deleteIssuerTypeSuccess = (): IDeleteIssuerTypeSuccess => {
    return {
        type: DidListActionsTypes.DELETE_ISSUER_TYPE_SUCCESS,
    }
}

export const deleteIssuerTypeFailed = (
    error: string
): IDeleteIssuerTypeFailed => {
    return {
        type: DidListActionsTypes.DELETE_ISSUER_TYPE_FAILED,
        payload: {
            error: error,
        },
    }
}

export const deleteVerifierType = (id: string): IDeleteVerifierType => {
    return {
        type: DidListActionsTypes.DELETE_VERIFIER_TYPE,
        payload: {
            id: id,
        },
    }
}

export const deleteVerifierTypeSuccess = (): IDeleteVerifierTypeSuccess => {
    return {
        type: DidListActionsTypes.DELETE_VERIFIER_TYPE_SUCCESS,
    }
}

export const deleteVerifierTypeFailed = (
    error: string
): IDeleteVerifierTypeFailed => {
    return {
        type: DidListActionsTypes.DELETE_VERIFIER_TYPE_FAILED,
        payload: {
            error: error,
        },
    }
}

export const addIssuerType = (id: string): IAddIssuerType => {
    return {
        type: DidListActionsTypes.ADD_ISSUER_TYPE,
        payload: {
            id: id,
        },
    }
}

export const addIssuerTypeSuccess = (): IAddIssuerTypeSuccess => {
    return {
        type: DidListActionsTypes.ADD_ISSUER_TYPE_SUCCESS,
    }
}

export const addIssuerTypeFailed = (error: string): IAddIssuerTypeFailed => {
    return {
        type: DidListActionsTypes.ADD_ISSUER_TYPE_FAILED,
        payload: {
            error: error,
        },
    }
}

export const addVerifierType = (id: string): IAddVerifierType => {
    return {
        type: DidListActionsTypes.ADD_VERIFIER_TYPE,
        payload: {
            id: id,
        },
    }
}

export const addVerifierTypeSuccess = (): IAddVerifierTypeSuccess => {
    return {
        type: DidListActionsTypes.ADD_VERIFIER_TYPE_SUCCESS,
    }
}

export const addVerifierTypeFailed = (
    error: string
): IAddVerifierTypeFailed => {
    return {
        type: DidListActionsTypes.ADD_VERIFIER_TYPE_FAILED,
        payload: {
            error: error,
        },
    }
}

// EW ACCOUNT DID
export const setEWAccountDidAction = (did?: string): ISetEWDidAction => {
    return {
        type: EWDidActionsTypes.SET_ACCOUNT_DID,
        payload: {
            did,
        },
    }
}
