import cx from 'classnames'
import { t } from 'i18next'
import React from 'react'
import { gaColors } from '../../../../../../assets/globalConstants'
import {
    IConnectionsDataAgreementGroup,
    ICredentialAuthorityModel,
} from '../../../../../../interfaces/interfaces'
import styles from './connectionsCells.module.scss'
import { Trans } from 'react-i18next'
import CircleIcon from '../../../../../../assets/iconsLibrary/simple/circle/circleIcon'

// CELLS COMPONENTS
export type TableContentProps = {
    item: IConnectionsDataAgreementGroup
    index?: number
    cantChangeCredentialsStatus?: boolean
    authorityIssuers?: ICredentialAuthorityModel[]
    selectItem?: (x?) => void
    deleteItem?: (x, y?, z?) => void
    issueCredential?: (x?) => void
    rejectCredential?: (x?) => void
    handleDropdownChange?: (x, y, z?) => void
}

export const DataAgreementsCards: React.FC<TableContentProps> = (props) => {
    const { item } = props
    const dataAgreements = item?.dataAgreements as Array<any>

    return (
        <td>
            <div className={styles.dataAgreementsTd}>
                {dataAgreements?.slice(0, 2)?.map((dataAgr, index) => {
                    return (
                        <div key={'dataAgr__' + index}>
                            <div>
                                <p className={cx('buttonSM')}>
                                    <Trans
                                        i18nKey={
                                            'ew.connections.dataAgreementIndex'
                                        }
                                        values={{ index: index + 1 }}
                                    />
                                </p>
                                <CircleIcon
                                    size={16}
                                    color={gaColors.success300}
                                />
                            </div>
                            <p className={cx('bodyRegularSM neutral700')}>
                                <Trans
                                    i18nKey={'ew.connections.sharedCredsQtty'}
                                    values={{
                                        quantity:
                                            dataAgr?.dag?.personal_data?.length,
                                    }}
                                />
                            </p>
                        </div>
                    )
                })}
                {item?.dataAgreements?.length > 2 ? (
                    <div className={styles.moreDataAgreements}>
                        <p className={cx('buttonSM neutral600')}>
                            + {item?.dataAgreements?.length - 2}
                        </p>
                    </div>
                ) : null}
            </div>
        </td>
    )
}

export const ConnectionDetailLink: React.FC<TableContentProps> = (props) => {
    const { item, selectItem } = props

    return (
        <td>
            <div>
                <p
                    onClick={() => (selectItem ? selectItem(item) : null)}
                    className={cx('primary700 buttonSM', styles.detailLink)}
                >
                    {t('ew.connections.viewDetail')}
                </p>
            </div>
        </td>
    )
}
