import { Epic, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import dataService from '../../../services/dataService'
import { showNotification } from '../../common/Notification/notificationActions'
import {
    deleteCertificateFailed,
    deleteCertificateSuccess,
    getCertificatesFailed,
    getCertificatesSuccess,
    uploadCertificateFailed,
    uploadCertificateSuccess,
} from './certificatesActions'
import { CertificatesListActionsTypes } from './certificatesTypes'

// LIST
export const getCertificatesEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CertificatesListActionsTypes.GET_QUALIFIED_SIGNATURES),
        switchMap((action): any => {
            return from(dataService.getCertificates()).pipe(
                map((response?: any) => {
                    return getCertificatesSuccess(response.response)
                }),
                catchError((error) =>
                    of(
                        getCertificatesFailed(error),
                        showNotification(
                            'certificates.getCertificatesError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const uploadCertificatesEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CertificatesListActionsTypes.UPLOAD_QUALIFIED_SIGNATURE),
        switchMap((action): any => {
            const alias = action?.payload.alias
            const password = action?.payload.password
            const file = action?.payload.file

            return from(
                dataService.uploadCertificate(alias, password, file)
            ).pipe(
                mergeMap((response?: any) => {
                    return of(
                        uploadCertificateSuccess(),
                        showNotification(
                            'certificates.uploadCertificatesSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        uploadCertificateFailed(error),
                        showNotification(
                            'certificates.uploadCertificatesError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deleteCertificatesEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CertificatesListActionsTypes.DELETE_QUALIFIED_SIGNATURE),
        switchMap((action): any => {
            return from(dataService.deleteCertificate(action.payload?.id)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        deleteCertificateSuccess(),
                        showNotification(
                            'certificates.deleteCertificatesSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        deleteCertificateFailed(error),
                        showNotification(
                            'certificates.deleteCertificatesError',
                            'error',
                            error?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
