import { getFormattedJWTCredLists } from '../../features/wallet/requestCredentials/utils/formatCredUtil'
import { RootState } from '../indexReducers'

// List
export const gettingProcessStatus = (state: RootState) =>
    state.scanModalReducer.statusGettingProccess

export const getProccess = (state: RootState) => state.scanModalReducer.process

export const sendingProcessDataStatus = (state: RootState) =>
    state.scanModalReducer.statusSendingData

export const getProccessDataToSend = (state: RootState) =>
    state.scanModalReducer.processData

export const getProccessWithFormattedCreds = (state: RootState) =>
    formatProccess(state.scanModalReducer.process)

const formatProccess = (process) => {
    return {
        processId: process?.process_id,
        available_credentials: getFormattedJWTCredLists(
            process?.available_credentials
        ),
        needs_consent: process?.needs_consent,
    }
}
