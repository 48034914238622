import React from 'react'

type IQrBuilderProps = {
    qrData: string
    qrColor?: string
    size?: number
    logoSrc?: string
    logoSize?: number
}

const styles = {
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: 12,
    },
    inputBox: {
        flexGrow: 1,
    },
}

const defaultProps = {
    qrData: 'https://gataca.page.link/?apn=com.gatacaapp&ibi=com.gataca.wallet&link=https%3A%2F%2Fgataca.page.link%2Fscan%3Fsession%3Dd8d0e169-95f4-4113-b57f-6cc1f8de2a8f%26callback%3DaHR0cHMlM0ElMkYlMkZjb25uZWN0LmRldi5nYXRhY2EuaW8lM0E5MDkw',
}

const QrBuilder: React.FC<IQrBuilderProps> = ({
    qrData,
    size,
    logoSize,
    logoSrc,
    qrColor,
}) => {
    return qrData ? (
        // @ts-ignore
        <gataca-qrdisplay
            size={size}
            qr-data={qrData}
            logo-size={0.2}
            logo-src={logoSrc}
            qr-color={qrColor}
        />
    ) : (
        <></>
    )
}

QrBuilder.defaultProps = defaultProps
export default QrBuilder
