import React, { useEffect, useState, InputHTMLAttributes } from 'react'
import InputSkeleton from '../../../molecules/skeletons/inlineSkeleton/InputSkeleton'
import style from './gaTextInputPassword.module.scss'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { TextSizes } from '../../../../data/globalVar'
import EyeOffIcon from '../../../../assets/iconsLibrary/simple/eyeOff/eyeOffIcon'
import EyeOnIcon from '../../../../assets/iconsLibrary/simple/eyeOn/eyeOn'
import CopyIconInput from '../../icons/copyIconInput/CopyIconInput'

interface GaTextInputPasswordProps
    extends InputHTMLAttributes<HTMLInputElement> {
    id?: string
    labelText?: string
    placeholder?: string
    helperText?: string
    extraDescription?: string
    value?: string
    readOnly?: boolean
    defaultValue?: string
    disabled?: boolean
    errorTexts?: NonNullable<React.ReactNode[]>
    invalid?: boolean
    name?: string
    required?: boolean
    className?: string
    autoComplete?: string
    isLoading?: boolean
    maxLength?: number
    minLength?: number
    ariaLabel?: string
    sucessText?: string
    textSize?: TextSizes
    copyOption?: boolean
    copyIconSize?: number
    onChange?: (e?) => void
    onKeyDown?: (e?) => void
}

const GaTextInputPassword: React.FC<GaTextInputPasswordProps> = React.memo(
    (props) => {
        const {
            id,
            labelText,
            placeholder,
            className,
            helperText,
            extraDescription,
            disabled,
            name,
            value,
            readOnly,
            defaultValue,
            errorTexts,
            invalid,
            required,
            autoComplete,
            isLoading,
            maxLength,
            minLength,
            ariaLabel,
            sucessText,
            textSize,
            copyOption,
            copyIconSize,
            onChange,
            onKeyDown,
        } = props
        const { t } = useTranslation()

        const sizeFontClass = {
            LG: 'bodyRegularMD',
            MD: 'bodyRegularSM',
        }

        const [touched, setTouched] = useState(false)
        const [success, setSuccess] = useState(false)
        const [missing, setMissing] = useState(false)
        const [password, setPassword] = useState('')
        const [type, setTypePass] = useState('password')
        const [icon, setIcon] = useState(
            <EyeOffIcon color="#1E1E20" size={24} />
        )
        const invalidErrorTexts =
            invalid && errorTexts?.length && touched && !readOnly
        const sucessTextActive = success && sucessText

        useEffect(() => {
            invalid && setTouched(false)
        }, [value])

        useEffect(() => {
            success && setSuccess(true)
        }, [value])

        useEffect(() => {
            if (touched && !readOnly) {
                required && setMissing(!value?.trim()?.length)
            }
        }, [touched, readOnly, value])

        const handleToggle = () => {
            if (type === 'password') {
                setIcon(<EyeOnIcon color="#1E1E20" size={24} />)
                setTypePass('text')
            } else {
                setIcon(<EyeOffIcon color="#1E1E20" size={24} />)
                setTypePass('password')
            }
        }

        return (
            <div
                className={`${className} ${style.gaTextInputContainer} ${
                    required ? style.required : ''
                } ${readOnly ? style.readOnly : ''} ${
                    disabled ? style.disabled : ''
                } ${
                    (missing && !readOnly) || (invalid && touched && !readOnly)
                        ? style.error
                        : ''
                } 
                ${sucessTextActive ? style.success : ''} ${
                    copyOption ? style.copyOption : ''
                }`}
            >
                {!isLoading ? (
                    <>
                        {(labelText || extraDescription) && (
                            <div className={style.topTextsContainer}>
                                {labelText ? (
                                    <label
                                        className={`${cx(
                                            'bodyRegularXS neutral700'
                                        )}`}
                                    >
                                        {labelText}
                                    </label>
                                ) : null}
                                {extraDescription ? (
                                    <span
                                        className={`${cx(
                                            'bodyRegularCap neutral600'
                                        )} ${style.extraDescription} ${
                                            !labelText ? style.noLabel : ''
                                        }`}
                                    >
                                        {extraDescription}
                                    </span>
                                ) : null}
                            </div>
                        )}
                        <input
                            className={`${cx(sizeFontClass[textSize || 'LG'])}`}
                            maxLength={maxLength}
                            minLength={minLength}
                            aria-label={
                                labelText
                                    ? labelText
                                    : ariaLabel
                                    ? ariaLabel
                                    : ''
                            }
                            readOnly={readOnly}
                            defaultValue={defaultValue}
                            disabled={disabled || false}
                            id={id ? id : labelText || '' + Math.random()}
                            onKeyDown={(e) => {
                                onKeyDown ? onKeyDown(e) : () => {}
                            }}
                            onBlur={() => setTouched(true)}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                onChange ? onChange(e) : () => {}
                            }}
                            name={name ? name : ''}
                            required={required || false}
                            autoComplete={autoComplete ? autoComplete : 'Off'}
                            placeholder={placeholder ? placeholder : ''}
                            type={type}
                            value={value || ''}
                        ></input>

                        <div className={style.showPasswordButton__container}>
                            <div
                                className={style.showPassword__button}
                                onClick={handleToggle}
                            >
                                {icon}
                            </div>
                        </div>
                        {copyOption ? (
                            <CopyIconInput
                                size={copyIconSize}
                                value={value || ''}
                            />
                        ) : null}
                        {helperText ||
                        invalidErrorTexts ||
                        missing ||
                        sucessTextActive ? (
                            <ul
                                className={`${style.bottomTextsContainer} ${cx(
                                    'marginTop4'
                                )}`}
                            >
                                {helperText ? (
                                    <li
                                        key="helperText"
                                        className={`${cx(
                                            'bodyRegularCap neutral600'
                                        )}`}
                                    >
                                        {helperText}
                                    </li>
                                ) : null}
                                {invalidErrorTexts
                                    ? errorTexts?.map((errorText) => (
                                          <li
                                              key="errorText"
                                              className={`${cx(
                                                  'bodyRegularCap alert300'
                                              )}`}
                                          >
                                              {errorText}
                                          </li>
                                      ))
                                    : null}

                                {missing && !readOnly ? (
                                    <li
                                        key="required"
                                        className={`${cx(
                                            'bodyRegularCap alert300'
                                        )} `}
                                    >
                                        {t('public.required')}
                                    </li>
                                ) : null}
                                {sucessTextActive ? (
                                    <li
                                        key="successText"
                                        className={`${cx(
                                            'bodyRegularCap success300'
                                        )}`}
                                    >
                                        {sucessText}
                                    </li>
                                ) : null}
                            </ul>
                        ) : null}
                    </>
                ) : (
                    <InputSkeleton
                        className={className || ''}
                        labelText={labelText}
                        required={required}
                    />
                )}
            </div>
        )
    }
)

export default GaTextInputPassword
