import React, { useEffect } from 'react'
import { ActionStatusTypes } from '../../../../assets/globalConstants'
import { ReactComponent as CloseLogo } from '../../../../assets/images/close.svg'
import { ButtonModel } from '../../../../interfaces/interfaces'
import FailedContent from './FailedContent/FailedContent'
import LoadingContent from './LoadingContent/LoadingContent'
import style from './statusPanel.module.scss'
import SuccessContent from './SuccessContent/SuccessContent'

type StatusPanelProps = {
    invisibleBg?: boolean
    insideModal?: boolean
    operationStatus?: ActionStatusTypes
    show?: boolean
    className?: string
    loadingText?: string
    failedText?: string
    error?: string
    successMessage: string
    subject?: string
    state: string
    Example?: any
    actionsText?: string
    primaryButton: ButtonModel
    secondaryButton: ButtonModel
    handleClose?: () => void
}

const StatusPanel: React.FC<StatusPanelProps> = React.memo((props) => {
    const {
        show,
        insideModal,
        operationStatus,
        invisibleBg,
        loadingText,
        failedText,
        error,
        successMessage,
        subject,
        className,
        state,
        Example,
        actionsText,
        primaryButton,
        secondaryButton,
        handleClose,
        children,
    } = props

    useEffect(() => {}, [operationStatus, show])

    return show ? (
        <div
            className={`${style.statusPanelBackgroundLayer} ${
                invisibleBg ? style.transparent : ''
            } ${show ? style.visibleModal : style.hiddenModal} ${
                className ? className : ''
            }`}
        >
            <div
                className={`${insideModal ? style.statusPanelModal : ''} ${
                    style.statusPanelContainer
                }`}
            >
                <div className={style.statusPanel}>
                    <div className={style.statusPanel__closeIcon}>
                        {(operationStatus as ActionStatusTypes) ===
                        ActionStatusTypes.failed ? (
                            <CloseLogo onClick={handleClose} />
                        ) : null}
                    </div>
                    <div
                        className={`${style.circleLoader} ${
                            operationStatus === 'success'
                                ? style.loadSuccess
                                : ''
                        } ${
                            operationStatus === 'failed' ? style.loadFailed : ''
                        }`}
                    >
                        <div
                            className={`${style.checkmark} ${style.draw} ${
                                operationStatus === 'success'
                                    ? style.showCheckmark
                                    : style.hiddenCheckmark
                            }`}
                        ></div>
                        <CloseLogo
                            className={`${style.errormark} ${
                                operationStatus === 'failed'
                                    ? style.animateErrorIcon
                                    : ''
                            }`}
                        />
                    </div>

                    <div className={`${style.operationInfo}`}>
                        {(operationStatus as ActionStatusTypes) ===
                        ActionStatusTypes.loading ? (
                            <LoadingContent loadingText={loadingText} />
                        ) : null}

                        {(operationStatus as ActionStatusTypes) ===
                        ActionStatusTypes.success ? (
                            <SuccessContent
                                successMessage={successMessage}
                                subject={subject}
                                state={state}
                                Example={Example}
                                actionsText={actionsText}
                                primaryButton={primaryButton}
                                secondaryButton={secondaryButton}
                            />
                        ) : null}

                        {(operationStatus as ActionStatusTypes) ===
                        ActionStatusTypes.failed ? (
                            <FailedContent
                                failedText={failedText}
                                error={error}
                                primaryButton={primaryButton}
                                secondaryButton={secondaryButton}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    ) : null
})

export default StatusPanel
