import React, { useState } from 'react'
import { t } from 'i18next'
import cx from 'classnames'
import styles from './stepsAppIntegration.module.scss'
import {
    IAppIntegrationModel,
    StepModalModel,
} from '../../../../../../../interfaces/interfaces'
import AgeVerificationModal from '../AgeVerificationModal'
import VouchModalCloseConfirmation from '../Status/CloseConfirmation/CloseConfirmation'
import { gaImages } from '../../../../../../../assets/globalConstants'
import GaTextInput from '../../../../../../../Components/atoms/formFields/textInput/GaTextInput'

type ILinksFormProps = {
    ageVerificationCurrentStep: StepModalModel
    ageVerificationSteps: StepModalModel[]
    isLoading?: boolean
    formData: IAppIntegrationModel
    setAgeVerificationCurrentStep: (x?) => void
    hideModal: () => void
    handleInputChange: (x?) => void
    continueButtonFunction: () => void
}

const LinksForm: React.FC<ILinksFormProps> = (props) => {
    const {
        ageVerificationCurrentStep,
        ageVerificationSteps,
        formData,
        setAgeVerificationCurrentStep,
        hideModal,
        handleInputChange,
        continueButtonFunction,
    } = props

    // States
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)

    // Functions
    const goBackStepFunction = () => {
        setAgeVerificationCurrentStep(
            ageVerificationSteps[ageVerificationCurrentStep?.order - 1]
        )
    }

    return !showCloseConfirmation ? (
        <AgeVerificationModal
            stepTitle={t('dashboard.vouchModal.linksForm.stepTitle')}
            title={t('dashboard.vouchModal.linksForm.title')}
            description={t('dashboard.vouchModal.linksForm.description')}
            hideModal={() => setShowCloseConfirmation(true)}
            continueButton={{
                label: t('dashboard.vouchModal.linksForm.continueButton'),
                function: continueButtonFunction,
                disabled:
                    !formData.tos_uri?.trim() || !formData.policy_uri?.trim(),
            }}
            cancelBackButton={{
                label: t('public.back'),
                function: goBackStepFunction,
            }}
            rightImage={gaImages.securityObjects}
            progressIndicatorOptions={3}
            progressIndicatorCurrentOption={3}
            imageClassName={`${styles.thirdStepImage}`}
        >
            <div className={`${styles.bodyContainer}`}>
                <GaTextInput
                    className={`${cx('marginTop32')}`}
                    id={'policy_uri'}
                    name={'policy_uri'}
                    labelText={t(
                        'dashboard.vouchModal.linksForm.privacyURLLabel'
                    )}
                    helperText={t(
                        'dashboard.vouchModal.linksForm.privacyInputDesc'
                    )}
                    value={formData.policy_uri}
                    onChange={(e) => {
                        handleInputChange(e)
                    }}
                    placeholder="https://url.com"
                    required
                />

                <GaTextInput
                    id={'tos_uri'}
                    name={'tos_uri'}
                    labelText={t(
                        'dashboard.vouchModal.linksForm.termsServiceURLLabel'
                    )}
                    helperText={t(
                        'dashboard.vouchModal.linksForm.termsInputDesc'
                    )}
                    value={formData.tos_uri}
                    onChange={(e) => {
                        handleInputChange(e)
                    }}
                    placeholder="https://url.com"
                    required
                />
            </div>
        </AgeVerificationModal>
    ) : showCloseConfirmation ? (
        <VouchModalCloseConfirmation
            closeModal={() => setShowCloseConfirmation(false)}
            continueFunction={hideModal}
            goBackFunction={() => setShowCloseConfirmation(false)}
        />
    ) : null
}

export default LinksForm
