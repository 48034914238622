import { Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { CredentialListActionsTypes } from './requestCredentialsTypes'
import dataService from '../../services/dataService'
import { from, of } from 'rxjs'
import {
    deleteCredentialFailed,
    deleteCredentialSuccess,
    getCredentials,
    getCredentialsFailed,
    getCredentialsSuccess,
    importCredentialFailed,
    importCredentialSuccess,
} from './requestCredentialsActions'
import { showNotification } from '../../features/common/Notification/notificationActions'
import { hideScreenLoaderAction } from '../../features/common/Loader/loaderActions'

export const getCredentialsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CredentialListActionsTypes.GET_CREDENTIALS),
        switchMap((action): any => {
            const { did, page, size } = action?.payload

            return from(dataService.getEWCredentials(did, page, size)).pipe(
                map((response?: any) => {
                    return getCredentialsSuccess(response.response)
                }),
                catchError((error) =>
                    of(
                        getCredentialsFailed(error),
                        showNotification(
                            'ew.requestCredentials.getCredsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deleteCredentialsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CredentialListActionsTypes.DELETE_CREDENTIAL),
        switchMap((action): any => {
            const { did, credentialId, page, size, url } = action?.payload

            return from(dataService.deleteEWCredential(did, credentialId)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        deleteCredentialSuccess(response.response),
                        hideScreenLoaderAction(),
                        getCredentials(did, page, size),
                        showNotification(
                            'ew.requestCredentials.credDeletedSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        deleteCredentialFailed(error),
                        hideScreenLoaderAction(),
                        showNotification(
                            'ew.requestCredentials.credDeletedError',
                            'error',
                            error.response?.data?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const importCredentialsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(CredentialListActionsTypes.IMPORT_CREDENTIAL),
        switchMap((action): any => {
            const { did, credData, size } = action?.payload
            return from(dataService.importEWCredential(did, credData)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        importCredentialSuccess(credData),
                        getCredentials(did, 1, size),
                        showNotification(
                            'ew.requestCredentials.credImportedSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        importCredentialFailed(error),
                        showNotification(
                            '',
                            'error',
                            'ew.requestCredentials.credImportedError',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
