import { Epic, ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, delay, mergeMap, switchMap } from 'rxjs/operators'
import { IIssuanceConfigItemModel } from '../../../interfaces/interfaces'
import dataService from '../../../services/dataService'
import { setReachedLimitsAction } from '../../common/licenses/licensesActions'
import { showNotification } from '../../common/Notification/notificationActions'
import { setSessionSSIOperations } from '../../common/session/sessionActions'
import {
    createIssuanceFailed,
    createIssuanceSuccess,
    deleteIssuanceFailed,
    deleteIssuanceSuccess,
    getIssuances,
    getIssuancesFailed,
    getIssuancesSuccess,
    updateIssuanceFailed,
    updateIssuanceSuccess,
} from './issuanceActions'
import { IssuanceActionsTypes } from './issuanceTypes'
import { hideScreenLoaderAction } from '../../common/Loader/loaderActions'

export const getIssuancesEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(IssuanceActionsTypes.GET_ISSUANCES),
        delay(100),
        switchMap((action: any): any => {
            return from(dataService.getIssuances()).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(getIssuancesSuccess(response?.response)),
                        of(
                            setSessionSSIOperations(
                                'issuer',
                                response?.response?.map(
                                    (config: IIssuanceConfigItemModel) =>
                                        config.prConfig
                                )
                            )
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        getIssuancesFailed(error),
                        showNotification(
                            'editIssuance.getIssuancesError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const updateIssuanceEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(IssuanceActionsTypes.UPDATE_ISSUANCE),
        switchMap((action: any): any => {
            const issuance = action.payload.issuance
            return from(dataService.updateIssuance(issuance)).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(
                            updateIssuanceSuccess(issuance),
                            hideScreenLoaderAction(),
                            showNotification(
                                'editIssuance.updateIssuanceSuccess',
                                'success',
                                ''
                            )
                        )
                    )
                }),
                catchError((error) => {
                    return of(
                        updateIssuanceFailed(error),
                        hideScreenLoaderAction(),
                        showNotification(
                            'editIssuance.updateIssuanceError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                })
            )
        })
    )
}

export const createIssuanceEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(IssuanceActionsTypes.CREATE_ISSUANCE),
        switchMap((action: any): any => {
            const issuance = action.payload.issuance
            return from(dataService.createIssuance(issuance)).pipe(
                mergeMap(() => {
                    return merge(
                        of(createIssuanceSuccess(), hideScreenLoaderAction())
                    )
                }),
                catchError((error) => {
                    const limitReachedError = error?.status === 429
                    return of(
                        createIssuanceFailed(error),
                        hideScreenLoaderAction(),
                        limitReachedError
                            ? setReachedLimitsAction(
                                  'subscription.limitedFeatures.issuanceTemplates'
                              )
                            : showNotification(
                                  'editIssuance.createIssuanceError',
                                  'error',
                                  error.response?.message,
                                  error?.status
                              )
                    )
                })
            )
        })
    )
}

export const deleteIssuanceEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(IssuanceActionsTypes.DELETE_ISSUANCE),
        switchMap((action: any): any => {
            const issuanceID = action.payload.issuanceID
            return from(dataService.deleteIssuanceConfig(issuanceID)).pipe(
                mergeMap((response?: any) => {
                    return of(getIssuances(), deleteIssuanceSuccess(issuanceID))
                }),
                catchError((error) =>
                    of(
                        deleteIssuanceFailed(error),
                        showNotification(
                            'editVerifier.deleteVerifierError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
