// NAVIGATION

export interface ISetActiveRoute {
    type: ScafoldingActionsTypes.SET_ACTIVE_ROUTE
    payload: {
        route: string
    }
}

export interface ISetSettings {
    type: ScafoldingActionsTypes.SET_SETTINGS_PANEL
    payload: {
        settings: boolean
    }
}

export interface ISetWalletActive {
    type: ScafoldingActionsTypes.SET_WALLET_ACTIVE
    payload: {
        walletActive: boolean
    }
}

export type ScafoldingActions =
    | ISetActiveRoute
    | ISetSettings
    | ISetWalletActive

export enum ScafoldingActionsTypes {
    SET_ACTIVE_ROUTE = 'scafolding/set_active_route',
    SET_SETTINGS_PANEL = 'scafolding/set_settings_panel',
    SET_WALLET_ACTIVE = 'scafolding/set_wallet_active',
}

export interface IScafoldingState {
    activeRoute: string
    isSettingsPanel?: boolean
    isWalletActive?: boolean
}
