/* eslint-disable react/jsx-props-no-spreading */
import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { rolesSelectors } from '../store'
import { gaImages } from '../../../../../../assets/globalConstants'
import Description from '../../../../../../Components/atoms/texts/Description/Description'
import PermissionsPanel from '../../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import CardListSkeleton from '../../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import BasicCard from '../../../../../../Components/organisms/Cards/BasicCard'
import { IRoleModel } from '../../../../../../interfaces/interfaces'
import {
    getTotalPageItems,
    paginate,
} from '../../../../../../utils/paginationUtil'
import { userHasScopes } from '../../../../../../utils/permissionsUtil'
import { sessionSelectors } from '../../../../session'
import { rolesScopes } from '../../users/store/team.constants'
import { getRoles, selectRole } from '../store/rolesActions'
import UpsertRole from '../components/upsertRole/UpsertRole'
import styles from './roles.module.scss'
import MediumModal from '../../../../../../Components/molecules/modals/Medium/MediumModal'
import { GaPagination } from '../../../../../../Components/Pagination/Pagination'
import { defaultPageSize } from '../../../../../../Components/Pagination/paginationConstants'

type ISearchState = {
    searched: string
    searchedResult: any
}

export const Roles: React.FC = (ISearchState) => {
    const history = useHistory()
    const rolesList: IRoleModel[] | null = useSelector(rolesSelectors.getRoles)
    const selectedRole: IRoleModel | undefined = useSelector(
        rolesSelectors.getSelectedRole
    )

    const isLoading = useSelector(rolesSelectors.loadingStatus)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [pageRolesIndex, setPageRolesIndex] = useState(1)
    const [paginatedRoles, setPaginatedRoles] = useState([])
    const [pageRolesSize, setPageSize] = useState(5)

    const [configRole, setConfigRole] = React.useState<boolean>(false)

    const [newRole, setNewRole] = React.useState<boolean>(false)

    const [roles, setRoles] = React.useState<IRoleModel[] | null>()

    const [totalRolesItems, setTotalRolesItems] = useState(roles?.length)

    const initialRolesListState = {
        searched: '',
        searchedResult: paginatedRoles?.slice() || [],
    }

    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canEditRoles = !!(
        availableScopes && userHasScopes(rolesScopes?.edit, availableScopes)
    )
    const canReadRoles = !!(
        availableScopes && userHasScopes(rolesScopes?.read, availableScopes)
    )

    const [rolesState, setRolesState] = React.useState<ISearchState>(
        initialRolesListState
    )

    useEffect(() => {
        canReadRoles && dispatch(getRoles())
    }, [])

    useEffect(() => {
        setRoles(rolesList)
        setRolesState(initialRolesListState)
    }, [rolesList, pageRolesSize, pageRolesIndex])

    useEffect(() => {
        setPaginatedRoles(paginate(roles, pageRolesSize, pageRolesIndex))
    }, [roles?.length])

    useEffect(() => {
        setRolesState(initialRolesListState)
        setTotalRolesItems(roles?.length)
    }, [paginatedRoles])

    const handlePageChange = (page) => {
        setPageRolesIndex(page)
        setPaginatedRoles(paginate(roles, defaultPageSize, page))
        setRolesState(initialRolesListState)
    }

    const selectRoleHandler = (item) => {
        dispatch(selectRole(item))
        setConfigRole(true)
    }

    const hideUserModal = () => {
        setConfigRole(false)
        dispatch(selectRole())
    }

    return (
        <>
            {canReadRoles ? (
                <>
                    {!isLoading ? (
                        roles && roles?.length > 0 ? (
                            <>
                                {rolesState.searchedResult?.map((e, index) => {
                                    return (
                                        <BasicCard
                                            className={styles.tabCard}
                                            item={e}
                                            name={e.role_id}
                                            rightIcon={
                                                canEditRoles
                                                    ? gaImages.pencilIcon
                                                    : undefined
                                            }
                                            buttonFunction={
                                                canEditRoles
                                                    ? selectRoleHandler
                                                    : () => {}
                                            }
                                        />
                                    )
                                })}
                                {roles?.length > defaultPageSize ? (
                                    <GaPagination
                                        className={cx('margin-top')}
                                        currentPage={pageRolesIndex}
                                        totalPages={
                                            getTotalPageItems(
                                                totalRolesItems || 0,
                                                defaultPageSize
                                            ) || 0
                                        }
                                        onChange={handlePageChange}
                                        totalItems={totalRolesItems || 0}
                                        pageSize={defaultPageSize}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <Description text={t('roles.noRolesCreated')} />
                        )
                    ) : (
                        <CardListSkeleton cardsNumber={5} />
                    )}
                </>
            ) : (
                <PermissionsPanel scopes={rolesScopes?.read} readScope />
            )}
            {configRole && (
                <MediumModal
                    title={'roles.role'}
                    subtitle={selectedRole?.role_id}
                    headerClose={true}
                    show={configRole}
                    handleClose={hideUserModal}
                >
                    <UpsertRole hideCreateRoleModal={hideUserModal} />
                </MediumModal>
            )}
        </>
    )
}
