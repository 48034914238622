import { ActionStatusTypes } from '../../../assets/globalConstants'
import { ScafoldingActions } from '../PanelScafolding/scafoldingTypes'
import { SessionAction } from '../session/sessionTypes'

export interface ISignUpEmailAction {
    type: LoginActionTypes.EMAIL_SIGN_UP
    payload: {
        user: ILoginItem
    }
}

export interface ISignUpEmailSuccessAction {
    type: LoginActionTypes.EMAIL_SIGN_UP_SUCCESS
}

export interface ISignUpEmailFailedAction {
    type: LoginActionTypes.EMAIL_SIGN_UP_FAILED
    payload: {
        error: string
    }
}
export interface ISubmitLoginAction {
    type: LoginActionTypes.SUBMIT_LOGIN
    token: string
}

export interface ISubmitEmailLoginAction {
    type: LoginActionTypes.SUBMIT_EMAIL_LOGIN
    payload: {
        user: ILoginItem
    }
}

export interface ISwitchTenantAction {
    type: LoginActionTypes.SWITCH_TENANT
    payload: {
        tenant: string
    }
}

export interface ICheckLoginAction {
    type: LoginActionTypes.CHECK_LOGIN
}

export interface ILoginSuccessAction {
    type: LoginActionTypes.LOGIN_SUCCESS
    payload: {}
}

export interface IQRSignupSuccessAction {
    type: LoginActionTypes.QR_SIGNUP_SUCCESS
    payload: {}
}

export interface ILoginDone {
    type: LoginActionTypes.LOGIN_DONE
}

export interface ILoginFailedAction {
    type: LoginActionTypes.LOGIN_FAILED
}
export interface ILogOutAction {
    type: LoginActionTypes.LOGOUT
}

export interface ISetEmailSigningUp {
    type: LoginActionTypes.SET_EMAIL_SIGNING_UP
}

export type LoginActions =
    | ICheckLoginAction
    | ISignUpEmailAction
    | ISignUpEmailSuccessAction
    | ISignUpEmailFailedAction
    | ISetEmailSigningUp
    | ISubmitLoginAction
    | ISubmitEmailLoginAction
    | ILoginSuccessAction
    | ILoginFailedAction
    | ILoginSuccessAction
    | ILoginFailedAction
    | ILoginDone
    | ILogOutAction
    | ISwitchTenantAction
    | IQRSignupSuccessAction

export interface ILoginState {
    loading: Iloading
    loggedIn: boolean
    signUpStatus: ActionStatusTypes
}

export interface Iloading {
    status: 'inactive' | 'active' | 'finished' | 'error'
    description: string
}

export enum LoginActionTypes {
    CHECK_LOGIN = 'login/check',
    SUBMIT_LOGIN = 'login/submit',
    SUBMIT_EMAIL_LOGIN = 'login/submitEmail',
    SWITCH_TENANT = 'login/switch_tenant',
    EMAIL_SIGN_UP = 'login/signup_email',
    EMAIL_SIGN_UP_SUCCESS = 'login/signup_email_success',
    EMAIL_SIGN_UP_FAILED = 'login/signup_email_failed',
    SET_EMAIL_SIGNING_UP = 'login/set-email-signing_up',
    QR_SIGNUP_SUCCESS = 'login/signup_qr_success',
    LOGIN_SUCCESS = 'login/success',
    LOGIN_FAILED = 'login/failed',
    LOGIN_DONE = 'login/done',
    LOGOUT = 'login/logout',
}

export interface ILoginItem {
    username: string
    password: string
}

export type ReduxActions = LoginActions | ScafoldingActions | SessionAction
