import localStorageService from './localStorageService'
import sessionStorageService from './sessionStorageService'

const TOKEN_KEY = 'token'
const TENANT_KEY = 'tenant'
const LICENSE_KEY = 'sub'
const USER_KEY = 'user'
const REGISTRATION_TOKEN_KEY = 'registration_token'

export default {
    deleteSession() {
        sessionStorageService.deleteSessionStorageData(TOKEN_KEY)
        sessionStorageService.deleteSessionStorageData(USER_KEY)
        sessionStorageService.deleteSessionStorageData(TENANT_KEY)
    },
    deleteRegistrationToken() {
        sessionStorageService.deleteSessionStorageData(REGISTRATION_TOKEN_KEY)
    },
    deleteCurrentTenant() {
        sessionStorageService.deleteSessionStorageData(TENANT_KEY)
    },
    setCurrentTenant(tenant: string) {
        sessionStorageService.setSessionStorageData(TENANT_KEY, tenant)
    },
    setChoosenLicense(license: string) {
        localStorageService.setLocalStorageData(LICENSE_KEY, license)
    },
    getChoosenLicense() {
        return localStorageService.getLocalStorageData(LICENSE_KEY)
    },
    getRegistrationToken() {
        const token = sessionStorageService.getSessionStorageData(
            REGISTRATION_TOKEN_KEY
        )
        return token
    },
    setRegistrationToken(token: string) {
        sessionStorageService.setSessionStorageData(
            REGISTRATION_TOKEN_KEY,
            token
        )
        //networkService.updateInterceptors()
    },
}
