import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import commonEn from './locales/common/en.json'
import commonEs from './locales/common/es.json'
import commonPt from './locales/common/pt.json'
import moment from 'moment'

const resources = {
    es: { common: commonEs },
    en: { common: commonEn },
    pt: { common: commonPt },
}

const options = {
    order: [
        //'querystring',
        //'cookie',
        //'localStorage',
        //'sessionStorage',
        'navigator',
        //'htmlTag',
        //'path',
        //'subdomain',
    ],
    lookupQuerystring: 'lng',
}

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // lng: 'en' // <--- turn off for detection to work
        detection: options,
        resources,
        ns: ['common'],
        defaultNS: 'common',
        fallbackLng: 'en',
        supportedLngs: ['es', 'en', 'pt'],
        interpolation: {
            escapeValue: false,
        },
        debug: false,
    })

// Check the localstorage saved language
const storedLang = localStorage.getItem('i18nextLng')
const initialLang = storedLang?.length === 2 ? storedLang : 'en'
const supportedLngs = ['es', 'en', 'pt']

export const translateDate = (date: string, shorted?: boolean): string => {
    let dateFormatted: string = date

    if (isValidDate(date)) {
        const spanishFormat = moment(new Date(date))
            .locale('es')
            .format(shorted ? 'DD/MM/YYYY' : 'DD MMMM YYYY')
        const othersFormat = moment(new Date(date)).format(
            shorted ? 'MM/DD/YYYY' : 'MMMM DD YYYY'
        )

        dateFormatted = initialLang == 'es' ? spanishFormat : othersFormat
    }

    return dateFormatted
}

const isValidDate = (date: string): boolean => !isNaN(Date.parse(date))

/**
 * Returns the translation of a concrete property. This properties could exist in tranlation files or not.
 * In case the translation is not possible, the value returned is the property name.
 * @param {string} property name (identifier) of the property should be translated.
 * @returns {string} Property translated in the language used at that moment in the wallet.
 */
export const translateProperty = (property: string): string => {
    let propertyFormatted: string = i18n.t(property, { defaultValue: property })
    return propertyFormatted
}

export default i18n
export { initialLang, storedLang, supportedLngs }
