import cx from 'classnames'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DarkButton from '../../../../Components/atoms/buttons/DarkButton/DarkButton'
import GaSelect from '../../../../Components/atoms/formFields/select/GaSelect'
import { ReactComponent as CloseLightIcon } from '../../../../assets/images/close-light.svg'
import { onPremise } from '../../../../data/globalVar'
import { switchTenant } from '../../Login/loginAction'
import { sessionSelectors } from '../../session'
import style from './tenantModal.module.scss'

type ITenantModalProps = {
    show: boolean
    hideModal: () => void
    licenseTenants: string[]
}

const TenantModal: React.FC<ITenantModalProps> = (props) => {
    const { hideModal, show, licenseTenants } = props
    const dispatch = useDispatch()
    const currentTenant = useSelector(sessionSelectors.getCurrentTenant)

    const [selectedActiveTenant, setSelectedActiveTenant] =
        useState(currentTenant)

    const hideAndCleanModal = () => {
        hideModal()
    }

    const switchSelectedTenant = () => {
        selectedActiveTenant &&
            // Just in cloud for now
            !onPremise &&
            dispatch(switchTenant(selectedActiveTenant)),
            hideModal()
    }

    return (
        <div
            className={`${style.tenantModalBackgroundLayer} ${
                show ? style.visibleTenantModal : style.hiddenTenantModal
            }`}
        >
            <div className={style.tenantModal}>
                <div
                    className={style.tenantModal__header}
                    onClick={() => {
                        hideAndCleanModal()
                    }}
                >
                    <p className={style.sandboxPanel__header__title}>
                        {t('navigation.tenantModal.title')}
                    </p>
                    <div className={style.tenantModal__header__close}>
                        <CloseLightIcon />
                    </div>
                </div>
                <p className={style.tenantModal__active}>
                    {t('navigation.tenantModal.activeTenant') + ': '}
                    <span>{currentTenant}</span>
                </p>
                <GaSelect
                    idSelect={'swicthTenant'}
                    className={cx('margin-top margin-bottom')}
                    value={selectedActiveTenant || ''}
                    labelText={t('navigation.tenantModal.swicthTenant')}
                    onChange={(e) => {
                        setSelectedActiveTenant(e?.target?.value)
                    }}
                    options={licenseTenants}
                />
                <DarkButton
                    className={style.tenantModal__button}
                    text={'public.save'}
                    disabled={!selectedActiveTenant}
                    functionality={() => {
                        switchSelectedTenant()
                    }}
                />
            </div>
        </div>
    )
}

export default TenantModal
