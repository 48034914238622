import cx from 'classnames'
import React from 'react'
import styles from './confirmation.module.scss'
import { t } from 'i18next'
import CloseIcon from '../../../../assets/iconsLibrary/simple/close/closeIcon'
import GaImage from '../../../atoms/Image/Image'
import LightButton from '../../../atoms/buttons/LightButton/LightButton'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../data/globalVar'
import { gaImages } from '../../../../assets/globalConstants'
import { ImageModel } from '../../../../interfaces/interfaces'

type IConfirmationModalProps = {
    title: string
    description: string
    show: boolean
    className?: string
    image?: ImageModel
    continueText?: string
    warning?: boolean
    noCancel?: boolean
    confirmFunction: (x?: any) => void
    hideModal: (x?: boolean) => void
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = (props) => {
    const {
        title,
        description,
        show,
        className,
        image,
        continueText,
        warning,
        noCancel,
        confirmFunction,
        hideModal,
    } = props

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            } ${className && className}`}
        >
            <div className={cx(styles.modal, warning && styles.shortHeight)}>
                <div className={styles.modal__header}>
                    <div
                        className={styles.modal__header__close}
                        onClick={() => hideModal()}
                    >
                        <CloseIcon size={32} />
                    </div>
                </div>
                <div className={`${styles.modal__content}`}>
                    <GaImage image={image ? image : gaImages.warning1} />
                    <p className={cx('heading6 marginTop20')}>{t(title)}</p>
                    <p className={cx('bodyRegularMD neutral800 marginTop8')}>
                        {t(description)}
                    </p>
                </div>
                <div className={styles.modal__footer}>
                    {!noCancel ? (
                        <LightButton
                            className={styles.modal__footer__lightButton}
                            functionality={hideModal}
                            text={'public.noGoBack'}
                            disabled={false}
                            size={ButtonSizes.MD}
                        />
                    ) : null}
                    <DarkButton
                        className={cx(
                            !warning && styles.modal__footer__primaryButton
                        )}
                        functionality={confirmFunction}
                        text={continueText ? continueText : 'public.yesDelete'}
                        disabled={false}
                        size={ButtonSizes.MD}
                    />
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal
