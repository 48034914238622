import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { issuanceSelectors } from '../..'
import AddToApiKeyModal from '../../../../../Components/molecules/modals/AddToApiKeyModal/AddToApiKeyModal'
import InvalidFormModal from '../../../../../Components/molecules/table/dynamicTable/invalidFormModal/InvalidFormModal'
import {
    IApiKeyModel,
    IIssuanceConfigItemModel,
    LocationStateModel,
} from '../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { apiKeysSelectors } from '../../../ApiKeys'
import { getApiKeys, updateApiKey } from '../../../ApiKeys/apiKeysActions'
import { apiKeysScopes } from '../../../ApiKeys/views/apiKeysList/apiKeysList.constants'
import { addIssuerToGroup } from '../../../../common/catalog/catalogActions'
import { addIssuerType } from '../../../Dids/didActions'
import { sandboxScopes } from '../../../Home/HomeComponents/sandboxModal/sandboxModalConstants'
import { setSelectedRoute } from '../../../../common/PanelScafolding/scafoldingActions'
import { sessionSelectors } from '../../../../common/session'
import { formSteps, issuancesScopes } from '../../issuance.constants'
import {
    clearFormState,
    editIssuance,
    setActiveStep,
    setSteps,
    updateIssuance,
} from '../../issuanceActions'
import TestModal from '../testModal/TestModal'
import styles from './configIssuance.module.scss'
import { getCertificates } from '../../../certificates/certificatesActions'
import { didsScopes } from '../../../Dids/views/didList/didList.constants'
import { onPremise } from '../../../../../data/globalVar'
import dataService from '../../../../../services/dataService'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'

type IConfigIssuanceProps = {}

const ConfigIssuance: React.FC<IConfigIssuanceProps> = ({}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const activeStep = useSelector(issuanceSelectors.getActiveStep)
    const issuance = useSelector(issuanceSelectors.getSelectedIssuance)
    const location = useLocation<LocationStateModel>()
    const creatingIssuance = location && location?.state
    const [validForm, setValidForm] = React.useState(false)
    const apiKeysListUnfiltered = useSelector(apiKeysSelectors.getApiKeys)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const apiKeysList = apiKeysListUnfiltered?.filter((ak: IApiKeyModel) =>
        ak.ssi_operations?.includes(issuance?.id)
    )
    const apiKeysLoading = useSelector(apiKeysSelectors.loadingStatus)

    // Change Api Keys Dids
    let apiKeysWithChangedDidQtty = 0
    const apiKeysOnlyWithThisSSIConfig = apiKeysList?.filter(
        (ak: IApiKeyModel) => ak.ssi_operations?.length === 1
    )

    // Change Api Keys SSI Configs
    let apiKeysWithSSIConfigRemovedQtty = 0
    const apiKeysWithMoreSSIConfigs = apiKeysList?.filter(
        (ak: IApiKeyModel) => ak.ssi_operations?.length !== 1
    )

    const changeApiKeyDid = async (
        apiKey: any,
        apiKeyId: string,
        did: string
    ) => {
        let updatingApiKeys = false
        const newApyKey = apiKey
        newApyKey.did = did
        const simplifiedData = newApyKey as IApiKeyModel

        if (!updatingApiKeys) {
            updatingApiKeys = true

            while (
                apiKeysWithChangedDidQtty < apiKeysOnlyWithThisSSIConfig?.length
            ) {
                apiKeysWithChangedDidQtty = apiKeysWithChangedDidQtty + 1
                const callDone =
                    await dataService.updateApiKeyAccordingSSIConfig(
                        simplifiedData,
                        apiKeyId
                    )

                if (callDone !== undefined) {
                    updatingApiKeys = false
                }
            }
        }
    }

    const removeConfigFromApiKey = async (
        apiKey: any,
        apiKeyId: string,
        configID: string
    ) => {
        let updating = false
        const newApyKey = apiKey
        const newSSIConfigs = newApyKey.ssi_operations?.filter(
            (e) => e !== configID
        )
        newApyKey.ssi_operations = newSSIConfigs
        const simplifiedData = newApyKey as IApiKeyModel
        if (!updating) {
            updating = true
            while (
                apiKeysWithSSIConfigRemovedQtty <
                apiKeysWithMoreSSIConfigs?.length
            ) {
                apiKeysWithSSIConfigRemovedQtty =
                    apiKeysWithSSIConfigRemovedQtty + 1
                const callDone =
                    await dataService.updateApiKeyAccordingSSIConfig(
                        simplifiedData,
                        apiKeyId
                    )
                if (callDone !== undefined) {
                    updating = false
                }
            }
        }
    }

    const updateApiKeys = (did: string, config: string) => {
        if (
            apiKeysOnlyWithThisSSIConfig?.length ||
            apiKeysWithMoreSSIConfigs?.length
        ) {
            apiKeysOnlyWithThisSSIConfig?.map((apiKey: IApiKeyModel) => {
                changeApiKeyDid(apiKey, apiKey?.id, did)
            })
            apiKeysWithMoreSSIConfigs?.map((apiKey: IApiKeyModel) => {
                removeConfigFromApiKey(apiKey, apiKey?.id, config)
            })
        }
    }

    const canReadApikeys = !!(
        availableScopes && userHasScopes(apiKeysScopes?.read, availableScopes)
    )
    const canEditApikeys = !!(
        availableScopes && userHasScopes(apiKeysScopes?.edit, availableScopes)
    )
    const canCreateApiKey = !!(
        availableScopes && userHasScopes(apiKeysScopes?.create, availableScopes)
    )
    const canUseSandbox = !!(
        availableScopes && userHasScopes(sandboxScopes, availableScopes)
    )
    const canEditIssuances = !!(
        availableScopes && userHasScopes(issuancesScopes?.edit, availableScopes)
    )
    const canReadDids = !!(
        availableScopes && userHasScopes(didsScopes?.read, availableScopes)
    )

    useEffect(() => {
        dispatch(setSteps(formSteps))
    }, [formSteps])

    useEffect(() => {
        canReadApikeys && !apiKeysLoading && dispatch(getApiKeys())
        canReadDids && dispatch(getCertificates())
    }, [])

    const formatIssuance = (selectedIssuance) => {
        let formattedIssuance = selectedIssuance
        delete formattedIssuance._searchableText

        const issuanceCredentials =
            selectedIssuance?.prConfig?.credentials?.map((e) => {
                return {
                    type: e.type || e.id,
                    purpose: e.purpose,
                    mandatory: e.mandatory,
                    trustLevel: e.trustLevel,
                }
            })

        if (formattedIssuance?.prConfig?.credentials) {
            formattedIssuance!.prConfig!.credentials = issuanceCredentials
        }

        return formattedIssuance
    }

    const forwardFunction = (data: IIssuanceConfigItemModel) => {
        const selectedIssuance = { ...issuance, ...data }
        const formattedIssuance = formatIssuance(selectedIssuance)

        updateDidType(selectedIssuance)
        dispatch(editIssuance(selectedIssuance))
        dispatch(showScreenLoaderAction())

        return (
            dispatch(
                updateIssuance(formattedIssuance as IIssuanceConfigItemModel)
            ),
            clearFormState()
        )
    }

    const updateDidType = (newIssuanceConfig) => {
        const currentVMethod = issuance?.prConfig?.vmethodId
        const filledVMethod = newIssuanceConfig?.prConfig?.vmethodId
        if (activeStep?.order === 0 && currentVMethod !== filledVMethod) {
            let issuerDid = filledVMethod?.split('#')[0]
            // Just in cloud for now and will be done through Cloud
            !onPremise && dispatch(addIssuerType(issuerDid))
            updateApiKeys(issuerDid, newIssuanceConfig?.id)
        }
        if (
            activeStep?.order === 1 &&
            issuance?.credentialGroup !== newIssuanceConfig?.credentialGroup
        ) {
            let issuerDid = currentVMethod?.split('#')[0]

            if (issuerDid) {
                /* Just in cloud for now and will be done through it.
                In the future maybe it's not necessary or will there is a request to connect API for this. */
                !onPremise &&
                    dispatch(
                        addIssuerToGroup(
                            issuerDid,
                            newIssuanceConfig?.credentialGroup
                        )
                    )
            }
        }
    }

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    const [showInvalidFormModal, setShowInvalidDataModal] =
        React.useState(false)
    const showInvalidDataModal = () => setShowInvalidDataModal(true)
    const hideInvalidDataModal = () => setShowInvalidDataModal(false)

    const [showAddApiKeysModal, setShowAddApiKeyModal] = React.useState(false)
    const showAddApiKeyModal = () => setShowAddApiKeyModal(true)
    const hideAddApiKeyModal = () => setShowAddApiKeyModal(false)

    const onHandleChange = (step) => dispatch(setActiveStep(formSteps[step]))

    async function addConfigurationToApiKeys(apiKeys: IApiKeyModel[]) {
        for (let apiKey of apiKeys) {
            if (issuance && issuance?.id) {
                apiKey?.ssi_operations?.push(issuance?.id)
                await dispatch(updateApiKey(apiKey, apiKey?.id))
            }
        }
        hideAddApiKeyModal()
        canUseSandbox && setShowModal(true)
    }
    const navigateToCreateApiKey = () => {
        dispatch(setSelectedRoute('create-apikey'))
        history.push('create-apikey')
    }

    const getHeaderButton = () => {
        const canAddToApiKeys = canEditApikeys && canReadApikeys
        const thereAreApiKeysCreated = apiKeysListUnfiltered?.length > 0
        const ssiIsAsignedToApiKey = apiKeysList?.length > 0
        let button

        // Button Options
        const buttonToAddApiKey = {
            label: 'editVerifier.addApiKey',
            function: showAddApiKeyModal,
        }

        const buttonToCreateApiKey = canCreateApiKey
            ? {
                  label: 'editVerifier.addApiKey',
                  function: navigateToCreateApiKey,
              }
            : undefined

        const buttonToTestSSI = canUseSandbox
            ? {
                  label: 'editVerifier.tryIt',
                  function: showModal,
              }
            : undefined

        const buttonForAddToOrCreateApiKey = thereAreApiKeysCreated
            ? buttonToAddApiKey
            : buttonToCreateApiKey
        // get the current button option
        if (canAddToApiKeys) {
            button = !ssiIsAsignedToApiKey
                ? buttonForAddToOrCreateApiKey
                : buttonToTestSSI
        } else {
            button = undefined
        }

        return button
    }

    const headerButton = getHeaderButton()

    return (
        <>
            <div className={styles.editIssuance}>
                {activeStep?.component ? (
                    <activeStep.component
                        buttonFunction={forwardFunction}
                        issuance={issuance}
                        canEditIssuances={canEditIssuances}
                        setValidForm={setValidForm}
                        buttonFunctionHeader={
                            !creatingIssuance &&
                            headerButton &&
                            headerButton?.function
                                ? headerButton?.function
                                : undefined
                        }
                        buttonHeader={
                            !creatingIssuance ? headerButton : undefined
                        }
                        infoApiKeyPanel={
                            !creatingIssuance &&
                            !apiKeysList?.length &&
                            !apiKeysLoading
                        }
                        activeStep={activeStep || formSteps[0]}
                        formSteps={formSteps}
                        onHandleChange={
                            validForm ? onHandleChange : showInvalidDataModal
                        }
                    />
                ) : null}
            </div>

            {!!show ? <TestModal show={show} hideModal={hideModal} /> : null}
            {!!showInvalidFormModal ? (
                <InvalidFormModal hideModal={hideInvalidDataModal} />
            ) : null}
            {!!showAddApiKeysModal ? (
                <AddToApiKeyModal
                    configurationDID={issuance?.prConfig?.vmethodId}
                    configurationId={issuance?.id}
                    hideModal={hideAddApiKeyModal}
                    show={true}
                    updateApiKeys={addConfigurationToApiKeys}
                />
            ) : null}
        </>
    )
}

export default ConfigIssuance
