import { ActionStatusTypes } from '../../../../../../assets/globalConstants'
import { IRoleModel } from '../../../../../../interfaces/interfaces'

export interface IGetScopesAction {
    type: RolesActionTypes.GET_SCOPES
}

export interface IGetScopesSuccessAction {
    type: RolesActionTypes.GET_SCOPES_SUCCESS
    payload: {
        scopes: string[]
    }
}

export interface IGetScopesFailedAction {
    type: RolesActionTypes.GET_SCOPES_FAILED
    payload: { error: string }
}

export interface IGetRolesAction {
    type: RolesActionTypes.GET_ROLES
}

export interface IGetRolesSuccessAction {
    type: RolesActionTypes.GET_ROLES_SUCCESS
    payload: {
        roles: IRoleModel[]
        tenants?: string
    }
}

export interface IGetRolesFailedAction {
    type: RolesActionTypes.GET_ROLES_FAILED
    payload: { error: string }
}

export interface IGetRoleAction {
    type: RolesActionTypes.GET_ROLE
    payload: {
        roleId: string
    }
}

export interface IGetRoleSuccessAction {
    type: RolesActionTypes.GET_ROLE_SUCCESS
    payload: {
        role: IRoleModel
    }
}

export interface IGetRoleFailedAction {
    type: RolesActionTypes.GET_ROLE_FAILED
    payload: { error: string }
}

export interface ICreateRoleAction {
    type: RolesActionTypes.CREATE_ROLE
    payload: {
        role: IRoleModel
    }
}

export interface ICreateRoleSuccessAction {
    type: RolesActionTypes.CREATE_ROLE_SUCCESS
}

export interface ICreateRoleFailedAction {
    type: RolesActionTypes.CREATE_ROLE_FAILED
    payload: {
        error: string
    }
}

export interface IGetRegistrationTokenAction {
    type: RolesActionTypes.GET_REGISTRATION_TOKEN
    payload: {
        role: IRoleModel
        tenant?: string
    }
}

export interface IGetRegistrationTokenSuccessAction {
    type: RolesActionTypes.GET_REGISTRATION_TOKEN_SUCCESS
    payload: {
        message: string
    }
}

export interface IDeleteRoleAction {
    type: RolesActionTypes.DELETE_ROLE
    payload: {
        roleID: string
    }
}

export interface IDeleteRoleSuccessAction {
    type: RolesActionTypes.DELETE_ROLE_SUCCESS
}

export interface IDeleteRoleFailedAction {
    type: RolesActionTypes.DELETE_ROLE_FAILED
}

export interface ISelectRoleAction {
    type: RolesActionTypes.SELECT_ROLE
    payload: {
        role?: IRoleModel
    }
}

export interface IUpdateRoleAction {
    type: RolesActionTypes.UPDATE_ROLE
    payload: {
        role: IRoleModel
    }
}

export interface IUpdateRoleSuccessAction {
    type: RolesActionTypes.UPDATE_ROLE_SUCCESS
}

export interface IUpdateRoleFailedAction {
    type: RolesActionTypes.UPDATE_ROLE_FAILED
    payload: {
        error?: string
    }
}

export interface IClearActionStatus {
    type: RolesActionTypes.CLEAR_ACTION_STATUS
}

export interface ClearRoleList {
    type: RolesActionTypes.CLEAR_ROLE_LIST
}

export interface IClearRole {
    type: RolesActionTypes.CLEAR_CURRENT_ROLE
}

export type RolesAction =
    | IGetRoleAction
    | IGetRoleSuccessAction
    | IGetRoleFailedAction
    | IGetRolesAction
    | IGetRolesSuccessAction
    | IGetRolesFailedAction
    | IGetScopesAction
    | IGetScopesSuccessAction
    | IGetScopesFailedAction
    | ICreateRoleAction
    | ICreateRoleSuccessAction
    | ICreateRoleFailedAction
    | IGetRegistrationTokenAction
    | IDeleteRoleAction
    | IDeleteRoleSuccessAction
    | IDeleteRoleFailedAction
    | ISelectRoleAction
    | IUpdateRoleAction
    | IUpdateRoleSuccessAction
    | IUpdateRoleFailedAction
    | IClearActionStatus
    | ClearRoleList
    | IClearRole

export interface IRolesState {
    roles: IRoleModel[]
    selectedRole?: IRoleModel
    rolesLoadingStatus: boolean
    scopesLoadingStatus: boolean
    scopes?: string[]
    status: ActionStatusTypes
    error: string
}

export enum RolesActionTypes {
    GET_SCOPES = 'roles/get_scopes',
    GET_SCOPES_SUCCESS = 'roles/get_scopes_success',
    GET_SCOPES_FAILED = 'roles/get_scopes_failed',
    GET_ROLE = 'roles/get_specific_role',
    GET_ROLE_SUCCESS = 'roles/get_specific_role_success',
    GET_ROLE_FAILED = 'roles/get_specific_role_failed',
    GET_ROLES = 'roles/get_roles',
    GET_ROLES_SUCCESS = 'roles/get_roles_success',
    GET_ROLES_FAILED = 'roles/get_roles_failed',
    CREATE_ROLE = 'roles/create',
    CREATE_ROLE_SUCCESS = 'roles/create_role_success',
    CREATE_ROLE_FAILED = 'roles/create_role_failed',
    GET_REGISTRATION_TOKEN = 'roles/get_registration_token',
    GET_REGISTRATION_TOKEN_SUCCESS = 'roles/get_registration_token_success',
    DELETE_ROLE = 'roles/delete',
    DELETE_ROLE_SUCCESS = 'roles/delete_success',
    DELETE_ROLE_FAILED = 'roles/delete_failed',
    SELECT_ROLE = 'roles/select',
    CLOSE_role_DETAIL = 'roles/close_role_detail',
    UPDATE_ROLE = 'roles/update',
    UPDATE_ROLE_SUCCESS = 'roles/update_role_success',
    UPDATE_ROLE_FAILED = 'roles/update_role_failed',
    CLEAR_ACTION_STATUS = 'roles/clear_action_status',
    CLEAR_ROLE_LIST = 'roles/clear_roles_list',
    CLEAR_CURRENT_ROLE = 'roles/clear_current_role',
}

export interface ClearRoleList {
    type: RolesActionTypes.CLEAR_ROLE_LIST
}

export interface IClearRole {
    type: RolesActionTypes.CLEAR_CURRENT_ROLE
}
