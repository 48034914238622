import { ActionStatusTypes } from '../../../../../../assets/globalConstants'
import { IUsersState, UsersAction, UsersActionTypes } from './usersTypes'

const initialUsersState: IUsersState = {
    users: [],
    selectedUser: undefined,
    tenant: '',
    changePassword: {
        loading: {
            status: 'inactive',
            description: ' ',
        },
    },
    showUserDetail: false,
    loadingStatus: false,
    status: ActionStatusTypes.neutral,
    error: '',
    profile: undefined,
}

export function usersReducer(
    state = initialUsersState,
    action: UsersAction
): IUsersState {
    switch (action.type) {
        case UsersActionTypes.GET_USERS:
            return {
                ...state,
                loadingStatus: true,
                selectedUser: undefined,
                showUserDetail: false,
            }
        case UsersActionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
                tenant: action.payload.tenants,
                loadingStatus: false,
            }
        case UsersActionTypes.GET_USERS_FAILED:
            return {
                ...state,
                users: action.payload,
                loadingStatus: false,
            }
        case UsersActionTypes.CREATE_USER:
            return {
                ...state,
                loadingStatus: true,
                status: ActionStatusTypes.loading,
                error: '',
            }
        case UsersActionTypes.CREATE_USER_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                status: ActionStatusTypes.success,
                error: '',
            }
        case UsersActionTypes.CREATE_USER_FAILED:
            return {
                ...state,
                loadingStatus: false,
                status: ActionStatusTypes.failed,
                error: action.payload?.error,
            }
        case UsersActionTypes.UPDATE_USER:
            return {
                ...state,
                selectedUser: action.payload.user,
                loadingStatus: true,
                status: ActionStatusTypes.loading,
                error: '',
            }
        case UsersActionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                status: ActionStatusTypes.success,
                error: '',
            }
        case UsersActionTypes.UPDATE_USER_FAILED:
            return {
                ...state,
                loadingStatus: false,
                status: ActionStatusTypes.failed,
                error: action.payload?.error || '',
            }
        case UsersActionTypes.SELECT_USER:
            return {
                ...state,
                loadingStatus: false,
                showUserDetail: true,
                selectedUser: action.payload.user,
            }
        case UsersActionTypes.CLOSE_USER_DETAIL:
            return {
                ...state,
                loadingStatus: false,
                showUserDetail: false,
            }
        case UsersActionTypes.CHANGE_PASSWORD_SUBMIT:
            return {
                ...state,
                changePassword: {
                    loading: {
                        status: 'active',
                        description: 'Sending new password',
                    },
                },
            }
        case UsersActionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePassword: {
                    loading: {
                        status: 'finished',
                        description: 'Password changed successfully',
                    },
                },
            }
        case UsersActionTypes.CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                changePassword: {
                    loading: {
                        status: 'error',
                        description:
                            'Something happened, check and try again. If the error persist contact the administrator.',
                    },
                },
            }
        case UsersActionTypes.CLEAR_ACTION_STATUS:
            return {
                ...state,
                status: ActionStatusTypes.neutral,
            }
        case UsersActionTypes.CLEAR_USER_LIST:
            return initialUsersState as IUsersState
        case UsersActionTypes.GET_USER_PROFILE:
            return {
                ...state,
                loadingStatus: true,
            }
        case UsersActionTypes.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload.profile,
                loadingStatus: false,
            }
        case UsersActionTypes.GET_USER_PROFILE_FAILED:
            return {
                ...state,
                profile: undefined,
                loadingStatus: false,
            }
        case UsersActionTypes.UPDATE_USER_PROFILE:
            return {
                ...state,
                loadingStatus: true,
            }
        case UsersActionTypes.UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload.profile,
                loadingStatus: false,
            }
        case UsersActionTypes.UPDATE_USER_PROFILE_FAILED:
            return {
                ...state,
                loadingStatus: false,
            }

        default:
            return state
    }
}
