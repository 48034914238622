import React, { useEffect, useState, InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import style from './gaNumberInput.module.scss'
import cx from 'classnames'
import { TextSizes } from '../../../../data/globalVar'
import InputSkeleton from '../../../molecules/skeletons/inlineSkeleton/InputSkeleton'

type GaNumberInputProps = {
    id?: string
    label?: string
    placeholder?: string
    helperText?: string
    extraDescription?: string
    value?: number
    readOnly?: boolean
    defaultValue?: string
    disabled?: boolean
    errorTexts?: NonNullable<React.ReactNode[]>
    invalid?: boolean
    name?: string
    min?: number
    max?: number
    step?: number
    required?: boolean
    className?: string
    autoComplete?: string
    type?: 'number'
    isLoading?: boolean
    ariaLabel?: string
    sucessText?: string
    textSize?: TextSizes
    onChange?: (e?) => void
    onKeyDown?: (e?) => void
}

const GaNumberInput: React.FC<GaNumberInputProps> = React.memo((props) => {
    const {
        id,
        label,
        placeholder,
        className,
        step,
        helperText,
        extraDescription,
        disabled,
        name,
        value,
        readOnly,
        defaultValue,
        errorTexts,
        invalid,
        min,
        max,
        required,
        autoComplete,
        type,
        isLoading,
        ariaLabel,
        sucessText,
        textSize,
        onChange,
        onKeyDown,
    } = props
    const { t } = useTranslation()

    const sizeFontClass = {
        LG: 'bodyRegularMD',
        MD: 'bodyRegularSM',
    }

    const [touched, setTouched] = useState(false)
    const [success, setSuccess] = useState(false)
    const [missing, setMissing] = useState(false)
    const invalidErrorTexts =
        invalid && errorTexts?.length && touched && !readOnly
    const sucessTextActive = success && sucessText

    useEffect(() => {
        invalid && setTouched(false)
    }, [value])

    useEffect(() => {
        invalid && setTouched(false)
    }, [value])

    useEffect(() => {
        success && setSuccess(true)
    }, [value])

    useEffect(() => {
        if (touched && !readOnly) {
            required && setMissing(value !== 0)
        }
    }, [touched, value])

    return (
        <div
            className={`${className} ${style.gaNumberInputContainer} ${
                required ? style.required : ''
            } ${readOnly ? style.readOnly : ''} ${
                disabled ? style.disabled : ''
            } ${missing || (invalid && touched && !readOnly) ? style.error : ''}
            ${sucessTextActive ? style.success : ''}`}
        >
            {!isLoading ? (
                <>
                    <div className={style.topTextsContainer}>
                        {label ? (
                            <label
                                className={`${cx('bodyRegularXS neutral700')}`}
                            >
                                {label}
                            </label>
                        ) : null}
                        {extraDescription ? (
                            <span
                                className={`${cx(
                                    'bodyRegularCap neutral600'
                                )} ${style.extraDescription} ${
                                    !label ? style.noLabel : ''
                                }`}
                            >
                                {extraDescription}
                            </span>
                        ) : null}
                    </div>
                    <input
                        className={`${cx(sizeFontClass[textSize || 'LG'])}`}
                        id={id ? id : label || '' + Math.random()}
                        min={min || 0}
                        max={max}
                        step={step || 1}
                        aria-label={label ? label : ariaLabel ? ariaLabel : ''}
                        value={value || 0}
                        readOnly={readOnly}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        onKeyDown={(e) => {
                            onKeyDown ? onKeyDown(e) : () => {}
                        }}
                        onBlur={() => setTouched(true)}
                        onChange={(e) => {
                            onChange ? onChange(e) : () => {}
                        }}
                        name={name ? name : ''}
                        required={required || false}
                        autoComplete={autoComplete ? autoComplete : 'Off'}
                        placeholder={placeholder ? placeholder : ''}
                        type={type ? type : 'number'}
                    ></input>
                    {helperText ||
                    invalidErrorTexts ||
                    missing ||
                    sucessTextActive ? (
                        <ul
                            className={`${style.bottomTextsContainer} ${cx(
                                'marginTop4'
                            )}`}
                        >
                            {helperText ? (
                                <li
                                    key="helperText"
                                    className={`${cx(
                                        'bodyRegularCap neutral600'
                                    )}`}
                                >
                                    {helperText}
                                </li>
                            ) : null}
                            {invalidErrorTexts
                                ? errorTexts?.map((errorText) => (
                                      <li
                                          key="errorText"
                                          className={`${cx(
                                              'bodyRegularCap alert300'
                                          )}`}
                                      >
                                          {errorText}
                                      </li>
                                  ))
                                : null}

                            {missing ? (
                                <li
                                    key="required"
                                    className={`${cx(
                                        'bodyRegularCap alert300'
                                    )} `}
                                >
                                    {t('public.required')}
                                </li>
                            ) : null}
                            {sucessTextActive ? (
                                <li
                                    key="successText"
                                    className={`${cx(
                                        'bodyRegularCap success300'
                                    )}`}
                                >
                                    {sucessText}
                                </li>
                            ) : null}
                        </ul>
                    ) : null}
                </>
            ) : (
                <InputSkeleton
                    className={className || ''}
                    labelText={label}
                    required={required}
                />
            )}
        </div>
    )
})

export default GaNumberInput
