import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
    ButtonModel,
    ImageModel,
} from '../../../../../../interfaces/interfaces'
import DarkButton from '../../../../../atoms/buttons/DarkButton/DarkButton'
import styles from './infoPanelWithAction.module.scss'
import cx from 'classnames'
import InfoIcon from '../../../../../../assets/iconsLibrary/simple/info/infoIcon'

type IInfoPanelWithActionProps = {
    title?: string
    label?: string
    label2?: string
    label3?: string
    hasIcon?: boolean
    icon?: ImageModel
    extraTranslationParams?: Object
    button?: ButtonModel
}

const InfoPanelWithAction: React.FC<IInfoPanelWithActionProps> = (props) => {
    const {
        title,
        label,
        label2,
        label3,
        hasIcon,
        icon,
        extraTranslationParams,
        button,
    } = props
    const { t } = useTranslation()

    return (
        <div className={styles.infoPanelWithAction}>
            <div className={styles.infoPanelWithAction__texts}>
                <p>
                    <InfoIcon color="#0081F1" size={24} />
                    <span className={cx('bodyBoldSM neutral1000')}>
                        {title ? title : t('public.reminder')}
                    </span>
                </p>
                <p className={cx('bodyRegularSM neutral1000')}>
                    <Trans
                        i18nKey={label}
                        values={{ ...extraTranslationParams }}
                    />
                </p>
                {label2 && (
                    <p className={cx('bodyRegularSM neutral1000')}>
                        <Trans
                            i18nKey={label2}
                            values={{ ...extraTranslationParams }}
                        />
                    </p>
                )}
                {label3 && (
                    <p className={cx('bodyRegularSM neutral1000')}>
                        <Trans
                            i18nKey={label3}
                            values={{ ...extraTranslationParams }}
                        />
                    </p>
                )}
            </div>
            {button && (
                <DarkButton
                    text={button.label}
                    disabled={false}
                    functionality={button.function}
                />
            )}
        </div>
    )
}

export default InfoPanelWithAction
