import React from 'react'

type ChevronRightIconProps = {
    size?: number
    color?: string
    className?: string
}

const ChevronRightIcon: React.FC<ChevronRightIconProps> = React.memo(
    (props) => {
        const { size, color, className } = props

        return (
            <svg
                className={className && className}
                width={size || '32'}
                height={size || '32'}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12 24L20 16L12 8"
                    stroke={color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )
    }
)

export default ChevronRightIcon
