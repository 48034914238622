import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import StatusPanel from '../../../../../Components/atoms/panels/StatusPanel/StatusPanel'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import { onPremise } from '../../../../../data/globalVar'
import { ICreateDidModel } from '../../../../../interfaces/interfaces'
import { setSelectedRoute } from '../../../../common/PanelScafolding/scafoldingActions'
import {
    clearActionStatus,
    clearDid,
    clearFormState,
    createDid,
    setSteps,
} from '../../didActions'
import {
    getActiveStep,
    getError,
    getSelectedDidToken,
    getStatus,
} from '../../didSelectors'
import { createFormSteps } from '../didList/didList.constants'

type ICreateDidProps = {}

const CreateDid: React.FC<ICreateDidProps> = ({}) => {
    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const activeStep = useSelector(getActiveStep)
    const selectedDidToken = useSelector(getSelectedDidToken)
    const createStatus = useSelector(getStatus)
    const creationError = useSelector(getError)

    useEffect(() => {
        dispatch(setSteps(createFormSteps))
        return () => {
            dispatch(clearDid())
        }
    }, [createFormSteps])

    const registerDid = (data: any) => {
        const simplifiedDid = data as ICreateDidModel
        // Just in cloud for now
        !onPremise && dispatch(createDid(simplifiedDid))
    }

    const handleCloseModal = () => dispatch(clearActionStatus())

    const navigate = (route) => {
        dispatch(setSelectedRoute(route)), history.push(route)
    }
    const goToHome = () => {
        clearState(), navigate('home')
    }
    const goToList = () => {
        clearState(), navigate('dids')
    }

    const clearState = () => {
        dispatch(clearFormState())
        dispatch(clearDid())
    }

    return (
        <>
            <ScreenHeader title={'createDid.title'} />
            <div className={cx('margin-top')}>
                {activeStep?.component ? (
                    <activeStep.component
                        buttonFunction={registerDid}
                        webToken={selectedDidToken}
                    />
                ) : null}
            </div>

            <StatusPanel
                successMessage={'createDid.success'}
                state={'createDid.isReady'}
                operationStatus={createStatus}
                show={
                    createStatus &&
                    (createStatus as ActionStatusTypes) !==
                        ActionStatusTypes.neutral
                }
                failedText={
                    t('createDid.failedOperation') +
                    (creationError ? ': ' : '.')
                }
                error={creationError || ''}
                primaryButton={{
                    label: 'createDid.backToList',
                    function: goToList,
                }}
                secondaryButton={{
                    label: 'createDid.backToHome',
                    function: goToHome,
                }}
                handleClose={handleCloseModal}
            />
        </>
    )
}

export default CreateDid
