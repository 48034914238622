import React from 'react'

type ShieldErrorIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const ShieldErrorIcon: React.FC<ShieldErrorIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M19.7712 11.5982L15.9999 15.3694M15.9999 15.3694L12.2287 19.1407M15.9999 15.3694L12.2287 11.5982M15.9999 15.3694L19.7712 19.1407M15.9999 29.3333C15.9999 29.3333 26.6666 24 26.6666 16V6.66666L15.9999 2.66666L5.33325 6.66666V16C5.33325 24 15.9999 29.3333 15.9999 29.3333Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <path
                d="M15.9999 29.3333C15.9999 29.3333 26.6666 24 26.6666 16V6.66666L15.9999 2.66666L5.33325 6.66666V16C5.33325 24 15.9999 29.3333 15.9999 29.3333Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.7712 11.5982L12.2288 19.1407M12.2288 11.5982L19.7712 19.1407"
                stroke="white"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export default ShieldErrorIcon
