import moment from 'moment'
import i18n, { initialLang, translateDate } from '../../../../i18n'
import {
    Authority,
    CredentialBase,
    CredentialCard,
    CredentialFormat,
    CredentialGroup,
    CredentialStatusType,
    CredentialStatusWrapper,
    DidPublicKey,
    GroupableCredential,
    JwtCredential,
    LdpCredential,
    Proof,
    VerifiableCredential,
    credentialStatusTypes,
} from '../data/requestCredentials.interfaces'
import { t } from 'i18next'
import {
    faceTypes,
    imagesTypes,
    jp,
    subClaims,
} from '../data/requestCredentials.constants'
import { SignatureAlgorithm } from '../data/requestCredentials.enums'
import { decodeJWT } from 'did-jwt'
import { TranslationModel } from '../../../../interfaces/interfaces'

export const getFormattedJWTCredLists = (lists) => {
    const decodedCreds = lists?.map((list) => {
        const credentials = list?.credentials?.map((cred) => {
            return cred?.vcFormat === 'jwt_vc'
                ? {
                      vc: decodeJWT(cred?.vc?.toString()).payload?.vc,
                      originalVC: cred?.vc,
                      vcFormat: cred?.vcFormat,
                      groupName: cred?.groupName,
                      groupId: cred?.groupId,
                      id: cred?.vc,
                  }
                : {
                      ...cred,
                      id: cred?.vc?.id,
                  }
        })

        return {
            ...list,
            credentials,
        }
    })

    return decodedCreds
}

export const getFormattedCreds = (
    creds,
    catalogGroups,
    issuers,
    catalogTypes
) => {
    const cards = generateCredentialCards(
        creds,
        catalogGroups,
        issuers,
        catalogTypes,
        false,
        true
    )

    cards?.map((cred, indexCard) => {
        creds?.forEach((c: GroupableCredential) => {
            c?.groupId === cred?.id ? cred?.vcs?.push(c?.vc) : null
        })
        cred.id = creds[indexCard]?.vc?.id

        return cred
    })

    return cards
}

export const getFormattedCredsForConsent = (
    creds,
    catalogGroups,
    issuers,
    catalogTypes,
    getImagevalue
) => {
    const cards = generateCredentialCards(
        creds,
        catalogGroups,
        issuers,
        catalogTypes,
        false,
        getImagevalue
    )

    cards?.map((cred) => {
        creds?.forEach((c: GroupableCredential) => {
            c?.groupId === cred?.id ? cred?.vcs?.push(c?.vc) : null
        })
    })

    return cards
}

const generateCredentialCards = (
    credentials: GroupableCredential[],
    catalogGroups: CredentialGroup[],
    issuers,
    types,
    filterByExisting?: boolean,
    getImagevalue?: boolean,
    index?: number
): CredentialCard[] => {
    let result: CredentialCard[] = []

    for (let c of credentials) {
        const existing = filterByExisting
            ? result.find((card) => card.id === c?.groupId)
            : null
        const catalogGroup = catalogGroups.find((g) => g.id === c?.groupName)

        if (existing) {
            if (
                !existing.mainClaim &&
                catalogGroup?.mainClaim === getType(c?.vc)
            ) {
                existing.mainClaim = getMainData(c?.vc)
            }
            if (
                subClaims[c?.groupName] &&
                !existing.subClaim &&
                subClaims[c?.groupName] === getType(c?.vc)
            ) {
                existing.subClaim = getMainData(c?.vc)
            }
            existing.isQSign = existing.isQSign || isQualifiedSignature(c?.vc)
        } else {
            const date =
                // @ts-ignore
                c?.vc?.issuanceDate || c?.vc?.validFrom || c?.vc?.issued
            const formatedDate = date ? translateDate(date) : 'N/A' // Default english
            // @ts-ignore
            const expDate = c?.vc?.expirationDate
            const expirationDate = expDate
                ? moment(expDate)?.format('LL')
                : expDate
            const formatedExpirationDate = expirationDate
                ? translateDate(expirationDate)
                : undefined
            const defaultName = t('ew.requestCredentials.credential')
            const subClaim =
                subClaims[c?.groupName] &&
                subClaims[c?.groupName] === getType(c?.vc)
                    ? getMainData(c?.vc)
                    : ''
            const qualified = isQualifiedSignature(c?.vc)
            const secLevel = getMaxLevel(c?.vc, issuers)
            // @ts-ignore
            const credIssuer = c?.vc?.issuer
            const firstCredIssuer = Array.isArray(credIssuer)
                ? credIssuer[0]
                : credIssuer
            const issuerName = issuers?.find(
                (iss) => iss.id === firstCredIssuer
            )?.name
            const issuer = issuerName
                ? issuerName[initialLang || 'en']
                : getIssuers(c?.vc, issuers)
            const representativeValues = getRepresentativeDisplay(c?.vc, types)
            const {
                representationField,
                representativeData,
                isFace,
                isImage,
                imageURI,
            } = representativeValues
            const mainData =
                (isFace || isImage) && !getImagevalue ? '' : representativeData

            const newCard: CredentialCard = {
                id: c?.groupId,
                group: catalogGroup || {
                    id: c?.groupName,
                    name: defaultName,
                    issuerList: [],
                    hidden: false,
                },
                groupId: c?.groupName,
                groupName: catalogGroup ? catalogGroup.name : defaultName,
                date: formatedDate,
                rawDate: date ? moment(date)?.format('LL') : date,
                rawExpirationDate: expirationDate,
                isPrivate: !catalogGroup,
                isQSign: qualified,
                isDisabled: false,
                isExpired: getIfExpired(expirationDate),
                status: '-',
                issuer: issuer || undefined,
                mainClaim: mainData,
                mainCredential: mainData,
                subClaim: subClaim,
                vcs: [],
                securityLevel: secLevel ? secLevel : 0,
                expirationDate: formatedExpirationDate,
                index: index,
            }

            result.push(newCard)
        }
    }
    // @ts-ignore
    result.sort((a, b) => b.rawDate - a.rawDate)

    return result
}

const getIfExpired = (expirationDate?: string) => {
    if (!!expirationDate) {
        const currentDate = moment()?.toDate()
        const expired = moment(currentDate).isAfter(expirationDate)

        return expired
    } else {
        return false
    }
}

const isQualifiedSignature = (credential: VerifiableCredential): boolean => {
    if (isJwt(credential)) {
        return false
    }
    let p = (credential as LdpCredential)?.proof
    if ((p as Proof)?.type) {
        return (p as Proof)?.type === SignatureAlgorithm.TypeEidas
    }
    if ((p as Proof[])?.length) {
        return (p as Proof[])?.find(
            (pr) => pr?.type === SignatureAlgorithm.TypeEidas
        )
            ? true
            : false
    }
    return false
}

const getAuthorities = (
    vc: VerifiableCredential,
    issuers: any
): Authority[] | null => {
    // @ts-ignore
    if (vc?.proof && vc?.proof?.length > 0) {
        // @ts-ignore
        return vc?.proof
            .map((proof: Proof) => {
                issuers?.filter((issuer) => {
                    //Can't trust issuer of the credential
                    return (
                        issuer.id === getDidOwnerFromKey(getProofCreator(proof))
                    )
                })

                return issuers.length === 1 ? issuers[0] : null
            })
            .filter((element) => element != null)
    }
    return null
}

const getProofCreator = (proof: Proof): string => {
    let creator = proof.creator || proof.verificationMethod
    if (typeof creator === 'string') {
        return creator
    }
    // @ts-ignore
    if ((creator as DidPublicKey).type) {
        // @ts-ignore
        return (creator as DidPublicKey).id
    }
    let c = (creator as string[] | DidPublicKey[])[0]
    if (typeof c === 'string') {
        return c
    }
    if ((c as DidPublicKey).type) {
        return (c as DidPublicKey).id
    }
    return 'unknown'
}

const getDidOwnerFromKey = (key: string): string =>
    key ? key.split('#')[0] : 'unknown'

const getMaxLevel = (vc: VerifiableCredential, issuers): number => {
    let authorities = getAuthorities(vc, issuers)

    if (authorities && !!authorities.length) {
        let levels = authorities.map((issuer) => {
            return issuer.level
        })
        return Math.max(...levels)
    }
    return 0
}

const getIssuers = (vc: VerifiableCredential, issuers): string[] | null => {
    let authorities = getAuthorities(vc, issuers)

    return authorities
        ? authorities?.map((issuer) => {
              return typeof issuer.name != 'string'
                  ? //@ts-ignore
                    translateCatalog(issuer.name)
                  : issuer.name
          })
        : null
}

const getRepresentativeDisplay = (vc: VerifiableCredential, types) => {
    const bs: CredentialBase = getBaseCredential(vc)
    const credentialType = types?.filter((type) => type.id === getType(vc))
    const representationField =
        credentialType && credentialType[0]
            ? credentialType[0]?.representationField
            : ''
    const representativeData = representationField
        ? jp?.query(bs?.credentialSubject, representationField)[0]
        : ''

    const isImage = imagesTypes.find((i) => representationField?.includes(i))
    const isFace = faceTypes.find((i) => representationField?.includes(i))
    const imageURI =
        representationField && representativeData?.includes('data:image')
            ? representativeData
            : 'data:image/jpg;base64,' + representativeData

    return {
        representationField,
        representativeData,
        isImage,
        isFace,
        imageURI,
    }
}

const getFormat = (cred: VerifiableCredential): CredentialFormat =>
    cred && (cred as JwtCredential)?.vc ? 'jwt_vc' : 'ldp_vc'

const isJwt = (cred: VerifiableCredential): boolean =>
    typeof cred === 'string' || getFormat(cred) === 'jwt_vc'

const getBaseCredential = (cred: VerifiableCredential): CredentialBase =>
    isJwt(cred) ? (cred as JwtCredential).vc : (cred as CredentialBase)

const getType = (credential: VerifiableCredential): string => {
    let bs: CredentialBase = getBaseCredential(credential)
    return bestType(bs?.type)
}

//The most definining type is the last one
const bestType = (types: string[]): string =>
    !!types && types.length > 1 ? types[types.length - 1] : ''

const getMainData = (credential: VerifiableCredential): string => {
    let bs: CredentialBase = getBaseCredential(credential)
    let type = getType(credential).split('Credential')[0] // TODO: DEV-2185 Remove business logic based on name 'Credential'

    if (bs?.credentialSubject) {
        let monoclaim = Object.keys(bs?.credentialSubject).length <= 2

        return !monoclaim
            ? type == 'vId'
                ? bs?.credentialSubject['personalIdentifier']
                : t('ew.requestCredentials.multipleData')
            : monoclaim
    }
    return ''
}

export const translateCatalog = (
    mapName: Map<string, string> | TranslationModel
): string => {
    let map = mapName as Map<string, string>

    return map[i18n.language] || map[initialLang]
}
