import cx from 'classnames'
import React from 'react'
import styles from './noCredentialsPanel.module.scss'
import { gaImages } from '../../../../../assets/globalConstants'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import GaImage from '../../../../../interfaces/Image/Image'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import { ButtonSizes } from '../../../../../data/globalVar'
import { t } from 'i18next'

type INoCredentialsPanelProps = {
    showScanModal: (x?: any) => void
    setFile: (x?) => void
}

const NoCredentialsPanel: React.FC<INoCredentialsPanelProps> = (props) => {
    const { showScanModal, setFile } = props

    return (
        <div className={styles.noCredentialsPanel}>
            <div className={styles.noCredentialsPanel__content}>
                <GaImage image={gaImages.folderFindEmpty} />
                <div className={styles.noCredentialsPanel__content__body}>
                    <p className={cx('heading5 neutral1000 marginAuto')}>
                        {t('ew.requestCredentials.noCredentialsPanelTitle')}
                    </p>
                    <p className={cx('bodyRegularMD neutral700 marginAuto')}>
                        {t('ew.requestCredentials.noCredentialsPanelDesc')}
                    </p>
                </div>
                <div className={styles.noCredentialsPanel__content__footer}>
                    <div>
                        <LightButton
                            text={t('ew.requestCredentials.importCred')}
                            className={styles.lightButton}
                            disabled={false}
                            size={ButtonSizes.SM}
                            functionality={() => console.log('import cred')}
                        />
                        <input
                            className={
                                styles.noCredentialsPanel__content__footer__input
                            }
                            type="file"
                            accept="text/plain"
                            onChange={(e) => {
                                setFile(e)
                            }}
                        />
                    </div>
                    <div>
                        <DarkButton
                            text={t('ew.requestCredentials.requestCred')}
                            disabled={false}
                            size={ButtonSizes.SM}
                            functionality={() => showScanModal(true)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoCredentialsPanel
