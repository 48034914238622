import React from 'react'

type LockIconProps = {
    size?: number
    color?: string
    className?: string
}

const LockIcon: React.FC<LockIconProps> = React.memo((props) => {
    const { size, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.3333 14.6667H6.66667C5.19391 14.6667 4 15.8606 4 17.3334V26.6667C4 28.1394 5.19391 29.3334 6.66667 29.3334H25.3333C26.8061 29.3334 28 28.1394 28 26.6667V17.3334C28 15.8606 26.8061 14.6667 25.3333 14.6667Z"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.3335 14.6667V9.33335C9.3335 7.56524 10.0359 5.86955 11.2861 4.61931C12.5364 3.36907 14.2321 2.66669 16.0002 2.66669C17.7683 2.66669 19.464 3.36907 20.7142 4.61931C21.9644 5.86955 22.6668 7.56524 22.6668 9.33335V14.6667"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})

export default LockIcon
