import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import FormPanel from '../../../../Components/FormPanel/FormPanel'
import PanelScafoldingPublic from '../../PanelScafolding/public/PanelScafoldingPublic'
import {
    formLegend,
    goBackLink,
    loginLink,
    sendEmailButton,
} from './forgetPasswordConstants'
import GaTextInput from '../../../../Components/atoms/formFields/textInput/GaTextInput'

type IForgetPasswordProps = {}

const ForgetPassword: React.FC<IForgetPasswordProps> = () => {
    const [recoveryEmail, setRecoveryEmail] = useState()
    const history = useHistory()
    const { t } = useTranslation()

    const resetPassword = () => {
        history.push('/reset-password')
    }

    return (
        <PanelScafoldingPublic link={loginLink}>
            <FormPanel
                title={'forgetPassword.passwordRecovery'}
                legend={formLegend}
                primaryButton={sendEmailButton}
                primaryFunction={resetPassword}
                ctaLink={goBackLink}
            >
                <GaTextInput
                    className="inputs"
                    disabled={false}
                    helperText=""
                    id="token-input-email"
                    invalid={false}
                    labelText={t('forgetPassword.auxText')}
                    onChange={(e) => setRecoveryEmail(recoveryEmail)}
                    placeholder="Email"
                    type="text"
                    required
                    value={recoveryEmail}
                />
            </FormPanel>
        </PanelScafoldingPublic>
    )
}

ForgetPassword.defaultProps = {}

export default ForgetPassword
