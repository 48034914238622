import { ICredentialCardProps } from '../../features/wallet/requestCredentials/components/credentialCard/CredentialCard'
import {
    EWCredential,
    ICredentialsItemModel,
    IEWCredentialsPaginatedResponseModel,
} from '../../interfaces/interfaces'
import {
    ClearCredentialList,
    CredentialListActionsTypes,
    IDeleteCredentialAction,
    IDeleteCredentialFailed,
    IDeleteCredentialSuccessAction,
    IGetCredentialsAction,
    IGetCredentialsFailed,
    IGetCredentialsSuccessAction,
    IImportCredentialAction,
    IImportCredentialFailed,
    IImportCredentialSuccessAction,
    ISelectCredentialAction,
} from './requestCredentialsTypes'

// LIST
export const getCredentials = (
    did: string,
    page?: number,
    size?: number
): IGetCredentialsAction => {
    return {
        type: CredentialListActionsTypes.GET_CREDENTIALS,
        payload: {
            did,
            page,
            size,
        },
    }
}

export const getCredentialsSuccess = (
    data: IEWCredentialsPaginatedResponseModel | ICredentialsItemModel[]
): IGetCredentialsSuccessAction => {
    return {
        type: CredentialListActionsTypes.GET_CREDENTIALS_SUCCESS,
        payload: { data },
    }
}

export const getCredentialsFailed = (error: string): IGetCredentialsFailed => {
    return {
        type: CredentialListActionsTypes.GET_CREDENTIALS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearCredentialList = (): ClearCredentialList => {
    return {
        type: CredentialListActionsTypes.CLEAR_CREDENTIAL_LIST,
    }
}

export const selectCredential = (
    credential?: ICredentialCardProps
): ISelectCredentialAction => {
    return {
        type: CredentialListActionsTypes.SELECT_CREDENTIAL,
        payload: {
            credential,
        },
    }
}

export const deleteCredential = (
    did: string,
    credentialId: string,
    page?: number,
    size?: number
): IDeleteCredentialAction => {
    return {
        type: CredentialListActionsTypes.DELETE_CREDENTIAL,
        payload: {
            did,
            credentialId,
            page,
            size,
        },
    }
}

export const deleteCredentialSuccess = (
    credentials: ICredentialsItemModel[]
): IDeleteCredentialSuccessAction => {
    return {
        type: CredentialListActionsTypes.DELETE_CREDENTIAL_SUCCESS,
    }
}

export const deleteCredentialFailed = (
    error: string
): IDeleteCredentialFailed => {
    return {
        type: CredentialListActionsTypes.DELETE_CREDENTIAL_FAILED,
        payload: {
            error: error,
        },
    }
}

export const importCredential = (
    did: string,
    credData: EWCredential,
    size?: number
): IImportCredentialAction => {
    return {
        type: CredentialListActionsTypes.IMPORT_CREDENTIAL,
        payload: {
            did,
            credData,
            size,
        },
    }
}

export const importCredentialSuccess = (
    credential: EWCredential
): IImportCredentialSuccessAction => {
    return {
        type: CredentialListActionsTypes.IMPORT_CREDENTIAL_SUCCESS,
        payload: {
            credential,
        },
    }
}

export const importCredentialFailed = (
    error: string
): IImportCredentialFailed => {
    return {
        type: CredentialListActionsTypes.IMPORT_CREDENTIAL_FAILED,
        payload: {
            error: error,
        },
    }
}
