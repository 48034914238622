import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { brandColors, gaImages } from '../../assets/globalConstants'
import { setSelectedRoute } from '../../features/common/PanelScafolding/scafoldingActions'
import { sessionSelectors } from '../../features/common/session'
import { getLicenseType } from '../../features/common/session/sessionSelectors'
import { ImageModel, LinkModel } from '../../interfaces/interfaces'
import GaImage from '../atoms/Image/Image'
import styles from './dropdownMenu.module.scss'
import { licensesSelectors } from '../../features/common/licenses'
import { userHasScopes } from '../../utils/permissionsUtil'
import cx from 'classnames'

type IDropdownMenuProps = {
    label?: string
    selected?: boolean
    icon?: ImageModel
    iconClassName?: string
    iconComponent?: any
    className?: string
    menuOptions: LinkModel[]
}

const DropdownMenu: React.FC<IDropdownMenuProps> = (props) => {
    const {
        label,
        icon,
        iconClassName,
        iconComponent,
        className,
        menuOptions,
        selected,
    } = props
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [active, setIsActive] = useState(false)
    const currentLicenseType: string = useSelector(getLicenseType)
    const cantAccessSupport = currentLicenseType === 'Starter'
    const userTenants = useSelector(sessionSelectors?.getTenants)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const thereIsDue = subscriptionInfo?.subscriptionStatus === 'past_due'
    const canceledLicense =
        subscriptionInfo?.subscriptionStatus === 'unpaid' ||
        subscriptionInfo?.subscriptionStatus === 'canceled'
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const userLicense = useSelector(sessionSelectors.getLicenses)
    const uRole = useSelector(sessionSelectors.getUserRole_s)

    const userPaymnetByStripe =
        subscriptionInfo?.currentTierId &&
        !subscriptionInfo?.paymentOutsideStripe
    const userIsOrgOwner = uRole === 'OrganizationOwner'
    const hasBillingScope = !!(
        availableScopes &&
        userHasScopes(['manageSubscriptions'], availableScopes)
    )
    const showGoToBillingButton =
        hasBillingScope && userPaymnetByStripe && userIsOrgOwner

    const showOption = (optionID?: string) => {
        return (
            (optionID === 'tenants' && userTenants?.length > 1) ||
            (optionID !== 'tenants' &&
                (optionID !== 'general' ||
                    (optionID === 'general' &&
                        !canceledLicense &&
                        (!thereIsDue || showGoToBillingButton))))
        )
    }

    function navigate(url) {
        setActiveRoute(url)
        history.push(url)
    }

    const setActiveRoute = (route: string) => {
        dispatch(setSelectedRoute(route))
    }

    return (
        <>
            {label ? (
                <>
                    {t(label)}
                    <button
                        className={styles.dropdownButton + ' ' + className}
                        onClick={() => setIsActive(!active)}
                    >
                        <GaImage
                            className={
                                iconClassName
                                    ? iconClassName
                                    : active
                                    ? styles.dropdownButton__iconActive
                                    : styles.dropdownButton__icon
                            }
                            image={icon || gaImages.arrowForward}
                        />
                    </button>
                </>
            ) : (
                <button
                    className={styles.dropdownButton + ' ' + className}
                    onClick={() => setIsActive(!active)}
                >
                    {iconComponent ? (
                        <props.iconComponent
                            color={
                                !active ? brandColors?.primaryColor : '#1B1A5F'
                            }
                        />
                    ) : (
                        <GaImage
                            className={
                                iconClassName
                                    ? iconClassName
                                    : active
                                    ? styles.dropdownButton__iconActive
                                    : styles.dropdownButton__icon
                            }
                            image={icon || gaImages.angleDown}
                        />
                    )}
                </button>
            )}
            <div
                className={
                    (active ? styles.active : styles.inActive) +
                    ' ' +
                    styles.menuOptions
                }
            >
                <ul className={styles.menuOptions__list}>
                    {menuOptions.map((e, index) => {
                        return e.subRoutes ? (
                            <DropdownMenu
                                key={'dropdownOption__' + index}
                                menuOptions={e.subRoutes}
                                label={e.label}
                            ></DropdownMenu>
                        ) : e.id !== 'support' && e.id !== 'documentation' ? (
                            showOption(e.id) ? (
                                <li
                                    key={e.route + e.id + index}
                                    className={styles.menuOptions__item}
                                    onClick={() => {
                                        if (e.action) {
                                            dispatch(e.action(e.id))
                                        }
                                        if (e.route !== 'no') {
                                            e.id === 'general' && thereIsDue
                                                ? navigate('billing')
                                                : navigate(e.route)
                                        }
                                        setIsActive(!active)
                                    }}
                                >
                                    {e.icon ? (
                                        <GaImage
                                            className={styles.menuOptions__icon}
                                            image={e.icon}
                                        />
                                    ) : null}
                                    <span
                                        className={`${
                                            selected ? 'font-weight-bold' : ''
                                        }
                                            ${cx('buttonSM neutral1000')}`}
                                    >
                                        {t(e.label)}
                                    </span>
                                </li>
                            ) : null
                        ) : !cantAccessSupport || e.id == 'documentation' ? (
                            <a
                                key={'dropdownLink__' + index}
                                className={styles.menuOptions__item}
                                href={e.outLink}
                                target="_blank"
                            >
                                <li key={e.route + e.id + index}>
                                    {e.icon ? (
                                        <GaImage
                                            className={styles.menuOptions__icon}
                                            image={e.icon}
                                        />
                                    ) : null}
                                    <span
                                        className={`${
                                            selected ? 'font-weight-bold' : ''
                                        }
                                            ${cx('buttonSM neutral1000')}`}
                                    >
                                        {t(e.label)}
                                    </span>
                                </li>
                            </a>
                        ) : null
                    })}
                </ul>
            </div>
            {active ? (
                <div
                    className={styles.backgroundLayer}
                    onClick={() => setIsActive(!active)}
                ></div>
            ) : null}
        </>
    )
}

export default DropdownMenu
