import cx from 'classnames'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { gaImages } from '../../../../assets/globalConstants'
import { ImageModel } from '../../../../interfaces/interfaces'
import Description from '../../../atoms/texts/Description/Description'
import SearchBar, { IPropertiesToSearch } from '../../searchBar/SearchBar'
import styles from './searchableListHeader.module.scss'

type SearchableListHeaderProps = {
    title?: string
    extraTranslationTitleParams?: Object
    description?: string
    icon?: ImageModel
    isLoading?: boolean
    options?: any[]
    propertiesToSearch?: IPropertiesToSearch[]
    propertiesToFuzzySearch?: { key: string; threshold: number }[]
    placeholder?: string
    className?: string
    handleSearchedChange: (x?: any) => void
}

const SearchableListHeader: React.FC<SearchableListHeaderProps> = React.memo(
    (props) => {
        const {
            title,
            extraTranslationTitleParams,
            description,
            isLoading,
            icon,
            options,
            className,
            propertiesToSearch,
            propertiesToFuzzySearch,
            placeholder,
            handleSearchedChange,
        } = props
        const { t } = useTranslation()

        return (
            <div
                className={cx(
                    styles.searchableListHeader,
                    className && className
                )}
            >
                <div>
                    {title ? (
                        <p
                            className={
                                cx('margin-top-min neutral700 bodyRegularMD') +
                                ' ' +
                                styles.SearchableListHeader__description
                            }
                        >
                            <Trans
                                i18nKey={title}
                                values={{ ...extraTranslationTitleParams }}
                            />
                        </p>
                    ) : null}
                    {description ? (
                        <Description
                            className={cx('margin-top-micro')}
                            text={'editIssuance.step3.addPurposesAux'}
                        />
                    ) : null}
                </div>

                {!isLoading &&
                options &&
                options?.length > 0 &&
                !!options[0] ? (
                    <SearchBar
                        className={cx(
                            'width100 margin-left-auto marginTop32 marginBottom32'
                        )}
                        propertiesToFuzzySearch={propertiesToFuzzySearch}
                        options={options || []}
                        propertiesToSearch={propertiesToSearch}
                        icon={icon || gaImages.searchBar}
                        handleSearchedChange={handleSearchedChange}
                        placeholder={placeholder || 'public.search'}
                    />
                ) : null}
            </div>
        )
    }
)

export default SearchableListHeader
