import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import {
    ButtonModel,
    IIssuanceConfigItemModel,
} from '../../../../../interfaces/interfaces'
import {
    formatRequestedCredentials,
    getActiveStep,
    getFormattedRequestedCredentials,
    returnOriginalRequestedCredential,
} from '../../issuanceSelectors'
import { setSelectedCredentials } from '../../issuanceActions'
import style from './credentialsRequirementsForm.module.scss'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import TemplatesFormHeader from '../../../../common/TemplatesHeader/TemplatesFormHeader'
import RequirementAndPurposeCard from '../../../../../Components/organisms/CredentialCard/EditableCard/RequirementAndPurpose/RequirementAndPurposeCard'
import CredentialsRequirementsPreview from '../../../../../Components/molecules/modals/CredentialPreview/components/StepsContentPreview/CredentialsRequirementsPreview'
import { issuanceSelectors } from '../..'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import { getLoadingStatus } from '../../../../common/catalog/catalogSelectors'
import { getResponsive } from '../../../../../utils/formUtils'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'

type ICredentialsRequirementsFormProps = {
    canEditIssuances?: boolean
    issuance: IIssuanceConfigItemModel
    creatingIssuance?: boolean
    buttonHeader?: ButtonModel
    infoApiKeyPanel?: boolean
    activeStep: any
    formSteps: any
    buttonFunctionHeader?: (x) => void
    setRequirementsStepModified?: (x) => void
    buttonFunction: (x) => void
    setValidForm: (x) => void
    onHandleChange: (step) => void
}

const CredentialsRequirementsForm: React.FC<
    ICredentialsRequirementsFormProps
> = (props) => {
    const {
        issuance,
        canEditIssuances,
        creatingIssuance,
        buttonHeader,
        infoApiKeyPanel,
        activeStep,
        formSteps,
        buttonFunctionHeader,
        setRequirementsStepModified,
        buttonFunction,
        setValidForm,
        onHandleChange,
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    // Selectors
    const selected = useSelector(getFormattedRequestedCredentials)
    const credTypesList = useSelector(issuanceSelectors.getOriginalCatalogTypes)
    const catalogLoadingStatus = useSelector(getLoadingStatus)
    const catalogIsLoading = !(
        catalogLoadingStatus?.status === 'finished' ||
        catalogLoadingStatus?.status === 'error'
    )
    const currentRequestedUnfCred = useSelector(
        issuanceSelectors.getRequestedUnfCredentials
    )
    const initialRequestedUnfCred = useSelector(
        issuanceSelectors.getInitialRequestedUnfCredentials
    )

    const step = useSelector(getActiveStep)

    // States
    const [credentials, setSelection] = React.useState(
        formatRequestedCredentials(credTypesList, currentRequestedUnfCred) || []
    )

    const [formDataModified, setFormDataModified] = useState(false)

    // Variables
    let requiredCredentials = credentials?.filter((el) => !!el.mandatory)

    let optionalCredentials = credentials?.filter((el) => !el.mandatory)

    const validForm = !!credentials.length

    useEffect(() => {
        scrollToTop()
    }, [step])

    useEffect(() => {
        setSelection(
            formatRequestedCredentials(credTypesList, currentRequestedUnfCred)
        ),
            setValidForm(selected && !!selected[0])
    }, [step, currentRequestedUnfCred])

    useEffect(() => {
        !creatingIssuance &&
            setSelection(
                formatRequestedCredentials(
                    credTypesList,
                    currentRequestedUnfCred
                )
            ),
            setValidForm(selected && !!selected[0])
        if (creatingIssuance) {
            requiredCredentials = credentials?.filter((el) => !!el.mandatory)

            optionalCredentials = credentials?.filter((el) => !el.mandatory)
        }
    }, [selected, currentRequestedUnfCred])

    useEffect(() => {
        if (
            JSON.stringify(
                returnOriginalRequestedCredential(initialRequestedUnfCred)
            ) ===
            JSON.stringify(
                returnOriginalRequestedCredential(currentRequestedUnfCred)
            )
        ) {
            setFormDataModified(false)
            setRequirementsStepModified && setRequirementsStepModified(false)
        } else {
            setFormDataModified(true)
            setRequirementsStepModified && setRequirementsStepModified(true)
        }
    }, [credentials])

    useEffect(() => {}, [requiredCredentials, optionalCredentials])

    // Functions
    const setCredentialsMandatoryValues = (
        credIndex: number,
        value: string
    ) => {
        const modifiedList = credentials
        const isMandatory = value === 'required'
        modifiedList[credIndex]
            ? (modifiedList[credIndex].mandatory = isMandatory)
            : null

        dispatch(setSelectedCredentials(modifiedList))
        setSelection(modifiedList)
        setValidForm(credentials && credentials[0]?.length)
    }

    const saveAndNext = () => {
        buttonFunction({
            prConfig: {
                ...issuance.prConfig,
                credentials:
                    formatRequestedCredentials(
                        credTypesList,
                        currentRequestedUnfCred
                    ) || [],
            },
        })
        scrollToTop()
    }

    // Responsive preview

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    return (
        <>
            <div className={style.leftColumn}>
                <div className={style.headerStepper}>
                    <TemplatesFormHeader
                        title={
                            creatingIssuance
                                ? 'createIssuance.title'
                                : t('editIssuance.title', {
                                      id: issuance?.id || '',
                                  })
                        }
                        description={
                            creatingIssuance
                                ? 'createIssuance.description'
                                : 'editIssuance.description'
                        }
                        buttonFunctionHeader={buttonFunctionHeader}
                        buttonHeader={buttonHeader}
                        formSteps={formSteps}
                        elementsAllignment={creatingIssuance ? false : true}
                        infoApiKeyPanel={infoApiKeyPanel}
                        titleInfoPanel={t('editIssuance.addApiKeyInfo')}
                        onHandleChange={onHandleChange}
                        activeStep={activeStep}
                        loadingProgressBar={catalogIsLoading}
                        inactiveSteps={
                            !creatingIssuance &&
                            !issuance?.prConfig?.credentials?.length
                                ? [3, 4]
                                : undefined
                        }
                    />
                </div>
                <div className={style.form}>
                    <LightPanel>
                        <ScreenHeader
                            title={'editIssuance.step4.sectionTitle'}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            subText={
                                canEditIssuances || creatingIssuance
                                    ? 'editIssuance.step4.sectionDescription'
                                    : ''
                            }
                            buttonIsLight={true}
                            buttonFunction={showModal}
                            button={
                                getResponsive(1320)
                                    ? {
                                          label: 'general.showPreview',
                                          disabled: false,
                                      }
                                    : undefined
                            }
                            className={style.formHeader}
                        />

                        {!!selected?.length ? (
                            <div className={style.panelContainer}>
                                <div
                                    className={`${style.panelList} ${cx(
                                        'marginTop32'
                                    )}`}
                                >
                                    {selected?.map((credential, index) => (
                                        <RequirementAndPurposeCard
                                            index={index}
                                            credential={credential}
                                            onChangeSwitchSelect={
                                                setCredentialsMandatoryValues
                                            }
                                            indexCheckbox={credential.id}
                                            key={
                                                'editableCard' +
                                                index.toString()
                                            }
                                            checkedValue={
                                                !!credentials[index]?.mandatory
                                                    ? 'required'
                                                    : 'optional'
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <CardListSkeleton
                                key={'credentials__skeleton'}
                                className={style.credentialsSkeleton}
                                cardsNumber={1}
                            />
                        )}
                        {getResponsive(1320) ? (
                            <div className={style.buttonContainer}>
                                <DarkButton
                                    text={
                                        canEditIssuances && !creatingIssuance
                                            ? 'public.save'
                                            : 'editIssuance.sectionButton'
                                    }
                                    disabled={
                                        (!creatingIssuance &&
                                            !formDataModified) ||
                                        !validForm
                                    }
                                    functionality={saveAndNext}
                                />
                            </div>
                        ) : null}
                    </LightPanel>
                </div>
            </div>
            <CredentialsRequirementsPreview
                saveButton={{
                    label:
                        canEditIssuances && !creatingIssuance
                            ? 'public.save'
                            : 'editIssuance.sectionButton',
                    disabled:
                        (!creatingIssuance && !formDataModified) || !validForm,
                    function: saveAndNext,
                }}
                requiredCredentials={requiredCredentials}
                optionalCredentials={optionalCredentials}
                showSaveButton={canEditIssuances || creatingIssuance}
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
                issuanceTemplate
            />
        </>
    )
}

export default CredentialsRequirementsForm
