import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    CredentialIcons,
    gaImages,
} from '../../../../../assets/globalConstants'
import { initialLang } from '../../../../../i18n'
import { ICredentialRequestedModel } from '../../../../../interfaces/interfaces'
import GaCheckbox from '../../../../atoms/formFields/checkbox/GaCheckbox'
import style from './editableAssuranceCard.module.scss'

type IEditableAssuranceCardProps = {
    canEdit?: boolean
    credential: ICredentialRequestedModel
    indexCheckbox?: string | number | undefined
    handleSelectChange: (index: number, value: number) => void
    checkedValue: boolean
    index: number
}

const EditableAssuranceCard: React.FC<IEditableAssuranceCardProps> = (
    props
) => {
    const {
        credential,
        canEdit,
        indexCheckbox,
        handleSelectChange,
        checkedValue,
        index,
    } = props
    const { t } = useTranslation()

    return (
        <div
            className={style.editableAssuranceCardContainer}
            key={credential?.type}
        >
            <div className={style.editableAssuranceCard}>
                <p
                    className={cx(
                        'buttonSM neutral1000',
                        style.editableAssuranceCard__name
                    )}
                >
                    <img
                        className={style.editableAssuranceCard__name__icon}
                        src={
                            CredentialIcons[
                                credential?.groupList
                                    ? credential?.groupList[0]
                                    : 'credential'
                            ] || CredentialIcons?.credential
                        }
                        alt={t(gaImages.credential.alt)}
                    />

                    {t(
                        credential.name
                            ? credential.name[initialLang] ||
                                  credential.name['en']
                            : ''
                    )}
                </p>

                <div className={style.editableAssuranceCard__options}>
                    <div
                        className={style.editableAssuranceCard__options__select}
                    >
                        <GaCheckbox
                            className={cx('widthFitContent')}
                            itemText={t('editVerifier.trusted')}
                            index={indexCheckbox}
                            disabled={!canEdit}
                            checked={checkedValue}
                            onChange={(e) => {
                                handleSelectChange(index, e)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditableAssuranceCard
