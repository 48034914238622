import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
    ButtonModel,
    ImageModel,
} from '../../../../../../interfaces/interfaces'
import LightButton from '../../../../../atoms/buttons/LightButton/LightButton'
import styles from './successFilledPanel.module.scss'
import { ReactComponent as CloseIcon } from '../../../../../../assets/images/close.svg'
import CheckCircleIcon from '../../../../../../assets/iconsLibrary/simple/checkCircle/checkCircleIcon'
import cx from 'classnames'

type ISuccessFilledPanelProps = {
    title?: string
    titleExtraTranslationParams?: Object
    label: string
    label2?: string
    label3?: string
    hasIcon?: boolean
    hasTitle?: boolean
    icon?: ImageModel
    loadingAnimation?: boolean
    rightButton?: ButtonModel
    leftButton?: ButtonModel
    className?: string
    closeButton?: {
        action: (x?) => void
    }
}

const SuccessFilledPanel: React.FC<ISuccessFilledPanelProps> = (props) => {
    const {
        title,
        titleExtraTranslationParams,
        label,
        rightButton,
        leftButton,
        label2,
        label3,
        hasIcon,
        hasTitle,
        icon,
        closeButton,
        className,
        loadingAnimation,
    } = props
    const { t } = useTranslation()
    useEffect(() => {}, [label])

    return (
        <div
            className={`${styles.successFilledPanel} ${className && className}`}
        >
            {!loadingAnimation ? (
                <CheckCircleIcon color="#80CA8D" size={24} />
            ) : (
                <div className={styles.loading}></div>
            )}
            <div>
                <div className={styles.successFilledPanel__header}>
                    {title ? (
                        <p
                            className={`${
                                rightButton || leftButton
                                    ? ''
                                    : styles.fullWidth
                            } ${styles.title} ${cx('bodyBoldSM neutral1000')}`}
                        >
                            <span>
                                {!title ? (
                                    t('public.success')
                                ) : (
                                    <Trans
                                        i18nKey={title}
                                        values={{
                                            ...titleExtraTranslationParams,
                                        }}
                                    />
                                )}
                            </span>
                        </p>
                    ) : null}

                    {closeButton && (
                        <div onClick={closeButton?.action}>
                            <CloseIcon />
                        </div>
                    )}
                </div>

                <div className={styles.successFilledPanel__content}>
                    <div
                        className={`${
                            rightButton || leftButton ? '' : styles.fullWidth
                        } ${styles.successFilledPanel__content__texts}`}
                    >
                        <p className={cx('bodyRegularSM neutral1000')}>
                            <Trans i18nKey={label} />
                        </p>
                        <p className={cx('bodyRegularSM neutral1000')}>
                            {label2 && <Trans i18nKey={label2} />}
                        </p>
                        <p className={cx('bodyRegularSM neutral1000')}>
                            {label3 && <Trans i18nKey={label3} />}
                        </p>
                    </div>
                    <div
                        className={styles.successFilledPanel__content__buttons}
                    >
                        {leftButton && (
                            <LightButton
                                className={styles.button}
                                text={leftButton.label}
                                disabled={false}
                                functionality={leftButton.function}
                            />
                        )}
                        {rightButton && (
                            <LightButton
                                className={styles.button}
                                text={rightButton.label}
                                disabled={false}
                                functionality={rightButton.function}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessFilledPanel
