import React from 'react'
import styles from './copyCell.module.scss'
import cx from 'classnames'
import { t } from 'i18next'
import { gaColors } from '../../../../../assets/globalConstants'
import CopyIcon from '../../../../../assets/iconsLibrary/simple/copy/copyIcon'

type CopyCellProps = {
    item: any
    mainData?: string
    mainDataClickable?: boolean
    textToCopyDesc?: string
    textToCopy?: string
    textToCopyDisplayValue?: string
    className?: string
    selectItem?: (x?) => void
}

const CopyCell: React.FC<CopyCellProps> = (props) => {
    const {
        item,
        mainData,
        textToCopyDesc,
        mainDataClickable,
        textToCopy,
        textToCopyDisplayValue,
        className,
        selectItem,
    } = props

    return (
        <td className={cx(styles.copyCellContainer, className && className)}>
            <div>
                {mainData ? (
                    <p
                        onClick={() =>
                            mainDataClickable && selectItem
                                ? selectItem(item)
                                : {}
                        }
                        className={cx(
                            'buttonSM neutral1000 width100',
                            styles.copyCellMainData,
                            mainDataClickable && styles.clickable
                        )}
                    >
                        {mainData}
                    </p>
                ) : null}
                {!!textToCopy ? (
                    <>
                        <p className={cx('bodyRegularSM neutral700 width100')}>
                            {textToCopyDesc ? t(textToCopyDesc) : null}
                            {textToCopyDisplayValue
                                ? textToCopyDisplayValue
                                : textToCopy}
                            <span
                                className={styles.copyCell__button}
                                data-before-content={t('public.copy')}
                                data-after-content={t('public.copied')}
                                onClick={() => {
                                    navigator?.clipboard?.writeText(textToCopy)
                                }}
                            >
                                <CopyIcon
                                    color={gaColors.neutral700}
                                    size={16}
                                />
                            </span>
                        </p>
                    </>
                ) : null}
            </div>
        </td>
    )
}

export default CopyCell
