import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import { ButtonModel } from '../../../../../interfaces/interfaces'
import { setSelectedCredentials } from '../../issuanceActions'
import {
    formatRequestedCredentials,
    getFormattedRequestedCredentials,
    getSelectedIssuance,
    returnOriginalRequestedCredential,
} from '../../issuanceSelectors'
import style from './credentialsForm.module.scss'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import TemplatesFormHeader from '../../../../common/TemplatesHeader/TemplatesFormHeader'
import { issuanceSelectors } from '../..'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import { dynamicSort } from '../../../../../utils/credentialsUtils'
import CredentialsPreview from '../../../../../Components/molecules/modals/CredentialPreview/components/StepsContentPreview/CredentialsPreview'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import CredentialSelection from './searchAndSelect/CredentialSelection'
import { getLoadingStatus } from '../../../../common/catalog/catalogSelectors'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { getResponsive } from '../../../../../utils/formUtils'

type ICredentialsFormProps = {
    canEditIssuances?: boolean
    creatingIssuance?: boolean
    buttonHeader?: ButtonModel
    infoApiKeyPanel?: boolean
    activeStep: any
    formSteps: any
    buttonFunctionHeader?: (x) => void
    buttonFunction: (x) => void
    setValidForm: (x) => void
    onHandleChange: (step) => void
}

const CredentialsForm: React.FC<ICredentialsFormProps> = (props) => {
    const {
        canEditIssuances,
        creatingIssuance,
        buttonHeader,
        infoApiKeyPanel,
        activeStep,
        formSteps,
        buttonFunction,
        buttonFunctionHeader,
        setValidForm,
        onHandleChange,
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // Selectors

    const updateStatus = useSelector(issuanceSelectors.getUpdateStatus)
    const selected = useSelector(getFormattedRequestedCredentials)
    const credTypesList = useSelector(issuanceSelectors.getOriginalCatalogTypes)
    const catalogLoadingStatus = useSelector(getLoadingStatus)
    const catalogIsLoading = !(
        catalogLoadingStatus?.status === 'finished' ||
        catalogLoadingStatus?.status === 'error'
    )
    const initialRequestedUnfCred = useSelector(
        issuanceSelectors.getInitialRequestedUnfCredentials
    )
    const currentRequestedUnfCred = useSelector(
        issuanceSelectors.getRequestedUnfCredentials
    )
    const step = useSelector(issuanceSelectors.getActiveStep)
    const issuance = useSelector(getSelectedIssuance)

    // States

    const [credentials, setSelection] = React.useState(
        formatRequestedCredentials(credTypesList, currentRequestedUnfCred) || []
    )

    const [formDataModified, setFormDataModified] = useState(false)

    // Consts
    const validForm = !!credentials.length
    const sortedInitialRequestedCred =
        initialRequestedUnfCred &&
        returnOriginalRequestedCredential(initialRequestedUnfCred)?.sort(
            dynamicSort('id')
        )
    const sortedInitialRequestedCredsIds = sortedInitialRequestedCred?.map(
        (el) => el?.id
    )
    const sortedCurrentRequestedCred = currentRequestedUnfCred?.sort(
        dynamicSort('id')
    )
    const sortedCurrentRequestedCredsIds = sortedCurrentRequestedCred?.map(
        (el) => el?.id
    )

    // Effects
    useEffect(() => {
        if (
            updateStatus === ActionStatusTypes?.success &&
            JSON.stringify(sortedInitialRequestedCredsIds) ===
                JSON.stringify(sortedCurrentRequestedCredsIds)
        ) {
            setFormDataModified(false)
        }
    }, [updateStatus])

    useEffect(() => {
        scrollToTop()
        setSelection(
            formatRequestedCredentials(credTypesList, currentRequestedUnfCred)
        ),
            setValidForm(selected && !!selected[0])
    }, [step])

    useEffect(() => {
        setSelection(selected), setValidForm(selected && !!selected[0])
    }, [selected, currentRequestedUnfCred])

    useEffect(() => {
        if (
            JSON.stringify(sortedInitialRequestedCredsIds) ===
            JSON.stringify(sortedCurrentRequestedCredsIds)
        ) {
            setFormDataModified(false)
        } else {
            setFormDataModified(true)
        }
    }, [credentials])

    // Functions

    const setCredentialsSelection = (credentials) => {
        dispatch(setSelectedCredentials(credentials))
        setSelection(credentials)
        setValidForm(credentials && !!credentials[0]?.length)
    }

    const saveAndNext = () => {
        buttonFunction({
            prConfig: { ...issuance.prConfig, credentials: credentials },
        })
        scrollToTop()
    }

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    // Responsive preview

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    return (
        <>
            {' '}
            <div className={style.leftColumn}>
                <div className={style.headerStepper}>
                    <TemplatesFormHeader
                        title={
                            creatingIssuance
                                ? 'createIssuance.title'
                                : t('editIssuance.title', {
                                      id: issuance?.id || '',
                                  })
                        }
                        description={
                            creatingIssuance
                                ? 'createIssuance.description'
                                : 'editIssuance.description'
                        }
                        buttonFunctionHeader={buttonFunctionHeader}
                        buttonHeader={buttonHeader}
                        formSteps={formSteps}
                        elementsAllignment={creatingIssuance ? false : true}
                        infoApiKeyPanel={infoApiKeyPanel}
                        titleInfoPanel={t('editIssuance.addApiKeyInfo')}
                        onHandleChange={onHandleChange}
                        activeStep={activeStep}
                        loadingProgressBar={catalogIsLoading}
                        inactiveSteps={
                            !creatingIssuance &&
                            !issuance?.prConfig?.credentials?.length
                                ? [3, 4]
                                : undefined
                        }
                    />
                </div>
                <div className={style.form}>
                    <LightPanel>
                        <ScreenHeader
                            title={'editIssuance.step3.sectionTitle'}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            subText={
                                canEditIssuances || creatingIssuance
                                    ? 'editIssuance.step3.sectionDescription'
                                    : ''
                            }
                            className={style.formHeader}
                            buttonFunction={showModal}
                            button={
                                getResponsive(1320)
                                    ? {
                                          label: 'general.showPreview',
                                          disabled: false,
                                      }
                                    : undefined
                            }
                            buttonIsLight={true}
                        />
                        {credTypesList?.length ? (
                            <div className={style.panelContainer}>
                                {(!canEditIssuances ||
                                    canEditIssuances ||
                                    creatingIssuance) && (
                                    <>
                                        <div className={style.panelList}>
                                            <CredentialSelection
                                                setSelectedCredentials={
                                                    setCredentialsSelection
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <CardListSkeleton
                                key={'credentials__skeleton'}
                                className={style.credentialsSkeleton}
                                cardsNumber={1}
                            />
                        )}
                        {getResponsive(1320) ? (
                            <div className={style.buttonContainer}>
                                <DarkButton
                                    text={
                                        canEditIssuances && !creatingIssuance
                                            ? 'public.save'
                                            : 'editIssuance.sectionButton'
                                    }
                                    disabled={
                                        !validForm ||
                                        (!creatingIssuance && !formDataModified)
                                    }
                                    functionality={saveAndNext}
                                />
                            </div>
                        ) : null}
                    </LightPanel>
                </div>
            </div>
            <CredentialsPreview
                saveButton={{
                    label:
                        canEditIssuances && !creatingIssuance
                            ? 'public.save'
                            : 'editIssuance.sectionButton',
                    disabled:
                        !validForm || (!creatingIssuance && !formDataModified),
                    function: saveAndNext,
                }}
                showSaveButton={canEditIssuances || creatingIssuance}
                data={credentials}
                issuanceTemplate
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
            />
        </>
    )
}

export default CredentialsForm
