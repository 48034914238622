import React from 'react'

type smartphoneIconProps = {
    size?: number
    color?: string
    className?: string
}

const SmartphoneIcon: React.FC<smartphoneIconProps> = React.memo((props) => {
    const { size, color, className } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                d="M22.6665 2.66667H9.33317C7.86041 2.66667 6.6665 3.86058 6.6665 5.33334V26.6667C6.6665 28.1394 7.86041 29.3333 9.33317 29.3333H22.6665C24.1393 29.3333 25.3332 28.1394 25.3332 26.6667V5.33334C25.3332 3.86058 24.1393 2.66667 22.6665 2.66667Z"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 24H16.0125"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})

export default SmartphoneIcon
