import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LightPanel from '../../../../Components/atoms/panels/LightPanel/LightPanel'
import ScreenHeader from '../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import { userHasScopes } from '../../../../utils/permissionsUtil'

import { sessionSelectors } from '../../session'
import { getLicenseType } from '../../session/sessionSelectors'
import {
    clearActionStatus,
    clearUser,
    clearUserList,
    getUsers,
} from '../tabsContent/users/store/usersActions'

import {
    headerRolesButton,
    headerUsersButton,
    rolesScopes,
    usersScopes,
} from '../tabsContent/users/store/team.constants'
import styles from './team.module.scss'
import { onPremise } from '../../../../data/globalVar'
import MediumModal from '../../../../Components/molecules/modals/Medium/MediumModal'
import Tabs from '../../../../Components/Tabs/Tabs'
import CreateUser from '../tabsContent/users/components/createUser/CreateUser'
import Users from '../tabsContent/users/views/Users'
import { Roles } from '../tabsContent/roles/views/Roles'
import UpsertRole from '../tabsContent/roles/components/upsertRole/UpsertRole'
import { rolesSelectors } from '../tabsContent/roles/store'
import { gaColors } from '../../../../assets/globalConstants'

export const Team: React.FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    // States
    const [newUser, createNewUser] = React.useState<boolean>(false)
    const [newRole, createNewRole] = React.useState<boolean>(false)

    // Selectors
    const scopes = useSelector(rolesSelectors.getScopesSelectables)
    const currentLicenseType: string = useSelector(getLicenseType)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    // Consts
    const canCreateUsers = !!(
        availableScopes && userHasScopes(usersScopes?.create, availableScopes)
    )

    const canCreateRoles = !!(
        availableScopes && userHasScopes(rolesScopes?.create, availableScopes)
    )

    const createRolesDisabled =
        (currentLicenseType?.toLowerCase()?.includes('starter') ||
            currentLicenseType?.toLowerCase()?.includes('essential')) &&
        !onPremise

    const canReadUsers = !!(
        availableScopes && userHasScopes(usersScopes?.read, availableScopes)
    )

    // Functions
    const showNewUser = () => {
        createNewUser(true)
    }

    const showNewRole = () => {
        createNewRole(true)
    }

    const hideNewUser = () => {
        clearState()
        createNewUser(false)
    }

    const clearState = () => {
        dispatch(clearUser())
        dispatch(clearActionStatus())
        canReadUsers && dispatch(getUsers())
    }

    // Tab Sections
    const detailSections = [
        {
            id: '1',
            tabTitle: t('users.screenTitle'),
            tabContent: (
                <Users
                    canReadUsers={canReadUsers}
                    clearState={clearState}
                    newUser={newUser}
                />
            ),
            button: canCreateUsers
                ? {
                      text: t(headerUsersButton?.label),
                      disabled: false,
                      functionality: showNewUser,
                  }
                : undefined,
        },
        {
            id: '2',
            tabTitle: t('roles.screenTitle'),
            tabContent: <Roles />,
            button: canCreateRoles
                ? {
                      text: t(headerRolesButton?.label),
                      functionality: showNewRole,
                      disabled: createRolesDisabled,
                      disabledTooltip: createRolesDisabled,
                      disabledTooltipLabel: t(
                          'public.upgradeSubscriptionToAccess'
                      ),
                      tooltipIconColor: gaColors.neutral100,
                  }
                : undefined,
        },
    ]

    return (
        <>
            <ScreenHeader
                title={'team.title'}
                subText={t('profile.description')}
                hideBorderBottom={true}
            />
            <LightPanel className={styles.formLightPanel}>
                <Tabs sections={detailSections} />
                {newUser && (
                    <MediumModal
                        title={'createUser.inviteUser'}
                        subtitle={t('createUser.sectionDescription')}
                        headerClose={true}
                        show={newUser}
                        handleClose={hideNewUser}
                    >
                        <CreateUser hideNewUser={hideNewUser} />
                    </MediumModal>
                )}
                {newRole && scopes && (
                    <MediumModal
                        title={'roles.newRole'}
                        headerClose={true}
                        show={newRole}
                        handleClose={() => {
                            createNewRole(false)
                        }}
                    >
                        <UpsertRole
                            isNewRol
                            hideCreateRoleModal={() => createNewRole(false)}
                        />
                    </MediumModal>
                )}
            </LightPanel>
        </>
    )
}
