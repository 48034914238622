import { initialLang } from '../../../i18n'
import { EndsStepsModel } from '../../../interfaces/interfaces'
import { RootState } from '../../../store/indexReducers'
// List
export const isLoading = (state: RootState) =>
    state.credentialsReducer.loadingStatus
export const isIssuingCredentialLoading = (state: RootState) =>
    state.credentialsReducer?.issuingCredential
export const getCredentials = (state: RootState) => {
    return {
        credentials: getNoDeletedCredentials(
            state.credentialsReducer.issuedCredentialsResponse?.items
        ),
        data: state.credentialsReducer.issuedCredentialsResponse,
    }
}
export const getCredentialsError = (state: RootState) =>
    state.didListReducer.error
export const getIssuedCredentialsList = (state: RootState) =>
    getFormattedCredentials(
        state.credentialsReducer.issuedCredentialsResponse?.items,
        state.catalogReducer.credentialTypes
    )
export const getPendingCredentialsDataList = (state: RootState) =>
    state.credentialsReducer.pendingCredentias
export const getPendingCredentialsList = (state: RootState) =>
    getFormattedCredentials(
        state.credentialsReducer.pendingCredentias?.items,
        state.catalogReducer.credentialTypes
    )
export const getSelectedCredential = (state: RootState) =>
    state.credentialsReducer.selectedCredential

// Form steps
export const getActiveStep = (state: RootState) =>
    state.configCredentialFormReducer.step
export const getRegisterSteps = (state: RootState) =>
    state.configCredentialFormReducer.steps
export const getLastStep = (state: RootState) =>
    state.configCredentialFormReducer.finalStep
export const getEndsSteps = (state: RootState) => {
    const steps = state.configCredentialFormReducer.steps
    const activeStep = state.configCredentialFormReducer.step
    return activeStep && steps?.length
        ? getPreviousAndNextSteps(steps, activeStep)
        : null
}

// Functions

const getNoDeletedCredentials = (credentials) =>
    credentials?.filter((cred) => cred?.data?.status !== 'DELETED')

const getFormattedCredentials = (credentials, credentialTypes) => {
    const existingCredentials = getNoDeletedCredentials(credentials)

    return (
        existingCredentials?.slice()?.map((el) => {
            let newElements: any = new Array()
            const type = el.data?.data ? el.data?.data[0]?.type : []

            type?.map((element) => {
                let formattedType = credentialTypes?.find((i) => {
                    if (i?.id === element) {
                        return i
                    }
                })
                newElements.push(
                    formattedType?.name[initialLang]
                        ? formattedType?.name[initialLang]
                        : formattedType?.name['en'] || element
                )
                el.typeNames = newElements
            })

            return el
        }) || existingCredentials
    )
}

const getPreviousAndNextSteps = (steps, activeStep): EndsStepsModel => {
    let nextStep
    let previousStep

    const lastStep = steps[steps.length]
    const nextIndex = steps.indexOf(activeStep) + 1
    const previousIndex = steps.indexOf(activeStep) - 1
    nextStep = activeStep !== lastStep ? steps[nextIndex] : null
    previousStep = activeStep !== steps[0] ? steps[previousIndex] : null

    return { previousStep, nextStep }
}
