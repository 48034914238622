import { ActionStatusTypes } from '../../../assets/globalConstants'
import { ILoginState, LoginActions, LoginActionTypes } from './loginTypes'

const initialLoginState: ILoginState = {
    loading: {
        status: 'inactive',
        description: '',
    },
    loggedIn: false,
    signUpStatus: ActionStatusTypes.neutral,
}

export const loginReducer = (
    state: ILoginState = initialLoginState,
    action: LoginActions
): ILoginState => {
    switch (action.type) {
        case LoginActionTypes.EMAIL_SIGN_UP:
            return {
                ...state,
                signUpStatus: ActionStatusTypes.neutral,
            }
        case LoginActionTypes.EMAIL_SIGN_UP_SUCCESS:
            return {
                ...state,
                signUpStatus: ActionStatusTypes.success,
            }
        case LoginActionTypes.EMAIL_SIGN_UP_FAILED:
            return {
                ...state,
                signUpStatus: ActionStatusTypes.failed,
            }
        case LoginActionTypes.SET_EMAIL_SIGNING_UP:
            return {
                ...state,
                signUpStatus: ActionStatusTypes.neutral,
            }
        case LoginActionTypes.SUBMIT_LOGIN:
            return {
                ...state,
                loading: {
                    status: 'active',
                    description: 'Sending',
                },
            }
        case LoginActionTypes.CHECK_LOGIN:
            return {
                ...state,
                loading: {
                    status: 'inactive',
                    description: '',
                },
            }
        case LoginActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loading: {
                    status: 'finished',
                    description: 'Login Success',
                },
                loggedIn: true,
            }
        case LoginActionTypes.LOGIN_FAILED:
            return {
                ...state,
                loading: {
                    status: 'error',
                    description: 'Something went wrong',
                },
                loggedIn: false,
            }
        case LoginActionTypes.LOGIN_DONE:
            return {
                ...state,
                loading: {
                    status: 'inactive',
                    description: '',
                },
            }
        case LoginActionTypes.QR_SIGNUP_SUCCESS:
            return {
                ...state,
                signUpStatus: ActionStatusTypes.success,
            }
        default:
            return state
    }
}
