import cx from 'classnames'
import React from 'react'
import styles from './importCredentialCard.module.scss'
import { gaColors } from '../../../../../assets/globalConstants'
import CredentialIcon from '../../../../../assets/iconsLibrary/credential/credentialIcon'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes, LoaderSizes } from '../../../../../data/globalVar'
import AnimatedLoader from '../../../../../Components/loader/animatedLoader/AnimatedLoader'
import { t } from 'i18next'

type IImportCredentialCardProps = {
    loading?: boolean
    fileName?: string
    setFile: (x?) => void
}

const ImportCredentialCard: React.FC<IImportCredentialCardProps> = (props) => {
    const { loading, fileName, setFile } = props

    return (
        <div className={styles.importCredentialCard}>
            {!loading ? (
                <>
                    <CredentialIcon color={gaColors.neutral700} />
                    <div className={styles.importCredentialCard__body}>
                        <div
                            className={
                                styles.importCredentialCard__body__import
                            }
                        >
                            <DarkButton
                                className={styles.button}
                                text={t('ew.requestCredentials.importCred')}
                                disabled={false}
                                size={ButtonSizes.SM}
                            />
                            <input
                                className={styles.input}
                                type="file"
                                accept="text/plain"
                                onChange={(e) => {
                                    setFile(e)
                                }}
                            />
                        </div>
                    </div>
                    <p className={cx('bodyRegularCap neutral700')}>
                        {t('ew.requestCredentials.importCredFormats')}
                    </p>{' '}
                </>
            ) : (
                <div className={styles.loading}>
                    <AnimatedLoader size={LoaderSizes.MD} />
                    <div>
                        <p className="bodyBoldSM neutral1000">
                            {t('ew.requestCredentials.importingCred')}
                        </p>
                        {fileName ? (
                            <p className="bodyRegularSM neutral700 marginTop2">
                                {fileName}
                            </p>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ImportCredentialCard
