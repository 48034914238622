import { EWCredential, IEWNotificationModel } from '../../interfaces/interfaces'

import {
    NotificationsActionsTypes,
    IDownloadCredentialAction,
    IDownloadCredentialFailed,
    IDownloadCredentialSuccessAction,
    IDeleteNotification,
    IDeleteNotificationSuccess,
    IDeleteNotificationFailed,
    IClearNotificationsList,
    IGetUnreadNotificationsAction,
    IGetUnreadNotificationsFailed,
    IGetUnreadNotificationsSuccessAction,
    ISetReadedNotifications,
    ISetReadedNotificationsNotSeen,
    IStartNotificationsPollingAction,
    IStopNotificationsPollingAction,
} from './notificationsTypes'

// LIST
export const getUnreadNotifications = (
    profile: string
): IGetUnreadNotificationsAction => {
    return {
        type: NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS,
        payload: {
            profile,
        },
    }
}

export const getUnreadNotificationsSuccess = (
    notifications: IEWNotificationModel[]
): IGetUnreadNotificationsSuccessAction => {
    return {
        type: NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS,
        payload: {
            notifications,
        },
    }
}

export const getUnreadNotificationsFailed = (
    error: string
): IGetUnreadNotificationsFailed => {
    return {
        type: NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const downloadCredential = (
    profile: string,
    id: string
): IDownloadCredentialAction => {
    return {
        type: NotificationsActionsTypes.DOWNLOAD_CREDENTIAL,
        payload: {
            profile,
            id,
        },
    }
}

export const downloadCredentialSuccess = (
    credential: EWCredential
): IDownloadCredentialSuccessAction => {
    return {
        type: NotificationsActionsTypes.DOWNLOAD_CREDENTIAL_SUCCESS,
        payload: {
            credential,
        },
    }
}

export const downloadCredentialFailed = (
    error: string
): IDownloadCredentialFailed => {
    return {
        type: NotificationsActionsTypes.DOWNLOAD_CREDENTIAL_FAILED,
        payload: {
            error: error,
        },
    }
}

export const deleteNotification = (
    profile: string,
    id: string
): IDeleteNotification => {
    return {
        type: NotificationsActionsTypes.DELETE_NOTIFICATION,
        payload: {
            profile,
            id,
        },
    }
}

export const deleteNotificationSuccess = (
    credential: IEWNotificationModel
): IDeleteNotificationSuccess => {
    return {
        type: NotificationsActionsTypes.DELETE_NOTIFICATION_SUCCESS,
    }
}

export const deleteNotificationFailed = (
    error: string
): IDeleteNotificationFailed => {
    return {
        type: NotificationsActionsTypes.DELETE_NOTIFICATION_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearNotificationsList = (): IClearNotificationsList => {
    return {
        type: NotificationsActionsTypes.CLEAR_NOTIFICATIONS_LIST,
    }
}

export const setReadedNotifications = (
    readedNotifications?: IEWNotificationModel[]
): ISetReadedNotifications => {
    return {
        type: NotificationsActionsTypes.SET_READ_NOTIFICATIONS,
        payload: { readedNotifications },
    }
}

export const setReadedNotificationsNotSeen = (
    readedNotifications?: IEWNotificationModel[]
): ISetReadedNotificationsNotSeen => {
    return {
        type: NotificationsActionsTypes.SET_READ_NOTIFICATIONS_NOT_SEEN,
        payload: { readedNotifications },
    }
}

export function startNotificationsPolling(
    interval: number,
    fetch: any
): IStartNotificationsPollingAction {
    return {
        type: NotificationsActionsTypes.START_NOTIFICATIONS_POLLING,
        payload: {
            interval,
            fetch,
        },
    }
}

export function stopNotificationsPolling(): IStopNotificationsPollingAction {
    return {
        type: NotificationsActionsTypes.STOP_NOTIFICATIONS_POLLING,
    }
}
