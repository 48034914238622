import {
    ICredentialsActivityModel,
    ICredentialsItemModel,
    IIssuancePaginatedResponseModel,
    StepModel,
} from '../../../interfaces/interfaces'
import {
    ClearCredentialFormState,
    ClearCredentialList,
    ConfigCredentialActionsTypes,
    ConfigCredentialFormActionsTypes,
    CredentialListActionsTypes,
    CredentialsListActions,
    IChangeCredentialStatusAction,
    IClearActionStatus,
    IClearCredential,
    IDeleteCredentialAction,
    IDeleteCredentialFailed,
    IDeleteCredentialSuccessAction,
    IEditCredential,
    IGetCredentialsFailed,
    IGetCredentialsSuccessAction,
    IGetPendingCredentialsFailed,
    IGetPendingCredentialsSuccessAction,
    IIssueCredentialAction,
    IIssueCredentialFailed,
    IIssueCredentialSuccessAction,
    IRejectCredentialAction,
    IRejectCredentialFailed,
    IRejectCredentialSuccessAction,
    ISelectCredentialAction,
    IUpdateCredentialAction,
    IUpdateCredentialFailedAction,
    IUpdateCredentialSuccessAction,
    SetActiveStepAction,
    SetStepsAction,
} from './credentialsTypes'

// LIST
export const getCredentials = (
    page: number,
    size: number,
    url?: string
): CredentialsListActions => {
    return {
        type: CredentialListActionsTypes.GET_CREDENTIALS,
        payload: {
            page,
            size,
            url,
        },
    }
}

export const getCredentialsSuccess = (
    data: IIssuancePaginatedResponseModel
): IGetCredentialsSuccessAction => {
    const { items, links, pageSize, self, total } = data

    return {
        type: CredentialListActionsTypes.GET_CREDENTIALS_SUCCESS,
        payload: {
            items,
            links,
            pageSize,
            self,
            total,
        },
    }
}

export const getCredentialsFailed = (error: string): IGetCredentialsFailed => {
    return {
        type: CredentialListActionsTypes.GET_CREDENTIALS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const selectCredential = (
    credential: ICredentialsActivityModel
): ISelectCredentialAction => {
    return {
        type: CredentialListActionsTypes.SELECT_CREDENTIAL,
        payload: {
            credential: credential,
        },
    }
}

export const deleteCredentialSuccess = (
    credentials: ICredentialsItemModel[]
): IDeleteCredentialSuccessAction => {
    return {
        type: CredentialListActionsTypes.DELETE_CREDENTIAL_SUCCESS,
    }
}

export const deleteCredentialFailed = (
    error: string
): IDeleteCredentialFailed => {
    return {
        type: CredentialListActionsTypes.DELETE_CREDENTIAL_FAILED,
        payload: {
            error: error,
        },
    }
}

export const deleteCredential = (
    credential: ICredentialsItemModel
): IDeleteCredentialAction => {
    return {
        type: CredentialListActionsTypes.DELETE_CREDENTIAL,
        payload: {
            credential: credential,
        },
    }
}

export const getPendingCredentials = (
    page: number,
    size: number,
    url?: string
): CredentialsListActions => {
    return {
        type: CredentialListActionsTypes.GET_PENDING_CREDENTIALS,
        payload: {
            page,
            size,
            url,
        },
    }
}

export const getPendingCredentialsSuccess = (
    data: IIssuancePaginatedResponseModel
): IGetPendingCredentialsSuccessAction => {
    const { items, links, pageSize, self, total } = data

    return {
        type: CredentialListActionsTypes.GET_PENDING_CREDENTIALS_SUCCESS,
        payload: {
            items,
            links,
            pageSize,
            self,
            total,
        },
    }
}

export const getPendingCredentialsFailed = (
    error: string
): IGetPendingCredentialsFailed => {
    return {
        type: CredentialListActionsTypes.GET_PENDING_CREDENTIALS_FAILED,
        payload: {
            error: error,
        },
    }
}

export function issueCredential(
    id: string,
    subjectDID: string,
    credentials,
    credType: string,
    isMulti?: boolean,
    expirationDate?: string
): IIssueCredentialAction {
    return {
        type: CredentialListActionsTypes.ISSUE_CREDENTIAL,
        payload: {
            id: id,
            subjectDID: subjectDID,
            credentials: credentials,
            credType: credType,
            isMulti: isMulti,
            expirationDate: expirationDate,
        },
    }
}

export function changeCredentialStatus(
    id: string,
    status: string,
    pageIndex: number,
    pageSize: number
): IChangeCredentialStatusAction {
    return {
        type: CredentialListActionsTypes.CHANGE_CREDENTIAL_STATUS,
        payload: {
            id,
            status,
            pageIndex,
            pageSize,
        },
    }
}

export const issueCredentialSuccess = (): IIssueCredentialSuccessAction => {
    return {
        type: CredentialListActionsTypes.ISSUE_CREDENTIAL_SUCCESS,
    }
}

export const issueCredentialFailed = (
    error: string
): IIssueCredentialFailed => {
    return {
        type: CredentialListActionsTypes.ISSUE_CREDENTIAL_FAILED,
        payload: {
            error: error,
        },
    }
}

export function rejectCredential(id: string): IRejectCredentialAction {
    return {
        type: CredentialListActionsTypes.REJECT_CREDENTIAL,
        payload: {
            id: id,
        },
    }
}

export const rejectCredentialSuccess = (): IRejectCredentialSuccessAction => {
    return {
        type: CredentialListActionsTypes.REJECT_CREDENTIAL_SUCCESS,
    }
}

export const rejectCredentialFailed = (
    error: string
): IRejectCredentialFailed => {
    return {
        type: CredentialListActionsTypes.REJECT_CREDENTIAL_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearCredentialList = (): ClearCredentialList => {
    return {
        type: CredentialListActionsTypes.CLEAR_CREDENTIAL_LIST,
    }
}

// FORM STEPS
export const setSteps = (steps: StepModel[]): SetStepsAction => {
    return {
        type: ConfigCredentialFormActionsTypes.SETSTEPS,
        payload: {
            steps: steps,
        },
    }
}

export const setActiveStep = (step: StepModel): SetActiveStepAction => {
    return {
        type: ConfigCredentialFormActionsTypes.SETSTEP,
        payload: {
            step: step,
        },
    }
}

export const clearFormState = (): ClearCredentialFormState => {
    return {
        type: ConfigCredentialFormActionsTypes.CLEAR_FORM_STEP,
    }
}

export const editCredential = (
    credential: ICredentialsItemModel,
    token: string
): IEditCredential => {
    return {
        type: ConfigCredentialActionsTypes.EDIT_CREDENTIAL,
        payload: {
            credential: credential,
            token: token,
        },
    }
}

export const clearCredential = (): IClearCredential => {
    return {
        type: ConfigCredentialActionsTypes.CLEAR_CURRENT_CREDENTIAL,
    }
}

export const updateCredential = (
    newConfig: ICredentialsItemModel,
    credId: string
): IUpdateCredentialAction => {
    return {
        type: ConfigCredentialActionsTypes.UPDATE_CREDENTIAL,
        payload: {
            credential: newConfig,
            credId: credId,
        },
    }
}

export const updateCredentialSuccess = (): IUpdateCredentialSuccessAction => {
    return {
        type: ConfigCredentialActionsTypes.UPDATE_CREDENTIAL_SUCCESS,
    }
}

export const updateCredentialFailed = (
    err: any
): IUpdateCredentialFailedAction => {
    return {
        type: ConfigCredentialActionsTypes.UPDATE_CREDENTIAL_FAILED,
        payload: {
            error: err,
        },
    }
}

export const clearActionStatus = (): IClearActionStatus => {
    return {
        type: ConfigCredentialActionsTypes.CLEAR_ACTION_STATUS,
    }
}
