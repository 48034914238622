import angleForward from '../../../../assets/images/angle-forward.svg'
import { ButtonModel } from '../../../../interfaces/interfaces'

// FormPanel params
export const angleForwardIcon = angleForward
export const sendRecoveryButton: ButtonModel = {
    label: 'public.send',
    image: {
        src: angleForwardIcon,
        alt: 'public.angleForwardIcon',
    },
}
