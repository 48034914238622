import { onPremise } from '../data/globalVar'
import { ILoginItem } from '../features/common/Login/loginTypes'
import { StatisticsParams } from '../features/common/Statistics/statisticsTypes'
import { IChangeUserPasswords } from '../features/common/Team/tabsContent/users/store/usersTypes'
import {
    DA_Purpose,
    EWCredential,
    IAppIntegrationModel,
    ICreateDidModel,
    ICredentialsGroupModel,
    IDidsRespondeModel,
    IIssuanceConfigItemModel,
    ISimplifiedApiKeyModel,
    IUserItem,
    IVerifierConfigModel,
    MetricCode,
    PaymentMethodModel,
    RequestModel,
    UserProfile,
    UserSelectedCredentials,
} from '../interfaces/interfaces'
import { IGaasReq, gaasReq } from './networkService'

export default {
    // SIGN UP
    emailSignUp(user: ILoginItem) {
        const call = {
            method: 'post',
            url: '/admin/v1/gaas/signup',
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                email: user.username,
                password: user.password,
            },
        }
        return gaasReq(call)
    },
    getAuthData() {
        const call: IGaasReq = {
            url: '/admin/v1/auth',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }
        return gaasReq(call)
    },

    // LOGIN
    emailLogIn(user: ILoginItem) {
        const call: IGaasReq = {
            method: 'post',
            url: '/admin/v1/users/login',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Basic ' + btoa(user.username + ':' + user.password),
            },
        }
        return gaasReq(call)
    },
    logout() {
        const call: IGaasReq = {
            method: 'delete',
            url: '/admin/v1/users/logout',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    switchTenant(tenant: string) {
        const call = {
            method: 'post',
            url: '/admin/v1/users/login/switch',
            headers: {
                'Content-Type': 'application/json',
                tenant: tenant,
            },
        }
        return gaasReq(call)
    },
    authToken(token: string) {
        const call: IGaasReq = {
            url: '/token',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    isInit() {
        const call = {
            method: 'get',
            url: '/admin/v1/users/login',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    getRegistrationToken(tenant: string, role: string) {
        //TODO: Check when this is called. This endpoint doesn't exist in back. Can't be working.
        const call = {
            method: 'get',
            url: '/admin/v1/token/register?tenant=' + tenant + '&role=' + role,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },

    // ORGANIZATION
    getOrganization(orgID) {
        const call = {
            method: 'get',
            url: '/admin/v1/organizations/' + orgID,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },

    createOrganization(org) {
        const call = {
            method: 'post',
            url: '/admin/v1/organizations',
            headers: {
                'Content-Type': 'application/json',
            },
            body: org,
        }
        return gaasReq(call)
    },

    updateOrganization(org) {
        const call = {
            method: 'put',
            url: '/admin/v1/organizations/' + org?.id,
            headers: {
                'Content-Type': 'application/json',
            },
            body: org,
        }
        return gaasReq(call)
    },
    deleteOrganization(orgId: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/organizations/' + orgId,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    // USER
    getUserProfile(email: string) {
        const call: IGaasReq = {
            url: '/admin/v1/users/profile/' + email,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    changeUserProfile(profile: UserProfile) {
        const call: IGaasReq = {
            url: '/admin/v1/users/profile/' + profile.email,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: profile,
        }
        return gaasReq(call)
    },
    getUsers() {
        const call = {
            method: 'get',
            url: '/admin/v1/users',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    getUser(email: string) {
        const call = {
            method: 'get',
            url: '/admin/v1/users/' + email,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    inviteUser(user: IUserItem) {
        const call = {
            method: 'post',
            url: onPremise
                ? '/admin/v1/users/register'
                : '/admin/v1/gaas/user-invitation',
            headers: {
                'Content-Type': 'application/json',
            },
            body: user,
        }
        return gaasReq(call)
    },
    deleteUser(userID: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/users/' + userID,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    updateUser(user: IUserItem, email: string) {
        const call = {
            method: 'put',
            url: '/admin/v1/users/' + email,
            headers: {
                'Content-Type': 'application/json',
            },
            body: user,
        }
        return gaasReq(call)
    },
    existingUsers() {
        const call = {
            method: 'get',
            url: '/admin/v1/users/status',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    changePassword(payload: IChangeUserPasswords) {
        const call = {
            method: 'put',
            url:
                '/admin/v1/users/' +
                (payload.id ? payload.id : payload.userId) +
                '/password',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },

    // DIDs
    getDidsSetup() {
        const call = {
            method: 'get',
            url: '/admin/v1/setup',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    createFirstDid(did: ICreateDidModel) {
        let onboardingEBSIToken = did.ebsiToken
        const call = {
            method: 'post',
            url: '/admin/v1/onboards/enroll',
            headers: {
                ...(!!onboardingEBSIToken && {
                    onboardingEBSIToken: onboardingEBSIToken,
                }),
                'Content-Type': 'application/json',
            },
            body: {
                ...did,
            },
        }
        return gaasReq(call)
    },
    createDid(did: ICreateDidModel) {
        let onboardingEBSIToken = did.ebsiToken
        const call = {
            method: 'post',
            url: '/admin/v1/setup/did',
            headers: {
                ...(!!onboardingEBSIToken && {
                    onboardingEBSIToken: onboardingEBSIToken,
                }),
                'Content-Type': 'application/json',
            },
            body: {
                ...did,
            },
        }
        return gaasReq(call)
    },
    updateDid(did: ICreateDidModel, didId: string) {
        const call = {
            method: 'put',
            url: '/admin/v1/setup/did/' + didId,
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                ...did,
            },
        }
        return gaasReq(call)
    },
    deleteDid(didId: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/setup/did/' + didId,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    deleteIssuerType(didId: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/issuers/private/' + didId,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },

    deleteVerifierType(didId: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/providers/private/' + didId,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },

    addIssuerType(didId: string) {
        const call = {
            method: 'post',
            //TODO: ON PREMISE THIS TOKEN SHOULD NOT WORK => ON PREMISE it was originally decided that they should do it throught the studio manually
            url: '/admin/v1/issuers/private',
            headers: {
                'Content-Type': 'application/json',
            },
            body: { id: didId },
        }
        return gaasReq(call)
    },

    addVerifierType(didId: string) {
        const call = {
            method: 'post',
            url: '/admin/v1/providers/private',
            headers: {
                'Content-Type': 'application/json',
            },
            body: { id: didId },
        }
        return gaasReq(call)
    },

    deleteCredentialGroup(groupId: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/groups/private/' + groupId,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },

    addCredentialGroup(groupId: string, newGroup: ICredentialsGroupModel) {
        const call = {
            method: 'post',
            url: '/admin/v1/groups/private',
            headers: {
                'Content-Type': 'application/json',
            },
            body: newGroup,
        }
        return gaasReq(call)
    },

    // SETTINGS
    updateSettings(settings: IDidsRespondeModel) {
        let data = {
            token: settings.didConfigs,
            connectLogin: settings.connectLoginConfig,
        }
        const call = {
            method: 'put',
            url: '/admin/v1/setup',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }
        return gaasReq(call)
    },
    initSettings(settings: IDidsRespondeModel) {
        let data = {
            token: settings.didConfigs,
            connectLogin: settings.connectLoginConfig || undefined,
        }
        const call = {
            method: 'post',
            url: '/admin/v1/setup/init',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }
        return gaasReq(call)
    },
    autoSettings(
        backboneToken: string,
        ledger: string,
        onboardingEBSIToken?: string
    ) {
        let data = {
            ledger: ledger,
        }
        let va = {
            va: backboneToken,
        }
        const call = {
            method: 'post',
            url: '/admin/v1/setup/auto',
            headers: {
                ...(!!onboardingEBSIToken && {
                    onboardingEBSIToken: onboardingEBSIToken,
                }),
                'Content-Type': 'application/json',
            },
            body: {
                ...data,
                ...va,
            },
        }
        return gaasReq(call)
    },
    generateSecret() {
        const call = {
            method: 'get',
            url: '/admin/v1/setup/secret',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    generateKeypair() {
        const call = {
            method: 'get',
            url: '/admin/v1/setup/keys',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    getTenantDids(tenant: string) {
        const call = {
            method: 'get',
            url: '/admin/v1/tenants/' + tenant,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },

    // LICENSES
    getClientSecret(paymentMethod?: PaymentMethodModel) {
        const call = {
            method: 'post',
            url: '/api/v1/subscriptions/card',
            headers: {
                'Content-Type': 'application/json',
            },
            body: paymentMethod,
        }
        return gaasReq(call)
    },
    getSubscriptionInfo() {
        const call = {
            method: 'get',
            url: '/api/v1/subscriptions',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    getLicenses() {
        const call = {
            method: 'get',
            url: '/admin/v1/licenses',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    updateLicense(priceId: string, orgID: string) {
        const call = {
            method: 'put',
            url: '/api/v1/subscriptions/' + orgID,
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                priceId,
            },
        }
        return gaasReq(call)
    },
    cancelSubscription() {
        const call = {
            method: 'delete',
            url: '/api/v1/subscriptions',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    cancelDowngradeOrCancelation() {
        const call = {
            method: 'delete',
            url: '/api/v1/subscriptions/schedule',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    getTiers() {
        const call = {
            method: 'get',
            url: '/api/v1/subscriptions/tiers?&active=true',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    // SUBSCRIPTION REQUESTS
    requestSubscription(data?: RequestModel) {
        const call = {
            method: 'post',
            url: '/api/v1/requests',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }
        return gaasReq(call)
    },
    // API KEYS
    getApiKeys() {
        const call = {
            method: 'get',
            url: '/admin/v1/api_keys',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    createApiKey(apiKey: ISimplifiedApiKeyModel) {
        const call = {
            method: 'post',
            url: '/admin/v1/api_keys',
            headers: {
                'Content-Type': 'application/json',
            },
            body: apiKey,
        }
        return gaasReq(call)
    },
    updateApiKey(apiKey: ISimplifiedApiKeyModel, id: string) {
        const call = {
            method: 'put',
            url: '/admin/v1/api_keys/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
            body: apiKey,
        }
        return gaasReq(call)
    },
    regenerateApiKey(id: string) {
        const call = {
            method: 'get',
            url: '/admin/v1/api_keys/' + id + '/password',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    deleteApiKey(id: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/api_keys/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },

    // ISSUANCE CONFIGS
    getIssuances() {
        const call = {
            method: 'get',
            url: '/admin/v1/issuanceConfigs',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    updateIssuance(data: IIssuanceConfigItemModel) {
        const call = {
            method: 'put',
            url: '/admin/v1/issuanceConfigs/' + data.id,
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }
        return gaasReq(call)
    },
    createIssuance(data: IIssuanceConfigItemModel) {
        const call = {
            method: 'post',
            url: '/admin/v1/issuanceConfigs',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }
        return gaasReq(call)
    },
    deleteIssuanceConfig(issuanceConfigId: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/issuanceConfigs/' + issuanceConfigId,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },

    // QUALIFIED SIGNATURES - CERTIFICATES
    getCertificates() {
        const call = {
            method: 'get',
            url: '/admin/v1/certificates',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    uploadCertificate(alias: string, password: string, file: File) {
        const formData = new FormData()
        formData.append('alias', alias || '')
        formData.append('password', password || '')
        const blobFile = new Blob([file], { type: 'application/x-pkcs12' })
        formData.append('certificate', blobFile)
        formData.append('privKeyType', '')
        const call = {
            method: 'post',
            url: '/admin/v1/certificates',
            headers: {},
            body: formData,
        }
        return gaasReq(call)
    },
    deleteCertificate(id: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/certificates/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    // CREDENTIALS
    changeCredentialStatus(credId: string, status: string) {
        const call = {
            method: 'PATCH',
            url: '/admin/v1/credentials/' + credId,
            headers: {
                'Content-Type': 'application/json',
            },
            body: status,
        }
        return gaasReq(call)
    },
    getIsssuedRequests() {
        const call = {
            method: 'get',
            url: '/admin/v1/issuanceRequests',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    getTenantsRequests() {
        const call = {
            method: 'get',
            url: '/admin/v1/tenants',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    getCatalog() {
        const call = {
            method: 'get',
            url: '/api/v1/catalog',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    getPrivateCatalog() {
        const call = {
            method: 'get',
            url: '/api/v1/private/catalog',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    // VERIFIER CONFIGS
    getVerifierConfigs() {
        const call = {
            method: 'get',
            url: '/admin/v1/config',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    createVerifierConfigs(verifierConfig: IVerifierConfigModel) {
        const call = {
            method: 'post',
            url: '/admin/v1/config',
            headers: {
                'Content-Type': 'application/json',
            },
            body: verifierConfig,
        }
        return gaasReq(call)
    },
    deleteVerifierConfigs(verifierConfigId: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/config/' + verifierConfigId,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    updateVerifierConfigs(verifierConfig: IVerifierConfigModel) {
        const call = {
            method: 'PATCH',
            url: '/admin/v1/config/' + verifierConfig?.id,
            headers: {
                'Content-Type': 'application/json',
            },
            body: verifierConfig,
        }
        return gaasReq(call)
    },

    // Credentials
    getIssuedCredentials(page: number, size: number, url?: string) {
        const call = {
            method: 'get',
            url: url
                ? url
                : `/admin/v1/issuanceRequests?status=ISSUED&status=VALIDATED&status=REVOKED&status=SUSPENDED&page=${page}&size=${size}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    getPendingCredentials(page: number, size: number, url?: string) {
        const call = {
            method: 'get',
            url: url
                ? url
                : `/admin/v1/issuanceRequests?status=READY&page=${page}&size=${size}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    issueCredential(id: string, credential: any) {
        const call = {
            method: 'PATCH',
            url: '/admin/v1/issuanceRequests/' + id + '/credentials',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credential),
        }
        return gaasReq(call)
    },
    rejectCredential(id: string) {
        const call = {
            method: 'PATCH',
            url: '/admin/v2/issuanceRequests/' + id + '/status/rejected',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    deleteCredential(credID: string) {
        const call = {
            method: 'delete',
            url: '/api/v1/credentials/' + credID,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    updateCredential(id: string, status: string) {
        const call = {
            method: 'PATCH',
            url: '/admin/v1/credentials/' + id + '/issuanceRequest',
            headers: {
                'Content-Type': 'application/json',
            },
            body: { status: status },
        }
        return gaasReq(call)
    },

    // ROLES
    getRoles() {
        const call = {
            method: 'get',
            url: '/admin/v1/roles',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    getScopes() {
        const call = {
            method: 'get',
            url: '/admin/v1/roles/scopes',
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    getRole(id: string) {
        const call = {
            method: 'get',
            url: '/admin/v1/roles/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    createRole(role) {
        const call = {
            method: 'post',
            url: '/admin/v1/roles',
            headers: {
                'Content-Type': 'application/json',
            },
            body: role,
        }
        return gaasReq(call)
    },
    deleteRole(roleID: string) {
        const call = {
            method: 'delete',
            url: '/admin/v1/roles/' + roleID,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    updateRole(role, id: string) {
        const call = {
            method: 'put',
            url: '/admin/v1/roles/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
            body: role,
        }
        return gaasReq(call)
    },

    //CONSENTS
    getConsents() {
        const call = {
            method: 'get',
            url: '/admin/v2/data_agreements',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    getConsentData(id: string) {
        const call = {
            method: 'get',
            url: '/admin/v2/data_agreements/' + id + '/data',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    deleteConsent(id: string) {
        const call = {
            method: 'delete',
            url: '/admin/v2/data_agreements/' + id,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    //Purposes
    getPurposes() {
        const call = {
            method: 'get',
            url: '/admin/v2/data_agreements/purpose_templates',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    getPurpose(purpose_id: string) {
        const call = {
            method: 'get',
            url: '/admin/v2/data_agreements/purpose_templates/' + purpose_id,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    deletePurpose(purpose_id: string) {
        const call = {
            method: 'delete',
            url: '/admin/v2/data_agreements/purpose_templates/' + purpose_id,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    updatePurpose(purpose: DA_Purpose) {
        const call = {
            method: 'put',
            url: '/admin/v2/data_agreements/purpose_templates/' + purpose.id,
            headers: {
                'Content-Type': 'application/json',
            },
            data: purpose,
        }
        return gaasReq(call)
    },
    requestSbxInvitation() {
        const call = {
            method: 'get',
            url: '/admin/v1/onboards/sandbox',
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    getAllStatistics(orgID?: string) {
        const api = orgID
            ? '/api/v1/statistics?org=' + orgID
            : '/api/v1/statistics'
        const call = {
            method: 'get',
            url: api,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },

    getAdminStatistics(params: string, id?: string) {
        const api = id
            ? '/admin/v1/statistics/' + id + '?' + params
            : '/admin/v1/statistics?' + params
        const call = {
            method: 'get',
            url: api,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    requestMetricStatistic(code: MetricCode, params?: StatisticsParams) {
        const queryParams =
            params && Object.keys(params).length > 0
                ? '?' +
                  new URLSearchParams(
                      params as Record<string, string>
                  ).toString()
                : ''
        const call = {
            method: 'get',
            url: '/api/v1/statistics/' + code + queryParams,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    updateApiKeyAccordingSSIConfig(
        apiKey: ISimplifiedApiKeyModel,
        apiKeyId: string
    ) {
        const callDone = fetch('/admin/v1/api_keys/' + apiKeyId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(apiKey),
            mode: 'cors',
        })
        return callDone
    },

    // EW - CREDENTIALS
    getEWCredentials(did: string, page: number, size: number) {
        const call = {
            method: 'get',
            url:
                page && size
                    ? `/api/v1/ew-credentials?profile=${did}&page=${page}&size=${size}`
                    : `/api/v1/ew-credentials?profile=${did}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },

    deleteEWCredential(did: string, credID: string) {
        const call = {
            method: 'delete',
            url: `/api/v1/ew-credentials/${credID}?profile=${did}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },

    importEWCredential(did: string, credData: EWCredential) {
        const call = {
            method: 'post',
            url: `/api/v1/ew-credentials?profile=${did}`,
            headers: {
                'Content-Type': 'application/json',
            },
            body: credData,
        }
        return gaasReq(call)
    },

    // EW - CONNECTIONS
    getEWConnections(did: string, page: number, size: number) {
        const call = {
            method: 'get',
            url:
                page && size
                    ? `/api/v1/ew-dags?profile=${did}&page=${page}&size=${size}`
                    : `/api/v1/ew-dags?profile=${did}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },

    deleteEWDataAgreement(did: string, daID: string) {
        const call = {
            method: 'delete',
            url: `/api/v1/ew-dags/${daID}?profile=${did}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },

    // EW - SCAN MODAL
    getProcess(did: string, requestURL: string) {
        const call = {
            method: 'get',
            url: `/api/v1/scan?profile=${did}&request=${encodeURIComponent(
                requestURL
            )}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },

    sendProcessData(did: string, data: UserSelectedCredentials) {
        const call = {
            method: 'post',
            url: `/api/v1/scan?profile=${did}`,
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        }
        return gaasReq(call)
    },

    // APP INTEGRATIONS
    getAppIntegrations() {
        const call = {
            method: 'get',
            url: `/admin/v1/clients`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: '',
        }
        return gaasReq(call)
    },
    createAppIntegrations(client: IAppIntegrationModel) {
        const call = {
            method: 'post',
            url: `/admin/v1/clients`,
            headers: {
                'Content-Type': 'application/json',
            },
            body: client,
        }
        return gaasReq(call)
    },
    deleteAppIntegration(appId: string) {
        const call = {
            method: 'delete',
            url: `/admin/v1/clients/${appId}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    generateAppIntSecret(appInt: IAppIntegrationModel) {
        const call = {
            method: 'put',
            url: `/admin/v1/clients/${appInt?.client_id}`,
            headers: {
                'Content-Type': 'application/json',
            },
            body: appInt,
        }
        return gaasReq(call)
    },
    getAppIntScopes() {
        const call = {
            method: 'get',
            url: `/admin/v1/scopes`,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    updateAppIntegration(client: IAppIntegrationModel) {
        const call = {
            method: 'put',
            url: `/admin/v1/clients/${client?.client_id}`,
            headers: {
                'Content-Type': 'application/json',
            },
            body: client,
        }
        return gaasReq(call)
    },

    // Notifications
    getUnreadNotifications(profile: string) {
        const call = {
            method: 'get',
            url: `/api/v1/ew-notifications?profile=${profile}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    downloadCredential(profile: string, id: string) {
        const call = {
            method: 'get',
            url: `/api/v1/ew-notifications/${id}/download?profile=${profile}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
    deleteNotification(profile: string, id: string) {
        const call = {
            method: 'delete',
            url: `/api/v1/ew-notifications/${id}?profile=${profile}`,
            headers: {
                'Content-Type': 'application/json',
            },
        }
        return gaasReq(call)
    },
}
