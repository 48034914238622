import React from 'react'

type VerificationIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const VerificationIcon: React.FC<VerificationIconProps> = React.memo(
    (props) => {
        const { size, style, color, className } = props

        return (
            <svg
                className={className && className}
                width={size || '32'}
                height={size || '32'}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {style === 'filled' ? (
                    <FilledIconPaths color={color} />
                ) : (
                    <DeafaultIconPaths color={color} />
                )}
            </svg>
        )
    }
)

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M20.8687 12.957L15.9148 19.5613C15.7526 19.7776 15.5045 19.9129 15.2349 19.9323C14.9652 19.9517 14.7004 19.8532 14.509 19.6623L11.5366 16.6888"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4177 2.75829C14.8023 2.27769 15.3845 1.99792 16 1.99792C16.6156 1.99792 17.1977 2.27769 17.5823 2.75829L19.4629 5.09891C19.8964 5.63827 20.5752 5.91915 21.2631 5.84382L24.2476 5.51883C24.8579 5.45378 25.4649 5.6685 25.8985 6.10286C26.3322 6.53723 26.5459 7.14455 26.4798 7.75477L26.1549 10.7381C26.08 11.4261 26.3614 12.1048 26.901 12.5383L29.2404 14.4176C29.7206 14.8024 30 15.3845 30 15.9999C30 16.6153 29.7206 17.1974 29.2404 17.5822L26.901 19.4627C26.3614 19.8956 26.08 20.574 26.1549 21.2617L26.4798 24.2462C26.5466 24.8569 26.3327 25.4649 25.8983 25.8993C25.4638 26.3338 24.8558 26.5477 24.2451 26.4809L21.2606 26.1559C20.5726 26.0816 19.8941 26.3628 19.4604 26.9021L17.5823 29.239C17.1977 29.7196 16.6156 29.9994 16 29.9994C15.3845 29.9994 14.8023 29.7196 14.4177 29.239L12.5396 26.8996C12.1059 26.3604 11.4274 26.0792 10.7394 26.1535L7.7549 26.4785C7.14419 26.5452 6.53617 26.3313 6.10176 25.8969C5.66735 25.4625 5.45345 24.8545 5.52018 24.2438L5.84516 21.2593C5.92001 20.5715 5.63866 19.8932 5.09904 19.4603L2.75964 17.5822C2.27945 17.1974 2 16.6153 2 15.9999C2 15.3845 2.27945 14.8024 2.75964 14.4176L5.09904 12.5383C5.63867 12.1048 5.91997 11.4261 5.84516 10.7381L5.52018 7.75477C5.45399 7.14408 5.66803 6.53629 6.10226 6.10182C6.5365 5.66735 7.14418 5.45298 7.7549 5.51883L10.7394 5.84382C11.4273 5.91915 12.1061 5.63827 12.5396 5.09891L14.4177 2.75829Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4177 2.75829C14.8023 2.27769 15.3845 1.99792 16 1.99792C16.6156 1.99792 17.1977 2.27769 17.5823 2.75829L19.4629 5.09891C19.8964 5.63827 20.5752 5.91915 21.2631 5.84382L24.2476 5.51883C24.8579 5.45378 25.4649 5.6685 25.8985 6.10286C26.3322 6.53723 26.5459 7.14455 26.4798 7.75477L26.1549 10.7381C26.08 11.4261 26.3614 12.1048 26.901 12.5383L29.2404 14.4176C29.7206 14.8024 30 15.3845 30 15.9999C30 16.6153 29.7206 17.1974 29.2404 17.5822L26.901 19.4627C26.3614 19.8956 26.08 20.574 26.1549 21.2617L26.4798 24.2462C26.5466 24.8569 26.3327 25.4649 25.8983 25.8993C25.4639 26.3338 24.8558 26.5477 24.2451 26.4809L21.2606 26.156C20.5726 26.0816 19.8941 26.3628 19.4604 26.9021L17.5823 29.239C17.1977 29.7196 16.6156 29.9994 16 29.9994C15.3845 29.9994 14.8023 29.7196 14.4177 29.239L12.5396 26.8996C12.1059 26.3604 11.4274 26.0792 10.7394 26.1535L7.7549 26.4785C7.14419 26.5452 6.53617 26.3313 6.10176 25.8969C5.66735 25.4625 5.45345 24.8545 5.52018 24.2438L5.84516 21.2593C5.92001 20.5715 5.63866 19.8932 5.09904 19.4603L2.75964 17.5822C2.27945 17.1974 2 16.6153 2 15.9999C2 15.3845 2.27945 14.8024 2.75964 14.4176L5.09904 12.5383C5.63867 12.1048 5.91997 11.4261 5.84516 10.7381L5.52018 7.75477C5.45399 7.14408 5.66803 6.53629 6.10226 6.10182C6.5365 5.66735 7.14418 5.45298 7.7549 5.51883L10.7394 5.84382C11.4273 5.91915 12.1061 5.63827 12.5396 5.09891L14.4177 2.75829Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.8687 12.957L15.9148 19.5613C15.7526 19.7776 15.5045 19.9129 15.2349 19.9323C14.9652 19.9517 14.7004 19.8532 14.509 19.6623L11.5366 16.6888"
                stroke="white"
                strokeWidth="2.67"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export default VerificationIcon
