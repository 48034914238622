import { t } from 'i18next'
import { ReactComponent as PlusLogo } from '../../../../../assets/images/plus.svg'
import { ButtonModel } from '../../../../../interfaces/interfaces'

export const headerApyKeyListButton: ButtonModel = {
    label: 'apiKeys.newApiKey',
    hideIconTooltip: true,
    disabledTooltipLabel: t('subscription.limitReachedDescription', {
        feature: t('subscription.limitedFeatures.apiKeys'),
    }),
}

export const modalPrimaryButton: ButtonModel = {
    label: 'editApiKey.step2.addAndClose',
    svgIcon: PlusLogo,
}

export const modalSecondaryButton: ButtonModel = {
    label: 'editApiKey.step2.cancel',
}

export const propertiesToSearchApiKeys = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: true,
    },
    {
        text: 'alias',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const propertiesToSearchSSIOperations = [
    {
        text: 'alias',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const apiKeysScopes = {
    read: ['readApiKeys'],
    edit: ['updateApiKeys'],
    create: ['createApiKeys'],
    delete: ['deleteApiKeys'],
    addSSIToApiKey: ['readApiKeys', 'updateApiKeys'],
}
