import React from 'react'
import styles from './badget.module.scss'
import cx from 'classnames'
import { badgetBGColor } from '../../data/globalVar'

type BadgetProps = {
    content: string | number
    bgColor?: badgetBGColor
    className?: string
}

const Badget: React.FC<BadgetProps> = (props) => {
    const { content, bgColor, className } = props

    return (
        <div
            className={cx(
                bgColor ? bgColor : badgetBGColor.primary700,
                styles.badget,
                className && className
            )}
        >
            <p className={cx('buttonXS neutral100')}>{content}</p>
        </div>
    )
}

export default Badget
