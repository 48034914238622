import cx from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    CredentialIcons,
    gaColors,
    gaImages,
    purposesTypesList,
} from '../../../../assets/globalConstants'
import { initialLang } from '../../../../i18n'
import { ICredentialRequestedModel } from '../../../../interfaces/interfaces'
import style from './credentialCardPreview.module.scss'
import ShieldCheckIcon from '../../../../assets/iconsLibrary/shiedlCheck/shieldCheckIcon'
import GaImage from '../../../atoms/Image/Image'
import ShieldErrorIcon from '../../../../assets/iconsLibrary/shieldError/shieldErrorIcon'
import CheckIcon from '../../../../assets/iconsLibrary/simple/check/checkIcon'
import { getCredentialSubjectValue } from '../../../../utils/credentialsUtils'
import CloseIcon from '../../../../assets/iconsLibrary/simple/close/closeIcon'
import GaCheckbox from '../../../atoms/formFields/checkbox/GaCheckbox'

type ICredentialCardPreviewProps = {
    credential: ICredentialRequestedModel
    id?: string
    isOnRequest?: boolean
    isOnRequirements?: boolean
    isOnConstraints?: boolean
    isOnConsentError?: boolean
    showPurposes?: boolean
}

const CredentialCardPreview: React.FC<ICredentialCardPreviewProps> = (
    props
) => {
    const {
        credential,
        id,
        isOnRequest,
        isOnRequirements,
        isOnConstraints,
        isOnConsentError,
        showPurposes,
    } = props
    const { t } = useTranslation()

    const credPurposeText =
        credential?.purpose &&
        purposesTypesList?.find((el) => el?.id === credential?.purpose)?.text

    const credName = !isOnConsentError
        ? !!credential?.name
            ? credential?.name[initialLang] || credential?.name['en']
            : ''
        : !!credential?.group?.name
        ? credential?.group?.name[initialLang] || credential?.group?.name['en']
        : ''
    const requiredActive = credential?.mandatory
    const [addRequired, setAddRequired] = useState(requiredActive)

    return (
        <div id={id} className={`${style.credentialCardPreview} `}>
            <div className={style.credentialCardPreview__header}>
                <div className={cx(style.credentialCardPreview__header__left)}>
                    <GaImage
                        className={
                            style.credentialCardPreview__header__left__icon
                        }
                        image={{
                            src:
                                CredentialIcons[
                                    credential?.groupList
                                        ? credential?.groupList[0]
                                        : 'credential'
                                ] || CredentialIcons?.credential,
                            alt: t(gaImages.credential.alt),
                        }}
                    />{' '}
                    <p
                        className={cx(
                            'buttonMD neutral1000',
                            style.textSetByWidth
                        )}
                    >
                        {credName && credName}
                        {(credName && !isOnRequest && credential?.mandatory) ||
                        isOnConsentError ? (
                            <span className={cx('buttonMD alert300')}> *</span>
                        ) : null}
                    </p>
                </div>

                <div className={cx(style.credentialCardPreview__header__right)}>
                    {isOnConstraints ? (
                        credential?.trustLevel && credential?.trustLevel > 0 ? (
                            <ShieldCheckIcon
                                style="filled"
                                color={gaColors.success300}
                                size={24}
                            />
                        ) : (
                            <ShieldErrorIcon
                                style="filled"
                                color={gaColors.alert300}
                                size={24}
                            />
                        )
                    ) : null}
                    {isOnRequirements && credential?.mandatory ? (
                        <CheckIcon color={gaColors.success300} size={24} />
                    ) : null}
                    {isOnConsentError ? (
                        <CloseIcon color={gaColors.alert300} size={24} />
                    ) : null}
                </div>
            </div>
            {!isOnRequest && !isOnConsentError ? (
                <div className={style.mockedValue}>
                    <p className={cx('bodyBoldXL primary700')}>
                        {getCredentialSubjectValue(credential)}
                    </p>
                </div>
            ) : null}
            {isOnRequirements && showPurposes && credPurposeText ? (
                <div className={style.purpose}>
                    <p className={cx('bodyRegularSM neutral700')}>
                        {t('public.for')}
                        {t(credPurposeText)}
                    </p>
                </div>
            ) : null}
            {isOnConsentError ? (
                <div className={style.purpose}>
                    <p className={cx('bodyRegularSM alert300')}>
                        {t('ew.modalScan.error.unavailableAttr')}
                    </p>
                </div>
            ) : null}
        </div>
    )
}

export default CredentialCardPreview
