import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImageModel } from '../../../interfaces/interfaces'
import Description from '../../atoms/texts/Description/Description'
import styles from './formSection.module.scss'
import GaHoverTooltip from '../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import { TooltipArrowPosition, TooltipPosition } from '../../../data/globalVar'
type FormSectionProps = {
    title: string
    icon?: ImageModel
    titleInfo?: string
    subTitle?: string
    subText?: string
    description?: string
    titleClassName?: string
    descriptionClassName?: string
    extraTranslationParams?: Object
    subDescription?: string
    className?: string
    textParams?: { subtitle?: object }
    tooltipArrowPosition?: TooltipArrowPosition
    tooltipPosition?: TooltipPosition
}

const FormSection: React.FC<FormSectionProps> = React.memo((props) => {
    const {
        title,
        titleInfo,
        description,
        subTitle,
        subText,
        subDescription,
        titleClassName,
        descriptionClassName,
        className,
        extraTranslationParams,
        textParams,
        children,
        tooltipArrowPosition,
        tooltipPosition,
    } = props

    const { t } = useTranslation()

    return (
        <div
            className={cx(
                'width100',
                styles.formSection,
                className && className
            )}
        >
            {titleInfo ? (
                <GaHoverTooltip
                    position={tooltipPosition || TooltipPosition.bottom}
                    positionArrowDirection={
                        tooltipArrowPosition || TooltipArrowPosition.center
                    }
                    label={
                        <h3
                            className={`${
                                titleClassName
                                    ? cx(titleClassName)
                                    : cx('heading6')
                            } ${cx('neutral1000')}`}
                        >
                            {t(title)}
                        </h3>
                    }
                    info={titleInfo}
                />
            ) : (
                <h3
                    className={`${
                        titleClassName ? cx(titleClassName) : cx('heading6')
                    } ${cx('neutral1000')}`}
                >
                    {t(title)}
                </h3>
            )}
            {description ? (
                <div
                    className={cx(
                        'marginTop4',
                        styles.formSection__content__description
                    )}
                >
                    <Description
                        className={`${
                            descriptionClassName
                                ? cx(descriptionClassName)
                                : cx('bodyRegularSM')
                        } ${cx('neutral700')}`}
                        text={description}
                        extraTranslationParams={extraTranslationParams}
                    />
                </div>
            ) : null}
            <div className={styles.formSection__content}>
                {subTitle ? (
                    <div className={styles.formSection__content__subTitle}>
                        <h4 className={cx('margin-bottom')}>
                            {textParams?.subtitle
                                ? t(subTitle, textParams.subtitle)
                                : t(subTitle)}
                        </h4>
                    </div>
                ) : null}
                {subDescription ? (
                    <div
                        className={styles.formSection__content__subDescription}
                    >
                        <Description text={subDescription} />
                    </div>
                ) : null}
                {subText ? (
                    <div className={styles.formSection__content__subText}>
                        <Description text={subText} />
                    </div>
                ) : null}
                <div
                    className={cx(
                        !!(
                            description ||
                            subTitle ||
                            subText ||
                            subDescription ||
                            textParams
                        )
                            ? 'margin-top'
                            : '',
                        styles.formSection__content__child
                    )}
                >
                    {children}
                </div>
            </div>
        </div>
    )
})

export default FormSection
