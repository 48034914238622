import cx from 'classnames'
import React from 'react'
import GaImage from '../../../../../../Components/atoms/Image/Image'
import { gaImages } from '../../../../../../assets/globalConstants'
import { t } from 'i18next'
import styles from '../tutorial.module.scss'
import { initialLang } from '../../../../../../i18n'

type IThirdSteprops = {}

const ThirdStep: React.FC<IThirdSteprops> = (props) => {
    return (
        <div>
            <div className={cx(styles.image)}>
                <GaImage
                    image={
                        initialLang === 'es'
                            ? gaImages.shareYourDataEs
                            : initialLang === 'pt'
                            ? gaImages.shareYourDataPt
                            : gaImages.shareYourDataEn
                    }
                />
            </div>
            <div>
                <p className={cx('heading5')}>
                    {t('appIntegrations.tutorial.getVerified')}
                </p>
                <p className={cx('bodyRegularMD neutral700 marginTop4')}>
                    {t('appIntegrations.tutorial.getVerifiedDesc')}
                </p>
            </div>
        </div>
    )
}

export default ThirdStep
