import { ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { onPremise } from '../../../data/globalVar'
import { IUserItem } from '../../../interfaces/interfaces'
import auth from '../../../services/auth'
import dataService from '../../../services/dataService'
import localStorageService from '../../../services/localStorageService'
import { getApiKeys } from '../../studio/ApiKeys/apiKeysActions'
import { getDids } from '../../studio/Dids/didActions'
import { getIssuances } from '../../studio/Issuance/issuanceActions'
import { loginSuccess, qrSignupSuccess } from '../Login/loginAction'
import { showNotification } from '../Notification/notificationActions'
import { getUsers } from '../Team/tabsContent/users/store/usersActions'
import { getVerifierConfigs } from '../../studio/Verifier/verifierActions'
import { navigateAction } from '../global/globalActions'
import { getOrgAction } from '../organization/organizationActions'
import {
    getLicensesFailed,
    getLicensesSuccess,
    readSession,
    readSessionFailed,
    readSessionSucess,
    setUser,
} from './sessionActions'
import { SessionActionTypes } from './sessionTypes'

export const setSessionEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(SessionActionTypes.SET_SESSION),
        switchMap((action: any): any => {
            let taskFunc = action?.signUp ? qrSignupSuccess : loginSuccess
            return of(taskFunc())
        })
    )
}

export const readSessionEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(SessionActionTypes.READ_SESSION),
        switchMap((action: any): any => {
            return dataService.getAuthData().pipe(
                map((res: any) => {
                    return readSessionSucess(res.response)
                }),
                catchError((error) => of(readSessionFailed(error)))
            )
        })
    )
}

export const getUserEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(SessionActionTypes.GET_USER),
        switchMap((action: any): any => {
            return dataService.getUser(action.email).pipe(
                mergeMap((res: any) => {
                    const user: IUserItem = res.response[0]
                    if (!!user.org_ID) {
                        return [
                            setUser(user.email),
                            // Just in cloud for now
                            !onPremise && getOrgAction(),
                        ]
                    } else if (user) {
                        return [setUser(user.email), navigateAction('/home')]
                    } else {
                        return navigateAction('/email-login')
                    }
                }),
                catchError((error: any): any => {
                    return [navigateAction('/login')]
                })
            )
        })
    )
}

export const deleteSessionEpic = (
    action$: any,
    state$: any,
    dependencies
): any => {
    return action$.pipe(
        ofType(SessionActionTypes.DELETE_SESSION),
        switchMap((action: any): any => {
            return dataService.logout().pipe(
                map((res: any) => {
                    sessionStorage?.clear()
                    localStorageService?.deleteLocalStorageData('TMClosed')
                    return navigateAction('/login')
                }),
                catchError((error) => of(readSessionFailed(error)))
            )
        })
    )
}

export const getLicensesEpic = (action$, state$) => {
    return action$.pipe(
        ofType(SessionActionTypes.GET_LICENSES),
        switchMap((action: any): any => {
            return from(dataService.getLicenses()).pipe(
                mergeMap((response?: any) => {
                    return of(
                        getLicensesSuccess(response?.response),
                        readSession()
                    )
                }),

                catchError((error) =>
                    of(
                        getLicensesFailed(error),
                        showNotification(
                            'login.getLicensesError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const loadDataEpic = (action$, state$) => {
    return action$.pipe(
        ofType(SessionActionTypes.LOAD_DATA),
        mergeMap(() =>
            merge(
                // of(getLicenses()),
                of(getIssuances()),
                of(getVerifierConfigs()),
                of(getUsers()),
                of(getDids()),
                of(getApiKeys()),
                of(readSession())
            )
        )
    )
}
