import {
    IConnectionsDataAgreement,
    IEWConnectionsPaginatedResponseModel,
    OrganizationModel,
} from '../../interfaces/interfaces'

// LIST
export interface IGetConnectionsAction {
    type: ConnectionListActionsTypes.GET_CONNECTIONS
    payload: {
        did: string
        page?: number
        size?: number
        url?: string
    }
}

export interface IGetConnectionsSuccessAction {
    type: ConnectionListActionsTypes.GET_CONNECTIONS_SUCCESS
    payload: {
        data: IEWConnectionsPaginatedResponseModel | IConnectionsDataAgreement[]
    }
}

export interface IGetConnectionsFailed {
    type: ConnectionListActionsTypes.GET_CONNECTIONS_FAILED
    payload: {
        error: string
    }
}

export interface IDeleteDataAgreementAction {
    type: ConnectionListActionsTypes.DELETE_DATA_AGREEMENT
    payload: {
        did: string
        dataAgreementId: string
    }
}

export interface IDeleteDataAgreementSuccessAction {
    type: ConnectionListActionsTypes.DELETE_DATA_AGREEMENT_SUCCESS
}

export interface IDeleteDataAgreementFailed {
    type: ConnectionListActionsTypes.DELETE_DATA_AGREEMENT_FAILED
    payload: {
        error: string
    }
}

export interface ISelectConnectionAction {
    type: ConnectionListActionsTypes.SELECT_CONNECTION
    payload: {
        dataAgreement: IConnectionsDataAgreement
    }
}

export type ConnectionListActions =
    | IGetConnectionsAction
    | IGetConnectionsSuccessAction
    | IGetConnectionsFailed
    | ISelectConnectionAction
    | IDeleteDataAgreementAction
    | IDeleteDataAgreementSuccessAction
    | IDeleteDataAgreementFailed

export enum ConnectionListActionsTypes {
    GET_CONNECTIONS = 'connections/get',
    GET_CONNECTIONS_SUCCESS = 'connections/get_success',
    GET_CONNECTIONS_FAILED = 'connections/get_failed',
    SELECT_CONNECTION = 'connections/selectConnection',
    DELETE_DATA_AGREEMENT = 'connections/deleteDataAgreement',
    DELETE_DATA_AGREEMENT_SUCCESS = 'connections/delete_DataAgreement_success',
    DELETE_DATA_AGREEMENT_FAILED = 'connections/delete_DataAgreement_failed',
}

export interface IConnectionListState {
    connections?:
        | IEWConnectionsPaginatedResponseModel
        | IConnectionsDataAgreement[]
    selectedConnection?: IConnectionsDataAgreement
    loadingStatus?: boolean
}
