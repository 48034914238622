import React from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './changeCredential.module.scss'
import GaRadioButton from '../../../../../atoms/formFields/radioButton/GaRadioButton'
import {
    getCredName,
    getImageURI,
    isDate,
    isImage,
} from '../../../../../../utils/credentialsUtils'
import { translateDate } from '../../../../../../i18n'
import GaModal from '../../../modal/modal'

type IChangeCredentialProps = {
    selectedIndexForMultipleCreds: {
        selectedCredIndex: number
        accepted: boolean
    }[]
    initialSelectableCreds: {
        groupIndex: undefined
        selectableCreds: undefined
    }
    credentialsToSelect: {
        groupIndex: number | undefined
        selectableCreds: any[] | undefined
    }
    selectedCurrentIndexForMultipleCreds: {
        selectedCredIndex: number
        accepted: boolean
    }[]
    setSelectedIndexForMultipleCreds: (x?) => void
    setCredentialsToSelect: (x?) => void
    setImageToShowURI: (x?) => void
    setSelectedCurrentIndexForMultipleCreds: (x) => void
}

const ChangeCredential: React.FC<IChangeCredentialProps> = (props) => {
    const {
        selectedIndexForMultipleCreds,
        initialSelectableCreds,
        credentialsToSelect,
        selectedCurrentIndexForMultipleCreds,
        setSelectedIndexForMultipleCreds,
        setCredentialsToSelect,
        setImageToShowURI,
        setSelectedCurrentIndexForMultipleCreds,
    } = props

    return (
        <GaModal
            title={'ew.modalScan.serviceRequirements.changeCred'}
            hideModal={() => {
                setCredentialsToSelect(initialSelectableCreds),
                    setSelectedCurrentIndexForMultipleCreds(
                        selectedIndexForMultipleCreds
                    )
            }}
            className={styles.changeCredModal}
            show={true}
            backButton={true}
            content={
                <div className={styles.selectableCredList}>
                    {credentialsToSelect?.selectableCreds?.map(
                        (cred, indexSelectableCred) => (
                            <div className={styles.credContainer}>
                                <div className={styles.credDataContainer}>
                                    <p className={cx('buttonSM')}>
                                        {getCredName(cred) ||
                                            t(
                                                'ew.requestCredentials.credential'
                                            )}
                                    </p>
                                    <div className={styles.value}>
                                        {isImage(cred) ? (
                                            <div
                                                className={
                                                    styles.photoContainer
                                                }
                                            >
                                                <img
                                                    className={
                                                        styles.imagePreview
                                                    }
                                                    src={getImageURI(cred)}
                                                />
                                                <p
                                                    onClick={() =>
                                                        setImageToShowURI(
                                                            getImageURI(cred)
                                                        )
                                                    }
                                                    className={cx(
                                                        'buttonSM primary700'
                                                    )}
                                                >
                                                    {t(
                                                        'ew.modalScan.serviceRequirements.viewPhoto'
                                                    )}
                                                </p>
                                            </div>
                                        ) : isDate(cred) ? (
                                            <p className={cx('bodyBoldSM')}>
                                                {cred?.mainClaim &&
                                                    translateDate(
                                                        cred?.mainClaim
                                                    )}
                                            </p>
                                        ) : (
                                            <p className={cx('bodyBoldSM')}>
                                                {cred?.mainClaim}
                                            </p>
                                        )}
                                    </div>
                                    {cred?.issuer?.length ? (
                                        <p
                                            className={cx(
                                                'bodyRegularXS neutral700'
                                            )}
                                        >
                                            {cred.issuer}
                                        </p>
                                    ) : null}
                                </div>
                                <GaRadioButton
                                    id={
                                        'changeCredCard__' + indexSelectableCred
                                    }
                                    index={
                                        indexSelectableCred?.toString() || ''
                                    }
                                    value={''}
                                    checked={
                                        credentialsToSelect?.groupIndex ||
                                        credentialsToSelect?.groupIndex === 0
                                            ? selectedCurrentIndexForMultipleCreds[
                                                  credentialsToSelect
                                                      ?.groupIndex
                                              ]?.selectedCredIndex ===
                                              indexSelectableCred
                                            : false
                                    }
                                    onChange={() => {
                                        const newIndexesValue =
                                            selectedCurrentIndexForMultipleCreds?.map(
                                                (indexGroup, index) =>
                                                    index ===
                                                    credentialsToSelect?.groupIndex
                                                        ? {
                                                              selectedCredIndex:
                                                                  indexSelectableCred,
                                                              accepted:
                                                                  indexGroup?.accepted,
                                                          }
                                                        : indexGroup
                                            )
                                        setSelectedCurrentIndexForMultipleCreds(
                                            newIndexesValue
                                        )
                                    }}
                                />
                            </div>
                        )
                    )}
                </div>
            }
            footerRightButton={{
                label: t('ew.modalScan.serviceRequirements.saveChange'),
                disabled: false,
                function: () => {
                    setSelectedIndexForMultipleCreds(
                        selectedCurrentIndexForMultipleCreds
                    ),
                        setSelectedCurrentIndexForMultipleCreds(
                            selectedCurrentIndexForMultipleCreds
                        )
                    setCredentialsToSelect(initialSelectableCreds)
                },
            }}
        />
    )
}

export default ChangeCredential
