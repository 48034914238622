import { combineReducers } from 'redux'
import {
    apiKeysReducer,
    configApiKeyFormReducer,
} from '../features/studio/ApiKeys/apiKeysReducers'
// import {configApiKeyReducer} from '../features/ApiKeys/apiKeysReducers';
import { catalogReducer } from '../features/common/catalog/catalogReducers'
import {
    configCredentialFormReducer,
    credentialsReducer,
} from '../features/studio/credentials/credentialsReducers'
import { configDidFormReducer } from '../features/studio/Dids'
import {
    EWDidAccountReducer,
    configDidReducer,
    didListReducer,
} from '../features/studio/Dids/didReducers'
import { homeReducer } from '../features/studio/Home'
import { configIssuanceFormReducer } from '../features/studio/Issuance'
import { issuancesReducer } from '../features/studio/Issuance/issuanceReducers'
import { licensesReducer } from '../features/common/licenses/licensesReducers'
import { loaderReducer } from '../features/common/Loader'
import { loginReducer } from '../features/common/Login'
import { notificationReducer } from '../features/common/Notification/notificationReducer'
import { organizationReducer } from '../features/common/organization'
import { navigationReducer } from '../features/common/PanelScafolding'
import { scopesReducer } from '../features/common/Scopes'
import { sessionReducer } from '../features/common/session/sessionReducers'
import { statisticsReducer } from '../features/common/Statistics'
import { usersReducer } from '../features/common/Team/tabsContent/users/store/userReducers'
import { certificatesReducer } from '../features/studio/certificates/certificatesReducers'
import {
    configVerifierFormReducer,
    consentsReducer,
    purposesReducer,
    verifiersReducer,
} from '../features/studio/Verifier'
import { rolesReducer } from '../features/common/Team/tabsContent/roles/store'
import { requestCredentialsReducer } from './requestCredentials'
import { connectionsReducer } from './connections'
import { scanModalReducer } from './scanModal'
import {
    appIntegrationsListReducer,
    configAppIntegrationsReducer,
} from './appIntegrations'
import { appIntegrationModalReducer } from './appIntegrationModal'
import { notificationsReducer } from './notifications'

const rootState = {
    navigationReducer,
    loginReducer,
    sessionReducer,
    organizationReducer,
    scopesReducer,
    loaderReducer,
    homeReducer,
    purposesReducer,
    verifiersReducer,
    configVerifierFormReducer,
    issuancesReducer,
    configIssuanceFormReducer,
    configApiKeyFormReducer,
    // configApiKeyFormReducer,
    apiKeysReducer,
    configDidFormReducer,
    configDidReducer,
    didListReducer,
    EWDidAccountReducer,
    // settingsReducer,
    notificationReducer,
    usersReducer,
    catalogReducer,
    credentialsReducer,
    configCredentialFormReducer,
    rolesReducer,
    consentsReducer,
    statisticsReducer,
    licensesReducer,
    certificatesReducer,
    requestCredentialsReducer,
    connectionsReducer,
    scanModalReducer,
    appIntegrationsListReducer,
    configAppIntegrationsReducer,
    appIntegrationModalReducer,
    notificationsReducer,
}

const rootReducer = combineReducers(rootState)

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
