import { RootState } from '../../../../../../store/indexReducers'

export const getUsers = (state: RootState) => state.usersReducer.users
export const getTenants = (state: RootState) => state.usersReducer.tenant
export const loadingStatus = (state: RootState) =>
    state.usersReducer.loadingStatus
export const showUserDetail = (state: RootState) =>
    state.usersReducer.showUserDetail
export const getSelectedUser = (state: RootState) =>
    state.usersReducer.selectedUser
export const changePasswordStatus = (state: RootState) =>
    state.usersReducer.changePassword.loading
export const getStatus = (state: RootState) => state.usersReducer.status
export const getError = (state: RootState) => state.usersReducer.error
export const getProfile = (state: RootState) => state.usersReducer.profile
