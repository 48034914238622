import { Epic, ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, delay, map, mergeMap, switchMap } from 'rxjs/operators'
import dataService from '../../../services/dataService'
import { showNotification } from '../../common/Notification/notificationActions'
import {
    deleteConsentFailed,
    deleteConsentSuccess,
    getConsentsFailed,
    getConsentsSuccess,
    selectConsentFailed,
    selectConsentSuccess,
} from './consentActions'
import { ConsentActionsTypes } from './consentTypes'

export const getConsentsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConsentActionsTypes.GET_CONSENTS),
        delay(100),
        switchMap((action: any): any => {
            return from(dataService.getConsents()).pipe(
                mergeMap((response?: any) => {
                    return merge(of(getConsentsSuccess(response.response)))
                }),
                catchError((error) =>
                    of(
                        getConsentsFailed(error),
                        showNotification(
                            'consents.getConsentsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const selectConsentEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConsentActionsTypes.SELECT_CONSENT),
        switchMap((action: any): any => {
            const consent = action.payload.consentId
            return from(dataService.getConsentData(consent)).pipe(
                map((response?: any) => {
                    return selectConsentSuccess(response?.response)
                }),
                catchError((error) => {
                    return of(
                        selectConsentFailed(error),
                        showNotification(
                            'consents.viewConsentError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                })
            )
        })
    )
}

export const deleteIssuanceEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConsentActionsTypes.DELETE_CONSENT),
        switchMap((action: any): any => {
            const consent = action.payload.consentId
            return from(dataService.deleteConsent(consent)).pipe(
                map(() => deleteConsentSuccess()),
                catchError((error) =>
                    of(
                        deleteConsentFailed(error),
                        showNotification(
                            'consents.deleteConsentError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
