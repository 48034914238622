import { Epic, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { noPaymentMethod, onPremise } from '../../../data/globalVar'
import dataService from '../../../services/dataService'
import { history } from '../../../services/history'
import { RootState } from '../../../store/indexReducers'
import { createDid } from '../../studio/Dids/didActions'
import { hideScreenLoaderAction } from '../Loader/loaderActions'
import { setNotSigningUp } from '../Login/loginAction'
import { showNotification } from '../Notification/notificationActions'
import {
    getSubscriptionInfoAction,
    preselectLicenseAction,
    removeClientSecretAction,
    requestSubscriptionAction,
    requestSubscriptionActionFailed,
    setActivatedAccount,
    setCurrentStepAction,
} from '../licenses/licensesActions'
import {
    deleteSession,
    readSession,
    setSession,
} from '../session/sessionActions'
import {
    createOrgFailedAction,
    createOrgForRequestSuccessAction,
    createOrgSuccessAction,
    createStarterOrgSuccessAction,
    deleteOrgActionFailed,
    deleteOrgActionSuccess,
    loadOrgAction,
    loadOrgFailedAction,
    updateOrgActionFailed,
    updateOrgActionSuccess,
} from './organizationActions'
import { OrganizationTypes } from './organizationTypes'
import { firstDid } from '../../studio/Dids/views/didList/didList.constants'
import { createSubscriptionSteps } from '../licenses/licenses.constants'
import localStorageService from '../../../services/localStorageService'

export const loadOrgEpic: Epic<any, any, RootState> = (action$, state$) => {
    return action$.pipe(
        ofType(OrganizationTypes.LOAD_ORG),
        switchMap((action: any): any => {
            const orgID = state$.value.sessionReducer.tokenData?.organizationId
            return dataService.getOrganization(orgID).pipe(
                map((res: any) => {
                    return loadOrgAction(res.response)
                }),
                catchError((err): any => {
                    return loadOrgFailedAction()
                })
            )
        })
    )
}

export const getOrgEpic: Epic<any, any, RootState> = (action$, state$) => {
    return action$.pipe(
        ofType(OrganizationTypes.GET_ORG),
        switchMap((action: any): any => {
            const orgID = state$.value.sessionReducer.tokenData?.organizationId
            return dataService.getOrganization(orgID).pipe(
                map((res: any) => {
                    return loadOrgAction(res.response)
                }),
                catchError((err): any => {
                    return loadOrgFailedAction()
                })
            )
        })
    )
}

export const createOrgEpic: Epic<any, any, RootState> = (action$, state$) => {
    return action$.pipe(
        ofType(OrganizationTypes.CREATE_ORG),
        switchMap((action: any): any => {
            const org = action.payload.org
            return from(dataService.createOrganization(org)).pipe(
                mergeMap((response: any) => {
                    return of(
                        createOrgSuccessAction(response?.response),
                        setActivatedAccount(true),
                        setCurrentStepAction(createSubscriptionSteps[2]),
                        hideScreenLoaderAction()
                    )
                }),
                catchError((error) =>
                    of(
                        hideScreenLoaderAction(),
                        createOrgFailedAction(error),
                        showNotification(
                            'login.errorSignUp',
                            'error',
                            error?.response?.data?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const createStarterOrgEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(OrganizationTypes.CREATE_STARTER_ORG),
        switchMap((action: any): any => {
            const org = action.payload.org
            return from(dataService.createOrganization(org)).pipe(
                mergeMap((response: any) => {
                    return of(
                        setSession(),
                        createStarterOrgSuccessAction(response?.response),
                        preselectLicenseAction(undefined),
                        getSubscriptionInfoAction(),
                        // setNotSigningUp()
                        // Just in cloud for now
                        !onPremise && createDid(firstDid)
                    )
                }),
                catchError((error) =>
                    of(
                        hideScreenLoaderAction(),
                        createOrgFailedAction(error),
                        removeClientSecretAction(),
                        showNotification(
                            'login.errorSignUp',
                            'error',
                            error?.response?.data?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const createOrgSuccessEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(OrganizationTypes.CREATE_STARTER_ORG_SUCCESS),
        switchMap((action: any): any => {
            history.push('/home')
            return of(
                readSession(),
                setNotSigningUp()
                // hideScreenLoaderAction(),
            )
        })
    )
}

export const updateOrgEpic: Epic<any, any, RootState> = (action$, state$) => {
    return action$.pipe(
        ofType(OrganizationTypes.UPDATE_ORG),
        switchMap((action: any): any => {
            const org = action?.org

            return from(dataService.updateOrganization(org)).pipe(
                mergeMap((response: any) => {
                    return of(
                        updateOrgActionSuccess(org),
                        showNotification(
                            'general.updateOrgSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error): any =>
                    of(
                        updateOrgActionFailed(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'general.updateOrgError',
                            'error',
                            '',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const updateOrgNoPaymentEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(OrganizationTypes.UPDATE_ORG_NO_PAYMENT),
        switchMap((action: any): any => {
            const org = action?.org

            return from(dataService.updateOrganization(org)).pipe(
                mergeMap((response: any) => {
                    /* UpO: Updated Org*/
                    noPaymentMethod &&
                        localStorageService?.setLocalStorageData('UpO', 'Y')
                    return of(
                        updateOrgActionSuccess(org),
                        hideScreenLoaderAction(),
                        showNotification(
                            'general.updateOrgSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error): any =>
                    of(
                        updateOrgActionFailed(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'general.updateOrgError',
                            'error',
                            '',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deleteOrgEpic: Epic<any, any, RootState> = (action$, state$) => {
    return action$.pipe(
        ofType(OrganizationTypes.DELETE_ORG),
        switchMap((action: any): any => {
            return from(dataService.deleteOrganization(action?.orgId)).pipe(
                mergeMap((response: any) => {
                    history.push('/login')
                    return of(
                        deleteSession(),
                        hideScreenLoaderAction(),
                        deleteOrgActionSuccess(),
                        showNotification(
                            'general.deleteOrgSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error): any =>
                    of(
                        deleteOrgActionFailed(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'general.deleteOrgError',
                            'error',
                            '',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const createOrgToRequestSubEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(OrganizationTypes.CREATE_ORG_TO_REQUEST_SUB),
        switchMap((action: any): any => {
            const org = action.payload.org
            const data = action.payload.data
            const priceId = data?.priceId
            const message = data?.message
            return from(dataService.createOrganization(org)).pipe(
                mergeMap((response: any) => {
                    return of(
                        createOrgForRequestSuccessAction(response?.response),
                        requestSubscriptionAction(
                            priceId,
                            response?.response?.id,
                            message
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        hideScreenLoaderAction(),
                        requestSubscriptionActionFailed(error),
                        createOrgFailedAction(error),
                        showNotification(
                            'subscription.requestSubscritptionError',
                            'error',
                            '',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
