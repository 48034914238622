import React from 'react'

type FeaturesIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const FeaturesIcon: React.FC<FeaturesIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M28.0001 4H18.6667V13.3333H28.0001V4Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.0001 18.6667H18.6667V28H28.0001V18.6667Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3333 18.6667H4V28H13.3333V18.6667Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.96576 3.33333L9.62427 6.69481L13.3331 7.23716L10.6494 9.85225L11.2828 13.5467L7.96576 11.8015L4.64872 13.5467L5.28207 9.85225L2.59839 7.23716L6.30724 6.69481L7.96576 3.33333Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.3003"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <path
                d="M28.0001 4H18.6667V13.3333H28.0001V4Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.0001 18.6667H18.6667V28H28.0001V18.6667Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3333 18.6667H4V28H13.3333V18.6667Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.96576 3.33333L9.62427 6.69481L13.3331 7.23716L10.6494 9.85225L11.2828 13.5467L7.96576 11.8015L4.64872 13.5467L5.28207 9.85225L2.59839 7.23716L6.30724 6.69481L7.96576 3.33333Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.3003"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export default FeaturesIcon
