/*
    Util to assist in searching by similarity distance in an array of objects.
    It will receive as params:
        - a text parameter to search for
        - a list of options
        - a list of object keys to search between, with a greater or lesser margin of error depending on the threshold indicated for each of them.

    The text to search for will be separated by spaces and each of the words will be checked against each of the element keys.
    If there is a text between quotes, it will be searched in its entirety, without being separated by spaces.
*/

import * as fuzzysort from './fuzzySortUtil'

export const threshold = {
    high: -100,
    mediumHight: -300,
    medium: -500,
    mediumLow: -700,
    low: -1000,
}

// Results when nothing is found
const noResults = []
noResults.total = 0

export const fuzzySearch = (search, targets, options) => {
    if (!search || search.trim() === '"')
        return options?.length ? options : noResults

    const searchParsed = parseSearchTerms(search)
    const allResults = []

    for (let word of searchParsed) {
        const keys = options?.keys || []
        const defaultThreshold = options?.threshold || -500 // Default threshold
        const limit = options?.limit || Infinity

        for (let obj of targets) {
            let maxScore = -Infinity

            for (let keyObj of keys) {
                const key = keyObj.key
                const targetThreshold =
                    keyObj.threshold !== undefined
                        ? keyObj.threshold
                        : defaultThreshold
                const target = getValue(obj, key)
                if (!target) continue

                // Perform fuzzy search with fuzzysort
                const result = fuzzysort.single(word, target)
                if (!result) continue

                // Normalize the fuzzysort score (more negative is better)
                const normalizedScore =
                    result.score !== -Infinity ? -result.score : result.score

                if (normalizedScore >= targetThreshold) {
                    if (normalizedScore > maxScore) {
                        maxScore = normalizedScore
                    }
                }
            }

            if (maxScore >= defaultThreshold) {
                allResults.push({ obj, score: maxScore })
            }

            // Apply the optional scoring function
            if (options?.scoreFn) {
                allResults.forEach((result) => {
                    result.score = options.scoreFn(result)
                })
            }
        }

        // Sort the results by descending score and apply the limit of results to return if it exists
        allResults.sort((a, b) => b.score - a.score)
        if (allResults.length > limit) {
            allResults.length = limit
        }
    }

    return unifyResults(allResults)?.length
        ? unifyResults(allResults)
        : noResults
}

// Utility function to get nested values ​​in objects
var getValue = (obj, prop) => {
    if (typeof prop === 'function') return prop(obj)
    var tmp = obj[prop]
    if (tmp !== undefined) return tmp
    var segs = prop
    if (!Array.isArray(prop)) segs = prop.split('.')
    var len = segs.length
    var i = -1
    while (obj && ++i < len) obj = obj[segs[i]]
    return obj
}

const parseSearchTerms = (search) => {
    const terms = []
    const regex = /(?:[^\s"]+|"[^"]*")+/g
    let match

    while ((match = regex.exec(search)) !== null) {
        terms.push(match[0].replace(/"/g, '')) // Elimina las comillas de los términos agrupados
    }

    return terms
}

const unifyResults = (arr) => {
    return arr.filter((value, index) => {
        const _value = JSON.stringify(value)
        return (
            index ===
            arr.findIndex((obj) => {
                return JSON.stringify(obj) === _value
            })
        )
    })
}
