import { getCatalogAuthority } from '../../features/common/catalog/catalogSelectors'
import { regroupByProperty } from '../../utils/formatDataUtil'
import { RootState } from '../indexReducers'
import { createSelector } from 'reselect'

// List
export const isLoading = (state: RootState) =>
    state.connectionsReducer.loadingStatus

export const getConnections = (state: RootState) =>
    //@ts-ignore
    state.connectionsReducer.connections?.items ||
    state.connectionsReducer.connections

export const getSelectedConection = (state: RootState) =>
    state.connectionsReducer.selectedConnection

export const getFormattedConnections = createSelector(
    [getConnections, getCatalogAuthority],
    (connections, authorityIssuers) =>
        connectionsDataFormatted(connections, authorityIssuers)
)

const connectionsDataFormatted = (connections, authorityIssuers) => {
    const connectionsFormatted = connections?.map((el) => {
        el.dataReceiverId = el?.dag?.data_receiver?.id
        return el
    })

    return groupedDataAgreements(connectionsFormatted, authorityIssuers)
}

const groupedDataAgreements = (connections, authorityIssuers) => {
    const connectionsGrouped = connections?.length
        ? regroupByProperty(connections, 'dataReceiverId', 'dataAgreements')
        : []

    return getDagWithFullDataReceiver(connectionsGrouped, authorityIssuers)
}

const getDagWithFullDataReceiver = (connections, authorityIssuers) => {
    return connections?.map((el) => {
        const dataReceiver = authorityIssuers?.find(
            (authority) => authority?.id === el?.dataReceiverId
        )
        el.dataReceiver = dataReceiver
        return el
    })
}
