import React from 'react'

type InfoIconProps = {
    size?: number
    color?: string
    className?: string
}

const InfoIcon: React.FC<InfoIconProps> = React.memo((props) => {
    const { size, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.0002 2.66668C8.63637 2.66668 2.66683 8.63621 2.66683 16C2.66683 23.3638 8.63637 29.3333 16.0002 29.3333C23.364 29.3333 29.3335 23.3638 29.3335 16C29.3335 8.63622 23.364 2.66668 16.0002 2.66668Z"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.0002 10.6667L16.0002 16"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.0002 21.3333L15.9877 21.3333"
                stroke={color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
})

export default InfoIcon
