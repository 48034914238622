import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageModel } from '../../../interfaces/interfaces'
import SearchIcon from '../../../assets/iconsLibrary/simple/search/searchIcon'
import {
    getSearchableStringfiedOptions,
    latinize,
} from '../../../utils/formatDataUtil'
import style from './searchBar.module.scss'
import cx from 'classnames'
import CloseIcon from '../../../assets/iconsLibrary/simple/close/closeIcon'
import { gaColors } from '../../../assets/globalConstants'
import { fuzzySearch } from '../../../utils/fuzzySearchUtil'

export type IPropertiesToSearch = {
    text: string
    searchMultipleLanguages: boolean
    latinize: boolean
}

export type SearchBarProps = {
    options: any[]
    propertiesToSearch?: IPropertiesToSearch[]
    propertiesToFuzzySearch?: { key: string; threshold: number }[]
    icon?: ImageModel
    cleanIcon?: boolean
    border?: boolean
    placeholder?: string
    searched?: string
    className?: string
    handleSearchedChange?: (e) => void
}

export type SearchState = {
    searchableOptions: any[]
    searched: string
    searchedResult: any[]
}

const SearchBar: React.FC<SearchBarProps> = React.memo((props) => {
    const {
        options,
        icon,
        border,
        placeholder,
        propertiesToSearch,
        propertiesToFuzzySearch,
        searched,
        cleanIcon,
        className,
        handleSearchedChange,
    } = props

    const { t } = useTranslation()

    const keysToSearch = propertiesToFuzzySearch
        ? propertiesToFuzzySearch
        : propertiesToSearch || []

    const initialState = {
        searchableOptions: [],
        searched: searched || '',
        searchedResult: [],
    }

    const [state, setState] = React.useState<SearchState>(initialState)
    const [inputIsFocused, setInputIsFocused] = React.useState(false)

    useEffect(() => {
        setState({
            ...state,
            searchableOptions: getSearchableStringfiedOptions(
                options,
                keysToSearch
            ),
        })
    }, [options])

    const basicSearch = (searchString) => {
        const filteredOptions = state.searchableOptions?.filter((option) =>
            option._searchableText
                ?.toLowerCase()
                .match(searchString.toLowerCase())
        )
        return filteredOptions
    }

    const fuzzSearch = (searchString) => {
        let results =
            keysToSearch &&
            options &&
            fuzzySearch(searchString, options, {
                keys: keysToSearch,
                scoreFn: (r) => (r?.score * r?.obj.bookmarked ? 2 : 1), // if the item is bookmarked, boost its score
            })

        var keysResult = results && results[0]

        if (keysResult?.length) {
            // When using multiple `keys`, results are different. They're indexable to get each normal result
            keysResult[0]?.highlight()
            keysResult[1]?.highlight()
            keysResult.score
            keysResult.obj.title
        }

        return results?.map((el) => el.obj)
    }

    const handleTextSearchChange = (e) => {
        setState(
            e?.target?.value
                ? {
                      ...state,
                      searched: e?.target?.value || '',
                      searchedResult: state.searchedResult,
                  }
                : {
                      ...initialState,
                      searchableOptions: getSearchableStringfiedOptions(
                          options,
                          keysToSearch
                      ),
                  }
        )

        let result = options
        const searchString = latinize(e?.target?.value?.toString())

        if (searchString && searchString?.length) {
            result = propertiesToFuzzySearch
                ? fuzzSearch(searchString)
                : basicSearch(searchString)
        }

        setState(
            e?.target?.value
                ? {
                      ...state,
                      searched: e?.target?.value || '',
                      searchedResult: result,
                  }
                : {
                      ...initialState,
                      searchableOptions: getSearchableStringfiedOptions(
                          options,
                          keysToSearch
                      ),
                  }
        )
        handleSearchedChange && handleSearchedChange(result)
    }

    return (
        <div className={`${style.searchBar} ${className}`}>
            {icon ? (
                <SearchIcon
                    className={style.searchBar__icon}
                    color={gaColors.neutral600}
                    size={20}
                />
            ) : null}
            <div
                className={cx(
                    style.searchBar__inputContainer,
                    border && style.withBorder
                )}
            >
                <input
                    type="text"
                    className={cx(style.input, 'bodyRegularMD neutral600')}
                    placeholder={t(placeholder || 'public.search')}
                    value={state.searched || ''}
                    onFocus={() => setInputIsFocused(true)}
                    onBlur={() => setInputIsFocused(false)}
                    onChange={handleTextSearchChange}
                />
            </div>
            {cleanIcon && state?.searched ? (
                <div
                    className={style.searchBar__cleanIcon}
                    onClick={() => {
                        handleTextSearchChange('')
                    }}
                >
                    <CloseIcon color={gaColors.neutral100} size={12} />
                </div>
            ) : null}
        </div>
    )
})

export default SearchBar
