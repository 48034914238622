import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { apiKeysSelectors } from '../..'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import GaSelectCheckbox, {
    Selectable,
} from '../../../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import FormSection from '../../../../../Components/organisms/FormSection/FormSection'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { didsSelectors } from '../../../Dids'
import { getAliasFromDid, getDidFromAlias } from '../../../Dids/didSelectors'
import { getIssuances } from '../../../Issuance/issuanceActions'
import { setSelectedRoute } from '../../../../common/PanelScafolding/scafoldingActions'
import { getVerifierConfigs } from '../../../Verifier/verifierActions'
import { sessionSelectors } from '../../../../common/session'
import {
    clearActionStatus,
    createApiKey,
    getTenantConfig,
} from '../../apiKeysActions'
import ApiKeyPassModal from '../apiKeyPassword/ApiKeyPassModal'
import { getDids } from '../../../Dids/didActions'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import { propertiesToSearchSSIOperations } from '../apiKeysList/apiKeysList.constants'

type ICreateApiKeyProps = {}

const CreateApiKey: React.FC<ICreateApiKeyProps> = ({}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const createStatus = useSelector(apiKeysSelectors.getStatus)
    const creationError = useSelector(apiKeysSelectors.getError)
    const currentTenant = useSelector(sessionSelectors.getCurrentTenant)
    const userDids = useSelector(sessionSelectors.getUserDids)
    const allDidsAllowed = useSelector(sessionSelectors.allDidsAllowed)
    const dids = useSelector(didsSelectors.getDids)
    const didOptions = useSelector(didsSelectors.getdidConfigsVms) || [
        { DidId: '', alias: '' } as IDIDModel,
    ]
    const ssiOperations = useSelector(sessionSelectors.getSsiOperations)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canReadDids = !!(
        availableScopes &&
        userHasScopes(['readDids', 'readTenants'], availableScopes)
    )
    const canReadSSIConfigs = !!(
        availableScopes && userHasScopes(['readSSIConfigs'], availableScopes)
    )
    const [formData, setFormState] = useState({
        id: '',
        did: '',
        alias: '',
        tenant: currentTenant,
        ssi_operations: [''],
    })
    const [options, setOptions] = useState({
        tenantDids: allDidsAllowed ? didOptions : userDids || {},
        ssi_operations:
            ssiOperations.map((ssiOp) => {
                return { key: ssiOp.id, value: ssiOp.id }
            }) || [],
    })

    const { id, did, alias, tenant, ssi_operations } = formData
    const validForm =
        !!alias?.trim() && !!did?.trim() && !!ssi_operations?.length

    useEffect(() => {
        if (!userDids?.length && !!currentTenant && canReadDids) {
            dispatch(getTenantConfig(currentTenant))
        }
        if (canReadSSIConfigs) {
            dispatch(getVerifierConfigs())
            dispatch(getIssuances())
        }
        setOptions({ ...options })
    }, [currentTenant])

    useEffect(() => {
        setOptions({ ...options, tenantDids: userDids || {} })
    }, [userDids])

    useEffect(() => {
        if (
            canReadDids &&
            (!didOptions?.length || (didOptions?.length && !didOptions[0]))
        ) {
            dispatch(getDids())
        }
    }, [availableScopes])

    useEffect(() => {
        const ssiOptionsByDid = filterSsiOperationsByDid(did || '')
        setOptions({ ...options, ssi_operations: ssiOptionsByDid || [] })
    }, [userDids, did, ssiOperations])

    const create = () => {
        formData.tenant = currentTenant
        formData.id = 'override_me'
        dispatch(createApiKey(formData))
    }

    const filterSsiOperationsByDid = (selectedDid: string): Selectable[] => {
        if (selectedDid && selectedDid?.length) {
            const filteredOptions = ssiOperations?.filter((option) =>
                option.vmethodId?.includes(selectedDid)
            )
            return filteredOptions?.map((el) => {
                return { key: el.id, value: el.id }
            })
        }
        return []
    }

    function navigateToList() {
        dispatch(setSelectedRoute('api_keys'))
        history.push('api_keys')
    }

    const handleCloseModal = () => {
        dispatch(clearActionStatus()), navigateToList()
    }

    const showModal = (): boolean => {
        return (
            createStatus &&
            (createStatus as ActionStatusTypes) === ActionStatusTypes.success
        )
    }

    const [state, setState] = React.useState<{
        selections: Selectable[]
        searched: string
        searchedResult: Selectable[]
    }>({
        selections: [],
        searched: '',
        searchedResult: options?.ssi_operations || [],
    })

    function handleSearchedChange(e) {
        setState({
            selections: state.selections,
            searched: state.searched,
            searchedResult: e,
        })
    }

    return (
        <React.Fragment>
            <ScreenHeader
                title={'createApiKey.title'}
                titleClassname="heading5"
            />
            <div className={cx('margin-top')}>
                <LightPanel>
                    <FormSection
                        title={'createApiKey.subtitle'}
                        description={'createApiKey.auxText'}
                    >
                        <form>
                            <GaTextInput
                                disabled={false}
                                helperText=""
                                id="alias"
                                autoComplete="Off"
                                labelText={t('createApiKey.nameLabel')}
                                required
                                onChange={(e) => {
                                    setFormState({
                                        ...formData,
                                        alias: e.target.value,
                                    })
                                }}
                                type="text"
                                value={formData.alias}
                            />
                            <GaSelect
                                idSelect={'did'}
                                className={cx('margin-top margin-bottom')}
                                value={getAliasFromDid(didOptions, did)}
                                invalid={!did?.trim()?.length}
                                labelText={t('configApiKey.did')}
                                required
                                name={'did'}
                                permissions={{
                                    scopes: ['readDids', 'readTenants'],
                                    every: true,
                                }}
                                onChange={(e) => {
                                    let selected_did = getDidFromAlias(
                                        didOptions,
                                        e?.target?.value
                                    )
                                    setFormState({
                                        ...formData,
                                        did: selected_did?.split('#')[0] || '',
                                        ssi_operations: [],
                                    }),
                                        setOptions({
                                            ...options,
                                            ssi_operations:
                                                filterSsiOperationsByDid(
                                                    selected_did || ''
                                                ),
                                        })
                                }}
                                options={didOptions?.map(
                                    (did: IDIDModel) => did?.alias || did?.DidId
                                )}
                            />

                            <GaSelectCheckbox
                                id="ssiConfigsCheckbox"
                                className={cx('margin-top margin-bottom')}
                                labelText={t('createApiKey.ssiOperations')}
                                required
                                disabled={!options?.ssi_operations?.length}
                                permissions={{
                                    scopes: ['readSSIConfigs'],
                                    every: true,
                                }}
                                name={'ssi_operations'}
                                onChange={(e) => {
                                    setFormState({
                                        ...formData,
                                        ssi_operations: e,
                                    })
                                }}
                                checkedData={ssi_operations}
                                options={options?.ssi_operations}
                                placeholder={
                                    !options?.ssi_operations?.length
                                        ? t('createApiKey.noSsiOperations')
                                        : ''
                                }
                                handleSearchedChange={handleSearchedChange}
                                propertiesToSearch={
                                    propertiesToSearchSSIOperations
                                }
                            />
                        </form>
                    </FormSection>
                </LightPanel>
                <DarkButton
                    className={cx('marginTop32 margin-left-auto')}
                    text={'createApiKey.button'}
                    disabled={!validForm}
                    functionality={create}
                />
            </div>

            {showModal() ? (
                <ApiKeyPassModal
                    show={showModal()}
                    hideModal={handleCloseModal}
                />
            ) : null}

            {/* <StatusPanel
        successMessage={'createApiKey.success'}
        state={'createApiKey.isReady'}
        operationStatus={createStatus}
        show={
          createStatus &&
          (createStatus as ActionStatusTypes) !== ActionStatusTypes.neutral
        }
        failedText={
          t('createApiKey.failedOperation') + (creationError ? ': ' : '.')
        }
        error={creationError || ''}
        primaryButton={{ label: 'createApiKey.backToList', function: goToList }}
        secondaryButton={{
          label: 'createApiKey.backToHome',
          function: goToHome,
        }}
        handleClose={handleCloseModal}
      /> */}
        </React.Fragment>
    )
}

export default CreateApiKey
