import * as React from 'react'
import cx from 'classnames'
import { t } from 'i18next'

export type IQuantityDataCellProps = {
    data: any
}

const QuantityDataCell: React.FC<IQuantityDataCellProps> = (props) => {
    const { data } = props
    return (
        <div className={`${cx('bodyBoldSM neutral1000')}`}>
            {data === '∞' ? t('subscription.unlimited') : data}
        </div>
    )
}

export default QuantityDataCell
