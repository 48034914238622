import React from 'react'

type KeyIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const KeyIcon: React.FC<KeyIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M28.0001 2.66666L25.3335 5.33333M15.1868 15.48L20.6668 10M20.6668 10L24.6668 14L29.3335 9.33333L25.3335 5.33333M20.6668 10L25.3335 5.33333M15.1868 15.48C15.8752 16.1593 16.4225 16.968 16.7971 17.8597C17.1717 18.7514 17.3663 19.7084 17.3695 20.6755C17.3727 21.6427 17.1846 22.6009 16.816 23.4951C16.4474 24.3892 15.9055 25.2016 15.2216 25.8855C14.5378 26.5694 13.7254 27.1113 12.8312 27.4799C11.937 27.8485 10.9788 28.0366 10.0116 28.0334C9.04448 28.0301 8.08751 27.8356 7.19584 27.461C6.30417 27.0864 5.49541 26.5391 4.81612 25.8507C3.4803 24.4676 2.74114 22.6152 2.75785 20.6924C2.77455 18.7696 3.54579 16.9303 4.90545 15.5707C6.26511 14.211 8.1044 13.4398 10.0272 13.4231C11.95 13.4063 13.8024 14.1455 15.1855 15.4813L15.1868 15.48Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <path
                d="M27.9999 2.66666L25.3332 5.33333L27.9999 2.66666ZM15.1865 15.48C15.875 16.1593 16.4223 16.968 16.7969 17.8597C17.1715 18.7514 17.366 19.7084 17.3692 20.6755C17.3725 21.6427 17.1844 22.6009 16.8158 23.4951C16.4471 24.3892 15.9053 25.2016 15.2214 25.8855C14.5375 26.5694 13.7251 27.1113 12.8309 27.4799C11.9368 27.8485 10.9785 28.0366 10.0114 28.0334C9.04423 28.0301 8.08727 27.8356 7.1956 27.461C6.30393 27.0864 5.49517 26.5391 4.81588 25.8507C3.48005 24.4676 2.74089 22.6152 2.7576 20.6924C2.77431 18.7696 3.54555 16.9303 4.90521 15.5707C6.26487 14.211 8.10416 13.4398 10.0269 13.4231C11.9497 13.4063 13.8021 14.1455 15.1852 15.4813L15.1865 15.48ZM15.1865 15.48L20.6665 10L15.1865 15.48ZM20.6665 10L24.6665 14L29.3332 9.33333L25.3332 5.33333M20.6665 10L25.3332 5.33333L20.6665 10Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
            />
            <path
                d="M27.9999 2.66666L25.3332 5.33333M15.1865 15.48L20.6665 10M20.6665 10L24.6665 14L29.3332 9.33333L25.3332 5.33333M20.6665 10L25.3332 5.33333M15.1865 15.48C15.875 16.1593 16.4223 16.968 16.7969 17.8597C17.1715 18.7514 17.366 19.7084 17.3692 20.6755C17.3725 21.6427 17.1844 22.6009 16.8158 23.4951C16.4471 24.3892 15.9053 25.2016 15.2214 25.8855C14.5375 26.5694 13.7251 27.1113 12.8309 27.4799C11.9368 27.8485 10.9785 28.0366 10.0114 28.0334C9.04423 28.0301 8.08727 27.8356 7.1956 27.461C6.30393 27.0864 5.49517 26.5391 4.81588 25.8507C3.48005 24.4676 2.74089 22.6152 2.7576 20.6924C2.77431 18.7696 3.54555 16.9303 4.90521 15.5707C6.26487 14.211 8.10416 13.4398 10.0269 13.4231C11.9497 13.4063 13.8021 14.1455 15.1852 15.4813L15.1865 15.48Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export default KeyIcon
