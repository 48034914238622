import React, { useEffect, useState } from 'react'
import { clearModalState } from '../../../../../store/appIntegrationModal/appIntegrationModalActions'
import { useDispatch, useSelector } from 'react-redux'
import { IAppIntegrationModel } from '../../../../../interfaces/interfaces'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'
import { getAppIntegrationDataSaved } from '../../../../../store/appIntegrationModal/appIntegrationModalSelectors'
import { editAppIntModalStepsTwo } from '../../data/AppIntegrationsList.constants'
import {
    arraysHasSameData,
    checkIfURLIsSecure,
} from '../../../../../utils/formUtils'
import ConfirmationModal from '../../../../../Components/molecules/modals/confirmation/Confirmation'
import { updateAppIntegration } from '../../../../../store/appIntegrations/appIntegrationsActions'

type IEditAppIntProps = {
    selectedAppIntegration: IAppIntegrationModel
    setShowEditAppIntModal: (x?: any) => void
}

const EditAppInt: React.FC<IEditAppIntProps> = (props) => {
    const { selectedAppIntegration, setShowEditAppIntModal } = props
    const dispatch = useDispatch()

    // Constants and States
    const formatedSelectedAppInt = JSON.parse(
        JSON.stringify(selectedAppIntegration)
    )

    const [companyLogoChecked, setCompanyLogoChecked] = useState(
        !!formatedSelectedAppInt?.logo_uri
    )
    const initialFormState: IAppIntegrationModel = {
        ...selectedAppIntegration,
        client_name: formatedSelectedAppInt?.client_name || '',
        redirect_uris: formatedSelectedAppInt?.redirect_uris?.length
            ? formatedSelectedAppInt?.redirect_uris
            : [''],
        post_logout_redirect_uris: formatedSelectedAppInt
            ?.post_logout_redirect_uris?.length
            ? formatedSelectedAppInt?.post_logout_redirect_uris
            : [''],
        grant_types: formatedSelectedAppInt?.grant_types?.length
            ? formatedSelectedAppInt?.grant_types
            : [
                  'client_credentials',
                  'authorization_code',
                  'refresh_token',
                  'implicit',
              ],
        response_types: formatedSelectedAppInt?.response_types?.length
            ? formatedSelectedAppInt?.response_types
            : ['id_token', 'code', 'token'],
        scope: formatedSelectedAppInt?.scope || 'openid',
        formScopes: formatedSelectedAppInt?.formScopes || [],
        audience: formatedSelectedAppInt?.audience || null,
        owner: formatedSelectedAppInt?.owner || '',
        policy_uri: formatedSelectedAppInt?.policy_uri || '',
        allowed_cors_origins: formatedSelectedAppInt?.allowed_cors_origins
            ?.length
            ? formatedSelectedAppInt?.allowed_cors_origins
            : [],
        tos_uri: formatedSelectedAppInt?.tos_uri || '',
        contacts: formatedSelectedAppInt?.contacts?.length
            ? formatedSelectedAppInt?.contacts
            : [],
        client_secret_expires_at:
            formatedSelectedAppInt?.client_secret_expires_at || 0,
        subject_type: formatedSelectedAppInt?.subject_type || 'public',
        token_endpoint_auth_method:
            formatedSelectedAppInt?.token_endpoint_auth_method ||
            'client_secret_basic',
        request_uris: formatedSelectedAppInt?.request_uris || [],
        userinfo_signed_response_alg:
            formatedSelectedAppInt?.userinfo_signed_response_alg || 'none',
        metadata: formatedSelectedAppInt?.metadata || null,
        display_tutorial: formatedSelectedAppInt?.display_tutorial,
        logo_uri: companyLogoChecked
            ? formatedSelectedAppInt?.logo_uri
            : undefined,
    }

    const [editAppIntCurrentStep, setEditAppIntCurrentStep] = useState(
        editAppIntModalStepsTwo[0]
    )
    const [showCloseModal, setShowCloseModal] = useState(false)
    const [showFormErrorModal, setShowFormErrorModal] = useState(false)

    const [formData, setFormState] = useState(initialFormState)
    const {
        client_name,
        formScopes,
        redirect_uris,
        policy_uri,
        tos_uri,
        logo_uri,
        display_tutorial,
        post_logout_redirect_uris,
    } = formData

    const firstFormModified =
        ((client_name?.trim() || selectedAppIntegration?.client_name?.trim()) &&
            client_name?.trim() !==
                selectedAppIntegration?.client_name?.trim()) ||
        policy_uri?.trim() !== selectedAppIntegration?.policy_uri?.trim() ||
        tos_uri?.trim() !== selectedAppIntegration?.tos_uri?.trim() ||
        !arraysHasSameData(formScopes, selectedAppIntegration?.formScopes) ||
        !arraysHasSameData(
            redirect_uris,
            selectedAppIntegration?.redirect_uris
        ) ||
        (((!!post_logout_redirect_uris?.length &&
            post_logout_redirect_uris[0]) ||
            !!selectedAppIntegration?.post_logout_redirect_uris) &&
            !arraysHasSameData(
                post_logout_redirect_uris,
                selectedAppIntegration?.post_logout_redirect_uris
            ))

    const secondFormModified =
        ((logo_uri?.trim() || selectedAppIntegration?.logo_uri?.trim()) &&
            logo_uri?.trim() !== selectedAppIntegration?.logo_uri?.trim()) ||
        !!display_tutorial !== !!selectedAppIntegration?.display_tutorial

    const formIsValid =
        client_name?.trim() &&
        policy_uri?.trim() &&
        tos_uri?.trim() &&
        formScopes?.length &&
        formScopes[0] &&
        redirect_uris?.length &&
        redirect_uris[0] &&
        (!logo_uri?.trim()?.length || checkIfURLIsSecure(logo_uri?.trim()))

    // Selectors
    const savedAppInt = useSelector(getAppIntegrationDataSaved)

    // Effects
    useEffect(() => {}, [editAppIntCurrentStep])

    // Functions
    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formData,
            [name]: value,
        })
    }

    const removeLastRedirectUrl = (index) => {
        const modifiedRedirectUris = formData?.redirect_uris
        modifiedRedirectUris?.splice(index, 1)
        const redirectUrisWithoutLastItem = modifiedRedirectUris

        setFormState({
            ...formData,
            redirect_uris: redirectUrisWithoutLastItem,
        })
    }

    const saveButtonFunction = () => {
        if (formIsValid) {
            const formattedAppInt = formatDataToSend()
            dispatch(showScreenLoaderAction())
            dispatch(updateAppIntegration(formattedAppInt))
        } else {
            setShowFormErrorModal(true)
        }
    }

    const formatDataToSend = () => {
        const formattedData = {
            ...formData,
            scope: formScopes?.length
                ? `openid ${formScopes?.join(' ')}`
                : 'openid',
            logo_uri: companyLogoChecked ? formData?.logo_uri : undefined,
        }

        if (
            post_logout_redirect_uris?.length &&
            !post_logout_redirect_uris[0]?.length
        ) {
            delete formattedData?.post_logout_redirect_uris
        }

        delete formattedData?.formScopes
        delete formattedData?._searchableText

        return formattedData
    }

    const handleScopeChange = (scopes) => {
        setFormState({ ...formData, formScopes: scopes })
    }

    const handleInputURLChange = (event, index, name) => {
        const target = event.target
        const value = target.value
        let formDataURLValue = [...formData[name]]

        formDataURLValue[index] = value?.trim()

        setFormState({
            ...formData,
            [name]: formDataURLValue,
        })
    }

    const removeUrl = (index, name) => {
        const modifiedRedirectUris = formData[name]
        modifiedRedirectUris?.splice(index, 1)
        const redirectUrisWithoutLastItem = modifiedRedirectUris

        setFormState({
            ...formData,
            [name]: redirectUrisWithoutLastItem,
        })
    }

    const setTutorialChecked = (checked: boolean) =>
        setFormState({
            ...formData,
            display_tutorial: checked,
        })

    return (
        <>
            {editAppIntCurrentStep && (
                <editAppIntCurrentStep.content
                    hideModal={() => {
                        secondFormModified || firstFormModified
                            ? setShowCloseModal(true)
                            : (setEditAppIntCurrentStep(
                                  editAppIntModalStepsTwo[0]
                              ),
                              setShowEditAppIntModal(false),
                              dispatch(clearModalState()))
                    }}
                    setEditAppIntCurrentStep={() => {
                        setEditAppIntCurrentStep(
                            editAppIntModalStepsTwo[
                                editAppIntCurrentStep?.order === 0 ? 1 : 0
                            ]
                        )
                    }}
                    savedAppInt={savedAppInt}
                    editAppIntCurrentStep={editAppIntCurrentStep}
                    editAppIntModalStepsTwo={editAppIntModalStepsTwo}
                    handleInputChange={handleInputChange}
                    saveButtonFunction={saveButtonFunction}
                    formData={formData}
                    handleInputUrlChange={handleInputURLChange}
                    removeUrl={removeUrl}
                    handleScopeChange={handleScopeChange}
                    removeLastRedirectUrl={removeLastRedirectUrl}
                    companyLogoChecked={companyLogoChecked}
                    secondFormModified={secondFormModified}
                    firstFormModified={firstFormModified}
                    formsModified={secondFormModified || firstFormModified}
                    setCompanyLogoChecked={setCompanyLogoChecked}
                    setTutorialChecked={setTutorialChecked}
                />
            )}
            {showCloseModal ? (
                <ConfirmationModal
                    title={'appIntegrations.edit.sureCloseTitle'}
                    description={'appIntegrations.edit.sureCloseDesc'}
                    show={true}
                    continueText={'public.yesClose'}
                    confirmFunction={() => {
                        setEditAppIntCurrentStep(editAppIntModalStepsTwo[0])
                        setShowEditAppIntModal(false)
                        dispatch(clearModalState())
                    }}
                    hideModal={() => {
                        setShowCloseModal(false)
                    }}
                />
            ) : null}
            {showFormErrorModal ? (
                <ConfirmationModal
                    title={'appIntegrations.edit.formErrorsTitle'}
                    description={'appIntegrations.edit.formErrorsDesc'}
                    show={true}
                    noCancel
                    continueText={'appIntegrations.edit.okGoBack'}
                    confirmFunction={() => {
                        setShowFormErrorModal(false)
                    }}
                    hideModal={() => {
                        setShowFormErrorModal(false)
                    }}
                />
            ) : null}
        </>
    )
}

export default EditAppInt
