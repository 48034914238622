// import axios from 'axios-observable'
import { throwError } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { history } from './history'
import localStorageService from './localStorageService'

function handleNetworkErrors(error: any) {
    let errorToReturn: any = undefined
    switch (error.status) {
        // Expired Token
        case 401:
            sessionStorage.clear()
            localStorageService?.deleteLocalStorageData('TMClosed')
            history.push('/login')
            break
        // Invalid token
        case 403:
            errorToReturn = throwError(error)
            break
        case 422:
            errorToReturn = throwError(error)
            break
        case 429:
            errorToReturn = throwError(error)
            break
        case 409:
            errorToReturn = throwError(error)
            break
        case 500:
            errorToReturn = throwError(error)
            break
        default:
            return error
    }
    return !!errorToReturn ? errorToReturn : null
}

export interface IGaasReq {
    url: string
    method: string
    headers?: any
    body?: any
    vouch?: any
    // TODO: remove when login API is ready
    origin?: string
    referer?: string
    withCredentials?: boolean
}

export interface ProcessEnv {
    [key: string]: string | undefined
}

export const gaasReq = (payload: IGaasReq) => {
    const API_URI = process.env.REACT_APP_GAAS_API
    const VOUCH_URI = process.env.REACT_APP_VOUCH_API
    const API_VERSION = process.env.REACT_APP_API_VERSION
    // @ts-ignore
    //TODO check undefined warning
    const API = !payload.vouch ? API_URI + API_VERSION : VOUCH_URI
    return ajax({
        url: payload.url,
        method: payload.method,
        headers: {
            //Authorization: 'jwt ' + authHeeader,
            ...payload.headers,
        },
        body: payload.body,
        withCredentials: true,
        crossDomain: true,
    }).pipe(
        map((response) => {
            return response
        }),
        catchError((err) => {
            return handleNetworkErrors(err)
        })
    )
}
