import React from 'react'
import CredentialPreviewModal from '../../CredentialPreview'
import { t } from 'i18next'
import cx from 'classnames'
import {
    ButtonModel,
    ICredentialRequestedModel,
} from '../../../../../../interfaces/interfaces'
import CredentialCardPreview from '../../../../../organisms/CredentialCard/CredentialCardPreview/CredentialCardPreview'
import styles from '../../credentialPreviewModal.module.scss'
import GaImage from '../../../../../atoms/Image/Image'
import { brandImages } from '../../../../../../assets/globalConstants'
import CardListSkeleton from '../../../../skeletons/cardListSkeleton/CardListSkeleton'
import MediumModal from '../../../Medium/MediumModal'

type ICredentialsRequirementsPreviewProps = {
    issuanceTemplate?: boolean
    saveButton?: ButtonModel
    showSaveButton?: boolean
    requiredCredentials?: ICredentialRequestedModel[]
    optionalCredentials?: ICredentialRequestedModel[]
    showPurposes?: boolean
    smallResolution: boolean
    show: boolean
    hideModal: (x?) => void
}

type INoCredentialsYetProps = {
    title: string
    description: string
}

const NoCredentialsYet: React.FC<INoCredentialsYetProps> = (props) => {
    const { title, description } = props

    return (
        <div className={styles.noReqOrOptCredentials}>
            <GaImage image={brandImages.credExample} />

            <div>
                <p className={cx('heading6')}>{t(title)}</p>
                <p className={cx('bodyRegularSM neutral700 marginTop8')}>
                    {t(description)}
                </p>
            </div>
        </div>
    )
}

const CredentialsRequirementsPreview: React.FC<
    ICredentialsRequirementsPreviewProps
> = (props) => {
    const {
        issuanceTemplate,
        saveButton,
        showSaveButton,
        requiredCredentials,
        optionalCredentials,
        showPurposes,
        smallResolution,
        show,
        hideModal,
    } = props

    const thereAreCreds =
        requiredCredentials?.length || optionalCredentials?.length

    return (
        <>
            {smallResolution ? (
                <>
                    {show ? (
                        <MediumModal
                            title={t(
                                issuanceTemplate
                                    ? 'editIssuance.step4.credPrevTitle'
                                    : 'editVerifier.step3.credPrevTitle'
                            )}
                            subtitle={t(
                                issuanceTemplate
                                    ? 'editIssuance.step4.credPrevDescription'
                                    : 'editVerifier.step3.credPrevDescription'
                            )}
                            titleClassName={cx(
                                'heading6 neutral1000 marginBottom4'
                            )}
                            subtitleClassName={cx('bodyRegularSM neutral700')}
                            headerClose={true}
                            show={show}
                            handleClose={hideModal}
                            className={styles.previewModalResponsive}
                        >
                            <CredentialPreviewModal
                                titleHeader=""
                                descriptionHeader=""
                                modalResponsiveView={true}
                                hasBottomAlert={!!thereAreCreds}
                            >
                                {thereAreCreds ? (
                                    <div className={styles.requirementsPreview}>
                                        <p
                                            className={cx(
                                                'marginBottom12 marginRightAuto bodyRegularXS neutral800'
                                            )}
                                        >
                                            {t('editIssuance.step4.required')}{' '}
                                            <span className={cx('alert300')}>
                                                *
                                            </span>
                                        </p>
                                        {requiredCredentials?.length ? (
                                            <div
                                                className={
                                                    styles.credRequirements
                                                }
                                            >
                                                {requiredCredentials?.map(
                                                    (el, index) => (
                                                        <CredentialCardPreview
                                                            key={
                                                                'reqCredPreview__' +
                                                                index
                                                            }
                                                            credential={el}
                                                            isOnRequirements
                                                            showPurposes={
                                                                showPurposes
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <NoCredentialsYet
                                                title={
                                                    'editIssuance.step4.noRequiredCredYetTitle'
                                                }
                                                description={
                                                    'editIssuance.step4.noRequiredCredYetDesc'
                                                }
                                            />
                                        )}

                                        <p
                                            className={cx(
                                                'marginTop20 marginRightAuto marginBottom12 bodyRegularXS neutral800'
                                            )}
                                        >
                                            {t(
                                                'editIssuance.step4.optionalCredsPreviewText'
                                            )}
                                        </p>
                                        {optionalCredentials?.length ? (
                                            <div
                                                className={
                                                    styles.credRequirements
                                                }
                                            >
                                                {optionalCredentials?.map(
                                                    (el, index) => (
                                                        <CredentialCardPreview
                                                            key={
                                                                'optCredPreview__' +
                                                                index
                                                            }
                                                            credential={el}
                                                            isOnRequirements
                                                            showPurposes={
                                                                showPurposes
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <NoCredentialsYet
                                                title={
                                                    'editIssuance.step4.noOptionalCredYetTitle'
                                                }
                                                description={
                                                    'editIssuance.step4.noOptionalCredYetDesc'
                                                }
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <CardListSkeleton
                                        key={'credentials__skeleton'}
                                        className={styles.credentialsSkeleton}
                                        cardsNumber={1}
                                    />
                                )}
                            </CredentialPreviewModal>
                        </MediumModal>
                    ) : null}
                </>
            ) : (
                <CredentialPreviewModal
                    titleHeader={t(
                        issuanceTemplate
                            ? 'editIssuance.step4.credPrevTitle'
                            : 'editVerifier.step3.credPrevTitle'
                    )}
                    descriptionHeader={t(
                        issuanceTemplate
                            ? 'editIssuance.step4.credPrevDescription'
                            : 'editVerifier.step3.credPrevDescription'
                    )}
                    saveButton={saveButton}
                    showSaveButton={showSaveButton ? showSaveButton : false}
                    hasBottomAlert={!!thereAreCreds}
                >
                    {thereAreCreds ? (
                        <div className={styles.requirementsPreview}>
                            <p
                                className={cx(
                                    'marginBottom12 marginRightAuto bodyRegularXS neutral800'
                                )}
                            >
                                {t('editIssuance.step4.required')}{' '}
                                <span className={cx('alert300')}>*</span>
                            </p>
                            {requiredCredentials?.length ? (
                                <div className={styles.credRequirements}>
                                    {requiredCredentials?.map((el, index) => (
                                        <CredentialCardPreview
                                            key={'reqCredPreview__' + index}
                                            credential={el}
                                            isOnRequirements
                                            showPurposes={showPurposes}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <NoCredentialsYet
                                    title={
                                        'editIssuance.step4.noRequiredCredYetTitle'
                                    }
                                    description={
                                        'editIssuance.step4.noRequiredCredYetDesc'
                                    }
                                />
                            )}

                            <p
                                className={cx(
                                    'marginTop20 marginRightAuto marginBottom12 bodyRegularXS neutral800'
                                )}
                            >
                                {t(
                                    'editIssuance.step4.optionalCredsPreviewText'
                                )}
                            </p>
                            {optionalCredentials?.length ? (
                                <div className={styles.credRequirements}>
                                    {optionalCredentials?.map((el, index) => (
                                        <CredentialCardPreview
                                            key={'optCredPreview__' + index}
                                            credential={el}
                                            isOnRequirements
                                            showPurposes={showPurposes}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <NoCredentialsYet
                                    title={
                                        'editIssuance.step4.noOptionalCredYetTitle'
                                    }
                                    description={
                                        'editIssuance.step4.noOptionalCredYetDesc'
                                    }
                                />
                            )}
                        </div>
                    ) : (
                        <CardListSkeleton
                            key={'credentials__skeleton'}
                            className={styles.credentialsSkeleton}
                            cardsNumber={1}
                        />
                    )}
                </CredentialPreviewModal>
            )}
        </>
    )
}

export default CredentialsRequirementsPreview
