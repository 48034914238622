import AuthProcess2FA from '../../../../Components/molecules/modals/ScanModal/Steps/AuthProcess2FA'
import AuthProcessQR from '../../../../Components/molecules/modals/ScanModal/Steps/AuthProcessQR'
import ConnectionForm from '../../../../Components/molecules/modals/ScanModal/Steps/ConnectionForm'
import ServiceRequirements from '../../../../Components/molecules/modals/ScanModal/Steps/ServiceRequirements'
import { StepModalModel } from '../../../../interfaces/interfaces'
import { threshold } from '../../../../utils/fuzzySearchUtil'

export const jp = require('jsonpath')

export const imagesTypes = ['face', 'profile', 'Image', 'portrait']
export const faceTypes = ['face', 'profile', 'portrait']

export const multiTypeCredentials = [
    'studentIdCredential',
    'vIdCredential',
    'diplomaCredential',
    'transcriptOfRecordsCredential',
    'bankAccountCredential',
]

export const subClaims = {
    student: 'academicInstitutionCredential',
    university: 'academicInstitutionCredential',
    government: 'lastNameCredential',
    address: 'cityCredential',
    vid: 'vIdCredential',
}

export const timeFields = [
    'issuanceDate',
    'created',
    'issued',
    'validFrom',
    'expirationDate',
]

export const scanRequestSteps: StepModalModel[] = [
    {
        id: 1,
        order: 0,
        content: ConnectionForm,
    },
    {
        id: 2,
        order: 1,
        content: ServiceRequirements,
    },
    // Commented until 2AF is available
    // {
    //     id: 3,
    //     order: 2,
    //     content: AuthProcessQR,
    // },
    // {
    //     id: 4,
    //     order: 3,
    //     content: AuthProcess2FA,
    // },
]

export const propertiesToFuzzySearchCredentials = [
    { key: 'mainClaim', threshold: threshold.medium },
    { key: 'groupName.en', threshold: threshold.medium },
    { key: 'groupName.es', threshold: threshold.medium },
    { key: 'issuer', threshold: threshold.medium },
    { key: 'rawExpirationDate', threshold: threshold.low },
    { key: 'groupName', threshold: threshold.medium },
    { key: 'rawDate', threshold: threshold.low },
    { key: 'expirationDate', threshold: threshold.low },
    { key: 'date', threshold: threshold.low },
]
