import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { issuanceSelectors } from '../..'
import { gaImages } from '../../../../../assets/globalConstants'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaImage from '../../../../../Components/atoms/Image/Image'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import AddToApiKeyModal from '../../../../../Components/molecules/modals/AddToApiKeyModal/AddToApiKeyModal'
import InfoPanelWithAction from '../../../../../Components/molecules/notification/panels/info/infoPanelWithAction/InfoPanelWithAction'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import { IApiKeyModel } from '../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { apiKeysSelectors } from '../../../ApiKeys'
import { getApiKeys, updateApiKey } from '../../../ApiKeys/apiKeysActions'
import { apiKeysScopes } from '../../../ApiKeys/views/apiKeysList/apiKeysList.constants'
import { sandboxScopes } from '../../../Home/HomeComponents/sandboxModal/sandboxModalConstants'
import { setSelectedRoute } from '../../../../common/PanelScafolding/scafoldingActions'
import { sessionSelectors } from '../../../../common/session'
import { getUserOrganization } from '../../../../common/session/sessionSelectors'
import { clearFormState, clearIssuance } from '../../issuanceActions'
import TestModal from '../testModal/TestModal'
import styles from './successEnd.module.scss'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import cx from 'classnames'
import ShieldCheckIcon from '../../../../../assets/iconsLibrary/shiedlCheck/shieldCheckIcon'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import Tag from '../../../../../Components/atoms/tag/Tag'

type ISuccessEndProps = {}

const SuccessEnd: React.FC<ISuccessEndProps> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const userOrganization = useSelector(getUserOrganization)
    const issuer = useSelector(issuanceSelectors.getSelectedIssuance)
    const apiKeysListUnfiltered = useSelector(apiKeysSelectors.getApiKeys)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const apiKeysList = apiKeysListUnfiltered?.filter((ak: IApiKeyModel) =>
        ak.ssi_operations?.includes(issuer?.id)
    )
    const apiKeysLoading = useSelector(apiKeysSelectors.loadingStatus)

    const canReadApikeys = !!(
        availableScopes && userHasScopes(apiKeysScopes?.read, availableScopes)
    )
    const canEditApikeys = !!(
        availableScopes && userHasScopes(apiKeysScopes?.edit, availableScopes)
    )
    const canCreateApiKey = !!(
        availableScopes && userHasScopes(apiKeysScopes?.create, availableScopes)
    )
    const canUseSandbox = !!(
        availableScopes && userHasScopes(sandboxScopes, availableScopes)
    )
    const canAddToApiKeys = canEditApikeys && canReadApikeys

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    const [showAddApiKeysModal, setShowAddApiKeyModal] = React.useState(false)
    const showAddApiKeyModal = () => setShowAddApiKeyModal(true)
    const hideAddApiKeyModal = () => setShowAddApiKeyModal(false)

    function navigate(route: string) {
        history.push(route)
    }

    useEffect(() => {
        canReadApikeys && !apiKeysLoading && dispatch(getApiKeys())
    }, [])

    const clearState = () => {
        dispatch(clearFormState())
        dispatch(clearIssuance())
    }

    async function addConfigurationToApiKeys(apiKeys: IApiKeyModel[]) {
        for (let apiKey of apiKeys) {
            if (issuer && issuer?.id) {
                apiKey?.ssi_operations?.push(issuer?.id)
                await dispatch(updateApiKey(apiKey, apiKey?.id))
            }
        }
        apiKeys?.length && !apiKeysLoading ? dispatch(getApiKeys()) : null
        hideAddApiKeyModal()
        canUseSandbox && setShowModal(true)
    }

    const navigateToCreateApiKey = () => {
        dispatch(setSelectedRoute('create-apikey'))
        history.push('create-apikey')
    }

    const getButton = () => {
        const thereAreApiKeysCreated = apiKeysListUnfiltered?.length > 0
        const ssiIsAsignedToApiKey = apiKeysList?.length > 0
        let button

        // Button Options
        const buttonToAddApiKey = {
            label: 'editVerifier.addApiKey',
            function: showAddApiKeyModal,
        }

        const buttonToCreateApiKey = canCreateApiKey
            ? {
                  label: 'editVerifier.addApiKey',
                  function: navigateToCreateApiKey,
              }
            : undefined

        const buttonToTestSSI = canUseSandbox
            ? {
                  label: 'editVerifier.tryIt',
                  function: showModal,
              }
            : undefined

        const buttonForAddToOrCreateApiKey = thereAreApiKeysCreated
            ? buttonToAddApiKey
            : buttonToCreateApiKey
        // get the current button option
        if (canAddToApiKeys) {
            button = !ssiIsAsignedToApiKey
                ? buttonForAddToOrCreateApiKey
                : buttonToTestSSI
        } else {
            button = undefined
        }

        return button
    }
    const testButton = getButton()

    return (
        <div className={styles.successEndContainer}>
            <LightPanel className={styles.successEndContainer__messagePanel}>
                <GaImage
                    className={styles.successEndContainer__messagePanel__icon}
                    image={gaImages.greenCheckSvg}
                />
                <p
                    className={cx(
                        'heading5 neutral1000',
                        styles.successEndContainer__messagePanel__successMessage
                    )}
                >
                    {' '}
                    {t('editIssuance.configurationSuccess')}{' '}
                </p>
            </LightPanel>
            <LightPanel className={styles.successEndContainer__examplePanel}>
                <div
                    className={styles.successEndContainer__examplePanel__header}
                >
                    <p
                        className={cx(
                            styles.successEndContainer__serviceName,
                            'heading5 neutral1000'
                        )}
                    >
                        {' '}
                        {issuer?.id}{' '}
                    </p>
                    <Tag
                        text={t('editIssuance.isUpAndRunning')}
                        color={'grey'}
                        className={cx('neutral700')}
                    />
                </div>
                <p
                    className={cx(
                        styles.successEndContainer__examplePanel__description,
                        'buttonSM neutral700'
                    )}
                >
                    {' '}
                    {t('editIssuance.finishDescription')}{' '}
                </p>
                <div className={styles.card}>
                    <div className={styles.card__leftSide}>
                        <GaImage
                            className={styles.card__leftSide__icon}
                            image={gaImages.credentialIconDefault}
                        />
                    </div>
                    <div className={styles.card__infoSide}>
                        <span
                            className={cx(
                                'buttonSM neutral1000',
                                styles.card__infoSide__orgName
                            )}
                        >
                            {issuer?.credentialGroup?.toUpperCase()}
                        </span>
                        <p
                            className={cx(
                                'bodyBoldXL primary700',
                                styles.card__infoSide__appId
                            )}
                        >
                            {userOrganization}
                        </p>
                    </div>
                    <div className={styles.card__rightSide}>
                        <ShieldCheckIcon
                            color="#3D9A4E"
                            size={24}
                            style="filled"
                        />
                    </div>
                </div>
                <div
                    className={
                        styles.successEndContainer__examplePanel__buttons
                    }
                >
                    {testButton || canAddToApiKeys ? (
                        <>
                            {!(
                                apiKeysList?.length > 0 &&
                                canUseSandbox &&
                                testButton
                            )
                                ? !(
                                      apiKeysList?.length > 0 && !canUseSandbox
                                  ) && (
                                      <DarkButton
                                          className={cx('width100')}
                                          text={testButton?.label}
                                          functionality={testButton?.function}
                                          disabled={false}
                                      />
                                  )
                                : testButton && (
                                      <DarkButton
                                          className={cx(
                                              'width100',
                                              styles.successEndContainer__buttons__did
                                          )}
                                          text={testButton.label}
                                          disabled={false}
                                          functionality={testButton.function}
                                      />
                                  )}
                        </>
                    ) : (
                        <PermissionsPanel
                            specialViewPermission
                            scopes={apiKeysScopes?.addSSIToApiKey}
                            title={
                                'public.permissionsAddSSIToApiKeyWarningTitle'
                            }
                            content={'public.permissionsAddSSIToApiKeyWarning'}
                        />
                    )}
                </div>
            </LightPanel>
            {!!show ? <TestModal show={show} hideModal={hideModal} /> : null}
            {!!showAddApiKeysModal ? (
                <AddToApiKeyModal
                    configurationDID={issuer?.prConfig?.vmethodId}
                    configurationId={issuer?.id}
                    hideModal={hideAddApiKeyModal}
                    show={true}
                    updateApiKeys={addConfigurationToApiKeys}
                />
            ) : null}
        </div>
    )
}

export default SuccessEnd
