import React from 'react';
import {ImageModel} from '../../../../interfaces/interfaces';
import GaImage from '../../Image/Image';
import styles from './screenTitleIcon.module.scss';

type ScreenTitleIconProps = {
    icon: ImageModel
}

const ScreenTitleIcon: React.FC<ScreenTitleIconProps> = React.memo((props) => {
    const {icon} = props;

    return <GaImage className={styles.screenTitleIcon} image={icon} />;
})

export default ScreenTitleIcon;
