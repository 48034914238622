import {
    ILicenseItem,
    ITierModel,
    ISubscriptionInfo,
    LicenseToPreselect,
    PaymentMethodModel,
    StepModel,
} from '../../../interfaces/interfaces'
import {
    ICancelDowngrade,
    ICancelDowngradeFailed,
    ICancelDowngradeSuccess,
    ICancelSubscription,
    ICancelSubscriptionFailed,
    ICancelSubscriptionSuccess,
    IGetClientSecret,
    IGetClientSecretFailed,
    IGetClientSecretSuccess,
    IGetCurrentSubscriptionInfo,
    IGetTiers,
    IGetTiersFailed,
    IGetTiersSuccess,
    IGetSubscriptionInfo,
    IGetSubscriptionInfoFailed,
    IGetSubscriptionInfoSuccess,
    IPreselectLicense,
    IRemoveClientSecret,
    IRequestSubscription,
    IRequestSubscriptionFailed,
    IRequestSubscriptionSuccess,
    IResetRequestSubscriptionStatus,
    ISendPaymentMethod,
    ISendPaymentMethodFailed,
    ISendPaymentMethodSuccess,
    ISetCurrentLicense,
    ISetCurrentTier,
    ISetCurrentStep,
    ISetExcededLimits,
    ISetNewLicense,
    ISetReachedLimits,
    ISetSelectedPeriod,
    ISetShowAccountActivated,
    IUpdateLicense,
    IUpdateLicenseFailed,
    IUpdateLicenseSuccess,
    LicensesTypes,
} from './licensesTypes'

export const updateLicenseAction = (
    newLicense: ITierModel,
    priceId: string,
    orgID: string
): IUpdateLicense => {
    return {
        type: LicensesTypes.UPDATE_LICENSE,
        payload: {
            newLicense: newLicense,
            priceId: priceId,
            orgID: orgID,
        },
    }
}

export const updateLicenseActionSuccess = (
    subscriptionInfo?
): IUpdateLicenseSuccess => {
    return {
        type: LicensesTypes.UPDATE_LICENSE_SUCCESS,
        payload: { subscriptionInfo },
    }
}

export const updateLicenseActionFailed = (error: any): IUpdateLicenseFailed => {
    return {
        type: LicensesTypes.UPDATE_LICENSE_FAILED,
        error: error,
    }
}

export const requestSubscriptionAction = (
    priceId: string,
    orgId: string,
    message?: string
): IRequestSubscription => {
    return {
        type: LicensesTypes.REQUEST_SUBSCRIPTION,
        payload: {
            priceId,
            orgId,
            message,
        },
    }
}

export const requestSubscriptionActionSuccess = (
    subscriptionInfo?
): IRequestSubscriptionSuccess => {
    return {
        type: LicensesTypes.REQUEST_SUBSCRIPTION_SUCCESS,
        payload: { subscriptionInfo },
    }
}

export const requestSubscriptionActionFailed = (
    error: any
): IRequestSubscriptionFailed => {
    return {
        type: LicensesTypes.REQUEST_SUBSCRIPTION_FAILED,
        error: error,
    }
}

export const resetRequestSubscriptionStatusAction =
    (): IResetRequestSubscriptionStatus => {
        return {
            type: LicensesTypes.RESET_REQUEST_SUBSCRIPTION_STATUS,
        }
    }

export const getTiersAction = (): IGetTiers => {
    return {
        type: LicensesTypes.GET_TIERS,
    }
}

export const getTiersSuccessAction = (
    tiers: ITierModel[]
): IGetTiersSuccess => {
    return {
        type: LicensesTypes.GET_TIERS_SUCCESS,
        payload: {
            tiers: tiers,
        },
    }
}

export const getTiersFailedAction = (): IGetTiersFailed => {
    return {
        type: LicensesTypes.GET_TIERS_FAILED,
    }
}

export const getClientSecretAction = (): IGetClientSecret => {
    return {
        type: LicensesTypes.GET_CLIENT_SECRET,
    }
}

export const getClientSecretSuccessAction = (
    clientSecret: string
): IGetClientSecretSuccess => {
    return {
        type: LicensesTypes.GET_CLIENT_SECRET_SUCCESS,
        payload: {
            clientSecret: clientSecret,
        },
    }
}

export const getClientSecretFailedAction = (
    error?: any
): IGetClientSecretFailed => {
    return {
        type: LicensesTypes.GET_CLIENT_SECRET_FAILED,
        error: error?.status,
    }
}

export const sendPaymentMethodAction = (
    paymentMethod?: PaymentMethodModel
): ISendPaymentMethod => {
    return {
        type: LicensesTypes.SEND_PAYMENT_METHOD,
        payload: { paymentMethod: paymentMethod },
    }
}

export const sendPaymentMethodSuccessAction = (): ISendPaymentMethodSuccess => {
    return {
        type: LicensesTypes.SEND_PAYMENT_METHOD_SUCCESS,
    }
}

export const sendPaymentMethodFailedAction = (
    error?: any
): ISendPaymentMethodFailed => {
    return {
        type: LicensesTypes.SEND_PAYMENT_METHOD_FAILED,
    }
}

export const getSubscriptionInfoAction = (
    hideLoader?: boolean
): IGetSubscriptionInfo => {
    return {
        type: LicensesTypes.GET_SUBSCRIPTION_INFO,
        payload: { hideLoader },
    }
}

export const getSubscriptionInfoSuccessAction = (
    subscriptionInfo: ISubscriptionInfo
): IGetSubscriptionInfoSuccess => {
    return {
        type: LicensesTypes.GET_SUBSCRIPTION_INFO_SUCCESS,
        payload: {
            subscriptionInfo: subscriptionInfo,
        },
    }
}

export const getSubscriptionInfoFailedAction =
    (): IGetSubscriptionInfoFailed => {
        return {
            type: LicensesTypes.GET_SUBSCRIPTION_INFO_FAILED,
        }
    }

export const getCurrentSubscriptionInfoAction =
    (): IGetCurrentSubscriptionInfo => {
        return {
            type: LicensesTypes.GET_CURRENT_SUBSCRIPTION_INFO,
        }
    }

export const cancelSubscriptionAction = (): ICancelSubscription => {
    return {
        type: LicensesTypes.CANCEL_SUBSCRIPTION,
    }
}

export const cancelSubscriptionSuccessAction = (
    subscription: string
): ICancelSubscriptionSuccess => {
    return {
        type: LicensesTypes.CANCEL_SUBSCRIPTION_SUCCESS,
        payload: {
            subscription: subscription,
        },
    }
}

export const cancelSubscriptionFailedAction = (): ICancelSubscriptionFailed => {
    return {
        type: LicensesTypes.CANCEL_SUBSCRIPTION_FAILED,
    }
}

export const cancelDowngradeAction = (): ICancelDowngrade => {
    return {
        type: LicensesTypes.CANCEL_DOWNGRADE,
    }
}

export const cancelDowngradeSuccessAction = (
    subscription: string
): ICancelDowngradeSuccess => {
    return {
        type: LicensesTypes.CANCEL_DOWNGRADE_SUCCESS,
        payload: {
            subscription: subscription,
        },
    }
}

export const cancelDowngradeFailedAction = (): ICancelDowngradeFailed => {
    return {
        type: LicensesTypes.CANCEL_DOWNGRADE_FAILED,
    }
}
export const setCurrentLicenseAction = (
    license: ILicenseItem
): ISetCurrentLicense => {
    return {
        type: LicensesTypes.SET_CURRENT_LICENSE,
        license: license,
    }
}

export const setCurrentTierAction = (tier: ITierModel): ISetCurrentTier => {
    return {
        type: LicensesTypes.SET_CURRENT_TIER,
        tier: tier,
    }
}

export const setNewLicenseAction = (license: ITierModel): ISetNewLicense => {
    return {
        type: LicensesTypes.SET_NEW_LICENSE,
        license: license,
    }
}

export const setSelectedPeriodAction = (period: string): ISetSelectedPeriod => {
    return {
        type: LicensesTypes.SET_SELECTED_PERIOD,
        period: period,
    }
}

export const setCurrentStepAction = (step: StepModel): ISetCurrentStep => {
    return {
        type: LicensesTypes.SET_CURRENT_STEP,
        step: step,
    }
}

export const setExcededLimitsAction = (
    excededLimits: string[]
): ISetExcededLimits => {
    return {
        type: LicensesTypes.SET_EXCEDED_LIMITS,
        excededLimits: excededLimits,
    }
}

export const setReachedLimitsAction = (
    limitsAreReached: string
): ISetReachedLimits => {
    return {
        type: LicensesTypes.SET_REACHED_LIMITS,
        limitsAreReached: limitsAreReached,
    }
}

export const setActivatedAccount = (
    showActivatedAccount: boolean
): ISetShowAccountActivated => {
    return {
        type: LicensesTypes.SET_SHOW_ACCOUNT_ACTIVATED,
        payload: {
            showActivatedAccount,
        },
    }
}

export const removeClientSecretAction = (): IRemoveClientSecret => {
    return {
        type: LicensesTypes.REMOVE_CLIENT_SECRET,
    }
}

export const preselectLicenseAction = (
    license?: LicenseToPreselect
): IPreselectLicense => {
    return {
        type: LicensesTypes.SET_PRESELECTED_LICENSE,
        payload: { license },
    }
}
