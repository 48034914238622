import React, { useEffect, useState } from 'react'
import styles from './tabs.module.scss'
import { t } from 'i18next'
import cx from 'classnames'
import { ITabSectionModel } from '../../interfaces/interfaces'
import { ButtonSizes, badgetBGColor } from '../../data/globalVar'
import Badget from '../Badget/Badget'
import DarkButton from '../atoms/buttons/DarkButton/DarkButton'

type TabsProps = {
    sections: ITabSectionModel[]
    className?: string
    selected?: number
    onChangeFunction?: (x?) => void
}

const Tabs: React.FC<TabsProps> = (props) => {
    const { sections, className, selected, onChangeFunction } = props

    // Const
    const getIsActiveTab = (id: string) => visibleTab?.id === id
    const getIsHoveredTab = (id: string) => tabHovered === id
    const getBadgetBgColor = (id: string) => {
        return getIsActiveTab(id)
            ? badgetBGColor?.primary700
            : getIsHoveredTab(id)
            ? badgetBGColor?.primary1000
            : badgetBGColor?.neutral600
    }

    // States
    const [visibleTab, setVisibleTab] = useState(
        sections?.length
            ? selected
                ? sections[selected]
                : sections[0]
            : undefined
    )

    const [tabHovered, setTabHovered] = useState<string | undefined>()

    const listTitles = sections?.map((item, index) => (
        <li
            key={'tabSection__' + index}
            onClick={() =>
                !item?.disabled
                    ? visibleTab !== item &&
                      (setVisibleTab(item),
                      onChangeFunction && onChangeFunction())
                    : null
            }
            onMouseEnter={() =>
                !item?.disabled ? setTabHovered(item?.id) : null
            }
            onMouseLeave={() => setTabHovered(undefined)}
            className={cx(
                styles.tabTitle,
                getIsActiveTab(item?.id) && styles.tabTitleActive,
                item?.disabled && styles.disabled
            )}
        >
            {item?.info ? (
                <a
                    className={cx('bodyRegularSM')}
                    href="#"
                    aria-label={t(item?.info)}
                    tooltip-position="top"
                >
                    {t(item?.tabTitle)}
                </a>
            ) : (
                t(item?.tabTitle)
            )}{' '}
            {item?.badget ? (
                <Badget
                    content={item?.badget}
                    bgColor={getBadgetBgColor(item?.id)}
                />
            ) : null}
        </li>
    ))

    return (
        <div className={cx(styles.tabs, className && className)}>
            <div className={styles.tabs__header}>
                <ul className={styles.tabs__header__titles}>{listTitles}</ul>
                {visibleTab?.button ? (
                    <DarkButton
                        className={cx('marginLeftAuto')}
                        size={ButtonSizes.SM}
                        {...visibleTab?.button}
                    />
                ) : null}
            </div>
            <div className={styles.tabContent}>{visibleTab?.tabContent}</div>
        </div>
    )
}

export default Tabs
