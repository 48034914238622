import React from 'react'
import { useTranslation } from 'react-i18next'
import { gaImages } from '../../../../../assets/globalConstants'
import { ReactComponent as CloseIcon } from '../../../../../assets/images/close.svg'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaImage from '../../../../../Components/atoms/Image/Image'
import styles from './downgradeAreSureModal.module.scss'
import {
    ITierModel,
    ISubscriptionInfo,
} from '../../../../../interfaces/interfaces'
import { getIfCancelSubscription } from '../../../../../utils/licensesUtil'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import cx from 'classnames'
import { formatStringDate } from '../../../../../utils/formatDataUtil'

type IDowngradeAreSureModalProps = {
    show: boolean
    newLicense?: ITierModel
    currentSubscriptionPeriod?: string
    selectedPeriod?: string
    subscriptionInfo?: ISubscriptionInfo
    currentPeriodEnd?: string
    hideModal: (x?) => void
    closeModal: (x?) => void
}

const DowngradeAreSureModal: React.FC<IDowngradeAreSureModalProps> = (
    props
) => {
    const {
        show,
        newLicense,
        currentSubscriptionPeriod,
        selectedPeriod,
        subscriptionInfo,
        hideModal,
        closeModal,
    } = props
    const { t } = useTranslation()

    const cancelling = getIfCancelSubscription(
        newLicense,
        currentSubscriptionPeriod,
        selectedPeriod,
        subscriptionInfo
    )

    const formattedCurrentPeriodEnd = formatStringDate(
        subscriptionInfo?.currentPeriodEnd || '',
        'DD/MM/YYYY'
    )
    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            }`}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div
                        className={styles.modal__header__close}
                        onClick={() => closeModal()}
                    >
                        <CloseIcon />
                    </div>
                    <div className={styles.modal__header__icon}>
                        <GaImage image={gaImages.yellowWarningSvg} />
                    </div>
                    <div className={styles.modal__header__title}>
                        <h3>
                            {t(
                                !cancelling
                                    ? 'subscription.youSure'
                                    : 'subscription.youSureCancel'
                            )}
                        </h3>
                    </div>
                </div>
                {cancelling && formattedCurrentPeriodEnd ? (
                    <Description
                        className={cx(
                            'bodyRegularSM neutral700',
                            styles.modal__description
                        )}
                        text={'subscription.cancelDesc'}
                        extraTranslationParams={{
                            date: formattedCurrentPeriodEnd,
                        }}
                    />
                ) : null}
                <div className={styles.modal__container}>
                    <DarkButton
                        className={cx(styles.modal__container__button)}
                        functionality={hideModal}
                        text={
                            !cancelling
                                ? 'subscription.yesDowngrade'
                                : 'subscription.yesCancel'
                        }
                        disabled={false}
                    />
                    <LightButton
                        className={cx(
                            'marginTop20',
                            styles.modal__container__button
                        )}
                        functionality={closeModal}
                        text={'subscription.noDowngrade'}
                        disabled={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default DowngradeAreSureModal
