import { Epic, ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, delay, map, mergeMap, switchMap } from 'rxjs/operators'
import auth from '../../../../../../services/auth'
import dataService from '../../../../../../services/dataService'
import { history } from '../../../../../../services/history'
import { showNotification } from '../../../../Notification/notificationActions'
import {
    changePasswordError,
    changePasswordSuccess,
    createUser,
    createUserFailed,
    createUserSuccess,
    deleteUserFailed,
    getProfileFailed,
    getProfileSuccess,
    getUsers,
    getUsersFailed,
    getUsersSuccess,
    updateProfileSuccess,
    updateUserFailed,
    updateUserSuccess,
} from './usersActions'
import { UsersActionTypes } from './usersTypes'

export const getUsersEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(UsersActionTypes.GET_USERS),
        delay(100),
        switchMap((action: any): any => {
            const userTenants: any =
                state$.value.sessionReducer.tokenData?.currentTenant || ''
            return from(dataService.getUsers()).pipe(
                map((response?: any) => {
                    return getUsersSuccess(response?.response, userTenants)
                }),
                catchError((error) =>
                    of(
                        getUsersFailed(error),
                        showNotification(
                            'users.getUsersError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const getProfileEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(UsersActionTypes.GET_USER_PROFILE),
        delay(100),
        switchMap((action: any): any => {
            return from(
                dataService.getUserProfile(action?.payload?.email)
            ).pipe(
                map((response?: any) => {
                    return getProfileSuccess(response?.response)
                }),
                catchError((error) =>
                    of(
                        getProfileFailed(error),
                        showNotification(
                            'profile.getProfileError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const getRegistrationTokenEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(UsersActionTypes.GET_REGISTRATION_TOKEN),
        switchMap((action: any): any => {
            const tenant = action.payload.tenant
            const role = action.payload.role
            const user = action.payload.user

            return from(dataService.getRegistrationToken(tenant, role)).pipe(
                map((response?: any) => {
                    auth.setRegistrationToken(response.headers.token)
                    return createUser(user)
                }),
                catchError((error) =>
                    of(
                        getUsersFailed(error),
                        showNotification(
                            'login.errorSignUp',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const createUserEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(UsersActionTypes.CREATE_USER),
        switchMap((action: any): any => {
            const user = action.payload.user
            const register = action.payload.register
            return from(dataService.inviteUser(user)).pipe(
                map((response?: any) => {
                    return createUserSuccess()
                }),
                catchError((error) =>
                    of(createUserFailed(error?.response?.message))
                )
            )
        })
    )
}

export const deleteUserEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(UsersActionTypes.DELETE_USER),
        switchMap((action: any): any => {
            const userID = action.payload.userID

            return from(dataService.deleteUser(userID)).pipe(
                map((response?: any) => {
                    return getUsers()
                }),
                catchError((error) =>
                    of(deleteUserFailed(error?.response?.message))
                )
            )
        })
    )
}

export const updateUserEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(UsersActionTypes.UPDATE_USER),
        switchMap((action: any): any => {
            const user = action.payload.user
            const email = action.payload.email

            return from(dataService.updateUser(user, email)).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(updateUserSuccess()),
                        of(
                            showNotification(
                                'users.updateUserSuccess',
                                'success',
                                ''
                            )
                        )
                    )
                }),
                catchError((error) =>
                    of(updateUserFailed(error?.response?.message))
                )
            )
        })
    )
}

export const updateProfileEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(UsersActionTypes.UPDATE_USER_PROFILE),
        delay(100),
        switchMap((action: any): any => {
            return from(
                dataService.changeUserProfile(action?.payload?.profile)
            ).pipe(
                map((response?: any) => {
                    return updateProfileSuccess(response?.response)
                }),
                catchError((error) =>
                    of(
                        getProfileFailed(error),
                        showNotification(
                            'profile.updateProfileError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const checkUsersEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(UsersActionTypes.CHECK_USERS),
        switchMap((action: any): any => {
            return from(dataService.existingUsers()).pipe(
                mergeMap((response?: any) => {
                    if (history.location.pathname !== '/login')
                        history.push('login')
                    return of()
                }),
                catchError((error) => {
                    let token = error?.response?.headers?.token
                    auth.setRegistrationToken(token)
                    history.push('home')
                    return of()
                })
            )
        })
    )
}

export const changePasswordEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(UsersActionTypes.CHANGE_PASSWORD_SUBMIT),
        switchMap((action: any): any => {
            return from(dataService.changePassword(action.payload)).pipe(
                mergeMap((response) => {
                    return of(changePasswordSuccess())
                }),
                catchError((error) => {
                    return of(changePasswordError(error))
                })
            )
        })
    )
}
