import {
    PaginationItemKeys,
    PaginationItemTypes,
} from '../../interfaces/interfaces'

export const PAGINATION_ITEM_TYPES: PaginationItemTypes = {
    PAGE: 'PAGE',
    ELLIPSIS: 'ELLIPSIS',
    FIRST_PAGE_LINK: 'FIRST_PAGE_LINK',
    PREVIOUS_PAGE_LINK: 'PREVIOUS_PAGE_LINK',
    NEXT_PAGE_LINK: 'NEXT_PAGE_LINK',
    LAST_PAGE_LINK: 'LAST_PAGE_LINK',
}

export const PAGINATION_ITEM_KEYS: PaginationItemKeys = {
    FIRST_ELLIPSIS: -1,
    SECOND_ELLIPSIS: -2,
    FIRST_PAGE_LINK: -3,
    PREVIOUS_PAGE_LINK: -4,
    NEXT_PAGE_LINK: -5,
    LAST_PAGE_LINK: -6,
}

export const defaultPageSize = 50
