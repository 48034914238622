import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { credentialsSelectors } from '../..'
import GaTextArea from '../../../../../Components/atoms/formFields/textArea/GaTextArea'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import Modal from '../../../../../Components/molecules/modals/Base/Modal'
import InfoPanel from '../../../../../Components/molecules/notification/panels/info/infoPanel'
import { IIssuanceConfigItemModel } from '../../../../../interfaces/interfaces'
import { searchOnComplexTree } from '../../../../../utils/searchUtil'
import { issuanceSelectors } from '../../../Issuance'
import { getCatalogTypes } from '../../../Issuance/issuanceSelectors'
import { issueCredential } from '../../credentialsActions'
import {
    modalPrimaryButton,
    modalSecondaryButton,
} from '../credentialsList/credentialsList.constants'
import styles from './issuingModal.module.scss'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'

type IIssuingModalProps = {
    show: boolean
    hideModal: () => void
}

const IssuingModal: React.FC<IIssuingModalProps> = (props) => {
    const { hideModal, show } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const selectedCredential = useSelector(
        credentialsSelectors.getSelectedCredential
    )
    const credentialTypes = useSelector(getCatalogTypes)

    const issuingConfigs = useSelector(issuanceSelectors.getIssuances)
    const selectedCredData = selectedCredential?.data
    const proof = selectedCredData?.validator?.verifiablePresentation?.proof
    const subjectDID = proof ? proof[0].creator?.split('#')[0] || '' : ''
    const selectedCredId = selectedCredential?.id

    const issuanceConfig = issuingConfigs?.find(
        (ic: IIssuanceConfigItemModel) => {
            return ic.id === selectedCredData?.group
        }
    )

    const getGroupCredentials = (): string[] => {
        return (
            issuanceConfig?.credentialTypes?.map(
                (s) => s.split('Credential')[0]
            ) || []
        )
    }

    const getCredentialType = () => {
        const issuanceConfigType = issuanceConfig?.credentialTypes
            ? issuanceConfig?.credentialTypes[0]
            : ''
        const issuanceFullType = credentialTypes?.filter((el) => {
            return el.id === issuanceConfigType
        })[0]
        return issuanceFullType
    }

    let [formData, setFormState] = useState({})
    let [multiSchemaFormatted, setMultiSchemaStateFormatted] = useState()
    let [multiClaimSchemaInvalid, setMultiClaimSchemaInvalid] = useState(false)
    let [cred, setCred] = useState(getGroupCredentials())
    let [credentialType, setCredentialType] = useState(getCredentialType())

    const selectedCredentialUri = credentialType?.uri
    const isMulti = credentialType?.multi

    useEffect(() => {
        if (!cred) {
            cred = getGroupCredentials()

            cred?.map((key, idx) => {
                {
                    cred[idx] = key
                }
                let keyt = cred[idx]
                formData[keyt] = ''
            })
        }
    }, [selectedCredential])

    useEffect(() => {}, [cred, selectedCredentialUri, isMulti])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formData,
            [name]: value,
        })
    }

    function hideModalAndClean() {
        hideModal()
    }

    function issueCredentials() {
        const dataToSend = getDataToIssue(multiSchemaFormatted)
        const { credentialSubject, expirationDate } = dataToSend
        hideModal()
        dispatch(showScreenLoaderAction())
        dispatch(
            issueCredential(
                selectedCredId || '',
                subjectDID,
                credentialSubject,
                credentialType?.id,
                isMulti,
                expirationDate
            )
        )
    }

    const getDataToIssue = (multiSchemaFormatted) => {
        let data
        let dataParsed = ''
        let subject

        if (isMulti) {
            try {
                dataParsed = multiSchemaFormatted
                    ? JSON.parse(multiSchemaFormatted || '')
                    : ''
                const credentialSubject = getFilledCredentialSubject(dataParsed)
                const expirationDate = getFilledExpirationDate(dataParsed)

                subject = { credentialSubject, expirationDate }
            } catch (e) {
                setMultiClaimSchemaInvalid(false)
            }

            if (isMulti && !subject) {
                setMultiClaimSchemaInvalid(true)
            } else {
                setMultiClaimSchemaInvalid(false)
                data = subject
            }
        } else {
            data = { credentialSubject: formData }
        }

        return data
    }

    const getFilledCredentialSubject = (dataParsed: string) => {
        const credentialSubject = searchOnComplexTree(
            dataParsed,
            'credentialSubject'
        )
            ? searchOnComplexTree(dataParsed, 'credentialSubject')[1]
            : dataParsed

        return credentialSubject
    }

    const getFilledExpirationDate = (dataParsed: string) => {
        const expirationDate = searchOnComplexTree(dataParsed, 'expirationDate')

        return expirationDate && expirationDate[1]
            ? expirationDate[1]
            : undefined
    }

    const jsonPrettify = (json) => {
        if (typeof json === 'object' && json !== null) {
            const pretty = JSON.stringify(json, undefined, 4)
            return pretty
        }
        try {
            const obj = JSON.parse(json)
            return jsonPrettify(obj)
        } catch (e) {
            setMultiClaimSchemaInvalid(false)
            return json
        }
    }

    return (
        <Modal
            title={'credentials.issueCredential'}
            subtitle={''}
            headerClose={true}
            show={show}
            primaryButton={modalPrimaryButton}
            primaryButtonDisabled={!!(isMulti && multiClaimSchemaInvalid)}
            primaryButtonFunction={issueCredentials}
            secondaryButton={modalSecondaryButton}
            handleClose={hideModalAndClean}
        >
            {cred?.map((key: string, index: number) => {
                return !isMulti ? (
                    <GaTextInput
                        id={
                            key +
                            index +
                            Math.random().toString(36).substr(2, 9)
                        }
                        errorTexts={['Invalid value']}
                        labelText={key}
                        name={key}
                        value={formData[key]}
                        onChange={(e) => {
                            handleInputChange(e)
                        }}
                    />
                ) : (
                    <>
                        <InfoPanel
                            label={
                                <p className={styles.infoPanelLabel}>
                                    {t('credentials.multiCredNeedJson')}{' '}
                                    <a
                                        className={styles.link}
                                        target="_blank"
                                        href={selectedCredentialUri}
                                    >
                                        {t('credentials.schemeExample')}
                                    </a>
                                </p>
                            }
                            hasIcon={true}
                        />

                        <p
                            className={cx(
                                'bodyRegularMD nuetral700',
                                styles.credentialSchemeLabel
                            )}
                        >
                            {t('credentials.fillScheme')}
                        </p>
                        <GaTextArea
                            className={`${styles.multiCredArea}`}
                            id={'service-serviceDescription'}
                            name={'service'}
                            invalid={!!(isMulti && multiClaimSchemaInvalid)}
                            errorTexts={[
                                t('credentials.invalidJsonScheme')?.toString(),
                            ]}
                            onChange={(e) => {
                                setMultiSchemaStateFormatted(
                                    jsonPrettify(e?.target?.value)
                                )
                                getDataToIssue(e?.target?.value)
                            }}
                            placeholder={t('credentials.fillSchemePlaceholder')}
                            rows={20}
                            value={multiSchemaFormatted}
                        />
                    </>
                )
            })}
        </Modal>
    )
}

export default IssuingModal
