import React from 'react'

type DefaultCircleIconProps = {
    size?: number
    color?: string
    className?: string
}

const DefaultCircleIcon: React.FC<DefaultCircleIconProps> = React.memo(
    (props) => {
        const { size, color, className } = props

        return (
            <svg
                className={className && className}
                width={size || '17'}
                height={size || '16'}
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_5785_10067)">
                    <path
                        d="M8.50018 14.75C12.2281 14.75 15.2502 11.7279 15.2502 8C15.2502 4.27208 12.2281 1.25 8.50018 1.25C4.77223 1.25 1.75012 4.27208 1.75012 8C1.75012 11.7279 4.77223 14.75 8.50018 14.75Z"
                        stroke={color || '#C6C6C8' || 'currentColor'}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_5785_10067">
                        <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0.5)"
                        />
                    </clipPath>
                </defs>
            </svg>
        )
    }
)

export default DefaultCircleIcon
