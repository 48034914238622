import cx from 'classnames'
import React from 'react'
import styles from './newClientSecret.module.scss'
import { t } from 'i18next'
import CloseIcon from '../../../../../../assets/iconsLibrary/simple/close/closeIcon'
import { gaColors } from '../../../../../../assets/globalConstants'
import DarkButton from '../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../../../data/globalVar'
import GaTextInput from '../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import GaTextInputPassword from '../../../../../../Components/atoms/formFields/textInputPassword/GaTextInputPassword'
import { useSelector } from 'react-redux'
import { appIntegrationsSelectors } from '../../../../../../store/appIntegrations'
import { Trans } from 'react-i18next'

type INewClientSecretProps = {
    hideModal: (x?) => void
}

const NewClientSecret: React.FC<INewClientSecretProps> = (props) => {
    const { hideModal } = props

    const clientData = useSelector(
        appIntegrationsSelectors.getSelectedAppIntegration
    )

    return (
        <div className={`${styles.backgroundLayer} ${styles.visibleModal}`}>
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div className={styles.modal__header__top}>
                        <div className={styles.modal__header__top__texts}>
                            <p className={cx('heading5 neutral1000')}>
                                {t('appIntegrations.newClientSecretTitle')}
                            </p>

                            <p
                                className={cx(
                                    'bodyRegularMD neutral700 marginTop4'
                                )}
                            >
                                <Trans
                                    defaults={
                                        'appIntegrations.newClientSecretDesc'
                                    }
                                    components={{
                                        bold: <strong className="bodyBoldMD" />,
                                    }}
                                />
                            </p>
                        </div>

                        <div
                            className={cx(styles.modal__header__top__close)}
                            onClick={hideModal}
                        >
                            <CloseIcon size={24} color={gaColors.neutral1000} />
                        </div>
                    </div>
                </div>
                <div className={`${styles.modal__content}`}>
                    <GaTextInput
                        id={'clientID'}
                        name={'clientID'}
                        labelText={t(
                            'dashboard.vouchModal.integrationCompleteForm.clientIDLabel'
                        )}
                        helperText={t(
                            'dashboard.vouchModal.integrationCompleteForm.clientIDHelperText'
                        )}
                        copyOption
                        copyIconSize={24}
                        value={clientData?.client_id}
                    />
                    <GaTextInputPassword
                        id={'clientSecret'}
                        name={'clientSecret'}
                        labelText={t(
                            'dashboard.vouchModal.integrationCompleteForm.clientSecretLabel'
                        )}
                        helperText={t(
                            'dashboard.vouchModal.integrationCompleteForm.clientSecretHelperText'
                        )}
                        copyOption
                        copyIconSize={24}
                        value={clientData?.client_secret}
                    />
                </div>
                <div className={styles.modal__footer}>
                    <DarkButton
                        className={styles.modal__footer__primaryButton}
                        functionality={hideModal}
                        text={'appIntegrations.newClientSecretButton'}
                        disabled={false}
                        size={ButtonSizes.MD}
                    />
                </div>
            </div>
        </div>
    )
}

export default NewClientSecret
