import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './licensesModal.module.scss'
import cx from 'classnames'
import { licensesSelectors } from '../../../../common/licenses'
import {
    licensesInfoToggles,
    listLicenses,
} from '../../../../common/licenses/views/changeSubscription/featuresModal/licensesTableData'
import LicensesTableMobile from '../../../../common/licenses/views/changeSubscription/featuresModal/licensesTableMobile/LicensesTableMobile'
import LicensesTable from '../../../../common/licenses/views/changeSubscription/featuresModal/licensesTable/LicensesTable'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import OnboardingLicenseCard from '../../../../../Components/organisms/Cards/OnboardingLicenseCard/OnboardingLicenseCard'
import SwitchButton from '../../../../../Components/atoms/buttons/SwitchButton/SwicthButton'
import { onboardingSwitchPeriodButtonOptions } from '../../../../common/licenses/licenses.constants'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import { ReactComponent as ChevronUp } from '../../../../assets/iconsLibrary/simple/chevronUp/chevronUpIcon.svg'
import { ReactComponent as ChevronDown } from '../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon.svg'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import RequestSubscriptionModal from '../../../../../Components/molecules/modals/RequestSubscription/RequestSubscriptionModal'
import {
    hideScreenLoaderAction,
    showScreenLoaderAction,
} from '../../../../common/Loader/loaderActions'
import {
    requestSubscriptionAction,
    requestSubscriptionActionSuccess,
    resetRequestSubscriptionStatusAction,
} from '../../../../common/licenses/licensesActions'
import {
    createOrgAction,
    createOrgToRequestSubAction,
} from '../../../../common/organization/organizationActions'
import {
    DataRequestModel,
    ITierModel,
} from '../../../../../interfaces/interfaces'
import { noPaymentMethod, onPremise } from '../../../../../data/globalVar'
import { emptyOrg } from '../../../../common/organization/organizationReducers'
import { orgServicesEndpoints } from '../../../../common/Welcome/welcomeConstants'
import { sessionSelectors } from '../../../../common/session'
import {
    creatingOrgLoading,
    getOrg,
} from '../../../../common/organization/organizationSelectors'
import { setSession } from '../../../../common/session/sessionActions'
import {
    getIfProductHasTrialDays,
    getTiersLicenses,
    getTierActivePriceMonthly,
    getTierActivePriceYearly,
    getTrialDaysNumber,
    getSiwtchIntervalPeriodOptions,
    getIfTierHasInterval,
} from '../../../../../utils/licensesUtil'
import { showNotification } from '../../../../common/Notification/notificationActions'
import { isDefaultBrandActive } from '../../../../../utils/brandThemeUtil'

type ILicensesModalProps = {
    show: boolean
    switchPeriodValue: string
    selectPeriod: (x) => void
    setPeriodChecked: (x) => void
    navigateChangeSubscription: () => void
    selectStarter: (x) => void
    selectLicense: (x) => void
    setShowSubcriptionModal: (x) => void
}

const LicensesModal: React.FC<ILicensesModalProps> = (props) => {
    const {
        show,
        switchPeriodValue,
        selectPeriod,
        selectStarter,
        selectLicense,
        setPeriodChecked,
        navigateChangeSubscription,
        setShowSubcriptionModal,
    } = props

    const dispatch = useDispatch()

    const newLicensePeriod = useSelector(licensesSelectors.getNewLicensePeriod)
    const tiers = useSelector(licensesSelectors.getProviderTiersAndFeatures)
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)
    const currentOrg = useSelector(getOrg)
    const tierLicenses = getTiersLicenses(tiers, listLicenses)

    const requestSubscriptionError = useSelector(creatingOrgLoading)
    const requestSubStatus = useSelector(
        licensesSelectors.getRequestSubscritptionStatus
    )

    const [showAllFeatures, setShowAllFeatures] = React.useState(false)
    const [selectedLicense, setSelectedLicense] = React.useState(0)

    const [showRequestSubModal, setShowRequestSubModal] = React.useState(false)
    const [showSuccessModal, setShowSuccessModal] = React.useState(false)
    const [loadingReq, setLoadingReq] = React.useState(false)

    const [selectedLicenseForReq, setSelectedLicenseForReq] = React.useState('')

    const getExtraTranslationParamsTrial = (tier) => {
        return !!getIfProductHasTrialDays(tier, selectedPeriod)
            ? {
                  days: getTrialDaysNumber(tier, selectedPeriod)?.toString(),
              }
            : undefined
    }

    useEffect(() => {}, [selectedPeriod])

    useEffect(() => {
        if (requestSubStatus === ActionStatusTypes?.success) {
            setShowRequestSubModal(true)
            setShowSuccessModal(true)
            setLoadingReq(false)
        }
        if (requestSubStatus === ActionStatusTypes?.loading) {
            setShowSuccessModal(false)
            setLoadingReq(true)
        }
        if (requestSubStatus === ActionStatusTypes?.failed) {
            setShowRequestSubModal(false)
            setShowSuccessModal(false)
            setLoadingReq(false)
        }
    }, [requestSubStatus])

    function requestSubscription(data: DataRequestModel) {
        const org = {
            providerId: '',
            orgInfo: {
                ...emptyOrg?.orgInfo,
                name: data?.orgId,
                services: orgServicesEndpoints,
                domainName: data.domainName,
            },
            priceId: data?.priceId,
        }
        const { priceId, orgId, message } = data
        setLoadingReq(true)
        !onPremise &&
            (!currentOrg?.id
                ? dispatch(createOrgToRequestSubAction(org, data))
                : dispatch(requestSubscriptionAction(priceId, orgId, message)))
    }

    const resetRequestSubscriptionStatus = () =>
        dispatch(resetRequestSubscriptionStatusAction())

    const getFirstButtonProps = (tier, index: number) => {
        return {
            label:
                // !noPaymentMethod ||
                !!getIfProductHasTrialDays(tier, selectedPeriod)
                    ? 'subscription.startTrial'
                    : 'requestSubscription.requestSubscription',
            function: () => {
                getIfProductHasTrialDays(tier, selectedPeriod)
                    ? selectStarter(tier)
                    : !noPaymentMethod
                    ? selectLicense(tier)
                    : (setShowRequestSubModal(true),
                      setSelectedLicenseForReq(tier))
            },
        }
    }

    const getSwitchPeriodOptions = getSiwtchIntervalPeriodOptions(
        onboardingSwitchPeriodButtonOptions,
        tiers
    )

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            }`}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div className={styles.modal__header__title}>
                        <h3 className={cx('heading4 neutral1000')}>
                            {t('subscription.subscriptionPlans')}
                        </h3>

                        <p
                            className={cx(
                                'bodyRegularLG neutral1000 marginTop12'
                            )}
                        >
                            {t('subscription.chooseTheRightPlan')}
                        </p>
                    </div>
                </div>

                <div className={styles.tableContainer}>
                    {!!(getSwitchPeriodOptions?.length > 1) ? (
                        <SwitchButton
                            className={styles.onboardingSwicthButton}
                            checkedValue={switchPeriodValue || ''}
                            options={onboardingSwitchPeriodButtonOptions}
                            onChangeSwitchSelect={selectPeriod}
                        />
                    ) : null}
                    {tiers?.length ? (
                        <>
                            <div className={styles.licenses}>
                                {tiers?.map((tier, index) => {
                                    return getIfTierHasInterval(
                                        tier,
                                        getSwitchPeriodOptions,
                                        switchPeriodValue
                                    ) || !tier?.prices?.length ? (
                                        <OnboardingLicenseCard
                                            key={'onboardingCard__' + index}
                                            newLicensePeriod={
                                                newLicensePeriod || ''
                                            }
                                            license={tier}
                                            firstButton={getFirstButtonProps(
                                                tier,
                                                index
                                            )}
                                            buttonExtraTranslationParams={getExtraTranslationParamsTrial(
                                                tier
                                            )}
                                            showAllDetails
                                            period={
                                                getSwitchPeriodOptions?.length >
                                                1
                                                    ? switchPeriodValue
                                                    : getSwitchPeriodOptions[0]
                                                          ?.value || 'month'
                                            }
                                            isNewLicense={false}
                                        />
                                    ) : null
                                })}
                            </div>
                        </>
                    ) : (
                        <div className={styles.licenses}>
                            <CardListSkeleton cardsNumber={5} />
                        </div>
                    )}
                    {showAllFeatures ? (
                        <div className={styles.tablesContainer}>
                            <LicensesTable
                                tiers={tierLicenses || []}
                                switchPeriodValue={switchPeriodValue}
                                infoToggles={licensesInfoToggles}
                                setPeriodChecked={setPeriodChecked}
                            />
                            <LicensesTableMobile
                                licenseIndex={selectedLicense}
                                license={
                                    tierLicenses &&
                                    tierLicenses[selectedLicense]
                                }
                                switchPeriodValue={switchPeriodValue}
                                infoToggles={licensesInfoToggles}
                                selectLicense={setSelectedLicense}
                                setPeriodChecked={setPeriodChecked}
                            />
                        </div>
                    ) : null}
                    {/* COMEMNTED because this will be restored in the future
                    <LightButton
                        className={styles.showTableButton}
                        text={
                            showAllFeatures
                                ? 'subscription.hideAllFeatures'
                                : 'subscription.seeAllFeatures'
                        }
                        SvgIcon={showAllFeatures ? ChevronUp : ChevronDown}
                        disabled={false}
                        functionality={() => {
                            setShowAllFeatures(!showAllFeatures),
                                setSelectedLicense(0)
                        }}
                    /> */}
                </div>
            </div>
            {showRequestSubModal || (loadingReq && noPaymentMethod) ? (
                <RequestSubscriptionModal
                    licenses={tiers}
                    show={showRequestSubModal}
                    hideModal={() => (
                        setShowRequestSubModal(false),
                        resetRequestSubscriptionStatus(),
                        setLoadingReq(false)
                    )}
                    hideLicensesModal={() => (
                        setShowSubcriptionModal(false), dispatch(setSession())
                    )}
                    selectedLicense={selectedLicenseForReq}
                    selectedPeriodChecked={switchPeriodValue}
                    setPeriodChecked={setPeriodChecked}
                    submitData={(x?) => requestSubscription(x)}
                    loading={loadingReq && !showSuccessModal}
                />
            ) : null}
        </div>
    )
}

export default LicensesModal
