import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    ConsentActionsTypes,
    ConsentsListActions,
    ConsentsListState,
} from './consentTypes'

// LIST
const initialConsentListState: ConsentsListState = {
    consents: null,
    loadingStatus: false,
    consentData: undefined,
    status: ActionStatusTypes.neutral,
}

export function consentsReducer(
    state = initialConsentListState,
    action: ConsentsListActions
): ConsentsListState {
    switch (action.type) {
        case ConsentActionsTypes.GET_CONSENTS:
            return {
                consents: null,
                status: ActionStatusTypes.loading,
                loadingStatus: true,
            } as ConsentsListState
        case ConsentActionsTypes.GET_CONSENTS_SUCCESS:
            return {
                consents: action?.payload?.consents,
                status: ActionStatusTypes.neutral,
                loadingStatus: false,
            } as ConsentsListState
        case ConsentActionsTypes.GET_CONSENTS_FAILED:
            return {
                consents: null,
                status: ActionStatusTypes.failed,
                loadingStatus: false,
            } as ConsentsListState

        case ConsentActionsTypes.SELECT_CONSENT:
            return {
                ...state,
                status: ActionStatusTypes.loading,
                loadingStatus: true,
            } as ConsentsListState
        case ConsentActionsTypes.SELECT_CONSENT_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                status: ActionStatusTypes.success,
                consentData: action.payload.data,
            } as ConsentsListState
        case ConsentActionsTypes.SELECT_CONSENT_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
                consentData: undefined,
                loadingStatus: false,
            } as ConsentsListState
        case ConsentActionsTypes.DELETE_CONSENT:
            return {
                status: ActionStatusTypes.loading,
                loadingStatus: true,
            } as ConsentsListState
        case ConsentActionsTypes.DELETE_CONSENT_SUCCESS:
            return {
                ...state,
                status: ActionStatusTypes.success,
                loadingStatus: false,
            } as ConsentsListState
        case ConsentActionsTypes.DELETE_CONSENT_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
                loadingStatus: false,
            } as ConsentsListState
        case ConsentActionsTypes.CLEAR_SELECTED_CONSENT:
            return {
                ...state,
                consentData: undefined,
            } as ConsentsListState
        default:
            return state
    }
}
