import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
    ButtonModel,
    ImageModel,
} from '../../../../../../interfaces/interfaces'
import LightButton from '../../../../../atoms/buttons/LightButton/LightButton'
import styles from './errorFilledPanel.module.scss'
import { ReactComponent as CloseIcon } from '../../../../../../assets/images/close.svg'
import cx from 'classnames'

type IErrorFilledPanelProps = {
    title?: string
    titleExtraTranslationParams?: Object
    label: string
    label2?: string
    label3?: string
    hasIcon?: boolean
    hasTitle?: boolean
    icon?: ImageModel
    rightButton?: ButtonModel
    leftButton?: ButtonModel
    className?: string
    closeButton?: {
        action: (x?) => void
    }
}

const ErrorFilledPanel: React.FC<IErrorFilledPanelProps> = (props) => {
    const {
        title,
        titleExtraTranslationParams,
        label,
        rightButton,
        leftButton,
        label2,
        label3,
        hasIcon,
        hasTitle,
        icon,
        closeButton,
        className,
    } = props
    const { t } = useTranslation()
    useEffect(() => {}, [label])

    return (
        <div className={`${styles.errorFilledPanel} ${className && className}`}>
            <div className={styles.errorFilledPanel__header}>
                {hasTitle ? (
                    <p
                        className={`${
                            rightButton || leftButton ? '' : styles.fullWidth
                        } ${styles.title} ${cx('bodyBoldSM neutral1000')}`}
                    >
                        {!title ? (
                            t('public.error')
                        ) : (
                            <Trans
                                i18nKey={title}
                                values={{ ...titleExtraTranslationParams }}
                            />
                        )}
                    </p>
                ) : null}

                {closeButton && (
                    <div onClick={closeButton?.action}>
                        <CloseIcon />
                    </div>
                )}
            </div>

            <div className={styles.errorFilledPanel__content}>
                <div
                    className={`${
                        rightButton || leftButton ? '' : styles.fullWidth
                    } ${styles.errorFilledPanel__content__texts}`}
                >
                    {label?.length ? (
                        <p className={cx('bodyRegularSM neutral1000')}>
                            <Trans i18nKey={label} />
                        </p>
                    ) : undefined}
                    <p className={cx('bodyRegularSM neutral1000')}>
                        {label2 && <Trans i18nKey={label2} />}
                    </p>
                    <p className={cx('bodyRegularSM neutral1000')}>
                        {label3 && <Trans i18nKey={label3} />}
                    </p>
                </div>
                <div className={styles.errorFilledPanel__content__buttons}>
                    {leftButton && (
                        <LightButton
                            className={styles.button}
                            text={leftButton.label}
                            disabled={false}
                            functionality={leftButton.function}
                        />
                    )}
                    {rightButton && (
                        <LightButton
                            className={styles.button}
                            text={rightButton.label}
                            disabled={false}
                            functionality={rightButton.function}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ErrorFilledPanel
