import {
    ConfigCredentialFormActions,
    ConfigCredentialFormActionsTypes,
    CredentialListActionsTypes,
    CredentialsListActions,
    IConfigCredentialFormState,
    ICredentialsListState,
} from './credentialsTypes'

// LIST
const initialCredentialsListState: ICredentialsListState = {
    issuedCredentialsResponse: undefined,
    pendingCredentias: undefined,
    selectedCredential: undefined,
    loadingStatus: false,
    issuingCredential: false,
    error: '',
}

export function credentialsReducer(
    state = initialCredentialsListState,
    action: CredentialsListActions
): ICredentialsListState {
    switch (action.type) {
        case CredentialListActionsTypes.GET_CREDENTIALS:
            return {
                ...state,
                loadingStatus: true,
            }
        case CredentialListActionsTypes.GET_CREDENTIALS_SUCCESS:
            // TODO: remove this when total is coming always from backend
            const currentTotal = state.issuedCredentialsResponse?.total
            const newTotal = action.payload?.total
            return {
                ...state,
                issuedCredentialsResponse: {
                    ...action.payload,
                    total: newTotal ? newTotal : currentTotal || 0,
                },
                loadingStatus: false,
                error: '',
            }
        case CredentialListActionsTypes.GET_CREDENTIALS_FAILED:
            return {
                ...state,
                issuedCredentialsResponse: undefined,
                loadingStatus: false,
                error: action.payload.error,
            }
        case CredentialListActionsTypes.SELECT_CREDENTIAL:
            return {
                ...state,
                selectedCredential: action.payload.credential,
            }
        case CredentialListActionsTypes.DELETE_CREDENTIAL:
            return {
                ...state,
                issuedCredentialsResponse: undefined,
                loadingStatus: true,
                error: '',
            }
        case CredentialListActionsTypes.DELETE_CREDENTIAL_SUCCESS:
            return {
                ...state,
                issuedCredentialsResponse: undefined,
                loadingStatus: false,
                error: '',
            }
        case CredentialListActionsTypes.GET_PENDING_CREDENTIALS:
            return {
                ...state,
                loadingStatus: true,
            }
        case CredentialListActionsTypes.GET_PENDING_CREDENTIALS_SUCCESS:
            // TODO: remove this when total is coming always from backend
            const currentPendingTotal = state.pendingCredentias?.total
            const newPendingTotal = action.payload?.total
            return {
                ...state,
                pendingCredentias: {
                    ...action.payload,
                    total: newPendingTotal
                        ? newPendingTotal
                        : currentPendingTotal || 0,
                },
                loadingStatus: false,
                error: '',
            }
        case CredentialListActionsTypes.GET_PENDING_CREDENTIALS_FAILED:
            return {
                ...state,
                pendingCredentias: undefined,
                loadingStatus: false,
                error: action.payload.error,
            }
        case CredentialListActionsTypes.ISSUE_CREDENTIAL:
            return {
                ...state,
                issuingCredential: true,
            }
        case CredentialListActionsTypes.ISSUE_CREDENTIAL_SUCCESS:
            return {
                ...state,
                issuedCredentialsResponse: undefined,
                issuingCredential: false,
            }
        case CredentialListActionsTypes.ISSUE_CREDENTIAL_FAILED:
            return {
                ...state,
                issuingCredential: false,
            }
        case CredentialListActionsTypes.CLEAR_CREDENTIAL_LIST:
            return initialCredentialsListState as ICredentialsListState
        default:
            return state
    }
}

const initialFormState: IConfigCredentialFormState = {
    steps: [],
    firstStep: null,
    step: null,
    finalStep: null,
}

export function configCredentialFormReducer(
    state = initialFormState,
    action: ConfigCredentialFormActions
): IConfigCredentialFormState {
    switch (action.type) {
        case ConfigCredentialFormActionsTypes.SETSTEP:
            return {
                ...state,
                step: action.payload.step,
            } as IConfigCredentialFormState
        case ConfigCredentialFormActionsTypes.SETSTEPS:
            const lastPosition = action.payload.steps.length - 1
            return {
                ...state,
                steps: action.payload.steps,
                firstStep: action.payload.steps[0],
                step: action.payload.steps[0],
                finalStep: action.payload.steps[lastPosition],
            } as IConfigCredentialFormState
        case ConfigCredentialFormActionsTypes.CLEAR_FORM_STEP:
            return initialFormState as IConfigCredentialFormState
        default:
            return state
    }
}
