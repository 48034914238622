import {
    CellContentTypes,
    TextAlignTypes,
} from '../../../../../assets/globalConstants'
import { ButtonModel } from '../../../../../interfaces/interfaces'

export const propertiesToSearch = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: false,
    },
    {
        text: 'alias',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const modalPrimaryButton: ButtonModel = {
    label: 'public.submit',
}

export const modalSecondaryButton: ButtonModel = {
    label: 'public.cancel',
}

export const tableItemOptions = [
    {
        text: 'public.delete',
        value: 'delete',
        color: 'red',
    },
]

export const headersIssuedCredentialsTable = [
    { key: 'group', text: 'credentials.group' },
    {
        key: 'typeNames',
        text: 'credentials.credentialType',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.array,
    },
    { key: 'issuer', text: 'credentials.issuingEntity', short: true },
    {
        key: 'createdAt',
        text: 'credentials.creationDate',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.date,
    },
    {
        key: 'status',
        text: 'credentials.status',
        tag: true,
        type: CellContentTypes.tag,
    },
    {
        key: 'actions',
        text: 'credentials.actions',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.action,
    },
]

export const headersPendingCredentialsTable = [
    { key: 'group', text: 'credentials.group' },
    {
        key: 'createdAt',
        text: 'credentials.creationDate',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.date,
    },
    {
        key: 'id',
        text: 'credentials.validationData',
        textAlign: TextAlignTypes.right,
        type: CellContentTypes.detail,
    },
    {
        key: 'issueReject',
        text: 'credentials.actions',
        tagText: 'credentials.issue',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.modal,
    },
]

export const issueForms = {
    university: ['grade', 'alumni'],
    covid: ['inmunity', 'diagnostic'],
    gatacaEmployee: ['gatacaEmployee', 'gatacaRole'],
    movementSpecial: ['specialPermit'],
    movementInmune: ['inmunityPermit'],
    movementEssential: ['essentialWorkerCredential'],
    dnidemo: [
        'firstName',
        'lastName',
        'documentId',
        'expirationDate',
        'expeditionId',
        'birthDate',
        'nationality',
        'birthPlace',
        'gender',
    ],
    vid: [
        'vIdType',
        'vIdIdentifier',
        'vIdDateBirth',
        'vIdRegistrationPlace',
        'vIdLastName',
        'vIdFirstName',
        'vIdNationality',
        'vIdGender',
        'vIdBsn',
        'vIdPortrait',
        'vIdDtc1',
    ],
    diploma: [
        'diplomaType',
        'studyProgram',
        'immatriculationNumber',
        'currentGivenName',
        'learningAchievement',
        'dateAchievement',
        'overalEvaluation',
        'eqfLevel',
        'targetFrameworkName',
    ],
    vidFace: [
        'diplomaType',
        'studyProgram',
        'immatriculationNumber',
        'currentGivenName',
        'learningAchievement',
        'dateAchievement',
        'overalEvaluation',
        'eqfLevel',
        'targetFrameworkName',
    ],
}

export const credentialsScopes = {
    read: ['readSessions'],
    edit: ['manageCredentials'],
    validate: ['validateSessions'],
}

export const multiTypeCredentials = [
    'studentIdCredential',
    'vIdCredential',
    'diplomaCredential',
    'transcriptOfRecordsCredential',
    'bankAccountCredential',
]
