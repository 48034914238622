import {
    ICredentialsItemModel,
    IDataAgreementItem,
} from '../../../interfaces/interfaces'
import {
    ConsentActionsTypes,
    IClearSelectedConsentAction,
    IDeleteConsentAction,
    IDeleteConsentFailedAction,
    IDeleteConsentSuccessAction,
    IGetConsentsAction,
    IGetConsentsFailedAction,
    IGetConsentsSuccessAction,
    ISelectConsentAction,
    ISelectConsentFailedAction,
    ISelectConsentSuccessAction,
} from './consentTypes'

// LIST
export const getConsents = (): IGetConsentsAction => {
    return {
        type: ConsentActionsTypes.GET_CONSENTS,
    }
}
export const getConsentsSuccess = (
    consents: IDataAgreementItem[]
): IGetConsentsSuccessAction => {
    return {
        type: ConsentActionsTypes.GET_CONSENTS_SUCCESS,
        payload: {
            consents: consents,
        },
    }
}
export const getConsentsFailed = (err: any): IGetConsentsFailedAction => {
    return {
        type: ConsentActionsTypes.GET_CONSENTS_FAILED,
        payload: {
            error: err,
        },
    }
}

export const deleteConsent = (consentId: string): IDeleteConsentAction => {
    return {
        type: ConsentActionsTypes.DELETE_CONSENT,
        payload: {
            consentId: consentId,
        },
    }
}
export const deleteConsentSuccess = (): IDeleteConsentSuccessAction => {
    return {
        type: ConsentActionsTypes.DELETE_CONSENT_SUCCESS,
    }
}
export const deleteConsentFailed = (err: any): IDeleteConsentFailedAction => {
    return {
        type: ConsentActionsTypes.DELETE_CONSENT_FAILED,
        payload: {
            error: err,
        },
    }
}

export const selectConsent = (id: string): ISelectConsentAction => {
    return {
        type: ConsentActionsTypes.SELECT_CONSENT,
        payload: {
            consentId: id,
        },
    }
}

export const selectConsentSuccess = (
    data: ICredentialsItemModel[]
): ISelectConsentSuccessAction => {
    return {
        type: ConsentActionsTypes.SELECT_CONSENT_SUCCESS,
        payload: {
            data: data,
        },
    }
}

export const selectConsentFailed = (
    error: string
): ISelectConsentFailedAction => {
    return {
        type: ConsentActionsTypes.SELECT_CONSENT_FAILED,
        payload: {
            error: error,
        },
    }
}
export const clearSelectedConsent = (): IClearSelectedConsentAction => {
    return {
        type: ConsentActionsTypes.CLEAR_SELECTED_CONSENT,
    }
}
