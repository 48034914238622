import React from 'react'

type CompletedCircleIconProps = {
    size?: number
    color?: string
    className?: string
}

const CompletedCircleIcon: React.FC<CompletedCircleIconProps> = React.memo(
    (props) => {
        const { size, color, className } = props

        return (
            <svg
                className={className && className}
                width={size || '14'}
                height={size || '14'}
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.25"
                    width="13.5"
                    height="13.5"
                    rx="6.75"
                    fill={color || 'currentColor' || '#4745B7'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.35391 10.2219L3.875 7.6063L4.66329 6.77837L6.35391 8.56079L10.0277 4.68481L10.8159 5.51802L6.35391 10.2219Z"
                    fill="white"
                />
            </svg>
        )
    }
)

export default CompletedCircleIcon
