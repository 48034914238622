import { RootState } from '../../../store/indexReducers'

export const getApiKeys = (state: RootState) => state.apiKeysReducer.apiKeys
export const loadingStatus = (state: RootState) =>
    state.apiKeysReducer.loadingStatus
export const showApiKeyDetail = (state: RootState) =>
    state.apiKeysReducer.showApiKeyDetail
export const getSelectedApp = (state: RootState) =>
    state.configApiKeyFormReducer.apiKey
export const getStatus = (state: RootState) => state.apiKeysReducer.status
export const getError = (state: RootState) => state.apiKeysReducer.error
export const getSelectedApiKey = (state: RootState) =>
    state.apiKeysReducer.selectedApiKey
