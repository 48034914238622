import {
    IHideNotificationAction,
    IShowNotificationAction,
    NotificationActionTypes,
} from './notificationTypes'

export const showNotification = (
    title,
    kind,
    subtitle,
    status?,
    duration?
): IShowNotificationAction => {
    return {
        type: NotificationActionTypes.NOTIFICATION_SHOW,
        payload: {
            notification: {
                title: title,
                kind: kind,
                subtitle: subtitle,
                status: status,
                duration: duration,
            },
        },
    }
}

export const hideNotification = (): IHideNotificationAction => {
    return {
        type: NotificationActionTypes.NOTIFICATION_HIDE,
    }
}
