import React from 'react'

type HelpIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const HelpIcon: React.FC<HelpIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'filled' ? (
                <FilledIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.6362 23.3639 2.66666 16.0001 2.66666C8.63628 2.66666 2.66675 8.6362 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1201 12C12.4336 11.1089 13.0523 10.3575 13.8667 9.87885C14.6811 9.40022 15.6386 9.22526 16.5697 9.38495C17.5007 9.54465 18.3452 10.0287 18.9536 10.7514C19.5619 11.474 19.8949 12.3887 19.8935 13.3333C19.8935 16 15.8935 17.3333 15.8935 17.3333"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 22.6667H16.0133"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <path
                d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.6362 23.3639 2.66666 16.0001 2.66666C8.63628 2.66666 2.66675 8.6362 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1201 12C12.4336 11.1089 13.0523 10.3575 13.8667 9.87885C14.6811 9.40022 15.6386 9.22526 16.5697 9.38495C17.5007 9.54465 18.3452 10.0287 18.9536 10.7514C19.5619 11.474 19.8949 12.3887 19.8935 13.3333C19.8935 16 15.8935 17.3333 15.8935 17.3333"
                stroke="white"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 22.6667H16.0133"
                stroke="white"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export default HelpIcon
