// STATE & REDUCER

export interface IScopesState {
  isLoading: boolean,
  scopes: IScopes
}

export interface IScopes {
  [name: string]: IScope[];
}

export interface IScope {
  type: string;
  purpose: string;
  mandatory: boolean;
  trustLevel: number;
}

// ACTIONS
export enum ScopesTypes {
  SUBMIT_SCOPES = 'scopes/submit',
  UPDATE_SCOPES = 'scopes/update',
}

export interface ISubmitScopes {
  type: ScopesTypes.SUBMIT_SCOPES
}

export interface IUpdateScopes {
  type: ScopesTypes.UPDATE_SCOPES
  scopes: IScopes
}

export type IScopesActions = ISubmitScopes | IUpdateScopes
