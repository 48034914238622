import React, { useEffect, useState } from 'react'
import CredentialPreviewModal from '../../CredentialPreview'
import { t } from 'i18next'
import CredentialCard from '../../../../../../features/wallet/requestCredentials/components/credentialCard/CredentialCard'
import cx from 'classnames'
import ChevronUpIcon from '../../../../../../assets/iconsLibrary/simple/chevronUp/chevronUpIcon'
import ChevronDownIcon from '../../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import { gaColors } from '../../../../../../assets/globalConstants'
import Content from '../../../../../../features/wallet/requestCredentials/components/credentialCardDetail/components/content/Content'
import { initialLang } from '../../../../../../i18n'
import { ButtonModel } from '../../../../../../interfaces/interfaces'
import CardListSkeleton from '../../../../skeletons/cardListSkeleton/CardListSkeleton'
import styles from '../../credentialPreviewModal.module.scss'
import { useSelector } from 'react-redux'
import { orgSelectors } from '../../../../../../features/common/organization'
import { CredGroupMockedValues } from '../../../../../../data/credMockedValues'
import { getCredentialSubjectValue } from '../../../../../../utils/credentialsUtils'
import MediumModal from '../../../Medium/MediumModal'

type IIssuanceCredentialPreviewProps = {
    issuanceTemplate?: boolean
    saveButton?: ButtonModel
    showSaveButton?: boolean
    catalogGroups?: any
    catalogTypes?: any[]
    data: {
        credentialGroup?: any
        credentialTypes?: any[]
    }
    isMultiClaimSelected?: boolean
    smallResolution: boolean
    show: boolean
    hideModal: (x?) => void
}

const IssuanceCredentialPreview: React.FC<IIssuanceCredentialPreviewProps> = (
    props
) => {
    const {
        saveButton,
        showSaveButton,
        data,
        catalogGroups,
        catalogTypes,
        isMultiClaimSelected,
        smallResolution,
        show,
        hideModal,
    } = props

    const { credentialGroup, credentialTypes } = data

    const selectedGroupComplete = catalogGroups?.find(
        (el) => el?.id === credentialGroup
    )
    const selectedTypesComplete = catalogTypes?.filter(
        (item) => credentialTypes?.indexOf(item.id) !== -1
    )

    const orgName = useSelector(orgSelectors.getOrg)?.orgInfo?.name

    const mainClaimValue =
        selectedGroupComplete &&
        (!selectedGroupComplete?.id?.toLowerCase().includes('image') &&
        !selectedGroupComplete?.id?.toLowerCase().includes('photo') &&
        !selectedGroupComplete?.id?.toLowerCase().includes('picture')
            ? t(CredGroupMockedValues[selectedGroupComplete?.id]) ||
              t('editIssuance.step5.credValue')
            : '')

    const [contentOpen, setContentOpen] = useState(true)

    useEffect(() => {
        setContentOpen(!!credentialTypes?.length)
    }, [credentialGroup, credentialTypes])

    const credTypesWithValues = selectedTypesComplete?.map((el) => {
        el.idType = el?.id
        el.credentialSubject = {
            [el.name[initialLang] ? el.name[initialLang] : el.name['en']]:
                getCredentialSubjectValue(el),
        }

        return el
    })

    return (
        <>
            {smallResolution ? (
                <>
                    {show ? (
                        <MediumModal
                            title={t('editIssuance.step2.credPrevTitle')}
                            subtitle={t(
                                'editIssuance.step2.credPrevDescription'
                            )}
                            titleClassName={cx(
                                'heading6 neutral1000 marginBottom4'
                            )}
                            subtitleClassName={cx('bodyRegularSM neutral700')}
                            headerClose={true}
                            show={show}
                            handleClose={hideModal}
                            className={styles.previewModalResponsive}
                        >
                            <CredentialPreviewModal
                                titleHeader={''}
                                descriptionHeader={''}
                                modalResponsiveView={true}
                                hasBottomAlert={
                                    data?.credentialGroup?.length &&
                                    selectedGroupComplete
                                }
                            >
                                {data?.credentialGroup?.length &&
                                selectedGroupComplete ? (
                                    <div className={styles.credConstraints}>
                                        <div className={styles.credDetail}>
                                            <CredentialCard
                                                id={''}
                                                groupId={credentialGroup}
                                                group={selectedGroupComplete}
                                                mainClaim={mainClaimValue}
                                                groupName={
                                                    selectedGroupComplete?.name
                                                }
                                                securityLevel={1}
                                                vcs={[]}
                                                issuer={orgName}
                                                className={styles.credCard}
                                            />
                                            <div className={styles.content}>
                                                <div
                                                    className={styles.header}
                                                    onClick={() =>
                                                        setContentOpen(
                                                            !contentOpen
                                                        )
                                                    }
                                                >
                                                    <p
                                                        className={cx(
                                                            'buttonSM',
                                                            contentOpen
                                                                ? 'primary700'
                                                                : 'neutral1000'
                                                        )}
                                                    >
                                                        {t(
                                                            'ew.requestCredentials.content'
                                                        )}
                                                    </p>
                                                    {contentOpen ? (
                                                        <ChevronUpIcon
                                                            size={24}
                                                            color={
                                                                contentOpen
                                                                    ? gaColors.primary700
                                                                    : gaColors.neutral1000
                                                            }
                                                        />
                                                    ) : (
                                                        <ChevronDownIcon
                                                            size={24}
                                                            color={
                                                                gaColors.neutral1000
                                                            }
                                                        />
                                                    )}
                                                </div>

                                                <Content
                                                    group={
                                                        selectedGroupComplete
                                                    }
                                                    vcs={
                                                        credTypesWithValues ||
                                                        []
                                                    }
                                                    groupName={
                                                        selectedGroupComplete?.name
                                                    }
                                                    contentOpen={contentOpen}
                                                    types={catalogTypes}
                                                    isDemoContent={true}
                                                    isMulti={
                                                        !!isMultiClaimSelected
                                                    }
                                                    setContentToShow={() => {}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <CardListSkeleton
                                        key={'credentials__skeleton'}
                                        className={styles.credentialsSkeleton}
                                        cardsNumber={1}
                                    />
                                )}
                            </CredentialPreviewModal>
                        </MediumModal>
                    ) : null}
                </>
            ) : (
                <CredentialPreviewModal
                    titleHeader={t('editIssuance.step2.credPrevTitle')}
                    descriptionHeader={t(
                        'editIssuance.step2.credPrevDescription'
                    )}
                    saveButton={saveButton}
                    showSaveButton={showSaveButton ? showSaveButton : false}
                    hasBottomAlert={
                        data?.credentialGroup?.length && selectedGroupComplete
                    }
                >
                    {data?.credentialGroup?.length && selectedGroupComplete ? (
                        <div className={styles.credConstraints}>
                            <div className={styles.credDetail}>
                                <CredentialCard
                                    id={''}
                                    groupId={credentialGroup}
                                    group={selectedGroupComplete}
                                    mainClaim={mainClaimValue}
                                    groupName={selectedGroupComplete?.name}
                                    securityLevel={1}
                                    vcs={[]}
                                    issuer={orgName}
                                    className={styles.credCard}
                                />
                                <div className={styles.content}>
                                    <div
                                        className={styles.header}
                                        onClick={() =>
                                            setContentOpen(!contentOpen)
                                        }
                                    >
                                        <p
                                            className={cx(
                                                'buttonSM',
                                                contentOpen
                                                    ? 'primary700'
                                                    : 'neutral1000'
                                            )}
                                        >
                                            {t('ew.requestCredentials.content')}
                                        </p>
                                        {contentOpen ? (
                                            <ChevronUpIcon
                                                size={24}
                                                color={
                                                    contentOpen
                                                        ? gaColors.primary700
                                                        : gaColors.neutral1000
                                                }
                                            />
                                        ) : (
                                            <ChevronDownIcon
                                                size={24}
                                                color={gaColors.neutral1000}
                                            />
                                        )}
                                    </div>

                                    <Content
                                        group={selectedGroupComplete}
                                        vcs={credTypesWithValues || []}
                                        groupName={selectedGroupComplete?.name}
                                        contentOpen={contentOpen}
                                        types={catalogTypes}
                                        isDemoContent={true}
                                        isMulti={!!isMultiClaimSelected}
                                        setContentToShow={() => {}}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <CardListSkeleton
                            key={'credentials__skeleton'}
                            className={styles.credentialsSkeleton}
                            cardsNumber={1}
                        />
                    )}
                </CredentialPreviewModal>
            )}
        </>
    )
}

export default IssuanceCredentialPreview
