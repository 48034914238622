import React from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './ageVerificationModal.module.scss'
import {
    ButtonModel,
    ImageModel,
} from '../../../../../../interfaces/interfaces'
import DarkButton from '../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../../../data/globalVar'
import LightButton from '../../../../../../Components/atoms/buttons/LightButton/LightButton'
import CloseIcon from '../../../../../../assets/iconsLibrary/simple/close/closeIcon'
import { gaColors } from '../../../../../../assets/globalConstants'
import ProgressIndicator from '../../../../../../Components/molecules/progress/ProgressIndicator/ProgressIndicator'
import GaImage from '../../../../../../Components/atoms/Image/Image'
import { Trans } from 'react-i18next'

type IAgeVerificationModalProps = {
    className?: string
    imageClassName?: string
    stepTitle?: string
    title?: string
    description?: string
    continueButton?: ButtonModel
    cancelBackButton?: ButtonModel
    progressIndicatorOptions?: number
    progressIndicatorCurrentOption?: number
    rightImage?: ImageModel
    hideModal: () => void
}

const AgeVerificationModal: React.FC<IAgeVerificationModalProps> = (props) => {
    const {
        className,
        imageClassName,
        stepTitle,
        title,
        description,
        continueButton,
        cancelBackButton,
        progressIndicatorOptions,
        progressIndicatorCurrentOption,
        children,
        rightImage,
        hideModal,
    } = props

    return (
        <div
            className={cx(
                styles.backgroundLayer,
                styles.visibleModal,
                className && className
            )}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div className={styles.modal__header__top}>
                        <div
                            className={cx(styles.modal__header__top__close)}
                            onClick={hideModal}
                        >
                            <CloseIcon size={32} color={gaColors.neutral1000} />
                        </div>
                    </div>
                </div>
                <div className={`${styles.modal__body}`}>
                    <div className={`${styles.modal__body__leftColumn}`}>
                        {stepTitle || title || description ? (
                            <div>
                                {stepTitle ? (
                                    <p className={cx('heading7 neutral700')}>
                                        {t(stepTitle)}
                                    </p>
                                ) : null}
                                {title ? (
                                    <p
                                        className={cx(
                                            'heading5 neutral1000 marginTop4'
                                        )}
                                    >
                                        {t(title)}
                                    </p>
                                ) : null}
                                {description ? (
                                    <p
                                        className={cx(
                                            'bodyRegularMD neutral700 marginTop4'
                                        )}
                                    >
                                        <Trans i18nKey={description} />
                                    </p>
                                ) : null}
                            </div>
                        ) : null}

                        {progressIndicatorOptions &&
                        progressIndicatorCurrentOption ? (
                            <ProgressIndicator
                                className={`${styles.progressIndicator}`}
                                optionsNumber={progressIndicatorOptions}
                                currentOption={progressIndicatorCurrentOption}
                            />
                        ) : null}

                        <div
                            className={`${styles.modal__body__leftColumn__scrollable}`}
                        >
                            {children}
                        </div>
                    </div>
                    {rightImage && rightImage ? (
                        <div
                            className={`${styles.modal__body__rightColumn} ${
                                imageClassName && imageClassName
                            }`}
                        >
                            <GaImage image={rightImage && rightImage} />
                        </div>
                    ) : null}
                </div>

                {continueButton || cancelBackButton ? (
                    <div className={styles.modal__footer}>
                        {cancelBackButton ? (
                            <LightButton
                                className={styles.modal__footer__noBorderButton}
                                functionality={cancelBackButton?.function}
                                text={cancelBackButton?.label || ''}
                                disabled={cancelBackButton?.disabled || false}
                                size={ButtonSizes.MD}
                                noBorder
                            />
                        ) : null}
                        {continueButton ? (
                            <DarkButton
                                className={styles.modal__footer__primaryButton}
                                functionality={continueButton?.function}
                                text={continueButton?.label || ''}
                                disabled={continueButton?.disabled || false}
                                size={ButtonSizes.MD}
                            />
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default AgeVerificationModal
