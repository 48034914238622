import React from 'react'

type ArrowUpDiagonalCircleIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const ArrowUpDiagonalCircleIcon: React.FC<ArrowUpDiagonalCircleIconProps> =
    React.memo((props) => {
        const { size, style, color, className } = props

        return (
            <svg
                className={className && className}
                width={size || '32'}
                height={size || '32'}
                viewBox={`0 0 ${size || '32'} ${size || '32'}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {style === 'filled' ? (
                    <FilledIconPaths color={color} size={size} />
                ) : (
                    <DeafaultIconPaths color={color} />
                )}
            </svg>
        )
    })

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63619 23.3639 2.66666 16.0001 2.66666C8.63628 2.66666 2.66675 8.63619 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z"
                stroke="#1E1E20"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.3335 15.9997L16.0002 10.6664L10.6668 15.9997"
                stroke="#1E1E20"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 21.3334L16 10.6667"
                stroke="#1E1E20"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    const { size, color } = props

    return (
        <>
            <g clip-path="url(#clip0_6859_24323)">
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.65305 19.0711C1.74781 15.1658 1.74781 8.83417 5.65305 4.92893C9.5583 1.02369 15.8899 1.02369 19.7952 4.92893C23.7004 8.83418 23.7004 15.1658 19.7952 19.0711C15.8899 22.9763 9.5583 22.9763 5.65305 19.0711ZM17.4312 12.7072C17.0407 13.0977 16.4075 13.0977 16.017 12.7072L13.7241 10.4143L13.7241 16C13.7241 16.5523 13.2764 17 12.7241 17C12.1719 17 11.7241 16.5523 11.7241 16L11.7241 10.4142L9.43121 12.7072C9.04069 13.0977 8.40752 13.0977 8.017 12.7072C7.62647 12.3166 7.62647 11.6835 8.017 11.293L12.0153 7.29468C12.0224 7.28751 12.0297 7.28043 12.037 7.27347C12.1204 7.19457 12.214 7.13275 12.3134 7.088C12.4387 7.03148 12.5777 7.00002 12.7241 7.00002C12.8868 7.00002 13.0404 7.03885 13.1761 7.10774C13.2327 7.13644 13.2872 7.17086 13.3386 7.21103C13.374 7.23863 13.4075 7.26857 13.4389 7.3006L17.4312 11.293C17.8217 11.6835 17.8217 12.3166 17.4312 12.7072Z"
                    fill={color || 'currentColor' || '#1E1E20'}
                />
            </g>
        </>
    )
}

export default ArrowUpDiagonalCircleIcon
