import { ActionStatusTypes } from '../../../assets/globalConstants'
import { ITierModel } from '../../../interfaces/interfaces'
import { SessionAction, SessionActionTypes } from '../session/sessionTypes'
import { subscriptionSteps } from './licenses.constants'
import {
    ILicensesActions,
    ILicensesState,
    LicensesTypes,
} from './licensesTypes'

const emptySubscriptionInfo = {
    currentLicenseType: '',
    currentPeriodEnd: '',
    currentPeriodStart: '',
    currentPriceId: '',
    currentTierId: '',
    subscriptionSchedule: undefined,
    cancelAtPeriodEnd: false,
    orgId: '',
    paymentOutsideStripe: false,
    provider: '',
}

const emptyProduct: ITierModel = {
    id: '',
    name: '',
    description: '',
    index: 0,
    limits: new Array(),
    prices: [
        {
            id: '',
            name: '',
            currency: '',
            recurringInterval: '',
            amount: 0,
            type: '',
        },
    ],
}

const licensesState: ILicensesState = {
    newLicense: emptyProduct,
    subscriptionInfoIsLoading: false,
    selectedPeriod: 'month',
    subscriptionInfo: emptySubscriptionInfo,
    currentProduct: emptyProduct,
    clientSecret: '',
    sendingPaymentMethod: false,
    updatingSubscriptionLoading: false,
    cancelDowngradeLoading: false,
    subscriptionInfoUpdatedSuccess: false,
    cancelSubscriptionLoading: false,
    clientSecretErrorStatus: undefined,
    tiers: [],
    newLicensePeriod: '',
    currentSubscriptionPeriod: '',
    currentStep: subscriptionSteps[0],
    tiersAreLoading: false,
    excededLimits: [],
    reachedLimits: '',
    showAccountActivatedPanel: false,
    subscriptionRequestStatus: ActionStatusTypes?.neutral,
}

export const licensesReducer = (
    state = licensesState,
    action: ILicensesActions | SessionAction
): ILicensesState => {
    switch (action.type) {
        case LicensesTypes.GET_CLIENT_SECRET:
            return {
                ...state,
                clientSecretErrorStatus: undefined,
            }
        case LicensesTypes.GET_CLIENT_SECRET_SUCCESS:
            return {
                ...state,
                clientSecret: action.payload.clientSecret,
            }
        case LicensesTypes.GET_CLIENT_SECRET_FAILED:
            return {
                ...state,
                clientSecretErrorStatus: action.error,
            }
        case LicensesTypes.SEND_PAYMENT_METHOD:
            return {
                ...state,
                clientSecretErrorStatus: undefined,
                sendingPaymentMethod: true,
            }
        case LicensesTypes.SEND_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                sendingPaymentMethod: false,
                clientSecret: '',
            }
        case LicensesTypes.SEND_PAYMENT_METHOD_FAILED:
            return {
                ...state,
                sendingPaymentMethod: false,
            }
        case LicensesTypes.GET_TIERS:
            return {
                ...state,
                tiers: [],
                tiersAreLoading: true,
            }
        case LicensesTypes.GET_TIERS_SUCCESS:
            return {
                ...state,
                tiers: action.payload.tiers,
                tiersAreLoading: false,
            }
        case LicensesTypes.GET_TIERS_FAILED:
            return {
                ...state,
                tiersAreLoading: false,
            }
        case LicensesTypes.GET_SUBSCRIPTION_INFO:
            return {
                ...state,
                subscriptionInfoIsLoading: true,
            }
        case LicensesTypes.GET_SUBSCRIPTION_INFO_SUCCESS:
            const currentProduct = state.currentProduct
            return {
                ...state,
                subscriptionInfoIsLoading: false,
                subscriptionInfo: action.payload.subscriptionInfo,
                currentSubscriptionPeriod: currentProduct?.prices?.find(
                    (price) => {
                        return (
                            price.id ===
                            action.payload.subscriptionInfo?.currentPriceId
                        )
                    }
                )?.recurringInterval,
            }
        case LicensesTypes.GET_SUBSCRIPTION_INFO_FAILED:
            return {
                ...state,
                subscriptionInfoIsLoading: false,
            }
        case LicensesTypes.SET_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.step,
            }
        case LicensesTypes.SET_CURRENT_TIER:
            const subscriptionInfo = state?.subscriptionInfo
            return {
                ...state,
                currentProduct: action.tier,
                currentSubscriptionPeriod: action.tier?.prices?.find(
                    (price) => {
                        return price.id === subscriptionInfo?.currentPriceId
                    }
                )?.recurringInterval,
            }
        case LicensesTypes.SET_NEW_LICENSE:
            const selectedPeriod = state?.selectedPeriod
            return {
                ...state,
                newLicense: action.license,
                newLicensePeriod: action.license.prices?.find(
                    (price) => price?.recurringInterval === selectedPeriod
                )?.recurringInterval,
                subscriptionInfoUpdatedSuccess: false,
            }
        case LicensesTypes.SET_SELECTED_PERIOD:
            return {
                ...state,
                selectedPeriod: action.period,
            }
        case LicensesTypes.UPDATE_LICENSE:
            return {
                ...state,
                updatingSubscriptionLoading: true,
                subscriptionInfoUpdatedSuccess: false,
            }
        case LicensesTypes.UPDATE_LICENSE_SUCCESS:
            const actionSubInfo = action?.payload?.subscriptionInfo
            return {
                ...state,
                updatingSubscriptionLoading: false,
                subscriptionInfo: actionSubInfo
                    ? actionSubInfo
                    : state.subscriptionInfo,
                subscriptionInfoUpdatedSuccess: true,
            }
        case LicensesTypes.CANCEL_SUBSCRIPTION:
            return {
                ...state,
                subscriptionInfo: emptySubscriptionInfo,
                cancelSubscriptionLoading: true,
                subscriptionInfoUpdatedSuccess: false,
            }
        case LicensesTypes.CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptionInfo: emptySubscriptionInfo,
                cancelSubscriptionLoading: false,
            }
        case LicensesTypes.CANCEL_SUBSCRIPTION_FAILED:
            return {
                ...state,
                subscriptionInfo: emptySubscriptionInfo,
                cancelSubscriptionLoading: false,
            }
        case LicensesTypes.CANCEL_DOWNGRADE:
            return {
                ...state,
                subscriptionInfo: emptySubscriptionInfo,
                cancelDowngradeLoading: true,
            }
        case LicensesTypes.CANCEL_DOWNGRADE_SUCCESS:
            return {
                ...state,
                subscriptionInfo: emptySubscriptionInfo,
                cancelDowngradeLoading: false,
            }
        case LicensesTypes.CANCEL_DOWNGRADE_FAILED:
            return {
                ...state,
                subscriptionInfo: emptySubscriptionInfo,
                cancelDowngradeLoading: false,
            }
        case LicensesTypes.UPDATE_LICENSE_FAILED:
            return {
                ...state,
                updatingSubscriptionLoading: false,
            }
        case LicensesTypes.SET_EXCEDED_LIMITS:
            return {
                ...state,
                excededLimits: action.excededLimits,
            }
        case LicensesTypes.SET_REACHED_LIMITS:
            return {
                ...state,
                reachedLimits: action.limitsAreReached,
            }
        case LicensesTypes.SET_SHOW_ACCOUNT_ACTIVATED:
            return {
                ...state,
                showAccountActivatedPanel:
                    action?.payload?.showActivatedAccount,
            }
        case LicensesTypes.REMOVE_CLIENT_SECRET:
            return {
                ...state,
                clientSecret: '',
            }
        case LicensesTypes.SET_PRESELECTED_LICENSE:
            return {
                ...state,
                preSelectedLicense: action?.payload?.license,
            }
        case LicensesTypes.REQUEST_SUBSCRIPTION:
            return {
                ...state,
                subscriptionRequestStatus: ActionStatusTypes?.loading,
            }
        case LicensesTypes.REQUEST_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptionRequestStatus: ActionStatusTypes?.success,
            }
        case LicensesTypes.REQUEST_SUBSCRIPTION_FAILED:
            return {
                ...state,
                subscriptionRequestStatus: ActionStatusTypes?.failed,
            }
        case LicensesTypes.RESET_REQUEST_SUBSCRIPTION_STATUS:
            return {
                ...state,
                subscriptionRequestStatus: ActionStatusTypes?.neutral,
            }
        case SessionActionTypes.DELETE_SESSION:
            return {
                newLicense: emptyProduct,
                subscriptionInfoIsLoading: false,
                selectedPeriod: 'month',
                subscriptionInfo: emptySubscriptionInfo,
                currentProduct: emptyProduct,
                clientSecret: '',
                updatingSubscriptionLoading: false,
                cancelDowngradeLoading: false,
                subscriptionInfoUpdatedSuccess: false,
                cancelSubscriptionLoading: false,
                clientSecretErrorStatus: undefined,
                tiers: [],
                newLicensePeriod: '',
                currentSubscriptionPeriod: '',
                currentStep: subscriptionSteps[0],
                tiersAreLoading: false,
                excededLimits: [],
                reachedLimits: '',
                showAccountActivatedPanel: false,
                subscriptionRequestStatus: ActionStatusTypes?.neutral,
            }
        default:
            return state
    }
}
