import { Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { ConnectionListActionsTypes } from './connectionsTypes'
import dataService from '../../services/dataService'
import { from, of } from 'rxjs'
import {
    deleteDataAgreementFailed,
    deleteDataAgreementSuccess,
    getConnectionsFailed,
    getConnectionsSuccess,
} from './connectionsActions'
import { showNotification } from '../../features/common/Notification/notificationActions'
import { hideScreenLoaderAction } from '../../features/common/Loader/loaderActions'

export const getConnectionsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConnectionListActionsTypes.GET_CONNECTIONS),
        switchMap((action): any => {
            const { did, page, size } = action?.payload
            return from(dataService.getEWConnections(did, page, size)).pipe(
                map((response?: any) => {
                    return getConnectionsSuccess(response.response)
                }),
                catchError((error) =>
                    of(
                        getConnectionsFailed(error),
                        showNotification(
                            'ew.connections.getCredsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deleteDataAgreementEpic: Epic = (action$) => {
    return action$.pipe(
        ofType(ConnectionListActionsTypes.DELETE_DATA_AGREEMENT),
        switchMap((action): any => {
            const { dataAgreementId, did } = action.payload
            return from(
                dataService.deleteEWDataAgreement(did, dataAgreementId)
            ).pipe(
                mergeMap(() => {
                    return of(
                        deleteDataAgreementSuccess(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'ew.connections.daDeletedSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        deleteDataAgreementFailed(error),
                        hideScreenLoaderAction(),
                        showNotification(
                            'ew.connections.daDeletedError',
                            'error',
                            error.response?.data?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
