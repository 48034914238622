import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './info.module.scss'
import { t } from 'i18next'
import moment from 'moment'
import { getStatus } from '../../../../utils/displayDetailsUtil'
import { VerifiableCredential } from '../../../../data/requestCredentials.interfaces'
import { getStatusColorAndText } from '../../../../../../../utils/credentialsUtils'
import ShieldCheckIcon from '../../../../../../../assets/iconsLibrary/shiedlCheck/shieldCheckIcon'
import { gaColors } from '../../../../../../../assets/globalConstants'
import GaHoverTooltip from '../../../../../../../Components/molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import {
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../../../../data/globalVar'
import GaTag from '../../../../../../../Components/atoms/tag/Tag'
import InfoIcon from '../../../../../../../assets/iconsLibrary/simple/info/infoIcon'

type IInfoProps = {
    didAlias: string
    issuer?: string | string[]
    securityLevel?: number
    isPrivate?: boolean
    issuanceDate?: any
    status?: string
    expirationDate?: any
    isExpired?: boolean
    vcs: VerifiableCredential[]
}

const Info: React.FC<IInfoProps> = (props) => {
    const {
        didAlias,
        issuer,
        securityLevel,
        isPrivate,
        status,
        issuanceDate,
        expirationDate,
        isExpired,
        vcs,
    } = props

    const [statusValue, setStatusValue] = useState<string>(status || '')

    useEffect(() => {
        if (statusValue?.length < 2) {
            getStatus(vcs[0])
                .then((status) => {
                    setStatusValue(isExpired ? 'EXPIRED' : status)
                })
                .catch((e) => setStatusValue(''))
        }
    }, [])

    useEffect(() => {}, [statusValue])

    const showExpirationDate =
        expirationDate &&
        !isExpired &&
        statusValue !== 'EXPIRED' &&
        (statusValue === 'ISSUED' || statusValue === 'ACTIVE')

    const formattedIssuanceDate = moment(issuanceDate)?.format('MMMM DD, YYYY')
    const formattedExpirationDate = expirationDate
        ? moment(expirationDate)?.format('MMMM DD, YYYY')
        : undefined

    const statusText = getStatusColorAndText(statusValue)?.text
    const showStatusTag =
        statusValue && !(statusValue === 'ISSUED' || statusValue === 'ACTIVE')

    return (
        <div className={styles.info}>
            {issuer ? (
                <div className={styles.info__detail}>
                    <p className={cx('buttonSM')}>
                        {t('ew.requestCredentials.issuer')}
                    </p>
                    <p className={cx('bodyRegularXS neutral800')}>{issuer}</p>
                </div>
            ) : null}
            <div className={styles.info__detail}>
                <p className={cx('buttonSM')}>
                    {t('ew.requestCredentials.issuerStatus')}
                </p>
                {securityLevel && securityLevel > 0 ? (
                    <div className={styles.issuerStatus}>
                        <GaHoverTooltip
                            info={t(
                                'ew.requestCredentials.hasPermissionToIssue'
                            )}
                            label={
                                <div className={styles.issuerStatus}>
                                    <ShieldCheckIcon
                                        size={16}
                                        color={gaColors.success300}
                                        style="filled"
                                    />
                                    <p
                                        className={cx(
                                            'bodyRegularXS success300'
                                        )}
                                    >
                                        {t('ew.requestCredentials.validated')}
                                    </p>
                                </div>
                            }
                            position={TooltipPosition.bottom}
                            positionArrowDirection={TooltipArrowPosition.right}
                        />
                    </div>
                ) : (
                    <div className={styles.issuerStatus}>
                        <GaHoverTooltip
                            info={t(
                                'ew.requestCredentials.unableCheckPermissions'
                            )}
                            label={
                                <p className={cx('bodyRegularXS neutral800')}>
                                    {t('ew.requestCredentials.selfAttested')}
                                </p>
                            }
                            position={TooltipPosition.bottom}
                            positionArrowDirection={TooltipArrowPosition.right}
                        />
                    </div>
                )}
            </div>
            <div className={styles.info__detail}>
                <p className={cx('buttonSM')}>
                    {t('ew.requestCredentials.status')}
                </p>
                <p className={cx('bodyRegularXS neutral800')}>
                    {showStatusTag ? (
                        <div className={cx(styles.status)}>
                            <GaTag
                                color={
                                    getStatusColorAndText(statusValue)?.color
                                }
                                text={
                                    <GaHoverTooltip
                                        label={t(
                                            getStatusColorAndText(statusValue)
                                                ?.text
                                        )}
                                        info={t(
                                            getStatusColorAndText(statusValue)
                                                ?.info
                                        )}
                                        tooltipIconColor={gaColors.neutral100}
                                        position={TooltipPosition.bottom}
                                        positionArrowDirection={
                                            TooltipArrowPosition.right
                                        }
                                    />
                                }
                            />
                        </div>
                    ) : (
                        t(statusText)
                    )}
                </p>
            </div>
            <div className={styles.info__detail}>
                <p className={cx('buttonSM')}>
                    {t('ew.requestCredentials.awareness')}
                </p>
                <p className={cx('bodyRegularXS neutral800')}>
                    {t(
                        !isPrivate
                            ? 'ew.requestCredentials.public'
                            : 'ew.requestCredentials.private'
                    )}
                </p>
            </div>
            <div className={styles.info__detail}>
                <p className={cx('buttonSM')}>
                    {t('ew.requestCredentials.dateIssue')}
                </p>
                <p className={cx('bodyRegularXS neutral800')}>
                    {formattedIssuanceDate}
                </p>
            </div>
            {formattedExpirationDate && showExpirationDate ? (
                <div className={styles.info__detail}>
                    <p className={cx('buttonSM')}>
                        {t('ew.requestCredentials.expirationDate')}
                    </p>
                    <p className={cx('bodyRegularXS neutral800')}>
                        {formattedExpirationDate}
                    </p>
                </div>
            ) : null}
            <div className={styles.info__detail}>
                <p className={cx('buttonSM')}>DID</p>
                <p className={cx('bodyRegularXS neutral800')}>{didAlias}</p>
            </div>
        </div>
    )
}

export default Info
