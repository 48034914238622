import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    CredentialModel,
    DA_Purpose,
    ICredentialRequestedModel,
    IIssuanceConfigItemModel,
    IssuanceModel,
    StepModel,
} from '../../../interfaces/interfaces'

// LIST
export interface IssuancesListState {
    issuances: IIssuanceConfigItemModel[] | null
    loadingStatus: boolean
    status: ActionStatusTypes
    updateStatus: ActionStatusTypes
    deletedConfigId?: string
}

export interface IGetIssuancesAction {
    type: IssuanceActionsTypes.GET_ISSUANCES
}

export interface IGetIssuancesSuccessAction {
    type: IssuanceActionsTypes.GET_ISSUANCES_SUCCESS
    payload: {
        issuances: IIssuanceConfigItemModel[]
    }
}

export interface IGetIssuancesFailedAction {
    type: IssuanceActionsTypes.GET_ISSUANCES_FAILED
    payload: {
        error: string
    }
}

export interface ICreateIssuanceAction {
    type: IssuanceActionsTypes.CREATE_ISSUANCE
    payload: {
        issuance: IIssuanceConfigItemModel
    }
}

export interface ICreateIssuanceSuccessAction {
    type: IssuanceActionsTypes.CREATE_ISSUANCE_SUCCESS
    payload: {
        message: string
    }
}

export interface ICreateIssuanceFailedAction {
    type: IssuanceActionsTypes.CREATE_ISSUANCE_FAILED
    payload: {
        error: string
    }
}

export interface IDeleteIssuanceAction {
    type: IssuanceActionsTypes.DELETE_ISSUANCE
    payload: {
        issuanceID: string
    }
}

export interface IDeleteIssuanceSuccessAction {
    type: IssuanceActionsTypes.DELETE_ISSUANCE_SUCCESS
    payload: {
        id: string
    }
}

export interface IDeleteIssuanceFailedAction {
    type: IssuanceActionsTypes.DELETE_ISSUANCE_FAILED
}

export interface ISelectIssuanceAction {
    type: IssuanceActionsTypes.SELECT_ISSUANCE
    payload: {
        issuance: IIssuanceConfigItemModel
    }
}

export interface IUpdateIssuanceAction {
    type: IssuanceActionsTypes.UPDATE_ISSUANCE
    payload: {
        issuance: IIssuanceConfigItemModel
    }
}
export interface IUpdateIssuanceSuccessAction {
    type: IssuanceActionsTypes.UPDATE_ISSUANCE_SUCCESS
    payload: {
        issuance: IIssuanceConfigItemModel
    }
}

export interface IUpdateIssuanceFailedAction {
    type: IssuanceActionsTypes.UPDATE_ISSUANCE_FAILED
    payload: {
        error: string
    }
}

export interface IClearIssuanceGeneralStateAction {
    type: IssuanceActionsTypes.CLEAR_ISSUANCES_GENERAL_STATE
}

export type IssuanceListActions =
    | IGetIssuancesAction
    | IGetIssuancesSuccessAction
    | IGetIssuancesFailedAction
    | ICreateIssuanceAction
    | ICreateIssuanceSuccessAction
    | ICreateIssuanceFailedAction
    | IDeleteIssuanceAction
    | IDeleteIssuanceSuccessAction
    | IDeleteIssuanceFailedAction
    | ISelectIssuanceAction
    | IUpdateIssuanceAction
    | IUpdateIssuanceSuccessAction
    | IUpdateIssuanceFailedAction
    | IClearIssuanceGeneralStateAction

export enum IssuanceActionsTypes {
    GET_ISSUANCES = 'issuances/get',
    GET_ISSUANCES_SUCCESS = 'issuances/get_success',
    GET_ISSUANCES_FAILED = 'issuances/get_failed',
    CREATE_ISSUANCE = 'issuances/create',
    CREATE_ISSUANCE_SUCCESS = 'issuances/create_success',
    CREATE_ISSUANCE_FAILED = 'issuances/create_failed',
    DELETE_ISSUANCE = 'issuances/delete',
    DELETE_ISSUANCE_SUCCESS = 'issuances/delete_success',
    DELETE_ISSUANCE_FAILED = 'issuances/delete_failed',
    SELECT_ISSUANCE = 'issuances/select',
    UPDATE_ISSUANCE = 'issuances/update',
    UPDATE_ISSUANCE_SUCCESS = 'issuances/update_success',
    UPDATE_ISSUANCE_FAILED = 'issuances/update_failed',
    CLEAR_ISSUANCES_GENERAL_STATE = 'issuances/clear_general_state',
}

// EDIT OR CREATE FORMS
export interface IConfigIssuanceFormState {
    steps: StepModel[] | null
    firstStep: StepModel | null
    step: StepModel | null
    finalStep: StepModel | null
    credentialsSelected: ICredentialRequestedModel[] | null
    credentialsSelectedInitial: ICredentialRequestedModel[] | null
    selectedCredential: ICredentialRequestedModel | null
    purposesList: DA_Purpose[]
    issuance: IssuanceModel | null
    selectedIssuance: IIssuanceConfigItemModel
    credentialTypesSelected: string[] | null
    credentialTypesSelectedInitial: string[] | null
    credentialGroupSelected: string | null
    credentialGroupSelectedInitial: string | null
}

export interface SetActiveStepAction {
    type: ConfigIssuanceFormActionsTypes.SETSTEP
    payload: {
        step: StepModel
    }
}
export interface SetStepsAction {
    type: ConfigIssuanceFormActionsTypes.SETSTEPS
    payload: {
        steps: StepModel[]
    }
}

export interface SetSelectedCredentials {
    type: ConfigIssuanceFormActionsTypes.SET_SELECTED_CREDENTIALS
    payload: {
        credentials: CredentialModel[]
    }
}

export interface SetSelectedCredential {
    type: ConfigIssuanceFormActionsTypes.SET_SELECTED_CREDENTIAL
    payload: CredentialModel
}

export interface ClearSelectedCredential {
    type: ConfigIssuanceFormActionsTypes.CLEAR_SELECTED_CREDENTIAL
}

export interface SetPurposes {
    type: ConfigIssuanceFormActionsTypes.SET_PURPOSES
    payload: {
        purposes: DA_Purpose[]
    }
}
export interface SetIssuance {
    type: ConfigIssuanceFormActionsTypes.SET_VERIFIER
    payload: {
        issuance: IssuanceModel
    }
}
export interface ClearIssuanceFormState {
    type: ConfigIssuanceFormActionsTypes.CLEAR_ISSUANCE_FORM
}

export interface SetQRIssuance {
    type: ConfigIssuanceFormActionsTypes.SET_QR_VERIFIER
    payload: string
}

export interface EditIssuance {
    type: ConfigIssuanceFormActionsTypes.EDIT_ISSUANCE
    payload: IIssuanceConfigItemModel
}

export interface ClearIssuance {
    type: ConfigIssuanceFormActionsTypes.CLEAR_ISSUANCE
}

export type ConfigIssuanceFormActions =
    | SetActiveStepAction
    | SetStepsAction
    | SetPurposes
    | SetSelectedCredentials
    | SetSelectedCredential
    | ClearSelectedCredential
    | SetIssuance
    | ClearIssuanceFormState
    | SetQRIssuance
    | EditIssuance
    | ClearIssuance

export enum ConfigIssuanceFormActionsTypes {
    SETSTEPS = 'issuance/set_steps',
    SETSTEP = 'issuance/set_step',
    SET_PURPOSES = 'issuance/set_purposes',
    CLEAR_SELECTED_CREDENTIAL = 'issuance/clear_selected_credential',
    SET_SELECTED_CREDENTIAL = 'issuance/set_credential',
    SET_SELECTED_CREDENTIALS = 'issuance/set_credentials',
    SET_VERIFIER = 'issuance/set_issuance',
    CLEAR_ISSUANCE_FORM = 'issuance/clear_issuance_form',
    SET_QR_VERIFIER = 'issuance/set_qr_issuance',
    EDIT_ISSUANCE = 'issuance/edit_issuance',
    CLEAR_ISSUANCE = 'issuance/clear_issuance',
}

// CREATE NEW PURPOSE
export interface ICreateNewPurposeActionsState {
    newPurposeFormData: DA_Purpose | null
}

export interface SetNewPurposeAction {
    type: CreateNewPurposeActionsTypes.SET_NEW_PURPOSE
    payload: DA_Purpose | null
}
export interface ClearNewPurposeAction {
    type: CreateNewPurposeActionsTypes.CLEAR_NEW_PURPOSE
}

export type CreateNewPurposeActions =
    | SetNewPurposeAction
    | ClearNewPurposeAction

export enum CreateNewPurposeActionsTypes {
    SET_NEW_PURPOSE = 'purpose/setNewPurpose',
    CLEAR_NEW_PURPOSE = 'purpose/ClearNewPurpose',
}
