import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { gaImages } from '../../../../../assets/globalConstants'
import { ImageModel } from '../../../../../interfaces/interfaces'
import GaImage from '../../../../atoms/Image/Image'
import styles from './errorPanel.module.scss'

type IErrorPanelProps = {
    label: string
    label2?: string
    label3?: string
    hasIcon?: boolean
    hasTitle?: boolean
    icon?: ImageModel
}

const ErrorPanel: React.FC<IErrorPanelProps> = (props) => {
    const { label, label2, label3, hasIcon, hasTitle, icon } = props
    const { t } = useTranslation()

    return (
        <div className={styles.errorPanel}>
            <div className={styles.errorPanel__header}>
                {hasIcon && <GaImage image={icon || gaImages.failCircle} />}
                {hasTitle && (
                    <p className={styles.errorPanel__header__title}>
                        {t('public.error')}
                    </p>
                )}
            </div>
            <div className={styles.errorPanel__content}>
                <p>
                    <Trans i18nKey={label} />
                </p>
                <p>{label2 && <Trans i18nKey={label2} />}</p>
                <p>{label3 && <Trans i18nKey={label3} />}</p>
            </div>
        </div>
    )
}

export default ErrorPanel
