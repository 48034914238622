import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    DA_Purpose,
    ICredentialRequestedModel,
    IVerifierConfigModel,
    StepModel,
} from '../../../interfaces/interfaces'

// LIST
export interface VerifierConfigsListState {
    verifiers: IVerifierConfigModel[] | null
    loadingStatus: boolean
    status: ActionStatusTypes
    updateStatus: ActionStatusTypes
    purposes: DA_Purpose[] | null
    deletedConfigId?: string
}

export interface IGetVerifierConfigsAction {
    type: VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS
}

export interface IGetVerifierConfigsSuccessAction {
    type: VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS_SUCCESS
    payload: {
        verifiers: IVerifierConfigModel[]
    }
}

export interface IGetVerifierConfigsFailedAction {
    type: VerifierConfigActionsTypes.GET_VERIFIER_CONFIGS_FAILED
    payload: {
        error: string
    }
}

export interface IGetVerifierPurposesAction {
    type: VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES
}

export interface IGetVerifierPurposesSuccessAction {
    type: VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES_SUCCESS
    payload: {
        purposes: DA_Purpose[]
    }
}

export interface IGetVerifierPurposesFailedAction {
    type: VerifierConfigActionsTypes.GET_VERIFIER_PURPOSES_FAILED
    payload: {
        error: string
    }
}

export interface ICreateVerifierAction {
    type: VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG
    payload: {
        verifier: IVerifierConfigModel
    }
}

export interface ICreateVerifierSuccessAction {
    type: VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG_SUCCESS
    payload: {
        message: string
    }
}

export interface ICreateVerifierFailedAction {
    type: VerifierConfigActionsTypes.CREATE_VERIFIER_CONFIG_FAILED
    payload: {
        error: string
    }
}

export interface IDeleteVerifierAction {
    type: VerifierConfigActionsTypes.DELETE_VERIFIER_CONFIG
    payload: {
        verifierID: string
    }
}

export interface IDeleteVerifierSuccessAction {
    type: VerifierConfigActionsTypes.DELETE_VERIFIER_CONFIG_SUCCESS
    payload: {
        id: string
    }
}

export interface IDeleteVerifierFailedAction {
    type: VerifierConfigActionsTypes.DELETE_VERIFIER_CONFIG_FAILED
}

export interface ISelectVerifierAction {
    type: VerifierConfigActionsTypes.SELECT_VERIFIER_CONFIG
    payload: {
        verifier: IVerifierConfigModel
    }
}

export interface IUpdateVerifierAction {
    type: VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG
    payload: {
        verifier: IVerifierConfigModel
    }
}
export interface IUpdateVerifierSuccessAction {
    type: VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG_SUCCESS
}

export interface IUpdateVerifierFailedAction {
    type: VerifierConfigActionsTypes.UPDATE_VERIFIER_CONFIG_FAILED
    payload: {
        error: string
    }
}
export interface IClearVeririfersGeneralStateAction {
    type: VerifierConfigActionsTypes.CLEAR_VERIFIERS_GENERAL_STATE
}

export enum VerifierConfigActionsTypes {
    GET_VERIFIER_CONFIGS = 'verifiers/get',
    GET_VERIFIER_CONFIGS_SUCCESS = 'verifiers/get_success',
    GET_VERIFIER_CONFIGS_FAILED = 'verifiers/get_failed',
    CREATE_VERIFIER_CONFIG = 'verifiers/create',
    GET_VERIFIER_PURPOSES = 'verifiers/purposes/get',
    GET_VERIFIER_PURPOSES_SUCCESS = 'verifiers/purposes/get_success',
    GET_VERIFIER_PURPOSES_FAILED = 'verifiers/purposes/get_failed',
    CREATE_VERIFIER_CONFIG_SUCCESS = 'verifiers/create_success',
    CREATE_VERIFIER_CONFIG_FAILED = 'verifiers/create_failed',
    DELETE_VERIFIER_CONFIG = 'verifiers/delete',
    DELETE_VERIFIER_CONFIG_SUCCESS = 'verifiers/delete_success',
    DELETE_VERIFIER_CONFIG_FAILED = 'verifiers/delete_failed',
    SELECT_VERIFIER_CONFIG = 'verifiers/select',
    UPDATE_VERIFIER_CONFIG = 'verifiers/update',
    UPDATE_VERIFIER_CONFIG_SUCCESS = 'verifiers/update_success',
    UPDATE_VERIFIER_CONFIG_FAILED = 'verifiers/update_failed',
    CLEAR_VERIFIERS_GENERAL_STATE = 'verifiers/clear_general_state',
}

export type VerifiersListActions =
    | IGetVerifierConfigsAction
    | IGetVerifierConfigsSuccessAction
    | IGetVerifierConfigsFailedAction
    | IGetVerifierPurposesAction
    | IGetVerifierPurposesSuccessAction
    | IGetVerifierPurposesFailedAction
    | ICreateVerifierAction
    | ICreateVerifierSuccessAction
    | ICreateVerifierFailedAction
    | IDeleteVerifierAction
    | IDeleteVerifierSuccessAction
    | IDeleteVerifierFailedAction
    | ISelectVerifierAction
    | IUpdateVerifierAction
    | IUpdateVerifierSuccessAction
    | IUpdateVerifierFailedAction
    | IClearVeririfersGeneralStateAction

// FORM

// EDIT OR CREATE FORMS
export interface IConfigVerifierFormState {
    steps: StepModel[] | null
    firstStep: StepModel | null
    step: StepModel | null
    finalStep: StepModel | null
    credentialsSelected: ICredentialRequestedModel[] | null
    credentialsSelectedInitial: ICredentialRequestedModel[] | null
    selectedCredential: ICredentialRequestedModel | null
    purposesList: DA_Purpose[]
    qrVerifier?: string
    selectedVerifier: IVerifierConfigModel
    ignoreDataAgreement: boolean
}

export interface SetActiveStepAction {
    type: ConfigVerifierFormActionsTypes.SETSTEP
    payload: {
        step: StepModel
    }
}
export interface SetStepsAction {
    type: ConfigVerifierFormActionsTypes.SETSTEPS
    payload: {
        steps: StepModel[]
    }
}

export interface SetSelectedCredentials {
    type: ConfigVerifierFormActionsTypes.SET_SELECTED_CREDENTIALS
    payload: {
        credentials: ICredentialRequestedModel[]
    }
}

export interface SetSelectedCredential {
    type: ConfigVerifierFormActionsTypes.SET_SELECTED_CREDENTIAL
    payload: ICredentialRequestedModel
}

export interface ClearSelectedCredential {
    type: ConfigVerifierFormActionsTypes.CLEAR_SELECTED_CREDENTIAL
}

export interface SetPurposes {
    type: ConfigVerifierFormActionsTypes.SET_PURPOSES
    payload: {
        purposes: DA_Purpose[]
    }
}
export interface SetVerifier {
    type: ConfigVerifierFormActionsTypes.SET_VERIFIER
    payload: {
        verifier: IVerifierConfigModel
    }
}
export interface ClearVerifierFormState {
    type: ConfigVerifierFormActionsTypes.CLEAR_VERIFIER_FORM
}

export interface SetQRVerifier {
    type: ConfigVerifierFormActionsTypes.SET_QR_VERIFIER
    payload: string
}

export interface EditVerifier {
    type: ConfigVerifierFormActionsTypes.EDIT_VERIFIER
    payload: IVerifierConfigModel
}

export interface ClearVerifier {
    type: ConfigVerifierFormActionsTypes.CLEAR_VERIFIER
}

export type ConfigVerifierFormActions =
    | SetActiveStepAction
    | SetStepsAction
    | SetPurposes
    | SetSelectedCredentials
    | SetSelectedCredential
    | ClearSelectedCredential
    | SetVerifier
    | ClearVerifierFormState
    | SetQRVerifier
    | EditVerifier
    | ClearVerifier

export enum ConfigVerifierFormActionsTypes {
    SETSTEPS = 'verifier/set_steps',
    SETSTEP = 'verifier/set_step',
    SET_PURPOSES = 'verifier/set_purposes',
    CLEAR_SELECTED_CREDENTIAL = 'verifier/clear_selected_credential',
    SET_SELECTED_CREDENTIAL = 'verifier/set_credential',
    SET_SELECTED_CREDENTIALS = 'verifier/set_credentials',
    SET_VERIFIER = 'verifier/set_verifier',
    CLEAR_VERIFIER_FORM = 'verifier/clear_verifier_form',
    SET_QR_VERIFIER = 'verifier/set_qr_verifier',
    EDIT_VERIFIER = 'verifier/edit_verifier',
    CLEAR_VERIFIER = 'verifier/clear_verifier',
}

// CREATE NEW PURPOSE
export interface ICreateNewPurposeActionsState {
    newPurposeFormData: DA_Purpose | null
}

export interface SetNewPurposeAction {
    type: CreateNewPurposeActionsTypes.SET_NEW_PURPOSE
    payload: DA_Purpose | null
}
export interface ClearNewPurposeAction {
    type: CreateNewPurposeActionsTypes.CLEAR_NEW_PURPOSE
}

export type CreateNewPurposeActions =
    | SetNewPurposeAction
    | ClearNewPurposeAction

export enum CreateNewPurposeActionsTypes {
    SET_NEW_PURPOSE = 'purpose/setNewPurpose',
    CLEAR_NEW_PURPOSE = 'purpose/ClearNewPurpose',
}
