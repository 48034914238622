import React from 'react'
import cx from 'classnames'
import ChevronLeftIcon from '../../assets/iconsLibrary/simple/chevronLeft/chevronLeftIcon'
import styles from './pagination.module.scss'
import ChevronRightIcon from '../../assets/iconsLibrary/simple/chevronRight/chevronRightIcon'
import { Trans } from 'react-i18next'
import { t } from 'i18next'
import { PaginationModelOptions, createPagination } from './PaginationLogic'

function Page(props: PaginationModelOptions) {
    const { isActive, disabled, value, onClick } = props
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={cx(
                styles.pagination__item,
                isActive ? styles.selected : undefined
            )}
        >
            {value}
        </button>
    )
}

function Ellipsis(props: PaginationModelOptions) {
    const { disabled, onClick } = props
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={cx(styles.pagination__item, styles.dots)}
        >
            &#8230;
        </button>
    )
}

function FirstPageLink(props: PaginationModelOptions) {
    const { isDisabled, onClick } = props
    return (
        <button onClick={onClick} disabled={isDisabled}>
            {t('general.first')}
        </button>
    )
}

function PreviousPageLink(props: PaginationModelOptions) {
    const { isDisabled, isActive, onClick } = props
    return (
        <button
            onClick={onClick}
            disabled={isDisabled}
            className={cx(
                styles.pagination__item,
                styles.arrow,
                !isActive || isDisabled ? styles.disabled : undefined
            )}
        >
            <ChevronLeftIcon size={16} />
        </button>
    )
}

function NextPageLink(props: PaginationModelOptions) {
    const { isDisabled, isActive, disabled, onClick } = props
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={cx(
                styles.pagination__item,
                styles.arrow,
                !isActive || isDisabled ? styles.disabled : undefined
            )}
        >
            <ChevronRightIcon size={16} />
        </button>
    )
}

function LastPageLink(props: PaginationModelOptions) {
    const { disabled, onClick } = props
    return (
        <button onClick={onClick} disabled={disabled}>
            {t('general.last')}
        </button>
    )
}

function Wrapper(props: PaginationModelOptions) {
    const { currentPage, children, className, pageSize, totalItems } = props

    const itemsToShow = Array.from(
        { length: totalItems || 0 },
        (_, i) => `Item ${i + 1}`
    )

    const items = pageSize
        ? Array.from(
              { length: Math.ceil(itemsToShow.length / pageSize) },
              (_, i) => getPageLabel(itemsToShow.length, pageSize, i)
          )
        : undefined

    function getPageStart(pageSize, pageNr) {
        return pageSize * pageNr
    }

    function getPageLabel(total, pageSize, pageNr) {
        const start = Math.max(getPageStart(pageSize, pageNr), 0)
        const end = Math.min(getPageStart(pageSize, pageNr + 1), total)

        return `${start + 1} - ${end}`
    }

    return (
        <div className={cx(styles.paginator, className && className)}>
            {pageSize && items ? (
                <div>
                    <p className={cx('bodyRegularSM neutral700')}>
                        <Trans
                            i18nKey={'general.paginationInfo'}
                            values={{
                                startPageItem: items[currentPage - 1],
                                finishPageItem: totalItems,
                            }}
                        />
                    </p>
                </div>
            ) : null}
            <div className={cx(styles.pagination__container)}>{children}</div>
        </div>
    )
}

const itemTypeToComponent = {
    PAGE: Page,
    ELLIPSIS: Ellipsis,
    FIRST_PAGE_LINK: FirstPageLink,
    PREVIOUS_PAGE_LINK: PreviousPageLink,
    NEXT_PAGE_LINK: NextPageLink,
    LAST_PAGE_LINK: LastPageLink,
}

export const GaPagination = createPagination({
    itemTypeToComponent: itemTypeToComponent,
    WrapperComponent: Wrapper,
})
