import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './subtext.module.scss'

type SubtextProps = {
    text: string
    className?: string
}

const Subtext: React.FC<SubtextProps> = React.memo((props) => {
    const { text, className } = props
    const { t } = useTranslation()

    return (
        <p className={`${styles.subtext} ${className && className} `}>
            <Trans defaults={t(text)} />
        </p>
    )
})

export default Subtext
