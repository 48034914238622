import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { issuanceSelectors } from '../..'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import InvalidFormModal from '../../../../../Components/molecules/table/dynamicTable/invalidFormModal/InvalidFormModal'
import { IIssuanceConfigItemModel } from '../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { addIssuerToGroup } from '../../../../common/catalog/catalogActions'
import { didsSelectors } from '../../../Dids'
import { addIssuerType, getDids } from '../../../Dids/didActions'
import { didsScopes } from '../../../Dids/views/didList/didList.constants'
import { sessionSelectors } from '../../../../common/session'
import { formSteps } from '../../issuance.constants'
import {
    clearIssuance,
    createIssuance,
    editIssuance,
    setActiveStep,
    setSelectedCredentials,
    setSteps,
} from '../../issuanceActions'
import { getRequestedCredentials } from '../../issuanceSelectors'
import SuccessEnd from '../successEnd/SuccessEnd'
import styles from './createIssuance.module.scss'
import { getCertificates } from '../../../certificates/certificatesActions'
import { onPremise } from '../../../../../data/globalVar'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'

type ICreateIssuanceProps = {}

const CreateIssuance: React.FC<ICreateIssuanceProps> = ({}) => {
    const dispatch = useDispatch()
    const history = useHistory()

    // Selectors
    const endsStep = useSelector(issuanceSelectors.getEndsSteps)
    const activeStep = useSelector(issuanceSelectors.getActiveStep)
    const issuance = useSelector(issuanceSelectors.getSelectedIssuance)
    const createStatus = useSelector(issuanceSelectors.getOperationStatus)
    const selected = useSelector(getRequestedCredentials)
    const didOptions = useSelector(didsSelectors.getdidConfigs)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const canReadDids = !!(
        availableScopes && userHasScopes(didsScopes?.read, availableScopes)
    )

    // States
    const [validForm, setValidForm] = React.useState(false)
    const [showInvalidFormModal, setShowInvalidDataModal] =
        React.useState(false)
    const [lastStepSaved, setLastStepSaved] = React.useState(0)
    const [requirementsStepModified, setRequirementsStepModified] =
        React.useState(false)

    // Effects
    useEffect(() => {
        canReadDids && dispatch(getDids())
        canReadDids && dispatch(getCertificates())
    }, [])

    useEffect(() => {
        if (didOptions) {
            dispatch(setSteps(formSteps))
        }
    }, [formSteps])

    useEffect(() => {}, [createStatus])

    // Functions
    const formatIssuance = (selectedIssuance) => {
        let formattedIssuance = selectedIssuance
        delete formattedIssuance._searchableText
        formattedIssuance.prConfig.id = formattedIssuance.id

        const issuanceCredentials =
            selectedIssuance?.prConfig?.credentials?.map((e) => {
                return {
                    type: e.type || e.id,
                    purpose: e.purpose,
                    mandatory: e.mandatory,
                    trustLevel: e.trustLevel,
                }
            })

        if (formattedIssuance?.prConfig?.credentials) {
            formattedIssuance!.prConfig!.credentials = issuanceCredentials
        }

        return formattedIssuance
    }

    const forwardFunction = (data: IIssuanceConfigItemModel) => {
        setLastStepSaved(
            activeStep && activeStep?.id >= lastStepSaved
                ? activeStep?.id
                : lastStepSaved
        )

        const newIssuance = { ...issuance, ...data }
        if (
            !newIssuance?.prConfig?.credentials ||
            !newIssuance?.prConfig?.credentials[0]?.type
        ) {
            newIssuance!.prConfig!.credentials = selected || []
        }
        const formattedIssuance = formatIssuance(newIssuance)
        updateDidType(formattedIssuance)
        dispatch(editIssuance(newIssuance))
        dispatch(setSelectedCredentials(selected || []))
        return endsStep && endsStep.nextStep
            ? dispatch(setActiveStep(endsStep.nextStep))
            : (dispatch(showScreenLoaderAction()),
              dispatch(
                  createIssuance(formattedIssuance as IIssuanceConfigItemModel)
              ))
    }

    const updateDidType = (newIssuanceConfig) => {
        const currentVMethod = issuance?.prConfig?.vmethodId
        const filledVMethod = newIssuanceConfig?.prConfig?.vmethodId
        if (activeStep?.order === 0 && currentVMethod !== filledVMethod) {
            let issuerDid = filledVMethod?.split('#')[0]
            // Just in cloud for now
            !onPremise && dispatch(addIssuerType(issuerDid))
        }
        if (
            activeStep?.order === 1 &&
            issuance?.credentialGroup !== newIssuanceConfig?.credentialGroup
        ) {
            let issuerDid = currentVMethod?.split('#')[0]

            if (issuerDid) {
                /* Just in cloud for now and will be done through it.
                In the future maybe it's not necessary or will there is a request to connect API for this. */
                !onPremise &&
                    dispatch(
                        addIssuerToGroup(
                            issuerDid,
                            newIssuanceConfig?.credentialGroup
                        )
                    )
            }
        }
    }

    const showInvalidDataModal = () => setShowInvalidDataModal(true)
    const hideInvalidDataModal = () => setShowInvalidDataModal(false)

    const onHandleChange = (step) => {
        if (
            (activeStep && step < activeStep?.order) ||
            (activeStep &&
                (step <= lastStepSaved ||
                    (!requirementsStepModified && activeStep?.order === 3)))
        ) {
            dispatch(setActiveStep(formSteps[step]))
        } else {
            showInvalidDataModal()
        }
    }

    return (createStatus as ActionStatusTypes) !== ActionStatusTypes.success ? (
        <>
            <div className={styles.createIssuance}>
                {activeStep?.component ? (
                    <activeStep.component
                        buttonFunction={forwardFunction}
                        issuance={issuance}
                        setValidForm={setValidForm}
                        canEditIssuance={true}
                        creatingIssuance={true}
                        activeStep={activeStep || formSteps[0]}
                        onHandleChange={onHandleChange}
                        setRequirementsStepModified={
                            setRequirementsStepModified
                        }
                        formSteps={formSteps}
                    />
                ) : null}
            </div>

            {!!showInvalidFormModal ? (
                <InvalidFormModal hideModal={hideInvalidDataModal} />
            ) : null}
        </>
    ) : (
        <SuccessEnd />
    )
}

export default CreateIssuance
