import {
    CellContentTypes,
    TextAlignTypes,
} from '../../../../../assets/globalConstants'
import { ButtonModel } from '../../../../../interfaces/interfaces'

export const propertiesToSearch = [
    {
        text: 'id',
        searchMultipleLanguages: false,
        latinize: false,
    },
    {
        text: 'alias',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const modalPrimaryButton: ButtonModel = {
    label: 'public.submit',
}

export const modalSecondaryButton: ButtonModel = {
    label: 'public.cancel',
}

export const tableItemOptions = [
    {
        text: 'public.delete',
        value: 'delete',
        color: 'red',
    },
]

export const headersCertificatesTable = [
    {
        key: 'alias',
        text: 'certificates.alias',
        tag: true,
        type: CellContentTypes.text,
    },
]

export const certificatesScopes = {
    read: ['readSessions'],
    edit: ['manageCredentials'],
    validate: ['validateSessions'],
}
