import React from 'react'
import { useTranslation } from 'react-i18next'
import { gaImages } from '../../../../../assets/globalConstants'
import GaImage from '../../../../atoms/Image/Image'
import styles from './invalidFormModal.module.scss'

type IInvalidFormModalProps = {
    hideModal: () => void
}

const InvalidFormModal: React.FC<IInvalidFormModalProps> = (props) => {
    const { hideModal } = props
    const { t } = useTranslation()

    return (
        <>
            <div
                className={`${styles.backgroundLayer}`}
                onClick={() => hideModal()}
            >
                <div className={styles.modal}>
                    <GaImage
                        className={styles.modal__icon}
                        image={gaImages.warning}
                    />
                    <p className={styles.modal__title}>
                        {t('public.checkData')}
                    </p>
                </div>
            </div>
        </>
    )
}

export default InvalidFormModal
