import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    brandColors,
    brandImages,
    brandLabels,
} from '../../../assets/globalConstants'
import { onPremise } from '../../../data/globalVar'
import { showNotification } from '../Notification/notificationActions'
import { setSession } from '../session/sessionActions'
import { loginFailed } from './loginAction'

export enum RESULT_STATUS {
    ONGOING = 0,
    SUCCESS = 1,
    FAILED = 2,
}

type LoginQRProps = {
    stop?: (boolean) => void
    qrColor?: string
    logoSrc?: string
}

export const LoginQR: React.FC<LoginQRProps> = (props: LoginQRProps) => {
    const { qrColor, logoSrc, stop } = props
    const { t } = useTranslation()
    const qr = useRef(null)
    const dispatch = useDispatch()
    const history = useHistory()
    let connectToken: string | null
    let token: string
    let data: any
    let sessionId: string
    let signup: boolean
    let displaying: boolean = false

    const gatacaLoginSuccess = (newdata: any) => {
        //@ts-ignore
        qr?.current?.stop()
        dispatch(setSession(signup)) //Set Session will redirect to welcome if signup
    }

    const gatacaLoginError = (error: any) => {
        // console.log('Gataca Login Error', error)
        //@ts-ignore
        qr?.current?.stop()
        dispatch(loginFailed(error))
    }

    const createSessionId = async (): Promise<{
        sessionId: string
        authenticationRequest?: string
    }> => {
        let endpoint = '/admin/v1/users/login/request'
        let response = await fetch(endpoint, { mode: 'cors' })
        let data = await response.json()
        connectToken = data.connect_token
        sessionId = data.session_id
        return {
            sessionId: sessionId,
            authenticationRequest: data.authentication_request,
        }
    }

    const checkStatus = async (): Promise<{
        result: RESULT_STATUS
        data?: any
    }> => {
        let endpoint = '/admin/v1/users/login/gataca'
        let response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-id': sessionId,
            },
            body: '{}',
            mode: 'cors',
        })
        switch (response.status) {
            case 200:
                token = response.headers.get('token') || ''
                data = (await response.json()).data
                return {
                    result: RESULT_STATUS.SUCCESS,
                    data: { token: token, data: data },
                }
            case 201:
                token = response.headers.get('token') || ''
                data = (await response.json()).message
                signup = true
                return {
                    result: RESULT_STATUS.SUCCESS,
                    data: { token: token, data: data, signup: true },
                }
            case 202:
                return { result: RESULT_STATUS.ONGOING }
            case 403:
                onPremise
                    ? dispatch(
                          showNotification(
                              'login.errorAccessOP',
                              'error',
                              'login.errorAccessOPDesc',
                              '',
                              600
                          )
                      )
                    : null

                return { result: RESULT_STATUS.FAILED }
            default:
                return { result: RESULT_STATUS.FAILED }
        }
    }

    useEffect(() => {
        if (qr !== null && qr.current !== null && !displaying) {
            let gqr: HTMLGatacaQrElement = qr.current! as HTMLGatacaQrElement
            displaying = true
            gqr.qrModalTitle = t('login.qrTitle')
            gqr.qrModalDescription = t('login.qrAuxText')
            gqr.createSession = createSessionId
            gqr.checkStatus = checkStatus
            gqr.successCallback = gatacaLoginSuccess
            gqr.errorCallback = gatacaLoginError
        }
    })

    return (
        <>
            <section className="login-page">
                <div className="login-module">
                    <div className="gatacaLogin-form-container">
                        {
                            // @ts-ignore
                            <gataca-qr
                                callback-server={
                                    process.env.REACT_APP_CONNECT_HOST
                                }
                                qr-role="connect"
                                qr-modal-title={t('login.fastAccess')}
                                hide-brand-title={true}
                                ref={qr}
                                logo-size="0.33"
                                logo-src={brandImages?.brandLogo?.src}
                                qr-code-expired-label={t('login.qrExpired')}
                                credentials-not-validated-label={t(
                                    'login.userCredNotValidated'
                                )}
                                click-inside-box-label={t(
                                    'login.clickInsideBoxLabel'
                                )}
                                refresh-qr-label={t('login.refreshQrLabel')}
                                scan-qr-label={t('login.scanQrLabel')}
                                user-not-scan-in-time-error-label={t(
                                    'login.userNotScanInTimeErrorLabel'
                                )}
                                creds-not-validated-error-label={t(
                                    'login.credsNotValidatedErrorLabel'
                                )}
                                modal-title-color={brandColors?.primaryColor}
                                failed-login-error-label={t(
                                    'login.failedLoginErrorLabel'
                                )}
                                success-login-label={t(
                                    'login.successLoginLabel'
                                )}
                                by-brand-label={t('login.byBrandLabel', {
                                    brand: brandLabels?.brand,
                                })}
                                waiting-start-session-label={t(
                                    'login.waitingStartSessionLabel'
                                )}
                                hide-qr-modal-description="true"
                                polling-frequency={3}
                                session-timeout={175}
                                v-2={process.env.REACT_APP_USE_V2}
                            />
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginQR
