import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../../../../assets/images/close.svg'
import { ButtonModel } from '../../../../../interfaces/interfaces'
import { hideNotification } from '../../notificationActions'

import styles from './infoNotification.module.scss'

type IInfoNotificationProps = {
    title?: string
    label: string
    closeButton?: ButtonModel
    className?: string
}

const InfoNotification: React.FC<IInfoNotificationProps> = (props) => {
    const { title, label, closeButton, className } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {}, [label])

    const closeNotification = () => dispatch(hideNotification())

    return (
        <div
            className={`${styles.infoNotification} ${
                className ? className : ''
            }`}
        >
            <div className={styles.infoNotification__content}>
                <div
                    className={`${closeButton ? '' : styles.fullWidth} ${
                        styles.infoNotification__content__texts
                    }`}
                >
                    {title ? (
                        <p
                            className={`${
                                closeButton ? '' : styles.fullWidth
                            } ${styles.title}`}
                        >
                            {t(title)}
                        </p>
                    ) : null}
                    {label && (
                        <p className={styles.label}>
                            <Trans i18nKey={label} />
                        </p>
                    )}
                </div>
                {closeButton && (
                    <div
                        className={styles.infoNotification__content__close}
                        onClick={() => closeNotification()}
                    >
                        <CloseIcon className={styles.closeIcon} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default InfoNotification
