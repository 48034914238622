import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
    brandLabels,
    privateKeyTypeOptions,
    PrivateKeyTypes,
} from '../../../../../assets/globalConstants'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import InfoPanel from '../../../../../Components/molecules/notification/panels/info/infoPanel'
import FormSection from '../../../../../Components/organisms/FormSection/FormSection'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import { getKeypair } from '../../didActions'
import { getIsKeypairLoading } from '../../didSelectors'
import { onPremise } from '../../../../../data/globalVar'

type IKeysFormProps = {
    didCount: number
    currentDid: IDIDModel
    buttonFunction: (x?) => void
}

const KeysForm: React.FC<IKeysFormProps> = (props) => {
    const { didCount, currentDid, buttonFunction } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isKeypairLoading = useSelector(getIsKeypairLoading)

    const [formData, setFormState] = useState({
        count: didCount || 0,
        publicKey: currentDid?.publicKey,
        privateKeyData: currentDid?.privateKeyData,
        privateKeyType: currentDid?.privateKeyType,
    })

    let { count, publicKey, privateKeyData, privateKeyType } = formData

    useEffect(() => {
        setFormState({
            ...formData,
            publicKey: currentDid?.publicKey,
            privateKeyData: currentDid?.privateKeyData,
            privateKeyType: currentDid?.privateKeyType,
        })
    }, [currentDid])

    const onSubmit = (e) => {
        e.preventDefault()
        buttonFunction({ ...currentDid, formData })
    }

    return (
        <FormSection
            title={'createDid.step2.sectionTitle'}
            description={'createDid.step2.sectionDescription'}
            extraTranslationParams={{ brand: brandLabels?.brand }}
        >
            <form onSubmit={onSubmit}>
                <GaTextInput
                    className={cx('margin-bottom')}
                    id={'public-key'}
                    invalid={false}
                    labelText={t('createDid.step2.publicKeyLabel')}
                    isLoading={isKeypairLoading}
                    onChange={(e) =>
                        setFormState({
                            ...formData,
                            count: count + 1,
                            publicKey: e.target.value,
                        })
                    }
                    placeholder={t('createDid.step2.publicKeyPlaceholder')}
                    value={publicKey || ''}
                />
                <GaTextInput
                    className={cx('margin-bottom')}
                    id={'private-key'}
                    invalid={false}
                    isLoading={isKeypairLoading}
                    labelText={
                        privateKeyType === PrivateKeyTypes.DB
                            ? t('createDid.step2.privateKeyLabel')
                            : t('createDid.step2.privateKeyFileLabel')
                    }
                    onChange={(e) =>
                        setFormState({
                            ...formData,
                            privateKeyData: e.target.value,
                        })
                    }
                    placeholder={
                        privateKeyType === PrivateKeyTypes.DB
                            ? t('createDid.step2.privateKeyPlaceholder')
                            : t('createDid.step2.privateKeyFilePlaceholder')
                    }
                    value={privateKeyData || ''}
                />

                <InfoPanel
                    label={t('createDid.step2.generateKeypairInfo')}
                    hasIcon={true}
                />
                <LightButton
                    className={cx('margin-top', 'margin-bottom')}
                    text={'createDid.step2.generateKeypair'}
                    disabled={false}
                    functionality={() => {
                        // Just in cloud for now
                        !onPremise && dispatch(getKeypair())
                    }}
                />

                <GaSelect
                    idSelect={'privateKeyType'}
                    className={cx('margin-bottom')}
                    value={privateKeyType}
                    id="select-privateKeyType"
                    invalid={false}
                    labelText={t('createDid.step2.privateKeyTypesLabel')}
                    optionTextProperty={'text'}
                    optionValueProperty={'value'}
                    name={'privateKeyType'}
                    helperText={t('createDid.step2.privateKeyTypesInfo')}
                    onChange={(e) => {
                        setFormState({
                            ...formData,
                            privateKeyType: e.target.value as PrivateKeyTypes,
                        })
                    }}
                    options={privateKeyTypeOptions}
                />
            </form>

            <LightButton
                className={cx('margin-top-m', 'margin-left-auto')}
                text={'public.next'}
                disabled={false}
                functionality={() => {
                    buttonFunction(formData)
                }}
            />
        </FormSection>
    )
}

export default KeysForm
