import React from 'react'
import styles from './desktopPreview.module.scss'
import cx from 'classnames'
import GatacaLogoIcon from '../../../../../../../assets/iconsLibrary/gatacaLogo/gatacaLogoIcon'
import { Trans } from 'react-i18next'
import { gaColors, gaImages } from '../../../../../../../assets/globalConstants'
import GaImage from '../../../../../../../interfaces/Image/Image'
import ChevronDownIcon from '../../../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import { t } from 'i18next'
import { IAppIntScope } from '../../../../../../../interfaces/interfaces'
import CheckIcon from '../../../../../../../assets/iconsLibrary/simple/check/checkIcon'
import { initialLang } from '../../../../../../../i18n'
import StoresPanel from '../components/storesPanel/StoresPanel'

type DesktopPreviewProps = {
    scopes?: IAppIntScope[]
    selectedScopes?: string[]
    companyLogo?: string
    companyLogoChecked: boolean
    orgName?: string
}

const DesktopPreview: React.FC<DesktopPreviewProps> = React.memo((props) => {
    const { companyLogo, orgName, scopes, selectedScopes, companyLogoChecked } =
        props

    return (
        <div className={cx(styles.previewImageContainer)}>
            <div className={cx(styles.previewImage)}>
                <div className={cx(styles.previewImage__header)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={cx(styles.previewImage__body)}>
                    <div className={cx(styles.previewImage__body__left)}>
                        <div
                            className={cx(
                                styles.previewImage__body__left__getVerified
                            )}
                        >
                            <GatacaLogoIcon
                                size={11}
                                color={gaColors.primary700}
                            />
                            <p>
                                <Trans
                                    defaults={
                                        'appIntegrations.verificationPage.poweredBy'
                                    }
                                    components={{
                                        bold: <strong />,
                                    }}
                                />
                            </p>
                        </div>
                        <div
                            className={cx(
                                styles.previewImage__body__left__oneClick
                            )}
                        >
                            <GaImage image={gaImages.scanToVerificate} />
                            <p>
                                <Trans
                                    defaults={
                                        'appIntegrations.verificationPage.oneClick'
                                    }
                                    components={{
                                        bold: <strong />,
                                    }}
                                />
                            </p>
                        </div>
                        <p
                            className={cx(
                                styles.previewImage__body__left__language
                            )}
                        >
                            {t('appIntegrations.tutorial.language')}
                            <ChevronDownIcon
                                size={7}
                                color={gaColors.neutral700}
                            />
                        </p>
                    </div>
                    <div className={cx(styles.previewImage__body__right)}>
                        <div
                            className={cx(
                                styles.previewImage__body__right__content
                            )}
                        >
                            {companyLogoChecked ? (
                                !companyLogo?.trim() ? (
                                    <div
                                        className={cx(
                                            'neutral500',
                                            styles.previewImage__body__right__content__companyLogo
                                        )}
                                    >
                                        {t(
                                            'appIntegrations.verificationPage.companyLogo'
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        className={cx(
                                            styles.previewImage__body__right__content__companyLogoImg
                                        )}
                                    >
                                        <img src={companyLogo} />
                                    </div>
                                )
                            ) : null}
                            <div
                                className={cx(
                                    'neutral700',
                                    styles.previewImage__body__right__content__scan
                                )}
                            >
                                <p>
                                    {t('appIntegrations.verificationPage.scan')}
                                </p>
                                <p>
                                    <Trans
                                        defaults={
                                            'appIntegrations.verificationPage.toConinue'
                                        }
                                        components={{
                                            bold: <strong />,
                                        }}
                                        values={{
                                            orgName:
                                                orgName ||
                                                t(
                                                    'appIntegrations.verificationPage.yourOrgName'
                                                ),
                                        }}
                                    />
                                </p>
                            </div>
                            <div
                                className={cx(
                                    styles.previewImage__body__right__content__qr
                                )}
                            >
                                <div>
                                    <GaImage image={gaImages.qrExample} />
                                </div>
                                <div
                                    className={cx(
                                        styles.previewImage__body__right__content__qr__shared
                                    )}
                                >
                                    <div
                                        className={cx(
                                            styles.previewImage__body__right__content__qr__shared__header
                                        )}
                                    >
                                        <p className={cx('neutral700')}>
                                            {t(
                                                'appIntegrations.verificationPage.byScanning&Clicking'
                                            )}
                                        </p>
                                        <p>
                                            <Trans
                                                defaults={
                                                    'appIntegrations.verificationPage.informationShared'
                                                }
                                                components={{
                                                    bold: <strong />,
                                                }}
                                                values={{
                                                    orgName:
                                                        orgName ||
                                                        t(
                                                            'appIntegrations.verificationPage.yourOrgName'
                                                        ),
                                                }}
                                            />
                                        </p>
                                    </div>
                                    <div
                                        className={cx(
                                            styles.previewImage__body__right__content__qr__shared__scope
                                        )}
                                    >
                                        {selectedScopes?.map((scope) => {
                                            const fullScope = scopes?.find(
                                                (el) => el.scope === scope
                                            )
                                            return (
                                                <div
                                                    className={cx(
                                                        styles.previewImage__body__right__content__qr__shared__scope__item
                                                    )}
                                                >
                                                    <div>
                                                        <CheckIcon
                                                            size={10.46}
                                                            color={
                                                                gaColors.primary700
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <p>
                                                            {fullScope?.description_short
                                                                ? fullScope
                                                                      ?.description_short[
                                                                      initialLang
                                                                  ] ||
                                                                  fullScope
                                                                      ?.description_short[
                                                                      'en'
                                                                  ]
                                                                : fullScope?.scope}
                                                        </p>
                                                        {fullScope?.description_short &&
                                                        fullScope?.description_long ? (
                                                            <p
                                                                className={cx(
                                                                    'neutral800'
                                                                )}
                                                            >
                                                                {fullScope?.description_long
                                                                    ? fullScope
                                                                          ?.description_long[
                                                                          initialLang
                                                                      ] ||
                                                                      fullScope
                                                                          ?.description_long[
                                                                          'en'
                                                                      ]
                                                                    : null}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div
                                        className={cx(
                                            styles.previewImage__body__right__content__qr__shared__policiesTerms
                                        )}
                                    >
                                        <p className={cx('neutral700')}>
                                            <Trans
                                                defaults={
                                                    'appIntegrations.verificationPage.policiesTerms'
                                                }
                                                components={{
                                                    bold: <strong />,
                                                }}
                                                values={{
                                                    orgName:
                                                        orgName ||
                                                        t(
                                                            'appIntegrations.verificationPage.yourOrgName'
                                                        ),
                                                }}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <StoresPanel />
                        </div>
                        <div
                            className={cx(
                                styles.previewImage__body__right__footer
                            )}
                        >
                            <div
                                className={cx(
                                    styles.previewImage__body__right__footer__tutorial
                                )}
                            >
                                <p className={cx('primary700')}>
                                    {t(
                                        'appIntegrations.verificationPage.guidedTutorial'
                                    )}
                                </p>
                            </div>
                            <div
                                className={cx(
                                    styles.previewImage__body__right__footer__terms
                                )}
                            >
                                <p className={cx('neutral700')}>
                                    {t('appIntegrations.verificationPage.help')}
                                </p>
                                <p className={cx('neutral700')}>
                                    {t('welcome.privacyPolicy')}
                                </p>
                                <p className={cx('neutral700')}>
                                    {t('welcome.termsOfUse')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default DesktopPreview
