import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    CellContentTypes,
    TextAlignTypes,
} from '../../../../../assets/globalConstants'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import {
    IConnectionsDataAgreementGroup,
    ICredentialAuthorityModel,
    PaginationProps,
} from '../../../../../interfaces/interfaces'
import styles from './connectionsTable.module.scss'
import {
    ConnectionDetailLink,
    DataAgreementsCards,
} from './content/ConnectionsCells'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import ChevronDownIcon from '../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import { GaPagination } from '../../../../../Components/Pagination/Pagination'
import ConnectionDetail from '../connectionDetail/ConnectionDetail'
import { initialLang } from '../../../../../i18n'
import CopyCell from '../../../../../Components/organisms/table/cells/copyCell/CopyCell'
import { centerEllipsisTrim } from '../../../../../utils/formatDataUtil'

export type ConnectionsTableProps = {
    tableData: IConnectionsDataAgreementGroup[]
    headerData: {
        key: string
        text: string
        textAlign?: TextAlignTypes
        type?: CellContentTypes
        tag?: boolean
        short?: boolean
        tagText?: string
        action?: (x?) => void
    }[]
    noResults?: boolean
    paginationData?: PaginationProps
    className?: string
    authorityIssuers?: ICredentialAuthorityModel[]
    selectedItem?: IConnectionsDataAgreementGroup
    selectItem: (x?) => void
}

const ConnectionsTable: React.FC<ConnectionsTableProps> = (props) => {
    const {
        tableData,
        headerData,
        paginationData,
        noResults,
        authorityIssuers,
        selectedItem,
        selectItem,
    } = props
    const { t } = useTranslation()

    useEffect(() => {}, [tableData, selectedItem])

    // Util when pagination it's implemented
    const showPagination =
        paginationData?.totalItems &&
        paginationData.pageSize &&
        paginationData?.totalItems > paginationData.pageSize &&
        !noResults

    const getDataReceiverName = (item) => item?.dataReceiver?.name

    const selectedConnectionAuthName = getDataReceiverName(selectedItem)

    return (
        <div className={styles.container}>
            <LightPanel className={styles.tableContainer}>
                <table>
                    <thead>
                        <tr>
                            {headerData?.map((header, idx) => (
                                <th
                                    className={cx('testAlignLeft')}
                                    key={header.key + idx + Math.random()}
                                >
                                    <div>
                                        {header.text ? (
                                            <span
                                                className={cx(
                                                    'bodyBoldXS neutral700'
                                                )}
                                            >
                                                {t(header.text)}
                                            </span>
                                        ) : null}
                                        {idx !== headerData?.length - 1 ? (
                                            <ChevronDownIcon
                                                color="#707074"
                                                size={16}
                                            />
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {!!tableData
                            ? tableData?.map((item, index) => (
                                  <tr data-id={index} key={'tr__body' + index}>
                                      {!!item ? (
                                          <>
                                              <CopyCell
                                                  item={item}
                                                  mainData={
                                                      item?.dataReceiver?.name
                                                          ? item?.dataReceiver
                                                                ?.name[
                                                                initialLang
                                                            ]
                                                          : undefined
                                                  }
                                                  textToCopy={
                                                      item?.dataReceiver?.id
                                                  }
                                                  textToCopyDisplayValue={centerEllipsisTrim(
                                                      item?.dataReceiver?.id,
                                                      23
                                                  )}
                                              />

                                              <DataAgreementsCards
                                                  item={item}
                                                  index={index}
                                              />
                                              <ConnectionDetailLink
                                                  item={item}
                                                  index={index}
                                                  selectItem={selectItem}
                                              />
                                          </>
                                      ) : null}
                                  </tr>
                              ))
                            : null}
                    </tbody>
                </table>
            </LightPanel>

            {noResults ? (
                <Description
                    className={cx('margin-top')}
                    text={t('dids.noResults')}
                />
            ) : null}

            {/* TODO: Change by "Show more" option when Design is ready. Now util for calls paginated only */}
            {paginationData && showPagination ? (
                <>
                    <div className={styles.paginationContainer}>
                        <GaPagination {...paginationData} />
                    </div>
                </>
            ) : null}
            {selectedItem ? (
                <ConnectionDetail
                    dataReceiverName={
                        selectedConnectionAuthName
                            ? selectedConnectionAuthName[initialLang]
                            : ''
                    }
                    show={!!selectedItem}
                    selectedItem={selectedItem}
                    hideModal={() => selectItem(undefined)}
                />
            ) : null}
        </div>
    )
}

export default ConnectionsTable
