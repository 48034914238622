import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import FormPanel from '../../../../Components/FormPanel/FormPanel'
import PanelScafoldingPublic from '../../PanelScafolding/public/PanelScafoldingPublic'
import { sendRecoveryButton } from './resetPasswordConstants'
import GaTextInputPassword from '../../../../Components/atoms/formFields/textInputPassword/GaTextInputPassword'

type IResetPasswordProps = {}

const ResetPassword: React.FC<IResetPasswordProps> = () => {
    const [{ password, confirmedPassword }, setNewPassword] = useState({
        password: '',
        confirmedPassword: '',
    })
    const history = useHistory()
    const { t } = useTranslation()

    const goToLogin = () => {
        history.push('/login')
    }

    return (
        <PanelScafoldingPublic>
            <FormPanel
                title={'resetPassword.passwordReset'}
                primaryButton={sendRecoveryButton}
                primaryFunction={goToLogin}
            >
                <GaTextInputPassword
                    disabled={false}
                    helperText=""
                    id="token-input-password"
                    invalid={false}
                    labelText={t('login.password')}
                    onChange={(e) =>
                        setNewPassword({
                            password: e.target.value,
                            confirmedPassword,
                        })
                    }
                    placeholder={t('resetPassword.newPassword')}
                    type="password"
                    required
                    value={password}
                />
                <GaTextInputPassword
                    disabled={false}
                    helperText=""
                    id="token-input-password"
                    invalid={false}
                    labelText={t('resetPassword.confirmPassword')}
                    onChange={(e) =>
                        setNewPassword({
                            password,
                            confirmedPassword: e.target.value,
                        })
                    }
                    placeholder={t('resetPassword.newPassword')}
                    type="password"
                    required
                    value={confirmedPassword}
                />
            </FormPanel>
        </PanelScafoldingPublic>
    )
}

ResetPassword.defaultProps = {}

export default ResetPassword
