import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { didsSelectors } from '../../..'
import {
    CellContentTypes,
    didTypeOptions,
    gaImages,
    TextAlignTypes,
} from '../../../../../../assets/globalConstants'
import GaSelectTag from '../../../../../../Components/atoms/formFields/selectTag/GaSelectTag'
import GaTextInput from '../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import GaTag from '../../../../../../Components/atoms/tag/Tag'
import Description from '../../../../../../Components/atoms/texts/Description/Description'
import styles from './dynamicTable.module.scss'
import {
    ICreateDidModel,
    PaginationProps,
} from '../../../../../../interfaces/interfaces'
import { shortValue } from '../../../../../../utils/formatDataUtil'
import { userHasScopes } from '../../../../../../utils/permissionsUtil'
import { catalogSelectors } from '../../../../../common/catalog'
import { sessionSelectors } from '../../../../../common/session'
import { clearDid, selectDid, updateDid } from '../../../didActions'
import { getSelectedDidBaseId } from '../../../didSelectors'
import { didsScopes } from '../didList.constants'
import didTableStyle from './didsTable.module.scss'
import LightPanel from '../../../../../../Components/atoms/panels/LightPanel/LightPanel'
import ChevronDownIcon from '../../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import { GaPagination } from '../../../../../../Components/Pagination/Pagination'
import CopyCell from '../../../../../../Components/organisms/table/cells/copyCell/CopyCell'
import DropdownActions from '../../../../../../Components/organisms/table/cells/dropdownActions/dropdownActions'

export type DidsTableProps = {
    tableData: any[]
    headerData: {
        key: string
        text: string
        textAlign?: TextAlignTypes
        type?: CellContentTypes
        tag?: boolean
        short?: boolean
        tagText?: string
        action?: (x?) => void
    }[]
    noResults?: boolean
    showingModal?: boolean
    paginationData?: PaginationProps
    mobileItem?: ((x) => any) | ((x) => Element)
    deleteItem?: (x?) => void
    editItem?: (x?) => void
    removeType: (x, y?) => void
    addType: (x, y?) => void
}

const DidsTable: React.FC<DidsTableProps> = (props) => {
    const {
        tableData,
        headerData,
        paginationData,
        noResults,
        editItem,
        mobileItem,
        deleteItem,
        removeType,
        addType,
    } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const didConfigs = useSelector(didsSelectors.getdidConfigs)
    const catalog = useSelector(catalogSelectors.getLoadingStatus)

    useEffect(() => {
        dispatch(clearDid())
    }, [])
    useEffect(() => {}, [tableData, catalog])
    useEffect(() => {}, [didConfigs])
    const [readAliasOnly, setReadOnly] = useState(true)

    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canEdit = !!(
        availableScopes && userHasScopes(didsScopes?.edit, availableScopes)
    )
    const canDelete = !!(
        availableScopes && userHasScopes(didsScopes?.delete, availableScopes)
    )
    const tagSelectorIsLoading =
        !didConfigs || !tableData || catalog?.status !== 'finished'

    const showPagination =
        !!paginationData &&
        paginationData?.totalItems &&
        paginationData.pageSize &&
        paginationData?.totalItems > paginationData.pageSize &&
        !noResults

    const tableActions = [
        {
            text: 'public.edit',
            hide: !canEdit,
            action: editItem,
        },
        {
            text: 'public.delete',
            hide: !canDelete,
            color: 'alert300',
            action: deleteItem,
        },
    ]

    return (
        <>
            <LightPanel className={styles.panelContainer}>
                <div className={cx('width100', styles.table)}>
                    <table className="">
                        <thead>
                            <tr>
                                {headerData.map((header, idx) =>
                                    !(
                                        header.key &&
                                        header.key === 'actions' &&
                                        !(canEdit || canDelete)
                                    ) ? (
                                        <th
                                            className={cx(
                                                'testAlignLeft bodyBoldXS neutral700'
                                            )}
                                            key={
                                                header.key + idx + Math.random()
                                            }
                                        >
                                            <div className={cx('width100')}>
                                                {header.text ? (
                                                    <span
                                                        className={cx(
                                                            'testAlignLeft bodyBoldXS neutral700 margin-left-auto'
                                                        )}
                                                    >
                                                        {t(header.text)}
                                                    </span>
                                                ) : null}
                                                {idx !==
                                                headerData?.length - 1 ? (
                                                    <ChevronDownIcon
                                                        color="#707074"
                                                        size={16}
                                                    />
                                                ) : null}
                                            </div>
                                        </th>
                                    ) : null
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {!!tableData
                                ? tableData?.map((item, idx) => (
                                      <>
                                          <tr
                                              data-id={idx}
                                              key={idx + Math.random()}
                                              className={
                                                  !!mobileItem
                                                      ? styles.table__longDisplay
                                                      : ''
                                              }
                                          >
                                              {!!item ? (
                                                  <>
                                                      <CopyCell
                                                          item={item}
                                                          mainData={item?.alias}
                                                          textToCopy={
                                                              item?.DidId
                                                          }
                                                          textToCopyDisplayValue={shortValue(
                                                              item?.DidId,
                                                              15,
                                                              5
                                                          )}
                                                      />
                                                      <td>
                                                          <Moment
                                                              className={cx(
                                                                  'buttonMD neutral1000'
                                                              )}
                                                              format="DD/MM/YYYY"
                                                          >
                                                              {item?.createdAt}
                                                          </Moment>
                                                      </td>
                                                      <td>
                                                          <div
                                                              className={
                                                                  didTableStyle.selectTag
                                                              }
                                                          >
                                                              {!tagSelectorIsLoading ? (
                                                                  <>
                                                                      <DidTypeTags
                                                                          item={
                                                                              item
                                                                          }
                                                                          removeType={
                                                                              removeType
                                                                          }
                                                                      />
                                                                      {!item
                                                                          ?.type
                                                                          ?.length &&
                                                                      didConfigs &&
                                                                      tableData &&
                                                                      catalog?.status ===
                                                                          'finished' ? (
                                                                          <Description
                                                                              className={cx(
                                                                                  'margin-top-micro',
                                                                                  'margin-right-min'
                                                                              )}
                                                                              text={t(
                                                                                  'dids.noTypeSelected'
                                                                              )}
                                                                          />
                                                                      ) : null}
                                                                  </>
                                                              ) : null}
                                                              {item?.type
                                                                  ?.length !==
                                                              2 ? (
                                                                  <GaSelectTag
                                                                      iconTagFunctionality={
                                                                          addType
                                                                      }
                                                                      isLoading={
                                                                          tagSelectorIsLoading
                                                                      }
                                                                      canEdit={
                                                                          canEdit
                                                                      }
                                                                      id={idx.toString()}
                                                                      item={
                                                                          item
                                                                      }
                                                                      value={
                                                                          item
                                                                              ?.type
                                                                              ?.text
                                                                      }
                                                                      onChange={(
                                                                          x
                                                                      ) => {}}
                                                                      options={
                                                                          item
                                                                              .type
                                                                              ?.length
                                                                              ? didTypeOptions.filter(
                                                                                    (
                                                                                        option
                                                                                    ) =>
                                                                                        option !==
                                                                                            item
                                                                                                .type[0] ||
                                                                                        item
                                                                                            .type[1]
                                                                                )
                                                                              : didTypeOptions
                                                                      }
                                                                      optionTextProperty={
                                                                          'text'
                                                                      }
                                                                      optionValueProperty={
                                                                          'value'
                                                                      }
                                                                  />
                                                              ) : null}
                                                          </div>
                                                      </td>
                                                      {canEdit || canDelete ? (
                                                          <td>
                                                              <div
                                                                  className={
                                                                      didTableStyle.actions
                                                                  }
                                                              >
                                                                  <DropdownActions
                                                                      item={
                                                                          item
                                                                      }
                                                                      className={
                                                                          styles.actions
                                                                      }
                                                                      options={
                                                                          tableActions
                                                                      }
                                                                      isLastItem={
                                                                          idx ===
                                                                          tableData?.length -
                                                                              1
                                                                      }
                                                                  />
                                                              </div>
                                                          </td>
                                                      ) : null}
                                                  </>
                                              ) : null}
                                          </tr>
                                          {!!mobileItem ? (
                                              <tr
                                                  className={
                                                      styles.table__mobileDisplay
                                                  }
                                                  key={
                                                      'mobile' + idx.toString()
                                                  }
                                              >
                                                  {mobileItem(item)}
                                              </tr>
                                          ) : null}
                                      </>
                                  ))
                                : null}
                        </tbody>
                    </table>
                </div>
            </LightPanel>

            {noResults ? (
                <Description
                    className={cx('margin-top')}
                    text={t('dids.noResults')}
                />
            ) : null}

            {paginationData && showPagination ? (
                <div>
                    <GaPagination {...paginationData} />
                </div>
            ) : null}
        </>
    )
}

export default DidsTable

export type DidTypeTagsProps = {
    removeType: (x, y?) => void
    item
}

export const DidTypeTags: React.FC<DidTypeTagsProps> = (props) => {
    const { item, removeType } = props
    useEffect(() => {}, [item, removeType])

    item?.type?.map((el) => {})
    return (
        <>
            {item?.type?.map((el, index) => {
                return (
                    <GaTag
                        key={'gaTag__' + el?.text + index}
                        className={didTableStyle.typeTag}
                        icon={gaImages.whiteClose}
                        color={el.color}
                        text={el.text}
                        item={item}
                        iconFunctionality={removeType}
                    />
                )
            })}
        </>
    )
}

export type AliasInputProps = {
    item
    readAliasOnly
    showingModal?: boolean
    setReadOnly: (x?) => void
}

export const AliasInput: React.FC<AliasInputProps> = (props) => {
    const { item, showingModal, setReadOnly, readAliasOnly } = props

    const dispatch = useDispatch()
    const selectedDidBaseId = useSelector(getSelectedDidBaseId)
    const [alias, setFormState] = useState(item?.alias)

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) =>
        e?.key === 'Enter' ? saveDid({ ...item, alias: alias }) : null

    const saveDid = (data: any) => {
        const simplifiedDid = data as ICreateDidModel
        dispatch(updateDid(simplifiedDid, selectedDidBaseId))
        dispatch(clearDid())
    }

    const selectDidToEdit = (item) => {
        // console.log(
        //     'selectedDidBaseId',
        //     selectedDidBaseId,
        //     'item?.DidId',
        //     item?.DidId,
        //     selectedDidBaseId === item?.DidId
        // )
        dispatch(selectDid(item))
    }

    useEffect(() => {
        setFormState(item?.alias)
    }, [item])
    useEffect(() => {}, [selectedDidBaseId])

    return (
        <>
            <div
                className={didTableStyle.alias__content}
                onClick={() => {
                    selectDidToEdit(item)
                }}
            >
                <GaTextInput
                    id={'alias'}
                    className={`${didTableStyle.alias__content__input} ${
                        selectedDidBaseId === item?.DidId && !showingModal
                            ? false
                            : true
                            ? didTableStyle.noBorder
                            : ''
                    }`}
                    required={false}
                    onChange={(e) => {
                        setFormState(e.target.value)
                    }}
                    value={alias}
                    type={'text'}
                    onKeyDown={onKeyDown}
                    readOnly={
                        readAliasOnly &&
                        !showingModal &&
                        selectedDidBaseId === item?.DidId
                            ? false
                            : true
                    }
                />
            </div>
            {selectedDidBaseId === item?.DidId && !showingModal ? (
                <div className={didTableStyle.alias__content__buttons}>
                    <div
                        className={`${styles.pendingAction__content__button} ${styles.issue}`}
                        onClick={() => {
                            saveDid({ ...item, alias: alias })
                            setReadOnly(true)
                        }}
                    >
                        <GaTag color={'green'} text={'✓'} />
                    </div>
                    <div
                        className={`${styles.pendingAction__content__button} ${styles.reject}`}
                        onClick={() => {
                            setFormState(item?.alias)
                            setReadOnly(true)
                            dispatch(clearDid())
                        }}
                    >
                        <GaTag color={'red'} text={'✕'} />
                    </div>
                </div>
            ) : null}
        </>
    )
}
