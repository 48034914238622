import { Base64 } from 'js-base64'

let currentHost = window?.location?.host
const defaultHost = process.env.REACT_APP_DEFAULT_HOST || ''
const incmHost = process.env.REACT_APP_INCM_HOST || ''

export const gatacaBrand = 'gataca'
export const incmBrand = 'incm'

const brands = {
    [defaultHost]: getBrandId(gatacaBrand),
    [incmHost]: getBrandId(incmBrand),
}

export function getBrandId(brandName: string) {
    return Base64.encodeURI(brandName)
}

export function getBrand() {
    return brands[currentHost] || brands[defaultHost]
}

export function isDefaultBrandActive() {
    return getBrand() === getBrandId(gatacaBrand)
}
