import * as React from 'react'
import cx from 'classnames'
import { t } from 'i18next'

export type IListDataCellProps = {
    data: any
}

const ListDataCell: React.FC<IListDataCellProps> = (props) => {
    const { data } = props

    const isLastItem = (itemsArray: any[], item: any) => {
        const arrLength = itemsArray?.length
        const itemPosition = itemsArray?.indexOf(item)
        return itemPosition === arrLength - 1
    }

    return (
        <>
            <td className={`${cx('bodyBoldSM')}`}>
                {data?.length ? (
                    data?.map((el: string, index: number) => (
                        <p
                            key={'dataC__' + index}
                            className={`${cx('bodyBoldSM')}`}
                        >
                            {t(el)}
                            {!isLastItem(data, el) ? ',' : ''}
                        </p>
                    ))
                ) : (
                    <p className={`${cx('bodyBoldSM')}`}>
                        {t('subscription.none')}
                    </p>
                )}
            </td>
        </>
    )
}

export default ListDataCell
