import cx from 'classnames'
import React from 'react'
import styles from './content.module.scss'
import { t } from 'i18next'
import { translateCatalog } from '../../../../utils/formatCredUtil'
import {
    CredentialGroup,
    IDeepContent,
    VerifiableCredential,
} from '../../../../data/requestCredentials.interfaces'
import SimpleContent from './components/SimpleContent'
import ComplexContent from './components/complexContent/ComplexContent'

type IContentProps = {
    group: CredentialGroup
    vcs: any[]
    groupName: Map<string, string> | string
    contentOpen?: boolean
    types: any
    isMulti: boolean
    isDemoContent?: boolean
    setContentToShow: (x?: IDeepContent) => void
}

const Content: React.FC<IContentProps> = (props) => {
    const {
        vcs,
        groupName,
        contentOpen,
        group,
        types,
        isMulti,
        isDemoContent,
        setContentToShow,
    } = props

    if (!!vcs?.length) {
        const category =
            typeof groupName != 'string'
                ? translateCatalog(groupName)
                : groupName

        return (
            <div
                className={cx(
                    contentOpen
                        ? styles.detailContent
                        : styles.detailContentHidden,
                    (isMulti || !vcs?.length) &&
                        styles.detailContentWithoutBorder
                )}
            >
                {!isMulti ? (
                    vcs.map((vc: VerifiableCredential, index: number) => {
                        return (
                            <SimpleContent
                                vc={vc}
                                key={'simpleContent__' + index}
                                isLast={vcs?.length - 1 === index}
                                isFirst={index === 0}
                                group={group}
                                types={types}
                            />
                        )
                    })
                ) : (
                    <>
                        <ComplexContent
                            vc={vcs[0]}
                            group={group}
                            level={-1}
                            isDemoContent={isDemoContent}
                            category={category}
                            types={undefined}
                            passingOnPress={(content, level, title?) =>
                                setContentToShow({
                                    contents: content,
                                    title: title,
                                })
                            }
                        />
                    </>
                )}
            </div>
        )
    } else {
        return (
            <p
                className={cx(
                    'bodyRegularSM neutral800',
                    styles.noCredentialsLoaded,
                    !contentOpen && styles.detailContentHidden
                )}
            >
                {t(
                    !isDemoContent
                        ? 'ew.requestCredentials.noCredsLoaded'
                        : 'ew.requestCredentials.noCredsAdded'
                )}
            </p>
        )
    }
}

export default Content
