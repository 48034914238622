import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as OptionsIcon } from '../../assets/images/cardMenu.svg'
import { ActionModel, ImageModel, LinkModel } from '../../interfaces/interfaces'
import styles from './dropdownCard.module.scss'
import MoreVerticalIcon from '../../assets/iconsLibrary/simple/moreVertical/moreVerticalIcon'
import cx from 'classnames'

type IDropdownCardProps = {
    icon?: ImageModel
    iconClassName?: string
    className?: string
    menuOptions?: LinkModel[]
    left?: boolean
    noLayer?: boolean
    selectOptions?: ActionModel[]
    item: any
}

const DropdownCard: React.FC<IDropdownCardProps> = (props) => {
    const {
        icon,
        iconClassName,
        left,
        noLayer,

        item,
        className,
        menuOptions,
        selectOptions,
    } = props
    const [active, setIsActive] = useState(false)
    const { t } = useTranslation()

    return (
        <div className={styles.dropdownCardContainer}>
            <button
                className={styles.dropdownCardButton + ' ' + className}
                onClick={() => {
                    setIsActive(!active)
                }}
            >
                <MoreVerticalIcon color="#1E1E20" size={24} />
            </button>
            {active && (
                <div
                    className={
                        (active ? styles.active : styles.inActive) +
                        ' ' +
                        styles.menuOptionsCard +
                        ' ' +
                        (left ? styles.alignLeft : '')
                    }
                    id="dropdownOptions"
                >
                    <ul className={styles.menuOptionsCard__list}>
                        {selectOptions?.map((e, index) => {
                            return (
                                <li
                                    key={index}
                                    className={`${
                                        styles.menuOptionsCard__item
                                    } ${
                                        e.color && e.color === 'red'
                                            ? styles.menuOptionsCard__red
                                            : ''
                                    }`}
                                    onClick={() => {
                                        e?.action(item)
                                    }}
                                >
                                    <p className={cx('buttonMD neutral1000')}>
                                        {t(e.label)}
                                    </p>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
            {!noLayer ? (
                <div
                    className={
                        active
                            ? styles.backgroundLayerActive
                            : styles.backgroundLayerInactive
                    }
                    onClick={() => {
                        setIsActive(!active)
                    }}
                ></div>
            ) : null}
        </div>
    )
}

export default DropdownCard
