import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
    ActionStatusTypes,
    brandLabels,
    countriesAndStates,
    domains,
} from '../../../../../assets/globalConstants'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import { initialLang } from '../../../../../i18n'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { sessionSelectors } from '../../../session'
import { orgServicesEndpoints } from '../../../Welcome/welcomeConstants'
import {
    deleteOrgAction,
    getOrgAction,
    updateOrgAction,
} from '../../organizationActions'
import { organizationScopes } from './organization.constants'
import styles from './organization.module.scss'
import { ButtonSizes, onPremise } from '../../../../../data/globalVar'
import ErrorFilledPanel from '../../../../../Components/molecules/notification/panels/error/errorFilledPanel/ErrorFilledPanel'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import WarningModal from '../../../../../Components/molecules/table/dynamicTable/warninModal/WarningModal'
import { orgSelectors } from '../..'
import { showScreenLoaderAction } from '../../../Loader/loaderActions'
import {
    defaultCountry,
    defaultCountryByBrand,
    defaultCountryIndexByBrand,
} from '../../../../../utils/formUtils'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import CloseIcon from '../../../../../assets/iconsLibrary/simple/close/closeIcon'

type IOrganizationProps = {}

const Organization: React.FC<IOrganizationProps> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const tokenOrg = useSelector(sessionSelectors.getUserOrganization)
    const org = useSelector(orgSelectors.getOrg)
    const orgIsLoading = useSelector(orgSelectors.getOrgIsLoading)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canReadOrg = !!(
        availableScopes &&
        userHasScopes(organizationScopes.read, availableScopes)
    )
    const uRole = useSelector(sessionSelectors.getUserRole_s)
    const userIsOrgOwner = uRole === 'OrganizationOwner'
    const canUpdateOrg = !!(availableScopes &&
        userHasScopes(organizationScopes.edit, availableScopes),
    true)

    const canDeleteOrg = !!(
        availableScopes &&
        userHasScopes(organizationScopes?.delete, availableScopes) &&
        !onPremise
    )

    const updateOrgStatus = useSelector(orgSelectors.getOrgByIdStatus)

    useEffect(() => {
        if (updateOrgStatus === ActionStatusTypes?.success && formActive) {
            setFormActive(false)
        } else if (
            updateOrgStatus === ActionStatusTypes?.failed &&
            formActive
        ) {
            setFormState(emptyFormData), setLegalAddressState(emptyFormAddress)
        }
    }, [org?.id])

    const orgId = useSelector(sessionSelectors.getUserOrganization)
    const orgInfo = org?.orgInfo
    const orgBilling = org?.billingInfo
    const orgAddress = orgBilling?.legalAddress
    const currentCountry = orgAddress
        ? orgAddress?.country
        : defaultCountryByBrand
    const orgCountry = countriesAndStates.find((el) => {
        return (
            currentCountry === el.name_en ||
            currentCountry === el.name_es ||
            currentCountry === el.name_pt
        )
    })
    const orgCountryTranslated =
        orgCountry && initialLang && orgCountry['name_' + initialLang]
            ? orgCountry['name_' + initialLang]
            : orgCountry?.name_es || defaultCountry(defaultCountryIndexByBrand)

    // STATES

    const [formActive, setFormActive] = useState(false)
    const [formDataModified, setFormDataModified] = useState(false)
    const defaultStates = defaultCountryByBrand?.states
    const [states, setStates] = useState(orgCountry?.states || defaultStates)
    let emptyFormData = {
        name: orgInfo?.name || '',
        legalName: orgBilling?.LegalName || '',
        domainName: orgInfo?.domainName || '',
        VATRegistration: org?.billingInfo?.vatRegistration || '',
        discoveryURL: orgInfo?.discoveryURL || '',
        photo: undefined,
    }
    const emptyFormAddress = {
        street: orgAddress?.street || '',
        suite: orgAddress?.suite || '',
        number: orgAddress?.number || '',
        locality: orgAddress?.locality || '',
        country: orgCountryTranslated || '',
        region: orgAddress?.region || '',
        postalCode: orgAddress?.postalCode || '',
    }
    const [formData, setFormState] = useState(emptyFormData)
    const [legalAddres, setLegalAddressState] = useState(emptyFormAddress)
    const [showDeleteWarning, setShowDeleteModal] = React.useState(false)

    const {
        name,
        legalName,
        domainName,
        VATRegistration,
        discoveryURL,
        // photo,
    } = formData
    const { street, suite, number, locality, region, country, postalCode } =
        legalAddres

    const validForm = !!(
        name.trim()?.length &&
        legalName.trim()?.length &&
        domainName.trim()?.length &&
        discoveryURL.trim()?.length &&
        street.trim()?.length &&
        number.trim()?.length &&
        locality.trim()?.length &&
        region &&
        region?.trim()?.length &&
        country &&
        country?.trim()?.length &&
        postalCode.trim()?.length &&
        VATRegistration.trim()?.length
    )

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        const currentValue = emptyFormData[name as keyof typeof emptyFormData]

        if (currentValue !== value) {
            setFormDataModified(true)
        }

        setFormState({
            ...formData,
            [name]: value,
        })
    }

    const handleAddressInputsChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        const currentValue =
            emptyFormAddress[name as keyof typeof emptyFormAddress]

        if (currentValue !== value) {
            setFormDataModified(true)
        }

        setLegalAddressState({
            ...legalAddres,
            [name]: value,
        })
    }
    const getStates = (name: string) => {
        setStates([])
        const selectedCountry = countriesAndStates.find((el) => {
            return (
                name === el.name_en ||
                name === el.name_es ||
                name === el.name_pt
            )
        })

        setStates(selectedCountry?.states || [])
        setLegalAddressState({
            ...legalAddres,
            country: name,
            region: selectedCountry?.states[0] || '',
        })
    }

    const deleteOrg = () => {
        tokenOrg &&
            !onPremise &&
            (dispatch(showScreenLoaderAction()),
            dispatch(deleteOrgAction(tokenOrg)))
    }

    const deleteModalButtons = {
        primary: {
            label: 'public.delete',
            function: () => deleteOrg(),
        },
        secondary: {
            label: 'public.cancel',
            function: () => setShowDeleteModal(false),
        },
    }
    // Uncomment when upload image is ready in BE
    // const [preview, setPreview] = useState('')

    // useEffect(() => {
    //     if (!photo) {
    //         setPreview('')
    //         return
    //     }

    //     const objectUrl = URL.createObjectURL(photo)
    //     setPreview(objectUrl)

    //     // free memory when ever this component is unmounted
    //     return () => URL.revokeObjectURL(objectUrl)
    // }, [photo])

    // const onSelectFile = (e) => {
    //     if (!e.target.files || e.target.files.length === 0) {
    //         setFormState({ ...formData, photo: undefined })
    //         return
    //     }

    //     // Use the first image instead of multiple
    //     setFormState({ ...formData, photo: e.target.files[0] })
    // }

    useEffect(() => {
        if (orgId && canReadOrg && !orgInfo?.name && !orgIsLoading) {
            // Just in cloud for now
            !onPremise && dispatch(getOrgAction())
        }
    }, [])

    useEffect(() => {
        if (country && orgCountryTranslated && orgCountry) {
            orgCountry && setStates(orgCountry?.states)
            setLegalAddressState(emptyFormAddress)
        }
    }, [orgAddress])

    function submitData() {
        const formattedOrg = buildDataObject()
        // Just in cloud for now
        !onPremise && dispatch(updateOrgAction(formattedOrg))
        setFormDataModified(false)
        setFormActive(false)
    }

    useEffect(() => {
        setFormState({
            ...emptyFormData,
            domainName: orgInfo?.domainName || domains[0]?.value || '',
        })
    }, [
        orgBilling?.vatRegistration?.length,
        orgInfo?.domainName?.length,
        domains,
    ])

    useEffect(() => {
        if (JSON.stringify(formData) === JSON.stringify(emptyFormData)) {
            setFormDataModified(false)
        }
    }, [formData])

    useEffect(() => {
        if (JSON.stringify(legalAddres) === JSON.stringify(emptyFormAddress)) {
            setFormDataModified(false)
        }
    }, [legalAddres])

    const buildDataObject = () => {
        const data = {
            id: org?.id || '',
            providerId: 'gataca',
            orgInfo: {
                name: name,
                domainName: domainName,
                discoveryURL: discoveryURL,
                services: orgServicesEndpoints,
            },
            billingInfo: {
                legalAddress: {
                    street,
                    suite,
                    number,
                    locality,
                    region,
                    country,
                    postalCode,
                },
                LegalName: legalName,
                vatRegistration: VATRegistration,
            },
        }
        return data
    }

    return (
        <React.Fragment>
            <ScreenHeader
                title={'general.title'}
                subText={t('general.description')}
                hideBorderBottom={true}
            />
            {canReadOrg ? (
                <>
                    <LightPanel className={styles.formLightPanel}>
                        <div className={styles.form}>
                            <div
                                className={`${styles.headerContainer} ${cx(
                                    'marginBottom24'
                                )}`}
                            >
                                <div className={cx('heading5 neutral1000')}>
                                    {t('welcome.formTitle')}
                                </div>
                                <div>
                                    {canUpdateOrg ? (
                                        <>
                                            {!formActive ? (
                                                <LightButton
                                                    text={t('public.edit')}
                                                    functionality={() =>
                                                        setFormActive(true)
                                                    }
                                                    disabled={false}
                                                    size={ButtonSizes.SM}
                                                />
                                            ) : (
                                                <div
                                                    onClick={() => (
                                                        setFormState(
                                                            emptyFormData
                                                        ),
                                                        setLegalAddressState(
                                                            emptyFormAddress
                                                        ),
                                                        setFormDataModified(
                                                            false
                                                        ),
                                                        setFormActive(false)
                                                    )}
                                                >
                                                    <CloseIcon
                                                        size={24}
                                                        className={
                                                            styles.closeFormIcon
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.form__textInputs}>
                                <GaTextInput
                                    className={cx('margin-bottom')}
                                    name="name"
                                    invalid={false}
                                    labelText={t('welcome.companyName')}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={t('welcome.companyName')}
                                    type="text"
                                    value={name}
                                    required
                                    readOnly={!formActive}
                                />
                                <GaTextInput
                                    className={cx('margin-bottom')}
                                    name="legalName"
                                    invalid={false}
                                    labelText={t('welcome.legalName')}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={t('welcome.companyName')}
                                    type="text"
                                    value={legalName}
                                    required
                                    readOnly={!formActive}
                                />
                                <div
                                    className={
                                        styles.form__textInputs__doubleColumn
                                    }
                                >
                                    <GaTextInput
                                        className={cx('margin-bottom')}
                                        name="discoveryURL"
                                        invalid={false}
                                        labelText={t('login.website')}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder={t('login.website')}
                                        type="text"
                                        value={discoveryURL}
                                        required
                                        readOnly={!formActive}
                                    />
                                    <GaSelect
                                        idSelect={'domainNameContainer'}
                                        value={domainName}
                                        name="domainName"
                                        isLoading={!domains?.length}
                                        labelText={t('welcome.domainName')}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder={t('welcome.domainName')}
                                        optionTextProperty={'text'}
                                        optionValueProperty={'value'}
                                        options={domains}
                                        readOnly={!formActive}
                                    />
                                </div>

                                <GaTextInput
                                    name="street"
                                    invalid={false}
                                    labelText={t('welcome.street')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    placeholder={t('welcome.street')}
                                    type="text"
                                    value={street}
                                    required
                                    readOnly={!formActive}
                                />
                                <div
                                    className={
                                        styles.form__textInputs__doubleColumn
                                    }
                                >
                                    <GaTextInput
                                        name="number"
                                        invalid={false}
                                        labelText={t('welcome.number')}
                                        onChange={(e) =>
                                            handleAddressInputsChange(e)
                                        }
                                        placeholder={t('welcome.number')}
                                        type="text"
                                        value={number}
                                        required
                                        readOnly={!formActive}
                                    />
                                    <GaTextInput
                                        name="suite"
                                        invalid={false}
                                        labelText={t('welcome.suite')}
                                        onChange={(e) =>
                                            handleAddressInputsChange(e)
                                        }
                                        placeholder={t('welcome.suite')}
                                        type="text"
                                        value={suite}
                                        readOnly={!formActive}
                                    />
                                </div>
                                <div
                                    className={
                                        styles.form__textInputs__doubleColumn
                                    }
                                >
                                    <GaSelect
                                        idSelect={'countryContainer'}
                                        value={country}
                                        name="country"
                                        isLoading={!countriesAndStates?.length}
                                        labelText={t('welcome.country')}
                                        optionValueProperty={
                                            initialLang
                                                ? 'name_' + initialLang
                                                : 'name_es'
                                        }
                                        optionTextProperty={
                                            initialLang
                                                ? 'name_' + initialLang
                                                : 'name_es'
                                        }
                                        onChange={(e) => {
                                            handleAddressInputsChange(e),
                                                getStates(e.target.value)
                                        }}
                                        options={countriesAndStates}
                                        readOnly={!formActive}
                                    />
                                    <GaSelect
                                        idSelect={'regionContainer'}
                                        value={region}
                                        name="region"
                                        isLoading={!states?.length}
                                        labelText={t('welcome.state')}
                                        onChange={(e) =>
                                            handleAddressInputsChange(e)
                                        }
                                        options={states}
                                        readOnly={!formActive}
                                    />
                                </div>
                                <div
                                    className={
                                        styles.form__textInputs__doubleColumn
                                    }
                                >
                                    <GaTextInput
                                        className={cx('margin-bottom')}
                                        name="locality"
                                        invalid={false}
                                        labelText={t('welcome.locality')}
                                        onChange={(e) =>
                                            handleAddressInputsChange(e)
                                        }
                                        placeholder={t('welcome.locality')}
                                        type="text"
                                        value={locality}
                                        required
                                        readOnly={!formActive}
                                    />

                                    <GaTextInput
                                        className={cx('margin-bottom')}
                                        name="postalCode"
                                        invalid={false}
                                        labelText={t('welcome.postalCode')}
                                        onChange={(e) =>
                                            handleAddressInputsChange(e)
                                        }
                                        placeholder={t('welcome.postalCode')}
                                        type="text"
                                        value={postalCode}
                                        required
                                        readOnly={!formActive}
                                    />
                                </div>
                                <GaTextInput
                                    name="VATRegistration"
                                    invalid={false}
                                    labelText={t('welcome.nif')}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={t('welcome.nif')}
                                    type="text"
                                    value={VATRegistration}
                                    required
                                    readOnly={!formActive}
                                />
                            </div>
                            {/* Commented until upload photo is available */}
                            {/* <div
                            className={`${styles.form__imageInput} ${
                                photo ? styles.form__imageInputFilled : ''
                            }`}
                            onClick={() => {
                                document.getElementById('imageInput')?.click()
                            }}
                        >
                            <input
                                id={'imageInput'}
                                type="file"
                                onChange={onSelectFile}
                            />
                            {photo ? (
                                <img src={preview} />
                            ) : (
                                <div className={styles.form__imageInput__empty}>
                                    <GaImage image={gaImages.editCard} />
                                    <p>{t('general.editLogo')}</p>
                                </div>
                            )}
                        </div> */}
                        </div>
                        {formActive ? (
                            <>
                                {canUpdateOrg && (
                                    <div className={styles.formButtons}>
                                        <LightButton
                                            size={ButtonSizes.SM}
                                            text={t('public.cancel')}
                                            functionality={() => (
                                                setFormState(emptyFormData),
                                                setLegalAddressState(
                                                    emptyFormAddress
                                                ),
                                                setFormDataModified(false),
                                                setFormActive(false)
                                            )}
                                            disabled={false}
                                        />
                                        <DarkButton
                                            size={ButtonSizes.SM}
                                            text={'public.save'}
                                            disabled={
                                                !(formDataModified && validForm)
                                            }
                                            functionality={submitData}
                                        />
                                    </div>
                                )}
                            </>
                        ) : null}
                    </LightPanel>
                </>
            ) : (
                <PermissionsPanel scopes={organizationScopes.read} readScope />
            )}
            {!!canDeleteOrg && !!canReadOrg && !onPremise && userIsOrgOwner ? (
                <ErrorFilledPanel
                    className={`${cx(
                        'marginTop32 padding32',
                        styles.warningPanelContainer
                    )}`}
                    title={'general.deleteOrganization'}
                    label={t('general.deleteOrganizationDesc')}
                    hasTitle={true}
                    rightButton={{
                        label: 'general.deleteOrganization',
                        function: () => setShowDeleteModal(true),
                    }}
                />
            ) : null}
            {showDeleteWarning ? (
                <WarningModal
                    title="general.sureYouWantDeleteOrg"
                    description="general.sureYouWantDeleteOrgDesc"
                    primaryButton={deleteModalButtons.primary}
                    secondaryButton={deleteModalButtons.secondary}
                    hideModal={() => setShowDeleteModal(false)}
                    item={org}
                />
            ) : null}
        </React.Fragment>
    )
}

Organization.defaultProps = {}

export default Organization
