import cx from 'classnames'
import React from 'react'
import styles from './notificationCard.module.scss'
import { t } from 'i18next'
import CircleIcon from '../../../../../../../assets/iconsLibrary/simple/circle/circleIcon'
import {
    CredentialIcons,
    gaColors,
    gaImages,
} from '../../../../../../../assets/globalConstants'
import GaImage from '../../../../../../../interfaces/Image/Image'
import GaTag from '../../../../../../../Components/atoms/tag/Tag'
import DarkButton from '../../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../../../../data/globalVar'
import { IEWNotificationModel } from '../../../../../../../interfaces/interfaces'
import { translateCatalog } from '../../../../../../wallet/requestCredentials/utils/formatCredUtil'
import DropdownActions from '../../../../../../../Components/organisms/table/cells/dropdownActions/dropdownActions'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { notificationsSelectors } from '../../../../../../../store/notifications'

type INotificationCardProps = {
    notification: IEWNotificationModel
    loadingStatus: boolean
    showDeleteNotificationConfirmation: (id: string) => void
    downloadCred: (id: string) => void
}

const NotificationCard: React.FC<INotificationCardProps> = (props) => {
    const {
        notification,
        loadingStatus,
        showDeleteNotificationConfirmation,
        downloadCred,
    } = props

    const {
        notificationId,
        credentialGroup,
        group_name,
        issuer_name,
        expirationAt,
        readAt,
        createdAt,
        expired,
        createdFromTime,
    } = notification

    const actions = [
        {
            text: 'public.delete',
            color: 'alert300',
            action: () => showDeleteNotificationConfirmation(notificationId),
        },
    ]

    // Selectors
    const notificationsReadedInFront = useSelector(
        notificationsSelectors.notificationsReadedInFrontNotSeen
    )

    // Consts
    const readed =
        !!notificationsReadedInFront?.filter(
            (el) => el?.notificationId === notificationId
        )?.length && notification?.readAt?.length

    return (
        <div className={`${styles.notificationCard}`}>
            <div className={styles.notificationCard__header}>
                {!readAt || !readed ? (
                    <div className={styles.unreadIcon}>
                        <CircleIcon size={8} color={gaColors.info300} />
                    </div>
                ) : null}

                <div className={styles.info}>
                    <GaImage
                        image={{
                            src:
                                CredentialIcons[
                                    credentialGroup
                                        ? credentialGroup
                                        : 'credential'
                                ] || CredentialIcons?.credential,
                            alt: t(gaImages.credential.alt),
                        }}
                    />

                    <div>
                        <p className={cx('bodyBoldSM')}>
                            {typeof group_name == 'string'
                                ? t(group_name)
                                : translateCatalog(group_name) ||
                                  t('ew.requestCredentials.credential')}
                        </p>
                        <p className={cx('buttonSM')}>
                            {t('ew.notifications.by', {
                                entity: translateCatalog(issuer_name),
                            })}
                        </p>
                        <p className={cx('bodyRegularXS neutral800')}>
                            {t('ew.notifications.hasBeen')}
                            <span className={cx('bodyRegularXS success300')}>
                                {t('ew.notifications.issued')}
                            </span>
                        </p>
                        {expirationAt &&
                        moment(expirationAt) &&
                        moment(expirationAt)
                            ?.format('DD/MM/YYYY')
                            ?.toLowerCase() !== 'invalid date' ? (
                            <div className={styles.notStatusTag}>
                                {!expired ? (
                                    <GaTag
                                        text={t('ew.notifications.getItBy', {
                                            date: moment(expirationAt)?.format(
                                                'DD/MM/YYYY'
                                            ),
                                        })}
                                        className={
                                            loadingStatus
                                                ? styles.getItDisabled
                                                : undefined
                                        }
                                        color={
                                            loadingStatus ? 'grey' : 'yellow'
                                        }
                                    />
                                ) : (
                                    <GaTag
                                        text={t('credentials.expired', {
                                            date: moment(expirationAt)?.format(
                                                'DD/MM/YYYY'
                                            ),
                                        })}
                                        color={'red'}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className={styles.actions}>
                    <div>
                        {createdFromTime ? (
                            <p className={cx('bodyRegularCap neutral800')}>
                                {createdFromTime}
                            </p>
                        ) : null}
                        {expired ? (
                            <DropdownActions
                                item={notification}
                                isLastItem={false}
                                className={styles.moreIcon}
                                options={actions}
                                closeDropdownOnSelectAction={true}
                            />
                        ) : null}
                    </div>
                    <DarkButton
                        text={'Download'}
                        disabled={!!expired}
                        className={
                            expired || loadingStatus
                                ? styles.disabled
                                : undefined
                        }
                        size={ButtonSizes.SM}
                        functionality={() => downloadCred(notificationId)}
                    />
                </div>
            </div>
        </div>
    )
}

export default NotificationCard
