import cx from 'classnames'
import React from 'react'
import styles from './connections.module.scss'
import { t } from 'i18next'
import NoLinkedServices from './noLinkedServices/NoLinkedServices'

type IConnectionsProps = {
    connections?: any[]
}

const Connections: React.FC<IConnectionsProps> = (props) => {
    const { connections } = props

    return connections?.length ? (
        <div className={styles.connections}>
            {/* TODO: Replace for real connections */}
            {/* {connections?.map((service, index) => { */}
            {/* return ( */}
            <div
                // key={'linkedService__' + index}
                className={styles.connections__linkedService}
            >
                <div>
                    <p className={cx('buttonSM')}>Gataca</p>
                    <p className={cx('bodyRegularCap neutral700')}>
                        {t('ew.requestCredentials.issued')}
                    </p>
                </div>
                <p className={cx('bodyRegularXS')}>Jan 12, 2023 at 12:58</p>
            </div>
            <div
                // key={'linkedService__' + index}
                className={styles.connections__linkedService}
            >
                <div>
                    <p className={cx('buttonSM')}>Gataca</p>
                    <p className={cx('bodyRegularCap neutral700')}>
                        {' '}
                        {t('ew.requestCredentials.issued')}
                    </p>
                </div>
                <p className={cx('bodyRegularXS')}>Jan 12, 2023 at 12:58</p>
            </div>
            <div
                // key={'linkedService__' + index}
                className={styles.connections__linkedService}
            >
                <div>
                    <p className={cx('buttonSM')}>Gataca</p>
                    <p className={cx('bodyRegularCap neutral700')}>
                        {' '}
                        {t('ew.requestCredentials.issued')}
                    </p>
                </div>
                <p className={cx('bodyRegularXS')}>Jan 12, 2023 at 12:58</p>
            </div>
            <div
                // key={'linkedService__' + index}
                className={styles.connections__linkedService}
            >
                <div>
                    <p className={cx('buttonSM')}>Gataca</p>
                    <p className={cx('bodyRegularCap neutral700')}>
                        {' '}
                        {t('ew.requestCredentials.issued')}
                    </p>
                </div>
                <p className={cx('bodyRegularXS')}>Jan 12, 2023 at 12:58</p>
            </div>
            <div
                // key={'linkedService__' + index}
                className={styles.connections__linkedService}
            >
                <div>
                    <p className={cx('buttonSM')}>Gataca</p>
                    <p className={cx('bodyRegularCap neutral700')}>
                        {' '}
                        {t('ew.requestCredentials.issued')}
                    </p>
                </div>
                <p className={cx('bodyRegularXS')}>Jan 12, 2023 at 12:58</p>
            </div>
            <div
                // key={'linkedService__' + index}
                className={styles.connections__linkedService}
            >
                <div>
                    <p className={cx('buttonSM')}>Gataca</p>
                    <p className={cx('bodyRegularCap neutral700')}>
                        {' '}
                        {t('ew.requestCredentials.issued')}
                    </p>
                </div>
                <p className={cx('bodyRegularXS')}>Jan 12, 2023 at 12:58</p>
            </div>
            {/* )
            })} */}
        </div>
    ) : (
        <NoLinkedServices />
    )
}

export default Connections
