import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { didsSelectors } from '../..'
import StatusPanel from '../../../../../Components/atoms/panels/StatusPanel/StatusPanel'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import { ICreateDidModel } from '../../../../../interfaces/interfaces'
import { setSelectedRoute } from '../../../../common/PanelScafolding/scafoldingActions'
import {
    clearActionStatus,
    clearDid,
    clearFormState,
    setSteps,
    updateDid,
} from '../../didActions'
import {
    getActiveStep,
    getEndsSteps,
    getLastStep,
    getSelectedDid,
    getSelectedDidBaseId,
    getSelectedDidToken,
} from '../../didSelectors'
import { configFormSteps } from '../didList/didList.constants'
import styles from './configDid.module.scss'

type IConfigDidProps = {}

const ConfigDid: React.FC<IConfigDidProps> = ({}) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const endsStep = useSelector(getEndsSteps)
    const activeStep = useSelector(getActiveStep)
    const selectedDid = useSelector(getSelectedDid)
    const lastStep = useSelector(getLastStep)
    const selectedDidToken = useSelector(getSelectedDidToken)
    const selectedDidBaseId = useSelector(getSelectedDidBaseId)
    const configStatus = useSelector(didsSelectors.getStatus)
    const configError = useSelector(didsSelectors.getError)

    useEffect(() => {
        dispatch(setSteps(configFormSteps))
        return () => {
            dispatch(clearDid())
        }
    }, [configFormSteps])

    const forwardFunction = (data: any) => {
        const simplifiedDid = data as ICreateDidModel
        dispatch(updateDid(simplifiedDid, selectedDidBaseId))
    }

    const handleCloseModal = () => dispatch(clearActionStatus())

    const navigate = (route) => {
        dispatch(setSelectedRoute(route)), history.push(route)
    }
    const goToHome = () => {
        clearState(), navigate('home')
    }
    const goToList = () => {
        clearState(), navigate('dids')
    }

    const clearState = () => {
        dispatch(clearFormState())
        dispatch(clearDid())
    }

    return (
        <>
            <ScreenHeader title={'configDid.title'} />

            <div className={styles.editDid}>
                <p className={styles.sectionDescription}>
                    {t('configDid.description')}
                </p>
                {activeStep?.component ? (
                    <activeStep.component
                        buttonFunction={forwardFunction}
                        currentDid={selectedDid}
                    />
                ) : null}
            </div>

            <StatusPanel
                successMessage={'configDid.success'}
                state={'configDid.isReady'}
                operationStatus={configStatus}
                show={
                    configStatus &&
                    (configStatus as ActionStatusTypes) !==
                        ActionStatusTypes.neutral
                }
                failedText={
                    t('configDid.failedOperation') + (configError ? ': ' : '.')
                }
                error={configError || ''}
                primaryButton={{
                    label: 'createDid.backToList',
                    function: goToList,
                }}
                secondaryButton={{
                    label: 'createDid.backToHome',
                    function: goToHome,
                }}
                handleClose={handleCloseModal}
            />
        </>
    )
}

export default ConfigDid
