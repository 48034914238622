import React from 'react'
import { useTranslation } from 'react-i18next'
import { gaImages } from '../../../../../assets/globalConstants'
import { ButtonModel, ImageModel } from '../../../../../interfaces/interfaces'
import LightButton from '../../../../atoms/buttons/LightButton/LightButton'
import GaImage from '../../../../atoms/Image/Image'
import Description from '../../../../atoms/texts/Description/Description'
import styles from './warningModal.module.scss'
import cx from 'classnames'

type IWarningModalProps = {
    title?: string
    description?: string
    item: any
    icon?: ImageModel
    primaryButton: ButtonModel
    secondaryButton: ButtonModel
    showBg?: boolean
    hideModal: () => void
}

const WarningModal: React.FC<IWarningModalProps> = (props) => {
    const {
        title,
        description,
        icon,
        item,
        primaryButton,
        secondaryButton,
        showBg,
        hideModal,
    } = props
    const { t } = useTranslation()

    const continueFunction = (x, item) =>
        primaryButton?.function ? primaryButton?.function(item) : null

    return (
        <>
            <div
                className={`${styles.backgroundLayer} ${
                    showBg ? styles.darkBackgroundLayer : ''
                }`}
                onClick={() => hideModal()}
            >
                <div className={styles.modal}>
                    <GaImage
                        className={styles.modal__icon}
                        image={icon || gaImages.yellowWarningPng}
                    />
                    <p
                        className={cx(
                            'heading5 neutral1000',
                            styles.modal__title
                        )}
                    >
                        {t(title || 'public.sureDelete')}
                    </p>
                    <Description
                        className={cx(
                            'bodyRegularSM neutral700',
                            styles.modal__description
                        )}
                        text={description || 'credentials.noAbleToRecover'}
                    />
                    <div>
                        <LightButton
                            className={styles.modal__cancel}
                            text={secondaryButton?.label}
                            disabled={false}
                            functionality={secondaryButton?.function}
                        />

                        <LightButton
                            className={styles.modal__delete}
                            text={primaryButton?.label}
                            disabled={false}
                            functionality={(e) => continueFunction(e, item)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default WarningModal
