import { Epic, ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import dataService from '../../../services/dataService'
import { getCatalog } from '../../common/catalog/catalogActions'
import { setReachedLimitsAction } from '../../common/licenses/licensesActions'
import { showNotification } from '../../common/Notification/notificationActions'
import {
    addIssuerTypeFailed,
    addIssuerTypeSuccess,
    addVerifierTypeFailed,
    addVerifierTypeSuccess,
    createDidFailed,
    createDidSuccess,
    deleteDidFailed,
    deleteDidSuccess,
    deleteIssuerTypeFailed,
    deleteIssuerTypeSuccess,
    deleteVerifierTypeFailed,
    deleteVerifierTypeSuccess,
    getDids,
    getDidsFailed,
    getDidsSuccess,
    getKeypairFailed,
    getKeypairSuccess,
    getSecretFailed,
    getSecretSuccess,
    updateDidFailed,
    updateDidSuccess,
} from './didActions'
import { ConfigDidActionsTypes, DidListActionsTypes } from './didTypes'

// LIST
export const requestDidsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(DidListActionsTypes.GET_DIDS),
        switchMap((): any => {
            return from(dataService.getDidsSetup()).pipe(
                mergeMap((response?: any) => {
                    return merge(of(getDidsSuccess(response.response)))
                }),
                catchError((error) =>
                    of(
                        getDidsFailed(error),
                        showNotification(
                            'createDid.getDidsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

// CONFIG DID

export const requestKeypairEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConfigDidActionsTypes.GET_KEYPAIR),
        switchMap((): any => {
            return from(dataService.generateKeypair()).pipe(
                map((response?: any) => {
                    return getKeypairSuccess(
                        response?.response?.privateKey,
                        response?.response?.publicKey
                    )
                }),
                catchError((error) =>
                    of(
                        getKeypairFailed(error),
                        showNotification(
                            'createDid.step2.generateKeypairError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const requestSecretEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConfigDidActionsTypes.GET_SECRET),
        switchMap((): any => {
            return from(dataService.generateSecret()).pipe(
                map((response?: any) => {
                    return getSecretSuccess(response?.response?.secret)
                }),
                catchError((error) =>
                    of(
                        getSecretFailed(error),
                        showNotification(
                            'createDid.step3.generateSecretError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

// CREATE / UPDATE
export const createDidEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConfigDidActionsTypes.CREATE_DID),
        switchMap((action: any): any => {
            const did = action.payload.did

            return from(dataService.createDid(did)).pipe(
                mergeMap((response?: any) => {
                    const newDidId = response?.response?.id
                    return of(
                        createDidSuccess(newDidId),
                        getDids(),

                        showNotification('dids.successCreateDid', 'success', '')
                    )
                }),
                catchError((error) => {
                    const limitReachedError = error?.status === 429
                    return of(
                        createDidFailed(error.response?.response?.status),
                        limitReachedError
                            ? setReachedLimitsAction(
                                  'subscription.limitedFeatures.dids'
                              )
                            : showNotification(
                                  'dids.errorCreateDid',
                                  'error',
                                  error.response?.message,
                                  error?.status
                              )
                    )
                })
            )
        })
    )
}

export const createFirstDidEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConfigDidActionsTypes.CREATE_FIRST_DID),
        switchMap((action: any): any => {
            const did = action.payload.did

            return from(dataService.createFirstDid(did)).pipe(
                mergeMap((response?: any) => {
                    const newDidId = response?.response?.id
                    return of(
                        createDidSuccess(newDidId),
                        getDids(),

                        showNotification('dids.successCreateDid', 'success', '')
                    )
                }),
                catchError((error) => {
                    const limitReachedError = error?.status === 429
                    return of(
                        createDidFailed(error.response?.response?.status),
                        limitReachedError
                            ? setReachedLimitsAction(
                                  'subscription.limitedFeatures.dids'
                              )
                            : showNotification(
                                  'dids.errorCreateDid',
                                  'error',
                                  error.response?.message,
                                  error?.status
                              )
                    )
                })
            )
        })
    )
}

export const updateDidEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConfigDidActionsTypes.UPDATE_DID),
        switchMap((action: any): any => {
            const did = action.payload.did
            const didId = action.payload.didId

            return from(dataService.updateDid(did, didId)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        updateDidSuccess(),
                        getDids(),
                        showNotification('dids.successUpdateDid', 'success', '')
                    )
                }),
                catchError((error) =>
                    of(
                        updateDidFailed(error),
                        getDids(),
                        showNotification(
                            'dids.errorUpdateDid',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deleteDidEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(DidListActionsTypes.DELETE_DID),
        switchMap((action: any): any => {
            const didId = action.payload.id

            return from(dataService.deleteDid(didId)).pipe(
                mergeMap(() => {
                    return of(
                        deleteDidSuccess(),
                        getDids(),
                        showNotification('dids.successDeleteDid', 'success', '')
                    )
                }),
                catchError((error) =>
                    of(
                        deleteDidFailed(error),
                        showNotification(
                            'dids.errorDeleteDid',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deleteIssuerTypeEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(DidListActionsTypes.DELETE_ISSUER_TYPE),
        switchMap((action: any): any => {
            const didId = action.payload.id

            return from(dataService.deleteIssuerType(didId)).pipe(
                mergeMap(() => {
                    return of(
                        deleteIssuerTypeSuccess(),
                        getCatalog(),
                        getDids(),
                        showNotification('dids.successUpdateDid', 'success', '')
                    )
                }),
                catchError((error) =>
                    of(
                        deleteIssuerTypeFailed(error),
                        showNotification(
                            'dids.errorUpdateDid',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deleteVerifierTypeEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(DidListActionsTypes.DELETE_VERIFIER_TYPE),
        switchMap((action: any): any => {
            const didId = action.payload.id

            return from(dataService.deleteVerifierType(didId)).pipe(
                mergeMap(() => {
                    return of(
                        deleteVerifierTypeSuccess(),
                        getCatalog(),
                        getDids(),
                        showNotification('dids.successUpdateDid', 'success', '')
                    )
                }),
                catchError((error) =>
                    of(
                        deleteVerifierTypeFailed(error),
                        showNotification(
                            'dids.errorUpdateDid',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const addIssuerTypeEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(DidListActionsTypes.ADD_ISSUER_TYPE),
        switchMap((action: any): any => {
            const didId = action.payload.id

            return from(dataService.addIssuerType(didId)).pipe(
                mergeMap(() => {
                    return of(
                        addIssuerTypeSuccess(),
                        getCatalog(),
                        getDids(),
                        showNotification(
                            'dids.successUpdateDidIssuerType',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        addIssuerTypeFailed(error),
                        showNotification(
                            'dids.errorUpdateDidIssuerType',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const addVerifierTypeEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(DidListActionsTypes.ADD_VERIFIER_TYPE),
        switchMap((action: any): any => {
            const didId = action.payload.id

            return from(dataService.addVerifierType(didId)).pipe(
                mergeMap(() => {
                    return of(
                        addVerifierTypeSuccess(),
                        getCatalog(),
                        getDids(),
                        showNotification(
                            'dids.successUpdateDidVerifierType',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        addVerifierTypeFailed(error),
                        showNotification(
                            'dids.errorUpdateDidVerifierType',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
