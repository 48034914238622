import {CatalogAction, CatalogState, CredentialActionTypes} from './catalogTypes';

const catalogInitialState: CatalogState = {
    authority: [],
    credentialGroups: [],
    credentialTypes: [],
    serviceProviders: [],
    loading: {
        status: 'inactive',
        description: ''
    }
};

export function catalogReducer (
    state = catalogInitialState,
    action: CatalogAction
) : CatalogState {
    switch (action.type) {
        case CredentialActionTypes.GET_CATALOG:
            return {
                ...state,
                loading: {
                    status: 'active',
                    description: 'Loading...'
                }
            };
        case CredentialActionTypes.GET_CATALOG_SUCCESS:
            return {
                ...state,
                authority: action.payload?.catalog?.authority,
                credentialGroups: action.payload?.catalog?.credentialGroup,
                credentialTypes: action.payload?.catalog?.credentialType,
                serviceProviders: action.payload?.catalog?.serviceProvider,
                loading: {
                    status: 'finished',
                    description: 'Loaded'
                }
            };
        case CredentialActionTypes.GET_CATALOG_ERROR:
            return {
                ...state,
                loading: {
                    status: 'error',
                    description: 'Something happened, check again.'
                }
            };
        case CredentialActionTypes.CREDENTIAL_REVOKE:
            return {
                ...state
            };
        case CredentialActionTypes.CREDENTIAL_SUSPEND:
            return {
                ...state
            };
        default:
            return state;
    }
};
