import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import SearchableListHeader from '../../../../../Components/molecules/headers/SearchableListHeader/SearchableListHeader'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import TableSkeleton from '../../../../../Components/molecules/skeletons/tableSkeleton/TableSkeleton'
import WarningModal from '../../../../../Components/molecules/table/dynamicTable/warninModal/WarningModal'
import BasicCard from '../../../../../Components/organisms/Cards/BasicCard'
import { gaImages } from '../../../../../assets/globalConstants'
import { CertificateModel } from '../../../../../interfaces/interfaces'
import { shortValue } from '../../../../../utils/formatDataUtil'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { didsScopes } from '../../../Dids/views/didList/didList.constants'
import { issuancesScopes } from '../../../Issuance/issuance.constants'
import { getIssuances } from '../../../Issuance/issuanceActions'
import { statsSelectors } from '../../../../common/Statistics'
import { getCatalogTypes } from '../../../../common/catalog/catalogSelectors'
import { sessionSelectors } from '../../../../common/session'
import { headerCertificatesListButton } from '../../certificates.constants'
import {
    deleteCertificate,
    getCertificates,
    selectCertificate,
    uploadCertificate,
} from '../../certificatesActions'
import {
    getCertificatesList,
    getSelectedCertificate,
    isLoading,
} from '../../certificatesSelectors'
import CertificatesTable from '../certificatesTable/CertificatesTable'
import DeleteWarningModal from '../components/deleteWarningModal/DeleteWarningModal'
import CertificatesUploadModal from '../uploadModal/UploadModal'
import {
    certificatesScopes,
    headersCertificatesTable,
} from './certificatesList.constants'

type ICertificatesListProps = {}

type ISearchState = {
    searched: string
    searchedResult: CertificateModel[] | undefined
}

const CertificatesList: React.FC<ICertificatesListProps> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const hasReadScope = !!(
        availableScopes && userHasScopes(didsScopes?.read, availableScopes)
    )
    const canReadIssuances = !!(
        availableScopes && userHasScopes(issuancesScopes?.read, availableScopes)
    )

    const canDelete = !!(
        availableScopes && userHasScopes(didsScopes?.delete, availableScopes)
    )

    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)

    const isLoadingCertificates = useSelector(isLoading)

    const certificates = useSelector(getCertificatesList)

    let selectedCertificate = useSelector(getSelectedCertificate)

    const certificateTypes = useSelector(getCatalogTypes)

    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const initialCertificatesListState = {
        searched: '',
        searchedResult: certificates?.slice(),
    }

    const [state, setState] = React.useState<ISearchState>(
        initialCertificatesListState
    )

    useEffect(() => {
        hasReadScope &&
            (canReadIssuances && dispatch(getIssuances()),
            dispatch(getCertificates()))
        if (canReadIssuances) {
            dispatch(getIssuances())
        }
    }, [])

    useEffect(() => {}, [selectedCertificate])

    useEffect(() => {
        setState(initialCertificatesListState)
    }, [certificates, pageSize, pageIndex, certificateTypes])

    useEffect(() => {
        if (!!certificates) {
            setState(initialCertificatesListState)
        }
    }, [])

    const handlePageChange = (e, type: string) => {
        setPageIndex(e.page)
        setPageSize(e.pageSize)

        /* { TODO: Uncomment when BE Search is available */
        // setPaginatedCertificates(
        //     paginate(certificates, e.pageSize, e.page)
        // )

        // setState(initialCertificatesListState)
    }

    const [showUploadModal, setShowUploadModal] = React.useState(false)
    const showQSUploadModal = () => setShowUploadModal(true)
    const hideUploadModal = () => setShowUploadModal(false)

    const [showDelete, setShowWarningModal] = React.useState(false)
    const showWarningModal = () => setShowWarningModal(true)
    const hideWarningModal = () => setShowWarningModal(false)

    /* { TODO: Uncomment when BE Search is available */
    // const handleSearchedChange = (items) => {
    //   if(!!items) {
    //     setState({
    //       searched: state.searched,
    //       searchedResult: paginate(items, pageSize, pageIndex)
    //     })
    //     setTotalItems(items.length)
    //   }
    // }

    const selectCertificateHandler = (item) => {
        dispatch(selectCertificate(item))
    }

    const deleteSelectedCertificate = (item) => {
        dispatch(deleteCertificate(item?.id))
        hideWarningModal()
        dispatch(getCertificates())
    }

    const uploadCertificateFunc = (
        file: File,
        alias?: string,
        password?: string
    ) => {
        dispatch(uploadCertificate(file, alias, password))
    }

    const handleOptions = (item, selection?, y?) => {
        selectedCertificate = item
        dispatch(selectCertificate(item))
        showWarningModal()
    }

    const warningModalButtons = {
        primary: {
            label: 'public.delete',
            function: deleteSelectedCertificate,
        },
        secondary: { label: 'public.cancel', function: hideWarningModal },
    }

    const handleSearchedChange = (items) => {
        if (!!items) {
            setState({
                searched: state.searched,
                searchedResult: items,
            })
        }
    }

    return (
        <>
            <ScreenHeader
                title={'certificates.title'}
                hideBorderBottom={true}
                subText="certificates.description"
                buttonScopes={didsScopes?.create}
                button={{
                    ...headerCertificatesListButton,
                    // disabled: !!limitsAreExceded || limitsAreReached,
                    // disabledTooltip: limitsAreReached,
                    disabled: false,
                    disabledTooltip: false,
                }}
                buttonFunction={showQSUploadModal}
            />

            <>
                {!isLoadingCertificates ? (
                    <>
                        {certificates?.length ? (
                            <SearchableListHeader
                                isLoading={isLoadingCertificates}
                                options={certificates}
                                propertiesToSearch={[
                                    {
                                        text: 'alias',
                                        searchMultipleLanguages: false,
                                        latinize: true,
                                    },
                                ]}
                                handleSearchedChange={handleSearchedChange}
                            />
                        ) : null}
                        {hasReadScope ? (
                            !!certificates &&
                            certificates?.length &&
                            !!certificates[0] ? (
                                <LightPanel>
                                    <CertificatesTable
                                        tableData={state?.searchedResult}
                                        headerData={headersCertificatesTable}
                                        mobileItem={(item) => (
                                            <BasicCard
                                                item={item}
                                                name={shortValue(
                                                    item.id,
                                                    15,
                                                    5
                                                )}
                                                rightDate={item?.createdAt}
                                                buttonFunction={function (
                                                    e: any
                                                ): void {
                                                    throw new Error(
                                                        'Function not implemented.'
                                                    )
                                                }}
                                            />
                                        )}
                                        selectItem={selectCertificateHandler}
                                        deleteItem={handleOptions}
                                        noResults={
                                            !(
                                                state.searchedResult &&
                                                state.searchedResult?.length > 0
                                            )
                                            /* { TODO: Uncomment when BE Search is available */
                                            // &&
                                            // !(
                                            //     state.searchedResult &&
                                            //     state.searchedResult
                                            //         ?.length
                                            // )
                                        }
                                        handleDropdownChange={() => {}}
                                        handleOptions={handleOptions}
                                    />

                                    {showDelete ? (
                                        selectedCertificate?.templates
                                            ?.length ? (
                                            <DeleteWarningModal
                                                hideModal={hideWarningModal}
                                                item={selectedCertificate}
                                                primaryButton={
                                                    warningModalButtons.primary
                                                }
                                                secondaryButton={
                                                    warningModalButtons.secondary
                                                }
                                                affectedTemplates={
                                                    selectedCertificate?.templates
                                                }
                                            />
                                        ) : (
                                            <WarningModal
                                                hideModal={hideWarningModal}
                                                title="certificates.sureYouWantDelete"
                                                description="certificates.notAbleToRecover"
                                                item={selectedCertificate}
                                                primaryButton={
                                                    warningModalButtons.primary
                                                }
                                                secondaryButton={
                                                    warningModalButtons.secondary
                                                }
                                                icon={gaImages.warning}
                                            />
                                        )
                                    ) : null}
                                </LightPanel>
                            ) : (
                                <Description
                                    className={cx('bodyRegularSM neutral700')}
                                    text={t('certificates.notCertificatesYet')}
                                />
                            )
                        ) : (
                            <PermissionsPanel
                                scopes={certificatesScopes?.read}
                                readScope
                            />
                        )}
                    </>
                ) : (
                    <TableSkeleton
                        columnsNumber={3}
                        rowsNumber={10}
                        responsiveCards={true}
                    />
                )}
                {showUploadModal ? (
                    <CertificatesUploadModal
                        show={showUploadModal}
                        hideModal={hideUploadModal}
                        uploadCertificate={uploadCertificateFunc}
                    />
                ) : null}
            </>
        </>
    )
}

export default CertificatesList
