import * as React from 'react'
import cx from 'classnames'
import stylesSM from './animatedLoaderSM.module.scss'
import stylesMD from './animatedLoaderMD.module.scss'
import { LoaderSizes } from '../../../data/globalVar'

type GaAnimatedLoaderProps = {
    size: LoaderSizes
}

const AnimatedLoader: React.FC<GaAnimatedLoaderProps> = (props) => {
    const { size } = props

    const sizeStyles = size === LoaderSizes?.MD ? stylesMD : stylesSM

    return (
        <div className={cx(sizeStyles.container)}>
            <div className={cx(sizeStyles.loader)}>
                <div
                    className={cx(
                        sizeStyles.loader__item,
                        sizeStyles.loader__item__1
                    )}
                ></div>
                <div
                    className={cx(
                        sizeStyles.loader__item,
                        sizeStyles.loader__item__2
                    )}
                ></div>
                <div
                    className={cx(
                        sizeStyles.loader__item,
                        sizeStyles.loader__item__3
                    )}
                ></div>
                <div
                    className={cx(
                        sizeStyles.loader__item,
                        sizeStyles.loader__item__4
                    )}
                ></div>
                <div
                    className={cx(
                        sizeStyles.loader__item,
                        sizeStyles.loader__item__5
                    )}
                ></div>
                <div
                    className={cx(
                        sizeStyles.loader__item,
                        sizeStyles.loader__item__6
                    )}
                ></div>
                <div
                    className={cx(
                        sizeStyles.loader__item,
                        sizeStyles.loader__item__7
                    )}
                ></div>
                <div
                    className={cx(
                        sizeStyles.loader__item,
                        sizeStyles.loader__item__8
                    )}
                ></div>
            </div>
        </div>
    )
}

export default AnimatedLoader
