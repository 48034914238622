import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { configVerifierSelectors } from '../..'
import AddToApiKeyModal from '../../../../../Components/molecules/modals/AddToApiKeyModal/AddToApiKeyModal'
import InvalidFormModal from '../../../../../Components/molecules/table/dynamicTable/invalidFormModal/InvalidFormModal'
import {
    IApiKeyModel,
    IVerifierConfigModel,
    LocationStateModel,
} from '../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { apiKeysSelectors } from '../../../ApiKeys'
import { getApiKeys, updateApiKey } from '../../../ApiKeys/apiKeysActions'
import { apiKeysScopes } from '../../../ApiKeys/views/apiKeysList/apiKeysList.constants'
import { addVerifierType } from '../../../Dids/didActions'
import { displayScan } from '../../../Home/homeActions'
import { sandboxScopes } from '../../../Home/HomeComponents/sandboxModal/sandboxModalConstants'
import { setSelectedRoute } from '../../../../common/PanelScafolding/scafoldingActions'
import { sessionSelectors } from '../../../../common/session'
import {
    createVerifier,
    editVerifier,
    setActiveStep,
    setSteps,
    updateVerifier,
} from '../../verifierActions'
import TestModal from '../testModal/TestModal'
import { formSteps, verifierScopes } from '../../data/verifier.constants'
import styles from './configVerifier.module.scss'
import { onPremise } from '../../../../../data/globalVar'
import dataService from '../../../../../services/dataService'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'
import { getPurposes } from '../../verifierSelectors'

type IConfigVerifierProps = {}

const ConfigVerifier: React.FC<IConfigVerifierProps> = ({}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const endsStep = useSelector(configVerifierSelectors.getEndsSteps)
    const activeStep = useSelector(configVerifierSelectors.getActiveStep)
    const verifier = useSelector(configVerifierSelectors.getSelectedVerifier)
    const configStatus = useSelector(configVerifierSelectors.getOperationStatus)
    const purposeList = useSelector(getPurposes)
    const location = useLocation<LocationStateModel>()
    const creatingVerifier = location && location?.state
    const apiKeysListUnfiltered = useSelector(apiKeysSelectors.getApiKeys)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const apiKeysList = apiKeysListUnfiltered?.filter((ak: IApiKeyModel) =>
        ak.ssi_operations?.includes(verifier?.id)
    )
    const apiKeysLoading = useSelector(apiKeysSelectors.loadingStatus)
    const canReadApikeys = !!(
        availableScopes && userHasScopes(apiKeysScopes?.read, availableScopes)
    )
    const canEditApikeys = !!(
        availableScopes && userHasScopes(apiKeysScopes?.edit, availableScopes)
    )
    const canCreateApiKey = !!(
        availableScopes && userHasScopes(apiKeysScopes?.create, availableScopes)
    )
    const canUseSandbox = !!(
        availableScopes && userHasScopes(sandboxScopes, availableScopes)
    )
    const canEditVerifiers = !!(
        availableScopes && userHasScopes(verifierScopes?.edit, availableScopes)
    )

    // Change Api Keys Dids
    let apiKeysWithChangedDidQtty = 0
    const apiKeysOnlyWithThisSSIConfig = apiKeysList?.filter(
        (ak: IApiKeyModel) => ak.ssi_operations?.length === 1
    )

    // Change Api Keys SSI Configs
    let apiKeysWithSSIConfigRemovedQtty = 0
    const apiKeysWithMoreSSIConfigs = apiKeysList?.filter(
        (ak: IApiKeyModel) => ak.ssi_operations?.length !== 1
    )

    const changeApiKeyDid = async (
        apiKey: any,
        apiKeyId: string,
        did: string
    ) => {
        let updatingApiKeys = false
        const newApyKey = apiKey
        newApyKey.did = did
        const simplifiedData = newApyKey as IApiKeyModel

        if (!updatingApiKeys) {
            updatingApiKeys = true

            while (
                apiKeysWithChangedDidQtty < apiKeysOnlyWithThisSSIConfig?.length
            ) {
                apiKeysWithChangedDidQtty = apiKeysWithChangedDidQtty + 1
                const callDone =
                    await dataService.updateApiKeyAccordingSSIConfig(
                        simplifiedData,
                        apiKeyId
                    )

                if (callDone !== undefined) {
                    updatingApiKeys = false
                }
            }
        }
    }

    const removeConfigFromApiKey = async (
        apiKey: any,
        apiKeyId: string,
        configID: string
    ) => {
        let updating = false
        const newApyKey = apiKey
        const newSSIConfigs = newApyKey.ssi_operations?.filter(
            (e) => e !== configID
        )
        newApyKey.ssi_operations = newSSIConfigs
        const simplifiedData = newApyKey as IApiKeyModel

        if (!updating) {
            updating = true
            while (
                apiKeysWithSSIConfigRemovedQtty <
                apiKeysWithMoreSSIConfigs?.length
            ) {
                apiKeysWithSSIConfigRemovedQtty =
                    apiKeysWithSSIConfigRemovedQtty + 1
                const callDone =
                    await dataService.updateApiKeyAccordingSSIConfig(
                        simplifiedData,
                        apiKeyId
                    )
                if (callDone !== undefined) {
                    updating = false
                }
            }
        }
    }

    const updateApiKeys = (did: string, config: string) => {
        if (
            apiKeysOnlyWithThisSSIConfig?.length ||
            apiKeysWithMoreSSIConfigs?.length
        ) {
            apiKeysOnlyWithThisSSIConfig?.map((apiKey: IApiKeyModel) => {
                changeApiKeyDid(apiKey, apiKey?.id, did)
            })
            apiKeysWithMoreSSIConfigs?.map((apiKey: IApiKeyModel) => {
                removeConfigFromApiKey(apiKey, apiKey?.id, config)
            })
        }
    }

    useEffect(() => {
        dispatch(setSteps(formSteps))
    }, [])

    useEffect(() => {
        canReadApikeys && !apiKeysLoading && dispatch(getApiKeys())
    }, [])

    const formatVerifier = (selectedVerifier) => {
        let formattedVerifier = selectedVerifier
        delete formattedVerifier._searchableText

        const verifierCredentials = selectedVerifier?.credentials?.map((e) => {
            return {
                type: e.type || e.id,
                purpose: e.purpose,
                mandatory: e.mandatory,
                trustLevel: e.trustLevel,
            }
        })

        if (formattedVerifier?.credentials) {
            formattedVerifier!.credentials = verifierCredentials
            const credPurposes = formattedVerifier?.credentials?.map((cred) => {
                return cred?.purpose
            })
            const fullPurposes = purposeList?.filter((purpose) =>
                credPurposes?.includes(purpose?.id)
            )

            formattedVerifier.dataAgreementTemplate = {
                ...selectedVerifier?.dataAgreementTemplate,
                purposes: fullPurposes,
            }
        }

        return formattedVerifier
    }

    function forwardFunction(data: IVerifierConfigModel) {
        const selectedVerifier = { ...data }
        const formattedVerifier = formatVerifier(selectedVerifier)
        dispatch(editVerifier(formattedVerifier))
        updateDidType(formattedVerifier)
        if (!creatingVerifier) {
            return saveVerifierInStep(formattedVerifier)
        } else {
            return endsStep && endsStep.nextStep
                ? dispatch(setActiveStep(endsStep.nextStep))
                : dispatch(createVerifier(formattedVerifier))
        }
    }

    const updateDidType = (newVerifierConfig) => {
        const currentVMethod = verifier?.vmethodId
        const filledVMethod = newVerifierConfig?.vmethodId
        if (activeStep?.order === 0 && currentVMethod !== filledVMethod) {
            let verifierDid = filledVMethod?.split('#')[0]
            !onPremise && dispatch(addVerifierType(verifierDid))
            updateApiKeys(verifierDid, newVerifierConfig?.id)
        }
    }

    function saveVerifierInStep(formattedVerifier) {
        dispatch(showScreenLoaderAction())
        dispatch(updateVerifier(formattedVerifier as IVerifierConfigModel))
    }

    function onHandleChange(step) {
        dispatch(setActiveStep(formSteps[step]))
    }

    const [show, setShowModal] = React.useState(false)
    const showModal = () => {
        !onPremise && dispatch(displayScan()), setShowModal(true)
    }
    const hideModal = () => setShowModal(false)

    const [showInvalidFormModal, setShowInvalidDataModal] =
        React.useState(false)
    const showInvalidDataModal = () => setShowInvalidDataModal(true)
    const hideInvalidDataModal = () => setShowInvalidDataModal(false)

    const [showAddApiKeysModal, setShowAddApiKeyModal] = React.useState(false)
    const showAddApiKeyModal = () => setShowAddApiKeyModal(true)
    const hideAddApiKeyModal = () => setShowAddApiKeyModal(false)

    const [validForm, setValidForm] = React.useState(false)

    async function addConfigurationToApiKeys(apiKeys: IApiKeyModel[]) {
        for (let apiKey of apiKeys) {
            if (verifier && verifier?.id) {
                apiKey?.ssi_operations?.push(verifier?.id)
                await dispatch(updateApiKey(apiKey, apiKey?.id))
            }
        }

        hideAddApiKeyModal()
        canUseSandbox && setShowModal(true),
            !onPremise && dispatch(displayScan())
    }

    const navigateToCreateApiKey = () => {
        dispatch(setSelectedRoute('create-apikey'))
        history.push('create-apikey')
    }

    const getHeaderButton = () => {
        const canAddToApiKeys = canEditApikeys && canReadApikeys
        const thereAreApiKeysCreated = apiKeysListUnfiltered?.length > 0
        const ssiIsAsignedToApiKey = apiKeysList?.length > 0
        let button

        // Button Options
        const buttonToAddApiKey = {
            label: 'editVerifier.addApiKey',
            function: showAddApiKeyModal,
        }

        const buttonToCreateApiKey = canCreateApiKey
            ? {
                  label: 'editVerifier.addApiKey',
                  function: navigateToCreateApiKey,
              }
            : undefined

        const buttonToTestSSI = canUseSandbox
            ? {
                  label: 'editVerifier.tryIt',
                  function: showModal,
              }
            : undefined

        const buttonForAddToOrCreateApiKey = thereAreApiKeysCreated
            ? buttonToAddApiKey
            : buttonToCreateApiKey
        // get the current button option
        if (canAddToApiKeys) {
            button = !ssiIsAsignedToApiKey
                ? buttonForAddToOrCreateApiKey
                : buttonToTestSSI
        } else {
            button = undefined
        }

        return button
    }

    const headerButton = getHeaderButton()

    return (
        <>
            <div className={styles.editVerifier}>
                {activeStep?.component ? (
                    <activeStep.component
                        buttonFunction={forwardFunction}
                        verifier={verifier}
                        creatingVerifier={!!creatingVerifier}
                        canEditVerifiers={canEditVerifiers}
                        setValidForm={setValidForm}
                        buttonFunctionHeader={
                            !creatingVerifier &&
                            headerButton &&
                            headerButton?.function
                                ? headerButton?.function
                                : undefined
                        }
                        buttonHeader={
                            !creatingVerifier ? headerButton : undefined
                        }
                        infoApiKeyPanel={
                            !creatingVerifier &&
                            !apiKeysList?.length &&
                            !apiKeysLoading
                        }
                        activeStep={activeStep || formSteps[0]}
                        formSteps={formSteps}
                        onHandleChange={
                            validForm ? onHandleChange : showInvalidDataModal
                        }
                    />
                ) : null}
            </div>

            {!!show ? <TestModal show={show} hideModal={hideModal} /> : null}
            {!!showInvalidFormModal ? (
                <InvalidFormModal hideModal={hideInvalidDataModal} />
            ) : null}
            {!!showAddApiKeysModal ? (
                <AddToApiKeyModal
                    configurationDID={verifier?.vmethodId}
                    configurationId={verifier?.id || ''}
                    hideModal={hideAddApiKeyModal}
                    show={true}
                    updateApiKeys={addConfigurationToApiKeys}
                />
            ) : null}
        </>
    )
}

export default ConfigVerifier
