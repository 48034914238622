import { t } from 'i18next'
import moment from 'moment'
import { Selectable } from '../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import { initialLang, supportedLngs } from '../i18n'
import { NumberFormatOptions } from '../interfaces/interfaces'
import base64url from 'base64url'
import { gzip, ungzip } from 'pako'
import { Buffer } from 'buffer'

/* Cache the searchable properties.
 * This avoid to loop between properties to search, translate and latinize with every keypress.
 * TODO: It would be interesting add fuzzy search as an option
 */

export function getSearchableStringfiedOptions(
    options: any[],
    propertiesToSearch: any[]
) {
    const searchableOptions = [...options]

    for (const option of searchableOptions) {
        let formatedOption = ''
        for (const property of propertiesToSearch) {
            if (property.searchMultipleLanguages && property.latinize) {
                for (const lang of supportedLngs) {
                    const translatedLatinizedProperty = latinize(
                        option[property?.text]?.[lang]
                    )
                    formatedOption = translatedLatinizedProperty
                        ? formatedOption + '^' + translatedLatinizedProperty
                        : formatedOption
                }
            } else {
                if (property.latinize) {
                    const latinizedProperty = latinize(option[property.text])
                    formatedOption = latinizedProperty
                        ? formatedOption + '^' + latinizedProperty
                        : formatedOption
                } else {
                    const optionStringedProperty =
                        option[property?.text?.toString()]
                    formatedOption = !!optionStringedProperty
                        ? formatedOption + '^' + optionStringedProperty
                        : formatedOption
                }
            }
            option._searchableText = formatedOption
        }
    }
    return searchableOptions
}

export const getSimpleSearch = (list: string[], searchedValue: string) => {
    return list.filter((value) =>
        value.toLowerCase().includes(searchedValue.toLowerCase())
    )
}

export const getComplexSearch = (
    list: Selectable[],
    searchedValue: string,
    wantedProps?: string[]
) => {
    return list.filter((item: Selectable) => {
        if (wantedProps?.length) {
            return wantedProps.some((p) =>
                item.value[p]?.includes(searchedValue)
            )
        }
        return item.value.includes(searchedValue)
    })
}

/* Latinize, trim and lowercase string to compare values */
export function latinize(value) {
    if (value && value?.length) {
        let formattedvalue = value?.trim().toLowerCase()

        formattedvalue = formattedvalue.replace(new RegExp(/[àáâãäå]/g), 'a')
        formattedvalue = formattedvalue.replace(new RegExp(/[èéêë]/g), 'e')
        formattedvalue = formattedvalue.replace(new RegExp(/[ìíîï]/g), 'i')
        formattedvalue = formattedvalue.replace(new RegExp(/ñ/g), 'ny')
        formattedvalue = formattedvalue.replace(new RegExp(/[òóôõö]/g), 'o')
        formattedvalue = formattedvalue.replace(new RegExp(/[ùúûü]/g), 'u')
        formattedvalue = formattedvalue.replace(new RegExp(/[ýÿ]/g), 'y')

        return formattedvalue
    }
}

export function validateEmail(value: string) {
    var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return !!value?.match(validRegex)
}

/* Transform an value of type string[] to string joined width "," & "and"
 * Use translation for each array item.
 */
export function stringfyAsList(list: string[]) {
    if (list?.length > 1) {
        const lastItem = list.pop()
        let stringedData = list?.toString()
        let joinedData = stringedData.replace(/,[s]*/g, ', ')
        joinedData = list + ' ' + t('public.and') + ' ' + lastItem

        return joinedData
    } else {
        return t(list[0])
    }
}

/*
 * Return a sortened value with the center character replaced by '..."
 */
export const shortValue = (
    value: string,
    initialCharQuantity: number,
    finalCharQuantity: number
) => {
    const firstCharacters = value?.slice(0, initialCharQuantity)
    const lastCharacters = value?.slice(-finalCharQuantity)

    return firstCharacters + '...' + lastCharacters
}

/*
 * Return a dd/mm/yyyy formatted date '..."
 */
export const formatDate = (date) => {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/')
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0')
}

/*
 * Return a dd/mm/yyyy formatted date from an string
 * Format examples: 'DD-MM-YYYY' and "DD/MM/YYYY - HH:mm:ss"
 */
export const formatStringDate = (date: string, format?: string) => {
    const stringToDate = new Date(date)
    return moment(stringToDate).format(format ? format : 'DD-MM-YYYY')
}

/**
 * Returns the value of the selected language from an object with multiple translations
 */
export const getPropertyByLang = (property: string) => {
    return t(property[initialLang] ? property[initialLang] : property['en'])
}

/**
 * Returns the value of the property from an object greater than 1000 with K, M, B, etc, dominations
 */

export const formatNumberToCmpct = (
    number: number,
    options?: NumberFormatOptions,
    location?: string
) => {
    const usformatter = Intl.NumberFormat(location || 'en-US', options)
    return usformatter.format(number)
}

/**
 * Returns an string trimed with an centered ellipsis according to qtty of characters to show
 */

export const centerEllipsisTrim = (
    text?: string,
    maxLength?: number,
    elipsis?: string
) => {
    if (!text) return text
    if (!maxLength || maxLength < 1) return text
    if (text.length <= maxLength) return text
    if (maxLength == 1)
        return text.substring(0, 1) + elipsis ? elipsis : '........'

    var midpoint = Math.ceil(text.length / 2)
    var toremove = text.length - maxLength
    var lstrip = Math.ceil(toremove / 2)
    var rstrip = toremove - lstrip
    return (
        text.substring(0, midpoint - lstrip) +
        '........' +
        text.substring(midpoint + rstrip)
    )
}

/**
 * Returns an string trimed with an end ellipsis according to qtty of characters to show
 */

export const endEllipsisTrim = (text?: string, maxLength?: number) => {
    if (!text) return text
    if (!maxLength || maxLength < 1) return text
    if (text.length <= maxLength) return text
    if (maxLength == 1) return text.substring(0, 1) + '...'

    return text.substring(0, maxLength) + '...'
}

// Get type functions
export const isArray = (item: any) => !!Array.isArray(item)

export const isString = (item: any) => typeof item === 'string'

export const isStringOrNumber = (item: any) =>
    typeof item === 'string' || typeof item === 'number'

export const isNumber = (item: any) =>
    typeof item === 'number' && !Number.isNaN(item)

export const isObject = (item: any) => {
    for (let keys in item) {
        if (typeof item[keys] === 'object' && item[keys] !== null) {
            return true
        } else {
            return (
                item.toString() === '[object Object]' ||
                typeof item === 'object'
            )
        }
    }
}

export const isBoolean = (val: any) => {
    return val === false || val === true
}

// Get plain values from an array
export const plainValues = (values?: any[]) =>
    values &&
    values?.filter((el: any) => !(isObject(el.value) || isArray(el.value)))

// Decode
const byteBits: number = 8
const oneBit: number = 0x1
const allOneBits: number = 0xff
// @ts-ignore
window.Buffer = Buffer
export class BitString {
    static async decode(src: string): Promise<Uint8Array> {
        const decoded = Uint8Array.from(Buffer.from(src, 'base64'))
        const decompressed = ungzip(decoded)
        return decompressed
    }

    static async encode(src: Uint8Array): Promise<string> {
        let compressed = gzip(src)
        let encoded = base64url.encode(compressed)
        return encoded
    }

    static bitAt(bitString: Uint8Array, idx: number): boolean {
        const byteNb = Math.trunc(idx / byteBits)

        if (idx < 0 || byteNb >= bitString.length) {
            // console.log('index outside of bytestring', this)
        }

        const nBit = idx % byteBits
        return (bitString[byteNb] & (oneBit << nBit)) != 0
    }

    static setBitAt(bitString: Uint8Array, idx: number, value: boolean) {
        const byteNb = Math.trunc(idx / byteBits)

        if (idx < 0 || byteNb >= bitString.length) {
            // console.log('index outside of bytestring', this)
        }

        const nBit = idx % byteBits
        if (value) {
            bitString[byteNb] = bitString[byteNb] | (oneBit << nBit)
        } else {
            const inverted = allOneBits ^ (oneBit << nBit)
            bitString[byteNb] = bitString[byteNb] & inverted
        }
    }
}

/* Regroup objects of an array by repeated property
 * Ex.: [propertyToCheck, propertyToGroupRepeated: [repeatedItem, repeatedItem]]
 */
export const regroupByProperty = (
    items: any[],
    propertyToCheck: string,
    propertyToGroupRepeated: string
) => {
    const reducedItems = items?.reduce((acc, curr) => {
        if (!acc[curr[propertyToCheck]]) {
            acc[curr[propertyToCheck]] = new Set()
        }

        acc[curr[propertyToCheck]].add(curr)

        return acc
    }, {})

    let result = Object.entries(reducedItems).map((el) => ({
        [propertyToCheck]: el[0],
        //@ts-ignore (necessary because we need to clone full objects)
        [propertyToGroupRepeated]: [...el[1]],
    }))

    return result
}
