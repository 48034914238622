import { Epic, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, delay, map, mergeMap, switchMap } from 'rxjs/operators'
import dataService from '../../../../../../services/dataService'
import { showNotification } from '../../../../Notification/notificationActions'
import {
    createRoleFailed,
    createRoleSuccess,
    deleteRoleFailed,
    getRoles,
    getRolesFailed,
    getRolesSuccess,
    getRoleSuccess,
    getScopesFailed,
    getScopesSuccess,
    updateRoleFailed,
    updateRoleSuccess,
} from './rolesActions'
import { RolesActionTypes } from './rolesTypes'

export const getScopesEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(RolesActionTypes.GET_SCOPES),
        delay(100),
        switchMap((): any => {
            return from(dataService.getScopes()).pipe(
                map((response?: any) => {
                    // console.log(response)
                    return getScopesSuccess(response?.response)
                }),
                catchError((error) =>
                    of(
                        getScopesFailed(error),
                        showNotification(
                            'roles.getRolesError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const getRolesEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(RolesActionTypes.GET_ROLES),
        delay(100),
        switchMap((): any => {
            return from(dataService.getRoles()).pipe(
                map((response?: any) => {
                    // console.log(response)
                    return getRolesSuccess(response?.response)
                }),
                catchError((error) =>
                    of(
                        getRolesFailed(error),
                        showNotification(
                            'roles.getRolesError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const getRoleEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(RolesActionTypes.GET_ROLE),
        delay(100),
        switchMap((action: any): any => {
            let found = state$?.value?.roles?.find(
                (role) => role.Id === action.payload.roleId
            )
            if (found) {
                return getRoleSuccess(found)
            }
            return from(dataService.getRole(action.payload.roleId)).pipe(
                map((response?: any) => {
                    // console.log(response)
                    return getRoleSuccess(response?.response)
                }),
                catchError((error) =>
                    of(
                        getRolesFailed(error),
                        showNotification(
                            'roles.getRolesError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const createRoleEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(RolesActionTypes.CREATE_ROLE),
        switchMap((action: any): any => {
            const role = action.payload.role
            return from(dataService.createRole(role)).pipe(
                map((response?: any) => {
                    return createRoleSuccess()
                }),
                catchError((error) =>
                    of(createRoleFailed(error?.response?.message))
                )
            )
        })
    )
}

export const deleteRoleEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(RolesActionTypes.DELETE_ROLE),
        switchMap((action: any): any => {
            const roleID = action.payload.roleID

            return from(dataService.deleteRole(roleID)).pipe(
                map((response?: any) => {
                    return getRoles()
                }),
                catchError((error) =>
                    of(deleteRoleFailed(error?.response?.message))
                )
            )
        })
    )
}

export const updateRoleEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(RolesActionTypes.UPDATE_ROLE),
        switchMap((action: any): any => {
            const role = action.payload.role
            return from(dataService.updateRole(role, role.role_id)).pipe(
                mergeMap((response?: any) => {
                    return of(updateRoleSuccess())
                }),
                catchError((error) =>
                    of(updateRoleFailed(error?.response?.message))
                )
            )
        })
    )
}
