import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { rolesSelectors } from '../../store'
import { ActionStatusTypes } from '../../../../../../../assets/globalConstants'
import StatusPanel from '../../../../../../../Components/atoms/panels/StatusPanel/StatusPanel'
import { IRoleModel } from '../../../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../../../utils/permissionsUtil'
import { setSelectedRoute } from '../../../../../PanelScafolding/scafoldingActions'
import { sessionSelectors } from '../../../../../session'
import { scopeScopes } from '../../../users/store/team.constants'

import {
    clearActionStatus,
    clearRole,
    clearRoleList,
    createRole,
    getScopes,
    updateRole,
} from '../../store/rolesActions'
import styles from '../../views/roles.module.scss'
import UpsertForm from '../forms/upsertForm/UpsertForm'

type IUpsertRoleProps = {
    isNewRol?: boolean
    hideCreateRoleModal: (x?) => void
}

const UpsertRole: React.FC<IUpsertRoleProps> = (props) => {
    const { isNewRol, hideCreateRoleModal } = props
    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const selectedRole = useSelector(rolesSelectors.getSelectedRole)
    const createStatus = useSelector(rolesSelectors.getStatus)
    const creationError = useSelector(rolesSelectors.getError)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const scopes = useSelector(rolesSelectors.getScopesSelectables)
    const canReadScopes = !!(
        availableScopes && userHasScopes(scopeScopes?.read, availableScopes)
    )
    useEffect(() => {
        canReadScopes && dispatch(getScopes())
    }, [])

    useEffect(() => {}, [scopes, scopes?.length])

    const createOrUpdateRole = (role: IRoleModel) => {
        if (selectedRole) {
            dispatch(updateRole(role))
        } else {
            dispatch(createRole(role))
        }
    }

    const handleCloseModal = () => dispatch(clearActionStatus())

    const navigate = (route) => {
        dispatch(setSelectedRoute(route)), history.push(route)
    }
    const goToHome = () => {
        clearState(), navigate('home')
    }
    const goToUsersList = () => {
        clearState(), navigate('team')
    }

    const clearState = () => {
        dispatch(clearActionStatus())
        dispatch(clearRoleList())
        dispatch(clearRole())
    }

    // const getAllScopes = (): Selectable[] => {
    //     return (
    //         scopes?.map((sc) => {
    //             return { key: sc, value: sc }
    //         }) || []
    //     )
    // }
    // let allScopes = getAllScopes()

    return (
        <>
            <div>
                <UpsertForm
                    isNewRol={isNewRol || false}
                    buttonFunction={createOrUpdateRole}
                    currentRole={selectedRole}
                    allScopes={scopes}
                    closeModal={hideCreateRoleModal}
                />
            </div>

            <StatusPanel
                className={styles.rolesStatusPanel}
                successMessage={''}
                state={'roles.isReady'}
                operationStatus={createStatus}
                show={
                    createStatus &&
                    (createStatus as ActionStatusTypes) !==
                        ActionStatusTypes.neutral
                }
                failedText={
                    t('createUser.failed') + (creationError ? ': ' : '.')
                }
                error={creationError || ''}
                primaryButton={{
                    label: 'createUser.backToList',
                    function: goToUsersList,
                }}
                secondaryButton={{
                    label: 'createUser.backToHome',
                    function: goToHome,
                }}
                handleClose={handleCloseModal}
            />
        </>
    )
}

export default UpsertRole
