import { ActionStatusTypes } from '../../../../../../assets/globalConstants'
import { IUserItem, UserProfile } from '../../../../../../interfaces/interfaces'
import { Iloading } from '../../../../Login/loginTypes'

export interface IGetUsersAction {
    type: UsersActionTypes.GET_USERS
}

export interface IGetUsersSuccessAction {
    type: UsersActionTypes.GET_USERS_SUCCESS
    payload: {
        users: IUserItem[]
        tenants?: string
    }
}

export interface IGetUsersFailedAction {
    type: UsersActionTypes.GET_USERS_FAILED
    payload: IUserItem[]
}

export interface IGetUserProfileAction {
    type: UsersActionTypes.GET_USER_PROFILE
    payload: {
        email: string
    }
}

export interface IGetUserProfileSuccessAction {
    type: UsersActionTypes.GET_USER_PROFILE_SUCCESS
    payload: {
        profile: UserProfile
    }
}

export interface IGetUserProfileFailedAction {
    type: UsersActionTypes.GET_USER_PROFILE_FAILED
    payload: {
        message: string
    }
}

export interface ICreateUserAction {
    type: UsersActionTypes.CREATE_USER
    payload: {
        user: IUserItem
        register: boolean
    }
}

export interface ICreateUserSuccessAction {
    type: UsersActionTypes.CREATE_USER_SUCCESS
    payload: {
        message: string
    }
}

export interface ICreateUserFailedAction {
    type: UsersActionTypes.CREATE_USER_FAILED
    payload: {
        error: string
    }
}

export interface IGetRegistrationTokenAction {
    type: UsersActionTypes.GET_REGISTRATION_TOKEN
    payload: {
        user: IUserItem
        role?: string
        tenant?: string
    }
}

export interface IGetRegistrationTokenSuccessAction {
    type: UsersActionTypes.GET_REGISTRATION_TOKEN_SUCCESS
    payload: {
        message: string
    }
}

export interface IDeleteUserAction {
    type: UsersActionTypes.DELETE_USER
    payload: {
        userID: string
    }
}

export interface IDeleteUserSuccessAction {
    type: UsersActionTypes.DELETE_USER_SUCCESS
}

export interface IDeleteUserFailedAction {
    type: UsersActionTypes.DELETE_USER_FAILED
}

export interface ISelectUserAction {
    type: UsersActionTypes.SELECT_USER
    payload: {
        user?: IUserItem
    }
}

export interface ICloseUserDetailAction {
    type: UsersActionTypes.CLOSE_USER_DETAIL
}

export interface IUpdateUserAction {
    type: UsersActionTypes.UPDATE_USER
    payload: {
        user: IUserItem
        email: string
    }
}

export interface IUpdateUserSuccessAction {
    type: UsersActionTypes.UPDATE_USER_SUCCESS
}

export interface IUpdateUserFailedAction {
    type: UsersActionTypes.UPDATE_USER_FAILED
    payload: {
        error?: string
    }
}

export interface IUpdateUserProfileAction {
    type: UsersActionTypes.UPDATE_USER_PROFILE
    payload: {
        profile: UserProfile
    }
}

export interface IUpdateUserProfileSuccessAction {
    type: UsersActionTypes.UPDATE_USER_PROFILE_SUCCESS
    payload: {
        profile: UserProfile
    }
}

export interface IUpdateUserProfileFailedAction {
    type: UsersActionTypes.UPDATE_USER_PROFILE_FAILED
    payload: {
        error?: string
    }
}

export interface ICheckUsersAction {
    type: UsersActionTypes.CHECK_USERS
}

export interface IChangePasswordSubmit {
    type: UsersActionTypes.CHANGE_PASSWORD_SUBMIT
    payload: {
        id?: string | number
        oldPassword: string
        newPassword: string
    }
}

export interface IChangePassword_Success {
    type: UsersActionTypes.CHANGE_PASSWORD_SUCCESS
}

export interface IChangePassword_Error {
    type: UsersActionTypes.CHANGE_PASSWORD_ERROR
    error: string
}

export interface IClearActionStatus {
    type: UsersActionTypes.CLEAR_ACTION_STATUS
}

export interface ClearUserList {
    type: UsersActionTypes.CLEAR_USER_LIST
}

export interface IClearUser {
    type: UsersActionTypes.CLEAR_CURRENT_USER
}

export type UsersAction =
    | IGetUsersAction
    | IGetUsersSuccessAction
    | IGetUsersFailedAction
    | ICreateUserAction
    | ICreateUserSuccessAction
    | ICreateUserFailedAction
    | IGetRegistrationTokenAction
    | IDeleteUserAction
    | IDeleteUserSuccessAction
    | IDeleteUserFailedAction
    | ISelectUserAction
    | ICloseUserDetailAction
    | IUpdateUserAction
    | IUpdateUserSuccessAction
    | IUpdateUserFailedAction
    | ICheckUsersAction
    | IChangePasswordSubmit
    | IChangePassword_Success
    | IChangePassword_Error
    | IClearActionStatus
    | ClearUserList
    | IClearUser
    | IGetUserProfileAction
    | IGetUserProfileSuccessAction
    | IGetUserProfileFailedAction
    | IUpdateUserProfileAction
    | IUpdateUserProfileSuccessAction
    | IUpdateUserProfileFailedAction

export interface IChangeUserPasswords {
    id: string
    userId: string
    newPasswords: string
    oldPasswords: string
}

export interface IUsersState {
    users: IUserItem[]
    // TODO, change to IUSerItem
    selectedUser?: IUserItem
    showUserDetail: boolean
    loadingStatus: boolean
    changePassword: {
        loading: Iloading
    }
    tenant?: string
    status: ActionStatusTypes
    error: string
    profile?: UserProfile
}

export enum UsersActionTypes {
    GET_USERS = 'users/get_users',
    GET_USERS_SUCCESS = 'users/get_users_success',
    GET_USERS_FAILED = 'users/get_users_failed',
    GET_USER_PROFILE = 'users/get_profile',
    GET_USER_PROFILE_SUCCESS = 'users/get_profile_success',
    GET_USER_PROFILE_FAILED = 'users/get_profile_failed',
    CREATE_USER = 'users/create',
    CREATE_USER_SUCCESS = 'users/create_user_success',
    CREATE_USER_FAILED = 'users/create_user_failed',
    CHECK_USERS = 'users/status',
    GET_REGISTRATION_TOKEN = 'users/get_registration_token',
    GET_REGISTRATION_TOKEN_SUCCESS = 'users/get_registration_token_success',
    DELETE_USER = 'users/delete',
    DELETE_USER_SUCCESS = 'users/delete_success',
    DELETE_USER_FAILED = 'users/delete_failed',
    SELECT_USER = 'users/select',
    CLOSE_USER_DETAIL = 'users/close_user_detail',
    UPDATE_USER = 'users/update',
    UPDATE_USER_SUCCESS = 'users/update_user_success',
    UPDATE_USER_FAILED = 'users/update_user_failed',
    UPDATE_USER_PROFILE = 'users/update_profile',
    UPDATE_USER_PROFILE_SUCCESS = 'users/update_profile_success',
    UPDATE_USER_PROFILE_FAILED = 'users/update_profile_failed',
    CHANGE_PASSWORD_SUBMIT = 'changePasswordSubmit',
    CHANGE_PASSWORD_ERROR = 'changePasswordError',
    CHANGE_PASSWORD_SUCCESS = 'changePasswordSuccess',
    CLEAR_ACTION_STATUS = 'users/clear_action_status',
    CLEAR_USER_LIST = 'users/clear_users_list',
    CLEAR_CURRENT_USER = 'users/clear_current_user',
}

export interface ClearUserList {
    type: UsersActionTypes.CLEAR_USER_LIST
}

export interface IClearUser {
    type: UsersActionTypes.CLEAR_CURRENT_USER
}
