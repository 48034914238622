import {
    CredentialBase,
    CredentialFormat,
    CredentialStatusType,
    CredentialStatusWrapper,
    JwtCredential,
    VerifiableCredential,
    credentialStatusTypes,
} from '../data/requestCredentials.interfaces'
import { translateCatalog } from './formatCredUtil'
import { CredentialStatusList2017 } from './CredentialStatusWrappers/CredentialStatus2017'
import { CredentialStatusList2020 } from './CredentialStatusWrappers/CredentialStatus2020'
import { CredentialStatusList2021 } from './CredentialStatusWrappers/CredentialStatus2021'
import { initialLang } from '../../../../i18n'

const statusResolvers: Record<CredentialStatusType, CredentialStatusWrapper> = {
    CredentialStatusList2017: CredentialStatusList2017.getInstance(),
    RevocationList2020Status: CredentialStatusList2020.getInstance(),
    StatusList2021Entry: CredentialStatusList2021.getInstance(),
}

export const getType = (credential: VerifiableCredential): string => {
    let bs: CredentialBase = getBaseCredential(credential)
    return bestType(bs?.type)
}

//The most definining type is the last one
export const bestType = (types: string[]): string => {
    let type = ''
    if (types && types !== null && types.length > 1) {
        type = types[types.length - 1]
    }
    return type
}

export const getBaseCredential = (
    cred: VerifiableCredential
): CredentialBase => {
    let vc: CredentialBase = isJwt(cred)
        ? (cred as JwtCredential).vc
        : (cred as CredentialBase)
    return vc
}

export const isJwt = (cred: VerifiableCredential): boolean => {
    return typeof cred === 'string' || getFormat(cred) === 'jwt_vc'
}

const getFormat = (cred: VerifiableCredential): CredentialFormat =>
    cred && (cred as JwtCredential)?.vc ? 'jwt_vc' : 'ldp_vc'

export const getAllContents = (
    credential: VerifiableCredential
): { dataType?: string; value?: string }[] => {
    let content: any[] = []
    let bs: CredentialBase = getBaseCredential(credential)
    for (const [key, value] of Object.entries(bs?.credentialSubject)) {
        if (key !== 'id') {
            // @ts-ignore
            content.push({
                dataType: key,
                value:
                    typeof value === 'object' ? JSON.stringify(value) : value,
            })
        }
    }
    return content
}

export const getVerifiableCredentialName = async (
    cred: VerifiableCredential,
    types
): Promise<string> => await getCredentialName(getType(cred), types)

export const getCredentialName = async (
    type: string,
    types
): Promise<string> => {
    let c = types?.filter((c) => {
        return c.id === type
    })

    return c?.length > 0
        ? translateCatalog(c[0].name)
        : type.split('Credential')[0]
}

export const getCredentialSubjects = (selectedCredentials: any) => {
    let credential = {}
    selectedCredentials &&
        selectedCredentials
            ?.map((vc: any) => vc?.credentialSubject)
            ?.forEach((subj: any) => (credential = { ...credential, ...subj }))
    return credential
}

export const getStatus = async (vc: VerifiableCredential) => {
    let status = '-'
    status = (await getRemoteStatusByCredType(vc)) as string
    return status
}

export const getRemoteStatusByCredType = async (
    vc: VerifiableCredential
): Promise<credentialStatusTypes> => {
    try {
        let bc = getBaseCredential(vc)
        if (bc && bc.credentialStatus) {
            let type = bc.credentialStatus?.type
            let imp: CredentialStatusWrapper | undefined = statusResolvers[type]
            if (!imp) {
                imp = Object.values(statusResolvers).find((sr) =>
                    sr.apply(type)
                )
            }
            if (!imp) {
                // console.log('[Credential Manager] unknown status protocol')
                return 'INVALID'
            }
            // console.log('GOT IMP', imp.getType());
            return await imp.resolve(bc.credentialStatus, bc.id)
        } else {
            return 'ISSUED'
        }
    } catch (err) {
        // console.info('[Credential Manager] getRemoteStatus', err)
        return 'INVALID'
    }
}

export const getRemoteStatus = async (statusURL: string): Promise<any> => {
    try {
        // console.log('[Connector] Get Remote Status: ' + statusURL);
        let response = await fetch(statusURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        let vs = await response.json()
        // console.info('[Connector] VerifiableStatus: ', vs)

        return vs
    } catch (error) {
        // console.info('[Connector] Get Remote Status: ERROR => ', error)
        return error
    }
}

export const resolveResource = async (uri: string): Promise<any> => {
    try {
        let response = await fetch(uri, {
            headers: {
                Accept: 'application/json',
                'Accept-Language': initialLang,
            },
        })
        if (response.status >= 400) {
            // console.info(
            //     '[Connector]',
            //     'Unable to resolve resource',
            //     uri,
            //     response.status
            // )
        }
        try {
            let responseJson = await response.json()
            // console.info(
            //     '[Connector]',
            //     'Resolve resource',
            //     JSON.stringify(responseJson)
            // )
            return responseJson
        } catch (err) {
            let responseString = await response.text()
            // console.info(
            //     '[Connector]',
            //     'Resolve resource as string',
            //     responseString
            // )
            return responseString
        }
    } catch (err) {
        // console.info('[Connector]', 'Unable to resolve resource', uri, err)
    }
}
