import {
    CreateOrgPayloadModel,
    DataRequestModel,
    OrganizationModel,
} from '../../../interfaces/interfaces'
import {
    ICreateOrg,
    ICreateOrgFailed,
    ICreateOrgSuccess,
    ICreateOrgToRequestSub,
    ICreateRequestOrgSuccess,
    ICreateStarterOrg,
    ICreateStarterOrgSuccess,
    IDeleteOrg,
    IDeleteOrgFailed,
    IDeleteOrgSuccess,
    ILoadOrgFailed,
    ILoadOrgSuccess,
    ISubmitOrg,
    IUpdateOrg,
    IUpdateOrgFailed,
    IUpdateOrgNoPayment,
    IUpdateOrgSuccess,
    OrganizationTypes,
} from './organizationTypes'

export const getOrgAction = (): ISubmitOrg => {
    // console.log('LOAD ORG ACTION')
    return {
        type: OrganizationTypes.LOAD_ORG,
    }
}

export const loadOrgAction = (
    org: OrganizationModel,
    termsAreAccepted?: boolean
): ILoadOrgSuccess => {
    // console.log('LOAD ORG ACTION => ', org)
    return {
        type: OrganizationTypes.LOAD_ORG_SUCCESS,
        org: org,
        termsAreAccepted: termsAreAccepted,
    }
}

export const loadOrgFailedAction = (): ILoadOrgFailed => {
    return {
        type: OrganizationTypes.LOAD_ORG_FAILED,
    }
}

export const updateOrgAction = (org: OrganizationModel): IUpdateOrg => {
    return {
        type: OrganizationTypes.UPDATE_ORG,
        org: org,
    }
}

export const updateOrgNoPaymentAction = (
    org: OrganizationModel
): IUpdateOrgNoPayment => {
    return {
        type: OrganizationTypes.UPDATE_ORG_NO_PAYMENT,
        org: org,
    }
}

export const updateOrgActionSuccess = (
    org: OrganizationModel
): IUpdateOrgSuccess => {
    return {
        type: OrganizationTypes.UPDATE_ORG_SUCCESS,
        org: org,
    }
}

export const updateOrgActionFailed = (): IUpdateOrgFailed => {
    return {
        type: OrganizationTypes.UPDATE_ORG_FAILED,
    }
}

export const createOrgAction = (
    org: CreateOrgPayloadModel,
    isStarter?: boolean
): ICreateOrg => {
    return {
        type: OrganizationTypes.CREATE_ORG,
        payload: {
            org: org,
            isStarter: isStarter,
        },
    }
}

export const createStarterOrgAction = (
    org: CreateOrgPayloadModel
): ICreateStarterOrg => {
    return {
        type: OrganizationTypes.CREATE_STARTER_ORG,
        payload: {
            org: org,
        },
    }
}

export const createOrgToRequestSubAction = (
    org: CreateOrgPayloadModel,
    data: DataRequestModel
): ICreateOrgToRequestSub => {
    return {
        type: OrganizationTypes.CREATE_ORG_TO_REQUEST_SUB,
        payload: {
            org,
            data,
        },
    }
}

export const createOrgSuccessAction = (
    org: OrganizationModel,
    isStarter?: boolean
): ICreateOrgSuccess => {
    return {
        type: OrganizationTypes.CREATE_ORG_SUCCESS,
        payload: {
            org: org,
            isStarter: isStarter,
        },
    }
}

export const createStarterOrgSuccessAction = (
    org: OrganizationModel
): ICreateStarterOrgSuccess => {
    return {
        type: OrganizationTypes.CREATE_STARTER_ORG_SUCCESS,
        payload: {
            org: org,
        },
    }
}

export const createOrgForRequestSuccessAction = (
    org: OrganizationModel
): ICreateRequestOrgSuccess => {
    return {
        type: OrganizationTypes.CREATE_REQUEST_ORG_SUCCESS,
        payload: {
            org: org,
        },
    }
}

export const createOrgFailedAction = (err: string): ICreateOrgFailed => {
    return {
        type: OrganizationTypes.CREATE_ORG_FAILED,
        payload: {
            error: err,
        },
    }
}

export const deleteOrgAction = (orgId: string): IDeleteOrg => {
    return {
        type: OrganizationTypes.DELETE_ORG,
        orgId: orgId,
    }
}

export const deleteOrgActionSuccess = (): IDeleteOrgSuccess => {
    return {
        type: OrganizationTypes.DELETE_ORG_SUCCESS,
    }
}

export const deleteOrgActionFailed = (): IDeleteOrgFailed => {
    return {
        type: OrganizationTypes.DELETE_ORG_FAILED,
    }
}
