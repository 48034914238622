import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    CredentialIcons,
    gaImages,
    purposesTypesList,
} from '../../../../../assets/globalConstants'
import { initialLang } from '../../../../../i18n'
import {
    DA_Purpose,
    ICredentialRequestedModel,
} from '../../../../../interfaces/interfaces'
import style from './requirementAndPurposeCard.module.scss'
import { switchCredentialsRequirements } from '../../../../../features/common/PanelScafolding/private/panelScafoldingPrivateConstants'
import SwitchButton from '../../../../atoms/buttons/SwitchButton/SwicthButton'
import GaSelect from '../../../../atoms/formFields/select/GaSelect'

type IRequirementAndPurposeCardProps = {
    credential: ICredentialRequestedModel
    indexCheckbox?: string | number | undefined
    dataAgreementChecked?: boolean
    onChangeSwitchSelect?: (index: number, value: string) => void
    handleSelectChange?: (e: number, y: string) => void
    checkedValue: string
    index: number
    purposesList?: DA_Purpose[] | null
    selectValue?: string
}

const RequirementAndPurposeCard: React.FC<IRequirementAndPurposeCardProps> = (
    props
) => {
    const {
        credential,
        indexCheckbox,
        dataAgreementChecked,
        onChangeSwitchSelect,
        handleSelectChange,
        checkedValue,
        index,
        purposesList,
        selectValue,
    } = props
    const { t } = useTranslation()

    return (
        <div
            className={`${style.cardContainer}`}
            key={credential?.id}
            id={credential?.id}
        >
            <div className={style.card}>
                <div className={style.card__requirements}>
                    <p className={cx('buttonSM neutral1000', style.card__name)}>
                        <img
                            className={style.card__name__icon}
                            src={
                                CredentialIcons[
                                    credential?.groupList
                                        ? credential?.groupList[0]
                                        : 'credential'
                                ] || CredentialIcons?.credential
                            }
                            alt={t(gaImages.credential.alt)}
                        />
                        {t(
                            credential.name[initialLang]
                                ? credential.name[initialLang]
                                : credential.name?.en || ''
                        )}
                    </p>
                    <div className={style.card__requirementOptions}>
                        <SwitchButton
                            checkedValue={checkedValue}
                            options={switchCredentialsRequirements}
                            onChangeSwitchSelect={(value) =>
                                onChangeSwitchSelect &&
                                onChangeSwitchSelect(index, value)
                            }
                            className={style.switchButton}
                            nameSwitchOption={'option__' + indexCheckbox}
                        />
                    </div>
                </div>
                {dataAgreementChecked ? (
                    <>
                        <div className={style.card__purposes}>
                            <GaSelect
                                idSelect={'purposeList'}
                                value={selectValue}
                                name={selectValue}
                                isLoading={!purposesList?.length}
                                labelText={t('editVerifier.step3.purpose')}
                                onChange={(event) => {
                                    const purposeValue = event?.target?.value
                                    handleSelectChange &&
                                        handleSelectChange(index, purposeValue)
                                }}
                                optionTextProperty={'name'}
                                optionValueProperty={'id'}
                                options={purposesList || []}
                                required
                            />
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    )
}

export default RequirementAndPurposeCard
