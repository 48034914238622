import cx from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import GaSelect from '../../../../atoms/formFields/select/GaSelect'
import GaTextInput from '../../../../atoms/formFields/textInput/GaTextInput'
import MediumModal from '../../../../molecules/modals/Medium/MediumModal'
import { onPremise } from '../../../../../data/globalVar'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import style from './addAccountModal.module.scss'
import {
    didMethodOptions,
    gaImages,
} from '../../../../../assets/globalConstants'
import {
    clearDid,
    createDid,
    createFirstDid,
} from '../../../../../features/studio/Dids/didActions'
import WarningPanel from '../../../notification/panels/warning/warningPanel'

type IAddAccountModalProps = {
    show: boolean
    hideModal: () => void
    dids: IDIDModel[] | undefined
}

const AddAccountModal: React.FC<IAddAccountModalProps> = (props) => {
    const { hideModal, show, dids } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    // States
    const initialFormState = {
        alias: '',
        didMethod: didMethodOptions[0]?.value,
        ebsiToken: '',
    }

    const [formData, setFormState] = useState(initialFormState)
    const { alias, didMethod, ebsiToken } = formData

    // Consts
    const selectedEbsiMethod = didMethod === 'ebsi'

    const validForm = !!(
        alias?.trim() &&
        didMethod?.trim()?.length &&
        (!selectedEbsiMethod || ebsiToken?.trim()?.length)
    )

    // Actions
    const saveDid = () => {
        // Just in cloud for now
        dispatch(
            dids?.length && !!dids[0]
                ? createDid({ didMethod, alias, ebsiToken })
                : !onPremise && createFirstDid({ didMethod, alias, ebsiToken })
        )
        hideModalAndClean()
    }

    const hideModalAndClean = () => {
        dispatch(clearDid()), setFormState(initialFormState)
        hideModal()
    }

    return (
        <MediumModal
            title={'ew.addAccount'}
            subtitle={'ew.addAccountDesc'}
            headerClose={true}
            show={show}
            primaryButton={{
                label: 'public.create',
            }}
            primaryButtonDisabled={!validForm}
            secondaryButton={{
                label: 'public.cancel',
                function: hideModalAndClean,
            }}
            primaryButtonFunction={saveDid}
            handleClose={hideModalAndClean}
            className={style.addAccountModal}
        >
            <div className={style.content}>
                <form>
                    <GaTextInput
                        id={'alias'}
                        invalid={false}
                        labelText={t('dids.alias')}
                        required
                        name={'alias'}
                        onChange={(e) =>
                            setFormState({ ...formData, alias: e.target.value })
                        }
                        placeholder={t('ew.aliasPlaceholder')}
                        value={alias}
                    />
                    <GaSelect
                        idSelect={'privateKeyType'}
                        className={cx('marginTop24', style.methodInput)}
                        value={didMethod}
                        id="select-privateKeyType"
                        invalid={false}
                        placeholder={t('dids.selectMethod')}
                        labelText={t('dids.selectMethod')}
                        name={'privateKeyType'}
                        optionTextProperty={'text'}
                        optionValueProperty={'value'}
                        onChange={(e) =>
                            setFormState({
                                ...formData,
                                didMethod: e.target.value,
                            })
                        }
                        options={didMethodOptions}
                    />
                    {selectedEbsiMethod ? (
                        <GaTextInput
                            className={cx('marginTop24')}
                            id={'did-ebsi-token'}
                            invalid={false}
                            labelText={t('createDid.step4.token')}
                            required={true}
                            name={'EBSItoken'}
                            onChange={(e) =>
                                setFormState({
                                    ...formData,
                                    ebsiToken: e.target.value,
                                })
                            }
                            placeholder={t('createDid.step4.token')}
                            value={ebsiToken}
                        />
                    ) : null}
                </form>

                <WarningPanel
                    hasIcon={true}
                    icon={gaImages.warning}
                    className={`${style.warningAlert}`}
                    label={t('ew.privateDIDAlert')}
                    hasTitle={false}
                />
            </div>
        </MediumModal>
    )
}

export default AddAccountModal
