import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LightButton from '../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaNumberInput from '../../../../../Components/atoms/formFields/numberInput/GaNumberInput'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import InfoPanel from '../../../../../Components/molecules/notification/panels/info/infoPanel'
import FormSection from '../../../../../Components/organisms/FormSection/FormSection'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import { getSecret } from '../../didActions'
import { getIsSecretLoading } from '../../didSelectors'
import { onPremise } from '../../../../../data/globalVar'
import GaCheckbox from '../../../../../Components/atoms/formFields/checkbox/GaCheckbox'

type ITokenFormProps = {
    currentDid: IDIDModel
    isFinalStep?: boolean
    buttonFunction: (x?, y?, z?) => void
}

const TokenForm: React.FC<ITokenFormProps> = (props) => {
    const { currentDid, isFinalStep, buttonFunction } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isSecretLoading = useSelector(getIsSecretLoading)

    const [formData, setFormState] = useState({
        secret: currentDid?.secret || '',
        sessionTimeout: currentDid?.sessionTimeout || 500,
        tokenByCookie: currentDid?.tokenByCookie || false,
    })
    const { secret, sessionTimeout, tokenByCookie } = formData
    const validForm = !!sessionTimeout

    useEffect(() => {
        setFormState({
            ...formData,
            secret: currentDid?.secret,
            sessionTimeout: currentDid?.sessionTimeout,
            tokenByCookie: currentDid?.tokenByCookie,
        })
    }, [currentDid])

    const onSubmit = (e) => {
        e.preventDefault()
        buttonFunction(formData)
    }

    return (
        <FormSection
            title={'createDid.step3.sectionTitle'}
            description={'createDid.step3.sectionDescription'}
        >
            <form onSubmit={onSubmit}>
                <GaTextInput
                    className={cx('margin-bottom')}
                    id={'aplication-secret'}
                    invalid={false}
                    labelText={t('createDid.step3.aplicationSecretLabel')}
                    required={true}
                    isLoading={isSecretLoading}
                    helperText={t('createDid.step3.aplicationSecretHelperText')}
                    onChange={(e) =>
                        setFormState({ ...formData, secret: e.target.value })
                    }
                    placeholder={t(
                        'createDid.step3.aplicationSecretPlaceholder'
                    )}
                    value={secret || ''}
                />
                <InfoPanel
                    label={t('createDid.step3.generateSecretInfo')}
                    hasIcon={true}
                />

                <LightButton
                    className={cx('margin-top', 'margin-bottom')}
                    text={'createDid.step3.generateSecret'}
                    disabled={false}
                    functionality={() => {
                        // Just in cloud for now
                        !onPremise && dispatch(getSecret())
                    }}
                />

                <GaNumberInput
                    className={cx('margin-bottom')}
                    name={'sessionTimeout-ttl'}
                    invalid={false}
                    label={t('createDid.step3.sessionTTLLabel')}
                    onChange={(e) =>
                        setFormState({
                            ...formData,
                            sessionTimeout: e.target.value,
                        })
                    }
                    value={sessionTimeout}
                />

                <GaCheckbox
                    index={'0'}
                    itemText={t('createDid.step3.tokenByCookie')}
                    id="tokenByCookieCheckbox"
                    defaultChecked={tokenByCookie}
                    onChange={(status) =>
                        setFormState({ ...formData, tokenByCookie: status })
                    }
                />
            </form>

            <LightButton
                className={cx('margin-top-m', 'margin-left-auto')}
                text={isFinalStep ? 'public.finish' : 'public.next'}
                disabled={!validForm}
                functionality={() => {
                    buttonFunction(formData)
                }}
            />
        </FormSection>
    )
}

export default TokenForm
