import React from 'react'

type ArrowLeftDiagonalCircleIconProps = {
    style?: 'default' | 'filled'
    size?: number
    color?: string
    className?: string
}

const ArrowLeftDiagonalCircleIcon: React.FC<ArrowLeftDiagonalCircleIconProps> =
    React.memo((props) => {
        const { size, style, color, className } = props

        return (
            <svg
                className={className && className}
                width={size || '32'}
                height={size || '32'}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {style === 'filled' ? (
                    <FilledIconPaths color={color} />
                ) : (
                    <DeafaultIconPaths color={color} />
                )}
            </svg>
        )
    })

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63619 23.3639 2.66666 16.0001 2.66666C8.63628 2.66666 2.66675 8.63619 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2288 12.2288V19.7712H19.7712"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.7717 12.2287L12.2292 19.7711"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const FilledIconPaths = (props) => {
    return (
        <>
            <g clipPath="url(#clip0_5587_728)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.3333 16C29.3333 23.3638 23.3638 29.3334 16 29.3334C8.63618 29.3334 2.66665 23.3638 2.66665 16C2.66665 8.63624 8.63618 2.6667 16 2.6667C23.3638 2.6667 29.3333 8.63624 29.3333 16ZM12.2288 10.8954C12.9652 10.8954 13.5621 11.4924 13.5621 12.2288V16.5527L18.8289 11.2859C19.3496 10.7652 20.1938 10.7652 20.7145 11.2859C21.2352 11.8066 21.2352 12.6508 20.7145 13.1715L15.4482 18.4379H19.7712C20.5076 18.4379 21.1046 19.0349 21.1046 19.7712C21.1046 20.5076 20.5076 21.1046 19.7712 21.1046L12.2288 21.1046C11.4924 21.1046 10.8954 20.5076 10.8954 19.7712L10.8954 12.2288C10.8954 11.4924 11.4924 10.8954 12.2288 10.8954Z"
                    fill={props?.color || 'currentColor' || '#1E1E20'}
                    stroke={props?.color || 'white'}
                />
            </g>
            <defs>
                <clipPath id="clip0_5587_728">
                    <rect
                        width={props?.size || '32'}
                        height={props?.size || '32'}
                        fill={props?.color || 'currentColor' || '#1E1E20'}
                    />
                </clipPath>
            </defs>
        </>
    )
}

export default ArrowLeftDiagonalCircleIcon
