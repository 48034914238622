import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    ApiKeysActionTypes,
    AppsAction,
    ConfigApiKeyFormActions,
    ConfigApiKeyFormActionsTypes,
    IApiKeysState,
    IConfigApiKeyFormState,
} from './apiKeysTypes'

const emptyApiKey = {
    id: '',
    did: '',
    alias: '',
}

const initialFormState: IConfigApiKeyFormState = {
    steps: [],
    firstStep: null,
    step: null,
    finalStep: null,
    apiKey: emptyApiKey,
}

export function configApiKeyFormReducer(
    state = initialFormState,
    action: ConfigApiKeyFormActions
): IConfigApiKeyFormState {
    switch (action.type) {
        case ConfigApiKeyFormActionsTypes.SETSTEP:
            return {
                ...state,
                step: action.payload.step,
            } as IConfigApiKeyFormState
        case ConfigApiKeyFormActionsTypes.SETSTEPS:
            const lastPosition = action.payload.steps.length - 1
            return {
                ...state,
                steps: action.payload.steps,
                firstStep: action.payload.steps[0],
                step: action.payload.steps[0],
                finalStep: action.payload.steps[lastPosition],
            } as IConfigApiKeyFormState
        case ConfigApiKeyFormActionsTypes.EDIT_API_KEY:
            return {
                ...state,
                apiKey: action.payload.apiKey,
            } as IConfigApiKeyFormState
        case ConfigApiKeyFormActionsTypes.CLEAR_API_KEY_FORM:
            return initialFormState as IConfigApiKeyFormState
        default:
            return state
    }
}

// const initialApiKeyState: IApiKeyState = {
//   appID: '',
//   issuanceID: '',
//   requesterDid: '',
//   domain: '',
//   credentials: [],
// }

// export function configApiKeyReducer (
//   state = initialApiKeyState,
//   action: ConfigApiKeyActions
// ): IApiKeyState  {
//   switch (action.type) {
//     case ConfigApiKeyActionsTypes.SET_QR_VERIFIER:
//       console.log('CONFIG VERIFIER REDUCER SETSTEP', action.type)
//       return {
//         ...state,
//         qrApiKey: action.payload
//       } as IApiKeyState
//     case ConfigApiKeyActionsTypes.EDIT_API_KEY:
//       return {
//         ...state,
//         appID: !!action.payload.appID ? action.payload.appID : state.appID,
//         issuanceID: !!action.payload.issuanceID ? action.payload.issuanceID : state.issuanceID,
//         requesterDid: !!action.payload.requesterDid ? action.payload.requesterDid : state.requesterDid,
//         domain: !!action.payload.domain ? action.payload.domain : state.domain,
//         credentials: !!action.payload.credentials ? action.payload.credentials : state.credentials
//       } as IApiKeyState
//     case ConfigApiKeyActionsTypes.CLEAR_VERIFIER:
//       return initialApiKeyState as IApiKeyState
//     default:
//       return state
//   }
// }

const initialApiKeysState: IApiKeysState = {
    apiKeys: [],
    didOptions: undefined,
    showApiKeyDetail: false,
    loadingStatus: false,
    status: ActionStatusTypes.neutral,
    error: '',
}

export function apiKeysReducer(
    state = initialApiKeysState,
    action: AppsAction
): IApiKeysState {
    switch (action.type) {
        case ApiKeysActionTypes.GET_API_KEYS:
            return {
                ...state,
                loadingStatus: true,
                showApiKeyDetail: false,
            }
        case ApiKeysActionTypes.GET_API_KEYS_SUCCESS:
            return {
                ...state,
                apiKeys: action.payload,
                loadingStatus: false,
            }
        case ApiKeysActionTypes.GET_API_KEYS_FAILED:
            return {
                ...state,
                error: action.payload?.error,
            }

        case ApiKeysActionTypes.GET_TENANTS_CONFIG:
            return {
                ...state,
                didOptions: [],
            }
        case ApiKeysActionTypes.GET_TENANTS_CONFIG_SUCCESS:
            return {
                ...state,
                didOptions: action.payload?.tenantConfig?.dids || [],
            }
        case ApiKeysActionTypes.GET_TENANTS_CONFIG_FAILED:
            return {
                ...state,
                error: action.payload.error,
            }
        case ApiKeysActionTypes.CREATE_API_KEY:
            return {
                ...state,
                loadingStatus: true,
                status: ActionStatusTypes.loading,
                error: '',
            }
        case ApiKeysActionTypes.CREATE_API_KEY_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                status: ActionStatusTypes.success,
                selectedApiKey: action.payload.app,
                error: '',
            }
        case ApiKeysActionTypes.CREATE_API_KEY_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
                error: action.payload?.error,
            }
        case ApiKeysActionTypes.UPDATE_API_KEY:
            return {
                ...state,
                loadingStatus: true,
                status: ActionStatusTypes.loading,
                error: '',
            }
        case ApiKeysActionTypes.UPDATE_API_KEY_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                status: ActionStatusTypes.success,
                error: '',
            }
        case ApiKeysActionTypes.UPDATE_API_KEY_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
                error: action.payload?.error,
            }
        case ApiKeysActionTypes.REGENERATE_API_KEY:
            return {
                ...state,
                loadingStatus: true,
                status: ActionStatusTypes.loading,
                error: '',
            }
        case ApiKeysActionTypes.REGENERATE_API_KEY_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                status: ActionStatusTypes.success,
                selectedApiKey: action.payload.newApiKey,
                error: '',
            }
        case ApiKeysActionTypes.REGENERATE_API_KEY_FAILED:
            return {
                ...state,
                status: ActionStatusTypes.failed,
                error: action.payload?.error,
            }
        case ApiKeysActionTypes.CLEAR_API_KEY_LIST:
            return initialApiKeysState as IApiKeysState
        case ApiKeysActionTypes.CLEAR_ACTION_STATUS:
            return {
                ...state,
                selectedApiKey: undefined,
                status: ActionStatusTypes.neutral,
            }
        default:
            return state
    }
}
