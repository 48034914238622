import { ActionStatusTypes } from '../../assets/globalConstants'
import { ICredentialCardProps } from '../../features/wallet/requestCredentials/components/credentialCard/CredentialCard'
import {
    EWCredential,
    ICredentialsItemModel,
    IEWCredentialsPaginatedResponseModel,
} from '../../interfaces/interfaces'

// LIST
export interface IGetCredentialsAction {
    type: CredentialListActionsTypes.GET_CREDENTIALS
    payload: {
        did: string
        page?: number
        size?: number
    }
}

export interface IGetCredentialsSuccessAction {
    type: CredentialListActionsTypes.GET_CREDENTIALS_SUCCESS
    payload: {
        data: IEWCredentialsPaginatedResponseModel | ICredentialsItemModel[]
    }
}

export interface IGetCredentialsFailed {
    type: CredentialListActionsTypes.GET_CREDENTIALS_FAILED
    payload: {
        error: string
    }
}

export interface ClearCredentialList {
    type: CredentialListActionsTypes.CLEAR_CREDENTIAL_LIST
}

export interface IDeleteCredentialAction {
    type: CredentialListActionsTypes.DELETE_CREDENTIAL
    payload: {
        did: string
        credentialId: string
        page?: number
        size?: number
    }
}

export interface IDeleteCredentialSuccessAction {
    type: CredentialListActionsTypes.DELETE_CREDENTIAL_SUCCESS
}

export interface IDeleteCredentialFailed {
    type: CredentialListActionsTypes.DELETE_CREDENTIAL_FAILED
    payload: {
        error: string
    }
}

export interface IImportCredentialAction {
    type: CredentialListActionsTypes.IMPORT_CREDENTIAL
    payload: {
        did: string
        credData: EWCredential
        size?: number
    }
}

export interface IImportCredentialSuccessAction {
    type: CredentialListActionsTypes.IMPORT_CREDENTIAL_SUCCESS
    payload: {
        credential: EWCredential
    }
}

export interface IImportCredentialFailed {
    type: CredentialListActionsTypes.IMPORT_CREDENTIAL_FAILED
    payload: {
        error: string
    }
}

export interface ISelectCredentialAction {
    type: CredentialListActionsTypes.SELECT_CREDENTIAL
    payload: {
        credential?: ICredentialCardProps
    }
}

export type CredentialsListActions =
    | IGetCredentialsAction
    | IGetCredentialsSuccessAction
    | IGetCredentialsFailed
    | ClearCredentialList
    | ISelectCredentialAction
    | IDeleteCredentialAction
    | IDeleteCredentialSuccessAction
    | IDeleteCredentialFailed
    | IImportCredentialAction
    | IImportCredentialSuccessAction
    | IImportCredentialFailed

export enum CredentialListActionsTypes {
    GET_CREDENTIALS = 'request_credentials/get',
    GET_CREDENTIALS_SUCCESS = 'request_credentials/get_success',
    GET_CREDENTIALS_FAILED = 'request_credentials/get_failed',
    CLEAR_CREDENTIAL_LIST = 'request_credentials/clear_list',
    SELECT_CREDENTIAL = 'request_credentials/selectCredential',
    DELETE_CREDENTIAL = 'request_credentials/deleteCredential',
    DELETE_CREDENTIAL_SUCCESS = 'request_credentials/delete_credential_success',
    DELETE_CREDENTIAL_FAILED = 'request_credentials/delete_credential_failed',
    IMPORT_CREDENTIAL = 'request_credentials/importCredential',
    IMPORT_CREDENTIAL_SUCCESS = 'request_credentials/import_credential_success',
    IMPORT_CREDENTIAL_FAILED = 'request_credentials/import_credential_failed',
}

export interface ICredentialsListState {
    credentials?: IEWCredentialsPaginatedResponseModel | ICredentialsItemModel[]
    importedCredential?: EWCredential
    loadingStatus?: boolean
    deleteCredStatus?: ActionStatusTypes
    importCredStatus?: ActionStatusTypes
}
