import angleForward from '../../../../assets/images/angle-forward.svg'
import fastLogin from '../../../../assets/images/fast-login-icon.svg'
import {
    ButtonModel,
    ctaLinkModel,
    LinkModel,
} from '../../../../interfaces/interfaces'

// PanelScafolding params
export const loginLink: LinkModel = {
    label: '',
    route: 'login',
}

// FormPanel params
export const fastLoginIcon = fastLogin
export const angleForwardIcon = angleForward
export const forgetPasswordLink: LinkModel = {
    label: 'login.forgetPasswordQuestion',
    route: '/forget-password',
}
export const goBackLink: ctaLinkModel = {
    label: 'public.cancel',
    route: '/email-login',
}
export const ssiLoginLink: ctaLinkModel = {
    label: 'login.fastLogin',
    route: 'login',
    image: {
        src: fastLoginIcon,
        alt: 'login.fastLoginIcon',
    },
}
export const loginButton: ButtonModel = {
    label: 'login.signUp',
}

export const signUpLink: ctaLinkModel = {
    label: 'login.dontHaveAccountYet',
    route: 'login',
}
