import { Epic, ofType } from 'redux-observable'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { ScanModalListActionsTypes } from './scanModalTypes'
import dataService from '../../services/dataService'
import { from, of } from 'rxjs'
import {
    getProcessFailed,
    getProcessSuccess,
    sendProcessDataFailed,
    sendProcessDataSuccess,
} from './scanModalActions'
import { getCredentials } from '../requestCredentials/requestCredentialsActions'
import { getConnections } from '../connections/connectionsActions'

export const getProccessDataEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ScanModalListActionsTypes.GET_PROCCESS),
        switchMap((action): any => {
            const did = action.payload.profile
            const request = action.payload.request
            return from(dataService.getProcess(did, request)).pipe(
                map((response?: any) => {
                    return getProcessSuccess(response.response)
                }),
                catchError((error) => of(getProcessFailed(error)))
            )
        })
    )
}

export const sendProcessDataEpic: Epic = (action$) => {
    return action$.pipe(
        ofType(ScanModalListActionsTypes.SEND_PROCESS_DATA),
        switchMap((action): any => {
            const did = action.payload.profile
            const data = action.payload.data
            return from(dataService.sendProcessData(did, data)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        sendProcessDataSuccess(),
                        getCredentials(did),
                        getConnections(did)
                    )
                }),
                catchError((error) => of(sendProcessDataFailed(error)))
            )
        })
    )
}
