// TODO: remove jsons when services are ready
import { ActionStatusTypes } from '../../../assets/globalConstants'
import {
    IOrganizationActions,
    IOrganizationState,
    OrganizationTypes,
} from './organizationTypes'

export const emptyOrg = {
    id: '',
    providerId: '*',
    orgInfo: {
        name: '',
        domainName: '',
        services: [],
        areaGroup: '',
        discoveryURL: '',
        identifierBag: '',
        legalPersonalIdentifier: '',
        LEI: '',
        SEED: '',
        SIC: '',
    },
    billingInfo: {
        legalAddress: {
            street: '',
            suite: '',
            number: '',
            locality: '',
            region: '',
            country: '',
            postalCode: '',
        },
        LegalName: '',
        vatRegistration: '',
        taxReference: '',
    },
    // paymentMethod: {
    //     id: '',
    //     creditCardNumber: '',
    //     holderName: '',
    //     ccvCheck: '',
    //     expiration: '',
    // },
}

const organizationState: IOrganizationState = {
    did: 'test',
    name: 'test1_local',
    status: 'DISABLED',
    licenseType: 'Free',
    org: emptyOrg,
    creatingOrgLoading: false,
    termsAccepted: false,
    orgLoading: false,
    loadingOrgByIdStatus: ActionStatusTypes.neutral,
}

export const organizationReducer = (
    state = organizationState,
    action: IOrganizationActions
): IOrganizationState => {
    switch (action.type) {
        case OrganizationTypes.LOAD_ORG:
            // console.log('REDUCER_LOAD_ORG', action)
            return {
                ...state,
                orgLoading: true,
            }
        case OrganizationTypes.LOAD_ORG_SUCCESS:
            // console.log('REDUCER_LOAD_ORG_SUCCESS', action)
            return {
                ...state,
                org: action.org,
                termsAccepted: !!action?.termsAreAccepted,
                orgLoading: false,
            }
        case OrganizationTypes.LOAD_ORG_FAILED:
            return {
                ...state,
                orgLoading: false,
            }
        case OrganizationTypes.CREATE_ORG:
            return {
                ...state,
                creatingOrgLoading: true,
            }
        case OrganizationTypes.CREATE_STARTER_ORG:
            return {
                ...state,
                creatingOrgLoading: true,
            }
        case OrganizationTypes.CREATE_ORG_SUCCESS:
            return {
                ...state,
                org: action.payload.org,
                creatingOrgLoading: false,
            }
        case OrganizationTypes.CREATE_STARTER_ORG_SUCCESS:
            return {
                ...state,
                org: action.payload.org,
                creatingOrgLoading: false,
            }
        case OrganizationTypes?.CREATE_REQUEST_ORG_SUCCESS:
            return {
                ...state,
                org: action.payload.org,
                creatingOrgLoading: false,
            }
        case OrganizationTypes.CREATE_ORG_FAILED:
            return {
                ...state,
                creatingOrgLoading: false,
            }
        case OrganizationTypes.UPDATE_ORG_SUCCESS:
            return {
                ...state,
                org: action.org,
            }
        default:
            return state
    }
}
