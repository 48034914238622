import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DarkButton from '../../../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import LightButton from '../../../../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaSelect from '../../../../../../../../Components/atoms/formFields/select/GaSelect'
import GaSelectCheckbox, {
    Selectable,
} from '../../../../../../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import GaTextInput from '../../../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import { IRoleModel } from '../../../../../../../../interfaces/interfaces'
import { apiKeysSelectors } from '../../../../../../../studio/ApiKeys'
import {
    apiKeysScopes,
    propertiesToSearchApiKeys,
    propertiesToSearchSSIOperations,
} from '../../../../../../../studio/ApiKeys/views/apiKeysList/apiKeysList.constants'
import { didsSelectors } from '../../../../../../../studio/Dids'

import { sessionSelectors } from '../../../../../../session'
import { rolesScopes } from '../../../store/team.constants'
import styles from '../../../views/users.module.scss'
import GaCheckbox from '../../../../../../../../Components/atoms/formFields/checkbox/GaCheckbox'
import {
    LabelPosition,
    TextSizes,
} from '../../../../../../../../data/globalVar'
import { rolesSelectors } from '../../../../roles/store'
import { propertiesToSearchTenants } from '../../../../../constants/team.constants'
import { propertiesToSearchDids } from '../../../../../../../../Components/molecules/didsSelector/data/didsSelector.constants'

type IRegisterFormProps = {
    buttonFunction: (x?) => void
    closeModal: () => void
}

const provider = process.env['REACT_APP_PROVIDER'] || 'gataca'

const RegisterForm: React.FC<IRegisterFormProps> = (props) => {
    const { buttonFunction, closeModal } = props
    const { t } = useTranslation()
    // Data
    const allDidsAllowed = useSelector(sessionSelectors.allDidsAllowed)
    const allApiKeysAllowed = useSelector(sessionSelectors.allApiKeysAllowed)
    const allTenantsAllowed = useSelector(sessionSelectors.allTenantsAllowed)
    const getOrgId = useSelector(sessionSelectors.getUserOrganization)
    const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes)

    // Form selectors options
    const currentTenant = useSelector(sessionSelectors.getCurrentTenant)
    const userTenants = useSelector(sessionSelectors.getTenants)
    const selectableDids = useSelector(didsSelectors.getDidsForSelect)
    const apiKeysData = useSelector(apiKeysSelectors.getApiKeys)
    const ssiConfigs = useSelector(sessionSelectors.getSsiOperations)
    const rolesList: IRoleModel[] | undefined = useSelector(
        rolesSelectors.getRoles
    )
    const anyOption = {
        key: '*',
        value: t('createUser.any'),
    }
    const selectAllOption = {
        key: '*',
        value: t('createUser.selectAll'),
    }
    // Filtered selector options
    const [filteredApiKeysOptions, setApiKeyOptions] = useState(new Array())
    const [filteredSSIOptions, setSSIOptions] = useState(new Array())
    // Form Data States
    const [anyTenantAllowed, setAnyTenantAllowed] = useState(false)
    const [anyDidAllowed, setAnyDidAllowed] = useState(false)
    const [anyApiKeyAllowed, setAnyApiKeyAllowed] = useState(false)
    const [anySSIAllowed, setAnySSIAllowed] = useState(false)
    const [role, setSelectedRole] = useState(
        rolesList && rolesList?.length > 0 ? rolesList[0] : undefined
    )
    const [formData, setFormState] = useState({
        user: {
            email: '',
            role: rolesList ? rolesList[0]?.role_id : '',
            dids: new Array(),
            ssi_operations: new Array(),
            api_keys: new Array(),
            tenants: new Array(),
        },
        validEmail: true,
    })
    const { email, dids, ssi_operations, api_keys, tenants } = formData?.user
    const apiKeysLoading = useSelector(apiKeysSelectors.loadingStatus)

    const [state, setState] = React.useState<{
        selections: Selectable[]
        searched: string
        searchedResult: Selectable[]
    }>({
        selections: [],
        searched: '',
        searchedResult:
            formData?.user?.dids ||
            formData?.user?.ssi_operations ||
            formData?.user?.api_keys ||
            formData?.user?.tenants ||
            [],
    })

    useEffect(() => {
        !apiKeysLoading && getApiKeys()
    }, [dids])

    useEffect(() => {
        getAvailableSsiOperations()
    }, [api_keys])

    useEffect(() => {}, [tenants, formData, filteredApiKeysOptions])

    // Get formatted selectors options
    const getTenants = (): Selectable[] => {
        const userTenantsArr = !!userTenants?.length
            ? userTenants
            : [currentTenant]
        const tenantsOptions = userTenantsArr
        let mapped = tenantsOptions?.map((t) => {
            return { key: t || '', value: t || '' }
        })
        return mapped
    }
    const currentTenantSelected =
        tenants?.includes(currentTenant) || currentTenant === '*'

    const getDidsSelectorOption = () => {
        if ((displayTenants() && currentTenantSelected) || !displayTenants()) {
            return selectableDids
        } else {
            return []
        }
    }

    async function getApiKeys() {
        if ((displayTenants() && currentTenantSelected) || !displayTenants()) {
            const mapped = await getFilteredApiKeys()
            setApiKeyOptions(mapped)

            return mapped
        } else {
            setApiKeyOptions([])
        }
    }

    async function getAvailableSsiOperations() {
        if ((displayTenants() && currentTenantSelected) || !displayTenants()) {
            const mapped = await getFilteredSSIConfigs()
            setSSIOptions(mapped)
            return mapped
        } else {
            setSSIOptions([])
        }
    }

    // Get Filtered options
    const getFilteredApiKeys = () => {
        const filteredApiKeys = new Array()

        apiKeysData?.forEach((apk) => {
            dids?.forEach((did) => {
                did === apk.did &&
                    filteredApiKeys?.push({ key: apk.id, value: apk.id })
            })
        })

        return filteredApiKeys
    }

    async function getFilteredSSIConfigs() {
        const filteredSSI = new Array()

        ssiConfigs?.forEach((ssi) => {
            dids?.forEach((did) => {
                did === ssi.vmethodId?.split('#')[0] &&
                    filteredSSI?.push({ key: ssi.id, value: ssi.id })
            })
        })

        return filteredSSI
    }

    function handleSearchedChange(e) {
        setState({
            selections: state.selections,
            searched: state.searched,
            searchedResult: e,
        })
    }

    // Check fields display by role and scopes
    const displayTenants = (): boolean =>
        !!allowedScopes?.includes('readTenants') && !!getTenants().length

    const displayDids = (): boolean =>
        getDidsSelectorOption()?.length > 0 &&
        checkScopes([
            'deleteDids',
            'updateDids',
            'deleteApiKeys',
            'updateApiKeys',
            'deleteSSIConfigs',
            'updateSSIConfigs',
        ])

    const displayApiKeys = (): boolean =>
        filteredApiKeysOptions?.length > 0 &&
        checkScopes(['deleteApiKeys', 'updateApiKeys'])

    const displaySSIConfigs = (): boolean =>
        filteredSSIOptions?.length > 0 &&
        checkScopes(['deleteSSIConfigs', 'updateSSIConfigs'])

    const checkScopes = (scopes: string[]): boolean =>
        scopes.some((s) => role?.scopes?.includes(s))

    const selectRole = (role_id?: string) => {
        let selectedRole = rolesList?.find((role) => role.role_id === role_id)
        setSelectedRole(selectedRole)
    }

    // Check display of "any / '*'" checkboxes
    const displayAnyTenantCheckbox =
        !allTenantsAllowed &&
        tenants?.length > 0 &&
        tenants?.length === getTenants()?.length

    const displayAnyDidCheckbox =
        allDidsAllowed &&
        dids?.length > 0 &&
        dids?.length === selectableDids?.length

    const displayAnyApiKeyCheckbox =
        displayAnyDidCheckbox &&
        allApiKeysAllowed &&
        anyDidAllowed &&
        api_keys?.length > 0 &&
        api_keys?.length === filteredApiKeysOptions?.length

    const displayAnySSICheckbox =
        displayAnyDidCheckbox &&
        allDidsAllowed &&
        anyDidAllowed &&
        ssi_operations?.length > 0 &&
        ssi_operations?.length === filteredSSIOptions?.length

    // Submit info
    const validForm =
        !!email?.trim() &&
        !!formData?.user?.role &&
        !!formData?.user?.role?.trim() &&
        (!displayTenants() || tenants?.length > 0) &&
        (!displayDids() || dids?.length > 0) &&
        (!displayApiKeys() || api_keys?.length > 0) &&
        (!displaySSIConfigs() || ssi_operations?.length > 0)

    const onSubmit = (e) => {
        e.preventDefault()
        const formattedUser = formatUserInfo()
        buttonFunction({ ...formattedUser })
    }

    const formatUserInfo = () => {
        const userDidsIds = selectableDids?.map((did) => did?.key)
        const userSsiOperationsIds = ssiConfigs?.map((ssiConf) => ssiConf?.id)
        const userApiKeysIds = apiKeysData?.map((apkey) => apkey?.id)

        return {
            email: email,
            password: '',
            role: formData?.user?.role,
            provider: provider,
            dids:
                (anyDidAllowed && displayAnyDidCheckbox) ||
                (displayTenants() && !currentTenantSelected)
                    ? ['*']
                    : dids?.length > 0
                    ? dids
                    : userDidsIds,
            ssi_operations:
                (anySSIAllowed && displayAnySSICheckbox) ||
                (displayTenants() && !currentTenantSelected)
                    ? ['*']
                    : ssi_operations?.length > 0
                    ? ssi_operations
                    : userSsiOperationsIds,
            api_keys:
                (anyApiKeyAllowed && displayAnyApiKeyCheckbox) ||
                (displayTenants() && !currentTenantSelected)
                    ? ['*']
                    : api_keys?.length > 0
                    ? api_keys
                    : userApiKeysIds,
            tenants:
                anyTenantAllowed && displayAnyTenantCheckbox
                    ? ['*']
                    : tenants?.length > 0
                    ? tenants
                    : [currentTenant || userTenants[0]],
            org_id: getOrgId,
        }
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <GaTextInput
                    id={'email'}
                    invalid={!formData?.validEmail}
                    labelText={t('createUser.userEmailLabel')}
                    required={false}
                    name={'email'}
                    onChange={(e) => {
                        setFormState({
                            ...formData,
                            user: { ...formData?.user, email: e.target.value },
                        })
                    }}
                    placeholder={t('createUser.userEmailPlaceholder')}
                    value={email}
                />

                <GaSelect
                    idSelect={'role'}
                    className={cx('marginTop20')}
                    value={formData.user?.role}
                    id="select-role"
                    invalid={false}
                    labelText={t('configUser.roleLabel')}
                    name={'role'}
                    permissions={{
                        scopes: rolesScopes.read?.concat('updateUsers'),
                        every: true,
                    }}
                    optionTextProperty={'role_id'}
                    optionValueProperty={'role_id'}
                    onChange={(e) => {
                        selectRole(e.target.value)
                        setFormState({
                            ...formData,
                            user: {
                                ...formData?.user,
                                role: e.target.value,
                                dids: [],
                                api_keys: [],
                                ssi_operations: [],
                            },
                        })
                    }}
                    options={rolesList}
                />

                {displayTenants() && (
                    <>
                        <GaSelectCheckbox
                            id={'tenantsCheckbox'}
                            className={cx('marginTop20 margin-bottom')}
                            invalid={!tenants?.length}
                            labelText={t('createUser.tenantLabel')}
                            required={true}
                            selectAllOption={selectAllOption}
                            name={'tenants'}
                            permissions={{
                                scopes: ['readTenants', 'updateUsers'],
                                every: true,
                            }}
                            onChange={(e) => {
                                setFormState({
                                    ...formData,
                                    user: {
                                        ...formData?.user,
                                        tenants: e,
                                    },
                                })
                            }}
                            checkedData={tenants}
                            options={getTenants()}
                            handleSearchedChange={handleSearchedChange}
                            propertiesToSearch={propertiesToSearchTenants}
                        />
                        {displayAnyTenantCheckbox && (
                            <div className={styles.setAnyCheckbox}>
                                <GaCheckbox
                                    name="set_any"
                                    defaultChecked={anyTenantAllowed}
                                    onChange={() =>
                                        setAnyTenantAllowed(!anyTenantAllowed)
                                    }
                                    itemText={t('createUser.allowAnyTenant')}
                                    textSize={TextSizes.LG}
                                    position={LabelPosition.right}
                                ></GaCheckbox>
                            </div>
                        )}
                    </>
                )}

                {displayDids() && (
                    <>
                        <GaSelectCheckbox
                            id={'didsCheckbox'}
                            className={cx('marginTop20')}
                            selectAllOption={selectAllOption}
                            invalid={!dids?.length}
                            labelText={t('createUser.didLabel')}
                            required={!allDidsAllowed}
                            name={'dids'}
                            onChange={(e) => {
                                setFormState({
                                    ...formData,
                                    user: {
                                        ...formData?.user,
                                        dids: e,
                                        api_keys: [],
                                        ssi_operations: [],
                                    },
                                }),
                                    setAnyApiKeyAllowed(false)
                                setAnySSIAllowed(false)
                                !displayAnyDidCheckbox
                                    ? setAnyDidAllowed(false)
                                    : null
                            }}
                            permissions={{
                                scopes: ['readDids', 'updateUsers'],
                                every: true,
                            }}
                            options={getDidsSelectorOption()}
                            checkedData={dids}
                            handleSearchedChange={handleSearchedChange}
                            propertiesToSearch={propertiesToSearchDids}
                        />

                        {displayAnyDidCheckbox && (
                            <div className={styles.setAnyCheckbox}>
                                <GaCheckbox
                                    name="set_any"
                                    defaultChecked={anyDidAllowed}
                                    onChange={() =>
                                        setAnyDidAllowed(!anyDidAllowed)
                                    }
                                    itemText={t('createUser.allowAnyDid')}
                                    textSize={TextSizes.LG}
                                    position={LabelPosition.right}
                                ></GaCheckbox>
                            </div>
                        )}
                    </>
                )}

                {displayApiKeys() && (
                    <>
                        <GaSelectCheckbox
                            id={'apiKeysCheckbox'}
                            selectAllOption={selectAllOption}
                            className={cx('marginTop20')}
                            invalid={!api_keys?.length}
                            labelText={t('createUser.apiKeysLabel')}
                            required={true}
                            name={'api_keys'}
                            onChange={(e) => {
                                setFormState({
                                    ...formData,

                                    user: {
                                        ...formData?.user,
                                        api_keys: e,
                                        ssi_operations: [],
                                    },
                                }),
                                    setAnySSIAllowed(false)
                            }}
                            permissions={{
                                scopes: apiKeysScopes.read?.concat(
                                    'updateUsers'
                                ),
                                every: true,
                            }}
                            checkedData={api_keys}
                            options={filteredApiKeysOptions}
                            handleSearchedChange={handleSearchedChange}
                            propertiesToSearch={propertiesToSearchApiKeys}
                        />

                        {displayAnyApiKeyCheckbox && (
                            <div className={styles.setAnyCheckbox}>
                                <GaCheckbox
                                    name="set_any"
                                    defaultChecked={anyApiKeyAllowed}
                                    onChange={() =>
                                        setAnyApiKeyAllowed(!anyApiKeyAllowed)
                                    }
                                    itemText={t('createUser.allowAnyApiKey')}
                                    textSize={TextSizes.LG}
                                    position={LabelPosition.right}
                                ></GaCheckbox>
                            </div>
                        )}
                    </>
                )}

                {displaySSIConfigs() && (
                    <>
                        <GaSelectCheckbox
                            id={'ssiConfigsCheckbox'}
                            className={cx('marginTop20')}
                            selectAllOption={selectAllOption}
                            invalid={!ssi_operations?.length}
                            labelText={t('createUser.ssiConfigsLabel')}
                            required={true}
                            name={'ssi_operations'}
                            onChange={(e) => {
                                setFormState({
                                    ...formData,
                                    user: {
                                        ...formData?.user,
                                        ssi_operations: e,
                                    },
                                })
                            }}
                            permissions={{
                                scopes: ['readSSIConfigs', 'updateUsers'],
                                every: true,
                            }}
                            checkedData={ssi_operations}
                            options={
                                displayApiKeys()
                                    ? filteredSSIOptions
                                    : ssiConfigs?.map((ssi) => {
                                          return {
                                              key: ssi?.id,
                                              value: ssi?.id,
                                          }
                                      })
                            }
                            handleSearchedChange={handleSearchedChange}
                            propertiesToSearch={propertiesToSearchSSIOperations}
                        />

                        {displayAnySSICheckbox && (
                            <div className={styles.setAnyCheckbox}>
                                <GaCheckbox
                                    name="set_any"
                                    defaultChecked={anySSIAllowed}
                                    onChange={() =>
                                        setAnySSIAllowed(!anySSIAllowed)
                                    }
                                    itemText={t('createUser.allowAnySSIConfig')}
                                    textSize={TextSizes.LG}
                                    position={LabelPosition.right}
                                ></GaCheckbox>
                            </div>
                        )}
                    </>
                )}
            </form>
            <div className={styles.buttonsContainer}>
                <LightButton
                    className={cx('margin-left-auto')}
                    text={'public.cancel'}
                    disabled={false}
                    functionality={() => closeModal()}
                />
                <DarkButton
                    text={'public.finish'}
                    disabled={!validForm}
                    functionality={(e) => onSubmit(e)}
                />
            </div>
        </>
    )
}

export default RegisterForm
