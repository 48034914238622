import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import MediumModal from '../../../../../Components/molecules/modals/Medium/MediumModal'
import styles from './organizationModal.module.scss'
import {
    brandLabels,
    countriesAndStates,
    domains,
} from '../../../../../assets/globalConstants'
import { orgServicesEndpoints } from '../../../../common/Welcome/welcomeConstants'
import { initialLang } from '../../../../../i18n'
import { onPremise } from '../../../../../data/globalVar'
import {
    creatingOrgLoading,
    getOrg,
} from '../../../../common/organization/organizationSelectors'
import {
    createStarterOrgAction,
    updateOrgNoPaymentAction,
} from '../../../../common/organization/organizationActions'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import { IPriceModel } from '../../../../../interfaces/interfaces'
import { licensesSelectors } from '../../../../common/licenses'
import {
    defaultCountry,
    defaultCountryByBrand,
    defaultCountryIndexByBrand,
} from '../../../../../utils/formUtils'
import {
    hideScreenLoaderAction,
    showScreenLoaderAction,
} from '../../../../common/Loader/loaderActions'
import GaCheckbox from '../../../../../Components/atoms/formFields/checkbox/GaCheckbox'

type IOrganizationModalProps = {
    show: boolean
    onlyUpdate?: boolean
    hideModal: () => void
}

const OrganizationModal: React.FC<IOrganizationModalProps> = (props) => {
    const { hideModal, show, onlyUpdate } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const isCreateOrgLoading = useSelector(creatingOrgLoading)
    const newLicense = useSelector(licensesSelectors.getNewLicense)
    const organization = useSelector(getOrg)

    useEffect(() => {
        if (
            !isCreateOrgLoading &&
            organization?.id?.length &&
            organization?.billingInfo?.legalAddress?.country
        ) {
            hideModal()
            hideScreenLoaderAction()
        }
        setFormState(orgInitialFormValue)
    }, [isCreateOrgLoading, organization])

    const initialDomainName =
        organization?.orgInfo?.domainName || domains ? domains[0]?.value : ''

    const orgInitialFormValue = {
        name: organization?.orgInfo?.name || '',
        legalName: '',
        domainName: initialDomainName,
        services: orgServicesEndpoints,
        VATRegistration: '',
        discoveryURL: '',
    }

    const [formData, setFormState] = useState(orgInitialFormValue)

    const defaultStates = defaultCountryByBrand?.states
    const [states, setStates] = useState(defaultStates)

    const [legalAddres, setLegalAddressState] = useState({
        street: '',
        suite: '',
        number: '',
        locality: '',
        region: states ? states[0] : '',
        country: defaultCountry(defaultCountryIndexByBrand),
        postalCode: '',
    })

    const {
        name,
        legalName,
        domainName,
        services,
        VATRegistration,
        discoveryURL,
    } = formData
    const { street, suite, number, locality, region, country, postalCode } =
        legalAddres
    const [aceptedConditions, setConditionsState] = useState(false)

    const validForm = !!(
        name.trim()?.length &&
        legalName.trim()?.length &&
        domainName.trim()?.length &&
        discoveryURL.trim()?.length &&
        street.trim()?.length &&
        number.trim()?.length &&
        locality.trim()?.length &&
        region.trim()?.length &&
        country.trim()?.length &&
        postalCode.trim()?.length &&
        VATRegistration.trim()?.length &&
        aceptedConditions
    )

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setFormState({
            ...formData,
            [name]: value,
        })
    }

    const handleAddressInputsChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        setLegalAddressState({
            ...legalAddres,
            [name]: value,
        })
    }

    const getStates = (name: string) => {
        setStates([])
        const selectedItem = countriesAndStates.find((el) => {
            return name === el.name_en || name === el.name_es
        })

        setStates(selectedItem?.states || [])
    }
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)

    const getPriceId = (prices: IPriceModel[]) => {
        const selectedPeriodPrice = prices?.filter((el) => {
            return el.recurringInterval === selectedPeriod
        })[0]

        return selectedPeriodPrice?.id
    }

    function submitData() {
        hideModal()
        const formattedOrg = buildDataObject()
        !onPremise &&
            (!onlyUpdate
                ? dispatch(createStarterOrgAction(formattedOrg))
                : dispatch(updateOrgNoPaymentAction(formattedOrg)))
    }

    const buildDataObject = () => {
        const data = {
            id: organization?.id || '',
            providerId: 'gataca',
            orgInfo: {
                name: name,
                domainName: domainName,
                discoveryURL: discoveryURL,
                services: services,
            },
            billingInfo: {
                legalAddress: {
                    street,
                    suite,
                    number,
                    locality,
                    region,
                    country,
                    postalCode,
                },
                LegalName: legalName,
                vatRegistration: VATRegistration,
            },
            priceId: getPriceId(newLicense?.prices),
        }
        return data
    }
    return (
        <>
            <MediumModal
                className={styles.organizationModal}
                title={'subscription.organizationDetails'}
                description={'welcome.description'}
                headerClose={true}
                show={show}
                // hideFooter
                handleClose={hideModal}
                primaryButton={{
                    label: !onlyUpdate
                        ? 'subscription.activateFreeTrial'
                        : 'public.save',
                }}
                primaryButtonFunction={submitData}
                primaryButtonDisabled={!validForm}
                secondaryButton={{
                    label: 'public.cancel',
                    function: hideModal,
                }}
            >
                <div className={styles.organizationModal__content}>
                    <div className={styles.form}>
                        <div className={styles.form__textInputs}>
                            <GaTextInput
                                name="name"
                                invalid={false}
                                labelText={t('welcome.companyName')}
                                onChange={(e) => handleInputChange(e)}
                                placeholder={t('welcome.companyName')}
                                type="text"
                                value={name}
                                required
                            />
                            <GaTextInput
                                className={cx('marginTop20')}
                                name="legalName"
                                invalid={false}
                                labelText={t('welcome.legalName')}
                                onChange={(e) => handleInputChange(e)}
                                placeholder={t('welcome.companyName')}
                                type="text"
                                value={legalName}
                                required
                            />
                            <div className={styles.doubleColumn}>
                                <GaTextInput
                                    className={cx('marginTop20')}
                                    name="discoveryURL"
                                    invalid={false}
                                    labelText={t('login.website')}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={t('login.website')}
                                    type="text"
                                    value={discoveryURL}
                                    required
                                />
                                <GaSelect
                                    idSelect={'domainName'}
                                    className={cx('marginTop20')}
                                    value={domainName}
                                    name="domainName"
                                    invalid={false}
                                    isLoading={!states?.length}
                                    labelText={t('welcome.domainName')}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder={t('welcome.domainName')}
                                    optionTextProperty={'text'}
                                    optionValueProperty={'value'}
                                    options={domains}
                                />
                            </div>

                            <GaTextInput
                                className={cx('marginTop20')}
                                name="street"
                                invalid={false}
                                labelText={t('welcome.street')}
                                onChange={(e) => handleAddressInputsChange(e)}
                                placeholder={t('welcome.street')}
                                type="text"
                                value={street}
                                required
                            />
                            <div className={styles.doubleColumn}>
                                <GaTextInput
                                    className={cx('marginTop20')}
                                    name="number"
                                    invalid={false}
                                    labelText={t('welcome.number')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    placeholder={t('welcome.number')}
                                    type="text"
                                    value={number}
                                    required
                                />
                                <GaTextInput
                                    className={cx('marginTop20')}
                                    name="suite"
                                    invalid={false}
                                    labelText={t('welcome.suite')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    placeholder={t('welcome.suite')}
                                    type="text"
                                    value={suite}
                                />
                            </div>
                            <div className={styles.doubleColumn}>
                                <GaSelect
                                    idSelect={'country'}
                                    value={country}
                                    className={cx('marginTop20')}
                                    name="country"
                                    invalid={false}
                                    isLoading={!countriesAndStates?.length}
                                    labelText={t('welcome.country')}
                                    optionValueProperty={
                                        initialLang
                                            ? 'name_' + initialLang
                                            : 'name_es'
                                    }
                                    optionTextProperty={
                                        initialLang
                                            ? 'name_' + initialLang
                                            : 'name_es'
                                    }
                                    onChange={(e) => {
                                        handleAddressInputsChange(e),
                                            getStates(e.target.value)
                                    }}
                                    options={countriesAndStates}
                                />
                                <GaSelect
                                    idSelect={'region'}
                                    className={cx('marginTop20')}
                                    value={region}
                                    name="region"
                                    invalid={false}
                                    isLoading={!states?.length}
                                    labelText={t('welcome.state')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    options={states}
                                />
                            </div>
                            <div className={styles.doubleColumn}>
                                <GaTextInput
                                    className={cx('marginTop20')}
                                    name="locality"
                                    invalid={false}
                                    labelText={t('welcome.locality')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    placeholder={t('welcome.locality')}
                                    type="text"
                                    value={locality}
                                    required
                                />

                                <GaTextInput
                                    className={cx('marginTop20')}
                                    name="postalCode"
                                    invalid={false}
                                    labelText={t('welcome.postalCode')}
                                    onChange={(e) =>
                                        handleAddressInputsChange(e)
                                    }
                                    placeholder={t('welcome.postalCode')}
                                    type="text"
                                    value={postalCode}
                                    required
                                />
                            </div>
                            <GaTextInput
                                className={cx('marginTop20')}
                                name="VATRegistration"
                                invalid={false}
                                labelText={t('welcome.nif')}
                                onChange={(e) => handleInputChange(e)}
                                placeholder={t('welcome.nif')}
                                type="text"
                                value={VATRegistration}
                                required
                            />
                        </div>
                        <div className={styles.terms}>
                            <GaCheckbox
                                index={'0'}
                                name="accept_terms"
                                checked={aceptedConditions}
                                onChange={() =>
                                    setConditionsState(!aceptedConditions)
                                }
                                className={`${styles.required} ${cx(
                                    'marginRight8'
                                )}`}
                                id="accept-terms"
                            ></GaCheckbox>
                            <span
                                className={cx(
                                    'neutral700 bodyRegularSM',
                                    styles.checkbox__text
                                )}
                            >
                                <Trans
                                    i18nKey={'welcome.agreeToGataca'}
                                    values={{ brand: brandLabels?.brand }}
                                />
                            </span>
                            <a
                                className={cx('primary700 bodyRegularSM')}
                                target="_blank"
                                href={brandLabels?.termsOfServiceLink}
                            >
                                {t('welcome.termsOfUse')}
                            </a>
                            <span
                                className={cx(
                                    'primary700 bodyRegularSM',
                                    styles.checkbox__text
                                )}
                            >
                                <Trans
                                    i18nKey={'welcome.and'}
                                    values={{ brand: brandLabels?.brand }}
                                />
                            </span>
                            <a
                                className={cx('primary700 bodyRegularSM')}
                                target="_blank"
                                href={brandLabels.privacypolicylink}
                            >
                                {t('welcome.privacyPolicy')}
                            </a>
                        </div>
                    </div>
                </div>
            </MediumModal>
        </>
    )
}

export default OrganizationModal
