/**
 * Returns state display text and color of credential according its status
 */

import { t } from 'i18next'
import { CredClaimsMockedValues } from '../data/credMockedValues'
import { initialLang } from '../i18n'
import { decodeJWT } from 'did-jwt'

const revokedData = {
    color: 'red',
    text: 'credentials.revoked',
    info: 'ew.requestCredentials.revokedStatusInfo',
}

const expiredData = {
    color: 'red',
    text: 'credentials.expired',
    info: 'ew.requestCredentials.expiredStatusInfo',
}

const suspendedData = {
    color: 'yellow',
    text: 'credentials.suspended',
    info: 'ew.requestCredentials.suspendedStatusInfo',
}

const activeData = {
    color: 'green',
    text: 'credentials.active',
}

const validatedData = {
    color: 'green',
    text: 'credentials.issued',
}

const unavailableData = {
    color: 'grey',
    text: 'credentials.unavailable',
    info: 'ew.requestCredentials.suspendedStatusInfo',
}

export const getStatusColorAndText = (status?: string) => {
    let colorAndText

    switch (status) {
        case 'REVOKED':
            colorAndText = revokedData
            break
        case 'EXPIRED':
            colorAndText = expiredData
            break
        case 'SUSPENDED':
            colorAndText = suspendedData
            break
        case 'ACTIVE':
            colorAndText = activeData
            break
        case 'ISSUED':
            colorAndText = activeData
            break
        case 'VALIDATED':
            colorAndText = validatedData
            break
        default:
            colorAndText = unavailableData
    }

    return colorAndText
}

export const dynamicSort = (property, reverse?: boolean) => {
    /* works with strings and numbers */
    let sortOrder = 1
    if (property && property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        let result =
            (property && a[property] < b[property]) ||
            (property && reverse && a[property] > b[property])
                ? -1
                : a[property] > b[property]
                ? 1
                : 0
        return result * sortOrder
    }
}

export const getCredentialSubjectValue = (cred) => {
    return CredClaimsMockedValues[cred.id]
        ? t(CredClaimsMockedValues[cred.id])
        : !cred.id?.toLowerCase().includes('image') &&
          !cred.id?.toLowerCase().includes('photo') &&
          !cred.id?.toLowerCase().includes('picture')
        ? t('editIssuance.step2.credDefaultValue', {
              cred: cred.name[initialLang]
                  ? cred.name[initialLang]
                  : cred.name['en'],
          })
        : t('editIssuance.step2.credDefaultImg', {
              cred: cred.name[initialLang]
                  ? cred.name[initialLang]
                  : cred.name['en'],
          })
}

export const getCredName = (credential) =>
    !!credential?.groupName
        ? credential?.groupName[initialLang] || credential?.groupName['en']
        : ''

export const isImage = (credential) =>
    credential?.groupId?.toLowerCase().includes('image') ||
    credential?.groupId?.toLowerCase().includes('photo') ||
    credential?.groupId?.toLowerCase().includes('picture')

export const getImageURI = (credential) =>
    credential?.mainClaim?.includes('data:image')
        ? credential?.mainClaim
        : 'data:image/jpg;base64,' + credential?.mainClaim

export const isDate = (credential) => credential?.mainClaim?.includes('date')

export const getFormattedJWTCredForEW = (data) => {
    const decodedCreds = data?.map((cred) => {
        const credData = cred?.data || cred
        const vc =
            credData?.vcFormat === 'jwt_vc'
                ? decodeJWT(credData?.vc?.toString()).payload?.vc
                : credData?.vc

        return credData?.vcFormat === 'jwt_vc'
            ? {
                  vc: vc,
                  vcFormat: credData?.vcFormat,
                  groupName: credData?.groupName,
                  groupId: credData?.groupId,
                  id: cred?.id,
              }
            : credData
    })

    return decodedCreds
}

export const readCredLDPOrJWTFromTextFile = (e) => {
    const content = e.target?.result as string
    const isLDP = JSON.stringify(content)?.includes('context')
    const vcFormat = !isLDP ? 'jwt_vc' : 'ldp_vc'
    let jsonObj = ''

    if (isLDP) {
        jsonObj = content && JSON.parse(content?.toString())
    } else {
        jsonObj =
            content && JSON.parse(`{"vc": ${JSON.stringify(content?.trim())}}`)
    }

    const credData = {
        // @ts-ignore
        vc: isLDP ? jsonObj : jsonObj?.vc,
        vcFormat: vcFormat,
    }

    return credData
}
