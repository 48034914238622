import { RootState } from '../../../store/indexReducers'

// export const getBlocks = (state:RootState): IBlock[] => state.organizationReducer.services
export const getorgID = (state: RootState) => state.organizationReducer.did
export const getOrgName = (state: RootState) => state.organizationReducer.name
export const getOrg = (state: RootState) => state.organizationReducer.org
export const getOrgIsLoading = (state: RootState) =>
    state.organizationReducer.orgLoading
export const getIfTermsAreAccepted = (state: RootState) =>
    state.organizationReducer.termsAccepted
export const creatingOrgLoading = (state: RootState) =>
    state.organizationReducer.creatingOrgLoading
export const getOrgByIdStatus = (state: RootState) =>
    state.organizationReducer.loadingOrgByIdStatus
