import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { sessionSelectors } from '../../../../features/common/session'
import {
    ButtonModel,
    ImageModel,
    StepModel,
    SwitchButtonModel,
} from '../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../utils/permissionsUtil'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import SwitchButton from '../../../atoms/buttons/SwitchButton/SwicthButton'
import ScreenTitleIcon from '../../../atoms/icons/ScreenTitleIcon/ScreenTitleIcon'
import Description from '../../../atoms/texts/Description/Description'
import Subtext from '../../../atoms/texts/Subtext/Subtext'
import NumberStepper from '../../../Stepper/numberStepper/NumberStepper'
import styles from './screenHeader.module.scss'
import cx from 'classnames'

import LightButton from '../../../atoms/buttons/LightButton/LightButton'
type ScreenHeaderProps = {
    id?: string
    title: string
    icon?: ImageModel
    buttonScopes?: string[]
    className?: string
    titleClassname?: string
    subTextClassname?: string
    descriptionClassname?: string
    descriptionTextClassname?: string
    subText?: string
    button?: ButtonModel
    previousStepsAreInactive?: boolean
    buttonIsLight?: boolean
    hideBorderBottom?: boolean
    buttonClassname?: string
    switchButton?: SwitchButtonModel
    switchCheckedValue?: string
    onChangeSwitchSelect?: ((x?: any) => void) | any
    buttonFunction?: ((x?: any) => void) | any
    description?: string
    steps?: StepModel[]
    currentStep?: StepModel
    nextStepActive?: boolean
    onChangeStep?: (x?) => void
}

const ScreenHeader: React.FC<ScreenHeaderProps> = React.memo((props) => {
    const {
        id,
        title,
        icon,
        buttonScopes,
        className,
        buttonClassname,
        hideBorderBottom,
        previousStepsAreInactive,
        subText,
        button,
        buttonIsLight,
        switchCheckedValue,
        switchButton,
        buttonFunction,
        description,
        steps,
        currentStep,
        onChangeStep,
        onChangeSwitchSelect,
        titleClassname,
        subTextClassname,
        descriptionClassname,
        descriptionTextClassname,
        nextStepActive,
    } = props
    const { t } = useTranslation()

    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const hasScopes = !buttonScopes
        ? true
        : availableScopes
        ? userHasScopes(buttonScopes || [], availableScopes)
        : false

    useEffect(() => {}, [availableScopes])
    useEffect(() => {}, [buttonFunction])
    return (
        <div
            id={id}
            className={`${styles.screenHeader} ${className ? className : ''}`}
        >
            <div
                className={`${styles.screenHeader__topBar} ${
                    hideBorderBottom ? '' : styles.borderBottom
                }`}
            >
                <div className={styles.screenHeader__topBar__title}>
                    {icon ? <ScreenTitleIcon icon={icon} /> : null}
                    <div>
                        <h2
                            className={cx(
                                'neutral1000 heading4',
                                titleClassname
                            )}
                        >
                            {t(title)}
                        </h2>
                        {subText ? (
                            <Subtext
                                className={cx(
                                    'bodyRegularLG neutral700',
                                    subTextClassname
                                )}
                                text={subText}
                            />
                        ) : null}
                    </div>
                </div>
                {button && hasScopes ? (
                    buttonIsLight ? (
                        <LightButton
                            className={`${
                                (cx('margin-left-auto'),
                                styles.modal__header__primaryButton)
                            } ${buttonClassname ? buttonClassname : ''}`}
                            functionality={
                                buttonFunction ? buttonFunction : null
                            }
                            text={button?.label || ''}
                            icon={
                                !button?.disabled
                                    ? button?.image
                                    : button.disabledIcon
                            }
                            disabled={!!button?.disabled}
                        />
                    ) : (
                        <DarkButton
                            className={`${
                                styles.modal__header__primaryButton
                            } ${buttonClassname ? buttonClassname : ''}`}
                            functionality={
                                buttonFunction ? buttonFunction : null
                            }
                            text={button?.label || ''}
                            icon={
                                !button?.disabled
                                    ? button?.image
                                    : button.disabledIcon
                            }
                            disabled={!!button?.disabled}
                            disabledTooltip={button.disabledTooltip}
                            hideIconTooltip={button.hideIconTooltip}
                            disabledTooltipLabel={button.disabledTooltipLabel}
                        />
                    )
                ) : null}
                {switchButton ? (
                    <SwitchButton
                        checkedValue={switchCheckedValue || ''}
                        options={switchButton.options}
                        onChangeSwitchSelect={switchButton.function}
                    />
                ) : null}
                {steps && currentStep && onChangeStep ? (
                    <NumberStepper
                        previousStepsAreInactive={previousStepsAreInactive}
                        onChangeStep={onChangeStep}
                        steps={steps}
                        currentStep={currentStep}
                        nextStepActive={nextStepActive}
                    />
                ) : null}
            </div>
            {description ? (
                <div
                    className={`${styles.screenHeader__description} ${
                        descriptionClassname ? descriptionClassname : ''
                    }`}
                >
                    <Description
                        className={`${
                            descriptionTextClassname
                                ? cx(descriptionTextClassname)
                                : cx('bodyRegularLG')
                        } ${cx('neutral700')}`}
                        text={description}
                    />
                </div>
            ) : null}
        </div>
    )
})

export default ScreenHeader
