import cx from 'classnames'
import React from 'react'
import styles from './modal.module.scss'
import { t } from 'i18next'
import ChevronLeftIcon from '../../../../assets/iconsLibrary/simple/chevronLeft/chevronLeftIcon'
import TrashIcon from '../../../../assets/iconsLibrary/simple/trash/trashIcon'
import { gaColors } from '../../../../assets/globalConstants'
import CloseIcon from '../../../../assets/iconsLibrary/simple/close/closeIcon'
import { ButtonModel } from '../../../../interfaces/interfaces'
import LightButton from '../../../atoms/buttons/LightButton/LightButton'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../data/globalVar'

type IGaModalProps = {
    show?: boolean
    title?: string
    backButton?: boolean
    closeButton?: boolean
    trashButton?: boolean
    selectedItem?:
        | any
        | {
              [x: string]: string | any[]
          }
    content?: React.ReactNode
    footerLeftButton?: ButtonModel
    footerRightButton?: ButtonModel
    className?: string
    hideModal: (x?: boolean) => void
    deleteFunctionality?: (x?: boolean) => void
    closeParentModal?: (x?: boolean) => void
    deleteCred?: (x?: any) => void
}

const GaModal: React.FC<IGaModalProps> = (props) => {
    const {
        title,
        backButton,
        closeButton,
        trashButton,
        content,
        show,
        footerLeftButton,
        footerRightButton,
        className,
        hideModal,
        deleteFunctionality,
        closeParentModal,
    } = props

    // Functions
    const hideModalAndClean = () => {
        hideModal()
    }

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            }`}
        >
            <div className={cx(styles.modal, className && className)}>
                <div className={styles.modal__header}>
                    {backButton ? (
                        <div
                            className={styles.modal__header__close}
                            onClick={() => hideModalAndClean()}
                        >
                            <ChevronLeftIcon
                                size={32}
                                color={gaColors.neutral1000}
                            />
                        </div>
                    ) : null}
                    {title ? (
                        <p className={cx('bodyRegularMD neutral1000')}>
                            {t(title)}
                        </p>
                    ) : null}
                    <div
                        className={cx(styles.modal__header__delete)}
                        onClick={() =>
                            trashButton
                                ? deleteFunctionality && deleteFunctionality()
                                : closeButton
                                ? closeParentModal
                                    ? closeParentModal()
                                    : hideModal()
                                : null
                        }
                    >
                        {trashButton ? (
                            <TrashIcon size={24} color={gaColors.alert300} />
                        ) : closeButton ? (
                            <CloseIcon size={24} color={gaColors.neutral1000} />
                        ) : null}
                    </div>
                </div>
                <div className={cx(styles.modal__content)}>{content}</div>
                {footerRightButton || footerLeftButton ? (
                    <div className={styles.modal__footer}>
                        {footerLeftButton ? (
                            <LightButton
                                className={styles.modal__footer__lightButton}
                                functionality={footerLeftButton?.function}
                                text={footerLeftButton?.label || ''}
                                disabled={footerLeftButton?.disabled || false}
                                size={ButtonSizes.MD}
                            />
                        ) : null}
                        {footerRightButton ? (
                            <DarkButton
                                className={styles.modal__footer__primaryButton}
                                functionality={footerRightButton?.function}
                                text={footerRightButton?.label || ''}
                                disabled={footerRightButton?.disabled || false}
                                size={ButtonSizes.MD}
                            />
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default GaModal
