import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
    ButtonModel,
    ctaLinkModel,
    LinkModel,
} from '../../interfaces/interfaces'
import LightButton from '../atoms/buttons/LightButton/LightButton'
import GaImage from '../atoms/Image/Image'
import Description from '../atoms/texts/Description/Description'
import formPanelStyles from './FormPanel.module.scss'
import DarkButton from '../atoms/buttons/DarkButton/DarkButton'

type FormPanelProps = {
    topLink?: LinkModel
    title?: string
    legend?: string
    link?: LinkModel
    primaryButton: ButtonModel
    primaryFunction: Function | void
    primaryButtonDisabled?: boolean
    ctaLink?: ctaLinkModel
    ctaSeparator?: string
    signUpLink?: boolean
    secondaryButton?: ButtonModel
    secondaryFunction?: Function | void
    onSubmit?: (e?) => void
}

const FormPanel: React.FC<FormPanelProps> = React.memo((props) => {
    const {
        topLink,
        title,
        legend,
        link,
        primaryButton,
        primaryFunction,
        primaryButtonDisabled,
        ctaLink,
        ctaSeparator,
        secondaryButton,
        onSubmit,
        secondaryFunction,
        signUpLink,
        children,
    } = props
    const { t } = useTranslation()

    const handleKeyPress = (e: any) => {
        if (e.keyCode === 13 || e.which === 13) {
            onSubmit ? onSubmit(e) : null
        }
    }

    return (
        <>
            <div className={formPanelStyles.formContainer}>
                {topLink ? (
                    <div className={cx('text-align-left')}>
                        <Link
                            className={formPanelStyles.topLink}
                            to={topLink.route}
                        >
                            {t(topLink.label)}
                        </Link>
                    </div>
                ) : null}
                <div className={formPanelStyles.formContainer__panel}>
                    {title ? (
                        <h3 className={cx('neutral1000 heading6')}>
                            {t(title)}
                        </h3>
                    ) : null}

                    <form onSubmit={onSubmit} onKeyDown={handleKeyPress}>
                        {legend ? (
                            <Description
                                className={cx(
                                    'neutral700 bodyRegularSM',
                                    formPanelStyles.legend
                                )}
                                text={legend}
                            />
                        ) : null}

                        {children}

                        <div>
                            {link ? (
                                <Link
                                    className={formPanelStyles.link}
                                    to={link.route}
                                >
                                    {t(link.label)}
                                </Link>
                            ) : null}
                        </div>

                        <div className={formPanelStyles.ctaBar}>
                            {signUpLink ? (
                                <p className={formPanelStyles.signUpLink}>
                                    {t('login.dontHaveAccountYet')}
                                    <Link to={'email-signup'}>
                                        {t('login.signUpNow')}
                                    </Link>
                                </p>
                            ) : null}
                            {ctaLink ? (
                                <Link
                                    className={formPanelStyles.ctaLink}
                                    to={ctaLink.route}
                                >
                                    {ctaLink.image ? (
                                        <GaImage image={ctaLink.image} />
                                    ) : null}
                                    <p>{t(ctaLink.label)}</p>
                                </Link>
                            ) : null}
                            {ctaSeparator ? (
                                <div className={formPanelStyles.ctaSeparator}>
                                    <span
                                        className={
                                            formPanelStyles.ctaSeparator__text
                                        }
                                    >
                                        {t(ctaSeparator)}
                                    </span>
                                </div>
                            ) : null}
                            {secondaryButton ? (
                                <LightButton
                                    className={formPanelStyles.ctaLink}
                                    functionality={
                                        secondaryFunction
                                            ? secondaryFunction
                                            : null
                                    }
                                    text={secondaryButton.label}
                                    icon={secondaryButton.image}
                                    disabled={false}
                                />
                            ) : null}
                            <DarkButton
                                className={`${formPanelStyles.ctaButton} ${
                                    primaryButtonDisabled
                                        ? formPanelStyles.disabled
                                        : ''
                                }`}
                                functionality={
                                    primaryFunction ? primaryFunction : null
                                }
                                text={primaryButton.label}
                                icon={primaryButton.image}
                                disabled={primaryButtonDisabled || false}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
})

FormPanel.defaultProps = {}

export default FormPanel
