import { Epic, ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import dataService from '../../services/dataService'
import { showNotification } from '../../features/common/Notification/notificationActions'
import {
    deleteAppIntegrationFailed,
    deleteAppIntegrationSuccess,
    getAppIntegrations,
    getAppIntegrationsFailed,
    getAppIntegrationsSuccess,
    getScopesFailed,
    getScopesSuccess,
    getSecretFailed,
    getSecretSuccess,
    updateAppIntegrationFailed,
    updateAppIntegrationSuccess,
} from './appIntegrationsActions'
import {
    ConfigAppIntegrationActionsTypes,
    AppIntegrationsListActionsTypes,
} from './appIntegrationsTypes'
import { hideScreenLoaderAction } from '../../features/common/Loader/loaderActions'
import {
    sendAppIntegrationDataFailed,
    sendAppIntegrationDataSuccess,
} from '../appIntegrationModal/appIntegrationModalActions'

// LIST
export const requestAppIntegrationsEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(AppIntegrationsListActionsTypes.GET_APP_INTEGRATIONS),
        switchMap((): any => {
            return from(dataService.getAppIntegrations()).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(getAppIntegrationsSuccess(response.response))
                    )
                }),
                catchError((error) =>
                    of(
                        getAppIntegrationsFailed(error),
                        showNotification(
                            'appIntegrations.getAppIntsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const requestScopesEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(AppIntegrationsListActionsTypes.GET_SCOPES),
        switchMap((): any => {
            return from(dataService.getAppIntScopes()).pipe(
                mergeMap((response?: any) => {
                    return merge(of(getScopesSuccess(response.response)))
                }),
                catchError((error) =>
                    of(
                        getScopesFailed(error),
                        // TODO: Change not
                        showNotification(
                            'appIntegrations.getAppIntsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

// CONFIG APP INTEGRATIONS
export const requestSecretEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConfigAppIntegrationActionsTypes.GET_SECRET),
        switchMap((action: any): any => {
            const appInt = action.payload.appInt
            return from(dataService.generateAppIntSecret(appInt)).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(
                            getSecretSuccess(response?.response),
                            hideScreenLoaderAction(),
                            showNotification(
                                'appIntegrations.regenerateSecretSuccess',
                                'success',
                                ''
                            )
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        getSecretFailed(error),
                        hideScreenLoaderAction(),
                        showNotification(
                            'appIntegrations.regenerateSecretError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

// UPDATE

export const updateAppIntegrationEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ConfigAppIntegrationActionsTypes.UPDATE_APP_INTEGRATION),
        switchMap((action: any): any => {
            const appInt = action.payload.appIntegration

            return from(dataService.updateAppIntegration(appInt)).pipe(
                mergeMap(() => {
                    return of(
                        updateAppIntegrationSuccess(),
                        getAppIntegrations(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'appIntegrations.edit.successUpdate',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        updateAppIntegrationFailed(error),
                        getAppIntegrations(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'appIntegrations.edit.errorUpdate',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deleteAppIntegrationEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(AppIntegrationsListActionsTypes.DELETE_APP_INTEGRATION),
        switchMap((action: any): any => {
            const appId = action.payload.id
            return from(dataService.deleteAppIntegration(appId)).pipe(
                mergeMap(() => {
                    return of(
                        deleteAppIntegrationSuccess(),
                        hideScreenLoaderAction(),
                        getAppIntegrations(),
                        showNotification(
                            'appIntegrations.deleteAppIntSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        deleteAppIntegrationFailed(error),
                        hideScreenLoaderAction(),
                        showNotification(
                            'appIntegrations.errorGettingScopes',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const createAppIntegrationDataEpic: Epic = (action$) => {
    return action$.pipe(
        ofType(ConfigAppIntegrationActionsTypes.CREATE_APP_INTEGRATION),
        switchMap((action): any => {
            const client = action.payload?.appIntegration

            return from(dataService.createAppIntegrations(client)).pipe(
                mergeMap((response?: any) => {
                    return of(
                        hideScreenLoaderAction(),
                        sendAppIntegrationDataSuccess(response?.response)
                    )
                }),
                catchError((error) =>
                    of(
                        hideScreenLoaderAction(),
                        sendAppIntegrationDataFailed(error)
                    )
                )
            )
        })
    )
}
