import {
    UserCredentialSelection,
    UserSelectedCredentials,
} from '../../interfaces/interfaces'

import {
    ScanModalListActionsTypes,
    IGetProcessAction,
    IGetProcessFailed,
    IGetProcessSuccessAction,
    ISendProcessDataAction,
    ISendProcessDataSuccessAction,
    ISendProcessDataFailed,
    IClearStateAction,
    ISetProcessDataAction,
} from './scanModalTypes'

// LIST
export const getProcess = (
    profile: string,
    request: string
): IGetProcessAction => {
    return {
        type: ScanModalListActionsTypes.GET_PROCCESS,
        payload: {
            profile,
            request,
        },
    }
}

export const getProcessSuccess = (
    data: UserCredentialSelection
): IGetProcessSuccessAction => {
    return {
        type: ScanModalListActionsTypes.GET_PROCCESS_SUCCESS,
        payload: { data: data },
    }
}

export const getProcessFailed = (error: string): IGetProcessFailed => {
    return {
        type: ScanModalListActionsTypes.GET_PROCCESS_FAILED,
        payload: {
            error: error,
        },
    }
}

export const setProcessData = (
    profile: string,
    data: UserSelectedCredentials
): ISetProcessDataAction => {
    return {
        type: ScanModalListActionsTypes.SET_PROCESS_DATA,
        payload: {
            profile,
            data,
        },
    }
}

export const sendProcessData = (
    profile: string,
    data: UserSelectedCredentials
): ISendProcessDataAction => {
    return {
        type: ScanModalListActionsTypes.SEND_PROCESS_DATA,
        payload: {
            profile,
            data,
        },
    }
}

export const sendProcessDataSuccess = (): ISendProcessDataSuccessAction => {
    return {
        type: ScanModalListActionsTypes.SEND_PROCESS_DATA_SUCCESS,
    }
}

export const sendProcessDataFailed = (
    error: string
): ISendProcessDataFailed => {
    return {
        type: ScanModalListActionsTypes.SEND_PROCESS_DATA_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearModalState = (): IClearStateAction => {
    return {
        type: ScanModalListActionsTypes.CLEAR_STATE,
    }
}
