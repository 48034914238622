import cx from 'classnames'
import { t } from 'i18next'
import React from 'react'
import styles from './certificateCells.module.scss'
import { CertificateModel } from '../../../../../../interfaces/interfaces'
import TrashIcon from '../../../../../../assets/iconsLibrary/simple/trash/trashIcon'

const getStatusColorAndText = (status?: string) => {
    let colorAndText
    if (status === 'EXPIRED') {
        colorAndText = {
            color: 'red',
            text: 'certificates.expired',
        }
    } else if (status === 'ACTIVE') {
        colorAndText = {
            color: 'green',
            text: 'certificates.active',
        }
    }
    return colorAndText
}

const getActionOptions = (status?: string) => {
    let options
    if (status === 'ACTIVE' || status === 'EXPIRED') {
        options = []
    }
    return {
        options: options,
        color: options
            ? options[0]?.value === 'ACTIVE'
                ? 'green'
                : 'yellow'
            : '',
        text: options ? options[0]?.text : '',
    }
}

const setColorClass = (color) => {
    let colorClass = 'green'
    switch (color) {
        case 'yellow':
            colorClass = styles.yellow
            break
        case 'green':
            colorClass = styles.green
            break
        case 'red':
            colorClass = styles.red
            break
        case 'gaBlue':
            colorClass = styles.gaBlue
            break
        default:
            colorClass = styles.green
    }

    return colorClass
}

const getActionCellContent = (x, disabled) => {
    if (getActionOptions) {
        return {
            color: disabled ? 'grey' : getActionOptions(x)?.color || 'gaBlue',
            text: getActionOptions(x)?.text || x?.text,
            selectOptions: getActionOptions(x)?.options,
        }
    }
}

// CELLS COMPONENTS

export type TableContentProps = {
    item: CertificateModel
    index?: number
    cantChangeCertificateStatus?: boolean
    selectItem?: (x?) => void
    deleteItem?: (x, y?, z?) => void
    certificate?: (x?) => void
    rejectCertificate?: (x?) => void
    handleDropdownChange?: (x, y, z?) => void
}

export const Status: React.FC<TableContentProps> = (props) => {
    const { item, index } = props

    return (
        <td
            key={item.id + index?.toString()}
            className={`${cx('testAlignLeft')}`}
        >
            <div className={styles.certificateStatus}>
                {/* <GaTag
                    color={getStatusColorAndText(item?.data?.status)?.color}
                    text={''}
                />
                <span
                    className={`${setColorClass(
                        getStatusColorAndText(item?.data?.status)?.color
                    )}`}
                >
                    {t(getStatusColorAndText(item?.data?.status)?.text)}{' '}
                </span> */}
            </div>
        </td>
    )
}

// Commented until status or expiration date comes from back
// export const CreationDate: React.FC<TableContentProps> = (props) => {
//     const { item, index } = props
//     return (
//         <td>
//             <Moment format="DD/MM/YYYY">{item?.data?.createdAt}</Moment>
//         </td>
//     )
// }

// export const StatusActions: React.FC<TableContentProps> = (props) => {
//     const {
//         item,
//         cantChangeCertificateStatus,
//         index,
//         handleDropdownChange,
//     } = props

//     return (
//         <td>
//             {getActionCellContent(
//                 item?.data?.status,
//                 cantChangeCertificateStatus
//             )?.text ? (
//                 <div className={styles.certificateAction}>
//                     <GaTag
//                         color={
//                             getActionCellContent(
//                                 item?.data?.status,
//                                 cantChangeCertificateStatus
//                             )?.color || 'gaBlue'
//                         }
//                         text={
//                             getActionOptions(item?.data?.status)?.text ||
//                             item?.data?.status
//                         }
//                     />
//                     {!cantChangeCertificateStatus && (
//                         <DropdownTable
//                             onHandleChange={
//                                 handleDropdownChange
//                                     ? handleDropdownChange
//                                     : () => {}
//                             }
//                             selectOptions={
//                                 getActionCellContent(
//                                     item?.data?.status,
//                                     cantChangeCertificateStatus
//                                 )?.selectOptions
//                             }
//                             item={item}
//                             icon={gaImages.angleDown}
//                             iconClassName={styles.action__icon}
//                             className={styles.action__iconContainer}
//                         />
//                     )}
//                 </div>
//             ) : (
//                 ''
//             )}
//         </td>
//     )
// }

export const DetailLink: React.FC<TableContentProps> = (props) => {
    const { item, index, selectItem } = props

    return (
        <td>
            <span
                onClick={() => (selectItem ? selectItem(item) : null)}
                className={styles.detailLink}
            >
                {t('certificates.detailData')}
            </span>
        </td>
    )
}

export const DeleteButton: React.FC<TableContentProps> = (props) => {
    const { item, index, deleteItem } = props

    return (
        <td>
            {deleteItem ? (
                <TrashIcon
                    className={styles.bin}
                    onClick={() => deleteItem(item)}
                    size={24}
                    color="#1E1E20"
                />
            ) : null}
            {/* TODO: Uncomment when table has more than one option available
                <DropdownTable
                setActive={setIsDropdownActive}
                onHandleChange={handleOptions}
                selectOptions={tableItemOptions}
                item={item}
                left={true}
                noLayer={true}
                icon={gaImages.infoTable}
                iconClassName={styles.action__icon}
                className={styles.action__iconContainer}
            /> */}
        </td>
    )
}
