import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { apiKeysSelectors } from '../..'
import GaTextInput from '../../../../../Components/atoms/formFields/textInput/GaTextInput'
import MediumModal from '../../../../../Components/molecules/modals/Medium/MediumModal'
import styles from './apiKeyPassModal.module.scss'

type IApiKeyPassModalProps = {
    show: boolean
    hideModal: () => void
}

const ApiKeyPassModal: React.FC<IApiKeyPassModalProps> = (props) => {
    const { hideModal, show } = props
    const { t } = useTranslation()
    const apiKeyData = useSelector(apiKeysSelectors.getSelectedApiKey)

    return (
        <>
            <MediumModal
                title={'apiKeys.passwordModal.reminder'}
                description={'apiKeys.passwordModal.description'}
                headerClose={true}
                show={show}
                hideFooter
                handleClose={hideModal}
            >
                <div className={styles.apiKeyPassModal__content}>
                    <GaTextInput
                        id={'akid'}
                        invalid={false}
                        labelText={t('apiKeys.passwordModal.apiKeyId')}
                        name={'akid'}
                        copyOption
                        placeholder={t('apiKeys.passwordModal.apiKeyId')}
                        value={apiKeyData?.id}
                    />
                    <GaTextInput
                        className={cx('marginTop20')}
                        id={'password'}
                        invalid={false}
                        labelText={t('apiKeys.passwordModal.apiKeyPassword')}
                        name={'password'}
                        copyOption
                        placeholder={t('apiKeys.passwordModal.apiKeyPassword')}
                        value={apiKeyData?.password}
                        //type={'password'}
                    />
                </div>
            </MediumModal>
        </>
    )
}

//TODO: Use code snippets to allow copy to clipboard?
//<CodeSnippet type="single">{apiKeyData?.password}</CodeSnippet>
export default ApiKeyPassModal
