import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import {
    ICatalogModel,
    ICredentialAuthorityModel,
    ICredentialServiceProviderModel,
    ICredentialsGroupModel,
    ICredentialTypeModel,
} from '../../../interfaces/interfaces'
import dataService from '../../../services/dataService'
import { showNotification } from '../Notification/notificationActions'
import {
    getCatalog,
    getCatalogError,
    getCatalogSuccess,
    mergePrivateCatalog,
    updateCredentialStatus,
} from './catalogActions'
import { CredentialActionTypes } from './catalogTypes'

export const getCatalogEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(CredentialActionTypes.GET_CATALOG),
        switchMap((action: any): any => {
            return from(dataService.getCatalog()).pipe(
                mergeMap((response: any) => {
                    const catalog = response?.response
                    return of(mergePrivateCatalog(catalog))
                }),
                catchError((error) => of(getCatalogError(error)))
            )
        })
    )
}

export const mergePrivateCatalogEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(CredentialActionTypes.GET_PRIVATE_CATALOG),
        switchMap((action: any): any => {
            return from(dataService.getPrivateCatalog()).pipe(
                mergeMap((response: any) => {
                    const catalog = mergeCatalogs(
                        action.payload.catalog,
                        response?.response
                    )
                    return of(getCatalogSuccess(catalog))
                }),
                catchError((error) =>
                    of(getCatalogSuccess(action.payload.catalog))
                )
            )
        })
    )
}

export const revokeCredentialEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(CredentialActionTypes.CREDENTIAL_REVOKE),
        switchMap((action: any): any => {
            return from(
                dataService.changeCredentialStatus(action.payload.id, 'REVOKED')
            ).pipe(
                mergeMap((response: any) => {
                    return of(
                        updateCredentialStatus(action.payload.id, 'REVOKED')
                    )
                }),
                catchError((error) =>
                    of(
                        showNotification(
                            'Error revoking credential',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const suspendCredentialEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(CredentialActionTypes.CREDENTIAL_SUSPEND),
        switchMap((action: any): any => {
            return from(
                dataService.changeCredentialStatus(
                    action.payload.id,
                    'SUSPENDED'
                )
            ).pipe(
                mergeMap((response: any) => {
                    return of(
                        updateCredentialStatus(action.payload.id, 'SUSPENDED')
                        // showNotification(
                        //     'Credential status changed:',
                        //     'success',
                        //     action.payload.id + ' SUSPENDED'
                        // )
                    )
                }),
                catchError(
                    (error) => of()
                    // showNotification(
                    //     'Error suspending credential',
                    //     'error',
                    //     error.response?.message
                    // )
                )
            )
        })
    )
}

export const reactivateCredentialEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(CredentialActionTypes.CREDENTIAL_REACTIVATE),
        switchMap((action: any): any => {
            return from(
                dataService.changeCredentialStatus(action.payload.id, 'ISSUED')
            ).pipe(
                mergeMap((response: any) => {
                    return of(
                        updateCredentialStatus(action.payload.id, 'ISSUED')
                        // showNotification(
                        //     'Credential status changed:',
                        //     'success',
                        //     action.payload.id + ' ISSUED'
                        // )
                    )
                }),
                catchError(
                    (error) => of()
                    // showNotification(
                    //     'Error reactivating credential',
                    //     'error',
                    //     error.response?.message
                    // )
                )
            )
        })
    )
}

export const addIssuerToGroupEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(CredentialActionTypes.ADD_TO_GROUP),
        switchMap((action: any): any => {
            let group = state$.value?.catalogReducer?.credentialGroups?.find(
                (group) => group.id === action.payload.groupId
            ) as ICredentialsGroupModel
            group.issuerList?.push({
                id: action.payload.issuerDid,
                publicRequest: false,
            })
            return from(
                dataService.addCredentialGroup(action.payload.groupId, group)
            ).pipe(
                mergeMap((response: any) => {
                    return of(getCatalog())
                }),
                catchError((error) =>
                    of(
                        getCatalogError(error),
                        showNotification(
                            'credentials.getCatalogError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const removeIssuerFromGroupEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(CredentialActionTypes.REMOVE_FROM_GROUP),
        switchMap((action: any): any => {
            let group = state$.value?.catalogReducer?.credentialGroups?.find(
                (group) => group.id === action.payload.groupId
            ) as ICredentialsGroupModel
            let index = group?.issuerList?.findIndex(
                (iss) => iss.id === action.payload.issuerDid
            )
            if (index && index >= 0) {
                group.issuerList?.splice(index)
            }
            return from(
                dataService.addCredentialGroup(action.payload.groupId, group)
            ).pipe(
                mergeMap(() => {
                    return of(getCatalog())
                }),
                catchError((error) =>
                    of(
                        getCatalogError(error),
                        showNotification(
                            'credentials.getCatalogError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const deletePrivateGroupEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(CredentialActionTypes.DELETE_GROUP),
        switchMap((action: any): any => {
            return from(
                dataService.deleteCredentialGroup(action.payload.groupId)
            ).pipe(
                mergeMap(() => {
                    return of(getCatalog())
                }),
                catchError((error) =>
                    of(
                        getCatalogError(error),
                        showNotification(
                            'credentials.getCatalogError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

function mergeCatalogs(
    full: ICatalogModel,
    privateCatalogue: ICatalogModel
): ICatalogModel {
    for (let key of Object.keys(full)) {
        let catalog = full[key] as (
            | ICredentialAuthorityModel
            | ICredentialsGroupModel
            | ICredentialTypeModel
            | ICredentialServiceProviderModel
        )[]
        if (privateCatalogue[key]) {
            const pcat = privateCatalogue[key] as (
                | ICredentialAuthorityModel
                | ICredentialsGroupModel
                | ICredentialTypeModel
                | ICredentialServiceProviderModel
            )[]
            catalog = catalog.map((data) => {
                if (pcat.find((p) => p.id === data.id)) {
                    data.isPrivate = true
                }
                return data
            })
        }
        full[key] = catalog
    }
    return full
}
