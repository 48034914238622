import { ActionStatusTypes } from '../../assets/globalConstants'
import { EWCredential, IEWNotificationModel } from '../../interfaces/interfaces'

// LIST
export interface IGetUnreadNotificationsAction {
    type: NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS
    payload: {
        profile: string
    }
}

export interface IGetUnreadNotificationsSuccessAction {
    type: NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS
    payload: {
        notifications: IEWNotificationModel[]
    }
}

export interface IGetUnreadNotificationsFailed {
    type: NotificationsActionsTypes.GET_UNREAD_NOTIFICATIONS_FAILED
    payload: {
        error: string
    }
}

export interface IDownloadCredentialAction {
    type: NotificationsActionsTypes.DOWNLOAD_CREDENTIAL
    payload: {
        profile: string
        id: string
    }
}

export interface IDownloadCredentialSuccessAction {
    type: NotificationsActionsTypes.DOWNLOAD_CREDENTIAL_SUCCESS
    payload: {
        credential: EWCredential
    }
}

export interface IDownloadCredentialFailed {
    type: NotificationsActionsTypes.DOWNLOAD_CREDENTIAL_FAILED
    payload: {
        error: string
    }
}

export interface IDeleteNotification {
    type: NotificationsActionsTypes.DELETE_NOTIFICATION
    payload: {
        profile: string
        id: string
    }
}

export interface IDeleteNotificationSuccess {
    type: NotificationsActionsTypes.DELETE_NOTIFICATION_SUCCESS
}

export interface IDeleteNotificationFailed {
    type: NotificationsActionsTypes.DELETE_NOTIFICATION_FAILED
    payload: {
        error: string
    }
}

export interface IClearNotificationsList {
    type: NotificationsActionsTypes.CLEAR_NOTIFICATIONS_LIST
}

export interface ISetReadedNotifications {
    type: NotificationsActionsTypes.SET_READ_NOTIFICATIONS
    payload: {
        readedNotifications?: IEWNotificationModel[]
    }
}

export interface ISetReadedNotificationsNotSeen {
    type: NotificationsActionsTypes.SET_READ_NOTIFICATIONS_NOT_SEEN
    payload: {
        readedNotifications?: IEWNotificationModel[]
    }
}

export interface IStartNotificationsPollingAction {
    type: NotificationsActionsTypes.START_NOTIFICATIONS_POLLING
    payload: {
        interval: number
        fetch: any
    }
}

export interface IStopNotificationsPollingAction {
    type: NotificationsActionsTypes.STOP_NOTIFICATIONS_POLLING
}

export type NotificationsActions =
    | IStartNotificationsPollingAction
    | IStopNotificationsPollingAction
    | IGetUnreadNotificationsAction
    | IGetUnreadNotificationsSuccessAction
    | IGetUnreadNotificationsFailed
    | IDownloadCredentialAction
    | IDownloadCredentialSuccessAction
    | IDownloadCredentialFailed
    | IDeleteNotification
    | IDeleteNotificationSuccess
    | IDeleteNotificationFailed
    | IClearNotificationsList
    | ISetReadedNotifications
    | ISetReadedNotificationsNotSeen

export enum NotificationsActionsTypes {
    START_NOTIFICATIONS_POLLING = 'notifications/start_polling',
    STOP_NOTIFICATIONS_POLLING = 'notifications/stop_polling',
    GET_UNREAD_NOTIFICATIONS = 'notifications/get_unread_list',
    GET_UNREAD_NOTIFICATIONS_SUCCESS = 'notifications/get_unread_list_success',
    GET_UNREAD_NOTIFICATIONS_FAILED = 'notifications/get_unread_list_failed',
    DOWNLOAD_CREDENTIAL = 'notifications/download_credential',
    DOWNLOAD_CREDENTIAL_SUCCESS = 'notifications/download_credential_success',
    DOWNLOAD_CREDENTIAL_FAILED = 'notifications/download_credential_failed',
    DELETE_NOTIFICATION = 'notifications/delete_notification',
    DELETE_NOTIFICATION_SUCCESS = 'notifications/delete_notification_success',
    DELETE_NOTIFICATION_FAILED = 'notifications/delete_notification_failed',
    CLEAR_NOTIFICATIONS_LIST = 'notifications/clear_notifications_list',
    SET_READ_NOTIFICATIONS = 'notifications/set_readed',
    SET_READ_NOTIFICATIONS_NOT_SEEN = 'notifications/set_readed_not_seen',
}

export interface INotificationsState {
    notificationsList?: IEWNotificationModel[]
    getNotificationListStatus: ActionStatusTypes
    getNoticicationStatus: ActionStatusTypes
    downloadCredStatus: ActionStatusTypes
    deleteNotificationStatus: ActionStatusTypes
    readNotifications?: IEWNotificationModel[]
    readNotificationsNotSeen?: IEWNotificationModel[]
    polling: boolean
    pollingId: any
}
