import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { gaImages } from '../../../../../assets/globalConstants'
import { ImageModel } from '../../../../../interfaces/interfaces'
import GaImage from '../../../../atoms/Image/Image'
import styles from './warningPanel.module.scss'
import cx from 'classnames'
import CloseIcon from '../../../../../assets/iconsLibrary/simple/close/closeIcon'

type IWarningPanelProps = {
    label: string
    label2?: string
    label3?: string
    hasIcon?: boolean
    hasTitle?: boolean
    icon?: ImageModel
    IconComponent?: any
    className?: string
    closeAction?: (x?: any) => void
}

const WarningPanel: React.FC<IWarningPanelProps> = (props) => {
    const {
        label,
        label2,
        label3,
        hasIcon,
        hasTitle,
        icon,
        IconComponent,
        className,
        closeAction,
    } = props
    const { t } = useTranslation()

    return (
        <div className={cx(styles.warningPanel, className && className)}>
            <div className={styles.warningPanel__header}>
                {hasIcon &&
                    (IconComponent ? (
                        IconComponent
                    ) : (
                        <GaImage image={icon || gaImages.warningFlag} />
                    ))}
                {hasTitle && (
                    <p className={styles.warningPanel__header__title}>
                        {t('public.warning')}
                    </p>
                )}
            </div>
            <div className={styles.warningPanel__content}>
                <p>
                    <Trans i18nKey={label} />
                </p>
                <p>{label2 && <Trans i18nKey={label2} />}</p>
                <p>{label3 && <Trans i18nKey={label3} />}</p>
            </div>
            {closeAction ? (
                <div onClick={closeAction} className={styles.closeIcon}>
                    <CloseIcon size={24} />
                </div>
            ) : null}
        </div>
    )
}

export default WarningPanel
