import React from 'react'
import styles from './imageView.module.scss'
import cx from 'classnames'
import { gaColors } from '../../../../../../assets/globalConstants'
import CloseIcon from '../../../../../../assets/iconsLibrary/simple/close/closeIcon'
import DarkButton from '../../../../../atoms/buttons/DarkButton/DarkButton'
import { ButtonSizes } from '../../../../../../data/globalVar'

type ImageViewProps = {
    imageURI: string
    className?: string
    hideView?: () => void
}

const ImageView: React.FC<ImageViewProps> = (props) => {
    const { imageURI, className, hideView } = props

    return (
        <div
            className={cx(
                styles.backgroundLayer,
                styles.visibleModal,
                className && className
            )}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div
                        className={cx(styles.modal__header__close)}
                        onClick={hideView}
                    >
                        <CloseIcon size={24} color={gaColors.neutral1000} />
                    </div>
                </div>
                <div className={`${styles.modal__content}`}>
                    <img
                        className={styles.photoContainer__image}
                        src={imageURI}
                    />
                </div>
                <div className={`${styles.modal__footer}`}>
                    <DarkButton
                        className={styles.modal__footer__primaryButton}
                        functionality={hideView}
                        text={'ew.modalScan.serviceRequirements.hide'}
                        disabled={false}
                        size={ButtonSizes.MD}
                    />
                </div>
            </div>
        </div>
    )
}

export default ImageView
