import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { securityFactorsList } from '../../../../../../../assets/globalConstants'
import GaCheckbox from '../../../../../../../Components/atoms/formFields/checkbox/GaCheckbox'
import GaSelect from '../../../../../../../Components/atoms/formFields/select/GaSelect'
import Description from '../../../../../../../Components/atoms/texts/Description/Description'
import Modal from '../../../../../../../Components/molecules/modals/Base/Modal'
import {
    ICredentialRequestedModel,
    ISecurityConfig,
} from '../../../../../../../interfaces/interfaces'
import {
    modalPrimaryButton,
    modalSecondaryButton,
} from '../../../../issuance.constants'
import style from './secondFactorModal.module.scss'
import { LabelPosition } from '../../../../../../../data/globalVar'
import GaSelectCheckbox, {
    Selectable,
} from '../../../../../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
type ISecondFactorModalProps = {
    show: boolean
    credential?: ICredentialRequestedModel
    selected2AF?: ISecurityConfig
    setSecondFactors: (x, y) => void
    hideModal: () => void
}

const SecondFactorModal: React.FC<ISecondFactorModalProps> = (props) => {
    const { hideModal, selected2AF, setSecondFactors, show } = props
    const { t } = useTranslation()

    const [secondFactor, setSecondFactor] = useState(
        selected2AF?.accepted && selected2AF?.accepted[0]
            ? selected2AF?.accepted[0]
            : securityFactorsList[0]?.value
    )

    const acceptedFactorsLength = selected2AF?.accepted?.length
    const backup = selected2AF?.accepted?.slice(1, acceptedFactorsLength)

    const getSecondFactorBackup = (): Selectable[] => {
        const securityFactorsListArr = securityFactorsList?.filter(
            (e) => e?.value !== secondFactor
        )
        let mapped = securityFactorsListArr.map((factor, index) => {
            return { key: '' + index || '', value: t(factor?.text) || '' }
        })
        return mapped
    }

    const secondFactors = getSecondFactorBackup()

    const backupKeys = backup?.map((backupEl) => {
        return (
            secondFactors?.find(
                (el) => !!el?.value && !!el?.key && el?.value === backupEl
            )?.key || ''
        )
    })

    const [secondFactorBackup, setSecondFactorBackup] = useState({
        hasBackup: !!(
            selected2AF?.accepted?.length && selected2AF?.accepted?.length > 1
        ),
        backup: backup && !!backup[0] ? backupKeys : undefined,
    })

    useEffect(() => {
        setSecondFactor(
            selected2AF?.accepted && selected2AF?.accepted[0]
                ? selected2AF?.accepted[0]
                : securityFactorsList[0]?.value
        )
        setSecondFactorBackup({
            hasBackup: !!(
                selected2AF?.accepted?.length &&
                selected2AF?.accepted?.length > 1
            ),
            backup: backup && !!backup[0] ? backupKeys : undefined,
        })
    }, [selected2AF])

    const hideAndCleanModal = () => {
        setSecondFactorBackup({ hasBackup: false, backup: undefined })
        setSecondFactor(securityFactorsList[0]?.value)
        hideModal()
    }

    const handleCheckboxChange = (factor) => {
        setSecondFactorBackup({ ...secondFactorBackup, backup: factor })
    }
    const setSecondFactorWithBackUp = (factorIsModified?: boolean) => {
        let secondFactorFormatted = new Array()
        secondFactorFormatted.push(secondFactor)

        const backupNames = secondFactorBackup?.backup?.map((backupEl) => {
            return secondFactors?.find((el) => el?.key && el?.key === backupEl)
                ?.value
        })
        secondFactorFormatted = secondFactorFormatted.concat(
            backupNames?.filter((x) => !!x)
        )

        setSecondFactors(selected2AF, {
            type: 'AuthNFactor',
            accepted: secondFactorFormatted,
        })
        hideAndCleanModal()
        hideModal()
    }

    return !!show && !!secondFactor ? (
        <Modal
            title={'editIssuance.step5.addingSecondFactor'}
            primaryButton={modalPrimaryButton}
            secondaryButton={modalSecondaryButton}
            primaryButtonFunction={setSecondFactorWithBackUp}
            show={show}
            handleClose={hideAndCleanModal}
            className={style.modal}
        >
            <GaSelect
                idSelect={'privateKeyType'}
                className={cx('margin-bottom')}
                value={secondFactor}
                id="select-privateKeyType"
                invalid={false}
                labelText={t('editIssuance.step5.selectSecondFactor')}
                name={'privateKeyType'}
                optionTextProperty={'text'}
                optionValueProperty={'value'}
                onChange={(e) => {
                    setSecondFactor(e.target.value)
                    setSecondFactorBackup({
                        hasBackup: false,
                        backup: undefined,
                    })
                }}
                options={securityFactorsList}
            />

            {!!secondFactor ? (
                <GaCheckbox
                    index={'hasBackup'}
                    itemText={t('editIssuance.step5.twoAuthFactorBackupLabel')}
                    id="hasBackup"
                    checked={!!secondFactorBackup?.hasBackup}
                    onChange={() =>
                        setSecondFactorBackup({
                            hasBackup: !secondFactorBackup?.hasBackup,
                            backup: new Array(),
                        })
                    }
                    position={LabelPosition.right}
                />
            ) : null}

            {!!secondFactorBackup.hasBackup ? (
                <div className={cx('marginTop24')}>
                    <GaSelectCheckbox
                        id={'secondFactorBackup'}
                        onChange={(e) => {
                            handleCheckboxChange(e)
                        }}
                        required
                        checkedData={secondFactorBackup?.backup}
                        options={getSecondFactorBackup()}
                        labelText={t('editIssuance.step5.selectBackupLabel')}
                    />
                    <Description
                        className={cx('bodyRegularCap neutral600 marginTop4')}
                        text={'editIssuance.step5.selectBackupAuxText'}
                    />
                </div>
            ) : null}
        </Modal>
    ) : null
}

export default SecondFactorModal
