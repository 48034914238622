import { t } from 'i18next'
import {
    IScopesRequestedModel,
    OptionsModel,
} from '../../../../../../../interfaces/interfaces'
import moment from 'moment'

export const scopeOptions: OptionsModel[] = [
    {
        name: t('appIntegrations.ageVerificationOver', { age: 16 }),
        icon: 'userPlus',
        id: 'over16',
    },
    {
        name: t('appIntegrations.ageVerificationOver', { age: 18 }),
        icon: 'userPlus',
        id: 'over18',
    },
    {
        name: t('appIntegrations.ageVerificationOver', { age: 21 }),
        icon: 'userPlus',
        id: 'over21',
    },
    {
        name: t('appIntegrations.ageVerificationOver', { age: 65 }),
        icon: 'userPlus',
        id: 'over65',
    },
    {
        name: t('public.email'),
        icon: 'email',
        id: 'email',
    },
]

export const selectableScopeOptions = scopeOptions?.map((scope) => {
    return { key: scope?.id, value: scope?.name }
})

export const ScopeValues = {
    over16: moment().subtract(16, 'years').format('DD/MM/YYYY'),
    over18: moment().subtract(18, 'years').format('DD/MM/YYYY'),
    over21: moment().subtract(21, 'years').format('DD/MM/YYYY'),
    over65: moment().subtract(65, 'years').format('DD/MM/YYYY'),
    email: 'user@gataca.io',
}

export const scopeCredentials: IScopesRequestedModel[] = scopeOptions?.map(
    (scope) => {
        return {
            groupId: scope?.icon,
            name: scope?.name as string,
            key: scope?.id,
            value: ScopeValues[scope?.id],
        }
    }
)
