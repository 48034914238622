import { ICatalogModel } from '../../../interfaces/interfaces'
import {
    CredentialActionTypes,
    IAddIssuerToGroup,
    ICredentialRevoke,
    IDeletePrivateGroup,
    IGetCatalog,
    IGetCatalogSuccess,
    IGetCatalogSuccessError,
    IMergePrivateCatalog,
    IReactivateCredential,
    IRemoveIssuerFromGroup,
    ISuspendCredential,
    IUpdateCredential,
} from './catalogTypes'

// LIST
export const getCatalog = (): IGetCatalog => {
    return {
        type: CredentialActionTypes.GET_CATALOG,
    }
}

export const mergePrivateCatalog = (
    catalog: ICatalogModel
): IMergePrivateCatalog => {
    return {
        type: CredentialActionTypes.GET_PRIVATE_CATALOG,
        payload: {
            catalog: catalog,
        },
    }
}

export const getCatalogSuccess = (
    catalog: ICatalogModel
): IGetCatalogSuccess => {
    return {
        type: CredentialActionTypes.GET_CATALOG_SUCCESS,
        payload: {
            catalog: catalog,
        },
    }
}

export const getCatalogError = (error): IGetCatalogSuccessError => {
    return {
        type: CredentialActionTypes.GET_CATALOG_ERROR,
        error: error,
    }
}

// ACTIONS
export const credentialRevoke = (id: string): ICredentialRevoke => {
    return {
        type: CredentialActionTypes.CREDENTIAL_REVOKE,
        payload: {
            id: id,
        },
    }
}

export const suspendCredential = (id: string): ISuspendCredential => {
    return {
        type: CredentialActionTypes.CREDENTIAL_SUSPEND,
        payload: {
            id: id,
        },
    }
}

export const reactivateCredential = (id: string): IReactivateCredential => {
    return {
        type: CredentialActionTypes.CREDENTIAL_REACTIVATE,
        payload: {
            id: id,
        },
    }
}

export const updateCredentialStatus = (id, status): IUpdateCredential => {
    return {
        type: CredentialActionTypes.CREDENTIAL_UPDATE,
        payload: {
            id: id,
            status: status,
        },
    }
}

export const addIssuerToGroup = (
    issuerId: string,
    groupId: string
): IAddIssuerToGroup => {
    return {
        type: CredentialActionTypes.ADD_TO_GROUP,
        payload: {
            issuerDid: issuerId,
            groupId: groupId,
        },
    }
}

export const removeIssuerFromGroup = (
    issuerId: string,
    groupId: string
): IRemoveIssuerFromGroup => {
    return {
        type: CredentialActionTypes.REMOVE_FROM_GROUP,
        payload: {
            issuerDid: issuerId,
            groupId: groupId,
        },
    }
}

export const deletePrivateGroup = (groupId: string): IDeletePrivateGroup => {
    return {
        type: CredentialActionTypes.DELETE_GROUP,
        payload: {
            groupId: groupId,
        },
    }
}
