import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { rolesSelectors } from '../../../store'
import LightButton from '../../../../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaSelectCheckbox, {
    Selectable,
} from '../../../../../../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import GaTextInput from '../../../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import { IRoleModel } from '../../../../../../../../interfaces/interfaces'
import DarkButton from '../../../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import styles from '../../../views/roles.module.scss'
import { propertiesToSearchAllScopes } from '../../../../../constants/team.constants'

type IUpsertFormProps = {
    currentRole?: IRoleModel
    isNewRol?: boolean
    allScopes: Selectable[]
    buttonFunction: (x?) => void
    closeModal: () => void
}

type IUpsertFormState = {
    role: IRoleModel
    valid: boolean
}

const UpsertForm: React.FC<IUpsertFormProps> = (props) => {
    const { currentRole, isNewRol, allScopes, buttonFunction, closeModal } =
        props
    const { t } = useTranslation()
    const scopes = useSelector(rolesSelectors.getScopesSelectables)

    const initialFormState: IUpsertFormState = {
        role: currentRole || {
            role_id: '',
            scopes: [],
        },
        valid: true,
    }
    const [formData, setFormState] = useState(initialFormState)
    const { role } = formData

    const validForm = !!role?.role_id?.trim() && role?.scopes?.length > 0

    const [state, setState] = React.useState<{
        selections: Selectable[]
        searched: string
        searchedResult: Selectable[]
    }>({
        selections: [],
        searched: '',
        searchedResult: allScopes || [],
    })
    useEffect(() => {
        setFormState(initialFormState)
    }, [currentRole])

    useEffect(() => {}, [scopes, allScopes])

    function handleSearchedChange(e) {
        setState({
            selections: state.selections,
            searched: state.searched,
            searchedResult: e,
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        buttonFunction(formData?.role)
    }

    return (
        <>
            <form onSubmit={onSubmit}></form>
            <GaTextInput
                className={cx('margin-bottom')}
                id={'roleid'}
                labelText={t('upsertRole.roleId')}
                required={false}
                name={'roleid'}
                onChange={(e) => {
                    setFormState({
                        ...formData,
                        role: { ...role, role_id: e.target.value },
                    })
                }}
                readOnly={!isNewRol}
                placeholder={t('upsertRole.roleId_placeholder')}
                value={role?.role_id}
            />

            <GaSelectCheckbox
                id={'scopesCheckbox'}
                className={cx('margin-top margin-bottom')}
                invalid={!role?.scopes?.length}
                labelText={t('upsertRole.scopes')}
                required={true}
                name={'tenants'}
                isLoading={!(allScopes?.length > 0)}
                onChange={(e) => {
                    setFormState({
                        ...formData,
                        role: {
                            ...role,
                            scopes: e,
                        },
                    })
                }}
                options={allScopes}
                checkedData={role.scopes}
                handleSearchedChange={handleSearchedChange}
                propertiesToSearch={propertiesToSearchAllScopes}
            />
            <div className={styles.buttonsContainer}>
                <LightButton
                    className={cx('margin-left-auto')}
                    text={'public.cancel'}
                    disabled={false}
                    functionality={() => closeModal()}
                />
                <DarkButton
                    text={'public.save'}
                    disabled={!validForm}
                    functionality={() => buttonFunction(formData?.role)}
                />
            </div>
        </>
    )
}

export default UpsertForm
