import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import style from './gaSelect.module.scss'
import cx from 'classnames'
import { sessionSelectors } from '../../../../features/common/session'
import { PermissionsDataModel } from '../../../../interfaces/interfaces'
import { getPropertyByLang } from '../../../../utils/formatDataUtil'
import { userHasScopes } from '../../../../utils/permissionsUtil'
import PermissionsPanel from '../../../molecules/notification/panels/permissionsPanel/PermissionsPanel'
import InputSkeleton from '../../../molecules/skeletons/inlineSkeleton/InputSkeleton'
import ChevronDownIcon from '../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import { TextSizes, TooltipPosition } from '../../../../data/globalVar'
import ChevronUpIcon from '../../../../assets/iconsLibrary/simple/chevronUp/chevronUpIcon'
import GaHoverTooltip from '../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'

type GaSelectProps = {
    className?: string
    id?: string
    idSelect: string
    labelText?: string
    placeholder?: string
    helperText?: string
    extraDescription?: string
    value?: string
    disabled?: boolean
    name?: string
    required?: boolean
    invalid?: boolean
    options?: any[]
    optionTextProperty?: string
    optionTextPropertyTranslated?: boolean
    optionValueProperty?: string
    autoComplete?: string
    isLoading?: boolean
    permissions?: PermissionsDataModel
    textSize?: TextSizes
    readOnly?: boolean
    tooltip?: boolean
    infoText?: string
    onChange?: (e?) => void
}

const GaSelect: React.FC<GaSelectProps> = React.memo((props) => {
    const {
        className,
        id,
        idSelect,
        labelText,
        placeholder,
        helperText,
        extraDescription,
        value,
        disabled,
        name,
        required,
        invalid,
        options,
        optionTextProperty,
        optionTextPropertyTranslated,
        optionValueProperty,
        autoComplete,
        isLoading,
        permissions,
        textSize,
        readOnly,
        tooltip,
        infoText,
        onChange,
    } = props
    const { t } = useTranslation()

    // Vars
    const sizeFontClass = {
        LG: 'bodyRegularMD',
        MD: 'bodyRegularSM',
    }

    const comboValueDisplay =
        optionTextProperty &&
        optionValueProperty &&
        value &&
        options?.find((option) => option[optionValueProperty] === value)

    const valueDisplay =
        comboValueDisplay && optionTextProperty
            ? optionTextPropertyTranslated
                ? getPropertyByLang(comboValueDisplay[optionTextProperty])
                : t(comboValueDisplay[optionTextProperty])
            : value

    const [availableOptions, setOptions] = useState(new Array())
    const [touched, setTouched] = useState(false)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const [selectedValue, setSelectedValue] = useState(valueDisplay)
    const [ariaSelected, setAriaSelected] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null)

    const hasScopes = !permissions?.scopes
        ? true
        : availableScopes
        ? userHasScopes(
              permissions?.scopes || [],
              availableScopes,
              permissions?.every
          )
        : false

    // Effects
    useEffect(() => {}, [availableScopes])

    useEffect(() => {}, [selectedValue])

    useEffect(() => {
        setSelectedValue(valueDisplay)
    }, [value])

    useEffect(() => {
        options && setOptions(options)
    }, [options])

    useEffect(() => {
        const handler = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setShowDropdown(false)
            }
        }

        window.addEventListener('click', handler)
        return () => {
            window.removeEventListener('click', handler)
        }
    })

    // Functions
    const onItemClick = (item) => {
        const itemValue = optionValueProperty ? item[optionValueProperty] : item
        const changeParam = {
            target: {
                value: itemValue,
                name: name,
            },
        }

        const itemValueDisplay = optionTextProperty
            ? item[optionTextProperty]
            : item

        setSelectedValue(t(itemValueDisplay))
        setAriaSelected(true)
        onChange && onChange(changeParam)
    }

    return (
        <div
            className={`${className} ${style.gaSelectContainer} ${
                required ? style.required : ''
            } ${disabled ? style.readOnly : ''} ${
                readOnly ? style.readOnly : ''
            }`}
        >
            {!permissions?.scopes || hasScopes ? (
                !isLoading ? (
                    <>
                        {labelText || extraDescription ? (
                            <div className={style.topTextsContainer}>
                                {labelText ? (
                                    <label
                                        className={`${cx(
                                            'bodyRegularXS neutral700'
                                        )}`}
                                    >
                                        {labelText}
                                        {tooltip && infoText ? (
                                            <GaHoverTooltip
                                                info={infoText && infoText}
                                                position={TooltipPosition.right}
                                            />
                                        ) : null}
                                    </label>
                                ) : null}
                                {extraDescription ? (
                                    <span
                                        className={`${cx(
                                            'bodyRegularCap neutral600'
                                        )} ${style.extraDescription} ${
                                            !labelText ? style.noLabel : ''
                                        }`}
                                    >
                                        {extraDescription}
                                    </span>
                                ) : null}
                            </div>
                        ) : null}

                        {/* For screens below 672px */}
                        <select
                            className={`${cx(sizeFontClass[textSize || 'LG'])}`}
                            disabled={disabled || readOnly}
                            id={id ? id : labelText || '' + Math.random()}
                            name={name ? name : ''}
                            onChange={(e) => {
                                onChange ? onChange(e) : () => {}
                            }}
                            onBlur={() => setTouched(true)}
                            required={required || false}
                            autoComplete={autoComplete ? autoComplete : 'Off'}
                            placeholder={
                                placeholder
                                    ? placeholder
                                    : t('general.chooseAnOption')
                            }
                            value={value || ''}
                        >
                            {!!availableOptions?.length
                                ? availableOptions?.map((item, index) => {
                                      return (
                                          <option
                                              key={index}
                                              label={
                                                  optionTextProperty
                                                      ? optionTextPropertyTranslated
                                                          ? getPropertyByLang(
                                                                item[
                                                                    optionTextProperty
                                                                ]
                                                            )
                                                          : t(
                                                                item[
                                                                    optionTextProperty
                                                                ]
                                                            )
                                                      : item
                                              }
                                              value={
                                                  optionValueProperty
                                                      ? item[
                                                            optionValueProperty
                                                        ]
                                                      : item
                                              }
                                              disabled={item?.disabled || false}
                                          />
                                      )
                                  })
                                : null}
                        </select>
                        {/* For screens above 672px - Custom select*/}
                        <div
                            id={
                                idSelect ? idSelect : name || '' + Math.random()
                            }
                            className={style.selectCustomContainer}
                        >
                            <input
                                ref={inputRef}
                                className={`${cx(
                                    sizeFontClass[textSize || 'LG']
                                )}`}
                                onClick={() =>
                                    setShowDropdown(
                                        (showDropdown) => !showDropdown
                                    )
                                }
                                onChange={(e) => {
                                    onChange ? onChange(e) : () => {}
                                }}
                                disabled={disabled || readOnly}
                                placeholder={
                                    placeholder
                                        ? placeholder
                                        : t('general.chooseAnOption')
                                }
                                value={selectedValue}
                                name={name ? name : ''}
                                id={id ? id : labelText || '' + Math.random()}
                                autoComplete={
                                    autoComplete ? autoComplete : 'Off'
                                }
                                required={required || false}
                                role="combobox"
                                type="text"
                                aria-autocomplete="list"
                                aria-expanded={showDropdown}
                                aria-controls={
                                    id ? id : labelText || '' + Math.random()
                                }
                                readOnly
                            />
                            {showDropdown && !!availableOptions?.length ? (
                                <div
                                    className={style.optionsCustomContainer}
                                    role="listbox"
                                    aria-label="Choose an item"
                                    onBlur={() => setTouched(true)}
                                    placeholder={
                                        placeholder
                                            ? placeholder
                                            : t('general.chooseAnOption')
                                    }
                                >
                                    {!!availableOptions?.length
                                        ? availableOptions?.map(
                                              (item, index) => {
                                                  return (
                                                      <div
                                                          className={`${style.menuItem}`}
                                                          role="option"
                                                          aria-selected={
                                                              ariaSelected
                                                          }
                                                          key={index}
                                                          id={
                                                              optionValueProperty
                                                                  ? item[
                                                                        optionValueProperty
                                                                    ]
                                                                  : item
                                                          }
                                                          onClick={() => {
                                                              onItemClick(item)
                                                          }}
                                                          aria-disabled={
                                                              disabled ||
                                                              readOnly
                                                          }
                                                      >
                                                          <div
                                                              className={`${
                                                                  style.menuItem__option
                                                              } ${cx(
                                                                  'buttonSM neutral1000'
                                                              )}`}
                                                          >
                                                              {optionTextProperty
                                                                  ? optionTextPropertyTranslated
                                                                      ? getPropertyByLang(
                                                                            item[
                                                                                optionTextProperty
                                                                            ]
                                                                        )
                                                                      : t(
                                                                            item[
                                                                                optionTextProperty
                                                                            ]
                                                                        )
                                                                  : item}
                                                          </div>
                                                      </div>
                                                  )
                                              }
                                          )
                                        : null}
                                </div>
                            ) : null}
                        </div>
                        {showDropdown ? (
                            <ChevronUpIcon size={24} />
                        ) : (
                            <ChevronDownIcon size={24} />
                        )}

                        {helperText ? (
                            <ul
                                className={`${style.bottomTextsContainer} ${cx(
                                    'marginTop4'
                                )}`}
                            >
                                {helperText ? (
                                    <li
                                        key="helperText"
                                        className={`${cx(
                                            'bodyRegularCap neutral600'
                                        )}`}
                                    >
                                        {helperText}
                                    </li>
                                ) : null}
                            </ul>
                        ) : null}
                    </>
                ) : (
                    <InputSkeleton
                        className={className || ''}
                        labelText={labelText}
                        required={required}
                    />
                )
            ) : (
                <PermissionsPanel
                    scopes={permissions?.scopes}
                    labelText={labelText}
                    requiredLabel={required}
                />
            )}
        </div>
    )
})

export default GaSelect
