import { t } from 'i18next'
import {
    CellContentTypes,
    gaImages,
    TextAlignTypes,
} from '../../../../../assets/globalConstants'
import { ButtonModel, StepModel } from '../../../../../interfaces/interfaces'
import CredentialsForm from '../../forms/didKeys/KeysForm'
import IdentifierForm from '../../forms/identifier/IdentifierForm'
import RegisterForm from '../../forms/register/RegisterForm'
import TokenForm from '../../forms/token/TokenForm'

export const configFormSteps: StepModel[] = [
    {
        id: 1,
        label: 'createDid.identifier',
        order: 0,
        component: IdentifierForm,
    },
    {
        id: 2,
        label: 'createDid.keys',
        order: 1,
        component: CredentialsForm,
    },
    {
        id: 3,
        label: 'createDid.tokenConfiguration',
        order: 2,
        component: TokenForm,
    },
]

export const createFormSteps: StepModel[] = [
    {
        id: 1,
        label: 'createDid.register',
        order: 0,
        component: RegisterForm,
    },
]

export const headerButton: ButtonModel = {
    label: 'dids.newDid',
    hideIconTooltip: true,
    disabledTooltipLabel: t('subscription.limitReachedDescription', {
        feature: t('subscription.limitedFeatures.dids'),
    }),
}

export const propertiesToSearch = [
    {
        text: 'DidId',
        searchMultipleLanguages: false,
        latinize: false,
    },
    {
        text: 'alias',
        searchMultipleLanguages: false,
        latinize: true,
    },
]

export const modalHeaderButton: ButtonModel = {
    label: '',
    image: gaImages.close,
}

export const modalPrimaryButton: ButtonModel = {
    label: 'public.create',
}

export const modalPrimaryEditButton: ButtonModel = {
    label: 'public.save',
}

export const modalSecondaryButton: ButtonModel = {
    label: 'public.cancel',
}

export const headersDidsTable = [
    {
        key: 'DidId',
        text: 'dids.id',
        tag: true,
        type: CellContentTypes.tag,
        short: true,
    },
    {
        key: 'createdAt',
        text: 'dids.creationDate',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.date,
    },
    {
        key: 'type',
        text: 'dids.type',
        textAlign: TextAlignTypes.center,
        type: CellContentTypes.action,
    },
    {
        key: 'actions',
        text: 'credentials.actions',
        textAlign: TextAlignTypes.right,
        type: CellContentTypes.action,
    },
]

export const didsScopes = {
    read: ['readDids'],
    edit: ['updateDids'],
    create: ['createDids'],
    delete: ['deleteDids'],
}

export const firstDid = { didMethod: 'gatc', alias: 'My DID' }
