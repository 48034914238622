import React from 'react'

type BellIconProps = {
    style?: 'default' | 'filled' | 'active'
    size?: number
    color?: string
    className?: string
}

const BellIcon: React.FC<BellIconProps> = React.memo((props) => {
    const { size, style, color, className } = props

    return (
        <svg
            className={className && className}
            width={size || '32'}
            height={size || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {style === 'active' ? (
                <ActiveIconPaths color={color} />
            ) : style === 'filled' ? (
                <SelectedIconPaths color={color} />
            ) : (
                <DeafaultIconPaths color={color} />
            )}
        </svg>
    )
})

export const ActiveIconPaths = (props) => {
    return (
        <>
            <path
                d="M24.5164 16C25.5976 21.0651 28 22.6667 28 22.6667H4C4 22.6667 8 20 8 10.6667C8 8.54493 8.84286 6.5101 10.3431 5.00981C11.8434 3.50952 13.8783 2.66666 16 2.66666C16.2346 2.66666 16.4682 2.67697 16.7001 2.69733"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3067 28C18.0723 28.4041 17.7358 28.7395 17.331 28.9727C16.9262 29.2059 16.4672 29.3286 16 29.3286C15.5329 29.3286 15.0739 29.2059 14.6691 28.9727C14.2642 28.7395 13.9278 28.4041 13.6934 28"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M25.3334 13.3333C29.0153 13.3333 32.0001 10.3486 32.0001 6.66667C32.0001 2.98477 29.0153 0 25.3334 0C21.6515 0 18.6667 2.98477 18.6667 6.66667C18.6667 10.3486 21.6515 13.3333 25.3334 13.3333Z"
                fill={'#DC3D43'}
            />
        </>
    )
}

export const SelectedIconPaths = (props) => {
    return (
        <>
            <path
                d="M24 10.6667C24 8.54494 23.1571 6.51011 21.6569 5.00982C20.1566 3.50953 18.1217 2.66667 16 2.66667C13.8783 2.66667 11.8434 3.50953 10.3431 5.00982C8.84286 6.51011 8 8.54494 8 10.6667C8 20 4 22.6667 4 22.6667H28C28 22.6667 24 20 24 10.6667Z"
                fill={props?.color || 'currentColor' || '#1E1E20'}
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3067 28C18.0723 28.4041 17.7358 28.7395 17.331 28.9727C16.9262 29.2059 16.4672 29.3286 16 29.3286C15.5329 29.3286 15.0739 29.2059 14.6691 28.9727C14.2642 28.7395 13.9278 28.4041 13.6934 28"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export const DeafaultIconPaths = (props) => {
    return (
        <>
            <path
                d="M24 10.6667C24 8.54493 23.1571 6.5101 21.6569 5.00981C20.1566 3.50952 18.1217 2.66666 16 2.66666C13.8783 2.66666 11.8434 3.50952 10.3431 5.00981C8.84286 6.5101 8 8.54493 8 10.6667C8 20 4 22.6667 4 22.6667H28C28 22.6667 24 20 24 10.6667Z"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3067 28C18.0723 28.4041 17.7358 28.7395 17.331 28.9727C16.9262 29.2059 16.4672 29.3286 16 29.3286C15.5329 29.3286 15.0739 29.2059 14.6691 28.9727C14.2642 28.7395 13.9278 28.4041 13.6934 28"
                stroke={props?.color || 'currentColor' || '#1E1E20'}
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    )
}

export default BellIcon
