import { Epic, ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, delay, map, mergeMap, switchMap } from 'rxjs/operators'
import { IApiKeyModel } from '../../../interfaces/interfaces'
import dataService from '../../../services/dataService'
import { setReachedLimitsAction } from '../../common/licenses/licensesActions'
import { showNotification } from '../../common/Notification/notificationActions'
import { setSessionApiKeys } from '../../common/session/sessionActions'
import {
    createApiKeyFailed,
    createApiKeySuccess,
    deleteApiKeyFailed,
    getApiKeys,
    getApiKeysFailed,
    getApiKeysSuccess,
    getTenantConfigFailed,
    getTenantConfigSuccess,
    regenerateApiKeyFailed,
    regenerateApiKeySuccess,
    updateApiKeyFailed,
    updateApiKeySuccess,
} from './apiKeysActions'
import { ApiKeysActionTypes } from './apiKeysTypes'

export const getApiKeysEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ApiKeysActionTypes.GET_API_KEYS),
        delay(100),
        switchMap((action: any): any => {
            return from(dataService.getApiKeys()).pipe(
                mergeMap((response?: any) => {
                    return merge(
                        of(
                            setSessionApiKeys(
                                response.response?.map(
                                    (ak: IApiKeyModel) => ak.alias || ak.id
                                )
                            ),
                            getApiKeysSuccess(response.response)
                        )
                    )
                }),
                catchError((error) =>
                    of(
                        getApiKeysFailed(error),
                        showNotification(
                            'apiKeys.getApiKeysError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const createApiKeyEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ApiKeysActionTypes.CREATE_API_KEY),
        switchMap((action: any): any => {
            const app = action.payload.app

            return from(dataService.createApiKey(app)).pipe(
                mergeMap((response?: any) => {
                    return of(createApiKeySuccess(response?.response))
                }),
                catchError((error) => {
                    const limitReachedError = error?.status === 429
                    return of(
                        createApiKeyFailed(error),
                        limitReachedError
                            ? setReachedLimitsAction(
                                  'subscription.limitedFeatures.apiKeys'
                              )
                            : showNotification(
                                  'createApiKey.failedOperation',
                                  'error',
                                  error.response?.message,
                                  error?.status
                              )
                    )
                })
            )
        })
    )
}

export const deleteApiKeyEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ApiKeysActionTypes.DELETE_APP),
        switchMap((action: any): any => {
            const appID = action.payload.appID

            return from(dataService.deleteApiKey(appID)).pipe(
                map((response?: any) => {
                    return getApiKeys()
                }),
                catchError((error) => of(deleteApiKeyFailed(error)))
            )
        })
    )
}

export const updateApiKeyEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ApiKeysActionTypes.UPDATE_API_KEY),
        switchMap((action: any): any => {
            const app = action.payload.app
            const id = action.payload.id

            return from(dataService.updateApiKey(app, id)).pipe(
                mergeMap((response?: any) => {
                    return of(updateApiKeySuccess(), getApiKeys())
                }),
                catchError((error) =>
                    of(
                        updateApiKeyFailed(error),
                        showNotification(
                            app?.alias || app?.id,
                            'error',
                            'apiKeys.updateApiKey',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const regenerateApiKeyEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ApiKeysActionTypes.REGENERATE_API_KEY),
        switchMap((action: any): any => {
            const app = action.payload.app
            const id = app.id
            return from(dataService.regenerateApiKey(id)).pipe(
                mergeMap((response?: any) => {
                    return of(regenerateApiKeySuccess(response?.response))
                }),
                catchError((error) =>
                    of(
                        regenerateApiKeyFailed(error),
                        showNotification(
                            app?.alias || app?.id,
                            'error',
                            'apiKeys.updateApiKey',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const getTenantConfigEpic: Epic = (action$, state$) => {
    return action$.pipe(
        ofType(ApiKeysActionTypes.GET_TENANTS_CONFIG),
        switchMap((action: any): any => {
            return from(dataService.getTenantDids(action.payload.tenant)).pipe(
                map((response?: any) => {
                    const data = response?.response
                    return getTenantConfigSuccess(data)
                }),
                catchError((error) =>
                    of(
                        getTenantConfigFailed(error),
                        showNotification(
                            'apiKeys.getTenantConfigsError',
                            'error',
                            error.response?.message,
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
