import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    CellContentTypes,
    gaImages,
    TextAlignTypes,
} from '../../../../../assets/globalConstants'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import {
    IDataAgreementItem,
    PaginationProps,
} from '../../../../../interfaces/interfaces'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { sessionSelectors } from '../../../../common/session'
import {
    ConsentCreationDate,
    ConsentData,
    ConsentDeleteButton,
    ConsentDetailLink,
    ConsentUser,
} from './consentContent/ConsentsCells'
import { consentScopes } from './consentsList.constants'
import styles from './consentsTable.module.scss'
import ChevronDownIcon from '../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import { GaPagination } from '../../../../../Components/Pagination/Pagination'

export type ConsentsTableProps = {
    tableData: IDataAgreementItem[]
    headerData: {
        key: string
        text: string
        textAlign?: TextAlignTypes
        type?: CellContentTypes
        tag?: boolean
        short?: boolean
        tagText?: string
        action?: (x?) => void
    }[]
    noResults?: boolean
    paginationData?: PaginationProps
    className?: string
    itemOptions?: {
        text: string
        value: string
        color?: string
    }[]
    pending?: boolean
    canReadVerifiers?: boolean
    mobileItem?: ((x) => any) | ((x) => Element)
    selectItem?: (x?) => void
    deleteItem?: (x, y?, z?) => void
}

const ConsentsTable: React.FC<ConsentsTableProps> = (props) => {
    const {
        tableData,
        headerData,
        paginationData,
        noResults,
        pending,
        canReadVerifiers,
        deleteItem,
        mobileItem,
        selectItem,
    } = props
    const { t } = useTranslation()
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)

    const canDeleteVerifiedUsers = !!(
        availableScopes && userHasScopes(consentScopes?.delete, availableScopes)
    )

    useEffect(() => {}, [tableData])

    const getHeaderIcon = (type: CellContentTypes) => {
        let icon
        switch (type || '') {
            case CellContentTypes.date:
                icon = gaImages.clock
                break
            case CellContentTypes.action:
                icon = gaImages.arrowCircleDown
                break
            case CellContentTypes.modal:
                icon = gaImages.arrowCircleDown
                break
            case CellContentTypes.checkbox:
                icon = gaImages.checkbox
                break
            case CellContentTypes.detail:
                icon = gaImages.link
                break
            case CellContentTypes.icon:
                icon = gaImages.clock
                break
            case CellContentTypes.number:
                icon = gaImages.number
                break
            case CellContentTypes.text:
                icon = gaImages.textFile
                break
            default:
                icon = gaImages.textFile
        }
        return icon
    }

    const showPagination =
        paginationData?.totalItems &&
        paginationData.pageSize &&
        paginationData?.totalItems > paginationData.pageSize &&
        !noResults

    return (
        <>
            <LightPanel className={cx('marginTop32')}>
                <div className={styles.table}>
                    <table className="">
                        <thead>
                            <tr>
                                {headerData?.map((header, idx) => (
                                    <th
                                        className={cx('testAlignLeft')}
                                        key={header.key + idx + Math.random()}
                                    >
                                        <div>
                                            {header.text ? (
                                                <span
                                                    className={cx(
                                                        'bodyBoldXS neutral700'
                                                    )}
                                                >
                                                    {t(header.text)}
                                                </span>
                                            ) : null}
                                            <ChevronDownIcon
                                                color="#707074"
                                                size={16}
                                            />
                                        </div>
                                    </th>
                                ))}
                                {!pending && canDeleteVerifiedUsers ? (
                                    <th></th>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            {!!tableData
                                ? tableData?.map((item, idx) => (
                                      <>
                                          <tr
                                              data-id={idx}
                                              key={idx + Math.random()}
                                              className={
                                                  !!mobileItem
                                                      ? styles.table__longDisplay
                                                      : ''
                                              }
                                          >
                                              {!!item && (
                                                  <>
                                                      {/* Commented until we can know the template id
                                                  <ConsentTemplateCell
                                                      canReadVerifiers={
                                                          canReadVerifiers
                                                      }
                                                      item={item}
                                                  /> */}
                                                      <ConsentUser
                                                          item={item}
                                                      />
                                                      <ConsentCreationDate
                                                          item={item}
                                                      />
                                                      <ConsentData
                                                          item={item}
                                                      />
                                                      <ConsentDetailLink
                                                          item={item}
                                                          selectItem={
                                                              selectItem
                                                          }
                                                      />

                                                      {canDeleteVerifiedUsers && (
                                                          <ConsentDeleteButton
                                                              item={item}
                                                              deleteItem={
                                                                  deleteItem
                                                              }
                                                          />
                                                      )}
                                                  </>
                                              )}
                                          </tr>
                                          {!!mobileItem ? (
                                              <tr
                                                  className={
                                                      styles.table__mobileDisplay
                                                  }
                                                  key={
                                                      'mobile' + idx.toString()
                                                  }
                                              >
                                                  {mobileItem(item)}
                                              </tr>
                                          ) : null}
                                      </>
                                  ))
                                : null}
                        </tbody>
                    </table>
                </div>

                {noResults ? (
                    <Description
                        className={cx('margin-top')}
                        text={t('dids.noResults')}
                    />
                ) : null}
            </LightPanel>

            {paginationData && showPagination ? (
                <div className={cx('width100')}>
                    <GaPagination {...paginationData} />
                </div>
            ) : null}
        </>
    )
}

export default ConsentsTable
