import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { gaImages } from '../../../assets/globalConstants'
import { ActionModel, ImageModel } from '../../../interfaces/interfaces'
import GaImage from '../../atoms/Image/Image'
import Tag from '../../atoms/tag/Tag'
import Description from '../../atoms/texts/Description/Description'
import DropdownCard from '../../DropdownCard/DropdownCard'
import style from './basicCard.module.scss'

type IBasicCardProps = {
    id?: string
    item?: any
    icon?: ImageModel
    name: string
    description?: {
        text: string
        extraTranslationParams?: Object
    }
    actions?: ActionModel[]
    className?: string
    secondRightIcon?: ImageModel
    secondRightIconScopes?: string[]
    rightIcon?: ImageModel
    rightIconScopes?: string[]
    rightDate?: string
    tag?: {
        text: string
        color: string
    }
    tagFunction?: (e) => void
    buttonFunction: (e) => void
    secondButtonFunction?: (e) => void
}

const BasicCard: React.FC<IBasicCardProps> = (props) => {
    const {
        icon,
        name,
        item,
        description,
        tag,
        className,
        actions,
        secondRightIcon,
        rightIcon,
        rightDate,
        tagFunction,
        buttonFunction,
        secondButtonFunction,
    } = props
    const { t } = useTranslation()

    const handlePress = () => buttonFunction(item)
    const secondHandlePress = (e) => {
        if (secondButtonFunction) {
            secondButtonFunction(item)
            e.stopPropagation()
        }
    }

    return (
        <div
            className={`${style.basicCard} ${className ? className : ''}`}
            key={name + Math.random()}
            onClick={() => !actions && handlePress()}
        >
            <div
                className={`${style.basicCard__header} ${
                    rightDate ? style.flex_start : ''
                }`}
            >
                <div className={style.basicCard__header__title}>
                    {icon ? (
                        <GaImage
                            className={style.basicCard__header__title__icon}
                            image={icon}
                        />
                    ) : null}
                    <div className={style.basicCard__header__title__info}>
                        <p
                            className={cx(
                                'bodyBoldMD neutral1000',
                                style.basicCard__header__title__text
                            )}
                        >
                            {t(name)}
                        </p>
                        {description ? (
                            <Description
                                text={description?.text}
                                extraTranslationParams={
                                    description.extraTranslationParams
                                }
                                className={cx('bodyRegularXS neutral700')}
                            />
                        ) : null}
                        {tag && tag.text ? (
                            <div
                                onClick={() =>
                                    tagFunction ? tagFunction(item) : ''
                                }
                            >
                                {' '}
                                <Tag text={tag.text} color={tag.color} />
                            </div>
                        ) : null}
                    </div>
                </div>

                {!actions ? (
                    <div>
                        {secondRightIcon ? (
                            <button
                                onClick={(e) => secondHandlePress(e)}
                                className={`${style.basicCard__header__button} ${style.basicCard__header__button__two}`}
                            >
                                <GaImage
                                    image={secondRightIcon}
                                    className={
                                        style.basicCard__header__button__icon
                                    }
                                />
                            </button>
                        ) : null}
                        {rightIcon ? (
                            <button
                                onClick={() => handlePress()}
                                className={style.basicCard__header__button}
                            >
                                <GaImage
                                    image={rightIcon}
                                    className={
                                        style.basicCard__header__button__icon
                                    }
                                />
                            </button>
                        ) : null}
                        {rightDate ? (
                            <Moment
                                className={style.basicCard__header__date}
                                format="DD/MM/YYYY"
                            >
                                {rightDate}
                            </Moment>
                        ) : null}
                    </div>
                ) : (
                    <div>
                        <DropdownCard
                            selectOptions={actions}
                            item={item}
                            icon={gaImages.infoTable}
                            iconClassName={style.action__icon}
                            className={style.action__iconContainer}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default BasicCard
