import { applyPolyfills, defineCustomElements } from '@gataca/qr/loader'
import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import App from './App'
import './index.scss'
import store from './store/store'
// load global styles
import { brandImages, brandLabels } from './assets/globalConstants'
import { contentSP } from './data/csp'
import './styles/globals.module.scss'
import { getBrand } from './utils/brandThemeUtil'

applyPolyfills().then(() => {
    defineCustomElements(window)
})

const brand = getBrand()

ReactDOM.render(
    <div id="main" className={brand}>
        <Helmet>
            <link rel="icon" href={brandImages?.favicon?.src} />
            <meta http-equiv="Content-Security-Policy" content={contentSP} />
            <meta name="description" content={brandLabels?.studioName} />
            <title>{brandLabels?.studioName}</title>
            {brandLabels?.gtm ? (
                <script async src={brandLabels?.gtm}></script>
            ) : null}
        </Helmet>
        <Provider store={store()}>
            <App />
        </Provider>
    </div>,
    document.getElementById('root')
)

// If you want your store to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
