import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './stepsContent.module.scss'
import {
    ActionStatusTypes,
    brandLabels,
} from '../../../../../assets/globalConstants'
import GaCheckbox from '../../../../atoms/formFields/checkbox/GaCheckbox'
import { Trans } from 'react-i18next'
import { getIfTermsAreAccepted } from '../../../../../features/common/organization/organizationSelectors'
import { useDispatch, useSelector } from 'react-redux'
import ScanModal from '../ScanModal'
import { StepModalModel } from '../../../../../interfaces/interfaces'
import ScanModalCloseConfirmation from '../Status/CloseConfirmation/CloseConfirmation'
import CardListSkeleton from '../../../skeletons/cardListSkeleton/CardListSkeleton'
import { getFormattedCredsForConsent } from '../../../../../features/wallet/requestCredentials/utils/formatCredUtil'
import { catalogSelectors } from '../../../../../features/common/catalog'
import ConsentCredentialCard from '../../../../organisms/CredentialCard/ConsentCredentialCard/ConsentCredentialCard'
import ImageView from '../../../../organisms/CredentialCard/ConsentCredentialCard/components/ImageView/ImageView'
import ChangeCredential from '../Components/ChangeCred/ChangeCredential'
import { scanModalSelectors } from '../../../../../store/scanModal'
import {
    clearModalState,
    sendProcessData,
    setProcessData,
} from '../../../../../store/scanModal/scanModalActions'
import { didsSelectors } from '../../../../../features/studio/Dids'
import UnknowConsentError from '../Status/ConsentError/UnknowError/UnknowConsentError'
import ScanModalSuccess from '../Status/Success/Success'
import ScanModalLoading from '../Status/Loading/Loading'

type IAuthProcess2FA = {
    isConnection?: boolean
    scanCurrentStep: StepModalModel
    scanSteps: StepModalModel[]
    receiverDataName?: string
    setScanCurrentStep: (x?) => void
    hideModal: () => void
}

const ServiceRequirements: React.FC<IAuthProcess2FA> = (props) => {
    const {
        isConnection,
        scanCurrentStep,
        scanSteps,
        receiverDataName,
        setScanCurrentStep,
        hideModal,
    } = props
    const dispatch = useDispatch()

    // Selectors
    const sendingDataProcessStatus = useSelector(
        scanModalSelectors.sendingProcessDataStatus
    )
    const termsAreAccepted = useSelector(getIfTermsAreAccepted)
    const catalogGroups = useSelector(catalogSelectors.getCatalogGroups)
    const catalogIssuers = useSelector(catalogSelectors.getCatalogAuthority)
    const catalogTypes = useSelector(catalogSelectors.getCatalogTypes)
    const formattedProcess = useSelector(
        scanModalSelectors.getProccessWithFormattedCreds
    )
    const process = useSelector(scanModalSelectors.getProccess)
    const EWSelectedDid = useSelector(didsSelectors.getEWDid)
    const isFailed = sendingDataProcessStatus === ActionStatusTypes.failed

    // Constants
    let avaliableCredentialsRequired = formattedProcess?.available_credentials

    const formattedAvailableCredentials = avaliableCredentialsRequired?.length
        ? avaliableCredentialsRequired?.map((credList) => {
              return {
                  ...credList,
                  selectedCredIndex: credList?.selectedCredIndex || 0,
                  credentials: getFormattedCredsForConsent(
                      credList?.credentials,
                      catalogGroups,
                      catalogIssuers,
                      catalogTypes,
                      true
                  ),
              }
          })
        : undefined

    const requiredAvailableCredentials = formattedAvailableCredentials?.filter(
        (el) => !!el?.mandatory
    )
    const optionalAvailableCredentials = formattedAvailableCredentials?.filter(
        (el) => !el?.mandatory
    )

    const initialIndexes = Array.from(
        { length: formattedAvailableCredentials?.length },
        () => ({ selectedCredIndex: 0, accepted: false })
    )

    const initialSelectableCreds = {
        groupIndex: undefined,
        selectableCreds: undefined,
    }
    const [credentialsToSelect, setCredentialsToSelect] = useState<{
        groupIndex: number | undefined
        selectableCreds: any[] | undefined
    }>(initialSelectableCreds)

    // Effects
    useEffect(() => {
        avaliableCredentialsRequired = formattedProcess?.available_credentials
    }, [formattedProcess])

    useEffect(() => {
        if (sendingDataProcessStatus === ActionStatusTypes.success) {
            setIsLoading(false)
            setIsSuccess(true)
        } else if (sendingDataProcessStatus === ActionStatusTypes.failed) {
            setIsLoading(false)
        }
    }, [sendingDataProcessStatus])

    // States
    const [aceptedConditions, setConditionsState] = useState(termsAreAccepted)
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)
    const [imageToShowURI, setImageToShowURI] = useState<string | undefined>('')
    const [selectedIndexForMultipleCreds, setSelectedIndexForMultipleCreds] =
        useState(initialIndexes)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const [
        selectedCurrentIndexForMultipleCreds,
        setSelectedCurrentIndexForMultipleCreds,
    ] = useState(selectedIndexForMultipleCreds)

    // Functions
    const continueButtonFunction = () => {
        const data = getFormattedCredsToSend()
        sendData(data)
        // TODO: This will be used when 2FA is available
        // setScanCurrentStep(scanSteps[scanCurrentStep?.order + 1])
    }

    const sendData = (data) => {
        setIsLoading(true)
        data && dispatch(sendProcessData(EWSelectedDid || '', data))
    }

    const getFormattedCredsToSend = () => {
        const acceptedOrMandatoryCreds = formattedAvailableCredentials?.filter(
            (credList, index) => {
                const selectedCredsConfig = selectedIndexForMultipleCreds[index]
                return selectedCredsConfig?.accepted || !!credList?.mandatory
            }
        )

        const selectedCreds = acceptedOrMandatoryCreds?.map((el, index) => {
            const selectedCredsConfig = selectedIndexForMultipleCreds[index]
            el.credentials =
                el?.credentials[selectedCredsConfig?.selectedCredIndex]

            el.index = selectedCredsConfig?.selectedCredIndex
            return el
        })

        const originalProccess = JSON.parse(JSON.stringify(process))
        const result = originalProccess?.available_credentials?.map((el) => {
            const selectedCredIndex = selectedCreds?.find((list) => {
                return list?.descriptor_id === el?.descriptor_id
            })?.index

            if (el.credentials?.length) {
                el.credentials = new Array(el.credentials[selectedCredIndex])
            }

            return el
        })

        dispatch(
            setProcessData(EWSelectedDid || '', {
                process_id: process?.process_id || '',
                selected_credentials: result,
            })
        )

        return {
            process_id: process?.process_id || '',
            selected_credentials: result,
        }
    }

    return !showCloseConfirmation &&
        !credentialsToSelect?.selectableCreds?.length ? (
        <>
            {!isLoading && !isSuccess && !isFailed ? (
                <ScanModal
                    title={t('ew.modalScan.serviceRequirements.title')}
                    description={t(
                        'ew.modalScan.serviceRequirements.description'
                    )}
                    hideModal={() => setShowCloseConfirmation(true)}
                    continueButton={{
                        label: t('ew.modalScan.consent'),
                        disabled: !aceptedConditions,
                        function: continueButtonFunction,
                    }}
                    alert={{
                        type: 'info',
                        text: 'ew.modalScan.serviceRequirements.alert',
                    }}
                >
                    <div className={`${styles.bodyContainer}`}>
                        <div className={styles.credentialsContainer}>
                            {avaliableCredentialsRequired?.length ? (
                                <div className={styles.requirementsPreview}>
                                    {requiredAvailableCredentials?.length ? (
                                        <p
                                            className={cx(
                                                'marginBottom12 bodyRegularXS neutral800'
                                            )}
                                        >
                                            {t(
                                                'ew.modalScan.serviceRequirements.required'
                                            )}
                                            <span className={cx('alert300')}>
                                                *
                                            </span>
                                        </p>
                                    ) : null}
                                    {requiredAvailableCredentials?.length ? (
                                        <div
                                            className={styles.credRequirements}
                                        >
                                            {requiredAvailableCredentials.map(
                                                (list, index) => (
                                                    <ConsentCredentialCard
                                                        isAccepted={true}
                                                        setSelectedIndexForMultipleCreds={(
                                                            x
                                                        ) => {
                                                            setSelectedIndexForMultipleCreds(
                                                                x
                                                            )
                                                            setSelectedCurrentIndexForMultipleCreds(
                                                                x
                                                            )
                                                        }}
                                                        key={'cred__' + index}
                                                        credentials={
                                                            list?.credentials
                                                        }
                                                        selectedCredIndex={
                                                            selectedIndexForMultipleCreds[
                                                                formattedAvailableCredentials?.findIndex(
                                                                    (el) =>
                                                                        el ===
                                                                        list
                                                                )
                                                            ]?.selectedCredIndex
                                                        }
                                                        index={formattedAvailableCredentials?.findIndex(
                                                            (el) => el === list
                                                        )}
                                                        mandatory={
                                                            list?.mandatory
                                                        }
                                                        purpose={list?.purpose}
                                                        setImageToShowURI={(
                                                            x
                                                        ) =>
                                                            setImageToShowURI(x)
                                                        }
                                                        setCredentialsToSelect={(
                                                            x
                                                        ) =>
                                                            setCredentialsToSelect(
                                                                x
                                                            )
                                                        }
                                                        trustLevel={
                                                            list?.trustLevel
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                    {optionalAvailableCredentials?.length ? (
                                        <p
                                            className={cx(
                                                'marginTop24 marginBottom12 bodyRegularXS neutral800'
                                            )}
                                        >
                                            {t(
                                                'ew.modalScan.serviceRequirements.optional'
                                            )}
                                        </p>
                                    ) : null}
                                    {optionalAvailableCredentials ? (
                                        <div
                                            className={styles.credRequirements}
                                        >
                                            {optionalAvailableCredentials?.map(
                                                (list, index) => (
                                                    <ConsentCredentialCard
                                                        key={'cred__' + index}
                                                        credentials={
                                                            list?.credentials
                                                        }
                                                        selectedCredIndex={
                                                            selectedIndexForMultipleCreds[
                                                                formattedAvailableCredentials?.findIndex(
                                                                    (el) =>
                                                                        el ===
                                                                        list
                                                                )
                                                            ]?.selectedCredIndex
                                                        }
                                                        setSelectedIndexForMultipleCreds={(
                                                            accepted: boolean
                                                        ) => {
                                                            const indexCred =
                                                                formattedAvailableCredentials?.findIndex(
                                                                    (el) =>
                                                                        el ===
                                                                        list
                                                                )

                                                            const newAcceptedCredsValue =
                                                                selectedIndexForMultipleCreds?.map(
                                                                    (
                                                                        indexGroup,
                                                                        index
                                                                    ) =>
                                                                        index ===
                                                                        indexCred
                                                                            ? {
                                                                                  selectedCredIndex:
                                                                                      indexGroup?.selectedCredIndex,
                                                                                  accepted:
                                                                                      accepted,
                                                                              }
                                                                            : indexGroup
                                                                )

                                                            setSelectedIndexForMultipleCreds(
                                                                newAcceptedCredsValue
                                                            )
                                                            setSelectedCurrentIndexForMultipleCreds(
                                                                newAcceptedCredsValue
                                                            )
                                                        }}
                                                        isAccepted={
                                                            !!selectedIndexForMultipleCreds[
                                                                formattedAvailableCredentials?.findIndex(
                                                                    (el) =>
                                                                        el ===
                                                                        list
                                                                )
                                                            ]?.accepted
                                                        }
                                                        index={formattedAvailableCredentials?.findIndex(
                                                            (el) => el === list
                                                        )}
                                                        mandatory={
                                                            list?.mandatory
                                                        }
                                                        purpose={list?.purpose}
                                                        setImageToShowURI={(
                                                            x
                                                        ) =>
                                                            setImageToShowURI(x)
                                                        }
                                                        setCredentialsToSelect={(
                                                            x
                                                        ) =>
                                                            setCredentialsToSelect(
                                                                x
                                                            )
                                                        }
                                                        trustLevel={
                                                            list?.trustLevel
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <CardListSkeleton
                                    key={'credentials__skeleton'}
                                    className={styles.credentialsSkeleton}
                                    cardsNumber={1}
                                />
                            )}
                            <div className={styles.terms}>
                                <GaCheckbox
                                    index={'0'}
                                    name="accept_terms"
                                    checked={aceptedConditions}
                                    onChange={() =>
                                        setConditionsState(!aceptedConditions)
                                    }
                                    className={cx('marginRight8')}
                                    id="accept-terms"
                                ></GaCheckbox>
                                <div className={styles.termsText}>
                                    <span
                                        className={cx(
                                            'neutral1000 bodyRegularSM',
                                            styles.checkbox__text
                                        )}
                                    >
                                        <Trans
                                            i18nKey={
                                                'ew.modalScan.serviceRequirements.agreeToTerms'
                                            }
                                            values={{
                                                brand: brandLabels?.brand,
                                            }}
                                        />
                                    </span>
                                    <a
                                        className={cx(
                                            'primary700 bodyRegularSM'
                                        )}
                                        target="_blank"
                                        href={brandLabels?.termsOfServiceLink}
                                    >
                                        {t('welcome.termsOfUse')}
                                    </a>
                                    <span
                                        className={cx(
                                            'neutral1000 bodyRegularSM',
                                            styles.checkbox__text
                                        )}
                                    >
                                        <Trans
                                            i18nKey={
                                                'ew.modalScan.serviceRequirements.and'
                                            }
                                            values={{
                                                brand: brandLabels?.brand,
                                            }}
                                        />
                                    </span>
                                    <a
                                        className={cx(
                                            'primary700 bodyRegularSM'
                                        )}
                                        target="_blank"
                                        href={brandLabels.privacypolicylink}
                                    >
                                        {t('welcome.privacyPolicy')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScanModal>
            ) : isLoading ? (
                <ScanModalLoading
                    title={'ew.modalScan.loader.sharingWithService'}
                    description={'ew.modalScan.loader.processTakeMinutes'}
                />
            ) : isSuccess ? (
                <ScanModalSuccess
                    title={
                        !isConnection
                            ? 'ew.modalScan.success.requirementsShared'
                            : 'ew.modalScan.success.loginSuccess'
                    }
                    description={
                        !isConnection
                            ? t('ew.modalScan.success.requirementsSharedDesc', {
                                  brand: brandLabels?.brand,
                              })
                            : receiverDataName
                            ? t('ew.modalScan.success.loginSuccessDesc', {
                                  // TODO: Add real service here when BE is ready
                                  service: receiverDataName,
                              })
                            : undefined
                    }
                    secondDescription={
                        isConnection
                            ? t('ew.modalScan.success.loginSuccessDesc2')
                            : undefined
                    }
                    alertText={
                        !isConnection
                            ? 'ew.modalScan.success.requirementsSharedAlert'
                            : 'ew.modalScan.success.requirementsAuthAndShared'
                    }
                    continueFunction={hideModal}
                />
            ) : isFailed ? (
                <UnknowConsentError
                    title={'ew.modalScan.error.consentUnknowErrorTitle'}
                    description={'ew.modalScan.error.consentUnknowErrorDesc'}
                    continueFunction={() => {
                        dispatch(clearModalState()),
                            setScanCurrentStep(scanSteps[0])
                    }}
                />
            ) : null}
            {imageToShowURI ? (
                <ImageView
                    imageURI={imageToShowURI}
                    hideView={() => setImageToShowURI(undefined)}
                />
            ) : null}
        </>
    ) : showCloseConfirmation ? (
        <ScanModalCloseConfirmation
            continueFunction={hideModal}
            goBackFunction={() => setShowCloseConfirmation(false)}
        />
    ) : credentialsToSelect?.selectableCreds?.length ? (
        <>
            {' '}
            <ChangeCredential
                selectedIndexForMultipleCreds={selectedIndexForMultipleCreds}
                initialSelectableCreds={initialSelectableCreds}
                credentialsToSelect={credentialsToSelect}
                selectedCurrentIndexForMultipleCreds={
                    selectedCurrentIndexForMultipleCreds
                }
                setSelectedIndexForMultipleCreds={
                    setSelectedIndexForMultipleCreds
                }
                setCredentialsToSelect={setCredentialsToSelect}
                setImageToShowURI={setImageToShowURI}
                setSelectedCurrentIndexForMultipleCreds={
                    setSelectedCurrentIndexForMultipleCreds
                }
            />
            {imageToShowURI ? (
                <ImageView
                    imageURI={imageToShowURI}
                    hideView={() => setImageToShowURI(undefined)}
                />
            ) : null}
        </>
    ) : null
}

export default ServiceRequirements
