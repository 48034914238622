import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from './configurationForm.module.scss'
import { IAppIntegrationModel } from '../../../../../../interfaces/interfaces'
import { catalogSelectors } from '../../../../../common/catalog'
import { gaColors } from '../../../../../../assets/globalConstants'
import DarkButton from '../../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import GaTextInput from '../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LightPanel from '../../../../../../Components/atoms/panels/LightPanel/LightPanel'
import ScreenHeader from '../../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import FormSection from '../../../../../../Components/organisms/FormSection/FormSection'
import LinkButtonIcon from '../../../../../../Components/atoms/buttons/LinkButtonIcon/LinkButtonIcon'
import PlusIcon from '../../../../../../assets/iconsLibrary/simple/plus/plusIcon'
import GaSelectCheckbox, {
    Selectable,
} from '../../../../../../Components/atoms/formFields/selectCheckbox/GaSelectCheckbox'
import { appIntegrationsSelectors } from '../../../../../../store/appIntegrations'

type IConfigurationFormProps = {
    formData: IAppIntegrationModel
    buttonFunction: (x) => void
    handleInputChange: (x?) => void
    handleInputUrlChange: (e: any, x: number, y: string) => void
    removeUrl: (x: number, y: string) => void
    handleScopeChange: (x?: any) => void
}

const ConfigurationForm: React.FC<IConfigurationFormProps> = (props) => {
    const {
        formData,
        buttonFunction,
        handleScopeChange,
        handleInputChange,
        handleInputUrlChange,
        removeUrl,
    } = props

    const { t } = useTranslation()

    const { client_name, formScopes, redirect_uris, policy_uri, tos_uri } =
        formData

    const validForm =
        client_name?.trim()?.length &&
        formScopes?.length &&
        formScopes[0]?.trim()?.length &&
        redirect_uris?.length &&
        redirect_uris[0]?.trim()?.length &&
        policy_uri?.trim()?.length &&
        tos_uri?.trim()?.length

    // Selectors
    const areScopesLoading = useSelector(
        appIntegrationsSelectors.areScopesLoading
    )
    const scopes = useSelector(appIntegrationsSelectors.selectableScopeList)

    const serviceProviders = useSelector(
        catalogSelectors.getCatalogServiceProvider
    )

    // Effects
    useEffect(() => {
        scrollToTop()
    }, [])

    useEffect(() => {}, [serviceProviders])

    // Constants
    const redirectValue = formData.redirect_uris
    const logoutValue = formData.post_logout_redirect_uris || []
    const [redirectInputs, setRedirectInputs] = useState(redirectValue)
    const [logoutInputs, setLogoutInputs] = useState(logoutValue)

    // Functions
    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    const newRedirectInput = () => {
        setRedirectInputs([...redirectInputs, ''])
    }

    const newLogoutInput = () => {
        setLogoutInputs([...logoutInputs, ''])
    }

    const removeRedirectInput = (index: number, name: string) => {
        redirectInputs.splice(index, 1)
        setRedirectInputs(redirectInputs)
        removeUrl(index, name)
    }

    const removeLogoutInput = (index: number, name: string) => {
        logoutInputs.splice(index, 1)
        setLogoutInputs(logoutInputs)
        removeUrl(index, name)
    }

    return (
        <>
            <div className={styles.leftColumn}>
                <p className={cx('heading5')}>
                    {t('appIntegrations.createTitle')}
                </p>
                <div className={styles.form}>
                    <LightPanel>
                        <ScreenHeader
                            title={'appIntegrations.configuration.title'}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            className={styles.formHeader}
                        />
                        <FormSection title="">
                            <form>
                                <GaTextInput
                                    className={cx('margin-bottom')}
                                    id={'client_name'}
                                    invalid={
                                        !formData?.client_name?.trim()?.length
                                    }
                                    labelText={t(
                                        'appIntegrations.configuration.appNameLabel'
                                    )}
                                    required
                                    name={'client_name'}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder={t(
                                        'appIntegrations.configuration.appNamePlaceholder'
                                    )}
                                    value={formData.client_name}
                                />
                                <GaSelectCheckbox
                                    className={cx('margin-bottom')}
                                    checkedData={formData.formScopes}
                                    invalid={
                                        !formData.formScopes?.length ||
                                        !formData?.formScopes[0]
                                    }
                                    labelText={t('appIntegrations.scopes')}
                                    placeholder={t(
                                        'appIntegrations.configuration.scopePlaceholder'
                                    )}
                                    optionSubTextProperty={'subText'}
                                    required
                                    name={'formScopes'}
                                    onChange={(e) => {
                                        handleScopeChange(e)
                                    }}
                                    isLoading={areScopesLoading}
                                    options={scopes as Selectable[]}
                                    id={'scope'}
                                    tooltip
                                    infoText={t(
                                        'appIntegrations.configuration.scopeTooltip'
                                    )}
                                />
                                <div className={`${styles.multipleUrlsForm} `}>
                                    {redirectInputs?.map((uri, index) => {
                                        return (
                                            <GaTextInput
                                                id={'redirectURL_' + index}
                                                key={'redirectURL_' + index}
                                                name={'redirect_uris'}
                                                labelText={
                                                    index === 0
                                                        ? t(
                                                              'dashboard.vouchModal.redirectionForm.redirectURLLabel'
                                                          )
                                                        : undefined
                                                }
                                                deleteFunctionality={
                                                    index !== 0
                                                        ? () => {
                                                              removeRedirectInput(
                                                                  index,
                                                                  'redirect_uris'
                                                              )
                                                          }
                                                        : undefined
                                                }
                                                value={
                                                    formData?.redirect_uris &&
                                                    formData?.redirect_uris[
                                                        index
                                                    ]
                                                        ? formData
                                                              ?.redirect_uris[
                                                              index
                                                          ]
                                                        : ''
                                                }
                                                onChange={(e) => {
                                                    handleInputUrlChange(
                                                        e,
                                                        index,
                                                        'redirect_uris'
                                                    )
                                                }}
                                                placeholder="https://url.com"
                                                required
                                                infoText={t(
                                                    'dashboard.vouchModal.redirectionForm.redirectURLTooltip'
                                                )}
                                                tooltip
                                            />
                                        )
                                    })}
                                    {redirectInputs.length &&
                                    formData?.redirect_uris[
                                        redirectInputs?.length - 1
                                    ]?.trim() ? (
                                        <LinkButtonIcon
                                            className={cx(styles.linkButton)}
                                            functionality={newRedirectInput}
                                            text={
                                                <span>
                                                    <PlusIcon
                                                        size={16}
                                                        color={
                                                            gaColors.primary700
                                                        }
                                                    />
                                                    <p
                                                        className={cx(
                                                            'buttonSM primary700'
                                                        )}
                                                    >
                                                        {t(
                                                            'dashboard.vouchModal.redirectionForm.addUrl'
                                                        )}
                                                    </p>
                                                </span>
                                            }
                                            disabled={false}
                                        />
                                    ) : null}
                                </div>
                                <GaTextInput
                                    className={cx('margin-bottom')}
                                    id={'policy_uri'}
                                    name={'policy_uri'}
                                    labelText={t(
                                        'dashboard.vouchModal.linksForm.privacyURLLabel'
                                    )}
                                    value={formData.policy_uri}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder="https://url.com"
                                    required
                                    tooltip
                                    infoText={t(
                                        'dashboard.vouchModal.linksForm.privacyURLTooltip'
                                    )}
                                />

                                <GaTextInput
                                    id={'tos_uri'}
                                    name={'tos_uri'}
                                    className={cx('margin-bottom')}
                                    labelText={t(
                                        'dashboard.vouchModal.linksForm.termsServiceURLLabel'
                                    )}
                                    value={formData.tos_uri}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder="https://url.com"
                                    required
                                    tooltip
                                    infoText={t(
                                        'dashboard.vouchModal.linksForm.termsServiceURLTooltip'
                                    )}
                                />
                                <div className={`${styles.multipleUrlsForm} `}>
                                    {logoutInputs?.map((uri, index) => {
                                        return (
                                            <GaTextInput
                                                id={'post_logout_redirect_uris'}
                                                name={
                                                    'post_logout_redirect_uris'
                                                }
                                                labelText={
                                                    index === 0
                                                        ? t(
                                                              'appIntegrations.configuration.logoutURL'
                                                          )
                                                        : undefined
                                                }
                                                deleteFunctionality={
                                                    index !== 0
                                                        ? () => {
                                                              removeLogoutInput(
                                                                  index,
                                                                  'post_logout_redirect_uris'
                                                              )
                                                          }
                                                        : undefined
                                                }
                                                value={
                                                    formData?.post_logout_redirect_uris &&
                                                    formData
                                                        ?.post_logout_redirect_uris[
                                                        index
                                                    ]
                                                        ? formData
                                                              ?.post_logout_redirect_uris[
                                                              index
                                                          ]
                                                        : ''
                                                }
                                                onChange={(e) => {
                                                    handleInputUrlChange(
                                                        e,
                                                        index,
                                                        'post_logout_redirect_uris'
                                                    )
                                                }}
                                                placeholder="https://url.com"
                                                tooltip
                                                infoText={t(
                                                    'appIntegrations.configuration.logoutTooltip'
                                                )}
                                            />
                                        )
                                    })}
                                    {logoutInputs.length &&
                                    formData?.post_logout_redirect_uris &&
                                    formData?.post_logout_redirect_uris[
                                        logoutInputs.length - 1
                                    ]?.trim() ? (
                                        <LinkButtonIcon
                                            className={cx(styles.linkButton)}
                                            functionality={newLogoutInput}
                                            text={
                                                <span>
                                                    <PlusIcon
                                                        size={16}
                                                        color={
                                                            gaColors.primary700
                                                        }
                                                    />
                                                    <p
                                                        className={cx(
                                                            'buttonSM primary700'
                                                        )}
                                                    >
                                                        {t(
                                                            'dashboard.vouchModal.redirectionForm.addUrl'
                                                        )}
                                                    </p>
                                                </span>
                                            }
                                            disabled={false}
                                        />
                                    ) : null}
                                </div>
                            </form>
                        </FormSection>
                        {/* TODO: Uncomment when preview design is ready */}
                        {/* {getResponsive(1320) ? ( */}
                        <div className={styles.buttonContainer}>
                            <DarkButton
                                text={'editVerifier.sectionButton'}
                                disabled={!validForm}
                                functionality={buttonFunction}
                            />
                        </div>
                        {/* ) : null} */}
                    </LightPanel>
                </div>
            </div>

            {/* TODO: Uncomment when preview design is ready */}
            {/* <ConfigurationPreview
                saveButton={{
                    label: 'editVerifier.sectionButton',
                    disabled: !validForm,
                    function: buttonFunction,
                }}
                data={scopes?.filter((scope) =>
                    formData?.formScopes?.includes(scope?.key)
                )}
                showSaveButton={true}
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
            /> */}
        </>
    )
}

export default ConfigurationForm
