import { t } from 'i18next'
import { IUserItem, UserProfile } from '../../../../../../interfaces/interfaces'
import {
    ClearUserList,
    IChangePasswordSubmit,
    IChangePassword_Error,
    IChangePassword_Success,
    ICheckUsersAction,
    IClearActionStatus,
    IClearUser,
    ICloseUserDetailAction,
    ICreateUserAction,
    ICreateUserFailedAction,
    ICreateUserSuccessAction,
    IDeleteUserAction,
    IDeleteUserFailedAction,
    IDeleteUserSuccessAction,
    IGetRegistrationTokenAction,
    IGetUserProfileAction,
    IGetUserProfileFailedAction,
    IGetUserProfileSuccessAction,
    IGetUsersAction,
    IGetUsersFailedAction,
    IGetUsersSuccessAction,
    ISelectUserAction,
    IUpdateUserAction,
    IUpdateUserFailedAction,
    IUpdateUserProfileAction,
    IUpdateUserProfileFailedAction,
    IUpdateUserProfileSuccessAction,
    IUpdateUserSuccessAction,
    UsersActionTypes,
} from './usersTypes'

export const getUsers = (): IGetUsersAction => {
    return {
        type: UsersActionTypes.GET_USERS,
    }
}
export const getUsersSuccess = (
    users: IUserItem[],
    tenants?: string
): IGetUsersSuccessAction => {
    return {
        type: UsersActionTypes.GET_USERS_SUCCESS,
        payload: {
            users: users,
            tenants: tenants,
        },
    }
}
export const getUsersFailed = (err: any): IGetUsersFailedAction => {
    return {
        type: UsersActionTypes.GET_USERS_FAILED,
        payload: [],
    }
}

export const getProfile = (email?: string): IGetUserProfileAction => {
    return {
        type: UsersActionTypes.GET_USER_PROFILE,
        payload: {
            email: email || 'email',
        },
    }
}
export const getProfileSuccess = (
    profile: UserProfile
): IGetUserProfileSuccessAction => {
    return {
        type: UsersActionTypes.GET_USER_PROFILE_SUCCESS,
        payload: {
            profile: profile,
        },
    }
}
export const getProfileFailed = (err: any): IGetUserProfileFailedAction => {
    return {
        type: UsersActionTypes.GET_USER_PROFILE_FAILED,
        payload: {
            message: err,
        },
    }
}
export const createUser = (
    user: IUserItem,
    register?: boolean
): ICreateUserAction => {
    return {
        type: UsersActionTypes.CREATE_USER,
        payload: {
            user: user,
            register: register || false,
        },
    }
}
export const createUserSuccess = (): ICreateUserSuccessAction => {
    return {
        type: UsersActionTypes.CREATE_USER_SUCCESS,
        payload: {
            message: 'User created successfully',
        },
    }
}
export const createUserFailed = (error): ICreateUserFailedAction => {
    const errorDescription =
        error?.status === 409
            ? t('createUser.userAlreadyInvited')
            : error?.status === 429
            ? t('createUser.userLimitreached')
            : error?.response?.message
    return {
        type: UsersActionTypes.CREATE_USER_FAILED,
        payload: {
            error: errorDescription,
        },
    }
}
export const getRegistrationToken = (
    user: IUserItem,
    role?: string,
    tenant?: string
): IGetRegistrationTokenAction => {
    return {
        type: UsersActionTypes.GET_REGISTRATION_TOKEN,
        payload: {
            user: user,
            role: role,
            tenant: tenant,
        },
    }
}
export const deleteUser = (userID: string): IDeleteUserAction => {
    return {
        type: UsersActionTypes.DELETE_USER,
        payload: {
            userID: userID,
        },
    }
}
export const deleteUserSuccess = (): IDeleteUserSuccessAction => {
    return {
        type: UsersActionTypes.DELETE_USER_SUCCESS,
    }
}
export const deleteUserFailed = (err: any): IDeleteUserFailedAction => {
    return {
        type: UsersActionTypes.DELETE_USER_FAILED,
    }
}
export const selectUser = (user?: IUserItem): ISelectUserAction => {
    return {
        type: UsersActionTypes.SELECT_USER,
        payload: {
            user: user,
        },
    }
}
export const closeUserDetail = (): ICloseUserDetailAction => {
    return {
        type: UsersActionTypes.CLOSE_USER_DETAIL,
    }
}
export const updateUser = (
    user: IUserItem,
    email: string
): IUpdateUserAction => {
    return {
        type: UsersActionTypes.UPDATE_USER,
        payload: {
            user: user,
            email: email,
        },
    }
}

export const updateUserSuccess = (): IUpdateUserSuccessAction => {
    return {
        type: UsersActionTypes.UPDATE_USER_SUCCESS,
    }
}

export const updateUserFailed = (error: string): IUpdateUserFailedAction => {
    return {
        type: UsersActionTypes.UPDATE_USER_FAILED,
        payload: {
            error: error,
        },
    }
}

export const updateProfile = (
    profile: UserProfile
): IUpdateUserProfileAction => {
    return {
        type: UsersActionTypes.UPDATE_USER_PROFILE,
        payload: {
            profile: profile,
        },
    }
}
export const updateProfileSuccess = (
    profile: UserProfile
): IUpdateUserProfileSuccessAction => {
    return {
        type: UsersActionTypes.UPDATE_USER_PROFILE_SUCCESS,
        payload: {
            profile: profile,
        },
    }
}
export const updateProfileFailed = (
    err: any
): IUpdateUserProfileFailedAction => {
    return {
        type: UsersActionTypes.UPDATE_USER_PROFILE_FAILED,
        payload: {
            error: err,
        },
    }
}

export const checkUsers = (): ICheckUsersAction => {
    return {
        type: UsersActionTypes.CHECK_USERS,
    }
}

export const changePasswordSubmit = (payload): IChangePasswordSubmit => {
    return {
        type: UsersActionTypes.CHANGE_PASSWORD_SUBMIT,
        payload: payload,
    }
}

export const changePasswordError = (payload): IChangePassword_Error => {
    return {
        type: UsersActionTypes.CHANGE_PASSWORD_ERROR,
        error: payload,
    }
}

export const changePasswordSuccess = (): IChangePassword_Success => {
    return {
        type: UsersActionTypes.CHANGE_PASSWORD_SUCCESS,
    }
}

export const clearUser = (): IClearUser => {
    return {
        type: UsersActionTypes.CLEAR_CURRENT_USER,
    }
}

export const clearUserList = (): ClearUserList => {
    return {
        type: UsersActionTypes.CLEAR_USER_LIST,
    }
}

export const clearActionStatus = (): IClearActionStatus => {
    return {
        type: UsersActionTypes.CLEAR_ACTION_STATUS,
    }
}
