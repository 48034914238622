import { IAppIntegrationModel } from '../../interfaces/interfaces'
import {
    AppIntegrationModalListActionsTypes,
    ISendAppIntegrationDataAction,
    ISendAppIntegrationDataSuccessAction,
    ISendAppIntegrationDataFailed,
    IClearStateAction,
} from './appIntegrationModalTypes'

export const sendAppIntegrationData = (
    client: IAppIntegrationModel
): ISendAppIntegrationDataAction => {
    return {
        type: AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA,
        payload: {
            client,
        },
    }
}

export const sendAppIntegrationDataSuccess = (
    client: IAppIntegrationModel
): ISendAppIntegrationDataSuccessAction => {
    return {
        type: AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA_SUCCESS,
        payload: {
            client,
        },
    }
}

export const sendAppIntegrationDataFailed = (
    error: string
): ISendAppIntegrationDataFailed => {
    return {
        type: AppIntegrationModalListActionsTypes.SEND_APP_INTEGRATION_DATA_FAILED,
        payload: {
            error: error,
        },
    }
}

export const clearModalState = (): IClearStateAction => {
    return {
        type: AppIntegrationModalListActionsTypes.CLEAR_STATE,
    }
}
