import angleForward from '../../../assets/images/angle-forward.svg'
import { ButtonModel } from '../../../interfaces/interfaces'

// FormPanel params
export const angleForwardIcon = angleForward

export const backButton: ButtonModel = {
    label: 'public.cancel',
}

export const continueButton: ButtonModel = {
    label: 'public.continue',
}

export const orgServicesEndpoints = [
    {
        id: 'Connect Saas',
        type: 'Verifier',
        serviceEndpoint: process.env['REACT_APP_CONNECT_HOST'] || '',
    },
    {
        id: 'Certify Saas',
        type: 'Issuer',
        serviceEndpoint: process.env['REACT_APP_CERTIFY_HOST'] || '',
    },
]
