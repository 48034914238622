import { brandLabels, gaImages } from '../../../../assets/globalConstants'
import CredentialIcon from '../../../../assets/iconsLibrary/credential/credentialIcon'
import HomeIcon from '../../../../assets/iconsLibrary/home/homeIcon'
import IdIcon from '../../../../assets/iconsLibrary/id/idIcon'
import KeyIcon from '../../../../assets/iconsLibrary/keysIcon/keyIcon'
import ArrowLeftDiagonalCircleIcon from '../../../../assets/iconsLibrary/arrowLeftDiagonalCircle/arrowLeftDiagonalCircleIcon'
import ConnectIcon from '../../../../assets/iconsLibrary/connect/connectIcon'
import VerificationIcon from '../../../../assets/iconsLibrary/verification/verificationIcon'
import activity from '../../../../assets/images/activity.svg'
import apiKeys from '../../../../assets/images/api-keys.svg'
import features from '../../../../assets/iconsLibrary/features/featuresIcon'
import checkList from '../../../../assets/images/checklist-pen.svg'
import support from '../../../../assets/images/headphones-customer-support-question.svg'
import houseChimney from '../../../../assets/images/house-chimney.svg'
import logout from '../../../../assets/images/logout.svg'
import {
    default as burguerMenu,
    default as security,
} from '../../../../assets/images/security.svg'
import settings from '../../../../assets/images/settings.svg'
import { LinkModel } from '../../../../interfaces/interfaces'
import { deleteSession, showTenantModal } from '../../session/sessionActions'
import { setSettings } from '../scafoldingActions'
import ArrowUpDiagonalCircleIcon from '../../../../assets/iconsLibrary/arrowUpDiagonalCircle/arrowUpDiagonalCircleIcon'

export const houseChimneyIcon = houseChimney
export const supportIcon = support
export const checkListIcon = checkList
export const burguerMenuIcon = burguerMenu
export const settingsIcon = settings
export const logoutIcon = logout
export const activityIcon = activity
export const apiKeysIcon = apiKeys
export const securityIcon = security
export const featuresIcon = features

export const sideMenuSettings = [
    {
        id: 'profile',
        route: 'settings',
        label: 'navigation.settingsnav.profile',
    },
    {
        id: 'organization',
        route: 'organization',
        label: 'navigation.settingsnav.organization',
        scopes: ['readTenants', 'readProviders', 'deleteTenants'],
    },
    {
        id: 'team',
        route: 'team',
        label: 'navigation.settingsnav.team',
        scopes: ['readUsers', 'createUsers', 'readRoles', 'updateRoles'],
    },
    {
        id: 'billing',
        route: 'billing',
        label: 'navigation.settingsnav.billing',
        scopes: ['manageSubscriptions'],
    },
    {
        id: 'subscriptions',
        route: 'subscription',
        label: 'navigation.settingsnav.subscription',
    },
]

export const sideMenuOnPremiseSettings = [
    {
        id: 'team',
        route: 'team',
        label: 'navigation.settingsnav.team',
        scopes: ['readUsers', 'createUsers', 'readRoles', 'updateRoles'],
    },
]

export const sideMenu: LinkModel[] = [
    {
        route: 'home',
        label: 'navigation.home',
        icon: {
            src: houseChimneyIcon,
            alt: 'public.houseIcon',
        },
        iconComponent: HomeIcon,
    },
    {
        route: 'IssuanceNo',
        label: 'navigation.credentials',
        icon: gaImages.credentials,
        iconComponent: CredentialIcon,
        subRoutes: [
            {
                route: 'issuance_templates',
                label: 'navigation.issuances',
                scopes: [
                    'readSSIConfigs',
                    'createSSIConfigs',
                    'updateSSIConfigs',
                ],
            },
            {
                route: 'credentials',
                label: 'navigation.credentials_activity',
                scopes: ['readSessions', 'manageCredentials'],
            },
            {
                route: 'certificates',
                label: 'navigation.certificates',
                // TODO: Add right scopes
                scopes: ['readSessions', 'manageCredentials', 'readDids'],
            },
        ],
    },
    {
        route: 'verifiersNo',
        label: 'navigation.verifications',
        icon: gaImages.checkBadge,
        iconComponent: VerificationIcon,
        subRoutes: [
            {
                route: 'verification_templates',
                label: 'navigation.verifiers',
                scopes: [
                    'readSSIConfigs',
                    'updateSSIConfigs',
                    'createSSIConfigs',
                ],
            },
            {
                route: 'verified_users',
                label: 'navigation.consents',
                scopes: [
                    'readDataAgreements',
                    'createDataAgreements',
                    'revokeDataAgreements',
                ],
            },
        ],
    },
    {
        route: 'app-integrations',
        label: 'navigation.appIntegrations',
        iconComponent: featuresIcon,
        // TODO: Add scopes when they're defined
        scopes: [],
    },
    {
        route: 'api_keys',
        label: 'navigation.apiKeys',
        icon: {
            src: apiKeysIcon,
            alt: 'public.keysIcon',
        },
        iconComponent: KeyIcon,
        scopes: ['readApiKeys', 'createApiKeys'],
    },
    {
        route: 'dids',
        label: 'DIDs',
        icon: gaImages.did,
        iconComponent: IdIcon,
        scopes: ['readDids', 'createDids'],
    },
]

export const sideOnPremiseMenu: LinkModel[] = [
    {
        route: 'home',
        label: 'navigation.home',
        icon: {
            src: houseChimneyIcon,
            alt: 'public.houseIcon',
        },
    },
    {
        route: 'IssuanceNo',
        label: 'navigation.credentials',
        icon: gaImages.credentials,
        subRoutes: [
            {
                route: 'issuance_templates',
                label: 'navigation.issuances',
                scopes: [
                    'readSSIConfigs',
                    'createSSIConfigs',
                    'updateSSIConfigs',
                ],
            },
            // {
            //     route: 'credentials',
            //     label: 'navigation.credentials_activity',
            //     scopes: ['readSessions', 'manageCredentials'],
            // },
        ],
    },
    {
        route: 'verifiersNo',
        label: 'navigation.verifications',
        icon: gaImages.checkBadge,
        subRoutes: [
            {
                route: 'verification_templates',
                label: 'navigation.verifiers',
                scopes: [
                    'readSSIConfigs',
                    'updateSSIConfigs',
                    'createSSIConfigs',
                ],
            },
            // {
            //     route: 'verified_users',
            //     label: 'navigation.consents',
            //     scopes: [
            //         'readDataAgreements',
            //         'createDataAgreements',
            //         'revokeDataAgreements',
            //     ],
            // },
        ],
    },
    {
        route: 'api_keys',
        label: 'navigation.apiKeys',
        icon: {
            src: apiKeysIcon,
            alt: 'public.keysIcon',
        },
        scopes: ['readApiKeys', 'createApiKeys'],
    },
    {
        route: 'dids',
        label: 'DIDs',
        icon: gaImages.did,
        scopes: ['readDids', 'createDids'],
    },
]

export const sideMenuExtraHelp: LinkModel[] = [
    // {
    //     id: 'faq',
    //     route: '/faq',
    //     label: 'navigation.help.faq',
    //     status: 'disabled',
    //     // icon: {
    //     //   src: checkListIcon,
    //     //   alt: 'public.checkListIcon'
    //     // }
    // },
    // TODO: Uncomment when Settings is ready

    // {
    //   route: '/settings',
    //   label: 'navigation.settings',
    //   icon: {
    //     src: settingsIcon,
    //     alt: 'public.settingsIcon'
    //   }
    // },
    // {
    //     id: 'documentation',
    //     route: '/documentation',
    //     status: 'disabled',
    //     label: 'navigation.help.documentation',
    //     // icon: {
    //     //   src: logoutIcon,
    //     //   alt: 'public.logoutIcon'
    //     // }
    // },
    {
        id: 'support',
        route: '',
        label: 'navigation.help.support',
        status: 'disabled',
        outLink: process.env.REACT_APP_SUPPORT_URL,

        // icon: {
        //   src: supportIcon,
        //   alt: 'public.supportIcon',
        // },
    },
]

export const sideMenuExtraMobileOptions: LinkModel[] = [
    {
        id: 'generalSettings',
        route: 'generalSettings',
        label: 'navigation.settings',
        action: setSettings,
        icon: {
            src: settingsIcon,
            alt: 'public.settingsIcon',
        },
        subRoutes: [
            {
                id: 'profile',
                route: 'settings',
                label: 'navigation.settingsnav.profile',
            },
            {
                id: 'organization',
                route: 'organization',
                label: 'navigation.settingsnav.organization',
                scopes: ['readTenants', 'readProviders', 'deleteTenants'],
            },
            {
                id: 'team',
                route: 'team',
                label: 'navigation.settingsnav.team',
                scopes: [
                    'readUsers',
                    'createUsers',
                    'readRoles',
                    'updateRoles',
                ],
            },
            {
                id: 'billing',
                route: 'billing',
                label: 'navigation.settingsnav.billing',
                scopes: ['manageSubscriptions'],
            },
            {
                id: 'subscriptions',
                route: 'subscription',
                label: 'navigation.settingsnav.subscription',
            },
            {
                id: 'support',
                route: '',
                label: 'navigation.help.support',
                status: 'disabled',
                outLink: process.env.REACT_APP_SUPPORT_URL,
            },
        ],
    },
    {
        id: 'logout',
        route: 'no',
        action: deleteSession,
        label: 'navigation.logout',
    },
]

export const walletSideMenu: LinkModel[] = [
    {
        route: 'wallet',
        label: 'navigation.requestCredentials',
        iconComponent: ArrowLeftDiagonalCircleIcon,
        scopes: [],
    },
    {
        route: 'connections',
        label: 'navigation.connections',
        iconComponent: ConnectIcon,
        scopes: [],
    },
]

export const sideMenuExtraMobileOnPremiseOptions: LinkModel[] = [
    {
        id: 'team',
        route: 'team',
        label: 'navigation.settingsnav.team',
        scopes: ['readUsers', 'createUsers', 'readRoles', 'updateRoles'],
    },
    {
        id: 'documentation',
        route: 'no',
        label: 'navigation.documentation',
        outLink: brandLabels?.documentationLink,
    },
    {
        id: 'logout',
        route: 'no',
        action: deleteSession,
        label: 'navigation.logout',
    },
]

export const helpMenuExtraMobile = (): LinkModel[] => {
    let menu = [
        {
            id: 'support',
            route: '',
            label: 'navigation.help.support',
            status: 'disabled',
            outLink: process.env.REACT_APP_SUPPORT_URL,
        },
        {
            id: 'documentation',
            route: 'no',
            label: 'navigation.documentation',
            outLink: brandLabels?.documentationLink,
        },
    ]

    return menu
}

export const sideMenuExtraMobile = (): LinkModel[] => {
    let menu = [
        // {
        //     id: 'profile',
        //     route: 'settings',
        //     label: 'navigation.profile',
        //     action: setSettings,
        //     status: 'disabled',
        // },
        {
            id: 'tenants',
            route: 'no',
            label: 'navigation.tenants',
            action: showTenantModal,
        },
        {
            id: 'general',
            route: 'settings',
            label: 'navigation.settings',
            action: setSettings,
        },
        {
            id: 'logout',
            route: 'no',
            action: deleteSession,
            label: 'navigation.logout',
        },
    ]

    return menu
}
export const helpMenuExtraOnPremiseMobile = (): LinkModel[] => {
    let menu = [
        {
            id: 'documentation',
            route: 'no',
            label: 'navigation.documentation',
            outLink: brandLabels?.documentationLink,
        },
    ]

    return menu
}
export const sideMenuExtraOnPremiseMobile = (): LinkModel[] => {
    let menu = [
        {
            id: 'team',
            route: 'team',
            label: 'navigation.settingsnav.team',
            scopes: ['readUsers', 'createUsers', 'readRoles', 'updateRoles'],
        },
        {
            id: 'logout',
            route: 'no',
            action: deleteSession,
            label: 'navigation.logout',
        },
    ]

    return menu
}
export const errorPanelReachedFeatures = {
    issuance_templates: 'subscription.limitedFeatures.issuanceTemplates',
    verification_templates:
        'subscription.limitedFeatures.verificationTemplates',
    verified_users: 'subscription.limitedFeatures.activeUsers',
    credentials: 'subscription.limitedFeatures.issuedCredentials',
    api_keys: 'subscription.limitedFeatures.apiKeys',
    dids: 'subscription.limitedFeatures.dids',
}

export const switchSectionButtonOptions = [
    { text: 'general.studio', value: 'home' },
    { text: 'general.wallet', value: 'wallet' },
]

export const trialingSwitchSectionButtonOptions = [
    { text: 'general.studio', value: 'home' },
    {
        text: 'general.wallet',
        value: 'wallet',
        disabled: true,
        Icon: ArrowUpDiagonalCircleIcon,
        info: 'public.upgradeSubscriptionToAccessFromTrial',
    },
]

export const incompleteSwitchSectionButtonOptions = [
    { text: 'general.studio', value: 'home' },
    {
        text: 'general.wallet',
        value: 'wallet',
        disabled: true,
        info: 'public.notNecessaryPermission',
    },
]

export const settingRoutes = [
    'organization',
    'settings',
    'billing',
    'team',
    'change-subscription',
    'subscription',
    'create-user',
    'config-user',
]

export const walletRoutes = ['wallet', 'connections']

export const switchCredentialsRequirements = [
    { text: 'general.required', value: 'required' },
    { text: 'general.optional', value: 'optional' },
]
