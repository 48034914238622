import React, { FunctionComponent, SVGProps } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ImageModel } from '../../../../interfaces/interfaces'
import GaImage from '../../Image/Image'
import style from './darkButton.module.scss'
import cx from 'classnames'
import {
    ButtonSizes,
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../data/globalVar'
import GaHoverTooltip from '../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'

type DarkButtonProps = {
    text: string
    SvgIcon?: FunctionComponent<SVGProps<SVGSVGElement>>
    disabled: boolean
    color?: string
    buttonExtraTranslationParams?: Object
    hideIconTooltip?: boolean
    disabledTooltip?: boolean
    disabledTooltipLabel?: string
    type?: 'button' | 'submit' | 'reset' | undefined
    icon?: ImageModel
    className?: string
    size?: ButtonSizes
    functionality?: ((x?: any) => void) | any
    tooltipArrowPosition?: string
    tooltipPosition?: string
    tooltipIconColor?: string
}

const DarkButton: React.FC<DarkButtonProps> = React.memo((props) => {
    const {
        text,
        SvgIcon,
        color,
        type,
        disabled,
        icon,
        buttonExtraTranslationParams,
        hideIconTooltip,
        disabledTooltip,
        disabledTooltipLabel,
        className,
        size,
        functionality,
        tooltipArrowPosition,
        tooltipPosition,
        tooltipIconColor,
    } = props
    const { t } = useTranslation()

    const sizeFontClass = {
        LG: 'buttonLG',
        MD: 'buttonMD',
        SM: 'buttonSM',
    }

    const sizeClass = {
        LG: style.large,
        MD: style.medium,
        SM: style.small,
    }

    return !disabledTooltip ? (
        <button
            className={`${style.darkButton} ${className} ${
                disabled ? style.darkButtonInActive : style.darkButtonActive
            } ${cx(
                sizeClass[size || 'MD'],
                sizeFontClass[size || 'MD'],
                'neutral100'
            )}`}
            onClick={functionality}
            disabled={disabled}
            type={type || 'button'}
        >
            <Trans i18nKey={t(text)} values={buttonExtraTranslationParams} />

            {SvgIcon ? (
                <SvgIcon
                    className={`${style.darkButton__icon} ${
                        disabled
                            ? style.darkButtonIconInActive
                            : style.darkButtonIconActive
                    }`}
                />
            ) : icon ? (
                <GaImage className={style.darkButton__icon} image={icon} />
            ) : null}
        </button>
    ) : (
        <div
            className={`${style.darkButton} ${className} ${
                disabled ? style.darkButtonInActive : style.darkButtonActive
            } ${cx(
                sizeClass[size || 'MD'],
                sizeFontClass[size || 'MD'],
                'neutral100'
            )}`}
        >
            <GaHoverTooltip
                className={`${style.darkButtonHoverTooltip} ${
                    hideIconTooltip ? style.leftPosition : ''
                }`}
                position={
                    tooltipPosition || hideIconTooltip
                        ? TooltipPosition.left
                        : TooltipPosition.bottom
                }
                positionArrowDirection={
                    tooltipArrowPosition || hideIconTooltip
                        ? TooltipArrowPosition.center
                        : TooltipArrowPosition.right
                }
                tooltipIconColor={tooltipIconColor}
                label={
                    <p
                        className={cx(
                            sizeClass[size || 'MD'],
                            sizeFontClass[size || 'MD'],
                            'neutral100'
                        )}
                    >
                        {t(text)}
                    </p>
                }
                info={disabledTooltipLabel || ''}
                hideIcon={hideIconTooltip}
            />
        </div>
    )
})

export default DarkButton
