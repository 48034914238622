import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import TableSkeleton from '../../../../../Components/molecules/skeletons/tableSkeleton/TableSkeleton'
import WarningModal from '../../../../../Components/molecules/table/dynamicTable/warninModal/WarningModal'
import BasicCard from '../../../../../Components/organisms/Cards/BasicCard'
import {
    IDataAgreementItem,
    LocationStateModel,
} from '../../../../../interfaces/interfaces'
import { shortValue } from '../../../../../utils/formatDataUtil'
import {
    getTotalPageItems,
    paginate,
} from '../../../../../utils/paginationUtil'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../../utils/permissionsUtil'
import { DataUsersModal } from '../../../credentials/views/dataModal/DataModal'
import { sessionSelectors } from '../../../../common/session'
import { statsSelectors } from '../../../../common/Statistics'
import { getStatistics } from '../../../../common/Statistics/statisticsActions'
import {
    clearSelectedConsent,
    deleteConsent,
    getConsents,
    selectConsent,
} from '../../consentActions'
import * as consentSelectors from '../../consentSelector'
import { getVerifierConfigs } from '../../verifierActions'
import { verifierScopes } from '../../data/verifier.constants'
import { consentScopes, headersConsentsTable } from './consentsList.constants'
import ConsentsTable from './ConsentsTable'
import { onPremise } from '../../../../../data/globalVar'
import cx from 'classnames'
import { defaultPageSize } from '../../../../../Components/Pagination/paginationConstants'

type IConsentsProps = {}

type ISearchState = {
    searched: string
    searchedResult: any[]
}

const Consents: React.FC<IConsentsProps> = ({}) => {
    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const location = useLocation<LocationStateModel>()

    const isLoading = useSelector(consentSelectors.isLoading)
    const consents = useSelector(consentSelectors.getConsents)
    const consentData = useSelector(consentSelectors.getConsentData)

    const [pageIndex, setPageIndex] = useState(1)
    const [paginatedConsents, setPaginatedConsents] =
        React.useState<IDataAgreementItem[]>()

    const [selectedConsent, setSelectedConsent] = React.useState<{
        consent: IDataAgreementItem
    }>()

    const initialPurposeListState = {
        searched: '',
        searchedResult: paginatedConsents || [''],
    }

    const [state, setState] = React.useState<ISearchState>(
        initialPurposeListState
    )
    const [checked, setChecked] = useState(new Array(0))
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)

    const canReadVerifiers = !!(
        availableScopes && userHasScopes(verifierScopes?.read, availableScopes)
    )

    const canReadVerifiedUsers = !!(
        availableScopes && userHasScopes(consentScopes?.read, availableScopes)
    )

    useEffect(() => {
        if (canReadVerifiedUsers) {
            dispatch(getConsents())
            dispatch(getVerifierConfigs())
        }
        if (!statisticsIsLoading && getIsActiveSession()) {
            // Just in cloud for now
            !onPremise && dispatch(getStatistics())
        }
    }, [])

    useEffect(() => {
        setPaginatedConsents(paginate(consents, defaultPageSize, pageIndex))
    }, [consents])

    const [totalItems, setTotalItems] = useState(consents?.length)

    useEffect(() => {
        if (!!consents) {
            setState(initialPurposeListState)
            setTotalItems(consents.length)
        }
    }, [paginatedConsents])

    const handlePageChange = (page) => {
        setPageIndex(page)
        setPaginatedConsents(paginate(consents, defaultPageSize, page))
        setState(initialPurposeListState)
    }

    const [showDetail, setShowDetailModal] = React.useState(false)
    const showDetailModal = () => setShowDetailModal(true)
    const hideDetailModal = () => {
        setShowDetailModal(false), dispatch(clearSelectedConsent())
    }

    const [showDelete, setShowDeleteModal] = React.useState(false)
    const showDeleteModal = () => setShowDeleteModal(true)
    const hideDeleteModal = () => setShowDeleteModal(false)

    const selectConsentHandler = (item) => {
        dispatch(selectConsent(item.id))
        showDetailModal()
    }

    const deleteSelectedConsent = (item) => {
        dispatch(deleteConsent(item.id))
        hideDeleteModal()
        dispatch(getConsents())
    }

    const openDetailsModal = (id: string) => {
        /* { TODO: Add functionality to show modal for issue a credential */
        console.log('modal opened')
    }

    const handleOptions = (item, selection?, y?) => {
        setSelectedConsent(item)
        showDeleteModal()
    }

    const warningModalButtons = {
        primary: { label: 'public.delete', function: deleteSelectedConsent },
        secondary: { label: 'public.cancel', function: hideDeleteModal },
    }

    const paginationData = {
        currentPage: pageIndex,
        totalPages: getTotalPageItems(totalItems || 0, defaultPageSize) || 0,
        onChange: handlePageChange,
        totalItems: totalItems || 0,
        pageSize: defaultPageSize,
        page: pageIndex,
        className: 'marginTop32',
    }

    return (
        <>
            <ScreenHeader
                title={'consents.title'}
                hideBorderBottom={true}
                titleClassname="heading5"
            />
            {canReadVerifiedUsers ? (
                !isLoading && state.searchedResult ? (
                    <>
                        {!!consents && consents?.length ? (
                            <div>
                                {!canReadVerifiers && (
                                    <PermissionsPanel
                                        specialViewPermission
                                        scopes={verifierScopes?.read}
                                    />
                                )}

                                <ConsentsTable
                                    tableData={state.searchedResult}
                                    pending={false}
                                    headerData={headersConsentsTable}
                                    canReadVerifiers={canReadVerifiers}
                                    mobileItem={(item) => (
                                        <BasicCard
                                            item={item}
                                            name={shortValue(item.id, 15, 5)}
                                            rightDate={item?.createdAt}
                                            buttonFunction={openDetailsModal}
                                        />
                                    )}
                                    paginationData={paginationData}
                                    selectItem={selectConsentHandler}
                                    deleteItem={handleOptions}
                                    noResults={
                                        consents &&
                                        consents.length > 0 &&
                                        !(
                                            state.searchedResult &&
                                            state.searchedResult?.length
                                        )
                                    }
                                />

                                {showDetail && consentData ? (
                                    <DataUsersModal
                                        show={true}
                                        hideModal={hideDetailModal}
                                        selectedCredentials={consentData}
                                    />
                                ) : null}
                                {showDelete ? (
                                    <WarningModal
                                        hideModal={hideDeleteModal}
                                        item={selectedConsent}
                                        primaryButton={
                                            warningModalButtons.primary
                                        }
                                        secondaryButton={
                                            warningModalButtons.secondary
                                        }
                                    />
                                ) : null}
                            </div>
                        ) : (
                            <Description text={t('consents.noValidData')} />
                        )}
                    </>
                ) : (
                    <TableSkeleton
                        className={cx('marginTop32')}
                        columnsNumber={3}
                        rowsNumber={defaultPageSize}
                        responsiveCards={true}
                    />
                )
            ) : (
                <PermissionsPanel scopes={consentScopes?.read} readScope />
            )}
        </>
    )
}

export default Consents
