import { useEffect, useState } from 'react'
import { brandLabels, countriesAndStates } from '../assets/globalConstants'
import { initialLang } from '../i18n'

export const defaultCountryIndexByBrand = brandLabels?.defaultCountry || 0
export const defaultCountryByBrand =
    countriesAndStates[defaultCountryIndexByBrand]

export const defaultCountry = (defaultCountryIndex?: number) => {
    if (countriesAndStates) {
        const defaultCountry = defaultCountryIndex
            ? countriesAndStates[defaultCountryIndex]
            : countriesAndStates[0]
        return initialLang
            ? defaultCountry['name_' + initialLang]
            : defaultCountry?.name_es
    } else return ''
}

// Responsive preview

export const getResponsive = (widthSize) => {
    const isBrowser = typeof window !== 'undefined'
    const [dimensions, setDimensions] = useState(
        isBrowser ? window.innerWidth || Math.min(window.innerWidth) : 0
    )
    const handleResize = () => {
        setDimensions(
            isBrowser ? window.innerWidth || Math.min(window.innerWidth) : 0
        )
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize, false)
    }, [])

    const width = Math.min(dimensions)
    const smallResolution = width < widthSize

    return smallResolution
}

export const checkIfURLIsSecure = (url: string) => {
    // Secure protocol:
    const isValidUrl = url?.indexOf('https') == 0

    /* Check if the host is the same of the window
     to avoid that the browser set an empty host with the gataca host */
    let notSameHost = true
    const a = document?.createElement('a')

    if (a && window?.location?.host) {
        a.href = url
        notSameHost = !!(a.host && a.host != window.location.host)
    }

    return isValidUrl && notSameHost
}

export const arraysHasSameData = (arr1?: any[], arr2?: any[]) => {
    return (
        JSON.stringify([...(arr1 || [])]?.sort()) ===
        JSON.stringify([...(arr2 || [])]?.sort())
    )
}
