import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { gaImages } from '../../../../assets/globalConstants'
import { sessionSelectors } from '../../../../features/common/session'
import { PermissionsDataModel } from '../../../../interfaces/interfaces'
import { addOrRemove } from '../../../../utils/checkboxUtil'
import { userHasScopes } from '../../../../utils/permissionsUtil'
import PermissionsPanel from '../../../molecules/notification/panels/permissionsPanel/PermissionsPanel'
import InputSkeleton from '../../../molecules/skeletons/inlineSkeleton/InputSkeleton'
import GaCheckbox from '../checkbox/GaCheckbox'
import style from './gaSelectCheckbox.module.scss'
import { TextSizes, TooltipPosition } from '../../../../data/globalVar'
import ChevronUpIcon from '../../../../assets/iconsLibrary/simple/chevronUp/chevronUpIcon'
import ChevronDownIcon from '../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon'
import SearchBar, {
    IPropertiesToSearch,
} from '../../../molecules/searchBar/SearchBar'
import DarkButton from '../../buttons/DarkButton/DarkButton'
import LightButton from '../../buttons/LightButton/LightButton'
import GaHoverTooltip from '../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'

export type Selectable = {
    key: string
    value: any
    subText?: string
}

type GaSelectCheckboxProps = {
    id: string
    labelText?: string
    placeholder?: string
    extraDescription?: string
    value?: string
    searchFields?: string[]
    disabled?: boolean
    invalid?: boolean
    readOnly?: boolean
    name?: string
    required?: boolean
    className?: string
    options: Selectable[]
    optionTextProperty?: string
    optionValueProperty?: string
    optionSubTextProperty?: string
    autoComplete?: string
    selectAllOption?: Selectable
    errorTexts?: NonNullable<React.ReactNode[]>
    isLoading?: boolean
    checkedData?: string[]
    permissions?: PermissionsDataModel
    textSize?: TextSizes
    propertiesToSearch?: IPropertiesToSearch[]
    tooltip?: boolean
    infoText?: string
    showErrorsBeforeTouched?: boolean
    helperText?: string
    onChange: (e?) => void
    handleSearchedChange?: (x?: any) => void
}

const GaSelectCheckbox: React.FC<GaSelectCheckboxProps> = React.memo(
    (props) => {
        const {
            id,
            labelText,
            extraDescription,
            placeholder,
            className,
            disabled,
            readOnly,
            invalid,
            name,
            value,
            searchFields,
            required,
            options,
            selectAllOption,
            optionTextProperty,
            optionSubTextProperty,
            autoComplete,
            errorTexts,
            isLoading,
            helperText,
            checkedData,
            permissions,
            textSize,
            tooltip,
            infoText,
            showErrorsBeforeTouched,
            propertiesToSearch,
            onChange,
            handleSearchedChange,
        } = props
        const { t } = useTranslation()

        const sizeFontClass = {
            LG: 'bodyRegularMD',
            MD: 'bodyRegularSM',
        }

        const [availableOptions, setOptions] = useState(new Array())
        const [touched, setTouched] = useState(showErrorsBeforeTouched || false)
        const [allOptionsChecked, setAllOptionsChecked] = useState(false)

        const [checked, setChecked] = useState(checkedData || [])
        const [notSavedCheecked, setNotSavedChecked] = useState(
            checkedData || []
        )
        const [missing, setMissing] = useState(false)
        const invalidErrorTexts =
            invalid &&
            errorTexts?.length &&
            (touched || showErrorsBeforeTouched) &&
            !readOnly

        const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
        const [showDropdown, setShowDropdown] = useState(false)
        const hasScopes = !permissions?.scopes
            ? true
            : availableScopes
            ? userHasScopes(
                  permissions?.scopes || [],
                  availableScopes,
                  permissions?.every
              )
            : false

        const [selectedView, setSelectedView] = useState(
            checked?.length
                ? options
                      .filter((item) => checked.includes(item.key))
                      .map((item) => item.value)
                      .join(', ')
                : ''
        )

        const [searchedResult, setSearchState] = React.useState(
            options?.slice() || []
        )

        useEffect(() => {
            if (touched && !readOnly) {
                required && setMissing(!checkedData?.length)
            }
        }, [touched, readOnly, checkedData])

        useEffect(() => {
            const objectToShow = showDropdown ? notSavedCheecked : checkedData
            setSelectedView(
                objectToShow?.length
                    ? options
                          .filter((item) => objectToShow.includes(item.key))
                          .map((item) => item.value)
                          .join(', ')
                    : ''
            )
            setChecked(checkedData || [])
            setNotSavedChecked(checkedData || [])
        }, [checkedData])
        useEffect(() => {
            setOptions(options)
            setSearchState(options?.slice() || [])
        }, [options])
        useEffect(() => {}, [checked?.length])
        useEffect(() => {
            const optionKeys =
                options && options[0]
                    ? options?.map((option) => option.key)
                    : []
            const optionValues =
                options && options[0]
                    ? options?.map((option) => option.value)
                    : []
            if (!allOptionsChecked) {
                setChecked(
                    checked?.length > 0 && options?.length === checked?.length
                        ? optionKeys
                        : checked
                )
                setNotSavedChecked(
                    checked?.length > 0 && options?.length === checked?.length
                        ? optionKeys
                        : checked
                )
                showDropdown
                    ? onChange(
                          checked?.length > 0 &&
                              options?.length !== checked?.length
                              ? optionKeys
                              : []
                      )
                    : null
            } else {
                setSelectedView(optionValues?.join(', '))
                setChecked(optionKeys)
                setNotSavedChecked(optionKeys)
                showDropdown ? onChange(optionKeys) : null
            }
        }, [allOptionsChecked])

        function showCheckboxes(saveChanges?: boolean) {
            const checkboxes = document.getElementById(id)
            if (!showDropdown) {
                checkboxes ? (checkboxes.style.display = 'block') : null
                setShowDropdown(true)
            } else {
                checkboxes ? (checkboxes.style.display = 'none') : null

                if (!saveChanges) {
                    setChecked(checkedData || [])
                    setNotSavedChecked(checkedData || [])
                    setSelectedView(
                        checkedData?.length
                            ? options
                                  .filter((item) =>
                                      checkedData.includes(item.key)
                                  )
                                  .map((item) => item.value)
                                  .join(', ')
                            : ''
                    )
                    setShowDropdown(false)
                } else {
                    showDropdown && onChange(notSavedCheecked)
                    const objectToShow = showDropdown
                        ? notSavedCheecked
                        : checkedData

                    setSelectedView(
                        objectToShow?.length
                            ? options
                                  .filter((item) =>
                                      objectToShow.includes(item.key)
                                  )
                                  .map((item) => item.value)
                                  .join(', ')
                            : ''
                    )
                }
            }
        }

        const returnCheckecValues = (item) => {
            const checkedClonned = JSON.parse(JSON.stringify(notSavedCheecked))
            const selection = addOrRemove(item.key, checkedClonned)
            const checkedDataModified = checkedClonned?.length
                ? options
                      .filter((item) => checkedClonned?.includes(item.key))
                      .map((item) => item.value)
                      .join(', ')
                : ''
            setSelectedView(checkedDataModified)

            setNotSavedChecked(selection)
        }

        const clearAll = () => {
            setNotSavedChecked([])
            setSelectedView(
                notSavedCheecked?.length > 0 && options?.length
                    ? ''
                    : selectedView
            )
        }

        return !isLoading ? (
            <div
                className={`${className} ${style.gaSelectCheckboxContainer} ${
                    required ? style.required : ''
                }
                ${disabled ? style.disabled : ''} ${
                    readOnly ? style.readOnly : ''
                }`}
            >
                {!permissions?.scopes || hasScopes ? (
                    <>
                        <div className={style.topTextsContainer}>
                            {labelText ? (
                                <label
                                    className={`${cx(
                                        'bodyRegularXS neutral700'
                                    )}`}
                                >
                                    {labelText}
                                    {tooltip && infoText ? (
                                        <GaHoverTooltip
                                            info={infoText && infoText}
                                            position={TooltipPosition.right}
                                        />
                                    ) : null}
                                </label>
                            ) : null}
                            {extraDescription ? (
                                <span
                                    className={`${cx(
                                        'bodyRegularCap neutral600'
                                    )} ${style.extraDescription} ${
                                        !labelText ? style.noLabel : ''
                                    }`}
                                >
                                    {extraDescription}
                                </span>
                            ) : null}
                        </div>
                        <div
                            className={`${style.overSelect} `}
                            onClick={() => showCheckboxes()}
                        >
                            {!showDropdown && !notSavedCheecked?.length ? (
                                <div
                                    className={`${style.customPlaceholder} ${cx(
                                        sizeFontClass[textSize || 'LG']
                                    )}`}
                                >
                                    {placeholder
                                        ? placeholder
                                        : t('general.chooseAnOption')}
                                </div>
                            ) : null}
                            <select
                                className={`${style.noneEvents} ${cx(
                                    sizeFontClass[textSize || 'LG']
                                )}`}
                                disabled={disabled}
                                onClick={() => showCheckboxes()}
                                id={
                                    id
                                        ? id + '-select'
                                        : labelText || '' + Math.random()
                                }
                                name={name ? name : ''}
                                onChange={() => () => {}}
                                onBlur={() => setTouched(true)}
                                required={required || false}
                                autoComplete={
                                    autoComplete ? autoComplete : 'Off'
                                }
                                value={value || ''}
                            >
                                <option
                                    label={selectedView}
                                    value=""
                                    disabled={false}
                                />
                            </select>
                            {showDropdown ? (
                                <ChevronUpIcon size={24} />
                            ) : (
                                <ChevronDownIcon size={24} />
                            )}
                        </div>
                        <div id={id} className={style.checkboxes}>
                            {!!availableOptions?.length ? (
                                <>
                                    {/* TODO: Commented until search is working */}
                                    {/* {availableOptions?.length > 8 ? (
                                        <div
                                            className={style.searchBarContainer}
                                        >
                                            <SearchBar
                                                className={style.searchBar}
                                                options={options || []}
                                                propertiesToSearch={
                                                    propertiesToSearch
                                                }
                                                icon={gaImages.searchBar}
                                                cleanIcon={true}
                                                handleSearchedChange={(x) =>
                                                    handleSearchedChange &&
                                                    handleSearchedChange(x)
                                                }
                                                placeholder={'public.search'}
                                            />
                                        </div>
                                    ) : null} */}
                                    <div
                                        className={`${style.list} ${
                                            // TODO: Commented until search is working
                                            // availableOptions?.length > 8
                                            //     ? ''
                                            // :
                                            style.noSearchBar
                                        }`}
                                    >
                                        {selectAllOption &&
                                            options &&
                                            options[0] && (
                                                <GaCheckbox
                                                    className={`${
                                                        style.selectAll
                                                    }
                                                    ${cx(
                                                        'paddingTop16 paddingBottom16'
                                                    )}`}
                                                    key={selectAllOption?.key}
                                                    itemText={
                                                        selectAllOption?.value
                                                    }
                                                    textSize={TextSizes.LG}
                                                    item={selectAllOption}
                                                    index={
                                                        selectAllOption?.value
                                                    }
                                                    onChange={(e) => {
                                                        showDropdown &&
                                                            setAllOptionsChecked(
                                                                !allOptionsChecked
                                                            )
                                                    }}
                                                    checked={
                                                        notSavedCheecked?.length >
                                                            0 &&
                                                        notSavedCheecked?.length ===
                                                            options.length
                                                    }
                                                />
                                            )}

                                        {options?.map((item, index) => {
                                            return (
                                                <GaCheckbox
                                                    className={cx(
                                                        'paddingTop16 paddingBottom16'
                                                    )}
                                                    fullElementClickable
                                                    key={item.key}
                                                    itemSubText={
                                                        item.subText ||
                                                        undefined
                                                    }
                                                    itemText={
                                                        optionTextProperty
                                                            ? t(
                                                                  item.value[
                                                                      optionTextProperty
                                                                  ]
                                                              )
                                                            : item.value
                                                    }
                                                    textSize={TextSizes.LG}
                                                    item={item}
                                                    index={id + index}
                                                    onChange={(e) =>
                                                        showDropdown &&
                                                        returnCheckecValues(e)
                                                    }
                                                    checked={notSavedCheecked?.includes(
                                                        item.key
                                                    )}
                                                />
                                            )
                                        })}
                                    </div>
                                </>
                            ) : null}
                            <div className={style.buttonsContainer}>
                                <LightButton
                                    text={
                                        notSavedCheecked?.length > 0
                                            ? 'public.clearAll'
                                            : 'public.cancel'
                                    }
                                    disabled={false}
                                    noBorder
                                    functionality={() =>
                                        notSavedCheecked?.length > 0
                                            ? clearAll()
                                            : showCheckboxes()
                                    }
                                />

                                <DarkButton
                                    text={'public.confirmSelection'}
                                    disabled={false}
                                    functionality={() => showCheckboxes(true)}
                                />
                            </div>
                        </div>
                        {helperText || invalidErrorTexts || missing ? (
                            <ul
                                className={`${style.bottomTextsContainer} ${cx(
                                    'marginTop4'
                                )}`}
                            >
                                {helperText ? (
                                    <li
                                        key="helperText"
                                        className={`${cx(
                                            'bodyRegularCap neutral600'
                                        )}`}
                                    >
                                        {helperText}
                                    </li>
                                ) : null}
                                {invalidErrorTexts
                                    ? errorTexts?.map((errorText) => (
                                          <li
                                              key="errorText"
                                              className={`${cx(
                                                  'bodyRegularCap alert300'
                                              )}`}
                                          >
                                              {errorText}
                                          </li>
                                      ))
                                    : null}

                                {missing && !readOnly ? (
                                    <li
                                        key="required"
                                        className={`${cx(
                                            'bodyRegularCap alert300'
                                        )} `}
                                    >
                                        {t('public.required')}
                                    </li>
                                ) : null}
                            </ul>
                        ) : null}
                    </>
                ) : (
                    <>
                        <PermissionsPanel
                            scopes={permissions?.scopes}
                            labelText={labelText}
                            requiredLabel={required}
                        />
                    </>
                )}
            </div>
        ) : (
            <InputSkeleton labelText={labelText} required={required} />
        )
    }
)

export default GaSelectCheckbox
