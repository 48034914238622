import React from 'react'
import styles from './loader.module.scss'

type LoaderProps = {
    className?: string
    withNoOverlay?: boolean
}

const Loader: React.FC<LoaderProps> = (props) => {
    const { className, withNoOverlay } = props

    return (
        <div
            className={`${styles.loadingContainer} ${
                withNoOverlay ? styles.withNoOverlay : ''
            } ${className && className}`}
        >
            <div className={styles.loader}></div>
        </div>
    )
}

export default Loader
