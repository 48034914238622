import { ofType } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import auth from '../../../services/auth'
import dataService from '../../../services/dataService'
import { history } from '../../../services/history'
import localStorageService from '../../../services/localStorageService'
import { showNotification } from '../Notification/notificationActions'
import { checkUsers } from '../Team/tabsContent/users/store/usersActions'
import { loadSessionData, readSession } from '../session/sessionActions'
import {
    emailSignUpFailed,
    emailSignUpSuccess,
    loginDone,
    loginFailed,
} from './loginAction'
import { LoginActionTypes } from './loginTypes'

// import {checkUsers} from '../global/usersActions'
// import {showNotification} from '../notifications/notificationActions'

export const submitLoginEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(LoginActionTypes.SUBMIT_LOGIN),
        switchMap((action: any): any => {
            return dataService.authToken(action.token).pipe(
                map(
                    (res: any) => console.log(res)
                    // setSession(response.headers.token, global),
                ),
                catchError((error) =>
                    of(
                        loginFailed(error),
                        // console.log(error)
                        showNotification('login.errorSignIn', 'error', '')
                    )
                )
            )
        })
    )
}

export const emailSignUpEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(LoginActionTypes.EMAIL_SIGN_UP),
        switchMap((action: any): any => {
            const user = action.payload.user
            return from(dataService.emailSignUp(user)).pipe(
                mergeMap((response: any) => {
                    return of(emailSignUpSuccess(), readSession())
                }),
                catchError((error) => {
                    return of(
                        emailSignUpFailed(error),
                        showNotification(
                            'login.errorSignUp',
                            'error',
                            error.response?.data?.message
                        )
                    )
                })
            )
        })
    )
}

export const submitEmailloginEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(LoginActionTypes.SUBMIT_EMAIL_LOGIN),
        switchMap((action: any): any => {
            const user = action.payload.user
            return from(dataService.emailLogIn(user)).pipe(
                map((response: any) => {
                    return readSession()
                }),
                catchError((error) => {
                    console.log('ERROR', error)
                    return of(
                        checkUsers(),
                        showNotification(
                            'login.errorSignIn',
                            'error',
                            error?.response?.data?.message
                        )
                    )
                })
            )
        })
    )
}

export const switchTenantEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(LoginActionTypes.SWITCH_TENANT),
        switchMap((action: any): any => {
            const tenant = action.payload.tenant
            return from(dataService.switchTenant(tenant)).pipe(
                mergeMap((response: any) => {
                    auth.deleteCurrentTenant()
                    auth.setCurrentTenant(tenant)
                    return merge(of(readSession()), of(loadSessionData()))
                }),
                catchError((error) => {
                    return of(
                        showNotification(
                            'login.switchTenantError',
                            'error',
                            '',
                            error?.status
                        ),
                        checkUsers()
                    )
                })
            )
        })
    )
}

export const checkLoginEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(LoginActionTypes.CHECK_LOGIN),
        switchMap((action: any): any => {
            // console.log('EPIC:CHECK_LOGIN', action)
            return (
                map((response: any) => {
                    // console.log('Response CHECK_LOGIN:', response)
                    return readSession()
                }),
                catchError((error) => {
                    // console.log('LOGIN ERROR at', history.location)
                    return of()
                    // checkUsers()
                })
            )
        })
        // map(res => console.log(res))
    )
}

export const loginSuccessEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(LoginActionTypes.LOGIN_SUCCESS),
        switchMap((action: any): any => {
            // console.log('Login Success Results', action.payload)
            const orgId = action?.payload?.orgId
            history.push('home')
            return of(readSession(), loginDone())
        })
    )
}

export const signupSuccessEpic = (action$: any, state$: any): any => {
    return action$.pipe(
        ofType(LoginActionTypes.QR_SIGNUP_SUCCESS),
        switchMap((action: any): any => {
            history.push('home')
            return of(readSession())
        })
    )
}

export const loginOutEpic: any = (action$: any) => {
    return action$.pipe(
        ofType(LoginActionTypes.LOGOUT),
        switchMap((action: any): any => {
            sessionStorage.clear()
            localStorageService?.deleteLocalStorageData('TMClosed')
            return of(loginDone())
        })
    )
}
