import React, { useState } from 'react'
import { t } from 'i18next'
import cx from 'classnames'
import styles from './stepsAppIntegration.module.scss'
import {
    IAppIntegrationModel,
    StepModalModel,
} from '../../../../../../../interfaces/interfaces'
import AgeVerificationModal from '../AgeVerificationModal'
import VouchModalCloseConfirmation from '../Status/CloseConfirmation/CloseConfirmation'
import { gaColors, gaImages } from '../../../../../../../assets/globalConstants'
import GaTextInput from '../../../../../../../Components/atoms/formFields/textInput/GaTextInput'
import LinkButtonIcon from '../../../../../../../Components/atoms/buttons/LinkButtonIcon/LinkButtonIcon'
import PlusIcon from '../../../../../../../assets/iconsLibrary/simple/plus/plusIcon'

type IRedirectionFormProps = {
    ageVerificationCurrentStep: StepModalModel
    ageVerificationSteps: StepModalModel[]
    formData: IAppIntegrationModel
    setAgeVerificationCurrentStep: (x?) => void
    hideModal: () => void
    continueButtonFunction: () => void
    handleInputRedirectChange: (e?, i?) => void
    removeLastRedirectUrl: (x?) => void
}

const RedirectionForm: React.FC<IRedirectionFormProps> = (props) => {
    const {
        ageVerificationCurrentStep,
        ageVerificationSteps,
        formData,
        setAgeVerificationCurrentStep,
        hideModal,
        continueButtonFunction,
        handleInputRedirectChange,
        removeLastRedirectUrl,
    } = props

    // Constants
    const inputValue = formData.redirect_uris

    // States
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)
    const [redirectInputs, setRedirectInputs] = useState(inputValue)

    // Functions
    const goBackStepFunction = () => {
        setAgeVerificationCurrentStep(
            ageVerificationSteps[ageVerificationCurrentStep?.order - 1]
        )
    }

    const newRedirectInput = () => {
        setRedirectInputs([...redirectInputs, ''])
    }

    const removeRedirectInput = (index) => {
        redirectInputs.splice(index, 1)

        setRedirectInputs(redirectInputs)
        removeLastRedirectUrl(index)
    }

    return !showCloseConfirmation ? (
        <>
            <AgeVerificationModal
                stepTitle={t('dashboard.vouchModal.redirectionForm.stepTitle')}
                title={t('dashboard.vouchModal.redirectionForm.title')}
                description={t(
                    'dashboard.vouchModal.redirectionForm.description'
                )}
                hideModal={() => setShowCloseConfirmation(true)}
                continueButton={{
                    label: t('public.continue'),
                    function: continueButtonFunction,
                    disabled:
                        !formData?.redirect_uris?.length ||
                        !formData?.redirect_uris[0]?.trim(),
                }}
                cancelBackButton={{
                    label: t('public.back'),
                    function: goBackStepFunction,
                }}
                rightImage={gaImages.urlLost}
                progressIndicatorOptions={3}
                progressIndicatorCurrentOption={2}
            >
                <div
                    className={`${styles.bodyContainer} ${
                        styles.bodyContainer__redirectionForm
                    } ${cx('marginTop32')}`}
                >
                    {redirectInputs?.map((uri, index) => {
                        return (
                            <GaTextInput
                                id={'redirectURL_' + index}
                                key={'redirectURL_' + index}
                                name={'redirect_uris'}
                                labelText={
                                    index === 0
                                        ? t(
                                              'dashboard.vouchModal.redirectionForm.redirectURLLabel'
                                          )
                                        : undefined
                                }
                                helperText={
                                    index === 0
                                        ? t(
                                              'dashboard.vouchModal.redirectionForm.inputDesc'
                                          )
                                        : undefined
                                }
                                deleteFunctionality={
                                    index !== 0
                                        ? () => {
                                              removeRedirectInput(index)
                                          }
                                        : undefined
                                }
                                value={
                                    formData?.redirect_uris &&
                                    formData?.redirect_uris[index]
                                        ? formData?.redirect_uris[index]
                                        : ''
                                }
                                onChange={(e) => {
                                    handleInputRedirectChange(e, index)
                                }}
                                placeholder="https://url.com"
                                required
                                infoText={t(
                                    'dashboard.vouchModal.redirectionForm.redirectURLTooltip'
                                )}
                                tooltip
                            />
                        )
                    })}

                    {redirectInputs.length &&
                    formData?.redirect_uris[
                        redirectInputs?.length - 1
                    ]?.trim() ? (
                        <LinkButtonIcon
                            className={styles.linkButton}
                            functionality={newRedirectInput}
                            text={
                                <span>
                                    <PlusIcon
                                        size={16}
                                        color={gaColors.primary700}
                                    />
                                    <p className={cx('buttonSM primary700')}>
                                        {t(
                                            'dashboard.vouchModal.redirectionForm.addUrl'
                                        )}
                                    </p>
                                </span>
                            }
                            disabled={false}
                        />
                    ) : null}
                </div>
            </AgeVerificationModal>
        </>
    ) : showCloseConfirmation ? (
        <VouchModalCloseConfirmation
            closeModal={() => setShowCloseConfirmation(false)}
            continueFunction={hideModal}
            goBackFunction={() => setShowCloseConfirmation(false)}
        />
    ) : null
}

export default RedirectionForm
