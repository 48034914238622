import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './createVerifier.module.scss'
import { configVerifierSelectors } from '../..'
import {
    createVerifier,
    editVerifier,
    setActiveStep,
    setSelectedCredentials,
    setSteps,
} from '../../verifierActions'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import { formSteps } from '../../data/verifier.constants'
import { IVerifierConfigModel } from '../../../../../interfaces/interfaces'
import { getPurposes, getRequestedCredentials } from '../../verifierSelectors'
import { onPremise } from '../../../../../data/globalVar'
import { addVerifierType } from '../../../Dids/didActions'
import InvalidFormModal from '../../../../../Components/molecules/table/dynamicTable/invalidFormModal/InvalidFormModal'
import SuccessEnd from '../successEnd/SuccessEnd'
import { showScreenLoaderAction } from '../../../../common/Loader/loaderActions'

type ICreateVerifierProps = {}

const CreateVerifier: React.FC<ICreateVerifierProps> = ({}) => {
    const dispatch = useDispatch()

    // Variables
    const endsStep = useSelector(configVerifierSelectors.getEndsSteps)
    const verifier = useSelector(configVerifierSelectors.getSelectedVerifier)
    const createStatus = useSelector(configVerifierSelectors.getOperationStatus)
    const selected = useSelector(getRequestedCredentials)
    const purposeList = useSelector(getPurposes)
    const activeStep = useSelector(configVerifierSelectors.getActiveStep)
    const [validForm, setValidForm] = React.useState(false)
    const [lastStepSaved, setLastStepSaved] = React.useState(0)
    const [requirementsStepModified, setRequirementsStepModified] =
        React.useState(false)

    const [showInvalidFormModal, setShowInvalidDataModal] =
        React.useState(false)
    const showInvalidDataModal = () => setShowInvalidDataModal(true)
    const hideInvalidDataModal = () => setShowInvalidDataModal(false)

    const formatVerifier = (selectedVerifier) => {
        let formattedVerifier = selectedVerifier
        delete formattedVerifier._searchableText
        formattedVerifier.id

        const verifierCredentials = selectedVerifier?.credentials?.map((e) => {
            return {
                type: e.type || e.id,
                purpose: e.purpose,
                mandatory: e.mandatory,
                trustLevel: e.trustLevel,
            }
        })

        if (formattedVerifier?.credentials) {
            formattedVerifier!.credentials = verifierCredentials
            const credPurposes = formattedVerifier?.credentials?.map((cred) => {
                return cred?.purpose
            })
            const fullPurposes = purposeList?.filter((purpose) =>
                credPurposes?.includes(purpose?.id)
            )

            formattedVerifier.dataAgreementTemplate = {
                ...selectedVerifier?.dataAgreementTemplate,
                purposes: fullPurposes,
            }
        }

        return formattedVerifier
    }
    const forwardFunction = (data: IVerifierConfigModel) => {
        setLastStepSaved(
            activeStep && activeStep?.id >= lastStepSaved
                ? activeStep?.id
                : lastStepSaved
        )
        const newVerifier = { ...verifier, ...data }
        if (!newVerifier?.credentials || !newVerifier?.credentials[0]?.type) {
            newVerifier!.credentials = selected
        }
        const formattedVerifier = formatVerifier(newVerifier)
        updateDidType(formattedVerifier)
        dispatch(editVerifier(newVerifier))
        dispatch(setSelectedCredentials(selected))
        return endsStep && endsStep.nextStep
            ? dispatch(setActiveStep(endsStep.nextStep))
            : (dispatch(showScreenLoaderAction()),
              dispatch(
                  createVerifier(formattedVerifier as IVerifierConfigModel)
              ))
    }

    const updateDidType = (newVerifierConfig) => {
        const currentVMethod = verifier?.vmethodId
        const filledVMethod = newVerifierConfig?.vmethodId
        if (activeStep?.order === 0 && currentVMethod !== filledVMethod) {
            let verifierDid = filledVMethod?.split('#')[0]
            // Just in cloud for now
            !onPremise && dispatch(addVerifierType(verifierDid))
        }
    }

    // useEffect

    useEffect(() => {
        dispatch(setSteps(formSteps))
    }, [])

    useEffect(() => {}, [createStatus])

    // functions
    function onHandleChange(step) {
        if (
            (activeStep && step < activeStep?.order) ||
            (activeStep &&
                (step <= lastStepSaved ||
                    (!requirementsStepModified && activeStep?.order === 2)))
        ) {
            dispatch(setActiveStep(formSteps[step]))
        } else {
            showInvalidDataModal()
        }
    }

    return (createStatus as ActionStatusTypes) !== ActionStatusTypes.success ? (
        <>
            <div className={styles.createVerifier}>
                {activeStep?.component ? (
                    <activeStep.component
                        buttonFunction={forwardFunction}
                        verifier={verifier}
                        creatingVerifier={true}
                        canEditVerifiers={true}
                        setValidForm={setValidForm}
                        activeStep={activeStep || formSteps[0]}
                        onHandleChange={onHandleChange}
                        setRequirementsStepModified={
                            setRequirementsStepModified
                        }
                        formSteps={formSteps}
                    />
                ) : null}
            </div>

            {!!showInvalidFormModal ? (
                <InvalidFormModal hideModal={hideInvalidDataModal} />
            ) : null}
        </>
    ) : (
        <SuccessEnd />
    )
}

export default CreateVerifier
