import React from 'react'
import cx from 'classnames'
import { t } from 'i18next'
import styles from './closeConfirmation.module.scss'
import GaImage from '../../../../../../../../interfaces/Image/Image'
import { gaImages } from '../../../../../../../../assets/globalConstants'
import AgeVerificationModal from '../../AgeVerificationModal'

type IAppIntegrationModalCloseConfirmation = {
    continueFunction: () => void
    goBackFunction: () => void
    closeModal: () => void
}

const AppIntegrationModalCloseConfirmation: React.FC<
    IAppIntegrationModalCloseConfirmation
> = (props) => {
    const { continueFunction, goBackFunction, closeModal } = props

    return (
        <AgeVerificationModal
            className={styles.closeConfirmationModal}
            hideModal={closeModal}
            cancelBackButton={{
                label: t('public.noGoBack'),
                disabled: false,
                function: goBackFunction,
            }}
            continueButton={{
                label: t('dashboard.vouchModal.close.yesClose'),
                disabled: false,
                function: continueFunction,
            }}
        >
            <div className={styles.closeConfirmation}>
                <GaImage image={gaImages.warning1} />
                <p className={cx('heading6 marginTop20 ')}>
                    {t('dashboard.vouchModal.close.title')}
                </p>
                <p className={cx('bodyRegularMD neutral800 marginTop8')}>
                    {t('dashboard.vouchModal.close.description')}
                </p>
            </div>
        </AgeVerificationModal>
    )
}

export default AppIntegrationModalCloseConfirmation
