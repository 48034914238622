import React, { useState } from 'react'
import { t } from 'i18next'
import cx from 'classnames'
import styles from './stepsAppIntegration.module.scss'
import { IAppIntegrationModel } from '../../../../../../../interfaces/interfaces'
import AgeVerificationModal from '../AgeVerificationModal'
import { gaImages } from '../../../../../../../assets/globalConstants'
import VouchModalCloseConfirmation from '../Status/CloseConfirmation/CloseConfirmation'
import RadioButtonOptionsCard from '../../../../../../../Components/organisms/OptionsCard/RadioButtonOptionsCard/RadioButtonOptionsCard'
import { scopeOptions } from '../data/ageVerificationModal.constants'

type IScopeFormProps = {
    formData?: IAppIntegrationModel
    continueButtonFunction: (x?) => void
    setFormState: (x?) => void
    hideModal: () => void
}

const ScopeForm: React.FC<IScopeFormProps> = (props) => {
    const { formData, continueButtonFunction, hideModal, setFormState } = props

    // Constants
    const selectedOptionsArr = formData?.scope?.split(' ')
    const formModified = formData?.scope?.trim() !== 'openid'

    // States
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)

    function handleOptionChange(selected: string) {
        setFormState({
            ...formData,
            scope: selected,
        })
    }

    return !showCloseConfirmation ? (
        <>
            <AgeVerificationModal
                stepTitle={t('dashboard.vouchModal.scopeForm.stepTitle')}
                title={t('dashboard.vouchModal.scopeForm.title')}
                description={t('dashboard.vouchModal.scopeForm.description')}
                hideModal={() => {
                    formModified ? setShowCloseConfirmation(true) : hideModal()
                }}
                continueButton={{
                    label: t('public.continue'),
                    function: continueButtonFunction,
                    disabled: !formModified,
                }}
                cancelBackButton={{
                    label: t('public.cancel'),
                    function: () =>
                        formModified
                            ? setShowCloseConfirmation(true)
                            : hideModal(),
                }}
                rightImage={gaImages.usersAge}
                progressIndicatorOptions={3}
                progressIndicatorCurrentOption={1}
            >
                <div className={`${styles.bodyContainer} ${cx('marginTop32')}`}>
                    {scopeOptions.length > 0 &&
                        scopeOptions
                            ?.slice(0, 4)
                            .map((option, index) => (
                                <RadioButtonOptionsCard
                                    key={index}
                                    id={'ageVerification'}
                                    option={option}
                                    index={index}
                                    checked={selectedOptionsArr?.includes(
                                        option?.id
                                    )}
                                    handleOptionChange={handleOptionChange}
                                />
                            ))}
                </div>
            </AgeVerificationModal>
        </>
    ) : showCloseConfirmation ? (
        <VouchModalCloseConfirmation
            closeModal={() => setShowCloseConfirmation(false)}
            continueFunction={hideModal}
            goBackFunction={() => setShowCloseConfirmation(false)}
        />
    ) : null
}

export default ScopeForm
