import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './gaTextArea.module.scss'
import cx from 'classnames'
import { TextSizes } from '../../../../data/globalVar'

type GaTextAreaProps = {
    id?: string
    labelText?: string
    placeholder?: string
    helperText?: string
    extraDescription?: string
    value?: string
    readOnly?: boolean
    disabled?: boolean
    errorTexts?: NonNullable<React.ReactNode | undefined>[]
    invalid?: boolean
    name?: string
    required?: boolean
    ariaLabel?: string
    sucessText?: string
    className?: string
    autoComplete?: string
    formId?: string
    rows?: number
    cols?: number
    textSize?: TextSizes
    maxLength?: number
    minLength?: number
    onChange?: (e?) => void
    onKeyDown?: (e?) => void
}

const GaTextArea: React.FC<GaTextAreaProps> = React.memo((props) => {
    const {
        id,
        labelText,
        placeholder,
        sucessText,
        ariaLabel,
        className,
        helperText,
        extraDescription,
        disabled,
        name,
        value,
        errorTexts,
        invalid,
        required,
        autoComplete,
        readOnly,
        maxLength,
        minLength,
        formId,
        rows,
        cols,
        textSize,
        onChange,
        onKeyDown,
    } = props
    const { t } = useTranslation()

    const sizeFontClass = {
        LG: 'bodyRegularMD',
        MD: 'bodyRegularSM',
    }

    const [touched, setTouched] = useState(false)
    const [success, setSuccess] = useState(false)
    const [missing, setMissing] = useState(false)
    const invalidErrorTexts =
        invalid && errorTexts?.length && touched && !readOnly
    const sucessTextActive = success && sucessText
    const showBottomTexts =
        helperText || invalidErrorTexts || missing || sucessTextActive

    useEffect(() => {
        invalid && setTouched(false)
    }, [value])

    useEffect(() => {
        success && setSuccess(true)
    }, [value])

    useEffect(() => {
        if (touched && !readOnly) {
            required && setMissing(!value?.trim()?.length)
        }
    }, [touched, value])

    const addErrorClass = missing || (invalid && touched && !readOnly)

    return (
        <div
            className={`${className} ${style.gaTextAreaContainer}
            ${required && style.required}
            ${readOnly && style.readOnly}
             ${disabled && style.disabled} 
           ${addErrorClass && style.error}
            ${sucessTextActive && style.success} `}
        >
            {labelText || extraDescription ? (
                <div className={style.topTextsContainer}>
                    {labelText ? (
                        <label className={cx('bodyRegularXS neutral700')}>
                            {labelText}
                        </label>
                    ) : null}
                    {extraDescription ? (
                        <span
                            className={`${cx('bodyRegularCap neutral600')} ${
                                style.extraDescription
                            } ${!labelText ? style.noLabel : ''}`}
                        >
                            {extraDescription}
                        </span>
                    ) : null}
                </div>
            ) : null}
            <textarea
                className={`${cx(sizeFontClass[textSize || 'LG'])}`}
                id={id ? id : labelText || '' + Math.random()}
                name={name}
                rows={rows || 4}
                cols={cols || 50}
                aria-label={ariaLabel ? ariaLabel : labelText ? labelText : ''}
                disabled={!!disabled}
                form={formId}
                maxLength={maxLength}
                minLength={minLength}
                readOnly={readOnly}
                autoComplete={autoComplete ? autoComplete : 'Off'}
                required={!!required}
                placeholder={placeholder ? t(placeholder) : ''}
                onKeyDown={(e) => {
                    onKeyDown ? onKeyDown(e) : () => {}
                }}
                onBlur={() => setTouched(true)}
                onChange={(e) => {
                    onChange ? onChange(e) : () => {}
                }}
                value={value || ''}
            ></textarea>
            {showBottomTexts ? (
                <ul
                    className={`${style.bottomTextsContainer} ${cx(
                        'marginTop4'
                    )}`}
                >
                    {helperText ? (
                        <li
                            key="helperText"
                            className={`${cx('bodyRegularCap neutral600')} ${
                                style.leftText
                            }`}
                        >
                            {helperText}
                        </li>
                    ) : null}
                    {invalidErrorTexts
                        ? errorTexts?.map((errorText, index) => (
                              <li
                                  key={'errorText__' + index}
                                  className={`${cx(
                                      'bodyRegularCap alert300'
                                  )} ${style.leftText}`}
                              >
                                  {errorText}
                              </li>
                          ))
                        : null}
                    {missing ? (
                        <li
                            key="required"
                            className={`${cx('bodyRegularCap alert300')} ${
                                style.leftText
                            }`}
                        >
                            {t('public.required')}
                        </li>
                    ) : null}
                    {sucessTextActive ? (
                        <li
                            className={`${cx('bodyRegularCap success300')} ${
                                style.leftText
                            }`}
                        >
                            {sucessText}
                        </li>
                    ) : null}
                </ul>
            ) : null}
        </div>
    )
})

export default GaTextArea
