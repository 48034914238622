import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ActionStatusTypes } from '../../../../../../../assets/globalConstants'
import StatusPanel from '../../../../../../../Components/atoms/panels/StatusPanel/StatusPanel'
import { IUserItem } from '../../../../../../../interfaces/interfaces'
import { setSelectedRoute } from '../../../../../PanelScafolding/scafoldingActions'
import RegisterForm from '../forms/registerForm/RegisterForm'
import {
    clearActionStatus,
    clearUser,
    clearUserList,
    createUser,
} from '../../store/usersActions'
import {
    getError,
    getSelectedUser,
    getStatus,
} from '../../store/usersSelectors'

type ICreateUserProps = {
    hideNewUser: () => void
}

const CreateUser: React.FC<ICreateUserProps> = (props) => {
    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const selectedUser = useSelector(getSelectedUser)
    const createStatus = useSelector(getStatus)
    const creationError = useSelector(getError)

    useEffect(() => {
        return () => {
            dispatch(clearUser())
        }
    }, [])

    const registerUser = (user: IUserItem) => {
        dispatch(createUser(user))
    }

    const handleCloseModal = () => dispatch(clearActionStatus())

    const navigate = (route) => {
        dispatch(setSelectedRoute(route)), history.push(route)
    }
    const goToHome = () => {
        clearState(), navigate('home')
    }
    const goToUsersList = () => {
        clearState(), navigate('team')
    }

    const clearState = () => {
        dispatch(clearActionStatus())
        dispatch(clearUser())
    }

    return (
        <>
            <div>
                <RegisterForm
                    buttonFunction={registerUser}
                    closeModal={props?.hideNewUser}
                />
            </div>

            <StatusPanel
                insideModal
                successMessage={'createUser.success'}
                state={'createUser.isReady'}
                operationStatus={createStatus}
                show={
                    createStatus &&
                    (createStatus as ActionStatusTypes) !==
                        ActionStatusTypes.neutral
                }
                failedText={
                    t('createUser.failed') + (creationError ? ': ' : '.')
                }
                error={creationError || ''}
                primaryButton={{
                    label: 'createUser.backToList',
                    function: goToUsersList,
                }}
                secondaryButton={{
                    label: 'createUser.backToHome',
                    function: goToHome,
                }}
                handleClose={() => {
                    goToUsersList(), handleCloseModal()
                }}
            />
        </>
    )
}

export default CreateUser
