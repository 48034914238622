import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import { ButtonModel } from '../../../../../interfaces/interfaces'
import { setSelectedCredentials } from '../../verifierActions'
import {
    formatRequestedCredentials,
    getFormattedPurposes,
    getFormattedRequestedCredentials,
    getSelectedVerifier,
    returnOriginalRequestedCredential,
} from '../../verifierSelectors'
import style from './credentialsRequirementsForm.module.scss'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import {
    ActionStatusTypes,
    purposesTypesList,
} from '../../../../../assets/globalConstants'
import { configVerifierSelectors } from '../..'
import { ReactComponent as AngleDown } from '../../../../../assets/images/angle-down.svg'
import GaHoverTooltip from '../../../../../Components/molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import {
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../../data/globalVar'
import TemplatesFormHeader from '../../../../common/TemplatesHeader/TemplatesFormHeader'
import RequirementAndPurposeCard from '../../../../../Components/organisms/CredentialCard/EditableCard/RequirementAndPurpose/RequirementAndPurposeCard'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import CredentialsRequirementsPreview from '../../../../../Components/molecules/modals/CredentialPreview/components/StepsContentPreview/CredentialsRequirementsPreview'
import { getLoadingStatus } from '../../../../common/catalog/catalogSelectors'
import { getResponsive } from '../../../../../utils/formUtils'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'

type ICredentialsRequirementsFormProps = {
    canEditVerifiers?: boolean
    creatingVerifier?: boolean
    buttonFunction: (x) => void
    setValidForm: (x) => void
    buttonHeader?: ButtonModel
    buttonFunctionHeader?: (x) => void
    infoApiKeyPanel?: boolean
    onHandleChange: (step) => void
    activeStep: any
    formSteps: any
    operationStatus?: ActionStatusTypes
    setRequirementsStepModified?: (x) => void
}

const CredentialsRequirementsForm: React.FC<
    ICredentialsRequirementsFormProps
> = (props) => {
    const {
        canEditVerifiers,
        creatingVerifier,
        buttonFunction,
        setValidForm,
        buttonHeader,
        buttonFunctionHeader,
        infoApiKeyPanel,
        onHandleChange,
        activeStep,
        formSteps,
        setRequirementsStepModified,
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    const purposes = useSelector(getFormattedPurposes)
    const selected = useSelector(getFormattedRequestedCredentials)
    const credTypesList = useSelector(
        configVerifierSelectors.getOriginalCatalogTypes
    )
    const catalogLoadingStatus = useSelector(getLoadingStatus)
    const catalogIsLoading = !(
        catalogLoadingStatus?.status === 'finished' ||
        catalogLoadingStatus?.status === 'error'
    )
    const currentRequestedUnfCred = useSelector(
        configVerifierSelectors.getRequestedUnfCredentials
    )

    const [credentials, setSelection] = React.useState(
        formatRequestedCredentials(credTypesList, currentRequestedUnfCred) || []
    )

    const verifier = useSelector(getSelectedVerifier)

    let ignoreDataAgreement = verifier?.ignoreDataAgreement

    const [showAdvancedOptions, setShowAdvancedOptions] = useState(true)
    const [dataAgreementChecked, setDataAgreementChecked] = useState(
        !ignoreDataAgreement
    )

    let requiredCredentials = formatRequestedCredentials(
        credTypesList,
        currentRequestedUnfCred
    )?.filter((el) => !!el.mandatory)
    let optionalCredentials = formatRequestedCredentials(
        credTypesList,
        currentRequestedUnfCred
    )?.filter((el) => !el.mandatory)
    const validForm = !!credentials.length

    const updateStatus = useSelector(configVerifierSelectors.getUpdateStatus)
    const initialRequestedUnfCred = useSelector(
        configVerifierSelectors.getInitialRequestedUnfCredentials
    )

    const step = useSelector(configVerifierSelectors.getActiveStep)

    const [formDataModified, setFormDataModified] = useState(false)

    useEffect(() => {
        if (ignoreDataAgreement === !dataAgreementChecked) {
            setFormDataModified(false)
            setRequirementsStepModified && setRequirementsStepModified(false)
        } else {
            setFormDataModified(true)
            setRequirementsStepModified && setRequirementsStepModified(true)
        }
    }, [dataAgreementChecked])

    useEffect(() => {
        if (
            updateStatus === ActionStatusTypes?.success &&
            JSON.stringify(
                returnOriginalRequestedCredential(initialRequestedUnfCred)
            ) ===
                JSON.stringify(
                    returnOriginalRequestedCredential(currentRequestedUnfCred)
                )
        ) {
            setFormDataModified(false)
            setRequirementsStepModified && setRequirementsStepModified(false)
        }
    }, [updateStatus])

    useEffect(() => {
        scrollToTop()
    }, [step])

    useEffect(() => {
        setSelection(
            formatRequestedCredentials(credTypesList, currentRequestedUnfCred)
        ),
            setValidForm(selected && !!selected[0])
    }, [step, currentRequestedUnfCred])

    useEffect(() => {
        setSelection(
            formatRequestedCredentials(credTypesList, currentRequestedUnfCred)
        ),
            setValidForm(selected && !!selected[0])
    }, [selected, currentRequestedUnfCred])

    useEffect(() => {
        if (
            JSON.stringify(
                returnOriginalRequestedCredential(initialRequestedUnfCred)
            ) ===
            JSON.stringify(
                returnOriginalRequestedCredential(currentRequestedUnfCred)
            )
        ) {
            setFormDataModified(false)
            setRequirementsStepModified && setRequirementsStepModified(false)
        } else {
            setFormDataModified(true)
            setRequirementsStepModified && setRequirementsStepModified(true)
        }
    }, [credentials])

    useEffect(() => {
        requiredCredentials = credentials?.filter((el) => !!el.mandatory)
        optionalCredentials = credentials?.filter((el) => !el.mandatory)
    }, [requiredCredentials, optionalCredentials])

    const setCredentialsMandatoryValues = (
        credIndex: number,
        value: string
    ) => {
        const modifiedList = credentials
        const isMandatory = value === 'required'
        modifiedList[credIndex]
            ? (modifiedList[credIndex].mandatory = isMandatory)
            : null

        dispatch(setSelectedCredentials(modifiedList))
        setSelection(modifiedList)
        setValidForm(credentials && credentials[0]?.length)
    }

    const handleSwitchChange = () => {
        setDataAgreementChecked(!dataAgreementChecked)
        setFormDataModified(true)
        setRequirementsStepModified && setRequirementsStepModified(true)
        ignoreDataAgreement = !ignoreDataAgreement
    }

    const setCredentialPurposes = (credIndex: number, purposeValue: string) => {
        const modifiedList = credentials
        const selectedPurposeValue = purposesTypesList.find(
            (element) => element.value === purposeValue || ''
        )?.value

        modifiedList[credIndex]
            ? (modifiedList[credIndex].purpose = selectedPurposeValue)
            : null

        dispatch(setSelectedCredentials(modifiedList))
        setSelection(modifiedList)
        setValidForm(credentials && credentials[0]?.length)
    }

    const saveAndNext = () => {
        buttonFunction({
            ...verifier,
            credentials: credentials,
            ignoreDataAgreement: !dataAgreementChecked,
        })
        scrollToTop()
    }

    const saveChange = () => {
        const dataAgreementTemplate = {
            ...verifier.dataAgreementTemplate,
            credentials: credentials,
            personal_data: credentials.map((cred) => {
                return {
                    attribute_name: cred.id,
                    attribute_sensitive: true,
                    purposes: [cred.purpose],
                }
            }),
            template_version: verifier?.dataAgreementTemplate?.template_version
                ? (
                      parseInt(
                          verifier?.dataAgreementTemplate?.template_version
                      ) + 1
                  ).toString()
                : '1',
        }

        buttonFunction({
            ...verifier,
            credentials: credentials,
            dataAgreementTemplate,
            ignoreDataAgreement: !dataAgreementChecked,
        })
        scrollToTop()
    }

    // Responsive preview

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    return (
        <>
            <div className={style.leftColumn}>
                <div className={style.headerStepper}>
                    <TemplatesFormHeader
                        title={
                            creatingVerifier
                                ? 'createVerifier.title'
                                : t('editVerifier.title', {
                                      id: verifier?.id || '',
                                  })
                        }
                        description={
                            creatingVerifier
                                ? 'createVerifier.description'
                                : 'editVerifier.description'
                        }
                        buttonFunctionHeader={buttonFunctionHeader}
                        buttonHeader={buttonHeader}
                        formSteps={formSteps}
                        elementsAllignment={creatingVerifier ? false : true}
                        infoApiKeyPanel={infoApiKeyPanel}
                        titleInfoPanel={t('editVerifier.addApiKeyInfo')}
                        onHandleChange={onHandleChange}
                        activeStep={activeStep}
                        loadingProgressBar={catalogIsLoading}
                        inactiveSteps={
                            !creatingVerifier && !verifier?.credentials?.length
                                ? [2, 3]
                                : undefined
                        }
                    />
                </div>
                <div className={style.form}>
                    <LightPanel>
                        <ScreenHeader
                            title={'editVerifier.step3.sectionTitle'}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            subText={
                                canEditVerifiers || creatingVerifier
                                    ? 'editVerifier.step3.sectionDescription'
                                    : ''
                            }
                            buttonIsLight={true}
                            buttonFunction={showModal}
                            button={
                                getResponsive(1320)
                                    ? {
                                          label: 'general.showPreview',
                                          disabled: false,
                                      }
                                    : undefined
                            }
                            className={style.formHeader}
                        />

                        {!!selected?.length ? (
                            <div className={style.panelContainer}>
                                <div
                                    className={`${style.panelList} ${cx(
                                        'marginTop32'
                                    )}`}
                                >
                                    {selected?.map((credential, index) => (
                                        <RequirementAndPurposeCard
                                            index={index}
                                            credential={credential}
                                            onChangeSwitchSelect={
                                                setCredentialsMandatoryValues
                                            }
                                            purposesList={purposes}
                                            indexCheckbox={credential.id}
                                            key={
                                                'editableCard' +
                                                index.toString()
                                            }
                                            dataAgreementChecked={
                                                dataAgreementChecked
                                            }
                                            checkedValue={
                                                !!credentials[index]?.mandatory
                                                    ? 'required'
                                                    : 'optional'
                                            }
                                            handleSelectChange={
                                                setCredentialPurposes
                                            }
                                            selectValue={
                                                credentials[index]?.purpose
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <CardListSkeleton
                                key={'credentials__skeleton'}
                                className={style.credentialsSkeleton}
                                cardsNumber={1}
                            />
                        )}
                        <div
                            className={`${cx('marginTop32')} ${
                                style.advancedOptions
                            }`}
                        >
                            <p
                                className={cx('buttonSM')}
                                onClick={() =>
                                    setShowAdvancedOptions(!showAdvancedOptions)
                                }
                            >
                                {t('certificates.advancedOptions')}
                                <AngleDown
                                    className={`${
                                        style.advancedOptionsToogle
                                    } ${
                                        showAdvancedOptions && style.toogleOpen
                                    }`}
                                />
                            </p>
                        </div>
                        {showAdvancedOptions ? (
                            <>
                                <div
                                    className={`${cx('marginTop20')} ${
                                        style.dataAgreementContainer
                                    }`}
                                >
                                    <div
                                        className={`${cx('marginRight20')} ${
                                            style.dataAgreementText
                                        }`}
                                    >
                                        <GaHoverTooltip
                                            className={
                                                style.dataAgreementText__tooltip
                                            }
                                            position={TooltipPosition.bottom}
                                            positionArrowDirection={
                                                TooltipArrowPosition.left
                                            }
                                            label={
                                                <p
                                                    className={cx(
                                                        'buttonSM neutral1000'
                                                    )}
                                                >
                                                    {t(
                                                        'editVerifier.step3.dataAgreement'
                                                    )}
                                                </p>
                                            }
                                            info={t(
                                                'editVerifier.step3.dataAgreementTooltip'
                                            )}
                                        />

                                        <p className={cx('bodyRegularXS')}>
                                            {t(
                                                'editVerifier.step3.dataAgreementExplanation'
                                            )}
                                        </p>
                                    </div>
                                    <div className={style.activationToggle}>
                                        <input
                                            checked={dataAgreementChecked}
                                            className={style.activationCheckbox}
                                            type="checkbox"
                                            id="switch"
                                        />
                                        <label
                                            onClick={(e) =>
                                                handleSwitchChange()
                                            }
                                            className={
                                                style.activationCheckboxLabel
                                            }
                                        ></label>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {getResponsive(1320) ? (
                            <div className={style.buttonContainer}>
                                <DarkButton
                                    text={
                                        canEditVerifiers && !creatingVerifier
                                            ? 'public.save'
                                            : 'editVerifier.sectionButton'
                                    }
                                    disabled={
                                        (!creatingVerifier &&
                                            !formDataModified) ||
                                        !validForm
                                    }
                                    functionality={
                                        canEditVerifiers && !creatingVerifier
                                            ? saveChange
                                            : saveAndNext
                                    }
                                />
                            </div>
                        ) : null}
                    </LightPanel>
                </div>
            </div>
            <CredentialsRequirementsPreview
                saveButton={{
                    label:
                        canEditVerifiers && !creatingVerifier
                            ? 'public.save'
                            : 'editVerifier.sectionButton',
                    disabled:
                        (!creatingVerifier && !formDataModified) || !validForm,
                    function:
                        canEditVerifiers && !creatingVerifier
                            ? saveChange
                            : saveAndNext,
                }}
                showPurposes={dataAgreementChecked}
                requiredCredentials={requiredCredentials}
                optionalCredentials={optionalCredentials}
                showSaveButton={canEditVerifiers || creatingVerifier}
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
            />
        </>
    )
}

export default CredentialsRequirementsForm
