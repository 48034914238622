import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import GaCheckbox from '../../../../../Components/atoms/formFields/checkbox/GaCheckbox'
import GaSelect from '../../../../../Components/atoms/formFields/select/GaSelect'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import FormSection from '../../../../../Components/organisms/FormSection/FormSection'
import { initialLang } from '../../../../../i18n'
import {
    ButtonModel,
    ICredentialTypeModel,
    IIssuanceConfigItemModel,
} from '../../../../../interfaces/interfaces'
import { addOrRemove } from '../../../../../utils/checkboxUtil'
import {
    getCatalogGroups,
    getCatalogTypes,
    getLoadingStatus,
} from '../../../../common/catalog/catalogSelectors'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import { TextSizes } from '../../../../../data/globalVar'
import styles from './issuanceForm.module.scss'
import TemplatesFormHeader from '../../../../common/TemplatesHeader/TemplatesFormHeader'
import { issuanceSelectors } from '../..'
import { ActionStatusTypes } from '../../../../../assets/globalConstants'
import {
    getActiveStep,
    getSelectedIssuanceGroup,
    getSelectedIssuanceInitialGroup,
    getSelectedIssuanceInitialTypes,
    getSelectedIssuanceTypes,
} from '../../issuanceSelectors'
import IssuanceCredentialPreview from '../../../../../Components/molecules/modals/CredentialPreview/components/StepsContentPreview/IssuanceCredentialPreview'
import { ReactComponent as AngleDown } from '../../../../../assets/images/angle-down.svg'
import CardListSkeleton from '../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import DarkButton from '../../../../../Components/atoms/buttons/DarkButton/DarkButton'
import { getResponsive } from '../../../../../utils/formUtils'

type IIssuanceFormProps = {
    issuance: IIssuanceConfigItemModel
    canEditIssuances?: boolean
    creatingIssuance?: boolean
    buttonHeader?: ButtonModel
    infoApiKeyPanel?: boolean
    activeStep: any
    formSteps: any
    buttonFunctionHeader?: (x) => void
    buttonFunction: (x) => void
    setValidForm: (x) => void
    onHandleChange: (step) => void
}

const IssuanceForm: React.FC<IIssuanceFormProps> = (props) => {
    const {
        issuance,
        canEditIssuances,
        creatingIssuance,
        buttonHeader,
        infoApiKeyPanel,
        activeStep,
        formSteps,
        buttonFunctionHeader,
        buttonFunction,
        setValidForm,
        onHandleChange,
    } = props
    const { t } = useTranslation()

    // Selectors
    const credentialGroups = useSelector(getCatalogGroups)
    const credentialTypes = useSelector(getCatalogTypes)
    const selectedGroup = useSelector(getSelectedIssuanceGroup)
    const selectedInitialGroup = useSelector(getSelectedIssuanceInitialGroup)
    const selectedTypes = useSelector(getSelectedIssuanceTypes)
    const selectedInitialTypes = useSelector(getSelectedIssuanceInitialTypes)
    const [formDataModified, setFormDataModified] = useState(false)
    const updateStatus = useSelector(issuanceSelectors.getUpdateStatus)
    const step = useSelector(getActiveStep)
    const catalogLoadingStatus = useSelector(getLoadingStatus)
    const catalogIsLoading = !(
        catalogLoadingStatus?.status === 'finished' ||
        catalogLoadingStatus?.status === 'error'
    )

    // Consts
    const initialFormData = {
        credentialGroup: selectedInitialGroup
            ? JSON.parse(JSON.stringify(selectedInitialGroup))
            : credentialGroups[0]?.id || '',
        credentialTypes: selectedInitialTypes
            ? JSON.parse(JSON.stringify(selectedInitialTypes))
            : [],
    }

    // States
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

    const [formData, setFormState] = useState({
        credentialGroup: selectedGroup
            ? JSON.parse(JSON.stringify(selectedGroup))
            : credentialGroups[0]?.id || '',
        credentialTypes: selectedTypes
            ? JSON.parse(JSON.stringify(selectedTypes))
            : [],
    })

    const validForm =
        !!formData.credentialGroup && !!formData.credentialTypes?.length

    // Effects
    useEffect(() => {
        scrollToTop()
    }, [step])

    useEffect(() => {
        setValidForm(
            !!formData.credentialGroup && !!formData.credentialTypes?.length
        )
    }, [credentialTypes])

    useEffect(() => {
        if (JSON.stringify(formData) === JSON.stringify(initialFormData)) {
            setFormDataModified(false)
        } else {
            setFormDataModified(true)
        }
    }, [formData])

    useEffect(() => {
        if (updateStatus === ActionStatusTypes?.success) {
            setFormDataModified(false)
        }
    }, [updateStatus])

    // Functions
    const getCredTypesFiltered = () => {
        const typesFiltered = credentialTypes?.filter((e) =>
            e?.groupList?.includes(formData?.credentialGroup)
        )
        const multiCred = typesFiltered?.filter((el) => {
            return el.multi
        })
        if (multiCred && multiCred[0]) {
            const index = typesFiltered.indexOf(multiCred[0])
            if (index > -1) {
                typesFiltered.splice(index, 1)
            }
            typesFiltered?.unshift(multiCred[0])
        }

        return typesFiltered
    }

    const credentialTypesFiltered = getCredTypesFiltered()
    const multiCred = credentialTypesFiltered?.filter((el) => {
        return el.multi
    })[0]

    const onSubmit = (e) => {
        e.preventDefault(), saveAndNext()
    }

    const saveAndNext = () => {
        buttonFunction(formData)
        scrollToTop()
    }

    const handleChange = (credential?: ICredentialTypeModel) => {
        let selection = addOrRemove(credential?.id, formData?.credentialTypes)
        if (selection?.includes(multiCred?.id)) {
            selection = [multiCred?.id]
        }
        setFormState({
            credentialGroup: formData?.credentialGroup,
            credentialTypes: selection && JSON.parse(JSON.stringify(selection)),
        })
        setValidForm(!!formData.credentialGroup && !!selection?.length)
    }

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    // Responsive preview

    const [show, setShowModal] = React.useState(false)
    const showModal = () => setShowModal(true)
    const hideModal = () => setShowModal(false)

    return (
        <>
            <div className={styles.leftColumn}>
                <div className={styles.headerStepper}>
                    <TemplatesFormHeader
                        title={
                            creatingIssuance
                                ? 'createIssuance.title'
                                : t('editIssuance.title', {
                                      id: issuance?.id || '',
                                  })
                        }
                        description={
                            creatingIssuance
                                ? 'createIssuance.description'
                                : 'editIssuance.description'
                        }
                        buttonFunctionHeader={buttonFunctionHeader}
                        buttonHeader={buttonHeader}
                        formSteps={formSteps}
                        elementsAllignment={creatingIssuance ? false : true}
                        infoApiKeyPanel={infoApiKeyPanel}
                        titleInfoPanel={t('editIssuance.addApiKeyInfo')}
                        onHandleChange={onHandleChange}
                        activeStep={activeStep}
                        loadingProgressBar={catalogIsLoading}
                        inactiveSteps={
                            !creatingIssuance &&
                            !issuance?.prConfig?.credentials?.length
                                ? [3, 4]
                                : undefined
                        }
                    />
                </div>
                <div className={styles.form}>
                    <LightPanel>
                        <ScreenHeader
                            title={'editIssuance.step2.sectionTitle'}
                            subText={'editIssuance.step2.sectionAuxText'}
                            buttonFunction={showModal}
                            button={
                                getResponsive(1320)
                                    ? {
                                          label: 'general.showPreview',
                                          disabled: false,
                                      }
                                    : undefined
                            }
                            buttonIsLight={true}
                            titleClassname="heading6"
                            subTextClassname={'bodyRegularSM'}
                            className={styles.formHeader}
                        />
                        <FormSection title="">
                            <form onSubmit={onSubmit}>
                                {credentialGroups?.length ? (
                                    <>
                                        <GaSelect
                                            idSelect={'requesterDid'}
                                            className={cx('margin-bottom')}
                                            value={formData?.credentialGroup}
                                            invalid={
                                                !formData?.credentialGroup?.trim()
                                                    ?.length
                                            }
                                            name={'requesterDid'}
                                            labelText={t(
                                                'editIssuance.step2.credType'
                                            )}
                                            onChange={(e) => {
                                                setFormState({
                                                    credentialGroup:
                                                        e?.target?.value,
                                                    credentialTypes: [],
                                                })
                                            }}
                                            disabled={
                                                !canEditIssuances &&
                                                !creatingIssuance
                                            }
                                            isLoading={
                                                !credentialGroups?.length
                                            }
                                            optionTextProperty={'name'}
                                            optionTextPropertyTranslated={true}
                                            optionValueProperty={'id'}
                                            options={credentialGroups}
                                            required
                                        />
                                        <Description
                                            className={cx(
                                                'marginBottom4 bodyRegularXS neutral700'
                                            )}
                                            text={
                                                'editIssuance.step2.sectionSecondDescription'
                                            }
                                        />

                                        <div className={styles.checkboxesPanel}>
                                            <Description
                                                className={cx(
                                                    'paddingBottom12 borderBottom1000 bodyRegularMD neutral600'
                                                )}
                                                text={
                                                    'editIssuance.step2.sectionSecondAuxText'
                                                }
                                            />

                                            {multiCred && (
                                                <GaCheckbox
                                                    key={'multiCred'}
                                                    item={multiCred?.id}
                                                    className={cx(
                                                        styles.multiCredTypeCheckBox,
                                                        'borderBottom300',
                                                        'paddingTop16',
                                                        'paddingBottom16',
                                                        styles.justifyContent
                                                    )}
                                                    itemText={t(
                                                        multiCred?.name[
                                                            initialLang
                                                        ]
                                                            ? multiCred?.name[
                                                                  initialLang
                                                              ]
                                                            : multiCred?.name[
                                                                  'en'
                                                              ] || multiCred.id
                                                    )}
                                                    index={'multiCred'}
                                                    disabled={
                                                        !canEditIssuances &&
                                                        !creatingIssuance
                                                    }
                                                    checked={formData?.credentialTypes?.includes(
                                                        multiCred?.id
                                                    )}
                                                    onChange={(e) => {
                                                        handleChange(multiCred)
                                                    }}
                                                    textSize={TextSizes.LG}
                                                    name={t(
                                                        multiCred?.name[
                                                            initialLang
                                                        ]
                                                            ? multiCred?.name[
                                                                  initialLang
                                                              ]
                                                            : multiCred?.name[
                                                                  'en'
                                                              ] || multiCred.id
                                                    )}
                                                />
                                            )}

                                            {credentialTypesFiltered?.map(
                                                (credential, index) =>
                                                    multiCred?.id !==
                                                        credential?.id && (
                                                        <GaCheckbox
                                                            className={`${cx(
                                                                'paddingTop16'
                                                            )} ${
                                                                styles.justifyContent
                                                            }`}
                                                            key={index}
                                                            textSize={
                                                                TextSizes.LG
                                                            }
                                                            item={
                                                                credential?.id
                                                            }
                                                            itemText={t(
                                                                credential
                                                                    ?.name[
                                                                    initialLang
                                                                ]
                                                                    ? credential
                                                                          ?.name[
                                                                          initialLang
                                                                      ]
                                                                    : credential
                                                                          ?.name[
                                                                          'en'
                                                                      ] || ''
                                                            )}
                                                            disabled={
                                                                (!canEditIssuances &&
                                                                    !creatingIssuance) ||
                                                                formData?.credentialTypes?.includes(
                                                                    multiCred?.id
                                                                )
                                                            }
                                                            index={'' + index}
                                                            checked={
                                                                formData?.credentialTypes?.includes(
                                                                    credential?.id
                                                                ) &&
                                                                !formData?.credentialTypes?.includes(
                                                                    multiCred?.id
                                                                )
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(
                                                                    credential
                                                                )
                                                            }}
                                                            name={t(
                                                                credential
                                                                    ?.name[
                                                                    initialLang
                                                                ]
                                                                    ? credential
                                                                          ?.name[
                                                                          initialLang
                                                                      ]
                                                                    : credential
                                                                          ?.name[
                                                                          'en'
                                                                      ] || ''
                                                            )}
                                                        />
                                                    )
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <CardListSkeleton
                                        key={'credentials__skeleton'}
                                        className={styles.credentialsSkeleton}
                                        cardsNumber={1}
                                    />
                                )}
                                <div className={cx(styles.advancedOptions)}>
                                    <p
                                        className={cx('buttonSM primary700')}
                                        onClick={() =>
                                            setShowAdvancedOptions(
                                                !showAdvancedOptions
                                            )
                                        }
                                    >
                                        {t('certificates.advancedOptions')}
                                        <AngleDown
                                            className={`${
                                                styles.advancedOptionsToogle
                                            } ${
                                                showAdvancedOptions &&
                                                styles.toogleOpen
                                            }`}
                                        />
                                    </p>
                                    {showAdvancedOptions ? (
                                        <>
                                            <div
                                                className={`${
                                                    styles.notAvailable
                                                } ${cx('marginTop20')}`}
                                            >
                                                <div className={styles.text}>
                                                    <div
                                                        className={cx(
                                                            'buttonSM neutral600'
                                                        )}
                                                    >
                                                        {t(
                                                            'editIssuance.step2.selectDisclosureTitle'
                                                        )}
                                                    </div>
                                                    <div
                                                        className={cx(
                                                            'bodyRegularXS neutral600'
                                                        )}
                                                    >
                                                        {t(
                                                            'editIssuance.step2.selectDisclosureDescription'
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${
                                                        styles.tagComingSoon
                                                    } ${cx('bodyRegularXS')}`}
                                                >
                                                    {t('general.comingSoon')}
                                                </div>
                                            </div>
                                            <div
                                                className={cx(
                                                    'bodyRegularXS neutral600 marginTop20'
                                                )}
                                            >
                                                {t(
                                                    'editIssuance.step2.credFormatTitle'
                                                )}
                                            </div>
                                            <div
                                                className={`${
                                                    styles.notAvailable
                                                } ${cx('marginTop4')}`}
                                            >
                                                <div
                                                    className={`${
                                                        styles.text
                                                    } ${cx(
                                                        'bodyRegularSM neutral600'
                                                    )}`}
                                                >
                                                    {t(
                                                        'editIssuance.step2.credFormatDescription'
                                                    )}
                                                </div>
                                                <div
                                                    className={`${
                                                        styles.tagComingSoon
                                                    } ${cx('bodyRegularXS')}`}
                                                >
                                                    {t('general.comingSoon')}
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </form>
                        </FormSection>
                        {getResponsive(1320) ? (
                            <div className={styles.buttonContainer}>
                                <DarkButton
                                    text={
                                        canEditIssuances && !creatingIssuance
                                            ? 'public.save'
                                            : 'editIssuance.sectionButton'
                                    }
                                    disabled={
                                        !validForm ||
                                        (!creatingIssuance && !formDataModified)
                                    }
                                    functionality={saveAndNext}
                                />
                            </div>
                        ) : null}
                    </LightPanel>
                </div>
            </div>
            <IssuanceCredentialPreview
                saveButton={{
                    label:
                        canEditIssuances && !creatingIssuance
                            ? 'public.save'
                            : 'editIssuance.sectionButton',
                    disabled:
                        !validForm || (!creatingIssuance && !formDataModified),
                    function: saveAndNext,
                }}
                showSaveButton={canEditIssuances || creatingIssuance}
                catalogGroups={credentialGroups}
                catalogTypes={credentialTypes}
                data={formData}
                isMultiClaimSelected={formData?.credentialTypes?.includes(
                    multiCred?.id
                )}
                smallResolution={getResponsive(1320)}
                show={show}
                hideModal={hideModal}
            />
        </>
    )
}

export default IssuanceForm
