export enum credentialStatus {
    CredentialStatusPending = 'PENDING',
    CredentialStatusIssued = 'ISSUED',
    CredentialStatusRevoked = 'REVOKED',
    CredentialStatusDeleted = 'DELETED',
    CredentialStatusExpired = 'EXPIRED',
    CredentialStatusInvalid = 'INVALID',
    CredentialStatusClaimed = 'CLAIMED',
    CredentialStatusSuspended = 'SUSPEND',
}

export enum SignatureAlgorithm {
    TypeGatcEd25519 = 'GatcEd25519Signature2019',
    TypeEd25519 = 'Ed25519Signature2018',
    TypeEd25519_20 = 'Ed25519Signature2020',
    TypeJCS_Ed = 'JcsEd25519Signature2020',
    TypeRsa = 'RsaSignature2018',
    TypeEidas = 'CAdESRSASignature2020',
    TypeJWS = 'JsonWebSignature2020',
    TypeSECP256K1 = 'EcdsaSecp256k1Signature2019',
    TypeEbsiEd25519 = 'EbsiEd25519Signature2020',
    TypeEbsiRsa = 'EbsiRsaSignature2020',
    TypeEbsiSECP256K1 = 'EbsiEcdsaSecp256k1Signature2019',
    TypeEbsiJWS = 'JWS',
}

export enum MechanismId {
    AuthNFactor = 'AuthNFactor',
    AppAuth = 'AppAuth',
    Credential = 'Credential',
}

export enum TrustLevels {
    AutoSigned = 0,
    ThirdParty = 1,
    Issuer = 2,
}
