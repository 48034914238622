import {
    DataAgreementEvent,
    IDataAgreementItem,
} from '../../../interfaces/interfaces'
import { RootState } from '../../../store/indexReducers'

// LIST
export const getConsents = (state: RootState) =>
    sortConsents(state.consentsReducer.consents)
export const isLoading = (state: RootState) =>
    state.consentsReducer.loadingStatus
export const getConsentData = (state: RootState) =>
    state.consentsReducer.consentData

const sortConsents = (consents: IDataAgreementItem[] | null) => {
    const sortedConsents = consents || []

    sortedConsents?.sort(function (a, b) {
        const timestampA = getTimesTamp(a) || 0
        const timestampB = getTimesTamp(b) || 0

        return timestampB - timestampA
    })

    return sortedConsents
}

const getTimesTamp = (consent: IDataAgreementItem) => {
    return consent.event?.find((ev: DataAgreementEvent) => {
        return ev.state === 'Capture'
    })?.timestamp
}
